/**
 * Forgot password UI page.
 */
import React, { useEffect } from "react"
import { Sidebar } from "src/components/Konnect/Sidebar"
import { Editor } from "src/components/Konnect/Editor"
import { isEqual } from "lodash"
import { Flex } from "rebass"
import AppsSvg from "src/assets/images/apps"
import HeartSvg from "src/assets/images/heart"
import AuthTokenService from "utils/AuthTokenService";

import styled from "@emotion/styled"

const Wrapper = styled(Flex)`
  height: 100%;
`

class KonnectViewClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      layout: {},
    }
  }

  componentDidMount() {
    const { editorActions } = this.props
    editorActions.getAuthApps().then((res) => {
      if (res.type === "GET_AUTHORISED_APPS_FAIL") {
        if (res.error.response.status === 422) {
          AuthTokenService.clear()
          this.props.history.push('/login')
        }
      }
    })
  }

  saveCanvas = e => {
    const state = this.editorRef.getState()
  }

  shouldComponentUpdate(prevProps) {
    return !isEqual(prevProps, this.props)
  }

  render() {
    const {
      authorisedApps,
      editorActions,
      editorState,
      editorMeta,
      onSearchItem,
      isCollapsed,
      toggleSidebar,
      testAndReviewLoader
    } = this.props

    const { layout } = this.state

    return (
      <Wrapper>
        {isCollapsed ? (
          <Flex
            onClick={toggleSidebar}
            sx={{ flexDirection: "column", minWidth: "65px", height: "100%", backgroundColor: "#152F73" }}
          >
            <Flex
              sx={{ cursor: "pointer", width: "100%", height: "60px", alignItems: "center", justifyContent: "center" }}
            >
              <HeartSvg />
            </Flex>
            <Flex
              sx={{ cursor: "pointer", width: "100%", height: "60px", alignItems: "center", justifyContent: "center" }}
            >
              <AppsSvg />
            </Flex>
          </Flex>
        ) : (
            <Sidebar
              apps={authorisedApps}
              editorMeta={editorMeta}
              onSearchItem={onSearchItem}
              editorActions={editorActions}
            />
          )}
        <Editor editorState={editorState} editorActions={editorActions} editorMeta={editorMeta} testAndReviewLoader = {testAndReviewLoader}/>
      </Wrapper>
    )
  }
}

export const KonnectView = KonnectViewClass