import React, { useState } from "react"
import { Box, Flex } from "rebass"
import { Label, Radio } from "@rebass/forms"
import { Dropdown } from "src/components/Konnect/DropdownMenu/index"
import { Input } from "src/components/Input/Input"
import { faEllipsisH, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons"
import { Icon } from "src/components"
import { useTranslation } from "react-i18next"

export const ConditionItem = ({
  condition,
  operatorsList,
  fromFields,
  showQueryOperators,
  updateCondition,
  itemIndex,
  konnect_activity_id,
}) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState("")
  const [searchOperators, setSearchOperators] = useState("")
  const [searchFields, setSearchFields] = useState("")
  return (
    <>
      <Flex
        sx={{
          border: "1px solid #B7CBFF",
          flexDirection: "column",
          borderRadius: "12px",
          pl: "14px",
          pr: "24px",
          py: "9px",
          boxSizing: "border-box",
          mb: "20px",
        }}
      >
        <Box sx={{ my: "10px" }}>
          <Label sx={{ fontSize: "18px", fontWeight: "500" }}>
            {t("konnect.conditions.headerConditionText")} {itemIndex + 1}
          </Label>
        </Box>
        <Flex sx={{ width: "100%", flexDirection: "row", textAlign: "left", justifyContent: "space-around" }}>
          <Box sx={{ mx: "20px", width: "100%", height: "58px" }}>
            <Dropdown
              searchable={true}
              searchValue={searchFields}
              onFilterChange={text => {
                setSearchFields(text)
              }}
              inputPlaceholder={!searchFields ? "Search field" : ""}
              headerTitle={t("konnect.conditions.selectFieldHeaderTitle")}
              items={fromFields}
              renderKey="label"
              selected={condition.field ? condition.field.label : null}
              onChange={selected => {
                const conditionObj = { ...condition, field: { key: selected.linkId, label: selected.label, value: selected.config_key } }
                updateCondition(conditionObj)
                setSearchFields("")
              }}
            />
          </Box>

          <Box sx={{ mx: "20px", width: "100%", height: "58px" }}>
            <Dropdown
              searchable={true}
              searchValue={searchOperators}
              onFilterChange={text => {
                setSearchOperators(text)
              }}
              inputPlaceholder={!searchOperators ? "Search field" : ""}
              headerTitle={t("konnect.conditions.selectConditionHeaderTitle")}
              items={operatorsList}
              renderKey={"name"}
              selected={condition.operator}
              onChange={selected => {
                const conditionObj = { ...condition, operator: selected.id }
                updateCondition(conditionObj)
                setSearchOperators("")
              }}
            />
          </Box>
          <Box sx={{ mx: "20px", width: "100%", height: "40px" }}>
            {/* <Input
              style={{ height: "40px" }}
              value={condition.value}
              onChange={event => {
                const conditionObj = { ...condition, value: event.currentTarget.value }
                updateCondition(conditionObj)
              }}
            /> */}
            <Dropdown
              headerTitle={
                condition.value
                  ? condition.value.type
                    ? condition.value.name
                    : condition.value
                  : t("konnect.conditions.selectFieldHeaderTitle")
              }
              _condition={condition}
              items={fromFields}
              renderKey="label"
              onChange={(selected, _condition) => {
                let value = ""
                if (selected.label) {
                  value = {
                    type: "key-map",
                    value: selected.config_key,
                    konnect_activity_id: konnect_activity_id,
                    name: selected.label,
                  }
                } else {
                  value = selected
                }
                
                const conditionObj = { ...condition, value }
                updateCondition(conditionObj)
                setSearch("")
              }}
              searchable={true}
              searchValue={search}
              onFilterChange={text => {
                setSearch(text)
              }}
              inputPlaceholder={!search ? "Search field" : ""}
            />
          </Box>
          <Box sx={{ mx: "20px", height: "40px" }}>
            <Icon
              ml="30px"
              mr="40px"
              icon={faTrash}
              sx={{ cursor: "pointer" }}
              onClick={() => updateCondition(condition, true)}
            />
          </Box>
        </Flex>
      </Flex>
      {showQueryOperators && (
        <Flex sx={{ mb: "20px", flexDirection: "row", display: "inline-flex !important" }}>
          <Label sx={{ display: "-webkit-box !important" }}>
            <Radio
              name={condition.key + "operator"}
              id="and"
              value="and"
              checked={"and" === condition.queryOperator}
              onChange={event => {
                const conditionObj = { ...condition, queryOperator: event.currentTarget.value }
                updateCondition(conditionObj)
              }}
            />
            {t("konnect.conditions.operatorAndLabel")}
          </Label>
          <Label sx={{ ml: "20px" }}>
            <Radio
              name={condition.key + "operator"}
              id="or"
              value="or"
              
              checked={condition.queryOperator === "or"}
              onChange={event => {
                const conditionObj = { ...condition, queryOperator: event.currentTarget.value }
                updateCondition(conditionObj)
              }}
            />
            {t("konnect.conditions.operatorOrLabel")}
          </Label>
        </Flex>
      )}
    </>
  )
}
