import React, { useState, useRef } from "react"
import { generateCurvePath } from "../"
import { DeleteLink } from "../../../UI"
import ReactTooltip from "react-tooltip"
import styled from "@emotion/styled"
import ReactDOMServer from "react-dom/server"
import DeleteIcon from "src/assets/images/delete-node"
import { DecoratorIcon } from "src/components"
import { faPlay, faPlus, faTrash, faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons"
const StyledTooltip = styled(ReactTooltip)`
  pointer-events: auto !important;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
  height: 40px;
  width: 20px;
  & span {
    align-items: center;
    justify-content: center;
    display: flex;
  }
`

const DeleteButton = ({ fill = "#152F73" }) => {
  return <DecoratorIcon icon={faTrash} size={18} iconColor={fill}></DecoratorIcon>
}

export const LinkDefault = ({
  config,
  link,
  startPos,
  endPos,
  fromPort,
  toPort,
  onLinkMouseEnter,
  onLinkMouseLeave,
  onLinkClick,
  isHovered,
  isSelected,
  offset,
  chart,
  onDeleteLink,
}) => {
  const points = generateCurvePath(startPos, endPos)
  const tooltip = useRef(null)
  const lineColor = useRef(null)
  const tooltipColor = useRef(null)
  const [Tooltip, setTooltip] = useState(false)
  const [LineHover, setLineHover] = useState(false)

  return (
    <>
      <svg
        style={{
          overflow: "visible",
          position: "absolute",
          cursor: "pointer",
          left: 0,
          right: 0,
          // zIndex: 1,
        }}
      >
        <circle r="2" cx={startPos.x} cy={startPos.y} fill="#F7C545" />
        {/* Main line */}
        <path d={points} stroke={lineColor.current && toPort ? "#152F73" : "#F7C545"} strokeWidth="3" fill="none" />
        {/* Thick line to make selection easier */}

        <path
          // data-for={link.id}
          // data-tip="<DeleteLink />"
          // data-html={true}
          // data-iscapture={true}
          data-tip
          data-for={link.id}
          d={points}
          stroke="#F7C545"
          strokeWidth={"10"}
          fill="none"
          strokeLinecap="round"
          strokeOpacity={0}
          onMouseEnter={e => {
            lineColor.current = true
            setLineHover(true)
          }}
          onMouseLeave={e => {
            lineColor.current = false
            setLineHover(false)
          }}
          onMouseMove={event => {
            // if (fromPort && toPort) {
            //   onLinkMouseEnter({
            //     link,
            //     pos: {
            //       x: event.x,
            //       y: event.y,
            //     },
            //   })
            // }
          }}
          onClick={e => {
            // onLinkClick({ config, link })
            // setDelete(false)
            e.stopPropagation()
          }}
        />
        <circle r="2" cx={endPos.x} cy={endPos.y} fill="#F7C545" />
      </svg>
      {fromPort && toPort ? (
        <StyledTooltip
          id={link.id}
          place="right"
          effect="solid"
          clickable={true}
          place={"top"}
          type={"dark"}
          effect={"float"}
          delayHide={500}
          backgroundColor="#FFFFFF"
          // afterHide={() => setIsOpen(false)}
          // afterShow={() => setIsOpen(true)}
          // ref={tooltip}
          overridePosition={({ left, top }, currentEvent, currentTarget, node) => {
            const d = document.documentElement

            left = left / chart.scale
            top = top / chart.scale

            // left = Math.max(0, left)
            // top = Math.max(0, top)

            return { top, left }
          }}
        >
          <div
            onMouseEnter={() => {
              setTooltip(true)
              tooltipColor.current = true
            }}
            onMouseLeave={() => {
              setTooltip(false)
              tooltipColor.current = false
            }}
            onClick={() => {
              onDeleteLink({ id: link.id })
              tooltip.current = null

              ReactTooltip.hide()
            }}
          >
            <DeleteButton fill={tooltipColor.current ? "#ff6347" : "#152F73"} />
          </div>
        </StyledTooltip>
      ) : null}
    </>
  )
}
