/**
 * App config reducer to handle the global config api responses.
 */

import { GET_AUTH_URL, SAVE_AUTH, GET_APP_DETAILS  } from 'config/Constants'

const initState = {
    oauthData: {},
    oauthSaved: false,
    appDetails: {}
}

const SUCCESS = '_SUCCESS'
const FAIL = '_FAIL'

const appsReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_AUTH_URL + SUCCESS:
            return state.set('oauthData', action.payload)

        case GET_AUTH_URL + FAIL:
            return state.set('oauthData', null)

        case SAVE_AUTH:
            return state.set('oauthSaved', false)
        
        case SAVE_AUTH + SUCCESS:
            return state.set('oauthSaved', true)

        case SAVE_AUTH + FAIL:
            return state.set('oauthData', false)

        case GET_APP_DETAILS + SUCCESS:
            return state.set('appDetails', action.payload)

    }

    return state
}

export default appsReducer
