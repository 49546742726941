/**
 * Containes the application routes configuration.
 */

import React from "react"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import {
  Shell,
  DashboardContainer,
  AppsContainer,
  ForgotContainer,
  ResetContainer,
  KonnectAppContainer,
  KonnectzContainer,
  HistoryContainer,
  NotificationsContainer,
  GetHelpContainer,
  AccountContainer,
  AppsOauthContainer,
} from "src/features"
import LoginContainer from "src/features/auth/login/Login.container"
import SignupContainer from "src/features/auth/signup/Signup.container"
import VerifyEmailContainer from "src/features/auth/verifyEmail/VerifyEmail.container"
import SignupSuccessContainer from "src/features/auth/signupSuccess/SignupSuccess.container"
import ReactFlow from "../features/ReactFlow/ReactFlow"
import Canvas from "../features/ReactFlow/Canvas"
import { ThemeProvider } from "../features/ReactFlow/store/context/themeContext"
const AUTH_SHELL_ROUTES = [
  {
    path: "/dashboard",
    key: "DASHBOARD",
    exact: true,
    component: DashboardContainer,
  },
  {
    path: "/apps",
    key: "APPS",
    exact: true,
    component: AppsContainer,
  },
  {
    path: "/history",
    key: "HISTORY",
    exact: true,
    component: HistoryContainer,
  },
  {
    path: "/notifications",
    key: "NOTIFICATIONS",
    exact: true,
    component: NotificationsContainer,
  },
  {
    path: "/get-help",
    key: "GET_HELP",
    exact: true,
    component: GetHelpContainer,
  },
  {
    path: "/account",
    key: "ACCOUNT",
    exact: true,
    component: AccountContainer,
  },
  // {
  //   path: "/konnectz1",
  //   key: "KONNECTZ",
  //   exact: true,
  //   component: KonnectzContainer,
  // },
  {
    path: "/konnect",
    key: "Reactflow",
    exact: true,
    component: ReactFlow,
  },
  {
    path: "/Konnectz",
    key: "Canvas",
    exact: true,
    component: Canvas,
  },
]

const AUTH_NON_SHELL_ROUTES = [
  { path: "/konnect1", key: "KONNECT", exact: true, component: KonnectAppContainer }, //to canvas
  { path: "/konnect1/:id", key: "KONNECT", exact: true, component: KonnectAppContainer }, //to particular konnect

  { path: "/konnect", key: "Reactflow", exact: true, component: ReactFlow }, //to canvas
  { path: "/konnect/:id", key: "Reactflow", exact: true, component: ReactFlow }, //to particular konnect

  {
    path: "/oauth/response/:appId/:token",
    key: "OAUTH",
    exact: true, //app to authorise
    component: AppsOauthContainer,
  },
  {
    path: "/oauth/response",
    key: "OAUTH",
    exact: true, //response after authorisation
    component: AppsOauthContainer,
  },
]

const NON_AUTH_ROUTES = [
  { path: "/", key: "ROOT", exact: true, component: LoginContainer },
  {
    path: "/login",
    key: "APP_LOGIN",
    exact: true,
    component: LoginContainer,
  },
  {
    path: "/logout",
    key: "APP_LOGOUT",
    exact: true,
    component: LoginContainer,
  },
  {
    path: "/signup",
    key: "APP_SIGNUP",
    exact: true,
    component: SignupContainer,
  },
  {
    path: "/verify-email/:token",
    key: "APP_VERIFY_EMAIL",
    exact: true,
    component: VerifyEmailContainer,
  },
  {
    path: "/verify-email-success",
    key: "APP_VERIFY_EMAIL_SUCCESS",
    exact: true,
    component: VerifyEmailContainer,
  },
  {
    path: "/signup-success",
    key: "APP_SIGN_UP_SUCCESS",
    exact: true,
    component: SignupSuccessContainer,
  },
  {
    path: "/forgot-password",
    key: "APP_FORGOT_PASSWORD",
    exact: true,
    component: ForgotContainer,
  },
  {
    path: "/reset-password/:token",
    key: "APP_RESET_PASSWORD",
    exact: true,
    component: ResetContainer,
  },
]

export function RenderRoutes({}) {
  return (
    <ThemeProvider>
      <Router>
        <Switch>
          {NON_AUTH_ROUTES.map((route, i) => {
            return (
              <Route
                key={route.key}
                path={route.path}
                exact={route.exact}
                render={props => <route.component {...props} route={route.key} />}
              />
            )
          })}
          {AUTH_NON_SHELL_ROUTES.map((route, i) => {
            return (
              <Route
                key={route.key}
                path={route.path}
                exact={route.exact}
                render={props => <route.component {...props} routes={route.routes} />}
              />
            )
          })}
          <Shell>
            <Switch>
              {AUTH_SHELL_ROUTES.map((route, i) => {
                return (
                  <Route
                    exact
                    key={route.key}
                    path={route.path}
                    render={props => <route.component {...props} routes={route.routes} />}
                  />
                )
              })}
              <Route path="*">
                <Redirect to="/logout" />
              </Route>
            </Switch>
          </Shell>
        </Switch>
      </Router>
    </ThemeProvider>
  )
}
