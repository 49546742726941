import React, { memo, useState, useMemo, useEffect } from "react"
import { useTranslation } from "react-i18next"
import "antd/dist/antd.css"
import { DatePicker, Space, TimePicker } from "antd"
import moment from "moment"
import "./style.css"
import { PortWrapper, PortDefault } from "../"
import ReactTooltip from "react-tooltip"
import { Box, Flex } from "rebass"
import { Handle } from "react-flow-renderer"
import { Input as RebassInput, Label as RebassLabel } from "@rebass/forms"
import CrossWhite from "../../../../../assets/images/Cross_white.svg"
import Multiselect from "multiselect-react-dropdown"
import { AdditionalConfigsModal } from "./modals/additionalConfigsModal"
import { Modal } from "react-responsive-modal"
import {
  AppInfo,
  ConnectorOuter,
  ConnectorPortOuter,
  ConnectorPortInput,
  ConnectorPortText,
  ConnectorPortLabel,
  ConnectorPortMentions,
  ConnectorPortDate,
  ConnectorPortDateTime,
  ConnectorPortTime,
  NodeOuter,
  SelectionHeader,
  EventSelectionGroup,
  TestAndReviewButton,
  AddMoreFieldButton,
} from "../UI"
import { Dropdown } from "src/components/Konnect/DropdownMenu"
import { CommonOuter } from "../NodeInner/CommonOuter"
import { useData } from "../../../Context/canvasContext"
import { updatePayloadType } from "store/actions/konnectActions"
import { faHandHolding } from "../../../../../../node_modules/@fortawesome/free-solid-svg-icons/index"
import {
  onDeleteNodeV2,
  getAppEventConfig,
  getAppEventConfigDetail,
  getAppEventConfigDetailFetch,
  appTestAndReview,
} from "../../../features/index"
import {
  onDeleteNode,
  handleRefresh,
  setConfigMenuState,
  setLinkedAccount,
  setEventsConfig,
  onPortTextChange,enableTestReviewLoader,createAdditionalFields
} from "../../../store/slice/canvasSlice"
import { useSelector, useDispatch } from "react-redux"
export const Scheduler = memo(({ data }) => {


  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [select, setSelect] = useState(false)
  const [event, setEvent] = useState(!!data.appEvent)
  const [eventConfig, setEventConfig] = useState(!!data.appEventConfiguration)
  const [eventSequence, setEventSequence] = useState(null)
  const [openInstructions, setOpenInstructions] = useState(false)
  const [IsDeleteShown, setIsDeleteShown] = useState(false)
  const [nodeReviewed, setNodeReviewed] = useState(data.tested ? data.reviewed : null)
  const [nodeTested, setNodeTested] = useState(data.tested ? data.tested : null)
  const [searchEvents, setSearchEvents] = useState("")
  const [searchEventConfigurations, setSearchEventConfigurations] = useState("")
  const [scheduleType, setScheduleType] = useState("")
  const [scheduleDate, setScheduleDate] = useState(null)
  const [scheduleTime, setScheduleTime] = useState(null)
  const [days, setDays] = useState(null)
  const [dates, setDates] = useState(null)
  const [timeSpan, setTimeSpan] = useState(null)
  const [text, setText] = useState(null)
  const [openAdditionalConfigs, setOpenAdditionalConfigs] = useState(false)
  const [addedKeys, setAddedKeys] = useState([])
  const [open, setOpen] = useState(false);
  const [dateOpen,setDateOpen] = useState(false)
  const today = moment() 
const todayDate = today.format('DD-MM-YYYY')
  const selectedEvent = !data.appEvent
    ? t("konnect.sidebar.choose_trigger")
    : data.appEvents.find(x => x.id === data.appEvent).name
    const { editorState } = useSelector(state => state.canvas.canvas)
    const { links, nodes } = editorState
  const nodeIdx = nodes.findIndex(x => x.id === data.nodeid)
  const source = nodeIdx === 0 || nodes.length > 2
  const target = nodeIdx !== 0
  const selectedTrigger = !data.appEventConfiguration
  ? t("konnect.sidebar.customize_form_partial")
  : data.appEventConfigurations.find(x => x.id === data.appEventConfiguration).service_name
let hasSequence =
  data.appEventConfigurations && data.appEventConfigurations.filter(x => x.sequence !== 0).length ? true : true
let configForms = []

  if (hasSequence) {
    configForms = data.appEventConfigurations
  }

const prepareElements = (responses, additional) => {
  let formElements = []
  if (responses) {
    formElements = responses.map(x => {
    
        const portInfo = {
          node: data.nodeid,
          app: x.app_id,
          appEvent: data.appEvent,
          port: x.id, 
          label: x.label,
          id: x.id, 
          config_key: x.config_key,
        }

        const __key = x.config_key
          .replace(/[^a-zA-Z ]/g, "")
          .split(" ")
          .join("_")


      let data1 = data.fromPorts ? data.fromPorts : []
     let ConnectorPort = x.key_value_type === "text" ? ConnectorPortText : ConnectorPortInput
      return (
        <>
           
              <ConnectorPortLabel style={{ marginTop: "10px", color: "#111111" }}>
                {x.label} {x.config_key_required ? <span style={{ marginTop: "10px", color:"#F74545" }}>*</span> : ""}
              </ConnectorPortLabel>
              <ConnectorOuter key={`${x.app_id}_${__key}`} type={x.key_value_type}>
                {target && !x.additional ? (
                  <ConnectorPortOuter placement="left" type={x.key_value_type}>
                  <Handle
                    type="target"
                    position="left"
                    isConnectable="true"
                    style={{
                      height: "20px",
                      background: "#152f73",
                      width: "20px",
                      borderRadius: "60%",
                      border: "1px solid #152f73",
                    }}
                    id={x.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "target" })}
                  />
                </ConnectorPortOuter>
              ) : null}

              <span
                style={{
                  width: "100%",
                }}
                data-for={`${x.app_id}_${__key}`}
                data-tip={x.label ? x.label : ""}
                data-iscapture="true"
              >
                <ConnectorPort
                  placeholder={!x.dirty ? x.label : `${x.label}*`}
                  data={data1}
                  onChange={val => {
                    dispatch(
                    onPortTextChange({
                      data,
                      nodeId: data.nodeid,
                      portId: x.id,
                      config_key: x.config_key,
                      input: val,
                    }))
                  }}
                  defaultValue={x.value ? x.value : ""}
                ></ConnectorPort>
              </span>

              <ReactTooltip
                id={`${x.app_id}_${__key}`}
                // id="zzzzzz"
                place={"bottom"}
                type={"dark"}
                effect={"solid"}
                multiline={true}
                // overridePosition={({ left, top }) => {
                //   return { left: left / chart.scale, top: top / chart.scale }
                // }}
              />

              {source && x.additional ? (
                <ConnectorPortOuter placement="right" type={x.key_value_type}>
                  <Handle
                    type="source"
                    position="right"
                    isConnectable="true"
                    style={{
                      right: "10px",
                      height: "20px",
                      background: "#152f73",
                      width: "20px",
                      borderRadius: "60%",
                      border: "1px solid #152f73",
                    }}
                    id={x.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "source" })}
                  />
                </ConnectorPortOuter>
              ) : null}
            </ConnectorOuter>
          </>
        )
      })
      return formElements
    }
  }
  let formElements = prepareElements(data.configResponses)

  const updateScheduleType = scheduleName => {
    switch (scheduleName) {
      case "At regular intervals":
        setScheduleType("AtRegularIntervals")
        break
      case "Once":
        setScheduleType("Once")
        break
      case "Every day":
        setScheduleType("Everyday")
        break
      case "Days of the week":
        setScheduleType("DaysOfTheWeek")
        break
      case "Days of the month":
        setScheduleType("DaysOfTheMonth")
        break
    }
  }
  const updateScheduleDate = (evt, x) => {
    setScheduleDate(evt)
    dispatch(onPortTextChange({
      data,
      nodeId: data.nodeid,
      //portId: x.port.id,
      config_key: x.config_key,
      input: evt + " " + scheduleTime ? scheduleTime : "",
    }))
  }

  const updateScheduleTime = (evt, x) => {
    setScheduleTime(evt)
    dispatch(onPortTextChange({
      data,
      nodeId: data.nodeid,
      //portId: x.port.id,
      config_key: x.config_key,
      input: (scheduleDate ? scheduleDate : "") + " " + evt,
    }))
  }

  const getSchedulerData = () => {
    switch (scheduleType) {
      case "AtRegularIntervals":
        return { schedule_type: scheduleType, every: text, interval: timeSpan, schedule_time: scheduleTime, days: days }
        break
      case "Once":
        return { schedule_type: scheduleType, schedule_time: scheduleDate + " " + scheduleTime }
        break
      case "Everyday":
        return { schedule_type: scheduleType, schedule_time: scheduleTime }
        break
      case "DaysOfTheWeek":
        return { schedule_type: scheduleType, days: days, schedule_time: scheduleTime }
        break
      case "DaysOfTheMonth":
        return { schedule_type: scheduleType, dates: dates, schedule_time: scheduleTime }
        break
    }
  }
  useEffect(() => {
    setNodeTested(data.tested ? data.tested : null)
    setNodeReviewed(data.tested ? data.reviewed : null)
  }, [data.tested, data.reviewed])

useEffect((evt) => {
  if(data.config_fields!=null && data.tested){
    let days = JSON.parse(data.config_fields).days
    let dates = (data.raw_response).dates
    let time = (data.raw_response).schedule_time
    let date = JSON.parse(data.config_fields).schedule_date
    setScheduleTime(scheduleType=="Once"||(data.raw_response).schedule_type=="Once"?time.split(" ")[1]:time)
    setScheduleDate(scheduleType=="Once" || (data.raw_response).schedule_type=="Once"?time.split(" ")[0]:date)

    setDays(days)
    setDates(dates)
    
  }
})


  const handleAdditionalConfigsClick = () => {
    setOpenAdditionalConfigs(true)
  }

const onCloseAdditionalConfigsModal = list => {
  setOpenAdditionalConfigs(false)
  const selected = list.filter(x => x.selected)
  setAddedKeys(selected)
 dispatch( createAdditionalFields({ fields: selected, nodeId: data.nodeid }))
  //
}
const onChange = (date, dateString) => {

};
  //let nodeReviewed = node.tested ? node.reviewed : null
  return (
    <>
      <CommonOuter node={data} reviewed={nodeReviewed} nodeTested={nodeTested} />
      <NodeOuter
        onMouseEnter={() => setIsDeleteShown(true)}
        onMouseLeave={() => setIsDeleteShown(false)}
        isFirst={!nodeIdx}
        reviewed={nodeReviewed}
      >
        <AppInfo
          title={data.name}
          subtitle={data.account}
          image={data.image}
          className="DraggableHandle"
          background={data.background}
          hasDelete={IsDeleteShown && (nodeIdx !== 0 || nodes.length === 1)}
          // onDelete={() => {
          //   editorActions.onDeleteNode({
          //     nodeId: node.nodeId,
          //   })
          // }}
          onDelete={() => {
            if (data.tested || data.konnect_activity_id) {
              dispatch(
                onDeleteNodeV2({
                  konnect_id: nodes[0].data.konnect_id,
                  konnect_activity_id: data.konnect_activity_id,
                  canvas_json: editorState,
                  nodeId: data.nodeid,
                })
              ).then(res => {
                dispatch(onDeleteNode(data))
              })
            } else {
              dispatch(onDeleteNode(data))
            }
          }}
        ></AppInfo>
        {/* <DatePicker onClick = {() => setOpen(true)}  onBlur={() => setOpen(false)} open={open} onChange={onChange} /> */}
        <>
          <EventSelectionGroup>
            <SelectionHeader>{"Select Trigger"}</SelectionHeader>

            <Dropdown
              searchable={true}
              searchValue={searchEvents}
              onFilterChange={text => {
                setSearchEvents(text)
              }}
              inputPlaceholder={!searchEvents ? "Search field" : ""}
              disableIfEmpty
              headerTitle={
                data.appEvents.length
                  ? t("konnect.sidebar.choose_option_header")
                  : t("konnect.sidebar.no_options_available")
              }
              items={data.appEvents}
              renderKey="name"
              selected={data.appEvent ? data.appEvents.find(x => x.id === data.appEvent).name : null}
              onChange={selected => {

                updateScheduleType(selected.name)
                setDays("")
                // setScheduleDate("")

                // setScheduleTime(scheduleTime)

                setDates("")
                setSelect(true)

                dispatch(getAppEventConfig({
                  data,
                  nodeId: data.nodeid,
                  appId: data.id,
                  eventId: selected.id,
                  source: nodeIdx === 0,
                  type: selected.side === "left" ? "source" : selected.side === "right" ? "target" : "both",
                }))
                setEvent(false)
                setSearchEvents("")
              }}
              onChangeState={isOpen => {
                dispatch(setConfigMenuState({ isOpen }))
              }}
            />
          </EventSelectionGroup>
          {hasSequence && data.appEvent && (
            <EventSelectionGroup>
              {/* <SelectionHeader>{"Interval"}</SelectionHeader> */}
              {configForms.map((x, i) => {
                const trigger = data.appEventSequences.find(y => x.sequence === y.sequence)
                switch (x.key_value_type) {
                  case "input":
                    if (x.config_key === "schedular_date") {
                      return (
                        <>
                          {/* <h1>hi</h1> */}
                          <Flex mb={2}>
                            <RebassLabel sx={{ flex: "1 1 0" }}>{"Date"}</RebassLabel>
                          </Flex>
                          <Box
                            tabIndex={-1}
                            sx={{
                              position: "relative",
                              marginTop: "10px",
                              color: "app.primary.30",
                              ":hover, :focus": { color: "app.primary.30" },
                            }}
                          >
                            <Box
                              tabIndex={-1}
                              sx={{
                                position: "relative",
                                color: "app.primary.30",
                                ":hover, :focus": { color: "app.primary.30" },
                              }}
                            >
                              <DatePicker
                                //open={true}
                                onClick={() => setDateOpen(true)}
                                open={dateOpen}
                                onSelect={() => setDateOpen(false)}
                                onChange={(evt, timeString) => {
                                  updateScheduleDate(timeString, evt, x)
                                }}
                                disabledDate={current => {
                                  return moment().add(-1, "days") >= current || moment().add(12, "year") <= current
                                }}
                                value={moment(
                                  data.tested ? scheduleDate : scheduleDate == null ? todayDate : scheduleDate,
                                  "DD/MM/YYYY"
                                )}
                                defaultOpenValue={moment("01/11/2022", "DD/MM/YYYY")}
                                format={"DD/MM/YYYY"}
                                size="large"
                                style={{ width: "100%", marginTop: "1%", antPickerCell: { color: "red" } }}
                                popupClassName="dropdown-class-name-1"
                              />
                            </Box>
                            <Box
                              tabIndex={-1}
                              sx={{
                                position: "relative",
                                marginTop: "10px",
                                color: "app.primary.30",
                                ":hover, :focus": { color: "app.primary.30" },
                              }}
                            >
                              <Flex mb={2}>
                                <RebassLabel sx={{ flex: "1 1 0" }}>{"Time"}</RebassLabel>
                              </Flex>
                              <TimePicker
                                onChange={(evt, timeString) => {
                                  updateScheduleTime(timeString, evt, x)
                                }}
                                // onSelect={() => setOpen(false)}
                                onClick={() => setOpen(true)}
                                onBlur={() => setOpen(false)}
                                open={open}
                                // open={open}
                                //  onOpenChange={setOpen}

                                value={moment(
                                  data.tested ? scheduleTime : scheduleTime == null ? "00:11" : scheduleTime,
                                  "HH:mm"
                                )}
                                // onSelect={time => moment(setScheduleTime(time),"HH:mm")}
                                // placeholder={"00:02"}
                                defaultOpenValue={moment("00:03", "HH:mm")}
                                // defaultValue={moment("00:03","HH:mm")}
                                format={"HH:mm"}
                                size="large"
                                style={{ width: "100%", marginTop: "3%" }}
                              />{" "}
                            </Box>
                          </Box>
                        </>
                      )
                    } else if (x.config_key === "schedule_time") {
                      return (
                        <Box
                          tabIndex={-1}
                          sx={{
                            position: "relative",
                            marginTop: "10px",
                            color: "app.primary.30",
                            ":hover, :focus": { color: "app.primary.30" },
                          }}
                        >
                          <span>{x.label}</span>

                          <TimePicker
                            onChange={(evt, timeString) => {
                              updateScheduleTime(timeString, evt, x)
                            }}
                            // onSelect={() => setOpen(false)}
                            onClick={() => setOpen(true)}
                            onBlur={() => setOpen(false)}
                            open={open}
                            //  open={open}
                            onOpenChange={setOpen}
                            value={moment(
                              data.tested ? scheduleTime : scheduleTime == null ? "00:00" : scheduleTime,
                              "HH:mm"
                            )}
                            // onSelect={time => moment(setScheduleTime(time),"HH:mm")}
                            placeholder={"00:02"}
                            defaultOpenValue={moment("00:03", "HH:mm")}
                            defaultValue={moment("00:03", "HH:mm")}
                            format={"HH:mm"}
                            size="large"
                            style={{ width: "100%", marginTop: "3%" }}
                          />
                        </Box>
                      )
                    } else {
                      return (
                        <>
                          <span>{x.label}</span>
                          <>
                            {" "}
                            {timeSpan == "Minutes" ? (
                              <ConnectorPortInput
                                type="text"
                                placeholder="Enter the value in minutes or hours or days"
                                className="Input"
                                value={text}
                                onChange={e => setText(e.target.value)}
                              />
                            ) : timeSpan == "Hours" ? (
                              <>
                                {" "}
                                <ConnectorPortInput
                                  type="text"
                                  placeholder="Enter the value in minutes or hours or days"
                                  className="Input"
                                  value={text}
                                  onChange={e => setText(e.target.value)}
                                />
                              </>
                            ) : timeSpan == "Days" ? (
                              <>
                                {" "}
                                <ConnectorPortInput
                                  placeholder="Enter the value in minutes or hours or days"
                                  type="text"
                                  className="Input"
                                  value={text}
                                  onChange={e => setText(e.target.value)}
                                  style={{ marginBottom: "10px" }}
                                />{" "}
                                <Flex mb={1}>
                                  <RebassLabel sx={{ flex: "1 1 0" }}>{"Time"}</RebassLabel>
                                </Flex>
                                <TimePicker
                                  onChange={(evt, timeString) => {
                                    updateScheduleTime(timeString, evt, x)
                                  }}
                                  // onSelect={() => setOpen(false)}
                                  onClick={() => setOpen(true)}
                                  onBlur={() => setOpen(false)}
                                  open={open}
                                  //  open={open}
                                  onOpenChange={setOpen}
                                  value={moment(
                                    data.tested ? scheduleTime : scheduleTime == null ? "00:11" : scheduleTime,
                                    "HH:mm"
                                  )}
                                  // onSelect={time => moment(setScheduleTime(time),"HH:mm")}
                                  placeholder={"00:02"}
                                  defaultOpenValue={moment("00:03", "HH:mm")}
                                  defaultValue={moment("00:03", "HH:mm")}
                                  format={"HH:mm"}
                                  size="large"
                                  style={{ width: "100%", marginTop: "3%" }}
                                />
                              </>
                            ) : timeSpan == "Weeks" ? (
                              <>
                                <ConnectorPortInput
                                  placeholder="Enter the value in minutes or hours or days"
                                  type="text"
                                  className="Input"
                                  value={text}
                                  onChange={e => setText(e.target.value)}
                                  style={{ marginBottom: "10px" }}
                                />{" "}
                                <Flex mb={2}>
                                  <RebassLabel sx={{ flex: "1 1 0" }}>{"Days of the Week"}</RebassLabel>
                                </Flex>
                                <Multiselect
                                  id={x.id}
                                  name={x.id}
                                  isObject={false}
                                  onSelect={event => {
                                    setDays(event.toString())
                                    dispatch(onPortTextChange({
                                      data,
                                      nodeId: data.nodeid,
                                      portId: x.id,
                                      config_key: x.config_key,
                                      input: val,
                                    }))
                                  }}
                                  selectedValues={days && days.split(",")}
                                  options={[
                                    "Sunday",
                                    "Monday",
                                    "Tuesday",
                                    "Wednesday",
                                    "Thursday",
                                    "Friday",
                                    "Saturday",
                                  ]}
                                  placeholder="Select"
                                  customCloseIcon={
                                    <>
                                      {" "}
                                      &nbsp; &nbsp; <CrossWhite />
                                    </>
                                  }
                                  style={{
                                    chips: {
                                      background: "rgb(21, 47, 115)",
                                      padding: "6px 10px",
                                      borderRadius: "10px",
                                    },
                                    multiselectContainer: {
                                      color: "rgb(21, 47, 115)",
                                    },

                                    customClose: {
                                      display: "flow-root",
                                      padding: "1px",
                                    },
                                  }}
                                />
                              </>
                            ) : timeSpan == "" ? (
                              <> </>
                            ) : (
                              <> </>
                            )}
                          </>
                        </>
                      )
                    }
                    break
                  case "list":
                    if (x.config_key === "days") {
                      return (
                        <Box
                          tabIndex={-1}
                          sx={{
                            position: "relative",
                            marginTop: "10px",
                            color: "app.primary.30",
                            ":hover, :focus": { color: "app.primary.30" },
                          }}
                        >
                          <Flex mb={2}>
                            <RebassLabel sx={{ flex: "1 1 0" }}>{"Days of the Week"}</RebassLabel>
                          </Flex>
                          <Multiselect
                            id={x.id}
                            name={x.id}
                            isObject={false}
                            onSelect={event => {
                              setDays(event.toString())
                              dispatch(onPortTextChange({
                                data,
                                nodeId: data.nodeid,
                                portId: x.id,
                                config_key: x.config_key,
                                input: val,
                              }))
                            }}
                            options={["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]}
                            selectedValues={days && days.split(",")}
                            placeholder="Select"
                            // disablePreSelectedValues={node.reviewed ? false :true}
                            customCloseIcon={
                              <>
                                {" "}
                                &nbsp; &nbsp; <CrossWhite />
                              </>
                            }
                            style={{
                              chips: {
                                background: "rgb(21, 47, 115)",
                                padding: "6px 10px",
                                borderRadius: "10px",
                              },
                              multiselectContainer: {
                                color: "rgb(21, 47, 115)",
                              },

                              customClose: {
                                display: "flow-root",
                                padding: "1px",
                              },
                            }}
                          />
                        </Box>
                      )
                    } else if (x.config_key === "days_of_the_month") {
                      return (
                        <Box
                          tabIndex={-1}
                          sx={{
                            position: "relative",
                            marginTop: "10px",
                            color: "app.primary.30",
                            ":hover, :focus": { color: "app.primary.30" },
                          }}
                        >
                          <Flex mb={2}>
                            <RebassLabel sx={{ flex: "1 1 0" }}>{"Days of the Month"}</RebassLabel>
                          </Flex>
                          <Multiselect
                            id={x.id}
                            name={x.id}
                            isObject={false}
                            onSelect={event => {
                              setDates(event.toString())
                              dispatch(onPortTextChange({
                                data,
                                nodeId: data.nodeid,
                                portId: x.id,
                                config_key: x.config_key,
                                input: val,
                              }))
                            }}
                            selectedValues={dates && dates.split(",")}
                            // selectedValues={select ? [] : null}
                            options={[
                              "1",
                              "2",
                              "3",
                              "4",
                              "5",
                              "6",
                              "7",
                              "8",
                              "9",
                              "10",
                              "11",
                              "12",
                              "13",
                              "14",
                              "15",
                              "16",
                              "17",
                              "18",
                              "19",
                              "20",
                              "21",
                              "22",
                              "23",
                              "24",
                              "25",
                              "26",
                              "27",
                              "28",
                              "29",
                              "30",
                              "31",
                            ]}
                            placeholder="Select"
                            customCloseIcon={
                              <>
                                {" "}
                                &nbsp; &nbsp; <CrossWhite />
                              </>
                            }
                            style={{
                              chips: {
                                background: "rgb(21, 47, 115)",
                                padding: "6px 10px",
                                borderRadius: "10px",
                              },
                              multiselectContainer: {
                                color: "rgb(21, 47, 115)",
                              },

                              customClose: {
                                display: "flow-root",
                                padding: "1px",
                              },
                            }}
                          />
                        </Box>
                      )
                    } else {
                      return (
                        <Dropdown
                          searchable={true}
                          searchValue={searchEventConfigurations}
                          onFilterChange={text => {
                            setSearchEventConfigurations(text)
                          }}
                          inputPlaceholder={!searchEventConfigurations ? "Search field" : ""}
                          key={i}
                          index={i}
                          headerTitle={trigger ? trigger.name : `${selectedTrigger} ${x.label}`}
                          items={data.appEventConfigurationDetails.filter(c => c.config_key === x.config_key)}
                          renderKey="name"
                          onOpen={() => {
                            const currSequenceIdx = data.appEventConfigurations.findIndex(
                              y => y.sequence === x.sequence
                            )
                            const prevSequence = data.appEventConfigurations[currSequenceIdx - 1]
                            let prevSeq = null
                            if (prevSequence) {
                              prevSeq = data.appEventSequences.find(c => c.sequence === prevSequence.sequence)
                            }
                            dispatch(getAppEventConfigDetail({
                              data,
                              nodeId: data.nodeid,
                              appId: data.id,
                              eventId: data.appEvent,
                              eventConfigId: x.id,
                              source: nodeIdx === 0,
                              config_key: x.config_key,
                              prevSequence: prevSeq,
                              // app_account_id: data.selectedAccount.id,
                            }))
                            setEventSequence(x.sequence ? x.sequence : x.config_id)
                            setEventConfig(true)
                          }}
                          onChange={selected => {
                            setTimeSpan(selected.name)
                            dispatch(setEventsConfig({
                              data,
                              nodeId: data.nodeid,
                              appId: data.id,
                              eventId: data.appEvent,
                              eventConfigId: eventSequence,
                              source: nodeIdx === 0,
                              sequence: {
                                sequence: eventSequence,
                                id: selected.id,
                                name: selected.name,
                                config_key: selected.config_key,
                              },
                              selectedEventConfigId: selected.id,
                              selectedEventConfigName: selected.name,
                            }))

                            if (
                              data.appEventConfigurations.findIndex(x => x.sequence === eventSequence) ===
                              data.appEventConfigurations.length - 1
                            ) {
                              dispatch(getAppEventConfigDetailFetch({
                                data,
                                nodeId: data.nodeid,
                                appId: data.id,
                                eventId: data.appEvent,
                                eventConfigId: selected.id,
                                source: nodeIdx === 0,
                                selectedEventConfigId: selected.id,
                                selectedEventConfigName: selected.name,
                                selectedConfigKey: selected.config_key,
                                //app_account_id: data.selectedAccount.id,
                              }))
                            }
                            setSearchEventConfigurations("")
                            // setEventConfig(false)
                          }}
                        />
                      )
                    }
                    break
                }
              })}
            </EventSelectionGroup>
          )}
          {formElements.length ? (
            <EventSelectionGroup>
              {data.configResponses ? (
                <>
                  <SelectionHeader></SelectionHeader>

                  <TestAndReviewButton
                 
                    onClick={() => {
                      
                     dispatch(appTestAndReview({
                        data,
                        nodeIdx: nodeIdx,
                        editorState: editorState,
                        schedulerData: getSchedulerData(),
                        enableTestReviewLoader,

                        dispatch
                      }))
                    }}
                  >
                    Test and Review
                  </TestAndReviewButton>

                  {nodeTested ? <> {formElements.map(form => form)} </> : null}
                </>
              ) : null}
              {nodeTested ? <SelectionHeader>{t("konnect.sidebar.configure_response")}</SelectionHeader> : null}
              {/* {formElements.map(form => form)} */}
            </EventSelectionGroup>
          ) : null} 
        </>
      </NodeOuter>
      {data.additionalResponses && data.additionalResponses.length ? (
        <AddMoreFieldButton onClick={handleAdditionalConfigsClick}></AddMoreFieldButton>
      ) : null}
      <Modal
        open={openAdditionalConfigs}
        onClose={onCloseAdditionalConfigsModal}
        onAnimationEnd={() => scrollTo(0, 0)}
        center
        styles={{ modal: { padding: "0px", borderRadius: "16px", minWidth: "1100px", minHeight: "700px" } }}
      >
        <AdditionalConfigsModal
          closeModal={onCloseAdditionalConfigsModal}
          addedKeys={addedKeys}
          data={data.additionalResponses}
          appIcon={data.image}
        />
      </Modal>
    </>
  )
})
