/**
 * Password set page.
 */
import React from "react"
import { Box, Text, Flex } from "rebass"
import { faEye, faLock, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { Input, Button } from "src/components"
import { useTranslation } from "react-i18next"
import { ToastContainer, toast } from "react-toastify"
import { isValidPassword } from "src/utils"
import px2vw from 'utils/px2vw'
import { AuthTitle, AuthSubtitle } from 'src/components/Typography/Typography'
import PasswordResetSVG from "src/assets/images/password-reset"

export const CreatePasswordView = ({ title, subTitle, state, loading, onContinueClick }) => {
  const [showConfirmPassword, setShowConfirmPassword] = state.showConfirmPassword
  const [showPassword, setShowPassword] = state.showPassword
  const [password, setPassword] = state.password
  const [passwordError, setPasswordError] = state.passwordError
  const [confirmPasswordError, setConfirmPasswordError] = state.confirmPasswordError
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = state.confirmPasswordErrorMsg
  const [confirmPassword, setConfirmPassword] = state.confirmPassword

  function fishEyeIconClick(e) {
    e.preventDefault()
    setShowPassword(!showPassword)
  }

  function toggleShowConfirmPassword(e) {
    e.preventDefault()
    setShowConfirmPassword(!showConfirmPassword)
  }

  function onPasswordChange(e) {
    setPasswordError(isValidPassword(e.currentTarget.value) ? false : true)
    if (e.currentTarget.value === confirmPassword) {
      setConfirmPasswordError(false)
    }
    setConfirmPasswordErrorMsg((e.currentTarget.value !== confirmPassword) && confirmPassword !== '' ? "auth.signup.createPassword.passwordMatchingErrorMsg" : "auth.signup.createPassword.confirmPasswordErrorMsg")
    setPassword(e.currentTarget.value)
  }

  function onConfirmPasswordChange(e) {
    setConfirmPasswordError(isValidPassword(e.currentTarget.value) && (password === e.currentTarget.value) ? false : true)
    setConfirmPasswordErrorMsg((password !== e.currentTarget.value) && e.currentTarget.value !== '' ? "auth.signup.createPassword.passwordMatchingErrorMsg" : "auth.signup.createPassword.confirmPasswordErrorMsg")
    setConfirmPassword(e.currentTarget.value)
  }

  // Password set submit handler.
  function submit() {
    setPasswordError(isValidPassword(password) ? false : true)

    setConfirmPasswordErrorMsg((password !== confirmPassword) && confirmPassword !== '' ? "auth.signup.createPassword.passwordMatchingErrorMsg" : "auth.signup.createPassword.confirmPasswordErrorMsg")

    setConfirmPasswordError(isValidPassword(confirmPassword) && (password === confirmPassword) ? false : true)

    if (isValidPassword(password) && isValidPassword(confirmPassword) && (password === confirmPassword)) {
      onContinueClick()
    }
  }

  const { t } = useTranslation()

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <AuthSubtitle>{t(subTitle)}</AuthSubtitle>
      </Box>
      <Box
        className="border-gradient"
        sx={{
          width: '121px',
          height: "0px",
          margin: "0px auto",
          mb: px2vw(10),
          mt: px2vw(5),
        }}
      />
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <AuthTitle>{t(title)}</AuthTitle>
      </Box>
      <Box sx={{ mt: px2vw(20), textAlign: "center", justifyContent: 'center' }}>
        <PasswordResetSVG />
      </Box>
      <Flex flexDirection="column" sx={{ height: "100%", justifyContent: 'center' }}>
        <Box
          sx={{
            pt: "0px",
            mx: px2vw(18),
          }}
        >
          <Input
            name="createPassword"
            type={showPassword ? "text" : "password"}
            sxProps={{
              "& ~ span": { color: "#B7CBFF !important" },
            }}
            defaultValue={password}
            setError={passwordError}
            errorMessage={t(state.passwordErrorMsg)}
            onChange={onPasswordChange}
            required
            icon={showPassword ? faEyeSlash : faEye}
            iconClick={fishEyeIconClick}
            iconLeft={faLock}
            placeholder={t("auth.signup.createPassword.createPasswordPlaceholder")}
          />
        </Box>
        <Box
          sx={{

            mx: px2vw(18),
          }}
        >
          <Input
            name="confirmPassword"
            type={showConfirmPassword ? "text" : "password"}
            sxProps={{
              "& ~ span": { color: "#B7CBFF !important" },
            }}
            defaultValue={confirmPassword}
            setError={confirmPasswordError}
            errorMessage={t(confirmPasswordErrorMsg)}
            onChange={onConfirmPasswordChange}
            required
            icon={showConfirmPassword ? faEyeSlash : faEye}
            iconClick={toggleShowConfirmPassword}
            iconLeft={faLock}
            placeholder={t("auth.signup.createPassword.confirmPasswordPlaceholder")}
          />
        </Box>

        <Box
          sx={{
            mt: px2vw(20),
            mx: px2vw(20),
            px: "10px",
            textAlign: "left",
            borderRadius: px2vw(24)
          }}
        >
          <Text sx={{ fontSize: "xxs", fontWeight: "600", color: "app.secondary.20" }}>
            {t("auth.signup.createPassword.passwordRule")}
          </Text>
          {/* <Text sx={{ fontSize: "xxs", fontWeight: "600", color: "app.secondary.20" }}>
            {t("auth.signup.createPassword.passwordRule2")}
          </Text>
          <Text sx={{ fontSize: "xxs", fontWeight: "600", color: "app.secondary.20" }}>
            {t("auth.signup.createPassword.passwordRule3")}
          </Text>
          <Text sx={{ fontSize: "xxs", fontWeight: "600", color: "app.secondary.20" }}>
            {t("auth.signup.createPassword.passwordRule4")}
          </Text> */}
        </Box>

        <Button
          height={{ _: 40, sm: 40, md: 40, lg: 40, xl: 60 }}
          fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }}
          sx={{ borderRadius: '0px', mt: px2vw(35), mx: px2vw(18), fontStyle: "normal" }}
          stretch
          data-tracking="click"
          data-category="Login"
          data-action="User login click"
          disabled={loading}
          onClick={submit}
        >
          {t("auth.signup.createPassword.continueBtnLabel")}
        </Button>
      </Flex>

      <Flex flexDirection="column" sx={{ flexGrow: 1, justifyContent: "flex-end", alignItems: "center" }}>
        {/* <Box sx={{ mt: px2vw(40), pt: "0px", textAlign: "center" }}>
          <Text className="auth-footer-text-2" sx={{ display: "inline-block", fontSize: ["xs", "sm", "md"], fontStyle: "normal", color: 'app.secondary.20' }}>
            {t("auth.signup.alreadyHaveAccountText")}
            <a href="/login" style={{ color: "#F7C545" }}>
              {t("auth.signup.loginBtnLabel")}
            </a>
          </Text>
        </Box> */}
        {/* <Box sx={{ mt: px2vw(21), pt: "0px", textAlign: "center" }}>
          <Text fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }} sx={{ display: "inline-block", fontStyle: "normal", color: 'app.secondary.20' }}>
            {t("auth.login.clickingContinueText")}
            <a href="/signup" style={{ color: "#F7C545" }}>
              {t("auth.login.termsAndConditionsText")}
            </a>
          </Text>
        </Box> */}
      </Flex>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  )
}
