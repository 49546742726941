import React from 'react';
import { connect } from "react-redux"
import { updateGsheetPop } from "src/store/actions"
import { fromJS } from "immutable"

const ChildWindow_ = ({
        gSheetPopUp,
        updateGsheetPop,
        scriptId
}) => {
        if (gSheetPopUp) {

                // updateGsheetPop(true)
                const name = "OAuth Permissions"
                const opts = `dependent=${1}, alwaysOnTop=${1}, alwaysRaised=${1}, alwaysRaised=${1}, width=800, height=600`
                const browser = window.self
                const popup = browser.open(`https://accounts.google.com/AccountChooser?continue=https://script.google.com/home/projects/${scriptId}/triggers`, name, opts)

                var timer = setInterval(function () {
                        if (popup.closed) {
                                clearInterval(timer);
                                updateGsheetPop(false)
                        }
                }, 1000);
        }
        return <></>

}
const mapStateToProps = state => {
        return {
                gSheetPopUp: state.getIn(["konnect", "gSheetPopUp"]),
        }
}

const mapDispatchToProps = dispatch => {
        return {
                updateGsheetPop: (data) => dispatch(updateGsheetPop(data)),
        }
}

export const ChildWindow = connect(mapStateToProps, mapDispatchToProps)(ChildWindow_)
