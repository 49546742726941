/**
 * Login container component to handle states & api interactions.
 */

import React, { useState, useEffect, useRef } from "react"
import { login } from "src/store/actions/appActions"
import { connect } from "react-redux"

import { LoginView } from "./Login.view"
import AuthShellContainer from "src/features/auth/shell/Shell.container"
import { useTranslation } from "react-i18next"
import { ToastContainer, toast } from "react-toastify"
import loginFeatures from "src/config/features/login-features"
import AuthTokenService from "utils/AuthTokenService"

function LoginContainer(props) {
  const state = {
    email: useState(""),
    emailError: useState(false),
    emailErrorMsg: "auth.login.emailInvalid",
    password: useState(""),
    passwordError: useState(false),
    passwordErrorMsg: "auth.login.passwordRequired",
    showPassword: useState(false),
    rememberMe: useState(false), // TODO: persist this setting
    captcha: useState(false),
  }

  const [loading, setLoading] = useState(false)

  const { t, i18n } = useTranslation()
  const { login } = props

  useEffect(() => {
    if (props.route === "APP_LOGOUT") {
      AuthTokenService.clear()
      props.history.push("/login")
    }
    if (AuthTokenService.get()) {
      props.history.push("/dashboard")
      localStorage.setItem("selected-route", "/dashboard")
    } else props.history.push("/login")
  }, [])

  // Login function to invoke the login api
  const onLogin = () => {
    setLoading(true)
    login({ email: state.email[0], password: state.password[0] }).then(res => {
      setLoading(false)
      if (res.type === "AUTH_LOGIN_FAIL") {
        toast.error(t("auth.login.loginFailMsg"))
      } else if (res.type === "AUTH_LOGIN_SUCCESS") {
        AuthTokenService.storeToken({
          token: res.payload.auth_token,
          keepmeLoggedIn: state.rememberMe[0],
          lang: res.payload.preferred_language,
        })
        i18n.changeLanguage(res.payload.preferred_language)
        localStorage.setItem("selected-route", "/dashboard")
        props.history.push("/dashboard")
      }
    })
  }

  return (
    <>
      <AuthShellContainer {...props} features={loginFeatures.features}>
        <LoginView loading={loading} onSubmit={onLogin} state={state} {...props} />
      </AuthShellContainer>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        closeButton={false}
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  )
}

const mapStateToProps = state => {
  return { user: state.getIn(["app", "user"]) }
}

const mapDispatchToProps = dispatch => {
  return {
    login: creds => dispatch(login(creds)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
