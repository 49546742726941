//common
export const SUCCESS = "_SUCCESS"
export const FAIL = "_FAIL"

export const OAUTH_REDIRECT_URL = process.env.APP_BASE_URL + "oauth/response"
// export const OAUTH_REDIRECT_URL = window.location.origin + "/oauth/response"

// Dashboard Action types
export const GET_ACTIVE_WIDGETS = "GET_ACTIVE_WIDGETS"
export const GET_WIDGET_DETAILS = "GET_WIDGET_DETAILS"

// Billing Action types
export const GET_PLANS_LIST = "GET_PLANS_LIST"
export const GET_REDEEMED_COUPONS = "GET_REDEEMED_COUPONS"
export const REDEEM_CODE = "REDEEM_CODE"

// Team Action types
export const GET_TEAM_USERS_LIST = "GET_TEAM_USERS_LIST"
export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER"
export const UPDATE_TEAM_MEMBER = "UPDATE_AGENCY_MEMBER"
export const DELETE_TEAM_MEMBER ="DELETE_AGENCY_MEMBER"
// Folders types
export const GET_FOLDERS_LIST = "GET_FOLDERS_LIST"
export const GET_FOLDER_KONNECTS = "GET_FOLDER_KONNECTS"
export const CREATE_FOLDER = "CREATE_FOLDER"
export const MOVE_KONNECTS_TO_FOLDER = "MOVE_KONNECTS_TO_FOLDER"
export const RENAME_FOLDER = "RENAME_FOLDER"
export const DELETE_FOLDER = "DELETE_FOLDER"

// Agency Action types
export const GET_AGENCY_USERS_LIST = "GET_AGENCY_USERS_LIST"
export const ADD_AGENCY_MEMBER = "ADD_AGENCY_MEMBER"
export const UPDATE_AGENCY_MEMBER = "UPDATE_AGENCY_MEMBER"
export const DELETE_AGENCY_MEMBER ="DELETE_AGENCY_MEMBER"

// Reseller Action types
export const GET_RESELLER_USERS_LIST = "GET_AGENCY_USERS_LIST"
export const ADD_RESELLER_MEMBER = "ADD_AGENCY_MEMBER"
export const UPDATE_RESELLER_MEMBER = "UPDATE_AGENCY_MEMBER"
export const DELETE_RESELLER_MEMBER ="DELETE_AGENCY_MEMBER"

// Task Logs Action types
export const GET_TASK_LOGS = "GET_TASK_LOGS"
export const GET_LOGS = "GET_LOGS"
export const GET_TASK_DETAILS = "GET_TASK_DETAILS"
export const QUERY_APP = "QUERY_APP"
export const TASKS_PAGE_SIZE = 10
// App Action types
export const AUTH_LOGIN = "AUTH_LOGIN"
export const AUTH_SIGN_UP = "AUTH_SIGN_UP"
export const AUTH_USER_EXISTS = "AUTH_USER_EXISTS"
export const VERIFY_EMAIL = "VERIFY_EMAIL"
export const UPDATE_USER = "UPDATE_USER"
export const GET_FORGOT_PASSWORD_LINK = "GET_FORGOT_PASSWORD_LINK"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const GET_AUTHORISED_APPS = "GET_AUTHORISED_APPS"
export const GET_ALL_APPS = "GET_ALL_APPS"
export const GET_ADDON_APPS = "GET_ADDON_APPS"
export const GET_KONNECTS_LIST = "GET_KONNECTS_LIST"
export const UPDATE_KONNECT_STATUS = "UPDATE_KONNECT_STATUS"
export const GET_APP_EVENTS = "GET_APP_EVENTS"
export const GET_ASSOCIATED_ACCOUNTS = "GET_ASSOCIATED_ACCOUNTS"
export const SET_LINKED_ACCOUNT = "SET_LINKED_ACCOUNT"
export const GET_EVENT_CONFIGURATIONS = "GET_EVENT_CONFIGURATIONS"
export const GET_APP_EVENT_CONFIGURATIONS_DETAILS = "GET_APP_EVENT_CONFIGURATIONS_DETAILS"
export const SET_APP_EVENT_CONFIG_VALUE = "SET_APP_EVENT_CONFIG_VALUE"
export const GET_EVENT_CONFIGURATIONS_DETAILS = "GET_EVENT_CONFIGURATIONS_DETAILS"
export const GET_EVENT_CONFIGURATIONS_DETAILS_FETCH = "GET_EVENT_CONFIGURATIONS_DETAILS_FETCH"
export const GET_EDITOR_STATE = "GET_EDITOR_STATE"
export const SET_EDITOR_STATE = "SET_EDITOR_STATE"
export const GET_KONNECT_NAME = "GET_KONNECT_NAME"
export const SET_KONNECT_NAME = "SET_KONNECT_NAME"
export const GET_CONDITIONS_LIST = "GET_CONDITIONS_LIST"
export const TOKEN_EXPIRY = 30
export const KONNECT_FLOW_DRAGGABLE = "KONNECT_FLOW_DRAGGABLE"
export const ONDRAGNODE = "ONDRAGNODE"
export const ONDRAGNODESTART = "ONDRAGNODESTART"
export const ONDRAGNODESTOP = "ONDRAGNODESTOP"
export const EXPANDCOLLAPSENODE = "EXPANDCOLLAPSENODE"
export const ONCANVASDROP = "ONCANVASDROP"
export const ONLINKSTART = "ONLINKSTART"
export const ONLINKMOVE = "ONLINKMOVE"
export const ONLINKCOMPLETE = "ONLINKCOMPLETE"
export const ONLINKCANCEL = "ONLINKCANCEL"
export const ONPORTPOSITIONCHANGE = "ONPORTPOSITIONCHANGE"
export const ONLINKMOUSEENTER = "ONLINKMOUSEENTER"
export const ONLINKMOUSELEAVE = "ONLINKMOUSELEAVE"
export const ONLINKCLICK = "ONLINKCLICK"
export const ONCANVASCLICK = "ONCANVASCLICK"

export const ONNODECLICK = "ONNODECLICK"
export const ONNODEMOUSEENTER = "ONNODEMOUSEENTER"
export const ONNODEMOUSELEAVE = "ONNODEMOUSELEAVE"
export const ONNODESIZECHANGE = "ONNODESIZECHANGE"
export const ONPORTTEXTCHANGE = "ONPORTTEXTCHANGE"
export const SAVEKONNECT = "SAVEKONNECT"
//to be removed later
export const GET_AUTH_URL = "GET_AUTH_URL"
export const SAVE_AUTH = "SAVE_AUTH"
export const GET_APP_DETAILS = "GET_APP_DETAILS"
export const UPDATE_APP_STATUS = "UPDATE_APP_STATUS"
export const SAVE_LABEL = "SAVE_LABEL"

export const TOGGLESIDEBAR = "TOGGLESIDEBAR"
export const EDITORSCALE = "EDITORSCALE"

export const EDITORUNDO = "EDITORUNDO"
export const EDITORREDO = "EDITORREDO"
export const CLEARSTATE = "CLEARSTATE"

export const ONDRAGCANVASSTOP = "ONDRAGCANVASSTOP"
export const ONDRAGCANVAS = "ONDRAGCANVAS"
export const GETKONNECT = "GETKONNECT"
export const BASIC_AUTH = "BASIC_AUTH"
export const CAPTUREWEBHOOKRESPONSE = "CAPTUREWEBHOOKRESPONSE"
export const ONWEBHOOKAUTHCHANGE = "ONWEBHOOKAUTHCHANGE"
export const ONDELETENODE = "ONDELETENODE"
export const ONSETASFIRSTNODE = "ONSETASFIRSTNODE"
export const ONDELETELINK = "ONDELETELINK"
export const MARKSTOREDIRTY = "MARKSTOREDIRTY"
export const APPTESTANDREVIEW = "APPTESTANDREVIEW"

export const GET_PROFILE = "GET_PROFILE"
export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const CREATEADDITIONALFIELDS = "CREATEADDITIONALFIELDS"
export const SET_CONFIG_MENU_STATE = "SET_CONFIG_MENU_STATE"
export const ONDELETENODEV2 = "ONDELETENODEV2"
export const MODIFYCONDITIONS = "MODIFYCONDITIONS"

export const MODIFY_HTTP_METHOD = "MODIFY_HTTP_METHOD"
export const MODIFY_URL_ENDPOINT = "MODIFY_URL_ENDPOINT"
export const MODIFY_PAYLOAD_TYPE = "MODIFY_PAYLOAD_TYPE"
export const MODIFY_WRAP_REQUEST_IN_ARRAY = "MODIFY_WRAP_REQUEST_IN_ARRAY"
export const MODIFY_HEADERS_AND_PARAMS = "MODIFY_HEADERS_AND_PARAMS"

export const MODIFY_DURATION = "MODIFY_DURATION";
export const MODIFY_EXPIRY_NUM = "MODIFY_EXPIRY_NUM";

export const MODIFY_SEGMENT_INDEX = "MODIFY_SEGMENT_INDEX";

export const UPDATE_GSHEET_POP_UP = "UPDATE_GSHEET_POP_UP"

export const ENABLE_TEST_REVIEW_LOADER = "ENABLE_TEST_REVIEW_LOADER"
