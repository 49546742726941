import { makeRequestActionCreator } from 'utils/redux'
import { GET_FOLDERS_LIST, GET_FOLDER_KONNECTS, CREATE_FOLDER, MOVE_KONNECTS_TO_FOLDER, RENAME_FOLDER, DELETE_FOLDER } from 'config/Constants'

export const getFoldersList = () => dispatch => {
    let url = "api/v1/folders/list"
    
    const request = makeRequestActionCreator(GET_FOLDERS_LIST)
    return dispatch(
      request({
        url: url,
        method: "GET",
      })
    )
  }

  export const getKonnectsByFolderId = (id) => dispatch => {
    let url = "api/v1/folders/" + id + "/konnects"
    
    const request = makeRequestActionCreator(GET_FOLDER_KONNECTS)
    return dispatch(
      request({
        url: url,
        method: "GET",
      })
    )
  }

  export const createFolder = (folderName) => dispatch => {
    let url = "api/v1/folders/add"
    
    const request = makeRequestActionCreator(CREATE_FOLDER)
    return dispatch(
      request({
        url: url,
        data: {
          folder_name: folderName
        },
        method: "POST",
      })
    )
  }

  export const moveKonnectsToFolder = (folderId, konnects) => dispatch => {
    let url = "api/v1/folders/" + folderId + "/assign"
    
    const request = makeRequestActionCreator(MOVE_KONNECTS_TO_FOLDER)
    return dispatch(
      request({
        url: url,
        data: {
          konnects: konnects.toString()
        },
        method: "POST",
      })
    )
  }

  export const renameKonnectFolder = (folderId, folderName) => dispatch => {
    let url = "api/v1/folders/" + folderId + "/rename"
    
    const request = makeRequestActionCreator(RENAME_FOLDER)
    return dispatch(
      request({
        url: url,
        data: {
          folder_name: folderName
        },
        method: "PUT",
      })
    )
  }
  
  export const deleteKonnectFolder = folderId => dispatch => {
    let url = "api/v1/folders/" + folderId + "/remove"
    
    const request = makeRequestActionCreator(DELETE_FOLDER)
    return dispatch(
      request({
        url: url,
        method: "GET",
      })
    )
  }

