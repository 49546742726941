import React, { useState } from "react"
import { Box, Text, Flex, Link } from "rebass"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw"
import { Button, Input, Label } from "src/components/index"
import { Icon } from "src/components"
import Select from "react-select"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import Check from "../../assets/images/check.svg"

export const UpdateResellerMemberModal = ({ users,userId, onUpdatingResellerMember }) => {
  const { t } = useTranslation()
  const user = users.find((t) => t.id === userId)
  const [firstName, setFirstName] = useState(user.first_name)
  const [lastName, setLastName] = useState(user.last_name)
  const [email, setEmail] = useState(user.email)
  const [tasks, setTasks] = useState(user.task)

  return (
    <>
     <Flex flexDirection="column" sx={{  borderRadius: "16px", px: px2vw(60), padding: "0px" ,minWidth: "500px"}}>
      <Flex flexDirection="row" sx={{ borderRadius: "16px", px: px2vw(20), padding: "0px" }}>
          <Box sx={{ p: px2vw(8), justifyContent: "left", textAlign: "left" }}>
            {/* <img width="20px" height="20px" src={CreateFolderImg} /> */}
          </Box>
          <Text
            fontSize={[20]}
            fontWeight="semi-bold"
            color="app.primary.30"
            textAlign="left"
            sx={{ pt: 2.5, justifyContent: "left", textAlign: "left", mr: px2vw(3) }}
          >
            Add New Sub-Account
          </Text>
          
        </Flex>
        <Box sx={{ background: "#BCC6CC", width: "inherit", height: "1px", margin: "14px -20px" }}></Box>
        <Box sx={{ color: "#000000", p: px2vw(10) }}>
          <Input
            label={t("account.agency.firstName")}
      
            sxProps={{
              height: "40px !important",
              backgroundColor: "#E6EDFF",
              border: "1px solid #E6EDFF",
              borderRadius: "18px",
              color:"#152F73"
            }}
            type={"text"}
            value={firstName}
            defaultValue={user.first_name}
            onChange={e => {
              setFirstName(e.currentTarget.value)
            }}
            setError={Boolean(firstName === "")}
            errorMessage={"Field should not be empty!"}
          />
          <Input
            label={t("account.agency.lastName")}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#E6EDFF",
              border: "1px solid #E6EDFF",
              borderRadius: "18px",
              color:"#152F73"
            }}
            type={"text"}
            value={lastName}
            derfaultValue={user.last_name}
            onChange={e => {
              setLastName(e.currentTarget.value)
            }}
            setError={Boolean(lastName === "")}
            errorMessage={"Field should not be empty!"}
          />
          <Input
            label={t("account.agency.email")}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#E6EDFF",
              border: "1px solid #E6EDFF",
              borderRadius: "18px",
              color:"#152F73"
            }}
            type={"text"}
            value={email}
            defaultValue={user.email}
            onChange={e => {
              setEmail(e.currentTarget.value)
            }}
            setError={Boolean(email === "")}
            errorMessage={"Field should not be empty!"}
          />
          <Input
            label={t("account.agency.tasks")}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#E6EDFF",
              border: "1px solid #E6EDFF",
              borderRadius: "18px",
              color:"#152F73"
            }}
            type={"text"}
            value={tasks}
            defaultValue={user.task}
            onChange={e => {
              setTasks(e.currentTarget.value)
            }}
            setError={Boolean(tasks === "")}
            errorMessage={"Field should not be empty!"}
          />
        </Box>
        <Box sx={{ p: px2vw(10), mt: 20 }}>
          <Flex flexDirection="row" sx={{ justifyContent: "center" }}>
            <Box>
         
              

                <Box    onClick={onAddBtnClick} sx={{ cursor: "pointer", m: "1px 0 0 10px",display:"flex",alignItems:"center",bg:"#F7C546",color:"#152F73",p:"3px 10px 3px 3px",borderRadius:"10px" }}><span><Check style={{margin:"10px 12px 5px 5px"}}/></span><span>Add Sub-account</span></Box>
            
             
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}
