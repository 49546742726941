import React from "react"
import { Checkbox as RebassCheckbox } from "@rebass/forms"

export const Checkbox = ({ ...props }) => (
  <RebassCheckbox
    sx={{
      m: "0px",
      color: "app.primary.80",
      ":hover": {
        color: "app.primary.30",
        "input:checked": {
          color: "app.primary.30",
        },
      },
    }}
    {...props}
  />
)
