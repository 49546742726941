import React, { useState } from "react"
import { Box, Text, Flex, Link } from "rebass"
import { Input, Button, Icon } from "src/components"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw"
import AccessAccountSvg from "src/assets/images/access-account"
import MoveFolderIcon from "src/assets/images/Move_folder_icon.svg"
import SmallFolderIcon from "src/assets/images/Small_folder_icon.svg"
import { faFolder, faCheckCircle } from "@fortawesome/free-solid-svg-icons"
export const MoveFolderModal = ({ closeModal, moveFolder, foldersList }) => {
  const { t } = useTranslation()
  const [selectedFolder, setSelectedFolder] = useState(null)
  const [select,setSelect]=useState(true)

  const filterKonnectsByFolderId = (folderId, event) => {
    let folderBtnElements = Array.from(document.getElementsByClassName("move-folder-btn"))
    folderBtnElements.map(element => {
      if (event.currentTarget !== element) {
        element.style.backgroundColor = "#FFFFFF"
        element.style.color = "#827E7E"
      }
    })
    if (event.currentTarget.style.backgroundColor === "rgb(21, 47, 115)") {
      event.currentTarget.style.backgroundColor = "#DBE5FF"
      event.currentTarget.style.color = "#152F73"
      event.currentTarget.style.fill = "#152F73"
      setSelect(true)
     
    } else {
      event.currentTarget.style.backgroundColor = "#152F73"
      event.currentTarget.style.color = "#FFFFFF"
      event.currentTarget.style.fill = "#FFFFFF"
     setSelect(false)
    }


    setSelectedFolder(folderId)
  }

  const moveKonnectToFolder = () => {
    let folderBtnElements = Array.from(document.getElementsByClassName("folder-btn"))
    folderBtnElements.map(element => {
      if (event.currentTarget !== element) {
        element.style.backgroundColor = "#FFFFFF"
        element.style.color = "#827E7E"
      }
    })
    moveFolder(selectedFolder)
  }

  return (
    <>
      <Flex flexDirection="column" sx={{ borderRadius: "16px", px: px2vw(50), padding: "0px", minWidth: "500px" }}>
        <Flex flexDirection="row" sx={{ borderRadius: "16px", px: px2vw(20), padding: "0px" }}>
          <Box
            sx={{
              p: "8px 3px 0px 8px",
              m: "2px",
              justifyContent: "left",
              textAlign: "left",
              boxShadow: "1px 1px 1px 1px #e6e2e2",
              borderRadius: "5px",
            }}
          >
            <MoveFolderIcon width="20px" height="20px" />
          </Box>
          <Text
            fontSize={[20]}
            fontWeight="semi-bold"
            color="app.primary.30"
            textAlign="left"
            sx={{ pb: 4.5, justifyContent: "left", textAlign: "left", ml: px2vw(6), ml: "12px" }}
          >
            Move Konnectz
          </Text>
        </Flex>
        <Box sx={{ background: "#BCC6CC", width: "inherit", height: "1px", margin: "14px -20px" }}></Box>
        <Box sx={{ p: px2vw(20), justifyContent: "center", textAlign: "center" }}>
          <MoveFolderIcon width="90px" height="70px" />
        </Box>
        <Box sx={{ p: px2vw(20), justifyContent: "center", textAlign: "center", }}>
          <Text
            sx={{
              fontSize: "18px",
              color: "app.primary.30",
              fontWeight: "semibold",
              textAlign: "center",
              width: "70%",
              margin: "auto",
            }}
          >
            {t("konnectzList.folders.selectFolderInfo")}
          </Text>
        </Box>
        <Flex sx={{ width: "100%", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
          {foldersList &&
            foldersList.length > 0 &&
            foldersList.map(folder => (
              <Box key={folder.id} sx={{ width: "auto" }}>
                <Button
                  onClick={event => filterKonnectsByFolderId(folder.id, event)}
                  sx={{
                    p: px2vw(10),
                    boxShadow: "none",
                    backgroundColor: "app.primary.120",
                    display: "flex",
                    color: "app.primary.30",
                    "&:hover": {
                      color: "#ffffff",
                      fill: "#ffffff",
                    },

                    m: "20px 10px 20px 10px",
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  <SmallFolderIcon className="svg" />

                  <Box sx={{ cursor: "pointer", ml: "10px" }}>{folder.name}</Box>
                </Button>
              </Box>
            ))}
        </Flex>
        <Box sx={{ p: px2vw(10) }}>
          <Flex flexDirection="row" sx={{ justifyContent: "center", mb: "10px" }}>
            <Box sx={{ textAlign: "center", justifyContent: "center" }}>
              <Button
                disabled={select}
                variant="primary"
                fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                sx={{
                  backgroundColor: "app.secondary.110",
                  boxShadow: "none",
                  fontWeight: "normal",
                  cursor: "pointer",
                  color: "app.primary.30",
                  display: "flex",
                }}
                onClick={moveKonnectToFolder}
              >
                {" "}
                <Icon icon={faCheckCircle} pt="3px" />
                <Box sx={{ cursor: "pointer", m: "1px 0 0 10px" }}>{t("konnectzList.folders.moveBtn")}</Box>
              </Button>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}