import { createRef } from "react"
import { createSlice } from "../../../../../node_modules/@reduxjs/toolkit/dist"

const initialState = {
  sidebarContainer: createRef(),
  topbarContainer: createRef(),
}
export const constantSlice = createSlice({
  name: "constant",
  initialState,
  reducers: {
    updateSidebarRef: (state, action) => {
      state.sidebarContainer.current = action.payload
    },
    updateTopbarRef: (state, action) => {
      state.topbarContainer.current = action.payload
    },
  },
})

export const { updateTopbarRef, updateSidebarRef } = constantSlice.actions

export default constantSlice.reducer
