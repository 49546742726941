/**
 * Features common component.
 */
import React from "react"
import { Flex, Box, Text } from "rebass"
import { FeatureTitle, FeatureDesc } from "src/components/Typography/Typography"
import { useTranslation } from "react-i18next"
import px2vw, { px2px } from 'utils/px2vw'


export const FeaturesView = ({ feature, mb }) => {
  const { t } = useTranslation()
  return (
    <Flex sx={{ flexDirection: 'column', px: [0, px2vw(10), px2vw(10)] }}>
      <Flex
        sx={{
          minHeight: '300px',
          m: ["auto", "auto", "auto"],
          mb: [px2vw(10), px2vw(10), px2vw(10)],
          borderRadius: px2vw(24),
          // width: '100%',
          alignItems: 'flex-end'
        }}
      >
        <img width="100%" src={t(feature.img)} />
      </Flex>
      <Flex
        sx={{ flexDirection: "column", mx: [px2vw(10), px2vw(28)], mb: '20px', mt: '20px' }}
        justifyContent={["center", "center", "center"]}
        alignItems={["center", "center", "center"]}
      >
        <FeatureTitle>{t(feature.title)}</FeatureTitle>
        <FeatureDesc>{t(feature.description)}</FeatureDesc>
      </Flex>
    </Flex>
  )
}
