import React, { useState, useEffect } from "react"
import { Box, Text, Flex, Link } from "rebass"
import { useTranslation } from "react-i18next"
import { Checkbox, Label } from "@rebass/forms"
import px2vw from "utils/px2vw"
import DeleteIconSvg from "src/assets/images/deleteIcon"
import { Button } from "src/components/index"
import "./styles.css"
import { Icon } from "src/components"
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons"
export const AdditionalConfigsModal = ({ closeModal, addedKeys = [], data = [], appIcon }) => {
  const { t } = useTranslation()
  const [list, setList] = useState([])
  const [a, setA] = useState(null)

  const [showValue, setShowValue] = useState("")
  const [searchValue, setSearchValue] = useState("")
  const [expandedSection, setExpandedSection] = useState([])
  const [checked, setChecked] = useState([])

  const allTrue = list.every(item => Boolean(item.selected))
  useEffect(() => {
    let listData = data.map(item => {
      const l = addedKeys.findIndex(y => y.uiKey === item.uiKey)
      const m = addedKeys.findIndex(y => y.selected === true)
      return { ...item, selected: l > -1 }
    })
    setList(listData)
    return () => {
      setList([])
    }
  }, [data])

  const handleCheckAllChange = e => {
    if (e.target.checked) {
      const updatedData = list.map(item => ({ ...item, selected: true }))
      const allCountries = list.map(c => c.label)

      setChecked(updatedData)
      setList(updatedData)
    } else {
      setChecked([])
      const updatedData1 = list.map(item => ({ ...item, selected: false }))
      setList(updatedData1)
    }
  }
  let formElements = []

  let sections = []

  const onMouseOverItem = value => {
    setShowValue(value)
  }

  const onMouseOutItem = () => {
    setShowValue("")
  }

  const collapseAll = parentId => {
    const _list = [...list]
    const section = _list.findIndex(x => x.id === parentId)
    _list[section].isOpen = false
    if (_list[section].type === "node") {
      _list[section].children.map(childKey => {
        let child = _list.findIndex(x => x.id === childKey)
        if (_list[child].type === "leaf") {
          _list[child].isOpen = false
        } else {
          _list[child].isAvailable = false
          _list[child].isOpen = false

          _list[child].children.map(x => {
            collapseAll(x)
          })
        }
      })
    }
    setList(_list)
  }

  const HiSection = ({ info, setExpanded, index, isExpanded, parent = [] }) => {
    return (
      <>
        <Flex
          width="100%"
          height="40px"
          sx={{
            background: "#F3F7FF",
            borderRadius: "8px",
            alignItems: "center",
            px: "10px",
            mb: "10px",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onMouseDown={e => {
            e.stopPropagation()
            if (e.nativeEvent.which === 1) {
              if (info.isOpen) {
                //close all children; including sub children
                collapseAll(info.id)
              } else {
                //open up to its immediate child
                const item = list.findIndex(u => u.id === info.id)
                list[item].isOpen = true
                list[item].children.map(x => {
                  const item = list.find(y => y.id === x)

                  if (item.type === "node") {
                    item.isAvailable = true
                  } else {
                    item.isOpen = true
                  }
                })
              }

              setExpandedSection([
                ...expandedSection,
                {
                  sectionId: info.id,
                },
              ])
            }
          }}
        >
          {info.label} 2
          <Icon icon={isExpanded ? faChevronUp : faChevronDown} size="lg" />
        </Flex>
      </>
    )
  }

  if (list) {
    Object.keys(list)
      .filter(a => {
        const x = list[a]
        return x.label.toLowerCase().includes(searchValue.toLowerCase())
      })
      .map(q => {
        const x = list[q]
        const __key = `${x.id}_${x.parent}${x.uiKey}`
        if (x.type === "leaf" && (x.isOpen || x.level === 0)) {
          let displayValue = x.selected ? true : showValue === x.value
          formElements.push(
            <Flex
              onMouseOver={() => onMouseOverItem(x.value)}
              onMouseOut={onMouseOutItem}
              variant="flex.center"
              sx={{
                flexDirection: "row",
                marginBottom: "20px",
                padding: "5px",
                marginRight: "20px",
                backgroundColor: displayValue ? "#F3F7FF" : "#FFFFFF",
                border: displayValue ? "1px solid #B7CBFF" : "1px solid #FFFFFF",
                borderRadius: "16px",
                "&:hover": {
                  backgroundColor: displayValue ? "#F3F7FF" : "#FFFFFF",
                  border: displayValue ? "1px solid #B7CBFF" : "1px solid #FFFFFF",
                  borderRadius: "16px",
                },
              }}
            >
              <Label
                variant="wrapperLabel"
                sx={{ fontSize: ["xxs", "xs", "sm"], ml: px2vw(8), color: "app.secondary.20" }}
              >
                <Checkbox
                  name="isAdmin"
                  onChange={e => {
                    const l = list.findIndex(y => y.uiKey === x.uiKey)
                    list[l] = { ...x, selected: !x.selected }
                    setList(list)
                    const { name, checked } = e.target
                    if (checked) {
                      setChecked([...checked, name])
                    } else {
                      setChecked(checked.filter(item => item.selected !== name))
                    }

                    return setA(a => a + 1)
                  }}
                  checked={x.selected}
                />
                {/* <Box sx={{ marginLeft: "20px" }}>
                                <img src={appIcon} style={{ width: 20 }} />
                            </Box> */}
                <Box sx={{ marginLeft: "10px" }}>
                  <span style={{ color: "#000000", fontSize: "14px", fontWeight: "500" }}>{x.label}</span>
                  {displayValue && (
                    <span
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "500",
                        "&:hover": {
                          color: "#000000",
                        },
                      }}
                    >
                      {" (" + x.value + ")"}
                    </span>
                  )}
                </Box>
              </Label>
            </Flex>
          )
        } else if (x.isAvailable || x.isOpen || x.level === 0) {
          formElements.push(<HiSection info={x} isExpanded={x.isOpen} key={`${x.app_id}_${__key}`}></HiSection>)
        }
      })
  }

  return (
    <Flex
      sx={{
        textAlign: "left",
        cursor: "pointer",
        flexDirection: "column",
        backgroundColor: "app.primary.110",
        m: "auto",
      }}
    >
      <Flex
        className={"modal-scroller"}
        sx={{
          flexDirection: "column",
          overflowY: "auto",
          width: "95%",
          marginTop: "40px",
          marginLeft: "35px",
          height: "90%",
        }}
      >
        <div style={{ display: "flex", marginBottom: "20px", justifyContent: "center" }}>
          <div>
            {" "}
            <input
              type="text"
              width={"600px"}
              value={searchValue}
              placeholder="Search Label Name"
              onChange={e => {
                setSearchValue(e.target.value)
              }}
              style={{ padding: "12px", border: "1px solid lightgray", borderRadius: "10px", width: "500px" }}
            />
          </div>

          <div></div>
        </div>
        <div style={{marginBottom:"8px"}}>
        <Label  sx={{ fontSize: ["xxs", "xs", "sm"], ml: px2vw(8), color: "app.secondary.20" }}>
          <Checkbox
            className="form-check-input"
            type="checkbox"
            id="selectAll"
            checked={checked.length === list.length || allTrue}
            onChange={handleCheckAllChange}
            sx={{ ml: "7px", mb: "10px" }}
          />
          <Box sx={{ marginLeft: "10px",mb:"5px" }}>
            <span style={{ color: "#000000", fontSize: "14px", fontWeight: "500" }}>Select All</span>
          </Box>
        </Label>
        </div>
         {formElements}
       

      </Flex>

      <Box sx={{ height: "10%", boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.08);", textAlign: "center" }}>
        <Flex flexDirection="row" sx={{ justifyContent: "center", marginTop: "10px" }}>
          <Box sx={{ textAlign: "center", justifyContent: "center" }}>
            <Button
              fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
              sx={{
                background: "none",
                boxShadow: "none",
                fontWeight: "normal",
                cursor: "pointer",
                color: "app.secondary.70",
                marginRight: "20px",
                "&:hover": {
                  background: "none",
                  color: "app.secondary.70",
                },
              }}
              onClick={closeModal}
            >
              {t("konnect.additionalConfigsModal.cancelBtnLabel")}
            </Button>
          </Box>
          <Box>
            <Button
              variant="primary"
              fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
              sx={{ fontWeight: "normal", px: px2vw(20), borderRadius: "0px" }}
              onClick={() => closeModal(list)}
            >
              {t("konnect.additionalConfigsModal.okBtnLabel")}
            </Button>
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}
