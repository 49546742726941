import React, { memo, useState, useMemo, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import { Dropdown } from "src/components/Konnect/DropdownMenu"
import { Checkbox } from "src/components/Konnect/Checkbox"
import { Modal } from "react-responsive-modal"
import { Handle, Position } from "react-flow-renderer"
import "../../../App.css"
import { AdditionalConfigsModal } from "../../../../../components/Konnect/Base/Components/NodeInner/modals/additionalConfigsModal" //./modals/additionalConfigsModal
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { useHistory } from "react-router-dom"
import { keyframes } from "emotion"

import {
  NodeOuter,
  AppInfo,
  EventSelectionGroup,
  SelectionHeader,
  CopyToClipboard,
  NodeButtonOutline,
  NodeInfoHelper,
  ConnectorOuter,
  ConnectorPortOuter,
  ConnectorPortInput,
  ConnectorPortText,
  ConnectorPortLabel,
  AddMoreFieldButton,
  TestAndReviewButton,
} from "../UI"
let hookTimer = null

import { Box, Flex } from "rebass"
import { Icon, Preloader, Span } from "src/components"
import { CommonOuter } from "../NodeInner/CommonOuter"
import { captureWebHookResponse, getAppEventConfig, onDeleteNodeV2 } from "../../../features/index"
import { useSelector, useDispatch } from "react-redux"
import {
  onDeleteNode,
  toggleBasicAuth,
  onWebhookAuthChange,
  handleRefresh,
  setConfigMenuState,
  turnWebhookResNull,
} from "../../../store/slice/canvasSlice"

const shake = keyframes`{
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-2px, 0px) rotate(1deg); }
  30% { transform: translate(2px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-2px, 1px) rotate(0deg); }
  70% { transform: translate(2px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}`

export const WebHookInner = memo(({ data, isConnectable }) => {
  const [IsDeleteShown, setIsDeleteShown] = useState(false)
  const { t } = useTranslation()
  const [captureWebHook, setCaptureWebHook] = useState(false)
  const [webhookLinked, setWebhookLinked] = useState(false)
  const [openInstructions, setOpenInstructions] = useState(false)
  const [searchEvents, setSearchEvents] = useState("")
  const [openConfigs, setOpenConfigs] = useState(false)
  const [addedKeys, setAddedKeys] = useState(data.configResponses.length > 5 ? data.configResponses.slice(0, 4) : [])
  const [addit, setAddit] = useState([])
  const [nodeReviewed, setNodeReviewed] = useState(data.tested ? data.reviewed : null)
  const [nodeTested, setNodeTested] = useState(data.tested ? data.tested : null)
  const [event, setEvent] = useState(!!data.appEvent)
  const [copy, setCopy] = useState(false)
  const { editorState } = useSelector(state => state.canvas.canvas)
  const { nodes, links } = editorState
  const dispatch = useDispatch()
  const nodeIdx = nodes.findIndex(x => x.id === data.nodeid)
  const node = nodes.find(n => n.id === data.nodeid)
  const handleHookCapture = () => {
    if (data.configResponses.length) {
      setCaptureWebHook(false)
      dispatch(turnWebhookResNull(data))
    }
    if (!captureWebHook) {
      setCaptureWebHook(true)
      const hitCapture = () => {
        dispatch(
          captureWebHookResponse({
            data,
            editorState,
            dispatch
          })
        ).then(res => {
          if (res.payload.message !== "WAITING") {
            clearInterval(hookTimer)
            setCaptureWebHook(false)
          }
        })
      }

      if (!hookTimer) {
        hitCapture()
      }

      hookTimer = setInterval(hitCapture, 10000)
    }
  }

  let form = []
  let formElements = []
  if (data && data.configResponses) {
    data.configResponses.map((q, idx) => {
      const portInfo = {
        node: data.nodeid,
        app: data.id,
        appEvent: data.appEvent ? data.appEvent : "undefined",
        port: q.id,
        label: q.label,
        id: q.id,
        config_key: q.config_key,
      }

      form.push(
        <Flex key={idx} sx={{ flexDirection: "column" }}>
          <ConnectorPortLabel>{q.parent ? `${q.parent}- ${q.label}` : q.label}</ConnectorPortLabel>
          <div style={{ position: "relative" }}>
            <Flex>
              <ConnectorPortInput
                type="text"
                value={q.value}
                className="Input"
                onMouseEnter={e => (e.target.style.cursor = "not-allowed")}
                onMouseLeave={e => (e.target.style.cursor = "pointer")}
                onClick={e => {
                  setTimeout(() => {
                    e.target.style.border = "1px solid #b7cbff"
                    e.target.style.animation = null
                  }, 500)
                  e.target.style.border = "1px solid #FF1C00"
                  e.target.style.animation = `${shake} 0.5s`
                }}
                id={idx}
                onChange={e => {
                  e.preventDefault()
                }}
              />
              <ConnectorPortOuter placement="left" type={q.key_value_type ? q.key_value_type : "input"}>
                <Handle
                  style={{
                    height: "20px",
                    background: "#152f73",
                    "& .connecting": {
                      background: "#ff0072",
                    },
                    width: "20px",
                    borderRadius: "60%",
                    border: "1px solid #152f73",
                  }}
                  type="source"
                  position="right"
                  id={q.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "source" })}
                  isConnectable="true"
                />
              </ConnectorPortOuter>
            </Flex>
          </div>
        </Flex>
      )
    })
  }
  const handleAConfigsClick = () => {
    setOpenConfigs(true)
  }
  const onCloseModal = list => {
    setOpenConfigs(false)
    const selected = list.filter(x => x.selected)
    setAddedKeys(selected)
    createAdditionalFields({ fields: selected, formElements })
    //
  }

  const createAdditionalFields = props => {
    const { fields, formElements } = props
    const newFeilds = fields
    setAddit(newFeilds)
  }
  if (addit.length > 0) {
    addit.map((q, idx) => {
      const portInfo = {
        node: data.nodeid,
        app: data.id,
        appEvent: data.appEvent ? data.appEvent : "undefined",
        port: q.id,
        label: q.label,
        id: q.id,
        config_key: q.config_key,
      }
      function strToArray(text) {
        if (text.includes(".")) {
          let str = text
          let strArr = str.split("")
          strArr.map(letter => {
            if (letter === ".") {
              let index = strArr.indexOf(".")
              strArr.splice(index, 2, " >", "> ")
              let final = strArr.join("")
              strArr = final.split("")
            }
          })

          return strArr.join("")
        } else {
          return text
        }
      }
      let connector = q.parent && strToArray(q.parent)
      let label = q.label && strToArray(q.label)

      formElements.push(
        <Flex key={idx} sx={{ flexDirection: "column" }}>
          <ConnectorPortLabel>{q.parent ? `${connector} >> ${label}` : q.label}</ConnectorPortLabel>
          <div style={{ position: "relative" }}>
            <Flex>
              <ConnectorPortInput
                type="text"
                value={q.value}
                className="Input"
                id={idx}
                onMouseEnter={e => (e.target.style.cursor = "not-allowed")}
                onMouseLeave={e => (e.target.style.cursor = "pointer")}
                onClick={e => {
                  setTimeout(() => {
                    e.target.style.border = "1px solid #b7cbff"
                    e.target.style.animation = null
                  }, 500)
                  e.target.style.border = "1px solid #FF1C00"
                  e.target.style.animation = `${shake} 0.5s`
                }}
                onChange={e => {
                  // toast - Data passed from Webhook
                  e.preventDefault()
                }}
              />
              <ConnectorPortOuter placement="left" type={q.key_value_type ? q.key_value_type : "input"}>
                <Handle
                  type="source"
                  position="right"
                  style={{
                    height: "20px",
                    background: "#152f73",
                    width: "20px",
                    borderRadius: "60%",
                    border: "1px solid #152f73",
                  }}
                  isConnectable={isConnectable}
                  id={q.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "source" })}
                />
              </ConnectorPortOuter>
            </Flex>
          </div>
        </Flex>
      )
    })
  }
  useEffect(() => {
    setNodeTested(data.tested ? data.tested : null)
    setNodeReviewed(data.tested ? data.reviewed : null)
  }, [{ ...data }])
  const history1 = useHistory()
  let idd = history1.location.pathname.split("/").pop()
  let ABC = []
  let impConfigResponses = []

  if (!isNaN(idd)) {
    let impHandles = []
    if (links && links.length && nodeIdx === 0) {
      impHandles = links.map(e => {
        return e.sourceHandle
      })
    }

    impConfigResponses = data.configResponses.filter(e => {
      return impHandles.find(element => {
        return JSON.parse(element.split("|")[1]).id === e.id
      })
    })
    impConfigResponses = impConfigResponses.map(i => {
      return { ...i, selected: true }
    })

    ABC = form.filter(f => {
      return impHandles.find(q => {
        return q === f.props.children[1].props.children.props.children[1].props.children.props.id
      })
    })
    formElements.concat(
      form.filter(f => {
        return impHandles.find(q => {
          return q === f.props.children[1].props.children.props.children[1].props.children.props.id
        })
      })
    )
  }
  useEffect(() => {
    if (!isNaN(idd) && links) {
      const selected = impConfigResponses.filter(x => x.selected)? impConfigResponses.filter(x => x.selected):data.configResponses.filter(d => d.value).slice(0, 3)
      return setAddedKeys(selected)
    }
  }, [idd])
  useEffect(() => {
    if (isNaN(idd)) {
      setAddedKeys(data.configResponses.filter(d => d.value).slice(0, 3))
      setAddit(data.configResponses.filter(d => d.value).slice(0, 3))
    }
  }, [data.configResponses])
  return (
    <>
      <CommonOuter
        node={data}
        reviewed={nodeReviewed}
        nodeTested={nodeTested}
        isFirst={!nodeIdx}
        captureWebHook={captureWebHook}
      />
      <NodeOuter
        onMouseEnter={() => setIsDeleteShown(true)}
        onMouseLeave={() => setIsDeleteShown(false)}
        isFirst={!nodeIdx}
        reviewed={nodeReviewed}
      >
        <AppInfo
          title={data.name}
          subtitle={data.account}
          image={data.image}
          className="DraggableHandle"
          background={data.background}
          hasDelete={IsDeleteShown && (nodeIdx !== 0 || nodes.length === 1)}
          onDelete={() => {
            setCaptureWebHook(false)

            if (data.tested || data.konnect_activity_id) {
              dispatch(onDeleteNode(data))
              dispatch(
                onDeleteNodeV2({
                  konnect_id: node.data.konnect_id,
                  konnect_activity_id: data.konnect_activity_id,
                  canvas_json: editorState,
                  nodeId: data.nodeid,
                })
              )
              clearInterval(hookTimer)
            } else {
              dispatch(onDeleteNode(data))
              clearInterval(hookTimer)
            }
          }}
          hasRefresh={true}
          onRefresh={() => {
            clearInterval(hookTimer)

            setCaptureWebHook(false)

            dispatch(handleRefresh(data))
          }}
        ></AppInfo>

        {data.appEvents.length ? (
          <EventSelectionGroup>
            <SelectionHeader>{t("konnect.sidebar.choose_trigger")}</SelectionHeader>
            <Dropdown
              searchable={true}
              searchValue={searchEvents}
              onFilterChange={text => {
                setSearchEvents(text)
              }}
              inputPlaceholder={!searchEvents ? "Search field" : ""}
              headerTitle={t("konnect.sidebar.select_trigger_action_dropdown_header")}
              items={data.appEvents}
              renderKey="name"
              selected={data.appEvent ? data.appEvents.find(x => x.id === data.appEvent).name : null}
              onChange={selected => {
                dispatch(
                  getAppEventConfig({
                    data,
                    nodeId: data.nodeid,
                    appId: data.id,
                    eventId: selected.id,
                    source: nodeIdx === 0,
                    type: selected.side === "left" ? "source" : selected.side === "right" ? "target" : "both",
                  })
                )
                setEvent(false)
                setSearchEvents("")
              }}
              onChangeState={isOpen => {
                dispatch(setConfigMenuState({ isOpen }))
              }}
            />
          </EventSelectionGroup>
        ) : null}

        {data.provider === "webhook" ? (
          <EventSelectionGroup>
            <Checkbox
              title={t("konnect.sidebar.basic_auth")}
              enabled={!!data.enabled}
              onChange={checked => {
                dispatch(
                  toggleBasicAuth({
                    enabled: checked,
                    data,
                    nodeId: data.nodeid,
                    appId: data.id,
                    source: nodeIdx === 0,
                  })
                )
              }}
            ></Checkbox>
          </EventSelectionGroup>
        ) : null}
        {data.enabled && (
          <>
            <SelectionHeader></SelectionHeader>

            {/* when we click on checkbox, it will give info about apis key and secret */}
            <ConnectorOuter>
              <ConnectorPortInput
                id="search"
                name="search"
                placeholder={t("konnect.sidebar.api_key")}
                style={{
                  flex: 1,
                }}
                value={data.apiKey}
                onMouseDown={e => {
                  e.stopPropagation()
                }}
                onChange={e => {
                  dispatch(
                    onWebhookAuthChange({
                      nodeId: data.nodeid,
                      type: "apiKey",
                      input: e.target.value,
                      data,
                    })
                  )
                }}
              />
            </ConnectorOuter>
            <ConnectorOuter>
              <ConnectorPortInput
                id="search"
                name="search"
                placeholder={t("konnect.sidebar.api_token")}
                style={{
                  flex: 1,
                }}
                value={data.secretKey}
                onMouseDown={e => {
                  e.stopPropagation()
                }}
                onChange={e => {
                  dispatch(
                    onWebhookAuthChange({
                      nodeId: data.nodeid,
                      type: "secretKey",
                      input: e.target.value,
                      data,
                    })
                  )
                }}
              />
            </ConnectorOuter>
          </>
        )}

        <EventSelectionGroup>
          <SelectionHeader>{t("konnect.sidebar.webhook_url")}</SelectionHeader>
          <CopyToClipboard
            buttonText={copy ? "Copied" : "Copy"}
            text={data.webhook_url}
            style={{ backgroundColor: copy ? "#F7C545" : "#152F73" }}
            // backgroundColor="white"
            onClick={() => {
              let input = document.createElement("input")
              input.value = data.webhook_url
              document.body.appendChild(input)
              input.select()
              document.execCommand("copy")
              document.body.removeChild(input)
              setCopy(true)

              setTimeout(() => {
                setCopy(false)
              }, 3000)
            }}
          />
        </EventSelectionGroup>

        {data.provider.toLowerCase() !== "webhook" ? (
          <NodeInfoHelper
            onClick={() => {
              setOpenInstructions(true)
            }}
          >
            Webhook Instructions - Click <span style={{ color: "#152F73", textDecoration: "underline" }}>Here</span>{" "}
            <Icon color="#152F73" size="sm" icon={faExternalLinkAlt} />
          </NodeInfoHelper>
        ) : (
          <NodeInfoHelper></NodeInfoHelper>
        )}

        <Flex sx={{ justifyContent: captureWebHook ? "space-between" : "center", gap: "10px" }}>
          <TestAndReviewButton onClick={handleHookCapture} disabled={captureWebHook || webhookLinked}>
            Capture WebHook Response{" "}
          </TestAndReviewButton>
          {captureWebHook && (
            <Preloader
              width={{ _: 40, sm: 40, md: 44, lg: 48, xl: 52 }}
              height={{ _: 40, sm: 40, md: 44, lg: 48, xl: 52 }}
              loading={captureWebHook}
              position="static"
            />
          )}
        </Flex>
        <EventSelectionGroup></EventSelectionGroup>
        {form.length && form.length <= 5 ? (
          <EventSelectionGroup>
            <SelectionHeader>{t("konnect.sidebar.webhook_response")}</SelectionHeader>
            {form.map(form => form)}
          </EventSelectionGroup>
        ) : formElements.length ? (
          <EventSelectionGroup>
            <SelectionHeader>{t("konnect.sidebar.webhook_response")}</SelectionHeader>
            {formElements.map(form => form)}
          </EventSelectionGroup>
        ) : (
          ABC.map(A => A)
        )}
        <Modal
          open={openInstructions}
          onClose={() => setOpenInstructions(false)}
          center
          styles={{ modal: { padding: "40px", paddingTop: "50px", minHeight: "300px" } }}
        >
          {/* <Instructions dangerouslySetInnerHTML={{ __html: node.instructions }}></Instructions> */}
          {data.instructions &&
            data.instructions.trim() !== "" &&
            JSON.parse(data.instructions).map(item => (
              <Span sx={{ color: "#7E7E7E", "& a": { color: "#F7C545", textDecoration: "none" } }}>
                - <label dangerouslySetInnerHTML={{ __html: item }}></label>
                <br />
              </Span>
            ))}
        </Modal>
      </NodeOuter>
      {captureWebHookResponse && form.length && form.length > 5 ? (
        <AddMoreFieldButton onClick={handleAConfigsClick}></AddMoreFieldButton>
      ) : null}
      <Modal
        open={openConfigs}
        onClose={onCloseModal}
        onAnimationEnd={() => scrollTo(0, 0)}
        center
        styles={{ modal: { padding: "0px", borderRadius: "16px", width: "100%", height: "100%" } }}
      >
        <AdditionalConfigsModal
          closeModal={onCloseModal}
          addedKeys={addedKeys}
          data={data.configResponses}
          appIcon={data.image}
        />
      </Modal>
    </>
  )
})
