import { isEqual } from "lodash"
import * as React from "react"
import * as ReactDOM from "react-dom"
import { v4 } from "uuid"

import CanvasContext from "../Canvas/CanvasContext"
import { PortDefault } from "./Port.default"
import { PortIndicator } from "../../../UI"
/** Construct the composed path by traversing parentElements */
const composedPath = (el = null) => {
  const path = []
  while (el) {
    path.push(el)
    el = el.parentElement
  }
  return path
}

//this is arrow sign comes after test and review

export class PortWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hover: false,
    }
  }
  static contextType = CanvasContext
  context = React.ContextType

  nodeRef = React.createRef()

  componentDidMount() {
    setTimeout(() => {
      this.updatePortPosition()
    }, 10)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.port, this.props.port)) {
      this.updatePortPosition()
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   // if (this.props.port.app_id === 1) {
  //   //   console.log("porttypes", this.props.port, nextProps.port)
  //   // }

  //   if (!this.props.port.port) {
  //     return true
  //   }
  //   return false
  // }

  onMouseDown = startEvent => {
    const { offset, node, port, onLinkStart, onLinkCancel, onLinkComplete, onLinkMove, config, chart } = this.props
    const linkId = v4()

    const path = composedPath(startEvent.target)
    const portEl = path.find(el => {
      const fromPortId = el.getAttribute && el.getAttribute("data-port-id")
      const fromNodeId = el.getAttribute && el.getAttribute("data-node-id")
      const fromPortInfo = JSON.parse(el.getAttribute && el.getAttribute("data-port-info"))
      return !!(fromPortId && fromNodeId && fromPortInfo)
    })

    const fromNodeId = portEl.dataset.nodeId
    const fromPortId = portEl.dataset.portId
    const fromPortType = portEl.dataset.portType
    const fromPortInfo = JSON.parse(portEl.dataset.portInfo)

    // Create the move handler
    // This will update the position as the mouse moves
    const mouseMoveHandler = e => {
      const { offsetX, offsetY, zoomScale } = this.context

      onLinkMove({
        config,
        linkId,
        startEvent,
        fromNodeId,
        fromPortId,
        fromPortInfo,
        toPosition: {
          x: (e.clientX - offsetX - offset.x) / zoomScale,
          y: (e.clientY - offsetY - offset.y) / zoomScale,
        },
      })
    }

    // Create and bind the mouse up handler
    // This is used to check if the link is complete or cancelled
    const mouseUpHandler = e => {
      // We traverse up the event path until we find an element with 'data-port-id' and data-node-id'
      // e.toElement cannot be used because it may be a child element of the port
      const path = composedPath(e.target)
      const portEl = path.find(el => {
        const toPortId = el.getAttribute && el.getAttribute("data-port-id")
        const toNodeId = el.getAttribute && el.getAttribute("data-node-id")
        return !!(toPortId && toNodeId)
      })

      // If both node-id and port-id are defined as data attributes, we are mouse-upping
      // on another port. Run the success handler
      if (portEl) {
        const toPortId = portEl.getAttribute("data-port-id")
        const toNodeId = portEl.getAttribute("data-node-id")
        const toPortInfo = JSON.parse(portEl.getAttribute && portEl.getAttribute("data-port-info"))

        onLinkComplete({
          config,
          linkId,
          startEvent,
          fromNodeId,
          fromPortId,
          toNodeId,
          toPortId,
          fromPortInfo,
          toPortInfo,
        })
      } else {
        onLinkCancel({ config, linkId, startEvent, fromNodeId, fromPortId })
      }

      // Remove the listeners if the link is complete or canceled
      window.removeEventListener("mouseup", mouseUpHandler, false)
      window.removeEventListener("mousemove", mouseMoveHandler, false)
    }

    // console.log("node", node)

    if (node.tested && node.reviewed) {
      // Add listeners
      window.addEventListener("mouseup", mouseUpHandler, false)
      window.addEventListener("mousemove", mouseMoveHandler, false)
      // Notify state of link start
      onLinkStart({ config, linkId, startEvent, fromNodeId, fromPortId, fromPortInfo })
    }

    // Prevent default and stop propagation to prevent text selection
    startEvent.preventDefault()
    startEvent.stopPropagation()
  }
  render() {
    const {
      selected,
      selectedLink,
      hovered,
      hoveredLink,
      style,
      port,
      node,
      config,
      pType,
      active,
      hidden = false,
    } = this.props

    const { hover } = this.state

    const portId = `${node.nodeId}|${node.appId}|${port.app_event_id}|${port.id}`
//console.log(portId,"portID")
    const portInfo = JSON.stringify({
      node: node.nodeId,
      app: node.appId,
      appEvent: port.app_event_id,
      port: port.id,
      type: pType,
      label: port.label,
      id: port.id,
      config_key: port.config_key,
      key_value_type: port.key_value_type,
    })
    //console.log(portInfo,"portinfo")

    const { tested = false, reviewed = false } = node

    if (hidden)
      return (
        <div
          data-port-id={portId}
          data-port-type={pType}
          data-node-id={node.nodeId}
          data-port-info={portInfo}
          ref={this.nodeRef}
          style={{
            position: "absolute",
          }}
        />
      )
    return (
      <div
        data-port-id={portId}
        data-port-type={pType}
        data-node-id={node.nodeId}
        data-port-info={portInfo}
        onMouseDown={this.onMouseDown}
        onMouseEnter={() => {
          if (tested && reviewed) {
            this.setState({ hover: true })
          }
        }}
        onMouseLeave={() => {
          this.setState({ hover: false })
        }}
        ref={this.nodeRef}
        style={{
          position: "absolute",
        }}
      >
        {pType === "target" ? <PortIndicator show={hover} /> : null}
        <PortDefault
          config={config}
          port={port}
          active={hover || active}
          isSelected={!!selected && selected.type === "port" && selected.id === port.id}
          isHovered={!!hovered && hovered.type === "port" && hovered.id === port.id}
          isLinkSelected={
            selectedLink
              ? (selectedLink.from.portId === port.id && selectedLink.from.nodeId === node.id) ||
                (selectedLink.to.portId === port.id && selectedLink.to.nodeId === node.id)
              : false
          }
          isLinkHovered={
            hoveredLink
              ? (hoveredLink.from.portId === port.id && hoveredLink.from.nodeId === node.id) ||
                (hoveredLink.to.portId === port.id && hoveredLink.to.nodeId === node.id)
              : false
          }
        />
        {pType === "source" ? <PortIndicator show={hover} pType={pType} /> : null}
      </div>
    )
  }

  updatePortPosition() {
    const el = ReactDOM.findDOMNode(this.nodeRef.current)
    if (el) {
      // Ports component should be positions absolute
      // Factor this in so we get position relative to the node
      const nodesEl = el.parentElement ? el.parentElement : { offsetLeft: 0, offsetTop: 0 }
      // update port position after node size has been determined

      this.props.onPortPositionChange({
        config: this.props.config,
        node: this.props.node,
        port: this.props.port,
        type: this.props.pType,
        el,
        nodesEl,
      })
    }
  }

  portsOfType(props) {
    const {
      port: { type },
      node,
      chart,
    } = props
    const ports = chart && chart.ports ? chart.ports.filter(x => x.node === node) : []

    const res = Object.values(ports).reduce((count, port) => {
      return isEqual(port.type, type) ? count + 1 : count
    }, 0)

    return res
  }
}
