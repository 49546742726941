import React from 'react'
import { Flex, Box } from "rebass"
import Icon from "assets/images/billing_icon"
import { Text, Button } from "src/components"
import { useTranslation } from "react-i18next"
import Circle from "src/assets/images/billing_circle"
import Mark from "../../assets/images/Mark.svg"

export const ProductsList = ({ plans }) => {
    const { t } = useTranslation()
    console.log(plans,"plans")
    return (
        <Flex flexDirection="column">
            <Flex flexDirection="row" sx={{flexWrap: "wrap"}}>
                { plans.products?.map((product, idx) => (
                    <>
                  { product &&  product.current_plan === true ? <>
                    <Flex key={idx} flexDirection="column" sx={{display: 'flex', alignContent: "space-between", p: 10, ml: 20, mb: 20, width:product.current_plan? "100%":250, height: 280, bg: product.current_plan ? "#152F73" : "#F3F7FF", borderRadius: 16}}>
                    <div style={{display:"grid",width:"100%",gridTemplateColumns:"20% 80%",gap:"30px",pl:"10px"}}>
                           <Flex>
                            <Box sx={{minWidth: 66}}>
                                <Icon />
                            </Box>
                            <Flex flexDirection="column" sx={{ml: 10}}>
                                <Text sx={{color: !product.current_plan ? "#7482A6" : "#45F903", fontSize: "20px"}} >{product.name}</Text>
                                {/* <Text sx={{color: !product.current_plan ? "#7482A6" : "#FFFFFF", fontSize: "14px"}} >{product.description}</Text> */}
                            </Flex>
                            </Flex>
                            <div style={{display:"grid",width:"100%",gridTemplateColumns:"50% 50%",pl:"10px"}}>
                            {plans.feature_list && plans.feature_list.length > 0 && plans.feature_list.map((product, idx) => (
                    <Flex key={idx} flexDirection="row" sx={{py: 10, px: '5px' }}>
                        <Mark />
                        <Text fontSize={12} sx={{ fontWeight: "500", color: "white", ml: 10 }}>{product}</Text>
                    </Flex>
                ))}
                                </div>

                        </div>
                        {/* <Flex sx={{width: "100%", alignItems: "flex-end", justifyContent: "center", display: 'flex', flex: 1}}>
                            {product.current_plan ? (
                                <></>
                                // <Text sx={{color: !product.current_plan ? "#7482A6" : "#fff", mb: 10}}>{t("account.billing.currentPlanText")}</Text>
                            ): (
                                <Button  sx={{minWidth: 150, py: 3,bg:"#45F903"}}>{t("account.billing.upgradeText")}</Button>
                            )}
                        </Flex> */}
                    </Flex>
                    </> : null }
                    </>
                ))}
            </Flex>
            <Flex flexDirection="row" sx={{flexWrap: "wrap"}}>
                { plans.products?.map((product, idx) => (
                    <>
                  { product &&  product.current_plan === "Reseller" ? <>
                    <Flex key={idx} flexDirection="column" sx={{display: 'flex', alignContent: "space-between", p: 10, ml: 20, mb: 20, width:product.current_plan? "100%":250, height: 280, bg: product.current_plan ? "#152F73" : "#F3F7FF", borderRadius: 16}}>
                    <div style={{display:"grid",width:"100%",gridTemplateColumns:"20% 80%",gap:"30px",pl:"10px"}}>
                           <Flex>
                            <Box sx={{minWidth: 66}}>
                                <Icon />
                            </Box>
                            <Flex flexDirection="column" sx={{ml: 10}}>
                                <Text sx={{color: !product.current_plan ? "#7482A6" : "#45F903", fontSize: "20px"}} >{product.name}</Text>
                                {/* <Text sx={{color: !product.current_plan ? "#7482A6" : "#FFFFFF", fontSize: "14px"}} >{product.description}</Text> */}
                            </Flex>
                            </Flex>
                            <div style={{display:"grid",width:"100%",gridTemplateColumns:"50% 50%",pl:"10px"}}>
                            {plans.feature_list && plans.feature_list.length > 0 && plans.feature_list.map((product, idx) => (
                    <Flex key={idx} flexDirection="row" sx={{py: 10, px: '5px' }}>
                        <Mark />
                        <Text fontSize={12} sx={{ fontWeight: "500", color: "white", ml: 10 }}>{product}</Text>
                    </Flex>
                ))}
                                </div>

                        </div>
                        {/* <Flex sx={{width: "100%", alignItems: "flex-end", justifyContent: "center", display: 'flex', flex: 1}}>
                            {product.current_plan ? (
                                <></>
                                // <Text sx={{color: !product.current_plan ? "#7482A6" : "#fff", mb: 10}}>{t("account.billing.currentPlanText")}</Text>
                            ): (
                                <Button  sx={{minWidth: 150, py: 3,bg:"#45F903"}}>{t("account.billing.upgradeText")}</Button>
                            )}
                        </Flex> */}
                    </Flex>
                    </> : null }
                    </>
                ))}
            </Flex>
            <Flex flexDirection="row" sx={{flexWrap: "wra"}}>
                { plans.products?.map((product, idx) => (
                    <>
                  { product &&  !product.current_plan ? <>
                    <Flex key={idx} flexDirection="column" sx={{display: 'flex', alignContent: "space-between", p: 10, ml: 20, mb: 20, width:product.current_plan? 550:250, height: 230, bg: product.current_plan ? "#152F73" : "#F3F7FF", borderRadius: 16}}>
                        <Flex flexDirection="row"> 
                            <Box sx={{minWidth: 66}}>
                                <Icon />
                            </Box>
                            <Flex flexDirection="column" sx={{ml: 10}}>
                                <Text sx={{color: !product.current_plan ? "#7482A6" : "#45F903", fontSize: "20px"}} >{product.name}</Text>
                                {/* <Text sx={{color: !product.current_plan ? "#7482A6" : "#FFFFFF", fontSize: "14px"}} >{product.description}</Text> */}
                            </Flex>
                        </Flex>
                        <Flex sx={{width: "100%", alignItems: "flex-end", justifyContent: "center", display: 'flex', flex: 1}}>
                            {product.current_plan ? (
                                <Text sx={{color: !product.current_plan ? "#7482A6" : "#fff", mb: 10}}>{t("account.billing.currentPlanText")}</Text>
                            ): (
                                <button style={{padding:"10px 15px 10px 15px",backgroundColor:"#45F903",border:"1px solid #45F903", borderRadius:"9px",color:"#152F73",marginBottom:"10px"} }>{t("account.billing.upgradeText")} Plan</button>
                              
                            )}
                        </Flex>
                    </Flex>
                    </> : null }
                    </>
                ))}
            </Flex>
        </Flex>
    )
}