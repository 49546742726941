import React, { useState } from "react"
import { Flex, Box } from "rebass"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { Input } from "src/components"
import px2vw from "utils/px2vw"
import { Link, Button, Preloader } from "src/components"
import { useTranslation } from "react-i18next"
import Cross from "../../assets/images/cross.svg"
import { ChatGPT } from "../../features/ReactFlow/chatgpt"
import { Modal } from "react-responsive-modal"
import { TopbarToast } from "../../features/toast/index"
export const SearchHeader = ({
  onSearchItem,
  searchValue,
  hideSearch,
  appsPage,
  home,
  taskHistory,
  konnectz,
  gptEnabled,
  toggleToast = false,
  toastContent = null,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const onCloseModal = () => {
    setOpenModal(false)
  }
  return (
    <>
      <Flex
        alignItems="center"
        px={{ _: 32, sm: 32, md: 34, lg: 38, xl: 45 }}
        py="18px"
        height="10%"
        sx={{
          width: "100%",
          zIndex: 1,
          backgroundColor: "white",
          boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <TopbarToast toggleToast={toggleToast} toastContent={toastContent} />
        <Flex
          width="15%"
          fontSize={{ _: 18, sm: 18, md: 20, lg: 22, xl: 22 }}
          sx={{ justifyContent: "left", fontWeight: "500", color: "#152f73" }}
        >
          {home ? "Home" : appsPage ? "Apps" : taskHistory ? "Task History" : konnectz ? "Konnectz" : ""}
          {/* Home */}
        </Flex>
        {/* <Flex
          // width="90%"
          flexDirection="row"
          alignItems="center"
          sx={{ justifyContent: "flex-end", gap: ["20px", "30px", "40px", "50px"] }}
        > */}
        {!gptEnabled && !hideSearch && (
          <Input
            width={{ _: "300px", sm: "300px", md: "340px", lg: "400px", xl: "475px" }}
            sxProps={{
              borderRadius: "12px",
              border: "1px solid #B7CBFF",
              bg: "#edf2ff",
              "& ~ span": { color: "gray !important" },
            }}
            defaultValue={searchValue}
            hideAdditional={true}
            iconLeft={faSearch}
            onChange={onSearchItem}
            placeholder={t("header.searchAppTextPlaceholder")}
          />
        )}
        <Flex
          width={!gptEnabled && "15%"}
          alignItems="center"
          sx={{ justifyContent: "flex-end", gap: ["20px", "30px", "40px", "50px"] }}
        >
          {gptEnabled && !hideSearch && (
            <Input
              width={{ _: "300px", sm: "300px", md: "340px", lg: "400px", xl: "475px" }}
              sxProps={{
                borderRadius: "12px",
                border: "1px solid #B7CBFF",
                bg: "#edf2ff",
                "& ~ span": { color: "gray !important" },
              }}
              defaultValue={searchValue}
              hideAdditional={true}
              iconLeft={faSearch}
              onChange={onSearchItem}
              placeholder={t("header.searchAppTextPlaceholder")}
            />
          )}
          {gptEnabled && (
            <Button
              fontSize={{ _: 16, sm: 16, md: 18, lg: 18, xl: 20 }}
              p={{
                _: "6px 30px 6px 10px",
                sm: "6px 30px 6px 10px",
                md: "6px 30px 6px 15px",
                lg: "8px 30px 8px 20px",
                xl: "10px 30px 10px 20px",
              }}
              sx={{
                backgroundColor: "#1E419D",
                textAlign: "left",
                position: "relative",
                color: "#FFFFFF",
                fontWeight: 500,
                cursor: "pointer",
                borderRadius: "12px",
                border: "none",
              }}
              onClick={() => setOpenModal(true)}
              //  onClick={onCreateFolderBtnClick}
              // iconRight={faFolderPlus}
            >
              ChatGPT{" "}
              <span
                style={{
                  fontSize: "10px",
                  background: "#2196F3",
                  borderTopRightRadius: "35%",
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  borderBottomLeftRadius: "35%",
                }}
              >
                Beta
              </span>
            </Button>
          )}
          <Link
            to={"/konnect"}
            sx={{
              cursor: "pointer",
            }}
          >
            <Button
              variant="secondary"
              fontSize={{ _: 16, sm: 16, md: 18, lg: 18, xl: 20 }}
              p={{ _: "6px 10px", sm: "6px 10px", md: "6px 15px", lg: "8px 20px", xl: "10px 20px" }}
              sx={{
                border: "none",
                borderRadius: ["6px", "8px", "10px", "12px"],
                fontStyle: "normal",
                fontWeight: 500,
              }}
            >
              {t("header.createKonnectzBtnLabel")}
            </Button>
          </Link>
        </Flex>

        {/* </Flex> */}
      </Flex>
      <Modal
        open={openModal}
        onClose={onCloseModal}
        closeIcon={<Cross />}
        center
        styles={{ modal: { padding: "20px", borderRadius: "16px", minWidth: "1000px", minHeight: "600px" } }}
      >
        <ChatGPT onCloseModal={onCloseModal} />
      </Modal>
    </>
  )
}
