import React, { useEffect, useState } from "react"
import { Flex, Box, Card, Heading } from "rebass"
import { useTranslation } from "react-i18next"
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from "react-tooltip"
import { Tiles } from "@rebass/layout"
import px2vw from "utils/px2vw"
import Popup from "reactjs-popup"
import { Text, Button, Preloader } from "src/components"
import { EllipsisIconWrapper } from "../Konnect/UI"
import { Modal } from "react-responsive-modal"
import { connect } from "react-redux"
import { UpdateResellerMemberModal } from "src/components/Reseller/updateResellerModal"
import Cross from "../../assets/images/cross.svg"
import { Input, Label } from "src/components/index"
import { updateResellerMember, deleteResellermember, changeSubAccountStatus } from "src/store/actions"
import { getResellerUsersList } from "src/store/actions"
import { ToastContainer, toast } from "react-toastify"
import "./Reseller.css"
import EditIcon from "../../assets/images/Edit_icon.svg"
import Lock from "../../assets/images/Lock.svg"
import DeleteIcon from "../../assets/images/Delete_icon.svg"
import Check from "../../assets/images/check.svg"
import Plus from "../../assets/images/Plus.svg"
export const ResellerUsersList_ = ({
  getResellerUsersList,
  users,
  totalSubAccounts,
  totalTasks,
  deleteResellermember,
  changeSubAccountStatus,
  activeSubAccounts,
  inActiveSubAccounts,
  taskConsumed,
  disabled,
  updateResellerMember,
  allowedSubaccountsLimit,
  addResellerMemberBtnClick,
}) => {
  const { t, i18n } = useTranslation("translation")
  const [showResults, setShowResults] = useState(false)
  const [active, setActive] = useState(true)
  const [loading, setLoading] = useState(false)
  //    const [name, setName] = useState("")
  //    const [email, setEmail] = useState("")
  //    const [task, setTask] = useState("")

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [users1, setUsers1] = useState([])

  const [tasks, setTasks] = useState("")
  const [id, setId] = useState(users)

  useEffect(() => {
    setLoading(true)
    getResellerUsersList().then(() => {
      setLoading(false)
    })
  }, [])

  const onClick = () => setShowResults(true)
  const addResellerModal = () => {
    setOpenModal(true)
  }
  const closeResellerModal = () => {
    setOpenModal(false)
  }

  const [open, setOpen] = useState(false)
  const [ userId,setUserId ]=useState(null)
  const [state, setstate] = useState([])

  const onCloseModal = () => setOpen(false)
  const onOpenModal = () => {
    setOpen(true)
  }
  
  const inactive = () => {
    setActive(true)
  }
  const onEditBtn = (id,name) => {
    setOpen(true)
    setUserId(id)
    setName(name)
    }
  const onUpdatingResellerMember = (firstName, lastName, tasks, sub_account_id, email) => {
    setLoading(true)
    setOpen(false)
    setUserId(sub_account_id)
    updateResellerMember({ firstName, lastName, tasks, sub_account_id, email }).then(res => {
      if (res.payload?.key === "SIGNUP_FAIL" || res.payload?.key === "UPDATE_RESELLER_MEMBER_FAIL") {
        toast.error(t("account.agency.subaccounterror")),
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
      }
      getResellerUsersList()
      setLoading(false)
    })
  }

  const deletemember = id => {
    setLoading(true)
    setUserId(id)

    deleteResellermember(id).then(res => {
      setOpen(false)
      getResellerUsersList()
      setLoading(false)
    })
  }

  const toggleSubaccountStatus = (firstName, lastName, tasks, status, id, email) => {
    setLoading(true)
    changeSubAccountStatus(firstName, lastName, tasks, status, id, email).then(res => {
      setOpen(false)
      getResellerUsersList()
      setLoading(false)
    })
  }

  return (
    <>
      <Box sx={{ width: "100%", height: "100%", border: "0px solid red", overflowY: "scroll", mt: "20px" }}>
        <Flex
           fontSize={[9, 11, 13, 16]}
          sx={{
            m: "0% 0% 0% 0.5%",
            width: "98.5%",
            height: "70px",
            border: "1px soild red",
            bg: "#152F73",
            borderRadius: "10px",
            color: "white",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Box sx={{ p: "8px", color: "#45F903" }}>
            <Text sx={{ fontWeight: 300 }}>
              {t("account.agency.total")} <span className="font">{totalTasks}</span>
            </Text>
          </Box>
          <Box sx={{ p: "8px" }}>
            <Text sx={{ fontWeight: 300 }}>
              {t("account.agency.consumed")} <span className="font">{taskConsumed}</span>
            </Text>
          </Box>
          <Box sx={{ p: "8px" }}>
            <Text sx={{ fontWeight: 300, color: "#45F903" }}>
              {t("account.agency.totalSubaccounts")} <span className="font">{totalSubAccounts}</span>/
              <span className="font">{allowedSubaccountsLimit}</span>
            </Text>
          </Box>
          <Box sx={{ p: "8px" }}>
            <Text sx={{ fontWeight: 300 }}>
              {t("account.agency.active")} <span className="font">{activeSubAccounts}</span>
            </Text>
          </Box>
          <Box sx={{ p: "8px" }}>
            <Text sx={{ fontWeight: 300 }}>
              {t("account.agency.inActive")} <span className="font">{inActiveSubAccounts}</span>
            </Text>
          </Box>
          <Box>
            <button
              style={{
                padding: "5px 10px 5px 10px",
                backgroundColor: "#45F903",
                border: "1px solid #45F903",
                borderRadius: "9px",
                color: "#152F73",
              }}
            >
              {t("account.billing.upgradeText")}{" "}
            </button>
          </Box>
        </Flex>
        <Flex sx={{ justifyContent: "space-between", mt: "15px", width: "98.5%" }}>
          <Text sx={{ color: "#152F73", p: "11px", fontSize: ["h1", "h1", "h2"] }}>Sub Accounts</Text>
          <Box
            onClick={addResellerMemberBtnClick}
            sx={{
              cursor: "pointer",
              m: "10px 10px 0 10px",
              display: "flex",
              alignItems: "center",
              bg: "#1E419D",
              color: "white",
              p: "3px 10px 3px 3px",
              borderRadius: "20px",
            }}
          >
            <span>
              <Plus style={{ margin: "10px 12px 5px 5px" }} />
            </span>
            <span>Add Sub-account</span>
          </Box>

          {/* <Button
            variant="secondary"
            disabled={totalSubAccounts >= allowedSubaccountsLimit}
            width="200px"
            height={[30, 40, 45, 55]}
            fontSize={[10, 12, 16, 18]}
            sx={{
              border: "none",
              borderRadius: "12px",
              height: "46px",
              fontStyle: "normal",
              fontWeight: 500,
            }}
             onClick={addAgencyMemberBtnClick}
          >
            {t("account.addSubAccountsText")}2
          </Button> */}
        </Flex>

        <Box sx={{ height: "300px", overflowY: "scroll" }}>
          <Flex sx={{ mt: "20px", gap: "18px", flexWrap: "wrap" }}>
            {users?.map((user, idx) => (
              <>
                <Flex
                  fontSize={[10, 12, 16, 18]}
                  sx={{
                    height: "100%",
                    border: "1px solid #F3F7FF",
                    p: "20px",
                    m: "10px",
                    bg: "#F3F7FF",
                    borderRadius: "10px",
                    overflowY: "scroll",
                  }}
                >
                  <Box>
                    <Flex flexDirection="flex" sx={{ width: "100%", justifyContent: "flex-start" }}>
                      <Box>
                        <img
                          style={{ borderRadius: "16px", border: "1px solid #B7CBFF" }}
                          width="40px"
                          height="40px"
                          // src={profile.image_url}
                        />
                      </Box>
                      <Box sx={{ m: "0 0px 0 20px" }}>
                        <Text
                          sx={{
                            fontWeight: 300,
                            fontSize: 18,
                            textAlign: "left",
                            justifyContent: "center",
                            width: 200,
                            color: "#152F73",
                          }}
                        >
                          {user.name}
                        </Text>
                        <Text
                          sx={{ fontWeight: 200, fontSize: 12, textAlign: "left", color: "gray" }}
                          data-tip={user.email}
                          data-for={user.email + ""}
                        >
                          {/* {t("account.agency.email")}{" "} */}
                          <span className="font">
                            {" "}
                            {user.email.length > 20 ? user.email.substring(0, 20) + "..." : user.email}
                          </span>
                        </Text>
                      </Box>
                      <Flex
                        flexDirection="row"
                        sx={{ mt: "0px", justifyContent: "space-around", alignItems: "center" }}
                      >
                        <EditIcon
                          style={{ cursor: "pointer", marginRight: "15px" }}
                          data-tip={"Edit"}
                          onClick={()=>onEditBtn(user.id,user.name)}
                        />
                        <Lock style={{ cursor: "pointer", marginRight: "15px " }} />
                        <DeleteIcon
                          data-tip="Remove"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#ea4f4f",
                            borderRadius: "50%",
                            padding: "4px 7px",
                            height: "30px",
                          }}
                          size="xl"
                          onClick={() => deletemember(user.id)}
                        />
                        <Modal
                          open={open}
                          onClose={onCloseModal}
                          styles={{ modal: { padding: "20px", borderRadius: "16px", minWidth: "400px" } }}
                          closeIcon={<Cross />}
                          center
                        >
                          <UpdateResellerMemberModal users={users} userId={userId} onUpdatingResellerMember={onUpdatingResellerMember} />
                        </Modal>
                      </Flex>
                    </Flex>
                    <Flex sx={{ mt: "35px" }}>
                      <Box sx={{ m: "5px", p: "5px", justifyContent: "space-between" }}>
                        <Text
                          sx={{
                            fontWeight: 300,
                            m: "5px",
                            pt: "40px",
                            pb: "40px",
                            p: "10px",
                            // height: "70px",
                            borderRadius: "10px",
                            textAlign: "center",
                            fontSize: 14,
                            bg: "white",
                          }}
                        >
                          <div style={{ testAlign: "center" }}>{user.task}</div>
                          <div style={{ testAlign: "center" }}>Task Assigned</div>
                        </Text>
                      </Box>
                      <Box sx={{ m: "5px", p: "5px", justifyContent: "space-between" }}>
                        <Text
                          sx={{
                            fontWeight: 300,
                            m: "5px",
                            pt: "40px",
                            pb: "40px",
                            p: "10px",
                            // height: "70px",
                            borderRadius: "10px",
                            textAlign: "center",
                            fontSize: 14,

                            bg: "white",
                          }}
                        >
                          <div style={{ testAlign: "center", color: "#F7C546" }}>{user.task_consumed}</div>
                          <div style={{ testAlign: "center" }}>Consumed</div>
                        </Text>
                      </Box>
                      <Box sx={{ m: "5px", p: "5px", justifyContent: "space-between" }}>
                        <Text
                          sx={{
                            fontWeight: 300,
                            m: "5px",
                            pt: "40px",
                            pb: "40px",
                            p: "10px",
                            // height: "70px",
                            borderRadius: "10px",
                            textAlign: "center",
                            fontSize: 14,
                            bg: "white",
                          }}
                        >
                          <div style={{ testAlign: "center" }}>{user.task - user.task_consumed} </div>
                          <div style={{ testAlign: "center" }}>Remaining</div>
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                </Flex>
              </>
              // <Flex
              //   flexDirection="row"
              //   key={user.id}
              //   sx={{
              //     p: 10,
              //     ml: 10,
              //     mb: 10,
              //     width: 280,
              //     mt:10,
              //     bg: "#F3F7FF",
              //     borderRadius: 16,
              //     border: "2px solid grey",
              //     opacity: user.sub_account_status === "INACTIVE" ? 0.4 : 1,
              //   }}
              // >
              //   <Flex
              //     flexDirection="column"
              //     sx={{ width: "100%", justifyContent: "flex-start", color: "white"}}
              //   >
              //     <Text
              //       sx={{
              //         fontWeight: 300,
              //         fontSize: 18,
              //         textAlign: "left",
              //         pb: 15,
              //         width: 300,
              //         color: "black",
              //       }}
              //     >
              //       {user.name}
              //     </Text>

              //     <Flex
              //       sx={{
              //         width: "100%",
              //         backgroundColor: "#B7CBFF",
              //         borderRadius: "5px",
              //         color: "#000",
              //         m: "5px",
              //         p: "5px",
              //       }}
              //     >
              //       <Text sx={{ fontWeight: 300, fontSize: 14, textAlign:"center" }} data-tip={user.email} data-for={user.email + ""}>
              //         {t("account.agency.email")}{" "}
              //         <span className="font">
              //           {" "}
              //           {user.email.length > 20 ? user.email.substring(0, 20) + "..." : user.email}
              //         </span>
              //       </Text>
              //     </Flex>
              //     <Flex
              //       sx={{
              //         width: "100%",
              //         backgroundColor: "#B7CBFF",

              //         borderRadius: "5px",
              //         color: "#000",
              //         m: "5px",
              //         p: "5px",
              //       }}
              //     >
              //       <Text sx={{ fontWeight: 300, fontSize: 14 }}>
              //         {t("account.agency.tasks")} <span className="font">{user.task}</span>
              //       </Text>
              //       <ReactTooltip id={user.email + ""} effect="solid" place="bottom"></ReactTooltip>
              //     </Flex>
              //     <Flex
              //       sx={{
              //         width: "100%",
              //         backgroundColor: "#B7CBFF",

              //         borderRadius: "5px",
              //         color: "#000",
              //         m: "5px",
              //         p: "5px",
              //       }}
              //     >
              //       <Text sx={{ fontWeight: 300, fontSize: 14 }}>
              //         {t("account.agency.consumed")} <span className="font">{user.task_consumed}</span>
              //       </Text>
              //     </Flex>
              //     <Flex
              //       sx={{
              //         width: "100%",
              //         backgroundColor: "#B7CBFF",

              //         borderRadius: "5px",
              //         color: "#000",
              //         m: "5px",
              //         p: "5px",
              //       }}
              //     >
              //       <Text sx={{ fontWeight: 300, fontSize: 14 }}>
              //         {t("account.agency.total")} <span className="font">{user.task + user.task_consumed}</span>
              //       </Text>
              //     </Flex>
              //   </Flex>
              //   <Flex sx={{ pl: 25, color: "white" }}>
              //     <Popup
              //       trigger={
              //         <div>
              //           <EllipsisIconWrapper icon={faEllipsisV} color="black" />{" "}
              //         </div>
              //       }
              //       position="right top"
              //       on="hover"

              //       onClose={onCloseModal}
              //       closeOnDocumentClick={false}
              //       closeOnModalClick={false}
              //       mouseLeaveDelay={100}
              //       mouseEnterDelay={0}
              //       contentStyle={{ padding: "0px", border: "none" }}
              //       arrow={false}
              //     >
              //       <Flex
              //         flexDirection="column"
              //         sx={{
              //           bg: "#F7C545",
              //           ml: 3,
              //         }}
              //       >
              //          <Flex
              //           variant="default"
              //           sx={{ mt: 2, ml: 3, mr: 2, bg: "white", cursor: "pointer", width:110, justifyContent:"center" }}
              //           onClick={onOpenModal}
              //         >
              //           {t("account.agency.updateTask")}
              //         </Flex>
              //         <Flex
              //           variant="default"
              //           sx={{ mt: 2, ml: 3, bg: "white", cursor: "pointer", width:110, justifyContent:"center" }}
              //           active={active}
              //           onClick={() =>
              //             toggleSubaccountStatus(
              //               user.first_name,
              //               user.last_name,
              //               user.task,
              //               user.sub_account_status,
              //               user.id,
              //               user.email
              //             )
              //           }
              //         >
              //           {user.sub_account_status === "ACTIVE" ? t("account.agency.activate") : t("account.agency.deactivate")}
              //         </Flex>
              //         <Flex
              //           variant="default"
              //           sx={{ mt: 2, ml: 3, mb: 2, bg: "white", cursor: "pointer", width:110, justifyContent:"center" }}
              //           onClick={() => deletemember(user.id)}
              //         >
              //           {t("account.agency.delete")}
              //         </Flex>
              //       </Flex>

              //       <Modal open={open} onClose={onCloseModal}  center>
              //         <UpdateAgencyMemberModal user={user} onUpdatingAgencyMember={onUpdatingAgencyMember} />
              //       </Modal>
              //     </Popup>
              //   </Flex>
              // </Flex>
            ))}
          </Flex>
        </Box>
      </Box>

      {/* <Flex sx={{ flexDirection: ["column", "column", "row"]}}>
        <Flex flexDirection="column" sx={{ width: "100%",  flexWrap: "wrap" }}>
           <Flex flexDirection="row" sx={{ justifyContent: "flex-start" }}>
            <Flex flexDirection="column" sx={{ justifyContent: "flex-start" }}>
              <Text
                sx={{
                  fontSize: ["h1", "h1", "h2"],
                  fontWeight: "bold",
                  color: "#222222",
                  pt: 10,
                  pl: 10,
                  textAlign: "center",
                  
                }}
              >
                {t("account.agency.agencyMembersHeaderText")}
              </Text>
            </Flex>
          </Flex>
          <Flex flexDirection="row" sx={{  display: "flex", flexWrap: "wrap", pt: 20 }}>
            <Flex flexDirection="column" sx={{ minWidth: "fit-content", p: 4, height: 270, bg: "#F7C545", borderRadius: "16px" }}>
              <Flex
                sx={{ width: "100%", flexDIrection: "row", backgroundColor: "#FFF", color: "#000", p: "5px", m: "5px", borderRadius: "5px" }}
              >
                <Text sx={{ fontWeight: 300 }}>
                {t("account.agency.total")} <span className="font">{totalTasks}</span>
                </Text>
              </Flex>
          <Flex
           sx={{ width: "100%", backgroundColor: "#FFF", color: "#000", p: "5px", m: "5px", borderRadius: "5px" }}
         >
           <Text sx={{ fontWeight: 300 }}>
           {t("account.agency.consumed")} <span className="font">{taskConsumed}</span>
           </Text>
         </Flex>
         <Flex
           sx={{ width: "100%", backgroundColor: "#FFF", color: "#000", p: "5px", m: "5px", borderRadius: "5px" }}
         >
           <Text sx={{ fontWeight: 300 }}>
             {t("account.agency.totalSubaccounts")} <span className="font">{totalSubAccounts}</span>
           </Text>
         </Flex>
         <Flex
           sx={{ width: "100%", backgroundColor: "#FFF", color: "#000", p: "5px", m: "5px", borderRadius: "5px" }}
         >
           <Text sx={{ fontWeight: 300 }}>
             {t("account.agency.active")} <span className="font">{activeSubAccounts}</span>
           </Text>
         </Flex>
         <Flex
           sx={{ width: "100%", backgroundColor: "#FFF", color: "#000", p: "5px", m: "5px", borderRadius: "5px" }}
         >
           <Text sx={{ fontWeight: 300 }}>
             {t("account.agency.inActive")} <span className="font">{inActiveSubAccounts}</span>
           </Text>
         </Flex>
        
       </Flex> 
            {users?.map((user, idx) => (
              <Flex
                flexDirection="row"
                key={user.id}
                sx={{
                  p: 10,
                  ml: 10,
                  mb: 10,
                  width: 280,
                  mt:10,
                  bg: "#F3F7FF",
                  borderRadius: 16,
                  border: "2px solid grey",
                  opacity: user.sub_account_status === "INACTIVE" ? 0.4 : 1,
                }}
              >
                <Flex
                  flexDirection="column"
                  sx={{ width: "100%", justifyContent: "flex-start", color: "white"}}
                >
                  <Text
                    sx={{
                      fontWeight: 300,
                      fontSize: 18,
                      textAlign: "left",
                      pb: 15,
                      width: 300,
                      color: "black",
                    }}
                  >
                    {user.name}
                  </Text>
                
                  <Flex
                    sx={{
                      width: "100%",
                      backgroundColor: "#B7CBFF",
                      borderRadius: "5px",
                      color: "#000",
                      m: "5px",
                      p: "5px",
                    }}
                  >
                    <Text sx={{ fontWeight: 300, fontSize: 14, textAlign:"center" }} data-tip={user.email} data-for={user.email + ""}>
                      {t("account.agency.email")}{" "}
                      <span className="font">
                        {" "}
                        {user.email.length > 20 ? user.email.substring(0, 20) + "..." : user.email}
                      </span>
                    </Text>
                  </Flex>
                  <Flex
                    sx={{
                      width: "100%",
                      backgroundColor: "#B7CBFF",

                      borderRadius: "5px",
                      color: "#000",
                      m: "5px",
                      p: "5px",
                    }}
                  >
                    <Text sx={{ fontWeight: 300, fontSize: 14 }}>
                      {t("account.agency.tasks")} <span className="font">{user.task}</span>
                    </Text>
                    <ReactTooltip id={user.email + ""} effect="solid" place="bottom"></ReactTooltip>
                  </Flex>
                  <Flex
                    sx={{
                      width: "100%",
                      backgroundColor: "#B7CBFF",

                      borderRadius: "5px",
                      color: "#000",
                      m: "5px",
                      p: "5px",
                    }}
                  >
                    <Text sx={{ fontWeight: 300, fontSize: 14 }}>
                      {t("account.agency.consumed")} <span className="font">{user.task_consumed}</span>
                    </Text>
                  </Flex>
                  <Flex
                    sx={{
                      width: "100%",
                      backgroundColor: "#B7CBFF",

                      borderRadius: "5px",
                      color: "#000",
                      m: "5px",
                      p: "5px",
                    }}
                  >
                    <Text sx={{ fontWeight: 300, fontSize: 14 }}>
                      {t("account.agency.total")} <span className="font">{user.task + user.task_consumed}</span>
                    </Text>
                  </Flex>
                </Flex>
                <Flex sx={{ pl: 25, color: "white" }}>
                  <Popup
                    trigger={
                      <div>
                        <EllipsisIconWrapper icon={faEllipsisV} color="black" />{" "}
                      </div>
                    }
                    position="right top"
                    on="hover"
                    
                    onClose={onCloseModal}
                    closeOnDocumentClick={false}
                    closeOnModalClick={false}
                    mouseLeaveDelay={100}
                    mouseEnterDelay={0}
                    contentStyle={{ padding: "0px", border: "none" }}
                    arrow={false}
                  >
                    <Flex
                      flexDirection="column"
                      sx={{
                        bg: "#F7C545",
                        ml: 3,
                      }}
                    >
                       <Flex
                        variant="default"
                        sx={{ mt: 2, ml: 3, mr: 2, bg: "white", cursor: "pointer", width:110, justifyContent:"center" }}
                        onClick={onOpenModal}
                      >
                        {t("account.agency.updateTask")}
                      </Flex>
                      <Flex
                        variant="default"
                        sx={{ mt: 2, ml: 3, bg: "white", cursor: "pointer", width:110, justifyContent:"center" }}
                        active={active}
                        onClick={() =>
                          toggleSubaccountStatus(
                            user.first_name,
                            user.last_name,
                            user.task,
                            user.sub_account_status,
                            user.id,
                            user.email
                          )
                        }
                      >
                        {user.sub_account_status === "ACTIVE" ? t("account.agency.activate") : t("account.agency.deactivate")}
                      </Flex>
                      <Flex
                        variant="default"
                        sx={{ mt: 2, ml: 3, mb: 2, bg: "white", cursor: "pointer", width:110, justifyContent:"center" }}
                        onClick={() => deletemember(user.id)}
                      >
                        {t("account.agency.delete")}
                      </Flex>
                    </Flex>

                    <Modal open={open} onClose={onCloseModal}  center>
                      <UpdateAgencyMemberModal user={user} onUpdatingAgencyMember={onUpdatingAgencyMember} />
                    </Modal>
                  </Popup>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
        {loading && (
            <Preloader loading={loading} />
        )}
      </Flex> */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    resellerUsersList: state.getIn(["reseller", "resellerUsersList"]),
    totalTasks: state.getIn(["reseller", "totalTasks"]),
    activeSubAccounts: state.getIn(["reseller", "activeSubAccounts"]),
    inActiveSubAccounts: state.getIn(["reseller", "inActiveSubAccounts"]),
    taskConsumed: state.getIn(["reseller", "taskConsumed"]),
    totalSubAccounts: state.getIn(["reseller", "totalSubAccounts"]),
    allowedSubaccountsLimit: state.getIn(["reseller", "allowedSubaccountsLimit"]),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getResellerUsersList: () => dispatch(getResellerUsersList()),
    updateResellerMember: data => dispatch(updateResellerMember(data)),
    deleteResellermember: sub_account_id => dispatch(deleteResellermember(sub_account_id)),
    changeSubAccountStatus: (firstName, lastName, tasks, sub_account_status, id, email) =>
      dispatch(changeSubAccountStatus(firstName, lastName, tasks, sub_account_status, id, email)),
  }
}

export const ResellerUsersList = connect(mapStateToProps, mapDispatchToProps)(ResellerUsersList_)
