import { makeRequestActionCreator } from "utils/redux"
import { GET_AGENCY_USERS_LIST, ADD_AGENCY_MEMBER, UPDATE_AGENCY_MEMBER, DELETE_AGENCY_MEMBER } from "config/Constants"

export const getAgencyUsersList = () => dispatch => {
  const request = makeRequestActionCreator(GET_AGENCY_USERS_LIST)

  return dispatch(
    request({
      url: "api/v1/users/list_agency_users",
      method: "GET",
    })
  )
}

export const addAgencyMember = data => dispatch => {
  const request = makeRequestActionCreator(ADD_AGENCY_MEMBER)

  return dispatch(
    request({
      url: "api/v1/users/add_agency_user",
      method: "POST",
      data: { first_name: data.firstName, last_name: data.lastName, email: data.email, sub_account_task: data.tasks },
    })
  )
}

export const updateAgencyMember = data => dispatch => {
  const request = makeRequestActionCreator(UPDATE_AGENCY_MEMBER)

  return dispatch(
    request({
      url: "api/v1/users/update_agency_user",
      method: "POST",
      data: {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        sub_account_id: data.sub_account_id,
        sub_account_task: data.tasks,
      },
    })
  )
}

export const deleteAgencymember = id => dispatch => {
  let url = `api/v1/users/remove_agency_user?sub_account_id=`

  const request = makeRequestActionCreator(DELETE_AGENCY_MEMBER)
  return dispatch(
    request({
      url: url + id,
      method: "GET",
    })
  )
}

export const changeSubAccountStatus = (firstName, lastName, tasks, status, id,email) => dispatch => {
  const request = makeRequestActionCreator(UPDATE_AGENCY_MEMBER)

  return dispatch(
    request({
      url: "api/v1/users/update_agency_user",
      method: "POST",
      data: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        sub_account_task: tasks,
        sub_account_status: status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        sub_account_id: id,
      },
    })
  )
}
