import React from "react"
import { Flex, Text, Box } from "rebass"
import { updateToastContent, updateToggleToast } from "../../store/slice/canvasSlice"
import { useDispatch, useSelector } from "react-redux"

export const TopbarToast = () => {
  const dispatch = useDispatch()
  const { toggleToast, toastContent } = useSelector(state => state.canvas)
  return (
    <Flex
      sx={{
        position: "absolute",
        width: "100%",
        height: "90%",
        top: toggleToast ? "0px" : "-200px",
        transition: "0.5s top",
        px: "20px",
        border: "1px #152F73",
        borderStyle: "none solid solid solid",
        backgroundColor: "#152F73",
        borderBottomLeftRadius: "12px",
        borderBottomRightRadius: "12px",
        boxShadow: `0px 0px 5px -1px #152F73`,
        gap: "10px",
      }}
    >
      {/* <Flex
        sx={{
          //   width: bP(["20px", "20px", "25px", "30px"]),
          // height: bP(["20px", "20px", "25px", "30px"]),
          animation: toastContent?.status === "load" ? "rotation 2s infinite linear" : null,
          fill:
            toastContent?.status === "success"
              ? "#3BBA5F"
              : toastContent?.status === "error"
              ? "#DE1717"
              : toastContent?.status === "alert"
              ? "#ffd43b"
              : toastContent?.status === "load"
              ? "#a2a1a1"
              : null,
        }}
      >
        {toastContent?.status === "success"
          ? svg_1.correct
          : toastContent?.status === "error"
          ? svg_1.error_1
          : toastContent?.status === "alert"
          ? svg_1.alert_1
          : toastContent?.status === "load"
          ? svg_1.load
          : null}
      </Flex> */}
      <Text
        sx={{
          width: "100%",
          fontSize: 14,
          m: "auto 0px",
          color: "#fff",
        }}
      >
        {toastContent?.message}
      </Text>
      {/* <Flex>
        <Box
          onClick={() => {
            resetStudio()
            dispatch(updateToggleToast(false))
          }}
          sx={{
            "&:hover": {
              transform: "scale(1.1)",
            },
            display: toastContent?.status === "alert" ? "flex" : "none",
            cursor: "pointer",
            fill: "#3ab95f",
            // width: bP(["20px", "20px", "25px", "30px"]),
            // height: bP(["20px", "20px", "25px", "30px"]),
            m: "auto 0px auto 20px",
          }}
        >
          {svg_1.accept}
        </Box>
        <Box
          onClick={() => dispatch(updateToggleToast(false))}
          sx={{
            "&:hover": {
              transform: "scale(1.1)",
            },
            display: toastContent?.status === "alert" ? "flex" : "none",
            cursor: "pointer",
            fill: "#DE1717",
            // width: bP(["20px", "20px", "25px", "30px"]),
            // height: bP(["20px", "20px", "25px", "30px"]),
            m: "auto 10px",
          }}
        >
          {svg_1.deny}
        </Box>
      </Flex> */}
    </Flex>
  )
}

export const updateToast = (dispatch, data) => {
  dispatch(updateToggleToast(true))
  if (data.status !== "alert" && data.status !== "load") {
    setTimeout(() => {
      dispatch(updateToggleToast(false))
      dispatch(updateToastContent(null))
    }, 2000)
  }
  dispatch(updateToastContent(data))
}

export const promiseToast = (dispatch, data) => {
  dispatch(updateToastContent(data))
  setTimeout(() => {
    dispatch(updateToggleToast(false))
    dispatch(updateToastContent(null))
  }, 2000)
}
