import React, { useState, useEffect } from "react"
import { HistoryView } from "./History.view"
import { connect } from "react-redux"
import { SearchHeader, Icon, Preloader } from "src/components"
import { getTaskLogs, searchApp, getLogs } from "src/store/actions"
import AuthTokenService from "utils/AuthTokenService"
import { getTaskDetails } from "store/actions/index"
import { GET_TASK_LOGS, GET_TASKS_DETAILS, TASKS_PAGE_SIZE, GET_LOGS } from "config/Constants"

import moment from "moment"

export const Container = props => {
  const { getTaskLogs, getTaskDetails, tasksList, taskDetails, searchApp, getLogs, logsList } = props
  const [expandedItem, setExpandedItem] = useState(null)
  const [dateRange, onDateRange] = useState([new Date("04/12/2020"), new Date()])
  const [selectedApp, setSelectedApp] = useState(null)
  const [historyType, setHistoryType] = useState("success")
  const [pageIndex, setPageIndex] = useState(1)
  const [logsPageIndex, setLogsPageIndex] = useState(1)
  const [loading, setLoading] = useState(false)
  const [konnectId,setKonnectId ]= useState(null)
  const [taskDetailsId, setTaskDetailsId] = useState(null)
  const [taskDetail, setTaskDetail] = useState([])
  const [taskLogs, setTaskLogs] = useState([])
  const [searchValue,setSearchValue] =useState(null)
  useEffect(() => {
    setLoading(true)
    getTaskLogs(searchValue?{
      start_date: moment(dateRange[0]).format("YYYY-MM-DD"),
      end_date: moment(dateRange[2]).format("YYYY-MM-DD"),
      task_log_type: historyType.toUpperCase(),
      page_index: pageIndex,
      page_size: TASKS_PAGE_SIZE,
      search_query:searchValue
    }:{
      start_date: moment(dateRange[0]).format("YYYY-MM-DD"),
      end_date: moment(dateRange[2]).format("YYYY-MM-DD"),
      task_log_type: historyType.toUpperCase(),
      page_index: pageIndex,
      page_size: TASKS_PAGE_SIZE,
      
    }
    ).then(res => {
      if (res.type === GET_TASK_LOGS + "_FAIL") {
        if (res.error.response.status === 422) {
          AuthTokenService.clear()
          props.history.push("/login")
        }
      }
      setLoading(false)
    })
  if(konnectId){
    getLogs({
      konnect_id: konnectId, page_index: logsPageIndex,
      task_log_type: historyType.toUpperCase(),
      page_size: TASKS_PAGE_SIZE,
    }).then(res => {
      if (res.type === "GET_LOGS_SUCCESS") {
        setTaskLogs(res.payload.task_logs)
      }
       
      if (res.type === GET_LOGS + "_FAIL") {
        if (res.error.response.status === 422) {
          AuthTokenService.clear()
          props.history.push("/login")
        }
      }
    })
  }
  
  }, [])

  useEffect(() => {
    let params = {
      page_index: pageIndex,
      task_log_type: historyType.toUpperCase(),
      page_size: TASKS_PAGE_SIZE,
     

    }
    let params1 = {
      page_index: pageIndex,
      task_log_type: historyType.toUpperCase(),
      page_size: TASKS_PAGE_SIZE,
      search_query:searchValue

    }
    let logsParams = {
      konnect_id: konnectId,
      page_index: logsPageIndex,
      task_log_type: historyType.toUpperCase(),
      page_size: TASKS_PAGE_SIZE,
    }
    if (selectedApp) {
      params.app_id = selectedApp.value
    }

    if (dateRange) {
      params.start_date = moment(dateRange[0]).format("YYYY-MM-DD")
      params.end_date = moment(dateRange[1]).format("YYYY-MM-DD")
    }
    getTaskLogs(searchValue? { ...params1 }:{...params})
    if(konnectId){
      getLogs({ ...logsParams }).then(res => {
        setTaskLogs(res.payload.task_logs)
      })
    }
   
    //new1()
  }, [dateRange, historyType, selectedApp, pageIndex, konnectId, logsPageIndex,searchValue])

  const handleExpandTask = taskId => {
    setLoading(true)
  //  setTaskDetailsId(taskId)
    // setExpandedItem(taskId)
    getTaskDetails(taskId).then(res => {


      // setTaskDetail(res.payload.tasks)
      if (res.type === 'GET_TASK_DETAILS_SUCCESS') {
        //console.log("success")

        setTaskDetail(res.payload.tasks)
       // console.log(res,"res121","taskDetail",data)
      }
      if (res.type === GET_TASKS_DETAILS + "_FAIL") {
        if (res.error.response.status === 422) {
          AuthTokenService.clear()
          props.history.push("/login")
        }
        setLoading(false)
      }
    })
    setLoading(false)
  }
  // const new1 = (taskId) => {
  //   getTaskDetails(taskId).then(res => {
  //   console.log(res,"res121")
  //   })
  // }
  const fetchMore = () => {
    setPageIndex(pageIndex + 1)
  }
  const fetchMoreLogs = () => {
    setLogsPageIndex(logsPageIndex + 1)
  }
  return (
    <>
      {loading && (
        <Preloader loading={loading} />
      )}
      <SearchHeader hideSearch={true} taskHistory={true}/>
      <HistoryView
      loading={loading}
        tasksList={tasksList.filter(item => item.status.toLowerCase() === historyType)}
        expandedItem={expandedItem}
        setExpandTask={handleExpandTask}
        dateRange={dateRange}
        onDateRange={onDateRange}
        historyType={historyType}
        setHistoryType={setHistoryType}
        taskDetail={taskDetail}
        taskDetails={taskDetails}
        searchApp={searchApp}
        setSelectedApp={setSelectedApp}
        selectedApp={selectedApp}
        fetchMore={fetchMore}
        fetchMoreLogs={fetchMoreLogs}
        taskDetailsId={taskDetailsId}
        setPageIndex={setPageIndex}
        setTaskDetailsId={setTaskDetailsId}
        taskLogs={taskLogs}
        setTaskLogs={setTaskLogs}
        logsList={logsList}
        logsPageIndex={logsPageIndex}
        setLogsPageIndex={setLogsPageIndex}
        setKonnectId={setKonnectId}
        pageIndex={pageIndex}
        setSearchValue={setSearchValue}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    tasksList: state.getIn(["history", "tasksList"]),
    logsList: state.getIn(["history", "logsList"]),
    taskDetails: state.getIn(["history", "taskDetails"]),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getTaskLogs: searchQuery => dispatch(getTaskLogs(searchQuery)),
    getLogs: searchQuery => dispatch(getLogs(searchQuery), "logs"),
    getTaskDetails: taskId => dispatch(getTaskDetails(taskId)),
    searchApp: searchQuery => dispatch(searchApp(searchQuery)),
  }
}

export const HistoryContainer = connect(mapStateToProps, mapDispatchToProps)(Container)
