/**
 * Total Konnectz Widget
 */
import React, { useState, useEffect } from "react"
import { Text, Flex, Image } from "rebass"
import { useTranslation } from "react-i18next"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { Icon } from "src/components"
import ActiveKonnectzIcon from "assets/images/ActiveKonnectz.png"
import InactiveKonnectzIcon from "assets/images/InactiveKonnectz.png"

export const TotalKonnectz = ({ getWidgetDetails }) => {
  const { t } = useTranslation()
  const [activeKonnects, setActiveKonnects] = useState(0)
  const [inactiveKonnects, setInactiveKonnects] = useState(0)
  const [totalKonnects, setTotalKonnects] = useState(0)

  useEffect(() => {
    getWidgetDetails("total_konnectz").then(res => {
      if (res.type === "GET_WIDGET_DETAILS_SUCCESS") {
        let details = res.payload
        setActiveKonnects(details.active_konnects)
        setInactiveKonnects(details.inactive_konnects)
        setTotalKonnects(details.total_konnects)
      }
    })
  }, [])

  return (
    <>
      <Flex
        p={{
          _: "20px",
          sm: "20px",
          md: "22px",
          lg: "24px",
          xl: "24px",
        }}
        sx={{
          width: "100%",
          flexDirection: "column",
          backgroundColor: "#FFF",
          borderRadius: "16px",
          boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
        }}
      >
        <Text
          fontSize={{ _: 18, sm: 18, md: 20, lg: 22, xl: 22 }}
          sx={{ fontWeight: "500", color: "#152F73", marginBottom: "18px" }}
        >
          {t("dashboard.widgets.totalKonnectz.headerTitle")}
        </Text>
        <Flex sx={{ flexDirection: "Row" }}>
          <Flex sx={{ flexDirection: "Column", marginRight: "41px" }}>
            <Image width={{ _: 32, sm: 32, md: 34, lg: 38, xl: 45 }} src={ActiveKonnectzIcon} />
            <Text
              fontSize={{ _: 18, sm: 18, md: 20, lg: 22, xl: 22 }}
              sx={{ fontWeight: "500", color: "#152F73", pt: "3px" }}
            >
              {activeKonnects}
            </Text>
            <Text
              fontSize={{ _: 10, sm: 10, md: 12, lg: 12, xl: 16 }}
              sx={{ fontWeight: "normal", color: "#7482A6", opacity: 0.5 }}
            >
              {t("dashboard.widgets.totalKonnectz.activeKonnectz")}
            </Text>
          </Flex>
          <Flex sx={{ flexDirection: "Column" }}>
            <Image width={{ _: 32, sm: 32, md: 34, lg: 38, xl: 45 }} src={InactiveKonnectzIcon} />
            <Text
              fontSize={{ _: 18, sm: 18, md: 20, lg: 22, xl: 22 }}
              sx={{ fontWeight: "500", color: "#152F73", pt: "3px" }}
            >
              {inactiveKonnects}
            </Text>
            <Text
              fontSize={{ _: 10, sm: 10, md: 12, lg: 12, xl: 16 }}
              sx={{ fontWeight: "normal", color: "#7482A6", opacity: 0.5 }}
            >
              {t("dashboard.widgets.totalKonnectz.inactiveKonnectz")}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
