/**
 * Sign-up container to handle state managment for sign-up pages.
 */
import React, { useState } from "react"
import { connect } from "react-redux"
import AuthShellContainer from "src/features/auth/shell/Shell.container"
import { signup, userExists } from "src/store/actions/appActions"
import { SignupView } from "./Signup.view"
import { CreatePasswordView } from "./CreatePassword.view"
import { VerificationSentView } from "./VerificationSent.view"
import signupFeatures from "src/config/features/signup-features"
import { AUTH_SIGN_UP, AUTH_USER_EXISTS } from 'config/Constants'
import { useTranslation } from "react-i18next"
import { ToastContainer, toast } from "react-toastify"

function SignupContainer(props) {
  const state = {
    email: useState(""),
    emailError: useState(false),
    emailErrorMsg: useState("auth.signup.emailInvalid"),
    isAdmin: useState(false),
    captcha: useState(false),
    screen: useState("email"),
    password: useState(""),
    passwordError: useState(false),
    passwordErrorMsg: "auth.signup.createPassword.passwordErrorMsg",
    confirmPassword: useState(""),
    loading: useState(false),
    confirmPasswordError: useState(false),
    confirmPasswordErrorMsg: useState("auth.signup.createPassword.confirmPasswordErrorMsg"),
    showPassword: useState(false),
    showConfirmPassword: useState(false),
  }

  const [screen, setScreen] = state.screen
  const [loading, setLoading] = state.loading
  const [emailErrorMsg, setEmailErrorMsg] = state.emailErrorMsg
  const [emailError, setEmailError] = state.emailError
  const { signup, userExists } = props
  const { t } = useTranslation()

  function onContinueClick() {
    setLoading(true)
    userExists({
      email: state.email[0]
    }).then(res => {
      setLoading(false)
      if (res.type === AUTH_USER_EXISTS + '_SUCCESS') {
        if (res.payload.key === 'USER_EXISTS') {
          setEmailError(true)
          setEmailErrorMsg("auth.signup.emailExists")
        } else {
          setScreen("createPasswordScreen")
        }
      } else {
        toastMessage(t("errorMessages." + res.type))
      }
    })
  }

  // Password set api handler
  function onCreatingPassword() {
    setLoading(true)
    signup({
      email: state.email[0],
      password: state.password[0],
      password_confirmation: state.confirmPassword[0],
      is_admin: state.isAdmin[0],
    }).then(res => {
      setLoading(false)
      if (res.type === AUTH_SIGN_UP + '_SUCCESS') {
        setScreen("verificationSentScreen")
      } else {
        toastMessage(t("errorMessages." + res.type))
      }
    })
  }

  // Api error toast handler
  function toastMessage(msg) {
    toast.error(msg)
  }

  return (
    <>
      <AuthShellContainer {...props} features={signupFeatures.features}>
        {screen === "email" && (
          <SignupView
            onSubmit={() => { }}
            state={state}
            onContinueClick={onContinueClick}
          />
        )}
        {screen === "createPasswordScreen" && (
          <CreatePasswordView
            loading={loading}
            title="auth.signup.createPassword.title"
            subTitle="auth.signup.createPassword.subtitle"
            state={state}
            onContinueClick={onCreatingPassword}
          />
        )}
        {screen === "verificationSentScreen" && (
          <VerificationSentView
            onSubmit={() => { }}
            state={state}
            onResendClick={onCreatingPassword}
          />
        )}
      </AuthShellContainer>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeButton={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    //user: state.getIn(['app', 'user'])
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signup: data => dispatch(signup(data)),
    userExists: data => dispatch(userExists(data)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupContainer)
