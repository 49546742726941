/**
 * Login view component to render the login UI.
 */

import React, { useEffect, useRef } from "react"
import { Box, Flex, Text } from "rebass"
import { faEnvelope, faExclamationCircle, faEye, faLock, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { Label } from "@rebass/forms"
import { Input, Button, Checkbox } from "src/components"
import { useTranslation } from "react-i18next"
import ReCAPTCHA from "react-google-recaptcha"
import { ToastContainer, toast } from "react-toastify"
import { isEmail } from "src/utils"
import px2vw from "utils/px2vw"
import { AuthTitle, AuthSubtitle } from "src/components/Typography/Typography"

export const LoginView = ({ error, state, loading, onSubmit, validation }) => {
  const [email, setEmail] = state.email
  const [emailError, setEmailError] = state.emailError
  const [password, setPassword] = state.password
  const [passwordError, setPasswordError] = state.passwordError
  const [showPassword, setShowPassword] = state.showPassword
  const [rememberMe, setRememberMe] = state.rememberMe
  const [captcha, setCaptcha] = state.captcha
  const { t } = useTranslation()
  const emailField = useRef(null)

  // Google captcha handler
  function handleGoogleCaptcha(value) {
    setCaptcha(value ? true : false)
  }

  // Password show/hide handler
  function fishEyeIconClick(e) {
    e.preventDefault()
    setShowPassword(!showPassword)
  }

  //Login sumbit handler.
  function submit() {
    if (!isEmail(email)) {
      setEmailError(true)
    } else {
      setEmailError(false)
    }

    if (!password) {
      setPasswordError(true)
    } else {
      setPasswordError(false)
    }

    if (isEmail(email) && password) {
      onSubmit()
    }
  }

  function displayError(e) {
    toast.error(t("auth.login.captchaErrorMsg"))
  }

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <AuthSubtitle>{t("auth.login.subtitle")}</AuthSubtitle>
      </Box>
      <Box
        className="border-gradient"
        sx={{
          width: "121px",
          height: "0px",
          margin: "0px auto",
          mb: px2vw(10),
          mt: px2vw(5),
        }}
      />
      <Box
        id="loginTitle"
        sx={{
          textAlign: "center",
        }}
      >
        <AuthTitle>{t("auth.login.title")}</AuthTitle>
      </Box>
      <Flex flexDirection="column" sx={{ height: "100%", mt: px2vw(25), mb: px2vw(16), justifyContent: "center" }}>
        <Box
          sx={{
            //pt: px2vw(52),
            mx: px2vw(18),
          }}
        >
          <Input
            id="email"
            ref={emailField}
            name="email"
            sxProps={{
              "& ~ span": { color: "#B7CBFF !important" },
            }}
            setError={emailError}
            errorMessage={t(state.emailErrorMsg)}
            defaultValue={email}
            onChange={e => setEmail(e.target.value)}
            required
            iconLeft={faEnvelope}
            placeholder={t("auth.login.emailPlaceholder")}
          />
        </Box>
        <Box
          sx={{
            pt: "0px",
            mx: px2vw(18),
          }}
        >
          <Input
            name="password"
            ref={state.passwordField}
            type={showPassword ? "text" : "password"}
            sxProps={{
              "& ~ span": { color: "#B7CBFF !important" },
            }}
            defaultValue={password}
            setError={passwordError}
            errorMessage={t(state.passwordErrorMsg)}
            onChange={e => setPassword(e.currentTarget.value)}
            required
            autoComplete="password"
            icon={showPassword ? faEyeSlash : faEye}
            iconClick={fishEyeIconClick}
            iconLeft={faLock}
            placeholder={t("auth.login.passwordPlaceholder")}
          />
        </Box>
        <Flex
          variant="flex.center"
          sx={{
            px: "0px",
            mx: px2vw(18),
          }}
        >
          <Label variant="wrapperLabel" sx={{ fontSize: ["xxs", "xs", "sm"], ml: px2vw(8), color: "app.secondary.20" }}>
            <Checkbox
              name="remember"
              onChange={e => {
                setRememberMe(!rememberMe)
              }}
              value={rememberMe}
            />
            {t("auth.login.rememberMe")}
          </Label>
        </Flex>
        <Box sx={{ width: "100%", mt: px2vw(25), mx: px2vw(18), mb: px2vw(15), textAlign: "center" }}>
          <ReCAPTCHA sitekey={process.env.GOOGLE_CAPTCHA_SITE_KEY} onChange={handleGoogleCaptcha} />
        </Box>
        <Button
          height={{ _: 40, sm: 40, md: 40, lg: 40, xl: 60 }}
          fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }}
          sx={{ borderRadius: "0px", mt: px2vw(10), mx: px2vw(18), fontStyle: "normal", fontWeight: 500 }}
          stretch
          disabled={loading}
          data-tracking="click"
          data-category="Login"
          data-action="User login click"
          // disabled={!((isEmail(email) && password) && captcha)}
          onClick={captcha ? submit : displayError}
        >
          {t("auth.login.loginBtnLabel")}
        </Button>
      </Flex>

      <Flex flexDirection="column" sx={{ flexGrow: 1, justifyContent: "flex-end", alignItems: "center" }}>
        <Box sx={{ pt: px2vw(15), textAlign: "center" }}>
          <Text
            fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }}
            sx={{ display: "inline-block", fontStyle: "normal", fontWeight: "500", color: "app.primary.30" }}
          >
            <a href="/forgot-password" style={{ color: "#152F73", textDecoration: "none" }}>
              {t("auth.login.forgotPasswordLbl")}
            </a>
          </Text>
        </Box>
        <Box sx={{ pt: px2vw(15), textAlign: "center" }}>
          <Text
            fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }}
            sx={{ display: "inline-block", fontStyle: "normal", fontWeight: "500", color: "app.secondary.20" }}
          >
            {t("auth.login.alreadyHaveAccountText")}
            <a href="/signup" style={{ color: "#F7C545" }}>
              {t("auth.login.signupNowText")}
            </a>
          </Text>
        </Box>
      </Flex>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        closeButton={false}
      />
    </>
  )
}
