import * as React from "react"
import { noop } from "../"
import { LinkDefault } from "./Link.default"
import { getLinkPosition } from "./utils"

export const LinkWrapper = React.memo(
  ({
    config,
    Component = LinkDefault,
    link,
    onLinkMouseEnter,
    onLinkMouseLeave,
    onDeleteLink,
    onLinkClick,
    isSelected,
    isHovered,
    fromNode,
    toNode,
    matrix = [[]],
    chart,
    offset,
  }) => {
    const _fromPort = fromNode.configResponses.find(x => x.port && x.port.id === link.from.port)
    const startPos = getLinkPosition(fromNode, _fromPort, link.from.type)
    const fromPort = link.from

    const _toPort = toNode && toNode.configResponses.find(x => x.port && x.port.id === link.to.port)

    const endPos = toNode && link.to.port ? getLinkPosition(toNode, _toPort, "target") : link.to.position
    const toPort = toNode && link.to.port ? link.to : undefined

    // Don't render the link yet if there is no end pos
    // This will occur if the link was just created
    if (!endPos) {
      return null
    }
    return (
      <Component
        config={config}
        link={link}
        matrix={matrix}
        startPos={startPos}
        endPos={endPos}
        fromPort={fromPort}
        toPort={toPort}
        onLinkMouseEnter={config.readonly ? noop : onLinkMouseEnter}
        onLinkMouseLeave={config.readonly ? noop : onLinkMouseLeave}
        onLinkClick={config.readonly ? noop : onLinkClick}
        isSelected={isSelected}
        isHovered={isHovered}
        chart={chart}
        offset={offset}
        onDeleteLink={onDeleteLink}
      />
    )
  }
)
