import UnlimitedSteps from "assets/images/features/unlimited-steps.png"
import VisualizeEverything from "assets/images/features/visualize-everything.png"
import IntegrationLibrary from "assets/images/features/integration-library.png"
import SimpleAutomation from "assets/images/features/simple-automation.png"
import PlatformForEveryone from "assets/images/features/platform-for-everyone.png"
import SeamlessAppsIntegration from "assets/images/features/seamless-apps-integration.png"

export const german = {
  translation: {
    common: {
      app: "Konnectz",
    },
    header: {
      topNavigations: {
        tipsAndTricks: "Tipps und Tricks",
        pricing: "Preisgestaltung",
        blogs: "Blogs",
        helpCenter: "Hilfe-Center",
       
      },
      searchAppTextPlaceholder: "Suche hier...",
      createKonnectzBtnLabel: "Konnectz erstellen",
      taskhistory: "Aufgabenverlauf",
      success: "Erfolg",
      error: "Fehler",
      notask: "Keine Aufgabe gefunden",
      tryagain: "Passen Sie Ihren Filter an und versuchen Sie es erneut"
    },
    errorMessages: {
      SENT_RESET_INSTRUCTIONS_SUCCESS: "Die Anweisungen zum Zurücksetzen des Passworts wurden per E-Mail versandt, bitte prüfen Sie Ihre E-Mail.",
      SENT_RESET_INSTRUCTIONS_FAIL: "Unautorisierte Anfrage!",
      VERIFY_EMAIL_FAIL: "Sorry, Ihre E-Mail konnte nicht bestätigt werden.",
      PASSWORD_NOT_UPDATED: "Sorry, Ihr Konto konnte nicht aktualisiert werden",
      GET_FORGOT_PASSWORD_LINK_SUCCESS: "",
      GET_FORGOT_PASSWORD_LINK_FAIL: "Das Senden der Passwortanweisungen ist fehlgeschlagen. Bitte versuchen Sie es nach einiger Zeit erneut !",
      AUTH_SIGN_UP_FAIL: "Etwas ist schief gelaufen. Bitte versuchen Sie es nach einiger Zeit erneut !",
      somethingWentWrong: "Etwas ist schief gelaufen. Bitte versuchen Sie es nach einiger Zeit erneut!",
    },
    successMessages: {
      PASSWORD_UPDATED: "Das Passwort wurde aktualisiert. Bitte melden Sie sich mit Ihrem neuen Passwort an!",
    },
    history:{
        task:{
          task: "Aufgabe",
          tasks: "Aufgaben",
          taskdate: "Aufgabendatum : ",
          user: "Nutzer : "
        }
    },
    auth: {
      signup: {
        createPassword: {
          title: "Passwort erstellen",
          subtitle: "Tippen Sie sie zur Bestätigung ZWEIMAL ein",
          passwordErrorMsg: "Ungültiges Passwort !",
          confirmPasswordErrorMsg: "Ungültiges Passwort!",
          passwordMatchingErrorMsg: "Beide Kennwörter stimmen nicht überein !",
          continueBtnLabel: "FORTSETZEN",
          createPasswordPlaceholder: "Passwort erstellen",
          confirmPasswordPlaceholder: "Passwort bestätigen",
          passwordRule:
            "Hinweis: Ihr Passwort muss zwischen 8 und 15 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen enthalten. ",
          passwordRule1: "Ihr Passwort muss zwischen 8 und 15 Zeichen lang sein.",
          passwordRule2: "Ihr Passwort muss mindestens einen Großbuchstaben enthalten",
          passwordRule3: "Ihr Passwort muss mindestens eine Ziffer enthalten",
          passwordRule4: "Ihr Passwort muss mindestens ein Sonderzeichen enthalten.",
        },
        verificationSent: {
          title: "ÜBERPRÜFUNG",
          subtitle: "E-Mail-Adresse überprüfen",
          verificationText1: "Wir haben den Verifizierungslink am ",
          verificationText2: ", öffnen Sie die E-Mail und verifizieren Sie Ihre E-Mail-Adresse.",
          didntReceiveEmailText: "Didn't recive any mail? ",
          resendText: "Erneut senden",
        },
        verifySuccess: {
          title: "Überprüfung abgeschlossen",
          verifyFailedTitle: "Verifizierung fehlgeschlagen",
        },
        signupSuccess: {
          firstName: "Vorname",
          firstNameErrorMsg: "Vorname ist erforderlich !",
          lastName: "Nachname",
          lastNameErrorMsg: "Nachname ist erforderlich !",
          verifyErrorMsg: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie, sich anzumelden, wenn Ihr Konto bereits verifiziert wurde.",
        },
        title: "Erstellen Sie Ihr Konto",
        subtitle: "Hey, willkommen",
        verifyingMessage: "Sie verifizieren",
        emailInvalid: "E-Mail ist ungültig!",
        emailExists: "Die von Ihnen eingegebene E-Mail-Adresse ist bereits registriert",
        teamSignup: "Sich als Team anmelden?",
        continueBtnLabel: "Fortfahren",
        alreadyHaveAccountText: "Haben Sie ein Konto? ",
        loginBtnLabel: "Anmelden",
        emailPlaceholder: "E-Mail eingeben",
        features: {
          feature1: {
            title: "Einfache Automatisierung",
            image: SimpleAutomation,
            description: "Erstellen Sie komplexe Arbeitsabläufe einfach und leicht in wenigen Minuten mit unserem visuellen Connect Builder",
          },
          feature2: {
            title: "Plattform für alle",
            image: PlatformForEveryone,
            description:
              "Kein normales Automatisierungstool nur für Programmierer, jeder kann mit KonnectzIT Automatisierung erstellen",
          },
          feature3: {
            title: "Nahtlose Integration von Apps",
            image: SeamlessAppsIntegration,
            description: "Verbinden Sie unbegrenzt viele Anwendungen aus unserer Integrationsbibliothek und automatisieren Sie tägliche Aufgaben",
          },
        },
      },
      login: {
        title: "In Ihrem Konto anmelden",
        subtitle: "Hey, willkommen zurück!",
        emailPlaceholder: "E-Mail eingeben",
        emailInvalid: "Ungültige E-Mail!",
        passwordRequired: "Passwort ist erforderlich!",
        passwordPlaceholder: "Passwort eingeben",
        rememberMe: "Erinnere dich an mich",
        loginBtnLabel: "JETZT ANMELDEN",
        forFirstTimeSignup: "Zum ersten Mal ? ",
        forgotPasswordLbl: "Passwort vergessen",
        alreadyHaveAccountText: "Sie haben noch kein Konto? ",
        clickingContinueText: "Indem Sie auf Weiter klicken, stimmen Sie unserem ",
        termsAndConditionsText: "Bedingungen und Konditionen.",
        signupNowText: "Anmelden",
        features: {
          feature1: {
            title: "Unbegrenzte Schritte",
            image: UnlimitedSteps,
            description: "Verbinden Sie mehrere Anwendungen und erstellen Sie unbegrenzte Schritte für eine nahtlose Automatisierung.",
          },
          feature2: {
            title: "Alles visualisieren",
            image: VisualizeEverything,
            description: "Erstellen Sie Ihre Arbeitsabläufe und stellen Sie sich die Automatisierung mithilfe eines visuellen Connect Builders einfach vor.",
          },
          feature3: {
            title: "Integrationsbibliothek",
            image: IntegrationLibrary,
            description: "Sammlung von Cloud-Anwendungen zum Zusammenführen und Senden von Daten zwischen ihnen, um Ihre Zeit zu sparen.",
          },
        },
        loginFailMsg: "Benutzername oder Passwort ist ungültig!",
        captchaErrorMsg: "Captcha-Fehler",
      },
      forgot: {
        title: "Passwort vergessen",
        forgtoBtnLabel: "Link zum Passwort vergessen",
        successSubTitle: "E-Mail gesendet",
        features: {
          feature1: {
            title: "Einfache Automatisierung",
            image: SimpleAutomation,
            description: "Erstellen Sie komplexe Arbeitsabläufe einfach und leicht in wenigen Minuten mit unserem visuellen Connect Builder",
          },
          feature2: {
            title: "Plattform für alle",
            image: PlatformForEveryone,
            description:
              "Kein normales Automatisierungstool nur für Programmierer, jeder kann mit KonnectzIT Automatisierung erstellen",
          },
          feature3: {
            title: "Nahtlose Integration von Apps",
            image: SeamlessAppsIntegration,
            description: "Verbinden Sie unbegrenzt viele Anwendungen aus unserer Integrationsbibliothek und automatisieren Sie tägliche Aufgaben",
          },
        },
        emailInvalid: "Ungültige E-Mail!",
      },
      reset: {
        title: "Passwort zurücksetzen",
        subTitle: "Neues Passwort erstellen",
      },
    },
    konnect: {
      sidebar: {
        search_apps: "Suche...",
        choose_trigger: "Auslöser wählen",
        choose_trigger_header: "App-Ereignis auswählen",
        no_triggers_available: "Keine Auslöser verfügbar",
        choose_format_header: "Formatoption",
        choose_config_header: "Konfiguration wählen",
        select_trigger_action_dropdown_header: "Select",
        choose_option_header: "Option definieren",
        no_options_available: "Keine Optionen verfügbar",
        choose_linked_account: "Konto wählen",
        customize_form_header: "Formular konfigurieren",
        customize_form_partial: "Wählen Sie ",
        configure_response: "Antwort anpassen",
        basic_auth: "Basis-Authentifizierung",
        api_key: "API-Schlüssel",
        api_token: "API-Geheimnis",
        webhook_url: "Webhook-URL",
        webhook_url_toast: "Webhook-URL in die Zwischenablage kopiert!",
        webhook_response: "Webhook-Antwort",
        choose_action_event: "Wählen Sie ein Aktionsereignis",
        endpoint_url: "Endpunkt-URL",
        payload_type: "Nutzlasttyp",
        wrap_request_array: "Anfrage in Array verpacken",
        set_params: "Params setzen",
        add_header: "Kopfzeile hinzufügen",
        set_paramter: "Parameter setzen",
        expiry_time: "Timeline-Zählung",
        expiry_val: "Zeitleiste",
        validate: "Bestätigen",
        generate: "Generieren",
      },
      UI: {
        betaText: "Beta",
        webhookDuplicateToastMsg: "Webhook-App existiert bereits!",
      },
      header: {
        backBtnLabel: "Zurück",
        saveBtnLabel: "Speichern",
        publishBtnLabel: "Veröffentlichen",
        nameOfKonnectz: "Name der Konnectz",
      },
      testAndReview: {
        btnLabel: "Test & Überprüfung",
      },
      proceed: {
        btnLabel: "VORGANG",
      },
      additionalConfigsModal: {
        cancelBtnLabel: "Abbrechen",
        okBtnLabel: "OK",
      },
      conditions: {
        addNewConditionBtnLabel: "Neue Bedingung hinzufügen +",
        cancelBtnLabel: "Abbrechen",
        okBtnLabel: "OK",
        operatorAndLabel: "UND",
        operatorOrLabel: "ODER",
        headerConditionText: "Bedingung",
        selectFieldHeaderTitle: "Feld auswählen",
        selectConditionHeaderTitle: "Bedingung auswählen",
        validateBtnLabel: "Validieren",
        conditionsHeaderText: "Bedingungen",
      },
      publishSuccessMsg: "Konnect wurde erfolgreich veröffentlicht !",
      saveSuccessMsg: "Konnect wurde erfolgreich gespeichert !",
    },
    apps: {
      authenticatedAppsTitle: "Authentifizierte Anwendungen",
      aunthenticateNowTitle: "Jetzt authentifizieren",
      noAppsToAunthenticate: "Im Moment gibt es keine zu authentifizierenden Anwendungen.",
      authenticateNow: "Sie haben keine authentifizierten Anwendungen. Authentifizieren Sie sich jetzt.",
      addAccountText: "Konto hinzufügen",
      betaText: "Beta",
      oAuthHandlerText: "KonnectzIT OAuth Handler, Bitte warten...",
      cancelBtnText: "Abbrechen",
      yesContinueText: "Ja, weiter",
      requiredText: "(Erforderlich)",
      accessAPIModalHeaderText1: "Erlaube KonnectzIT den Zugriff auf ",
      accessAPIModalHeaderText2: "Konto?",
      accessAPIModalMainText1: "Sie finden Ihre ",
      accessAPIModalMainText2: " durch Navigieren zu ",
      accessAPIModalMainText3: "Integrationsseite",
      authorized: "Autorisierte Apps"
      
    },
    konnectzList: {
      homeText: "Zuhause",
      noKonnectzInfo: "Im Moment keine Verbindung!",
      deleteModalBodyText: "Sind Sie sicher, dass Sie löschen wollen?",
      deleteModalNoText: "Nein",
      deleteModalDeleteText: "Löschen",
      folders: {
        createFolderTitleText: "Vergeben Sie einen Namen für Ihren Ordner",
        createFolderInputPlaceholder: "Ordnername",
        createFolderBtn: "Erstellen",
        deleteFolderConfirmationText: "Sind Sie sicher, dass Sie diesen Ordner löschen wollen?",
        deleteBtn: "Löschen",
        selectFolderInfo: "Wählen Sie den Ordner aus, in den Sie die ausgewählten Verbindungen verschieben möchten",
        moveFolderCancelBtn: "Abbrechen",
        moveBtn: "Bewegen",
        renameSaveBtn: "Speichern",
        renameYourFolderText: "Benennen Sie Ihren Ordner um",
        createFolderBtn: "Ordner erstellen"
      }
    },
    dashboard: {
      widgets: {
        latestApps: {
          headerTitle: "Neueste Apps",
        },
        totalKonnectz: {
          headerTitle: "Gesamt Konnectz",
          activeKonnectz: "Aktiv Konnectz",
          inactiveKonnectz: "Inaktiv Konnectz",
        },
        latestKonnectz: {
          headerTitle: "Neueste Konnectz",
        },
        subscription: {
          contentText: "Schalten Sie mehr Leistung frei, indem Sie auf Pro upgraden",
          upgradeBtnLabel: "JETZT UPGRADEN",
        },
        taskConsumption: {
          headerTitle: "Aufgabenverbrauch",
        },
      },
    },
    account: {
      countryRequiredText: "Land ist erforderlich!",
      addressLine1RequiredText: "Adresszeile 1 ist erforderlich!",
      cityRequiredText: "Stadt ist erforderlich!",
      stateRequiredText: "Staat ist erforderlich!",
      zipCodeRequiredText: "PLZ ist erforderlich!",
      taxIdRequiredText: "Steuer-ID ist erforderlich!",
      cityLabel: "Stadt",
      stateLabel: "Bundesland",
      zipCodeLabel: "Postleitzahl",
      taxIdLabel: "Steuer ID",
      addressLine2Label: "Adresszeile 2",
      addressLine1Label: "Anschrift Zeile 1",
      countryLabel: "Land",
      billingtext:"Rechnungsadresse",
      myProfileText: "Mein Profil",
      emailAddressLabel: "E-Mail-Addresse",
      firstNameLabel: "Vorname",
      lastNameLabel: "Nachname",
      firstNameRequiredText: "Vorname ist erforderlich!",
      lastNameRequiredText: "Nachname ist erforderlich!",
      companyNameLabel: "Name des Unternehmens",
      timeZoneLabel: "Zeitzone",
      changeLanguageLabel: "Sprache ändern",
      changePasswordHeaderText: "Passwort ändern",
      currentPasswordLabel: "Aktuelles Passwort",
      currentPasswordRequiredText: "Aktuelles Passwort ist erforderlich!",
      newPasswordLabel: "Neues Passwort",
      newPasswordRequiredText: "Neues Passwort ist erforderlich!",
      confirmPasswordLabel: "Passwort bestätigen",
      confirmPasswordRequiredText: "Passwort bestätigen ist erforderlich!",
      updateBtnLabel: "Aktualisieren",
      editUpdateAccountSideBarText: "Konto bearbeiten/aktualisieren",
      changePasswordSideBarText: "Passwort ändern",
      billingText: "Abrechnung",
      teamText: "Mannschaft",
      agencyText: "Agentur",
      sideBarHeaderAccountText: "Konto",
      profileUpdatedSuccessfullyText: "Profil erfolgreich aktualisiert!",
      addTeamMemberText: "Teammitglied hinzufügen",
      addSubAccountsText: "Unterkonten hinzufügen",

      billing: {
        couponCodeText: "Code",
        applyCodeBtnLbl: "Code anwenden",
        tasksText: "Aufgaben",
        redeemedOnText: "Erlöst am",
        expiresOnText: "Läuft ab am",
        noCouponsAppliedText: "Keine Lizenz angewandt",
        plansAndFeaturesText: "Pläne und Merkmale",
        currentPlanText: "Aktueller Plan",
        upgradeText: "Aktualisierung",
        billingText: "Abrechnung",
        licenseerror:"Ungültige Lizenz!",
        completionofprofile:"Bitte vervollständigen Sie Ihr Profil, bevor Sie den Code einlösen. Dies ist für GPDR-/Datenverarbeitungs- und Abrechnungszwecke erforderlich",
      },
      team: {
        name: "Name",
        firstName: "Vorname",
        lastName: "Nachname",
        access: "Zugang",
        email: "Email",
        addBtn: "Hinzufügen",
        cancelBtn: "Abbrechen",
        assignAccessLbl: "Zugang zuweisen",
        noUsersInfo: "Keine Benutzer verfügbar",
        teamMembersHeaderText: "Teammitglieder",
      },
      agency: {
        name: "Name",
        firstName: "Vorname",
        lastName: "Nachname",
        tasks: "Aufgabe zugewiesen :",
        consumed:"Aufgabe verbraucht :",
        totalSubaccounts: "Unterkonten insgesamt:",
        email: "Email :",
        active: "Aktiv :",
        inActive: "Inaktiv :",
        addBtn: "Hinzufügen",
        cancelBtn: "Abbrechen",
        noAgenciesInfo: "Keine Agenturen verfügbar",
        agencyMembersHeaderText: "Agenturmitglieder",
        total:"Gesamtaufgaben :",
        update:"aktualisieren",
        updateTask: "aktualisieren Aufgaben",
        delete: "Löschen",
        available: "Aufgabe zur Verfügung",
        deactivate: "aktivieren Sie",
        activate: "deaktivieren",
        subaccounterror:"Die Details des Unterkontos konnten nicht aktualisiert werden!"
 
      },
    },
    sideNav: {
      homeLabel: "Zuhause",
      appsLabel: "Anwendungen",
      konnectzLabel: "Konnectz",
      historyLabel: "Geschichte",
      accountLabel: "Konto",
      notificationsLabel: "Benachrichtigungen",
      logoutLabel: "Abmeldung",
      getHelpLabel: "Hilfe bekommen?",
      konnectzITLogoText: "KonnectzIT",
    },
  },
}
