import React, { useState } from 'react'
import { Box, Text, Flex, Link } from "rebass"
import { Span, Icon, Input, Button } from "src/components"
import { useTranslation } from "react-i18next"

export const ApplyCoupon = ({ applyCoupon }) => {
    const { t } = useTranslation()
    const [code, setCode] = useState(null)

    return (
        <Flex flexDirection="column" sx={{ m: '10px', mb: '30px',mt:"20px" }}>
            <Text sx={{ color: '#152F73', fontSize: "16px" ,mb:"5px"}}>
             Apply New
                {t("account.billing.couponCodeText")}
            </Text>
            <Flex flexDirection="row">
                <Input
                    label={''}
                    sxProps={{
                        height: "40px !important",
                        backgroundColor: "#F3F7FF",
                        // border: "1px solid #B7CBFF",
                        borderRadius: "18px",
                        color: "#000",
                        width: 240,
                        "& ~ .placeholder": {
                            color: "#aaa !important",
                            left: '50px'
                        }
                    }}
                    defaultValue={code}
                    value={code}
                    onChange={e => {
                        setCode(e.currentTarget.value)
                    }}
                    placeholder="i.e ASFDSF"
                />
                <Button
                    sx={{ ml: 30, color: '#fff', minWidth: 140, fontWeight:"300",backgroundColor:"gray"}}
                    onClick={() => {
                        applyCoupon(code)
                        setCode('')
                    }}
                >
                    {t("account.billing.applyCodeBtnLbl")}
                </Button>
            </Flex>
        </Flex>
    )
}