import React from "react"
import { Flex, Box } from "rebass"
import { useTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"

import { Text, Button } from "src/components"
import './stylee.css'
export const CouponsList = ({ coupons }) => {
  const { t, i18n } = useTranslation("translation")

  return (
    <>
      <Text sx={{ mb: "20px", fontSize: ["h1", "h1", "h2"], color: "#222222" }}>
        Active Codes
        {/* {t("account.billing.billingText")} */}
      </Text>
      <Flex sx={{ width: 470, bg: "#fff" ,border:"1px solid #eae3e3",borderRadius:"10px" }} flexDirection="column">
        <Flex sx={{ width: "100%", flexDirection: "row", bg: "#F3F7FF", color:"#152F73",height: 60, alignItems: "center", pl: 20 ,borderTopLeftRadius:"10px",borderTopRightRadius:"10px"}}>
          <Box width="30%" sx={{ fontWeight: 400 }}>
            {t("account.billing.couponCodeText")}
          </Box>
          {/* <Box width="15%" sx={{ fontWeight: 600 }}>{t('account.billing.tasksText')}</Box> */}
          <Box width="30%" sx={{ fontWeight: 400 }}>
            {t("account.billing.redeemedOnText")}
          </Box>
          <Box width="25%" sx={{ fontWeight: 400 }}>
            {t("account.billing.expiresOnText")}
          </Box>
        </Flex>
        {(!coupons || coupons?.length === 0) && (
          <Text fontSize="18px" sx={{ ml: 10, mt: 10, fontWeight: "500" }}>
            {t("account.billing.noCouponsAppliedText")}
          </Text>
        )}
        <Box sx={{overflowY:"scroll",height:"200px"}}>
        {coupons?.map((coupon, idx) => (
          <Flex sx={{ width: "100%", pl: 20, my: 10}}>
            <Box sx={{color:"blue"}}  data-tip={coupon.code} data-for={coupon.code + ""} width="30%">
              {coupon.code.length > 12 ? coupon.code.substring(0, 12) + "..." : coupon.code}
              <ReactTooltip id={coupon.code + ""} effect="solid" place="bottom"></ReactTooltip>
            </Box>
            {/* <Box width="15%">{coupon.task}</Box> */}
            <Box sx={{color:"gray"}}  width="30%">{coupon.redeemed_on?.substr(0, coupon.redeemed_on.indexOf("T"))}</Box>
            <Box sx={{color:"gray"}}  width="25%">{coupon.expires_on && coupon.expires_on === 'LTD' ? "LTD" : coupon.expires_on.substr(0, coupon.expires_on.indexOf("T"))}</Box>
          </Flex>
        ))}
        </Box>
       
      </Flex>
    </>
  )
}
