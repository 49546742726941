import React from "react"
import { Box } from "rebass"

export const CommonOuter = ({ node, reviewed, nodeTested }) => {
  //let nodeReviewed = node.tested ? node.reviewed : null
  return (
    <> 
      {nodeTested ? (
        <Box
          sx={{
            background: reviewed ? "rgba(22, 247, 137, 0.22)" : "rgba(244, 67, 54, 0.12)",
            borderRadius: "240px",
            height: "90px",
            marginBottom: "-45px",
            textAlign: "center", 
            padding: "10px",
            position: "absolute",
            width: "100%",
            top: "-40px",
            zIndex: -20,
          }}
        >
          <span style={{ color: reviewed ? "#0F9D58" : "#F44336", fontSize: "14px", fontWeight: "500" }}>
            {node.display_message || node.error}
          </span>
        </Box>
      ) : null}
    </>
  )
}
