import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { Flex, Box, Image } from "rebass"
import { AccountView, PasswordView, Billing } from "./Account.view"
import { Text, Button, Preloader } from "src/components"
import px2vw from "utils/px2vw"
import { useTranslation } from "react-i18next"
import {
  getProfile,
  updateProfile,
  updatePassword,
  addTeamMember,
  addAgencyMember,
  addResellerMember,
  getTeamUsersList,
  getAgencyUsersList,
  getResellerUsersList,
  updateAgencyMember,
} from "src/store/actions"
import { ToastContainer, toast } from "react-toastify"
import { isValidPassword } from "src/utils"
import DelIco from "src/assets/images/profile-delete"
import EditIco from "src/assets/images/profile-edit"
import axios from "axios"
import AuthTokenService from "src/utils/AuthTokenService"
import { BillingContainer } from "./Billing.container"
import { TeamContainer } from "./Team.container"
import { ResellerContainer } from "./Reseller.container"
import { AgencyContainer } from "./Agency.container"
import { Modal } from "react-responsive-modal"
import { AddTeamMemberModal } from "src/components/Team/addTeamMemberModal"
import { AddAgencyMemberModal } from "src/components/Agency/addAgencyMemberModal"
import { AddResellerMemberModal } from "src/components/Reseller/addResellerMemberModal"
import EditIcon from "src/assets/images/Edit_icon.svg"
import DisconnectIcon from "src/assets/images/Disconnect_icon.svg"
import DeleteIcon from "src/assets/images/Delete_icon.svg"
import Dollar from "../../assets/images/Dollar.svg"
import Lockk from "../../assets/images/Lockk.svg"
import LockSmall from "../../assets/images/LockSmall.svg"
import DollarGray from "../../assets/images/DollarGray.svg"
import People from "../../assets/images/People.svg"
import PeopleGray from "../../assets/images/PeopleGray.svg"
import Right from "../../assets/images/Right.svg"
import RightGray from "../../assets/images/RightGray.svg"
import Profilee from "../../assets/images/Profilee.svg"
import ProfileeGray from "../../assets/images/ProfileeGray.svg"
import Cross from "../../assets/images/cross.svg"
import avatar from "../../assets/images/avatar.jpg"
import { svg_1 } from "../../assets/images/svg"
export const AccountContainer_ = props => {
  const {
    profile,
    getProfile,
    updateProfile,
    totalSubAccounts,
    allowedSubaccountsLimit,
    updatePassword,
    history,
    addTeamMember,
    addAgencyMember,
    addResellerMember,
    getTeamUsersList,
    getAgencyUsersList,
    getResellerUsersList,
    agencyUsersList,
    users,
    disabled,
    updateAgencyMember,
  } = props
  const { t, i18n } = useTranslation("translation")
  const [selectedIndex, setSelectedIndex] = useState(1)
  const [openTeamMemberModal, setOpenTeamMemberModal] = useState(false)
  const [openAgencyMemberModal, setOpenAgencyMemberModal] = useState(false)
  const [openResellerMemberModal, setOpenResellerMemberModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [button, setButton] = useState(true)
  const accountState = {
    firstName: useState(profile.first_name),
    lastName: useState(profile.last_name),
    company: useState(profile.company_name),
    currentPosition: useState(profile.current_position),
    addressLine1: useState(profile.address_line1),
    addressLine2: useState(profile.address_line2),
    city: useState(profile.city),
    state: useState(profile.state),
    country: useState(profile.country),
    zipCode: useState(profile.zip_code),
    taxId: useState(profile.tax_id),
    email: useState(profile.email),
    timeZone: useState(profile.timezone),
    lang: useState(profile.preferred_language),
    errors: useState({}),
  }

  useEffect(() => {
    getProfile().then(res => {
      if (res.type === "GET_PROFILE_FAIL") {
        if (res.error.response.status === 422) {
          AuthTokenService.clear()
          history.push("/login")
        }
      }
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    accountState.firstName[1](profile.first_name)
    accountState.lastName[1](profile.last_name)
    accountState.company[1](profile.company_name)
    accountState.currentPosition[1](profile.current_position)
    accountState.addressLine1[1](profile.address_line1)
    accountState.addressLine2[1](profile.address_line2)
    accountState.country[1](profile.country)
    accountState.city[1](profile.city)
    accountState.state[1](profile.state)
    accountState.zipCode[1](profile.zip_code)
    accountState.taxId[1](profile.tax_id)
    accountState.email[1](profile.email)
    accountState.timeZone[1](profile.timezone)
    accountState.lang[1](profile.preferred_language)
    setLoading(false)
  }, [profile])

  useEffect(() => {
    setLoading(true)
    getAgencyUsersList()
    getResellerUsersList()
    setLoading(false)
  }, [])

  const profileReqd = ["firstName", "lastName"]

  const profileReqdAdd = ["state", "country", "addressLine1", "city", "zipCode"]
  const passwordReqd = ["new", "current", "confirm"]

  const passwordState = {
    current: useState(""),
    new: useState(""),
    confirm: useState(""),
    errors: useState({}),
  }

  const updateHandler = () => {
    setLoading(true)
    let validationError = false
    let errors = {}

    if (selectedIndex == 1) {
      errors = { ...accountState.errors[0] }
      profileReqd.map(prop => {
        if (!Boolean(accountState[prop][0])) {
          validationError = true
          errors[prop] = true
          setLoading(false)
        } else {
          errors[prop] = false
        }
      })
      accountState.errors[1](errors)

      if (!validationError) {
        updateProfile({
          first_name: accountState.firstName[0],
          last_name: accountState.lastName[0],
          company_name: accountState.company[0],
          current_position: accountState.currentPosition[0],
          address_line1: accountState.addressLine1[0],
          address_line2: accountState.addressLine2[0],
          country: accountState.country[0],
          city: accountState.city[0],
          state: accountState.state[0],
          zip_code: accountState.zipCode[0],
          tax_id: accountState.taxId[0],
          timezone: accountState.timeZone[0],
          preferred_language: accountState.lang[0],
        }).then(res => {
          AuthTokenService.updateLang(accountState.lang[0])
          i18n.changeLanguage(accountState.lang[0])
          toast.info(t("account.profileUpdatedSuccessfullyText"))
          getProfile()
          setLoading(false)
        })
      }
    } else if (selectedIndex == 2) {
      errors = { ...passwordState.errors[0], currentError: "", newError: "", confirmError: "" }
      errors["new-error"] = ""
      passwordReqd.map(prop => {
        if (!Boolean(passwordState[prop][0])) {
          validationError = true
          errors[prop] = true
          setLoading(false)
        } else {
          errors[prop] = false
        }
      })
      if (!validationError) {
        if (!isValidPassword(passwordState.new[0])) {
          validationError = true
          errors.newError = "auth.signup.createPassword.confirmPasswordErrorMsg"
          setLoading(false)
        } else if (passwordState.new[0] !== passwordState.confirm[0]) {
          validationError = true
          setLoading(false)
          errors.confirmError = "auth.signup.createPassword.passwordMatchingErrorMsg"
        }

        if (!validationError) {
          updatePassword({
            current_password: passwordState.current[0],
            password: passwordState.new[0],
            password_confirmation: passwordState.confirm[0],
          }).then(resp => {
            if (resp.payload.key === "UPDATE_FAILED") {
              errors.currentError = resp.payload.message //"Invalid password entered!"
              passwordState.errors[1]({ ...errors })
            } else {
              toast.info("Password updated successfully!")
              passwordState.current[1]("")
              passwordState.new[1]("")
              passwordState.confirm[1]("")
              setLoading(false)
            }
          })
        }
      }

      passwordState.errors[1]({ ...errors })
      //setLoading(false)
    } else if (selectedIndex === 3) {
      errors = { ...accountState.errors[0] }
      profileReqdAdd.map(prop => {
        if (!Boolean(accountState[prop][0])) {
          validationError = true
          errors[prop] = true
          setLoading(false)
        } else {
          errors[prop] = false
        }
      })
      accountState.errors[1](errors)

      if (!validationError) {
        updateProfile({
          first_name: accountState.firstName[0],
          last_name: accountState.lastName[0],
          company_name: accountState.company[0],
          current_position: accountState.currentPosition[0],
          address_line1: accountState.addressLine1[0],
          address_line2: accountState.addressLine2[0],
          country: accountState.country[0],
          city: accountState.city[0],
          state: accountState.state[0],
          zip_code: accountState.zipCode[0],
          tax_id: accountState.taxId[0],
          timezone: accountState.timeZone[0],
          preferred_language: accountState.lang[0],
        }).then(res => {
          AuthTokenService.updateLang(accountState.lang[0])
          i18n.changeLanguage(accountState.lang[0])
          toast.info(t("account.profileUpdatedSuccessfullyText"))
          getProfile()
          setLoading(false)
        })
      }
    }
  }

  const addTeamMemberBtnClick = () => {
    setOpenTeamMemberModal(true)
  }

  const addAgencyMemberBtnClick = () => {
    setOpenAgencyMemberModal(true)
  }
  const addResellerMemberBtnClick = () => {
    setOpenResellerMemberModal(true)
  }

  const onCloseAgencyMemberModal = () => {
    setOpenAgencyMemberModal(false)
  }
  const onCloseResellerMemberModal = () => {
    setOpenResellerMemberModal(false)
  }
  const onCloseTeamMemberModal = () => {
    setOpenTeamMemberModal(false)
  }

  const onAddingTeamMember = (firstName, lastName, email, access) => {
    setLoading(true)
    addTeamMember({ firstName, lastName, email, access }).then(() => {
      getTeamUsersList()
      setLoading(false)
      setOpenTeamMemberModal(false)
    })
  }

  const onAddingAgencyMember = (firstName, lastName, email, tasks) => {
    setLoading(true)
    setOpenAgencyMemberModal(false)
    addAgencyMember({ firstName, lastName, email, tasks }).then(() => {
      getAgencyUsersList()
      setLoading(false)
    })
  }

  const onAddingResellerMember = (firstName, lastName, email, tasks) => {
    setLoading(true)
    setOpenResellerMemberModal(false)
    addResellerMember({ firstName, lastName, email, tasks }).then(() => {
      getResellerUsersList()
      setLoading(false)
    })
  }

  const fileRef = useRef(null)

  const fileUpload = e => {

    const formData = new FormData()

    formData.append("image", event.target.files[0], event.target.files[0].name)
    uploadImg(formData)
  }

  const removeImage = () => {
    const formData = new FormData()
    formData.append("image", null)
    uploadImg(formData)
  }

  const uploadImg = data => {
    axios
      .post(process.env.API_BASE_URL + "api/v1/users/profile", data, {
        headers: { "content-type": "multipart/form-data", Authorization: `Bearer ${AuthTokenService.get()}` },
      })
      .then(() => {
        getProfile()
      })
  }

  return (
    <>
      {loading && <Preloader loading={loading} />}
      <Box
        p={{ _: 20, sm: 20, md: 22, lg: 24, xl: 24 }}
        sx={{
          border: "0px solid red",
          overflowX: "hidden",

          overflowY: "auto",
        }}
      >
        <Flex flexDirection="column" sx={{ height: "100%", width: "100%", border: "0px solid red" }}>
          <Flex
            sx={{
              width: "100%",
              boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
              px: px2vw(20),
              height: "1000px",
              backgroundColor: "#fff",
              borderRadius: "20px",
            }}
            flexDirection="column"
          >
            <AccountMenu selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} profile={profile} />
            <Flex sx={{ width: "100%" }} justifyContent="flex-end" alignItems="flex-end" flexDirection="column">
              {selectedIndex === 5 ? (
                <></>
              ) : // <Button
              //   variant="secondary"
              //   width="200px"
              //   height={[30, 40, 45, 51]}
              //   fontSize={[10, 12, 16, 18]}
              //   sx={{
              //     border: "none",
              //     borderRadius: "12px",
              //     height: "46px",
              //     fontStyle: "normal",
              //     fontWeight: 500,
              //   }}
              //   onClick={addTeamMemberBtnClick}
              // >
              //   {t("account.addTeamMemberText")}1
              // </Button>
              selectedIndex === 6 ? (
                <></>
              ) : (
                <></>
                // <Button
                //   variant="secondary"
                //   width="200px"
                //   height={[30, 40, 45, 51]}
                //   fontSize={[10, 12, 16, 18]}
                //   sx={{
                //     border: "none",
                //     borderRadius: "12px",
                //     height: "46px",
                //     fontStyle: "normal",
                //     fontWeight: 500,
                //   }}
                //   onClick={updateHandler}
                // >
                //   {/* Save Changes */}
                //   {/* {t("account.updateBtnLabel")} */}
                // </Button>
              )}

              {/* {selectedIndex === 1 && (
              <Flex flexDirection="row" alignItems="center" sx={{ mt: "20px" }}>
                <Flex flexDirection="column" sx={{ mr: "15px" }}>
                  <Box title="Delete Image" sx={{ cursor: "pointer", height: "22px" }} onClick={removeImage}>
                    <DelIco />
                  </Box>
                  <span style={{ marginTop: "10px" }}></span>
                  <Box sx={{ cursor: "pointer", height: "22px" }} onClick={() => fileRef.current.click()}>
                    <EditIco />
                    <input
                      accept="image/*"
                      ref={fileRef}
                      type="file"
                      name="image"
                      style={{ display: "none" }}
                      onChange={fileUpload}
                    />
                  </Box>
                </Flex>
                <img
                  style={{ borderRadius: "16px", border: "1px solid #B7CBFF" }}
                  width="100px"
                  height="100px"
                  src={profile.image_url}
                />
              </Flex>
            )} */}
            </Flex>
            <Box>
              {selectedIndex === 1 ? (
                <>
                  <Text tabIndex={-1} sx={{ fontSize: ["h1", "h1", "h2"], m: "2%", color: "#152F73" }}>
                    Personal Details
                  </Text>
                  <Box sx={{ display: "grid", width: "100%", margin: "1%", gap: "4%", gridTemplateColumns: "20% 80%" }}>
                    <Flex flexDirection="column" alignItems="center" sx={{ width: "100%", mt: "20px" }}>
                      <Image
                        style={{ borderRadius: "16px", border: "1px solid #B7CBFF" }}
                        width="200px"
                        height="200px"
                        src={profile.image_url?profile.image_url:avatar}
                      />
                      <Flex flexDirection="row" sx={{ mt: "20px", gap: "20px" }}>
                        <Box onClick={() => fileRef.current.click()}>
                          <Flex
                            data-tip={"Edit"}
                            width={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
                            height={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                transform: "scale(1.2)",
                              },
                              transition: "transform 0.25s",
                            }}
                          >
                            {svg_1.edit}
                          </Flex>
                          <input
                            accept="image/*"
                            ref={fileRef}
                            type="file"
                            name="image"
                            style={{ display: "none" }}
                            onChange={fileUpload}
                          />
                        </Box>
                        <Flex
                          data-tip="Remove"
                          onClick={removeImage}
                          width={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
                          height={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              transform: "scale(1.2)",
                            },
                            transition: "transform 0.25s",
                          }}
                        >
                          {svg_1.delete}
                        </Flex>
                      </Flex>
                    </Flex>
                    <AccountView accountState={accountState} updateHandler={updateHandler} />
                  </Box>
                </>
              ) : selectedIndex === 2 ? (
                <PasswordView passwordState={passwordState} updateHandler={updateHandler} />
              ) :  selectedIndex === 4 ? (
                <BillingContainer {...props} />
              ) : selectedIndex === 5 ? (
                <TeamContainer {...props} addTeamMemberBtnClick={addTeamMemberBtnClick} />
              ) : selectedIndex === 7 ? (
                <ResellerContainer {...props} addResellerMemberBtnClick={addResellerMemberBtnClick} />
              ) : (
                selectedIndex === 6 && <AgencyContainer {...props} addAgencyMemberBtnClick={addAgencyMemberBtnClick} />
              )}
            </Box>
          </Flex>
        </Flex>
      </Box>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <Modal
        open={openTeamMemberModal}
        onClose={onCloseTeamMemberModal}
        center
        closeIcon={<Cross />}
        styles={{ modal: { padding: "20px", borderRadius: "16px" } }}
      >
        <AddTeamMemberModal closeModal={onCloseTeamMemberModal} onAddingTeamMember={onAddingTeamMember} />
      </Modal>
      <Modal
        open={openAgencyMemberModal}
        onClose={onCloseAgencyMemberModal}
        center
        closeIcon={<Cross />}
        styles={{ modal: { padding: "20px", borderRadius: "16px" } }}
      >
        <AddAgencyMemberModal closeModal={onCloseAgencyMemberModal} onAddingAgencyMember={onAddingAgencyMember} />
      </Modal>
      <Modal
        open={openResellerMemberModal}
        onClose={onCloseAgencyMemberModal}
        closeIcon={<Cross />}
        center
        styles={{ modal: { padding: "20px", borderRadius: "16px" } }}
      >
        <AddResellerMemberModal
          closeModal={onCloseResellerMemberModal}
          onAddingResellerMember={onAddingResellerMember}
        />
      </Modal>
    </>
  )
}

export const AccountMenu = ({ selectedIndex, setSelectedIndex, profile }) => {
  const { t } = useTranslation("translation")
  return (
    <>
      <Flex flexDirection="row" sx={{ minWidth: "100px", bg: "#fff", pt: "10px", borderBottom: "1px solid lightgray" }}>
        {/* <Text sx={{ fontWeight: "bold", fontSize: "24px" }}>{t("account.sideBarHeaderAccountText")}</Text> */}
        <Text
          sx={{
            // fontWeight: selectedIndex == 1 ? "600" : "500",
            fontSize: "16px",
            mt: "20px",
            //  mb: "10px",
            p: "3px",
            color: selectedIndex == 1 ? "#152F73" : "gray",
            borderBottom: selectedIndex == 1 ? "3px solid #142E73" : null,
            cursor: "pointer",
          }}
          onClick={() => {
            setSelectedIndex(1)
          }}
        >
          <span>
            {" "}
            {selectedIndex === 1 ? (
              <Profilee
                style={{
                  cursor: "pointer",
                  // backgroundColor: "gray",

                  margin: "2px 4px -2px 2px",
                }}
              />
            ) : (
              <ProfileeGray
                style={{
                  cursor: "pointer",
                  // backgroundColor: "gray",

                  margin: "2px 4px -2px 2px",
                }}
              />
            )}{" "}
          </span>
          {/* {t("account.editUpdateAccountSideBarText")} */}
          Profile
        </Text>
        <Text
          sx={{
            // fontWeight: selectedIndex == 2 ? "600" : "500",
            mt: "20px",
            p: "3px",
            color: selectedIndex == 2 ? "#152F73" : "gray",
            borderBottom: selectedIndex == 2 ? "3px solid #142E73" : null,
            // mb: "10px",
            fontSize: "16px",
            ml: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            setSelectedIndex(2)
          }}
        >
          <span>
            {" "}
            {selectedIndex === 2 ? (
              <Lockk
                style={{
                  cursor: "pointer",
                  // backgroundColor: "gray",

                  margin: "2px 4px -2px 2px",
                }}
              />
            ) : (
              <LockSmall
                style={{
                  cursor: "pointer",
                  // backgroundColor: "gray",

                  margin: "2px 4px -2px 2px",
                }}
              />
            )}{" "}
          </span>
          Password
        </Text>
       
        <Text
          sx={{
            // fontWeight: selectedIndex == 4 ? "600" : "500",
            mt: "20px",
            // mb: "10px",
            p: "3px",
            color: selectedIndex == 4 ? "#152F73" : "gray",
            borderBottom: selectedIndex == 4 ? "3px solid #142E73" : null,
            // mb: "20px",
            ml: "10px",
            fontSize: "16px",
            cursor: "pointer",
          }}
          onClick={() => {
            setSelectedIndex(4)
          }}
        >
          <span>
            {" "}
            {selectedIndex === 4 ? (
              <Dollar
                style={{
                  cursor: "pointer",
                  // backgroundColor: "gray",

                  margin: "2px 4px -2px 2px",
                }}
              />
            ) : (
              <DollarGray
                style={{
                  cursor: "pointer",
                  // backgroundColor: "gray",

                  margin: "2px 4px -2px 2px",
                }}
              />
            )}{" "}
          </span>
          Subscription
          {/* {t("account.billingText")} */}
        </Text>
        {profile.current_plan === "Team" && (
          <Text
            sx={{
              // fontWeight: selectedIndex == 5 ? "600" : "500",
              mt: "20px",
              p: "3px",
              // mb: "10px",
              color: selectedIndex == 3 ? "#152F73" : "gray",
              borderBottom: selectedIndex == 5 ? "3px solid #142E73" : null,
              pb: "10px",
              ml: "10px",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedIndex(5)
            }}
          >
            <span>
              {" "}
              {selectedIndex === 5 ? (
                <People
                  style={{
                    cursor: "pointer",
                    // backgroundColor: "gray",

                    margin: "2px 4px -2px 2px",
                  }}
                />
              ) : (
                <PeopleGray
                  style={{
                    cursor: "pointer",
                    // backgroundColor: "gray",

                    margin: "2px 4px -2px 2px",
                  }}
                />
              )}{" "}
            </span>
            {t("account.teamText")}
          </Text>
        )}
        {profile.current_plan === "Agency" && (
          <Text
            sx={{
              // fontWeight: selectedIndex == 6 ? "600" : "500",
              mt: "20px",
              p: "3px",
              pb: "10px",
              color: selectedIndex == 3 ? "#152F73" : "gray",
              borderBottom: selectedIndex == 6 ? "3px solid #142E73" : null,
              // mb: "20px",
              ml: "10px",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedIndex(6)
            }}
          >
            <span>
              {" "}
              {selectedIndex === 6 ? (
                <People
                  style={{
                    cursor: "pointer",
                    // backgroundColor: "gray",

                    margin: "2px 4px -2px 2px",
                  }}
                />
              ) : (
                <PeopleGray
                  style={{
                    cursor: "pointer",
                    // backgroundColor: "gray",

                    margin: "2px 4px -2px 2px",
                  }}
                />
              )}{" "}
            </span>
            {t("account.agencyText")}
          </Text>
        )}
        {profile.current_plan === "Reseller" && (
          <Text
            sx={{
              // fontWeight: selectedIndex == 7 ? "600" : "500",
              mt: "20px",
              p: "3px",
              // mb: "10px",
              color: selectedIndex == 7 ? "#152F73" : "gray",
              borderBottom: selectedIndex == 7 ? "3px solid #142E73" : null,
              pb: "10px",
              ml: "10px",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedIndex(7)
            }}
          >
            <span>
              {" "}
              {selectedIndex === 7 ? (
                <People
                  style={{
                    cursor: "pointer",
                    // backgroundColor: "gray",

                    margin: "2px 4px -2px 2px",
                  }}
                />
              ) : (
                <PeopleGray
                  style={{
                    cursor: "pointer",
                    // backgroundColor: "gray",

                    margin: "2px 4px -2px 2px",
                  }}
                />
              )}{" "}
            </span>
            Reseller
          </Text>
        )}
      </Flex>
    </>
  )
}

const mapStateToProps = state => {
  return {
    profile: state.getIn(["profile", "profile"]),
    totalSubAccounts: state.getIn(["agency", "totalSubAccounts"]),
    allowedSubaccountsLimit: state.getIn(["agency", "allowedSubaccountsLimit"]),
    agencyUsersList: state.getIn(["agency", "agencyUsersList"]),
    resellerUsersList: state.getIn(["reseller", "resellerUsersList"]),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getProfile: () => dispatch(getProfile()),
    getTeamUsersList: () => dispatch(getTeamUsersList()),
    getAgencyUsersList: () => dispatch(getAgencyUsersList()),
    getResellerUsersList: () => dispatch(getResellerUsersList()),
    updateProfile: data => dispatch(updateProfile(data)),
    addTeamMember: data => dispatch(addTeamMember(data)),
    addAgencyMember: data => dispatch(addAgencyMember(data)),
    addResellerMember: data => dispatch(addResellerMember(data)),
    updatePassword: data => dispatch(updatePassword(data)),
  }
}

export const AccountContainer = connect(mapStateToProps, mapDispatchToProps)(AccountContainer_)
