/**
 * App config action to handle the global config api.
 */

import store from "store"
import { makeRequestActionCreator } from "utils/redux"
import {
  GET_ALL_APPS,
  GET_KONNECTS_LIST,
  UPDATE_KONNECT_STATUS,
  GET_AUTHORISED_APPS,
  GET_APP_EVENTS,
  GET_ASSOCIATED_ACCOUNTS,
  GET_EVENT_CONFIGURATIONS,
  GET_EVENT_CONFIGURATIONS_DETAILS,
  GET_EVENT_CONFIGURATIONS_DETAILS_FETCH,
  GET_CONDITIONS_LIST,
  SET_APP_EVENT_CONFIG_VALUE,
  GET_EDITOR_STATE,
  GET_KONNECT_NAME,
  SET_KONNECT_NAME,
  SET_LINKED_ACCOUNT,
  SET_EDITOR_STATE,
  ONDRAGNODE,
  ONDRAGNODESTOP,
  ONDRAGNODESTART,
  ONCANVASDROP,
  ONLINKSTART,
  ONLINKMOVE,
  ONLINKCOMPLETE,
  ONLINKCANCEL,
  ONPORTPOSITIONCHANGE,
  ONLINKMOUSEENTER,
  ONLINKMOUSELEAVE,
  ONLINKCLICK,
  ONCANVASCLICK,
  ONNODECLICK,
  ONNODEMOUSEENTER,
  ONNODEMOUSELEAVE,
  ONNODESIZECHANGE,
  ONPORTTEXTCHANGE,
  SAVEKONNECT,
  TOGGLESIDEBAR,
  EDITORSCALE,
  EDITORUNDO,
  EDITORREDO,
  CLEARSTATE,
  ONDRAGCANVASSTOP,
  ONDRAGCANVAS,
  GETKONNECT,
  BASIC_AUTH,
  CAPTUREWEBHOOKRESPONSE,
  ONWEBHOOKAUTHCHANGE,
  ONDELETENODE,
  ONDELETENODEV2,
  ONSETASFIRSTNODE,
  ONDELETELINK,
  MARKSTOREDIRTY,
  EXPANDCOLLAPSENODE,
  APPTESTANDREVIEW,
  CREATEADDITIONALFIELDS,
  SET_CONFIG_MENU_STATE,
  MODIFYCONDITIONS,
  MODIFY_HTTP_METHOD,
  MODIFY_URL_ENDPOINT,
  MODIFY_PAYLOAD_TYPE,
  MODIFY_WRAP_REQUEST_IN_ARRAY,
  MODIFY_HEADERS_AND_PARAMS,
  MODIFY_EXPIRY_NUM,
  MODIFY_SEGMENT_INDEX,
  MODIFY_DURATION,
  UPDATE_GSHEET_POP_UP,
  ENABLE_TEST_REVIEW_LOADER,
  GET_ADDON_APPS
} from "config/Constants"
import { node } from "prop-types"

export const getApps = (searchQuery,authorizedOnly) => dispatch => {
  let url = "api/v1/apps"
  if (searchQuery) {

    const qs = Object.keys(searchQuery)
    .map(key => `${key}=${searchQuery[key]}`)
    .join("&")
   
    url = url + `?${qs}`
   // url = url + "?search_query=" + searchQuery
    if (authorizedOnly) {
      url = url + "&authorized_only=" + authorizedOnly
    }
  }
  const request = makeRequestActionCreator(GET_ALL_APPS)
  return dispatch(
    request({
      url: url,
      method: "GET",
      searchQuery
    })
  )
}

export const getAddonApps = () => dispatch => {
  let url = "api/v1/apps/add_on_apps"
 

  const request = makeRequestActionCreator(GET_ADDON_APPS)
  return dispatch(
    request({
      url: url,
      method: "GET",
    })
  )
}

export const getKonnectsList = ( searchQuery,) => dispatch => {
  let url = "api/v1/konnects"
  // if (searchQuery) {
  //   url = url + "?search_query=" + searchQuery
  //   if (page) {
  //     const qs = Object.keys(searchQuery)
  //       .map(key => `${key}=${searchQuery[key]}`)
  //       .join("&")
       
  //       url = url + `?${qs}`
    
  //   }

  // }
  if(searchQuery){
    const qs = Object.keys(searchQuery)
          .map(key => `${key}=${searchQuery[key]}`)
          .join("&")
         
          url = url + `?${qs}`
      
  }
  
  // if (searchApp) {
 
  // }
  const request = makeRequestActionCreator(GET_KONNECTS_LIST)
  return dispatch(
    request({
      url: url,
      method: "GET",
      searchQuery,
    })
  )
}

export const updateKonnectStatus = (id, status) => dispatch => {
  const request = makeRequestActionCreator(UPDATE_KONNECT_STATUS)
  return dispatch(
    request({
      url: "api/v1/konnects/" + id + "/update_status",
      data: { status: status },
      method: "POST",
    })
  )
}

export const getAuthApps = (searchQuery, authorizedOnly) => dispatch => {
  const request = makeRequestActionCreator(GET_AUTHORISED_APPS)
  let url = "api/v1/apps/authorized_apps1"
  if (searchQuery) {
    url = url + "?search_query=" + searchQuery
    if (authorizedOnly) {
      url = url + "&authorized_only=" + authorizedOnly
    }
  }

  return dispatch(
    request({
      url: url,
      method: "GET",
    })
  )
}
export const getLinkedAccounts = node => dispatch => {
  const request = makeRequestActionCreator(GET_ASSOCIATED_ACCOUNTS)
  const { appId = "", nodeId = null } = node
  return dispatch(
    request({
      url: `api/v1/apps/${appId}/authorized_app_accounts`,
      method: "GET",
      extra: {
        ...node,
      },
    })
  )
}

export const setLinkedAccounts = data => ({
  type: SET_LINKED_ACCOUNT,
  data: { ...data },
})

export const getAppEvents = node => dispatch => {
  const request = makeRequestActionCreator(GET_APP_EVENTS)
  const { appId = "", nodeId = null } = node

  return dispatch(
    request({
      url: `api/v1/apps/${appId}/app_events`,
      method: "GET",
      extra: {
        ...node,
      },
    })
  )
}

export const getAppEventConfig = node => dispatch => {
  const request = makeRequestActionCreator(GET_EVENT_CONFIGURATIONS)
  const { appId = "", nodeId = null, eventId = "", source = false, app_account_id = null } = node
  let url = `api/v1/apps/${appId}/app_events/${eventId}/event_config/${source ? "left" : "right"}`
  if (app_account_id) url += `?app_account_id=${app_account_id}`
  return dispatch(
    request({
      url,
      method: "GET",
      extra: {
        ...node,
      },
    })
  )
}

export const getConditionsList = () => dispatch => {
  const request = makeRequestActionCreator(GET_CONDITIONS_LIST)
  return dispatch(
    request({
      url: "api/v1/konnects/conditions_list",
      method: "GET",
    })
  )
}

export const getAppEventConfigDetail = node => dispatch => {
  const request = makeRequestActionCreator(GET_EVENT_CONFIGURATIONS_DETAILS)
  const {
    appId = "",
    nodeId = null,
    eventId = "",
    eventConfigId = "",
    source = false,
    prevSequence = null,
    app_account_id = null,
  } = node
  let endpoint = `api/v1/apps/${appId}/app_events/${eventId}/event_config/${
    source ? "left" : "right"
  }/${eventConfigId}/details?`

  let params = ""
  // let params = node.node.appEventSequences
  // if (prevSequence) {
  //   params = { ...params, ...prevSequence }
  // }
  // if (app_account_id) {
  //   params = { ...params, app_account_id }
  // }

  // params = Object.keys(params)
  //   .map(key => key + "=" + params[key])
  //   .join("&")
  if (node.node.appEventSequences) {
    node.node.appEventSequences.map(x => {
      params += `&${x.config_key}=${x.id}`
    })
  }

  if (prevSequence) {
    endpoint = `${endpoint}${prevSequence.config_key}=${prevSequence.id}${params}`
  }

  if (app_account_id) {
    // endpoint += params ? "&" : "?"
    endpoint = endpoint + `&app_account_id=${app_account_id}`
  }
  // endpoint += `?${params}`
  return dispatch(
    request({
      url: endpoint,
      method: "GET",
      extra: {
        ...node,
      },
    })
  )
}

export const getAppEventConfigDetailFetch = node => dispatch => {
  const request = makeRequestActionCreator(GET_EVENT_CONFIGURATIONS_DETAILS_FETCH)
  const { appId = "", nodeId = null, eventId = "", eventConfigId = "", source = false, app_account_id = null } = node
  let params = ""
  if (node.node.appEventSequences) {
    node.node.appEventSequences.map(x => {
      if (x.config_key !== "sheet_id") params += `&${x.config_key}=${x.id}`
    })
  }

  if (node.selectedConfigKey) {
    params = `?${node.selectedConfigKey}=${node.selectedEventConfigId}${params}`
  }

  let url = `api/v1/apps/${appId}/app_events/${eventId}/event_config/${
    source ? "left" : "right"
  }/${eventConfigId}/fetch-fields`

  url += `${params}`

  if (app_account_id) url += `&app_account_id=${app_account_id}`

  return dispatch(
    request({
      url,
      method: "GET",
      extra: {
        ...node,
      },
    })
  )
}

export const setEventsConfig = ({ ...props }) => {
  return {
    type: SET_APP_EVENT_CONFIG_VALUE,
    data: props,
  }
}

export const setConfigMenuState = ({ ...props }) => {
  return {
    type: SET_CONFIG_MENU_STATE,
    data: props,
  }
}

export const getEditorState = () => ({
  type: GET_EDITOR_STATE,
})

export const setEditorState = data => ({
  type: SET_EDITOR_STATE,
  data: { ...data },
})
export const getKonnectName = () => ({
  type: GET_KONNECT_NAME,
})

export const setKonnectName = name => ({
  type: SET_KONNECT_NAME,
  data: name,
})

export const onDragNode = ({ ...props }) => ({
  type: ONDRAGNODE,
  data: props,
})

export const onDragNodeStart = ({ ...props }) => ({
  type: ONDRAGNODESTART,
  data: props,
})

export const onDragNodeStop = ({ ...props }) => ({
  type: ONDRAGNODESTOP,
  data: props,
})

export const onCanvasDrop = ({ ...actionData }) => dispatch => {
  const { data, nodeId } = actionData
  dispatch({
    type: ONCANVASDROP,
    data: actionData,
  })

  if (data.type !== "WEBHOOK") {
    dispatch(getLinkedAccounts({ nodeId: nodeId, appId: data.id }))
  }
  if (data.provider.toLowerCase() !== "webhook") dispatch(getAppEvents({ nodeId: nodeId, appId: data.id }))
}

export const onLinkStart = ({ ...props }) => ({
  type: ONLINKSTART,
  data: props,
})

export const onLinkMove = ({ ...props }) => ({
  type: ONLINKMOVE,
  data: props,
})

export const onLinkComplete = ({ ...props }) => ({
  type: ONLINKCOMPLETE,
  data: props,
})

export const onLinkCancel = ({ ...props }) => ({
  type: ONLINKCANCEL,
  data: props,
})

export const onPortPositionChange = ({ ...props }) => ({
  type: ONPORTPOSITIONCHANGE,
  data: props,
})

export const onLinkMouseEnter = ({ ...props }) => ({
  type: ONLINKMOUSEENTER,
  data: props,
})

export const onLinkMouseLeave = ({ ...props }) => ({
  type: ONLINKMOUSELEAVE,
  data: props,
})

export const onLinkClick = ({ ...props }) => ({
  type: ONLINKCLICK,
  data: props,
})

export const onCanvasClick = ({ ...props }) => ({
  type: ONCANVASCLICK,
  data: props,
})

export const onNodeClick = ({ ...props }) => ({
  type: ONNODECLICK,
  data: props,
})

export const onNodeMouseEnter = ({ ...props }) => ({
  type: ONNODEMOUSEENTER,
  data: props,
})

export const onNodeMouseLeave = ({ ...props }) => ({
  type: ONNODEMOUSELEAVE,
  data: props,
})

export const onNodeSizeChange = ({ ...props }) => ({
  type: ONNODESIZECHANGE,
  data: props,
})

export const onPortTextChange = ({ ...props }) => ({
  type: ONPORTTEXTCHANGE,
  data: props,
})

// export const saveConnect = data => dispatch => {
//   const request = makeRequestActionCreator(SAVEKONNECT)

//   data.nodes = data.nodes.map((x, idx) => {
//     x.order = idx
//     return x
//   })

//   const allReviewed = data.nodes.every(node => node.reviewed)
//   let payload = {}
//   const leftNode = data.nodes[0]
//   const rightNodes = data.nodes.slice(1)

//   if (leftNode) {
//     payload.app_account_id = leftNode.selectedAccount ? leftNode.selectedAccount.id : null
//     payload.webhookUrl = leftNode.webhookUrl ? leftNode.webhookUrl : null
//     payload.left_app_event_id = leftNode.appEvent
//     payload.left_app_id = leftNode.appId
//     payload.right_apps = []
//     payload.left_config = {}
//     payload.order = 0
//     leftNode.appEventSequences.map(x => {
//       payload.left_config[x.config_key] = x.id
//     })

//     rightNodes.map((x, idx) => {
//       const config = {}
//       x.configResponses = x.configResponses ? x.configResponses.filter(x => !!x.port) : []

//       x.configResponses &&
//         x.configResponses.map(y => {
//           if (y.value) {
//             config[y.config_key] = {
//               type: "user-defined",
//               value: y.value,
//             }
//           } else {
//             config[y.config_key] = {
//               port: y.port,
//             }
//           }
//         })
//       payload.right_apps[idx] = {
//         app_event_id: x.appEvent,
//         app_id: x.appId,
//         config: { ...config }, //todo

//         type: x.appType,
//       }
//       if (x.appType !== "ADD_ON") {
//         payload.right_apps[idx].app_account_id = x.selectedAccount.id
//       }
//     })

//     payload.right_apps.map(x => {
//       data.links.map(y => {
//         x, y

//         const fromNode = data.nodes.find(n => n.nodeId === y.from.node)
//         const toNode = data.nodes.find(n => n.nodeId === y.to.node)
//         if (fromNode.appType !== "WEBHOOK") fromNode.configResponses = fromNode.configResponses.filter(x => !!x.port)
//         if (toNode.appType !== "WEBHOOK") toNode.configResponses = toNode.configResponses.filter(x => !!x.port)
//         const fromPort = fromNode.configResponses.find(r => r.port && r.port.id === y.from.port)
//         const toPort = toNode.configResponses.find(r => r.port && r.port.id === y.to.port)

//         const toNodeIdx = data.nodes.findIndex(n => n.nodeId === y.to.node)
//         const fromPortIdx = data.nodes.findIndex(n => n.nodeId === y.from.node)
//         if (x.config[toPort.config_key]) {
//           if (fromPort.value && fromNode.appType !== "WEBHOOK") {
//             x.config[toPort.config_key] = {
//               type: "key-map",
//               value: fromPort.value,
//               order: fromNode.order,
//               orderout: toNode.order,
//             }
//           } else {
//             x.config[toPort.config_key] = {
//               type: "key-map",
//               value: fromPort.id,
//               order: fromNode.order,
//               orderout: toNode.order,
//             }
//             // }

//             toNode.appEventSequences.map(y => {
//               x.config[y.config_key] = y.id
//             })

//             x.left_app_id = fromNode.appId
//             x.left_app_event_id = fromNode.appEvent
//             x.level = toNodeIdx - fromPortIdx
//           }
//         }
//       })
//     })
//   }

//   payload.right_apps = payload.right_apps.map(x => {
//     const y = { ...x }
//     Object.keys(y.config).map(z => {
//       if (y.config[z].port) {
//         delete y.config[z]
//       }
//     })
//     return y
//   })
//   payload.canvas_json = data
//   payload.konnect_id = leftNode.konnect_id
//   //check if requred fields are set/not yet
//   let required = false
//   const updatedNodes = data.nodes.map(x => {
//     if (x.appType === "WEBHOOK") {
//       return x
//     }
//     x.configResponses = x.configResponses.filter(w => !!w.port)

//     x.configResponses = x.configResponses
//       ? x.configResponses.map(y => {
//           let hasLink = data.links.filter(z => z.to.port === y.port.id).length > 0
//           let hasValue = !!y.value

//           if (y.config_key_required && !(hasLink || hasValue)) {
//             required = true
//             y.dirty = true
//           }
//           return y
//         })
//       : []
//     return x
//   })
//   data.nodes = updatedNodes

//   if (required || !allReviewed) {
//     return new Promise((resolve, reject) => {
//       dispatch({ type: MARKSTOREDIRTY, data: data })
//       reject("workspace dirty")
//     })
//   } else {
//     if (data.id) {
//       const url = data.publish
//         ? `api/v1/konnects/${data.id}/publish_konnect`
//         : `api/v1/konnects/${data.id}/update_konnect`
//       return dispatch(
//         request({
//           url,
//           data: payload,
//           method: "POST",
//         })
//       )
//     } else {
//       const url = data.publish ? `api/v1/konnects/publish_konnect` : `api/v1/konnects/update_konnect`
//       return dispatch(
//         request({
//           url,
//           data: payload,
//           method: "POST",
//         })
//       )
//     }
//   }
// }
export const saveConnect = data => dispatch => {
  const request = makeRequestActionCreator(SAVEKONNECT)
  const storeState = store
    .getState()
    .getIn(["konnect"])
    .toJS()

  const editorState = {
    ...storeState.editorState,
    konnect_name: data.konnect_name,
  }

  data.nodes = editorState.nodes.map((x, idx) => {
    x.order = idx
    return x
  })

  const allReviewed = data.nodes.every(node => node.reviewed)

  const payload = {
    konnect_id: data.nodes[0].konnect_id,
    canvas_json: editorState,
  }

  const url = data.publish && allReviewed ? `api/v1/konnects/publish_konnect` : `api/v1/konnects/save_konnect`
  return dispatch(
    request({
      url,
      data: payload,
      method: "POST",
    })
  )
}

export const toggleSidebar = () => ({
  type: TOGGLESIDEBAR,
})

export const setScale = scale => ({
  type: EDITORSCALE,
  data: scale,
})

export const onDragCanvas = scale => ({
  type: ONDRAGCANVAS,
  data: scale,
})

export const onDragCanvasStop = scale => ({
  type: ONDRAGCANVASSTOP,
  data: scale,
})

export const undo = () => ({
  type: EDITORUNDO,
})

export const redo = () => ({
  type: EDITORREDO,
})

export const clearState = () => ({
  type: CLEARSTATE,
})

export const getKonnect = id => dispatch => {
  const request = makeRequestActionCreator(GETKONNECT)
  return dispatch(
    request({
      url: `api/v1/konnects/${id}/edit`,
      method: "GET",
    })
  )
}

export const toggleBasicAuth = ({ ...props }) => ({
  type: BASIC_AUTH,
  data: props,
})
//api to get data from backend after copy and adding data
export const captureWebhookResponse = props => dispatch => {
  const { node, editorState } = props
  const identifier = node.webhookUrl.split("/").pop()

  const storeState = store
    .getState()
    .getIn(["konnect"])
    .toJS()

  let params = ""
  // const _node = editorState.nodes.find(x => x.nodeId === node.nodeId)
  const payload = {}
  if (node.webhookUrl) {
    params += `?webhookUrl=${node.webhookUrl}`
    payload.webhookUrl = node.webhookUrl
  }

  if (node.appId) {
    params += `&left_app_id=${node.appId}`
    payload.left_app_id = node.appId
  }

  if (node.appEvent) {
    params += `&left_app_event_id=${node.appEvent}`
    payload.left_app_event_id = node.appEvent
  }

  if (node.enabled) {
    params += `&auth_enabled=true&api_key=${node.apiKey}&api_secret=${node.apiSecret}`
    payload.auth_enabled = true
    payload.api_key = node.apiKey
    payload.api_secret = node.apiSecret
  }

  if (storeState && storeState.editorState.nodes[0].konnect_id) {
    params += `&konnect_id=${storeState.editorState.nodes[0].konnect_id}`
    payload.konnect_id = storeState.editorState.nodes[0].konnect_id
  }

  params += `&canvas_json=${encodeURI(JSON.stringify(editorState))}`
  payload.canvas_json = editorState
  const request = makeRequestActionCreator(CAPTUREWEBHOOKRESPONSE)
  let awaitingWebhookResponse = !!node.awaitingWebhookResponse

  return dispatch(
    request({
      // url: `api/v1/konnect_webhooks/capture_webhook_response/${identifier}${params}`,
      url: `api/v1/konnect_webhooks/capture_webhook_response/${identifier}`,
      method: "POST",
      data: payload,
      extra: {
        ...node,
      },
    })
  )
}

export const onWebhookAuthChange = ({ ...props }) => ({
  type: ONWEBHOOKAUTHCHANGE,
  data: props,
})

export const onDeleteNode = ({ ...props }) => ({
  type: ONDELETENODE,
  data: props,
})

export const onDeleteNodeV2 = data => dispatch => {
  const request = makeRequestActionCreator(ONDELETENODEV2)

  return dispatch(
    request({
      url: `api/v1/konnects/delete_activity`,
      data: data,
      method: "POST",
    })
  )
}

export const onSetAsFirstNode = ({ ...props }) => ({
  type: ONSETASFIRSTNODE,
  data: props,
})

export const onDeleteLink = ({ ...props }) => ({
  type: ONDELETELINK,
  data: props,
})

export const expandCollapseNode = ({ ...props }) => ({
  type: EXPANDCOLLAPSENODE,
  data: props,
})

const payloadMaker = (data, appDetails) => {
  data.nodes = data.nodes.map((x, idx) => {
    x.order = idx
    return x
  })

  const allReviewed = data.nodes.every(node => node.reviewed)
  const leftNode = data.nodes[0]
  const rightNodes = data.nodes.slice(1)
  let payload = {}

  let condition_activity_id = 0
  let pass_through_condition = false;

  
   if (leftNode) {
    payload.left_app_id = leftNode.appId
    payload.webhookUrl = leftNode.webhookUrl ? leftNode.webhookUrl : null
    payload.app_account_id = leftNode.selectedAccount ? leftNode.selectedAccount.id : null
    payload.left_app_event_id = leftNode.appEvent

    payload.order = 0
    payload.left_config = {}
    leftNode.appEventSequences.map(x => {
      payload.left_config[x.config_key] = x.id
    })
    payload.right_apps = []
    rightNodes.map((x, idx) => {
      const config = {}
      const textFormatterConfig = []
      const conditionsFormatterConfig = []

      x.configResponses = x.configResponses ? x.configResponses.filter(x => !!x.port) : []
      if (x.appType === "ADD_ON" && x.provider === "conditioner") {
        let operator = null
        x.conditions.map(e => {
          const condition = {
            attribute: {
              type: "key-map",
              value: e.field.value,
              konnect_activity_id: x.parent_konnect_activity_id,
            },
            condition: e.operator,
            condition_value: e.value.value
              ? e.value
              : {
                  type: "user-defined",
                  value: e.value,
                  // konnect_activity_id: x.parent_konnect_activity_id,
                },
          }
          if (operator) condition.concat_value = operator
          operator = e.queryOperator ? e.queryOperator.toUpperCase() : null
          conditionsFormatterConfig.push(condition)
        })
      } else if (
        (x.appType === "ADD_ON" && (x.provider === "TextFormatter" || x.provider === "DateFormatter" || x.provider ==="Converter")) ||
        x.provider === "NumberFormatter" 
      ) {
        x.fromPorts.map(e => {
        
          const n = data.nodes.find(u => u.nodeId === e.info.node)
          textFormatterConfig.push({
            konnect_activity_id: n.konnect_activity_id,
            display: e.display,
            id: e.id,
            config_key: n.configResponses.find(i => i.id === e.id).config_key,
          })
        })
      }
      x.configResponses &&
        x.configResponses.map(y => {
          if (y.value) {
            config[y.config_key] = {
              type: "user-defined",
              //value: y._id ? y._id : y.value,
              value: y.value,
            }
          } else {
            config[y.config_key] = {
              port: y.port,
            }
          }
        })
      payload.right_apps[idx] = {
        app_id: x.appId,
        config: { ...config },
        nodeId: x.nodeId,
        type: x.appType,
        provider: x.provider || "",
        konnect_id: leftNode.konnect_id,
      }

      if (x.appEvent) {
        payload.right_apps[idx].app_event_id = x.appEvent
      }
      if (x.appType !== "ADD_ON") {
        payload.right_apps[idx].app_account_id = x.selectedAccount.id
      }

      if (
        x.appType === "ADD_ON" &&
        x.provider !== "conditioner" &&
        x.provider !== "api" &&
        x.provider !== "generator" &&
        x.provider.toLowerCase() !== "textsplitter"
      ) {
        let string = payload.right_apps[idx].config.text.value + ""

        textFormatterConfig.map(d => {

          const f = `@[${d.display}]`

          const mapValue = { type: "key-map", value: d.id, konnect_activity_id: d.konnect_activity_id }

          const g = `@[${d.display}](${JSON.stringify(mapValue)})`

          string = string.replace(f, g)

        })

        payload.right_apps[idx].config.text.value = string

      }

      x.appEventSequences.map(u => {
        if (!payload.right_apps[idx].config) payload.right_apps[idx].config = {}
        payload.right_apps[idx].config[u.config_key] = u.id
      })

      if (x.appType === "ADD_ON" && x.provider === "conditioner") {
        payload.right_apps[idx].config = [...conditionsFormatterConfig]
      } else if (x.appType === "ADD_ON" && x.provider === "api") {
        payload.right_apps[idx].config.apiDetails = appDetails
      } else if (x.appType === "ADD_ON" && x.provider === "generator") {
        payload.right_apps[idx].config.appDetails = appDetails
      } else if (x.appType === "ADD_ON" && x.provider.toLowerCase() === "textsplitter") {
        if (appDetails.textSplitter) payload.right_apps[idx].config.segmentIndex = appDetails.textSplitter.segmentIndex
      }
    })

    payload.right_apps.map(x => {
      if (x.type === "ADD_ON") {
        if (x.provider != "generator" && x.provider != "api" && x.provider.toLowerCase() != "textsplitter") {
          return
        }
      }
      data.links.map(y => {
        x, y

        const fromNode = data.nodes.find(n => n.nodeId === y.from.node)
        const toNode = data.nodes.find(n => n.nodeId === y.to.node)
        if (fromNode.appType !== "WEBHOOK") fromNode.configResponses = fromNode.configResponses.filter(x => !!x.port)
        if (toNode.appType !== "WEBHOOK") toNode.configResponses = toNode.configResponses.filter(x => !!x.port)

        const fromPort = fromNode.configResponses.find(r => r.port && r.port.id === y.from.port)
        const toPort = toNode.configResponses.find(r => r.port && y.to.port === r.port.id)

        const toNodeIdx = data.nodes.findIndex(n => n.nodeId === y.to.node)
        const fromPortIdx = data.nodes.findIndex(n => n.nodeId === y.from.node)
        if (toPort && x.config[toPort.config_key]) {
        if (x.config[toPort.config_key]) {
          if (fromPort.value && fromNode.appType !== "WEBHOOK" && fromNode.appType !== "WEBHOOK_API") {
            if (toPort.port.id.indexOf(x.nodeId) > -1) {
              x.config[toPort.config_key] = {
                type: "key-map",
                value: fromPort.id,
                // fromPort.mapped || fromNode.appType === "ADD_ON" || fromNode.appType === "WEBHOOK_API"
                //   ? fromPort.id
                //   : fromPort.value,
              }
              if (fromNode.konnect_activity_id !== undefined) {
                x.config[toPort.config_key].konnect_activity_id = fromNode.konnect_activity_id
              }
            }
            // toNode.appEventSequences.map(u => {
            //   x.config[toPort.config_key][u.config_key] = u.id
            // })
          } else {
            if (toPort.port.id === x.nodeId) {
              x.config[toPort.config_key] = {
                type: "key-map",
                value: fromPort.id,
              }
            }
            x.config[toPort.config_key].konnect_activity_id = fromNode.konnect_activity_id

            toNode.appEventSequences.map(y => {
              x.config[y.config_key] = y.id
            })

            x.left_app_id = fromNode.appId
            x.left_app_event_id = fromNode.appEvent
            x.level = toNodeIdx - fromPortIdx
          }

          // if (fromNode.pass_through_condition && x.config[toPort.config_key]) {
          //   x.config[toPort.config_key].konnect_activity_id = fromNode.konnect_activity_id
          // }
        }

        toNode.appEventSequences.map(u => {
          x.config[u.config_key] = u.id
        })

        if (toPort && toPort.port.id.indexOf(x.nodeId) !== -1) {
          x.config.pass_through_condition = !!fromNode.pass_through_condition
          x.config.condition_activity_id = !!fromNode.pass_through_condition ? fromNode.condition_activity_id : null

          toNode.pass_through_condition = !!fromNode.pass_through_condition
          toNode.condition_activity_id = !!fromNode.pass_through_condition ? fromNode.condition_activity_id : null
        }
      }})
    })
  }
  payload.right_apps = payload.right_apps.map(x => {
    const y = { ...x }
    Object.keys(y.config).map(z => {
      if (y.config[z] && y.config[z].port) {
        delete y.config[z]
      }
      if (z === "pass_through_condition" && !y.config[z]) delete y.config[z]
      if (z === "condition_activity_id" && !y.config[z]) delete y.config[z]
    })
    return y
  })
  return payload
}

export const appTestAndReview = data => dispatch => {
  dispatch({ type: ENABLE_TEST_REVIEW_LOADER, payload: data.nodeIdx })
  const request = makeRequestActionCreator(APPTESTANDREVIEW)
  let payload = {}
  const { node, nodeIdx, editorState, appDetails = [] } = data
  switch (nodeIdx) {
    case 0: {
      const config = {}
      node.appEventSequences.map(x => {
        config[x.config_key] = x.id
      })
      // const editorState = {
      //   ...data.editorState,
      //   konnect_name: data.konnect_name
      // }
      payload = {
        left_app_id: node.appId,
        left_app_event_id: node.appEvent,
        left_config: config,
        canvas_json: data.editorState,
        app_account_id: node.selectedAccount ? node.selectedAccount.id : null,
      }
      if (appDetails) {
        payload.appDetails = appDetails
      }
      return dispatch(
        request({
          url: `api/v1/konnects/test_and_review`,
          method: "POST",
          data: { ...payload },
          extra: {
            nodeId: node.nodeId,
          },
        })
      )
    }
    default: {
      const _payload = payloadMaker(editorState, appDetails)

      payload = _payload.right_apps.find(x => x.nodeId === node.nodeId)
      delete payload.left_app_id
      delete payload.left_app_event_id
      delete payload.nodeId
      delete payload.type
      payload.canvas_json = data.editorState
      // if (editorState.konnect_activity_id) payload.konnect_activity_id = editorState.konnect_activity_id
      if (node.konnect_activity_id) {
        payload.konnect_activity_id = node.konnect_activity_id
      }
      if (node.appType === "ADD_ON" && node.provider === "conditioner") {
        payload.konnect_activity_id = node.konnect_activity_id
      }
      if (node.appType === "ADD_ON" && node.provider === "api" && node.provider === "generator") {
        payload.konnect_activity_id = node.parent_konnect_activity_id
      }
      if (node.appType === "ADD_ON" && node.provider === "api" && node.provider.toLowerCase() === "textsplitter") {
        payload.konnect_activity_id = node.parent_konnect_activity_id
      }
      let dirty = false
      data.editorState.nodes[nodeIdx].configResponses = data.editorState.nodes[nodeIdx].configResponses.map(x => {
        if (x.config_key_required && !x.value) {
          x.dirty = true
          dirty = true
        }
        return x
      })
      if (dirty) {
        return new Promise((resolve, reject) => {
          dispatch({ type: MARKSTOREDIRTY, data: data.editorState })
          reject("workspace dirty")
        })
      }
      return dispatch(
        request({
          url: `api/v1/konnects/test_and_review`,
          method: "POST",
          data: { ...payload },
          extra: {
            nodeId: node.nodeId,
          },
        })
      )
    }
  }
}

export const createAdditionalFields = ({ ...props }) => ({
  type: CREATEADDITIONALFIELDS,
  data: props,
})

export const modifyConditions = ({ ...props }) => ({
  type: MODIFYCONDITIONS,
  data: props,
})

export const updateHttpMethods = props => ({
  type: MODIFY_HTTP_METHOD,
  data: props,
})

export const updateUrl = props => ({
  type: MODIFY_URL_ENDPOINT,
  data: props,
})

export const updatePayloadType = props => ({
  type: MODIFY_PAYLOAD_TYPE,
  data: props,
})

export const updateWrapRequestInArray = props => ({
  type: MODIFY_WRAP_REQUEST_IN_ARRAY,
  data: props,
})

export const updateHeadersAndParams = props => ({
  type: MODIFY_HEADERS_AND_PARAMS,
  data: props,
})

export const updateExpiryNum = props => ({
  type: MODIFY_EXPIRY_NUM,
  data: props,
})

export const updateDuration = props => ({
  type: MODIFY_DURATION,
  data: props,
})

export const updateSegmentIndex = props => ({
  type: MODIFY_SEGMENT_INDEX,
  data: props,
})

export const updateGsheetPop = props => ({
  type: UPDATE_GSHEET_POP_UP,
  data: props,
})
