import px2vw from "utils/px2vw"

export const size = {
  header: {
    height: px2vw(80),
    paddingTop: px2vw(22),
    paddingSide: px2vw(55),
  },
  sidebar: {
    padding:  px2vw(20) ,
    marginTop: px2vw(300),
    width: px2vw(388),
    swidth: px2vw(350),
    search: {
      height: px2vw(48),
      icon: {
        size: px2vw(18),
      },
      fontSize: px2vw(18),
      lineHeight: px2vw(22),
    },
    list: {
      tile: {
        height: px2vw(60),
        width: px2vw(60),
        imageHeight: px2vw(34),
        borderRadius: px2vw(12),
      },
      badge: {
        size: px2vw(18),
        borderRadius: px2vw(9),
      },
    },
  },
  appsList: {
    padding: px2vw(10),
    search: {
      height: px2vw(50),
      font: px2vw(18),
      padding: px2vw(9),
      icon: {
        height: px2vw(12),
      },
    },
  },
  editor: {
    placeHolder: {
      height: px2vw(148),
      width: px2vw(148),
      heightD: px2vw(164),
      widthD: px2vw(164),
    },
    nodeWidth: px2vw(419),
    nodePadding: px2vw(16),
    nodeMinHeight: px2vw(275),
    nodeRadius: px2vw(24),
    itemsGroup: {
      margin: px2vw(16),
    },
    node: {
      info: {
        iconPlaceholder: px2vw(34),
        icon: px2vw(24),
        titles: {
          padding: px2vw(16),
          title: {
            font: px2vw(18),  //font size of title name
            lineHeight: px2vw(27),
          },
          subTitle: {
            font: px2vw(10),
            lineHeight: px2vw(15),
          },
        },
      },
      selectors: {
        header: {
          paddingTop: px2vw(16),
          fontSize: px2vw(18),
          lineHeight: px2vw(27),
        },
        dropdown: {
          marginBottom: px2vw(8),
          fontSize: px2vw(18),
          lineHeight: px2vw(21),
          buttonHeight: px2vw(40),
          padding: px2vw(16),
          borderRadius: px2vw(8),
        },
        checkbox: {
          marginBottom: px2vw(30),
          fontSize: px2vw(18),
          lineHeight: px2vw(21),
          checkboxHeight: px2vw(18),
          padding: px2vw(16),
          borderRadius: px2vw(8),
        },
        response: {},
      },
    },
    nodeInput: {
      height: px2vw(30),
      borderRadius: px2vw(4),
      fontSize: px2vw(12),
      marginBottom: px2vw(8),
    },
    nodeTitle: {
      fontSize: px2vw(12),
      lineHeight: px2vw(16),
    },
    itemGroups: {
      marginBottom: px2vw(18),
      header: {
        marginBottom: px2vw(9),
      },
    },
    eventsItemOuter: {
      borderRadius: px2vw(7),
      text: {
        paddingLeft: px2vw(14),
        paddingTop: px2vw(10),
        paddingBottom: px2vw(10),
      },
      icon: {
        paddingRight: px2vw(9),
      },
    },
    connector: {
      outer: {
        height: px2vw(40),
        marginTop: px2vw(5),
        marginBottom: px2vw(8),
        port: {
          borderRadius: px2vw(6),
          fontSize: px2vw(18),
          lineHeight: px2vw(21),
          paddingLeft: px2vw(14),
          paddingRight: px2vw(14),
        },
      },
    },

    port: {
      outer: {
        height: px2vw(0),
      },
      inner: {
        height: px2vw(0),
      },
    },
    instructionsPopup: {
      width: px2vw(400),
      height: px2vw(300),
      paddingTop: px2vw(20),
    }
  },
}

export const colors = {}
