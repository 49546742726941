/**
 * Verification email sent page.
 */
import React, { useState } from "react"
import { Box, Text, Flex, Link } from "rebass"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { Input, Dropdown, Label } from "src/components"
import TimeZones from "./timezones"
import Langs from "./langs"
import langs from "./langs"
import Select from "react-select"
import { Button, Preloader } from "src/components"
import RightWhite from "../../assets/images/RightWhite.svg"
export const AccountView = ({ accountState, updateHandler }) => {
  const { t } = useTranslation()
  return (
    <>
      <div
        style={{
          display: "grid",
          width: "100%",
          gridTemplateColumns: "45% 45%",
          gap: "30px",
          marginLeft:"3%",
          padding: "0% 5% 0% 0%",
        }}
      >
        <Box sx={{ display: "flex", width: "100%", gap:"6%" }}>
          <Box sx={{width: "100%",}}>
            <Input
              label={t("account.firstNameLabel")}
              sxProps={{
                height: "40px !important",
                backgroundColor: "#F3F7FF",
                // border: "1px solid #B7CBFF",
                borderRadius: "18px",
                width: "100%",
                color: "#000",
              }}
              value={accountState.firstName[0] ? accountState.firstName[0] : ""}
              onChange={e => {
                accountState.firstName[1](e.currentTarget.value)
              }}
              setError={accountState.errors[0].firstName}
              errorMessage={t("account.firstNameRequiredText")}
            />
          </Box>
          <Box sx={{width: "100%",}}>
            <Input
              label={t("account.lastNameLabel")}
              sxProps={{
                height: "40px !important",
                backgroundColor: "#F3F7FF",
                // border: "1px solid #B7CBFF",
                borderRadius: "18px",
                width: "100%",
                color: "#000",
              }}
              value={accountState.lastName[0] ? accountState.lastName[0] : ""}
              onChange={e => {
                accountState.lastName[1](e.currentTarget.value)
              }}
              setError={accountState.errors[0].lastName}
              errorMessage={t("account.lastNameRequiredText")}
            />
          </Box>
        </Box>

        <Box>
          <Input
            label={t("account.emailAddressLabel")}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#F3F7FF",
              width: "100%",
              // border: "1px solid #B7CBFF",
              borderRadius: "18px",
              disabled: "disabled",
              color: "#000",
            }}
            disabled={true}
            value={accountState.email[0]}
          />
        </Box>
        <Box>
          <Input
            label={t("account.companyNameLabel")}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#F3F7FF",
              // border: "1px solid #B7CBFF",
              borderRadius: "18px",
              color: "#000",
            }}
            value={accountState.company[0] ? accountState.company[0] : ""}
            onChange={e => {
              accountState.company[1](e.currentTarget.value)
            }}
          />
        </Box>
        <Box>
          <Input
            label={t("account.currentPositionLabel")}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#F3F7FF",
              // border: "1px solid #B7CBFF",
              borderRadius: "18px",
              color: "#000",
            }}
            value={accountState.currentPosition[0] ? accountState.currentPosition[0] : ""}
            onChange={e => {
              accountState.currentPosition[1](e.currentTarget.value)
            }}
          />
        </Box>

        <Box
          sx={{
            "& .select__control": {
              // border: "1px solid #B7CBFF !important",
              boxShadow: "none !important",
              color: "#152F73",
              backgroundColor: "#F3F7FF",
              borderStyle: "none",
              borderRadius: "18px",
            },
            "& .select__control--is-focused": {
              // borderColor: "#152F73 !important",
            },
          }}
        >
          <Label>{t("account.timeZoneLabel")}</Label>
          <Select
            options={TimeZones}
            value={
              TimeZones.find(zone => zone.value === accountState.timeZone[0])
                ? TimeZones.find(zone => zone.value === accountState.timeZone[0])
                : null
            }
            onChange={item => accountState.timeZone[1](item.value)}
            menuShouldScrollIntoView={true}
            menuPosition="fixed"
            classNamePrefix="select"
          />
        </Box>

        <Box
          sx={{
            "& .select__control": {
              // border: "1px solid #B7CBFF",
              boxShadow: "none !important",
              color: "#152F73",
              backgroundColor: "#F3F7FF",
              borderStyle: "none",
              borderRadius: "18px",
            },
            "& .select__control--is-focused": {
              // borderColor: "#152F73 !important",
            },
          }}
        >
          <Label>{t("account.changeLanguageLabel")}</Label>
          <Select
            options={Langs}
            value={langs.find(zone => zone.value === accountState.lang[0])}
            onChange={item => {
              accountState.lang[1](item.value)
            }}
            menuShouldScrollIntoView={true}
            menuPosition="fixed"
            classNamePrefix="select"
          />
        </Box>

     
        <Box sx={{ height: "90px" ,mt:"17px" }}>
          <Input
            label={t("account.countryLabel")}
            sx={{
              // width: "476px",
              backgroundColor: "#F3F7FF",
              border: "1px solid #F3F7FF",
              borderRadius: "18px ",
              // height: "90x",
              pl: "10px",
              color: "#000",
              marginBottom:"0px !important",
            }}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#F3F7FF",
              // border: "1px solid #B7CBFF",
              borderRadius: "8px",
              color: "#000",
              marginBottom:"0px !important",
            }}
            value={accountState.country[0] ? accountState.country[0] : ""}
            onChange={e => {
              accountState.country[1](e.currentTarget.value)
            }}
            setError={accountState.errors[0].country}
            errorMessage={t("account.countryRequiredText")}
          />
        </Box>
        <Box sx={{mt:"17px"}}>
          <Input
            label={t("account.addressLine1Label")}
            sx={{
              // width: "476px",
              backgroundColor: "#F3F7FF",
              border: "1px solid #F3F7FF",
              borderRadius: "18px ",
              pl: "10px",
              color: "#000",
              marginBottom:"0px !important",
            }}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#F3F7FF",
              // border: "1px solid #B7CBFF",
              borderRadius: "8px",
              color: "#000",
            }}
            value={accountState.addressLine1[0] ? accountState.addressLine1[0] : ""}
            onChange={e => {
              accountState.addressLine1[1](e.currentTarget.value)
            }}
            setError={accountState.errors[0].addressLine1}
            errorMessage={t("account.addressLine1RequiredText")}
          />
        </Box>
        <Box>
          <Text>{t("account.cityLabel")}</Text>
          <Input
            sx={{
              // width: "476px",
              backgroundColor: "#F3F7FF",
              border: "1px solid #F3F7FF",
              borderRadius: "18px ",
              pl: "10px",
              marginBottom:"0px !important",
              color: "#000",
            }}
            // label={t("account.cityLabel")}
            tabIndex="-1"
            sxProps={{
              height: "40px !important",
              backgroundColor: "#F3F7FF",
              // border: "1px solid #B7CBFF",
              borderRadius: "8px",
              color: "#000",
            }}
            value={accountState.city[0] ? accountState.city[0] : ""}
            onChange={e => {
              accountState.city[1](e.currentTarget.value)
            }}
            setError={accountState.errors[0].city}
            errorMessage={t("account.cityRequiredText")}
          />
        </Box>
        <Box>
          <Input
            sx={{
              // width: "476px",
              backgroundColor: "#F3F7FF",
              border: "1px solid #F3F7FF",
              borderRadius: "18px ",
              marginBottom:"0px !important",
              pl: "10px",
              color: "#000",
            }}
            label={t("account.stateLabel")}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#F3F7FF",
              // border: "1px solid #B7CBFF",
              borderRadius: "8px",
              color: "#000",
            }}
            value={accountState.state[0] ? accountState.state[0] : ""}
            onChange={e => {
              accountState.state[1](e.currentTarget.value)
            }}
            setError={accountState.errors[0].state}
            errorMessage={t("account.stateRequiredText")}
          />
        </Box>
        <Box>
          <Input
            sx={{
              // width: "476px",
              backgroundColor: "#F3F7FF",
              border: "1px solid #F3F7FF",
              borderRadius: "18px ",
              pl: "10px",
              color: "#000",
              marginBottom:"0px !important",
            }}
            label={t("account.zipCodeLabel")}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#F3F7FF",
              // border: "1px solid #B7CBFF",
              borderRadius: "8px",
              color: "#000",
            }}
            value={accountState.zipCode[0] ? accountState.zipCode[0] : ""}
            onChange={e => {
              accountState.zipCode[1](e.currentTarget.value)
            }}
            setError={accountState.errors[0].zipCode}
            errorMessage={t("account.zipCodeRequiredText")}
          />
        </Box>

        <Box>
          <Input
            sx={{
              // width: "476px",
              backgroundColor: "#F3F7FF",
              border: "1px solid #F3F7FF",
              borderRadius: "18px ",
              pl: "10px",
              color: "#000",
              marginBottom: "0px",
              marginBottom:"0px !important",
            }}
            label={t("account.taxIdLabel")}
            // sxProps={{
            //   height: "40px !important",
            //   backgroundColor: "#F3F7FF",
            //   // border: "1px solid #B7CBFF",
            //   borderRadius: "8px",
            //   color: "#000",
            // }}
            value={accountState.taxId[0] ? accountState.taxId[0] : ""}
            onChange={e => {
              accountState.taxId[1](e.currentTarget.value)
            }}
            setError={accountState.errors[0].taxId}
            errorMessage={t("account.zipCodeRequiredText")}
          />
        </Box>
        <Box></Box>
        <Flex sx={{ justifyContent: "end", width: "100%", mt: "10px" }}>
          <Button
            //variant="secondary"
            width="200px"
            height={[30, 40, 45, 51]}
            fontSize={[10, 12, 16, 18]}
            sx={{
              backgroundColor: "#152F73",
              border: "none",
              borderRadius: "12px",
              height: "46px",
              color: "white",
              fontStyle: "normal",
              mb: "50px",
              fontWeight: 500,
            }}
            onClick={()=>updateHandler()}
          >
            <span><RightWhite  style={{
                            cursor: "pointer",
                            // backgroundColor: "gray",
                           
                            margin:"2px 4px -2px 2px"}}/></span>
          <span style={{marginLeft:"5px"}}>Save Changes</span>  
            {/* {t("account.updateBtnLabel")} */}
          </Button>
        </Flex>
      </div>
      
    </>
  )
}

export const PasswordView = ({ passwordState, updateHandler }) => {
  const { t } = useTranslation()
  const showPassword = {
    current: useState(false),
    new: useState(false),
    confirm: useState(false),
  }
  return (
    <Box
      width="70%"
      sx={{
        m: "38px 20px 20px 36px",
        "& label": {
          color: "#888888",
          fontSize: "14px",
        },
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Text sx={{ mb: "20px", fontSize: ["h1", "h1", "h2"], color: "#152F73" }}>
          {t("account.changePasswordHeaderText")}
        </Text>
        <Input
          label={t("account.currentPasswordLabel")}
          sxProps={{
            height: "40px !important",
            backgroundColor: "#F3F7FF",
            // border: "1px solid #B7CBFF",
            borderRadius: "18px",
            disabled: "disabled",
          }}
          type={showPassword.current[0] ? "text" : "password"}
          // icon={showPassword.current[0] ? faEyeSlash : faEye}
          // iconClick={() => showPassword.current[1](!showPassword.current[0])}
          value={passwordState.current[0]}
          onChange={e => {
            passwordState.current[1](e.currentTarget.value)
          }}
          setError={passwordState.errors[0].current || Boolean(passwordState.errors[0].currentError)}
          errorMessage={
            passwordState.errors[0].current
              ? t("account.currentPasswordRequiredText")
              : t(passwordState.errors[0].currentError)
          }
        />
      </Box>
      <Flex sx={{ mt: "20px", width: "100%", justifyContent: "space-between" }}>
        <Box sx={{ widht: "50%" }}>
          <Input
            label={t("account.newPasswordLabel")}
            sx={{ backgroundColor: "#F3F7FF", border: "1px solid #F3F7FF", borderRadius: "18px " }}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#F3F7FF",
              // border: "1px solid #B7CBFF",
              borderRadius: "18px ",
            }}
            type={showPassword.new[0] ? "text" : "password"}
            // icon={showPassword.new[0] ? faEyeSlash : faEye}
            // iconClick={() => showPassword.new[1](!showPassword.new[0])}
            value={passwordState.new[0]}
            onChange={e => {
              passwordState.new[1](e.currentTarget.value)
            }}
            setError={passwordState.errors[0].new || Boolean(passwordState.errors[0].newError)}
            errorMessage={
              passwordState.errors[0].new ? t("account.newPasswordRequiredText") : t(passwordState.errors[0].newError)
            }
          />

          <Text
            sx={{
              fontSize: "xxs",
              fontWeight: "600",
              color: "red",
              mb: "30px",
              borderRadius: "18px ",
              width: "400px",
              textAlign: "center",
            }}
          >
            {t("auth.signup.createPassword.passwordRule")}
          </Text>
        </Box>
        <Box sx={{ ml: "30px", width: "50%" }}>
          <Input
            label={t("account.confirmPasswordLabel")}
            sx={{ backgroundColor: "#F3F7FF", border: "1px solid #F3F7FF", borderRadius: "18px " }}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#F3F7FF",
              // border: "1px solid #B7CBFF",

              borderRadius: "18px !important",
            }}
            type={showPassword.confirm[0] ? "text" : "password"}
            // icon={showPassword.confirm[0] ? faEyeSlash : faEye}
            // iconClick={() => showPassword.confirm[1](!showPassword.confirm[0])}
            value={passwordState.confirm[0]}
            onChange={e => {
              passwordState.confirm[1](e.currentTarget.value)
            }}
            setError={passwordState.errors[0].confirm || Boolean(passwordState.errors[0].confirmError)}
            errorMessage={
              passwordState.errors[0].confirm
                ? t("account.confirmPasswordRequiredText")
                : t(passwordState.errors[0].confirmError)
            }
          />
        </Box>
      </Flex>
      <Flex sx={{ justifyContent: "end" }}>
        <Button
          //variant="secondary"
          width="200px"
          height={[30, 40, 45, 51]}
          fontSize={[10, 12, 16, 18]}
          sx={{
            backgroundColor: "#152F73",
            border: "none",
            borderRadius: "12px",
            height: "46px",
            color: "white",
            fontStyle: "normal",
            mb: "50px",
            fontWeight: 500,
          }}
          onClick={updateHandler}
        >
               <span><RightWhite  style={{
                            cursor: "pointer",
                            // backgroundColor: "gray",
                           
                            margin:"2px 4px -2px 2px"}}/></span>
                <span style={{marginLeft:"5px"}}>Save Changes</span>  
          {/* {t("account.updateBtnLabel")} */}
        </Button>
      </Flex>
    </Box>
  )
}

export const Billing = ({ accountState, updateHandler }) => {
  const { t } = useTranslation()
  return (
    <>
      <div
        style={{
          display: "grid",
          width: "100%",
          gridTemplateColumns: "40% 40%",
          gridColumnGap: "60px",
          margin: "38px 20px 20px 36px",
        }}
      >
        <Box sx={{ height: "90px" }}>
          <Input
            label={t("account.countryLabel")}
            sx={{
              // width: "476px",
              backgroundColor: "#F3F7FF",
              border: "1px solid #F3F7FF",
              borderRadius: "18px ",
              height: "90x",
              pl: "10px",
            }}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#F3F7FF",
              // border: "1px solid #B7CBFF",
              borderRadius: "8px",
              color: "#000",
            }}
            value={accountState.country[0] ? accountState.country[0] : ""}
            onChange={e => {
              accountState.country[1](e.currentTarget.value)
            }}
            setError={accountState.errors[0].country}
            errorMessage={t("account.countryRequiredText")}
          />
        </Box>
        <Box>
          <Input
            label={t("account.addressLine1Label")}
            sx={{
              // width: "476px",
              backgroundColor: "#F3F7FF",
              border: "1px solid #F3F7FF",
              borderRadius: "18px ",
              pl: "10px",
            }}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#F3F7FF",
              // border: "1px solid #B7CBFF",
              borderRadius: "8px",
              color: "#000",
            }}
            value={accountState.addressLine1[0] ? accountState.addressLine1[0] : ""}
            onChange={e => {
              accountState.addressLine1[1](e.currentTarget.value)
            }}
            setError={accountState.errors[0].addressLine1}
            errorMessage={t("account.addressLine1RequiredText")}
          />
        </Box>
       
        <Box>
          <Text>{t("account.cityLabel")}</Text>
          <Input
            sx={{
              // width: "476px",
              backgroundColor: "#F3F7FF",
              border: "1px solid #F3F7FF",
              borderRadius: "18px ",
              pl: "10px",
            }}
            // label={t("account.cityLabel")}
            tabIndex="-1"
            sxProps={{
              height: "40px !important",
              backgroundColor: "#F3F7FF",
              // border: "1px solid #B7CBFF",
              borderRadius: "8px",
              color: "#000",
            }}
            value={accountState.city[0] ? accountState.city[0] : ""}
            onChange={e => {
              accountState.city[1](e.currentTarget.value)
            }}
            setError={accountState.errors[0].city}
            errorMessage={t("account.cityRequiredText")}
          />
        </Box>
        <Box>
          <Input
            sx={{
              // width: "476px",
              backgroundColor: "#F3F7FF",
              border: "1px solid #F3F7FF",
              borderRadius: "18px ",
              pl: "10px",
            }}
            label={t("account.stateLabel")}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#F3F7FF",
              // border: "1px solid #B7CBFF",
              borderRadius: "8px",
              color: "#000",
            }}
            value={accountState.state[0] ? accountState.state[0] : ""}
            onChange={e => {
              accountState.state[1](e.currentTarget.value)
            }}
            setError={accountState.errors[0].state}
            errorMessage={t("account.stateRequiredText")}
          />
        </Box>
        <Box>
          <Input
            sx={{
              // width: "476px",
              backgroundColor: "#F3F7FF",
              border: "1px solid #F3F7FF",
              borderRadius: "18px ",
              pl: "10px",
            }}
            label={t("account.zipCodeLabel")}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#F3F7FF",
              // border: "1px solid #B7CBFF",
              borderRadius: "8px",
              color: "#000",
            }}
            value={accountState.zipCode[0] ? accountState.zipCode[0] : ""}
            onChange={e => {
              accountState.zipCode[1](e.currentTarget.value)
            }}
            setError={accountState.errors[0].zipCode}
            errorMessage={t("account.zipCodeRequiredText")}
          />
        </Box>

        <Box>
          <Input
            sx={{
              // width: "476px",
              backgroundColor: "#F3F7FF",
              border: "1px solid #F3F7FF",
              borderRadius: "18px ",
              pl: "10px",
            }}
            label={t("account.taxIdLabel")}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#F3F7FF",
              // border: "1px solid #B7CBFF",
              borderRadius: "8px",
              color: "#000",
            }}
            value={accountState.taxId[0] ? accountState.taxId[0] : ""}
            onChange={e => {
              accountState.taxId[1](e.currentTarget.value)
            }}
            setError={accountState.errors[0].taxId}
            errorMessage={t("account.zipCodeRequiredText")}
          />
        </Box>
        <Box>
          
        </Box>
        <Flex sx={{ justifyContent: "end", width: "100%" }}>
        <Button
          //variant="secondary"
           width="200px"
          height={[30, 40, 45, 51]}
          fontSize={[10, 12, 16, 18]}
          sx={{
            backgroundColor: "#152F73",
            border: "none",
            borderRadius: "12px",
            height: "46px",
            color: "white",
            fontStyle: "normal",
            fontWeight: 500,
            mb: "50px",
          }}
          onClick={() =>updateHandler()}
        >
               <span><RightWhite  style={{
                            cursor: "pointer",
                            // backgroundColor: "gray",
                           
                            margin:"2px 4px -2px 2px"}}/></span>
               <span style={{marginLeft:"5px"}}>Save Changes</span>  
          {/* {t("account.updateBtnLabel")} */}
        </Button>
      </Flex>
      </div>
      
    </>
  )
}
