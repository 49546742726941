/**
 * App config reducer to handle the team api responses.
 */

import { GET_RESELLER_USERS_LIST, ADD_RESELLER_MEMBER, UPDATE_RESELLER_MEMBER, DELETE_RESELLER_MEMBER } from "config/Constants"

const initState = {
   resellerUsersList: [],
   totalTasks: null,
   activeSubAccounts: null,
   inActiveSubAccounts: null,
   taskConsumed: null,
   totalSubAccounts: null,
   allowedSubaccountsLimit: null
}

const SUCCESS = '_SUCCESS'
const FAIL = '_FAIL'

const teamReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_RESELLER_USERS_LIST + SUCCESS:
           return state.set('resellerUsersList', action.payload.sub_accounts)
           .set("totalTasks", action.payload.total_tasks)
           .set("activeSubAccounts", action.payload.active_subaccounts)
           .set("inActiveSubAccounts", action.payload.inactive_subaccounts)
           .set("taskConsumed", action.payload.task_consumed)
           .set("totalSubAccounts", action.payload.total_subaccounts)
           .set("allowedSubaccountsLimit", action.payload.allowed_subaccount_limit)
        case UPDATE_RESELLER_MEMBER + SUCCESS: 
           return state
        case UPDATE_RESELLER_MEMBER + FAIL:
           return state
        case GET_RESELLER_USERS_LIST + FAIL:
           
           return state.set("resellerUsersList", [])
           case DELETE_RESELLER_MEMBER + SUCCESS:
              return state
        default:
           return state
    }
}

export default teamReducer
