import React from "react"
import { Box, Text, Flex } from "rebass"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import { Button, Input } from "src/components"
import { useTranslation } from "react-i18next"
import { ToastContainer, toast } from 'react-toastify';
import px2vw from 'utils/px2vw'
import VerifiedUserSvg from "src/assets/images/verified_user"
import { AuthTitle, AuthSubtitle, ErrorText } from 'src/components/Typography/Typography'

export const SignupSuccessView = ({ state, loading, onContinueClick, verifyError }) => {

  const { t } = useTranslation()
  const [firstName, setFirstName] = state.firstName
  const [firstNameError, setFirstNameError] = state.firstNameError
  const [lastNameError, setLastNameError] = state.lastNameError
  const [lastName, setLastName] = state.lastName

  function submit() {
    setFirstNameError(firstName === '' ? true : false)
    setLastNameError(lastName === '' ? true : false)

    if (firstName !== '' && lastName !== '') {
      onContinueClick()
    }
  }

  function onFirstNameChange(e) {
    setFirstName(e.currentTarget.value)
    setFirstNameError(e.currentTarget.value === '' ? true : false)
  }

  function onLastNameChange(e) {
    setLastName(e.currentTarget.value)
    setLastNameError(e.currentTarget.value === '' ? true : false)
  }

  return (
    <>
      <Box
        sx={{
          textAlign: 'center'
        }}
      >
        <AuthSubtitle>{t("auth.signup.subtitle")}</AuthSubtitle>
      </Box>
      <Box className="border-gradient" sx={{ width: '121px', height: '0px', margin: '0px auto', mb: px2vw(10), mt: px2vw(5) }} />
      <Box
        sx={{
          textAlign: 'center'
        }}
      >
        <AuthTitle>{t("auth.signup.title")}</AuthTitle>
      </Box>
      <Box
        sx={{
          textAlign: 'center'
        }}
      >
        <Box sx={{ textAlign: "center", mt: px2vw(25) }}>
          <VerifiedUserSvg />
        </Box>
        <Box
          sx={{
            mt: px2vw(22),
            mx: px2vw(18)
          }}
        >
          <Input
            id="firstName"
            name="firstName"
            sxProps={{
              "& ~ span": { color: "#B7CBFF !important" },
            }}
            setError={firstNameError}
            errorMessage={t(state.firstNameErrorMsg)}
            defaultValue={firstName}
            onChange={onFirstNameChange}
            required
            iconLeft={faUser}
            placeholder={t("auth.signup.signupSuccess.firstName")}
          />
        </Box>

        <Box
          sx={{
            mt: px2vw(2),
            mx: px2vw(18),
            mb: px2vw(15)
          }}
        >
          <Input
            id="lastName"
            name="lastName"
            sxProps={{
              "& ~ span": { color: "#B7CBFF !important" },
            }}
            setError={lastNameError}
            errorMessage={t(state.lastNameErrorMsg)}
            defaultValue={lastName}
            onChange={onLastNameChange}
            required
            iconLeft={faUser}
            placeholder={t("auth.signup.signupSuccess.lastName")}
          />
        </Box>


        <Button
          height={{ _: 40, sm: 40, md: 40, lg: 40, xl: 60 }}
          fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }}
          sx={{ borderRadius: '0px', mx: px2vw(18), mb: px2vw(0), fontStyle: 'normal' }}
          stretch
          data-tracking="click"
          data-category="Login"
          data-action="User login click"
          disabled={loading}
          onClick={submit}
        >
          {t("auth.signup.continueBtnLabel")}
        </Button>
        {verifyError && (<ErrorText>{verifyError}</ErrorText>)}
      </Box>


      <Flex flexDirection="column" sx={{ flexGrow: 1, justifyContent: "flex-end", alignItems: "center" }}>
        <Box sx={{ pt: px2vw(18), textAlign: 'center' }}>
          <Text className="auth-footer-text-2"
            sx={{ display: "inline-block", fontSize: ["xs", "sm", "md"], fontStyle: 'normal', color: "app.secondary.20" }}
          >
            {t("auth.signup.alreadyHaveAccountText")}<a href='/login' style={{ color: '#F7C545' }}>{t("auth.signup.loginBtnLabel")}</a>
          </Text>
        </Box>
        <Box sx={{ pt: px2vw(20), textAlign: 'center' }}>
          <Text fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }}
            sx={{ display: "inline-block", fontStyle: 'normal', color: "app.secondary.20" }}
          >
            {t("auth.login.clickingContinueText")}<a href='/signup' style={{ color: '#F7C545' }}>{t("auth.login.termsAndConditionsText")}</a>
          </Text>
        </Box>
      </Flex>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />

    </>

  )
}
