/**
 * Reset password container to handle state management.
 */
import React, { useState } from "react"
import { connect } from "react-redux"
import AuthShellContainer from "src/features/auth/shell/Shell.container"
import { CreatePasswordView } from "../signup/CreatePassword.view"
import forgotFeatures from "src/config/features/forgot-features"
import { resetPassword } from "src/store/actions/appActions"
import { ToastContainer, toast } from "react-toastify"
import { useTranslation } from "react-i18next"

const _ResetContainer = props => {
  const state = {
    email: useState(""),
    isAdmin: useState(false),
    captcha: useState(false),
    screen: useState("email"),
    password: useState(""),
    confirmPassword: useState(""),
    showPassword: useState(false),
    showConfirmPassword: useState(false),
    passwordError: useState(false),
    passwordErrorMsg: "auth.signup.createPassword.passwordErrorMsg",
    confirmPasswordError: useState(false),
    loading: useState(false),
    confirmPasswordErrorMsg: useState("auth.signup.createPassword.confirmPasswordErrorMsg"),
  }

  const [loading, setLoading] = state.loading
  const { t } = useTranslation()
  const { resetPassword } = props

  // Reset sumbit handler
  function onPasswordReset() {
    setLoading(true)
    resetPassword({
      password: state.password[0],
      password_confirmation: state.confirmPassword[0],
      reset_password_token: props.match.params.token
    }).then(res => {
      setLoading(false)
      if (res.payload.key === 'PASSWORD_UPDATED') {
        toastMessage(t("successMessages." + res.payload.key))
        props.history.push('/login')
      } else if (res.payload.key === 'PASSWORD_NOT_UPDATED') {
        toastMessage(t("errorMessages." + res.payload.key))
      }
    })
  }

  //Error toast handler
  function toastMessage(msg) {
    toast.error(msg), {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    }
  }

  return (
    <>
      <AuthShellContainer {...props} features={forgotFeatures.features}>
        <CreatePasswordView
          loading={loading}
          title="auth.reset.title"
          subTitle="auth.reset.subTitle"
          state={state}
          onContinueClick={onPasswordReset}
        />
      </AuthShellContainer>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: data => dispatch(resetPassword(data)),
  }
}

export const ResetContainer = connect(mapStateToProps, mapDispatchToProps)(_ResetContainer)
