/**
 * Verification email sent page.
 */
import React, { useState, useEffect } from "react"
import { Box, Text, Flex, Link } from "rebass"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw"
import ReactTooltip from "react-tooltip"

export const LatestApps = ({ getWidgetDetails }) => {
    const { t } = useTranslation()
    const [apps, setApps] = useState([])

    useEffect(() => {
        getWidgetDetails('latest_apps').then((res) => {
            if (res.type === 'GET_WIDGET_DETAILS_SUCCESS') {
                let details = res.payload
                setApps(details.apps)
            }
        })
    }, [])

    return (
        <>
            <Flex sx={{ flexDirection: 'Column', width: '100%', mx: '20px', my: '20px', backgroundColor: "#FFF", borderRadius: '16px', px: '32px', py: '24px' }}>
                <Text fontSize={{ _: 12, sm: 12, md: 12, lg: 14, xl: 16 }} sx={{ fontWeight: "500", color: "#7482A6", marginBottom: '28px' }}>{t("dashboard.widgets.latestApps.headerTitle")}</Text>
                <Flex sx={{ flexDirection: 'Row', justifyContent: 'space-between' }}>
                    {apps && apps.length > 0 && apps.map((app, index) => (
                        <React.Fragment>
                            <Flex
                                key={'latest-apps-' + index}
                                data-tip={app.name}
                                data-for={app.id + ""}
                                sx={{
                                    flexDirection: "column",
                                    textAlign: "-webkit-center",
                                    mr: px2vw(12),
                                }}
                            >
                                <Flex
                                    key={"latest-" + index}
                                    width={{ _: 58, sm: 58, md: 68, lg: 78, xl: 90 }}
                                    height={{ _: 58, sm: 58, md: 68, lg: 78, xl: 90 }}
                                    sx={{
                                        textAlign: "center",
                                        borderRadius: 12,
                                        backgroundColor: "#FFF",
                                        cursor: "pointer",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        position: "relative",
                                        border: "1px solid #152F73",
                                        borderWidth: 0,
                                        "&:hover": {
                                            transform: "scale(1.2)",
                                        },
                                        padding: 0,
                                    }}
                                >
                                    <img width="100%" height="100%" src={app.image_url} />
                                </Flex>
                                <Text
                                    fontSize={{ _: 10, sm: 10, md: 10, lg: 14, xl: 16 }}
                                    sx={{ mt: "5px", fontWeight: "500", color: "#7482A6" }}
                                >
                                    {app.name.length > 15 ? app.name.substring(0, 15) + "..." : app.name}
                                </Text>
                            </Flex>
                            <ReactTooltip id={app.id + ""} effect="solid" place="bottom"></ReactTooltip>
                        </React.Fragment>
                    ))}
                </Flex>
            </Flex>
        </>
    )
}
