import { createSlice } from "../../../../../node_modules/@reduxjs/toolkit/dist"

const initialState = {
  sidebarCollapse: false,
  addOnCollapse: true,
  loadedApps: null,
  loadedAddOnApps: [],
}

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    updateSidebarCollapse: (state, action) => {
      state.sidebarCollapse = action.payload
    },
    updateAddonCollapse: (state, action) => {
      state.addOnCollapse = action.payload
    },
    toggleAddonCollapse: state => {
      state.addOnCollapse = !state.addOnCollapse
    },
    updateLoadedApps: (state, action) => {
      state.loadedApps = action.payload
    },
    updateLoadedAddOnApps: (state, action) => {
      state.loadedAddOnApps = action.payload
    },
  },
})

export const {
  updateSidebarCollapse,
  updateAddonCollapse,
  toggleAddonCollapse,
  updateLoadedApps,
  updateLoadedAddOnApps,
} = sidebarSlice.actions

export default sidebarSlice.reducer
