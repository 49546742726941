import React from "react"
import { Box, Text, Flex } from "rebass"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "src/components"
import { useTranslation } from "react-i18next"
import { ToastContainer, toast } from "react-toastify"
import ConfirmationSvg from "src/assets/images/EmailVerificationDone"
import px2vw from 'utils/px2vw'
import { AuthTitle, AuthSubtitle } from 'src/components/Typography/Typography'

export const VerifyEmailView = ({ isVerified, loading, showError, onContinueClick }) => {
  const { t } = useTranslation()

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <AuthSubtitle>{t("auth.signup.subtitle")}</AuthSubtitle>
      </Box>
      <Box
        className="border-gradient"
        sx={{
          width: '121px',
          height: "0px",
          margin: "0px auto",
          mb: px2vw(10),
          mt: px2vw(5),
        }}
      />
      <Box
        sx={{
          textAlign: "center",
          mx: '0px',
          px: '0px'
        }}
      >
        <AuthTitle fontSize={{ _: 20, sm: 20, md: 24, lg: 26, xl: 33 }}>{showError ? t("auth.signup.verifySuccess.verifyFailedTitle") : t("auth.signup.verifySuccess.title")}</AuthTitle>
      </Box>
      <Flex flexDirection={["column", "column", "column"]} sx={{ height: "100%", flexGrow: 1, justifyContent: "center" }}>
        <Box
          sx={{
            textAlign: "center",
            mt: px2vw(40),
            mb: px2vw(20),
          }}
        >
          {showError ? (
            <Box sx={{ color: "app.primary.100" }}>{t("auth.signup.signupSuccess.verifyErrorMsg")}</Box>
          ) : (
              <ConfirmationSvg />
            )}
        </Box>

        <Button
          height={{ _: 40, sm: 40, md: 40, lg: 40, xl: 60 }}
          fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }}
          sx={{ mx: px2vw(18), fontStyle: "normal" }}
          stretch
          data-tracking="click"
          data-category="Login"
          data-action="User login click"
          disabled={showError}
          className={showError ? 'disable-button' : ''}
          onClick={onContinueClick}
        >
          {t("auth.signup.continueBtnLabel")}
        </Button>
      </Flex>
      <Flex flexDirection="column" sx={{ flexGrow: 1, justifyContent: "flex-end", alignItems: "center" }}>
        {showError && (<Box sx={{ pt: px2vw(18), textAlign: 'center' }}>
          <Text fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }}
            sx={{ display: "inline-block", fontStyle: 'normal', color: 'app.secondary.20' }}
          >
            {t("auth.signup.alreadyHaveAccountText")}<a href='/login' style={{ color: '#F7C545' }}>{t("auth.signup.loginBtnLabel")}</a>
          </Text>
        </Box>)}
        <Box sx={{ pt: px2vw(20), textAlign: "center" }}>
          <Text fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }} sx={{ display: "inline-block", fontStyle: "normal", color: "app.secondary.20" }}>
            {t("auth.login.clickingContinueText")}
            <a href="/signup" style={{ color: "#F7C545" }}>
              {t("auth.login.termsAndConditionsText")}
            </a>
          </Text>
        </Box>
      </Flex>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  )
}
