import React, { useState, useEffect, useRef } from "react"
import { Box, Flex, Image } from "rebass"
import { useTranslation } from "react-i18next"
import styled from "@emotion/styled"
import { Link as RouterLink } from "react-router-dom"
import {
  faUserCircle,
  faSquare,
  faHome,
  faBell,
  faTh,
  faHistory,
  faSignOutAlt,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons"
import { Link as ReLink } from "rebass"
import { Span, Link, Icon } from "src/components"
import px2vw from "utils/px2vw"
import AuthTokenService from "src/utils/AuthTokenService"
import { svg_1 } from "assets/images/svg"
import { useThemeContext } from "../ReactFlow/store/context/themeContext"

const Wrapper = styled(Flex)`
  padding: 0px 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
`
const menuItems = [
  {
    key: 1,
    icon: svg_1.home,
    label: "sideNav.homeLabel",
    route: "/dashboard",
  },
  {
    key: 2,
    icon: svg_1.apps,
    label: "sideNav.appsLabel",
    route: "/apps",
  },
  {
    key: 3,
    icon: svg_1.konnect,
    label: "Konnectz",
    route: "/Konnectz",
  },
  // {
  //   key: 3,
  //   icon: faSquare,
  //   label: "sideNav.konnectzLabel",
  //   route: "/konnectz",
  // },
  {
    key: 4,
    icon: svg_1.history,
    label: "sideNav.historyLabel",
    route: "/history",
  },
  {
    key: 5,
    icon: svg_1.profile,
    label: "sideNav.accountLabel",
    route: "/account",
  },
  // {
  //   key: 6,
  //   icon: faBell,
  //   label: "sideNav.notificationsLabel",
  //   route: "/notifications",
  // },
  // {
  //   key:7,
  //   icon:faBell,
  //   label:"Reactflow",
  //   route:"/Reactflow"
  // },
  // {
  //   key:8,
  //   icon:faSquare,
  //   label:"Konnectz 2.0",
  //   route:"/ReactflowKonnectz"
  // }
]

export const Shell = ({ children, props }) => {
  const { i18n } = useTranslation("translation")
  const [expandMenu, setExpandMenu] = useState(false)

  function onMenuHover(expand) {
    setExpandMenu(expand)
  }

  useEffect(() => {
    i18n.changeLanguage(AuthTokenService.getLang())
  }, [])

  return (
    <Wrapper flexDirection={"column"}>
      <Flex width="100%" height="100%" flexDirection="row">
        <LeftPanel className="left-panel-css" onMenuHover={onMenuHover} expandMenu={expandMenu} />
        <Flex flexDirection="column" flexGrow="1" width={expandMenu ? "85%" : "95%"} sx={{ bg: "#F3F7FF" }}>
          {children}
        </Flex>
      </Flex>

      {/* <Footer /> */}
    </Wrapper>
  )
}

const LeftPanel = ({ expandMenu, onMenuHover }) => {
  const { t } = useTranslation()
  const { selected, setSelected } = useThemeContext()
  useEffect(() => {
    let route = localStorage.getItem("selected-route")
    if (route) {
      setSelected(route)
    }
  }, [])
  const logoRef = useRef()
  return (
    <Flex
      flexDirection="column"
      width={expandMenu ? "15%" : "5%"}
      height="100%"
      onMouseEnter={() => onMenuHover(true)}
      onMouseLeave={() => onMenuHover(false)}
      sx={{
        "z-index": 10,
        minWidth: "auto",
        backgroundColor: "app.primary.30",
        fontWeight: "500",
        fontSize: "18px",
        justifyContent: "space-between",
        color: "#FFF !important",
        transition: "width 0.35s",
        "& .selected": {
          color: "app.secondary.50",
        },
      }}
    >
      <Flex sx={{ cursor: "pointer", height: "10%", flexDirection: "row", width: "100%" }}>
        <a href="/" style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", gap: "20px" }}>
          <Flex ref={logoRef} sx={{ m: "auto" }} height={{ _: 32, sm: 32, md: 34, lg: 38, xl: 45 }}>
            {svg_1.logo}
            {expandMenu && (
              <Flex fontSize={{ _: 20, sm: 20, md: 22, lg: 24, xl: 26 }} sx={{ ml: "20px", color: "#FFF" }}>
                {t("sideNav.konnectzITLogoText")}
              </Flex>
            )}
          </Flex>
        </a>
      </Flex>

      {menuItems.map(menu => (
        <RouterLink
          key={menu.key}
          onClick={() => {
            onMenuHover(false)
            setSelected(menu.route)
            localStorage.setItem("selected-route", menu.route)
          }}
          to={menu.route}
          style={{
            textDecoration: "none",
            outline: "none",
            width: "100%",
            height: "10%",
            display: "flex",
            alignItems: "center",
            position: "relative",
            zIndex: selected === menu.route ? 9 : 0,
            boxShadow: selected === menu.route ? "0px -13px 12px -13px #011947,0px 15px 10px -10px #011947" : "none",
            transition: "box-shadow 0.25s",
          }}
        >
          <Flex className={selected === menu.route ? "selected" : ""} sx={{ width: "100%" }}>
            <Flex
              sx={{
                m: !expandMenu
                  ? "auto"
                  : ["auto 0px auto 30px", "auto 0px auto 40px", "auto 0px auto 50px", "auto 0px auto 60px"],
                fill: selected === menu.route ? "app.secondary.50" : "#FFF",
                transform: selected === menu.route ? "scale(1.2)" : "none",
              }}
              height={{ _: 18, sm: 18, md: 20, lg: 22, xl: 24 }}
            >
              {menu.icon}
            </Flex>
            {expandMenu && (
              <Flex
                fontSize={{ _: 16, sm: 16, md: 16, lg: 18, xl: 20 }}
                sx={{
                  ml: "20px",
                  color: selected === menu.route ? "app.secondary.50" : "#FFF",
                  m: "auto 0px auto 20px",
                }}
              >
                {t(menu.label)}
              </Flex>
            )}
          </Flex>

          {selected === menu.route && (
            <Box
              height="100%"
              width="4px"
              sx={{ position: "absolute", right: 0, top: "0px", bg: "app.secondary.50" }}
            />
          )}
        </RouterLink>
      ))}
      <Flex height="20%" />
      <Flex sx={{ flexGrow: 1, flexDirection: "column", justifyContent: "flex-end", height: "20%" }}>
        <Flex sx={{ cursor: "pointer", height: "50%", flexDirection: "row", width: "100%" }}>
          <a
            href={"https://docs.konnectzit.com"}
            target="_blank"
            style={{ width: "100%", height: "100%", display: "flex", alignItems: "center" }}
          >
            <Flex
              sx={{
                m: !expandMenu
                  ? "auto"
                  : ["auto 0px auto 30px", "auto 0px auto 40px", "auto 0px auto 50px", "auto 0px auto 60px"],
                fill: "#FFF",
              }}
              height={{ _: 18, sm: 18, md: 20, lg: 22, xl: 24 }}
            >
              {svg_1.help}
            </Flex>
            {expandMenu && (
              <Flex fontSize={{ _: 16, sm: 16, md: 16, lg: 18, xl: 20 }} sx={{ ml: "20px", color: "#FFF" }}>
                {t("sideNav.getHelpLabel")}
              </Flex>
            )}
          </a>
        </Flex>
        <RouterLink
          to={"/logout"}
          onClick={() => setSelected("/dashboard")}
          style={{
            textDecoration: "none",
            outline: "none",
            width: "100%",
            height: "50%",

            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          {" "}
          <Flex sx={{ width: "100%" }}>
            <Flex
              sx={{
                m: !expandMenu
                  ? "auto"
                  : ["auto 0px auto 30px", "auto 0px auto 40px", "auto 0px auto 50px", "auto 0px auto 60px"],
                fill: "#FFF",
              }}
              height={{ _: 18, sm: 18, md: 20, lg: 22, xl: 24 }}
            >
              {svg_1.logout}
            </Flex>
            {expandMenu && (
              <Flex fontSize={{ _: 16, sm: 16, md: 16, lg: 18, xl: 20 }} sx={{ ml: "20px", color: "#fff" }}>
                {" "}
                {t("sideNav.logoutLabel")}
              </Flex>
            )}
          </Flex>
        </RouterLink>
      </Flex>
    </Flex>
  )
}
