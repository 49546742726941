import React, { useState, useEffect } from 'react'
import { GetHelpView } from './GetHelp.view'
import { SearchHeader } from "src/components"

export const GetHelpContainer = (props) => {

    useEffect(() => {

    }, [])

    return (
        <>
            <SearchHeader />
            <GetHelpView />
        </>
    )
}
