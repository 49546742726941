import React, { useEffect, useState } from "react"
import { Box, Text, Flex, Link, Button, Image } from "rebass"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw"

import { useHistory } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import Switch from "react-switch"
import InfiniteScroll from "react-infinite-scroll-component"
import { Modal } from "react-responsive-modal"
import { DeleteModal } from "./Modals/DeleteModal.view"
import { CreateFolderModal } from "./Modals/CreateFolderModal.view"
import { MoveFolderModal } from "./Modals/MoveFolderModal.view"
import { RenameFolderModal } from "./Modals/RenameFolderModal.view"
import { DeleteFolderModal } from "./Modals/DeleteFolderModal.view"
import { useData } from "./Context/canvasContext"

import Cross from "../../assets/images/cross.svg"
import LeftArrow from "../../assets/images/leftArrow.svg"
import { ToastContainer, toast } from "react-toastify"
import { P, SearchHeader } from "../../components/index"
import { svg } from "./asset/svg"
import { svg_1 } from "../../assets/images/svg"
import { useThemeContext } from "./store/context/themeContext"
const ReactflowCanvas = () => {
  const { t } = useTranslation()
  const [showRenameIcon, setShowRenameIcon] = useState(false)

  const [toggle, setToggle] = useState(false)
  const [expandedItem, setExpandedItem] = useState(null)
  const {
    inValue,
    getKonnectsList,
    foldersList,
    setKonnectId,
    konnectId,
    folderKonnects,
    createFolder,
    getFoldersList,
    moveKonnectsToFolder,
    getKonnectsByFolderId,
    deleteKonnectFolder,
    renameKonnectFolder,
    updateKonnectStatus,
  } = useData()
  const { bP } = useThemeContext()
  const history1 = useHistory()
  const [searchValue, setSearchValue] = useState(null)
  const [pageSize, setPageSize] = useState(30)
  const [loading, setLoading] = useState(false)
  const [showMoveIcon, setShowMoveIcon] = useState(false)
  const [k, setK] = useState(null)
  const [pageIndex, setPageIndex] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [openCreateFolderModal, setOpenCreateFolderModal] = useState(false)
  const [openMoveFolderModal, setOpenMoveFolderModal] = useState(false)
  const [openRenameFolderModal, setOpenRenameFolderModal] = useState(false)
  const [openDeleteFolderModal, setOpenDeleteFolderModal] = useState(false)
  const [selectedKonnects, setSelectedKonnects] = useState([])
  const [konnectsPageIndex, setKonnectsPageIndex] = useState(1)
  const [folderId, setFolderId] = useState(null)
  const [filterMode, setFilterMode] = useState(false)
  const [deleteId,setDeleteId] = useState(null)
  const [id, setId] = useState(null)
  const [selectedFolderName, setSelectedFolderName] = useState(null)
  const [showNewFolder, setShowNewFolder] = useState(true)
  const [konnectCount, setKonnectCount] = useState(null)
  const konnectsList = filterMode ? (folderKonnects ? folderKonnects : null) : inValue.konnects.konnects
  const [folderCollapse, setFolderCollapse] = useState(false)
  const [selectHover, setSelectHover] = useState(false)
  const [moveEnable, setMoveEnable] = useState(false)
  const [konnects, setKonnects] = useState(
    konnectsList && konnectsList.length > 0 ? konnectsList.filter(konnect => konnect.status !== "DELETED") : null
  )
  const onSearchItem = e => {
    setSearchValue(e.currentTarget.value)
  }
  useEffect(() => {
    let konnectsArr =
      konnectsList && konnectsList.length > 0 ? konnectsList.filter(konnect => konnect.status !== "DELETED") : null
    setKonnects(konnectsArr)
  }, [konnectsList])
  useEffect(() => {
    getKonnectsList(
      searchValue ? { page_size: pageSize, search_query: searchValue } : { page_size: pageSize, page_index: pageIndex }
    )
  }, [selectedFolderName])
  useEffect(() => {
    getFoldersList()
  }, [folderKonnects])

  useEffect(() => {
    let params = {
      page_size: pageSize,
    //  page_index: pageIndex,
    }
    let params1 = {
      page_size: pageSize,
    //  page_index: pageIndex,
      search_query: searchValue,
    }

    getKonnectsList(searchValue ? { ...params1 } : { ...params })
  }, [pageSize, searchValue])

  const fetchMoreKonnects = () => {
    setPageSize(pageSize + 10)
   // setKonnectsPageIndex(konnectsPageIndex + 1)
  }

  const setExpandKonnect = konnectId => {
    setExpandedItem(konnectId)
  }

  const filterKonnectsByFolderId = (folderId, event) => {
    setSelectedKonnects([])
    setMoveEnable(false)
    showLoader(true)
    if (!folderId) {
      var ele = document.getElementsByClassName("folder-btn")
      for (var i = 0; i < ele.length; i++) {
        ele[i].style.backgroundColor = "#FFFFFF"
        ele[i].style.color = "#827E7E"
      }
      setShowRenameIcon(false)
      setFolderId(null)
      filterKonnects(folderId, true)
      return
    }
    let folderBtnElements = Array.from(document.getElementsByClassName("folder-btn"))
    folderBtnElements.map(element => {
      if (event.currentTarget !== element) {
        element.style.backgroundColor = "#FFFFFF"
        element.style.color = "#827E7E"
      }
    })
    if (
      event.currentTarget.style.backgroundColor === "" ||
      event.currentTarget.style.backgroundColor === "rgb(255, 255, 255)"
    ) {
      event.currentTarget.style.backgroundColor = "#152F73"
      event.currentTarget.style.color = "#F7C545"
      setShowRenameIcon(true)
      setFolderId(folderId)

      const selectedName = foldersList.find(x => x.id === folderId).name
      const Count = foldersList.find(x => x.id === folderId).konnect_count
      setSelectedFolderName(selectedName)
      setKonnectCount(Count)

      // console.log(folderKonnects,"3333",foldersList,selectedFolderName,selectedName )
      filterKonnects(folderId, false)
    } else {
      event.currentTarget.style.backgroundColor = "#FFFFFF"
      event.currentTarget.style.color = "#827E7E"
      setShowRenameIcon(false)
      setFolderId(null)
      filterKonnects(folderId, true)
    }
  }

  const toggleForDropdown = p => {
    setExpandKonnect(p)
    setToggle(!toggle)
  }
  const deleteFolder = () => {
    deleteKonnectFolder(folderId).then(res => {
      if (res.status === "FAILURE") {
        toast.error(res.message),
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
      } else {
        toast.success(res.message),
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
      }
      setOpenDeleteFolderModal(false)
      getFoldersList()
      filterKonnectsByFolderId(null, null)
      setSelectedFolderName(null)
      setSelectedKonnects([])
      setMoveEnable(false)
    })
  }

  const onCloseModal = () => {
    setOpenModal(false)
  }

  const deleteKonnectModal = konnectId => {
    
    setOpenModal(true)
   
    setKonnectId(konnectId)
    setDeleteId(konnectId)
    // setId(konnectId)
  }

  const changeStatus = (konnectId, status) => {
    //  console.log(konnectId, "aaasd", status)
    setLoading(true)
    updateKonnectStatus({ id: konnectId, status: status === "ACTIVE" ? "INACTIVE" : "ACTIVE" }).then(() => {
      getKonnectsList({
       // page_index: konnectsPageIndex,
        page_size: pageSize,
      }).then(() => {
        setLoading(false)
      })
    })
    if (status === "INACTIVE") {
      toast.info("Status  changed to ACTIVE"),
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
    }
    if (status === "ACTIVE") {
      toast.info("Status  changed to INACTIVE"),
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
    }
    if (status === "Draft") {
      toast.info("Failed  because status is Draft"),
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
    }
  }

  const deleteKonnect = status => {
    setLoading(true)
    updateKonnectStatus({ id:deleteId, status }).then(() => {
      if (folderId) {
        getKonnectsByFolderId(folderId).then(() => {
          setLoading(false)
        })
      } else {
        getKonnectsList({
          //page_index: konnectsPageIndex,
          page_size: pageSize,
        }).then(() => {
          setLoading(false)
        })
      }

      onCloseModal()
    })
  }
  const onEditKonnect = id => {
    setId(id)

    //history1.push("/konnect/" + id)
  }
  const showAllKonnects = () => {
    history1.push("/ReacKonnectz/")
  }

  const lengthFolder = () => {}
  const filterKonnects = (id, clearFilter) => {
    setLoading(true)
    setSelectedKonnects([])
    setShowMoveIcon(false)
    setFilterMode(clearFilter ? false : true)
    if (!clearFilter) {
      setFolderId(id)
      getKonnectsByFolderId(id).then(res => {
        //  console.log("res",res)
        setLoading(false)
      })
    } else {
      setFolderId(null)
      getKonnectsList().then(() => {
        setLoading(false)
      })
    }
  }

  const onCreateFolderBtnClick = () => {
    setOpenCreateFolderModal(true)
  }

  const createFolderName = folderName => {
    createFolder(folderName).then(() => {
      getFoldersList()
      setOpenCreateFolderModal(false)
    })
  }

  const onMoveFolderIconClick = () => {
    setOpenMoveFolderModal(true)
  }

  const updateKonnectsSelection = item => {
    let itemExists = false
    let updatedList = []
    if (selectedKonnects.find(node => node.index === item.index)) {
      selectedKonnects.map((konnect, idx) => {
        if (konnect.index !== item.index) {
          updatedList.push(konnect)
        }
      })
      setSelectedKonnects(updatedList)
      itemExists = true
    }

    if (!itemExists) setSelectedKonnects(arr => [...arr, item])
    // setShowMoveIcon(selectedKonnects.length > 0)
  }

  const moveFolder = folderId => {
    setLoading(true)
    setOpenMoveFolderModal(false)
    setFolderId(folderId)
    moveKonnectsToFolder(folderId, selectedKonnects).then(() => {
      setFilterMode(true)
      setShowMoveIcon(false)
      setSelectedKonnects([])
      setSelectedFolderName(selectedFolderName)
      getKonnectsByFolderId(folderId).then(() => {
        setLoading(false)
      })
    })
  }

  const onRenameFolderIconClick = folderId => {
    setFolderId(folderId)
    setOpenRenameFolderModal(true)
  }

  const renameFolder = folderName => {
    renameKonnectFolder(folderId, folderName).then(() => {
      setOpenRenameFolderModal(false)
      getFoldersList()
    })
  }

  const onDeleteFolderIconClick = folderId => {
    setFolderId(folderId)
    setOpenDeleteFolderModal(true)
  }

  const showLoader = show => {
    setLoading(show)
  }

  
  useEffect(() => {
    localStorage.setItem("editKonnectId", JSON.stringify(id))
    if (id > 0) {
      history1.push("/konnect/" + id)
    }
  }, [id])

  return (
    <>
      <SearchHeader
        searchValue={searchValue}
        onSearchItem={onSearchItem}
        konnectz={true}
        hideSearch={false}
        gptEnabled={true}
      />
      <Flex
        p={{ _: 26, sm: 26, md: 28, lg: 30, xl: 32 }}
        height="90%"
        sx={{ flexDirection: "column", gap: [26, 28, 30, 32] }}
      >
        <Flex
          p={{ _: 14, sm: 14, md: 16, lg: 18, xl: 20 }}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "16px",
            boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
            flexDirection: "column",
            gap: [14, 16, 18, 20],
          }}
        >
          <Flex width="100%" sx={{ justifyContent: "space-between" }}>
            {!selectedFolderName ? (
              <Flex
                onClick={() => setFolderCollapse(c => !c)}
                sx={{
                  "&:hover": {
                    "& #collapse": {
                      transform: "scale(1.3)",
                    },
                    "& #text": {
                      fontWeight: 500,
                    },
                  },
                }}
              >
                <Box
                  id="collapse"
                  // onClick={() => setFolderCollapse(false)}
                  width={{ _: 16, sm: 16, md: 18, lg: 20, xl: 22 }}
                  sx={{
                    m: "auto 10px auto 0px",
                    fill: "#152F73",
                    cursor: "pointer",
                    display: folderCollapse ? "flex" : "none",
                    "&:hover": {
                      transform: "scale(1.3)",
                    },
                    transition: "fill 0.25s, transform 0.25s",
                  }}
                >
                  {svg.minimize}
                </Box>
                <Box
                  id="collapse"
                  // onClick={() => setFolderCollapse(true)}
                  width={{ _: 16, sm: 16, md: 18, lg: 20, xl: 22 }}
                  sx={{
                    m: "auto 10px auto 0px",
                    fill: "#152F73",
                    cursor: "pointer",
                    display: !folderCollapse ? "flex" : "none",
                    "&:hover": {
                      transform: "scale(1.3)",
                    },
                    transition: "fill 0.25s, transform 0.25s",
                  }}
                >
                  {svg.maximize}
                </Box>
                <Text
                  id="text"
                  fontSize={{ _: 18, sm: 18, md: 20, lg: 22, xl: 22 }}
                  sx={{
                    transition: "font-weight 0.25s",
                    cursor: "pointer",
                    fontWeight: "500",
                    color: "#152F73",
                    m: "auto 0px",
                  }}
                >
                  Folders ({foldersList.length ? foldersList.length : "0"})
                </Text>{" "}
              </Flex>
            ) : (
              <Box
                fontSize={{ _: 17, sm: 17, md: 20, lg: 22, xl: 22 }}
                onClick={() => {
                  setShowNewFolder(c => !c)
                  filterKonnectsByFolderId(null, null)
                  setSelectedFolderName(null)
                }}
                sx={{
                  cursor: "pointer",
                  color: "gray",
                  display: selectedFolderName ? "flex" : "none",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {" "}
                <LeftArrow />
                Back
              </Box>
            )}
            {!showRenameIcon ? (
              <Button
                fontSize={{ _: 14, sm: 14, md: 16, lg: 16, xl: 18 }}
                onClick={() => {
                  selectedKonnects.length > 0 && onMoveFolderIconClick()
                  selectedKonnects.length === 0 && onCreateFolderBtnClick()
                }}
                sx={{
                  backgroundColor: "#1E419D",
                  color: "#fff",
                  fontWeight: 400,
                  borderRadius: "16px",
                  p: ["6px 15px", "6px 15px", "8px 20px", "10px 25px"],
                  display: !showRenameIcon ? "flex" : "none",
                  fill: "#fff",
                  "&:hover": {
                    fill: "#F7C545",
                  },
                }}
              >
                <Flex width={{ _: 11, sm: 11, md: 13, lg: 15, xl: 17 }} sx={{ m: "auto 10px auto 0px" }}>
                  {selectedKonnects.length > 0 ? svg.moveFolder : svg.newfolder}
                </Flex>
                {selectedKonnects.length > 0 ? "Move" : "New Folder"}
              </Button>
            ) : (
              <Flex sx={{ gap: "20px" }}>
                <Button
                  onClick={() => onRenameFolderIconClick(folderId)}
                  fontSize={{ _: 14, sm: 14, md: 16, lg: 16, xl: 18 }}
                  sx={{
                    backgroundColor: "#1E419D",
                    color: "#fff",
                    fontWeight: 400,
                    borderRadius: "16px",
                    p: ["6px 15px", "6px 15px", "8px 20px", "10px 25px"],
                    display: showRenameIcon ? "flex" : "none",
                    fill: "#fff",
                    "&:hover": {
                      fill: "#F7C545",
                    },
                  }}
                >
                  <Flex width={{ _: 11, sm: 11, md: 13, lg: 15, xl: 17 }} sx={{ m: "auto 10px auto 0px" }}>
                    {svg.edit_1}
                  </Flex>
                  Rename
                </Button>
                <Button
                  onClick={() => onDeleteFolderIconClick(folderId)}
                  fontSize={{ _: 14, sm: 14, md: 16, lg: 16, xl: 18 }}
                  sx={{
                    backgroundColor: "red",
                    color: "#fff",
                    fontWeight: 400,
                    borderRadius: "16px",
                    p: ["6px 15px", "6px 15px", "8px 20px", "10px 25px"],
                    display: showRenameIcon ? "flex" : "none",
                    fill: "#fff",
                    "&:hover": {
                      fill: "#F7C545",
                    },
                  }}
                >
                  <Flex width={{ _: 11, sm: 11, md: 13, lg: 15, xl: 17 }} sx={{ m: "auto 10px auto 0px" }}>
                    {svg.delete_1}
                  </Flex>
                  Delete
                </Button>
              </Flex>
            )}
          </Flex>
          {!selectedFolderName && folderCollapse ? (
            <Flex flexDirection={"row"} sx={{ width: "100%" }}>
              <Flex
                sx={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: foldersList && foldersList.length > 0 ? "100%" : "0%",
                }}
              >
                {foldersList &&
                  foldersList.length > 0 &&
                  foldersList.map((folder, index) => (
                    <>
                      <Box id={`${folder.name + index}`} px={2} py={2} width={1 / 6} key={"folder" + index}>
                        <Button
                          data-folderid={folder.id}
                          // className="folder-btn"
                          data-tip={folder.name}
                          onClick={event => filterKonnectsByFolderId(folder.id, event)}
                          width="100%"
                          sx={{
                            backgroundColor: folderId === folder.id ? "#152F73" : "#FFFFFF",
                            color: folderId === folder.id ? "#F7C545" : "#152F73",
                            borderRadius: "8px",
                            fontSize: "14px",
                            p: ["8px 15px", "8px 15px", "8px 15px", "10px 15px"],
                            fontWeight: 500,
                            boxShadow: "8px 6px -6px rgb(81,142,248, 0.35)",
                            border: "1px solid lightgray",
                          }}
                          // iconRight=
                        >
                          <Flex sx={{ alignItems: "center", gap: "10px" }}>
                            <Flex width={{ _: 36, sm: 36, md: 38, lg: 38, xl: 40 }}>{svg.folder}</Flex>
                            <Box sx={{ textAlign: "left", justifyContent: "space-between" }}>
                              <Text fontSize={{ _: 14, sm: 14, md: 16, lg: 16, xl: 18 }}>
                                {folder.name.length > 11 ? folder.name.substring(0, 11) + ".." : folder.name}
                              </Text>
                              <Text
                                fontSize={{ _: 10, sm: 10, md: 12, lg: 12, xl: 14 }}
                                sx={{ color: "lightgray", fontSize: "10px" }}
                              >
                                {folder.konnect_count} Konnects
                              </Text>
                            </Box>
                          </Flex>
                        </Button>
                      </Box>
                      <ReactTooltip place="bottom" />
                    </>
                  ))}
              </Flex>
            </Flex>
          ) : null}
        </Flex>
        <Flex
          p={{ _: 14, sm: 14, md: 16, lg: 18, xl: 20 }}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "16px",
            boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
            flexDirection: "column",
            gap: [14, 16, 18, 20],
            height: "100%",
          }}
        >
          <Flex
            fontSize={{ _: 18, sm: 18, md: 20, lg: 22, xl: 22 }}
            sx={{
              fontWeight: "500",
              color: "#152F73",
              m: "auto 0px",
              gap: "10px",
            }}
          >
            {selectedFolderName && <Flex width={{ _: 36, sm: 36, md: 38, lg: 38, xl: 40 }}>{svg.folder}</Flex>}

            <Flex
              onClick={() => {
                if (!selectedFolderName) {
                  if (selectedKonnects.length > 0 && moveEnable) {
                    setSelectedKonnects([])
                  }
                  setMoveEnable(c => !c)
                }
              }}
              onMouseEnter={() => setSelectHover(true)}
              onMouseLeave={() => setSelectHover(false)}
              sx={{
                cursor: "pointer",
                fill: "#152F73",
                "&:hover": {
                  "& #icon": {
                    transform: "scale(1.3)",
                  },
                },
              }}
            >
              <Box
                id="icon"
                width={{ _: 16, sm: 16, md: 18, lg: 20, xl: 22 }}
                sx={{
                  display: !selectedFolderName ? "flex" : "none",
                  m: "auto 10px auto 0px",
                  // transform: moveEnable ? "scale(1.3)" : null,
                  transition: "fill 0.25s, transform 0.25s",
                }}
              >
                {(selectHover || moveEnable) && selectedKonnects?.length === 0
                  ? svg.select
                  : selectedKonnects?.length > 0 && moveEnable
                  ? svg.minus
                  : svg.unselect}
              </Box>
              <Text
                id="text"
                fontSize={{ _: 18, sm: 18, md: 20, lg: 22, xl: 22 }}
                sx={{
                  transition: "font-weight 0.25s",
                  cursor: "pointer",

                  fontWeight: "500",
                  color: "#152F73",
                  m: "auto 0px",
                }}
              >
                {selectedFolderName ? selectedFolderName : moveEnable ? "Select Konnectz" : "Recent Konnectz"}
              </Text>
            </Flex>
          </Flex>
          <Flex
            sx={{
              width: "100%",
              height: "100%",
              position: "relative",
              flexDirection: "column",
            }}
          >
            <Flex
              id="scrollableDiv1"
              sx={{
                position: "absolute",
                top: "0px",
                bottom: "0px",
                right: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                gap: [14, 16, 18, 20],
                overflowY: "auto",
                p: "3px",
              }}
            >
              {konnects && konnects.length > 0 ? (
                <InfiniteScroll
                  style={{ display: "flex", flexDirection: "column", gap: "20px", padding: "3px" }}
                  dataLength={konnects ? konnects.length : null || 0} //This is important field to render the next data
                  next={fetchMoreKonnects}
                  hasMore={true}
                  pullDownToRefresh={false}
                  scrollableTarget="scrollableDiv1"
                  loader={
                    !searchValue && (
                      <Flex
                        width={{ _: 32, sm: 32, md: 34, lg: 38, xl: 45 }}
                        height={{ _: 32, sm: 32, md: 34, lg: 38, xl: 45 }}
                        sx={{
                          cursor: "pointer",
                          fill: "#152f73",
                          m: "20px auto",
                          animation: "rotation 2s infinite linear",
                        }}
                      >
                        {svg.load}
                      </Flex>
                    )
                  }
                >
                  {konnects &&
                    konnects.length > 0 &&
                    konnects.map((konnect, idx) => {
                      return (
                        <Flex
                          key={idx}
                          onClick={() => moveEnable && updateKonnectsSelection({ ...konnect, index: idx })}
                          p={{ _: "14px", sm: "14px", md: "16px", lg: "18px", xl: "20px" }}
                          sx={{
                            width: "100%",
                            cursor: moveEnable ? "pointer" : "default",
                            backgroundColor: "#DBE5FF",
                            borderRadius: "16px",
                            zIndex: "900",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            outline: selectedKonnects?.find(node => node?.index === idx) ? "2px solid #f7c545" : null,
                            boxShadow: selectedKonnects?.find(node => node?.index === idx)
                              ? "0px 0px 3px 1px #f7c545"
                              : "0px 0px 3px 0px rgb(81,142,248, 0.35)",
                            "&:hover": {
                              outline: moveEnable && "2px solid #f7c545",
                              boxShadow: moveEnable ? "0px 0px 3px 1px #f7c545" : "0px 0px 3px 1px rgb(81,142,248)",
                            },
                          }}
                          // onClick={() => handleExpandKonnect(konnect.id)} - In case if you need expanding of the items and editing the konnect
                        >
                          <Flex width="70%" sx={{ flexDirection: "column", gap: ["6px", "8px", "10px", "12px"] }}>
                            <Box fontSize={{ _: 10, sm: 10, md: 12, lg: 12, xl: 16 }}>
                              {konnect.konnect_name ? (
                                konnect.konnect_name
                              ) : (
                                <>
                                  {konnect.left_app.name}
                                  {"  "}
                                  {konnect.right_apps.map(x => {
                                    return <>{" - " + x.app_name + "  "}</>
                                  })}
                                </>
                              )}
                            </Box>
                            <Flex width="100%" sx={{ position: "relative" }}>
                              <Image width="5%" src={konnect.left_app.image_url} />
                              {konnect.right_apps.length > 0 && (
                                <Flex
                                  sx={{
                                    m: "auto 0px",
                                    fill: "black",
                                  }}
                                >
                                  {svg_1.dashed_1}
                                </Flex>
                              )}

                              {konnect.right_apps.length > 0 &&
                                konnect.right_apps.map((rightApp, index) => {
                                  return (
                                    <>
                                      {index !== 0 && (
                                        <Flex
                                          sx={{
                                            m: "auto 0px",
                                            fill: "black",
                                          }}
                                        >
                                          {svg_1.dashed_1}
                                        </Flex>
                                      )}

                                      <Image width="5%" src={rightApp.image_url} />
                                    </>
                                  )
                                })}
                            </Flex>
                            <ReactTooltip id={"konnectText_" + konnect.id} effect="solid" place="right"></ReactTooltip>
                          </Flex>
                          <Flex  sx={{ alignItems: "center", gap: "50px" }}>
                            <Box  data-tip={konnect.status} sx={{mt:"7px"}}>
                            <Switch
                           
                              checked={konnect.status === "ACTIVE" ? true : false}
                              offColor="#B6B9BF"
                              onColor="#1E419D"
                              offHandleColor="#fff"
                              onHandleColor="#F7C545"
                              handleDiameter={bP([18, 20, 22, 22])}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={bP([28, 30, 32, 32])}
                              width={bP([60, 62, 64, 66])}
                              onChange={() => changeStatus(konnect.id, konnect.status)}
                            />
                            </Box>
                                <ReactTooltip place="bottom" />
                            <Flex sx={{ gap: "20px" }}>
                              <Flex
                              data-tip={"Edit"}
                                onClick={() => onEditKonnect(konnect.id)}
                                width={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
                                height={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
                                sx={{
                                  cursor: "pointer",
                                  "&:hover": {
                                    transform: "scale(1.2)",
                                  },
                                  transition: "transform 0.25s",
                                }}
                              >
                                {svg_1.edit}
                              </Flex>
                              <ReactTooltip place="bottom" />
                              <Flex
                               data-tip={"Delete"}
                                onClick={() => deleteKonnectModal(konnect.id)}
                                width={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
                                height={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
                                sx={{
                                  cursor: "pointer",
                                  "&:hover": {
                                    transform: "scale(1.2)",
                                  },
                                  transition: "transform 0.25s",
                                }}
                              >
                                {svg_1.delete}
                              </Flex>
                              <ReactTooltip place="bottom" />
                            </Flex>
                          </Flex>
                        </Flex>
                      )
                    })}
                </InfiniteScroll>
              ) : (
                <Text sx={{ fontSize: "20px", color: "app.primary.30", m: "auto" }}>No Konnectz Found</Text>
              )}
            </Flex>
          </Flex>

          {konnects && konnects.length === 0 && (
            <Box sx={{ px: px2vw(30), py: px2vw(20) }}>
              <Text sx={{ fontSize: "20px", color: "app.primary.30" }}>{t("konnectzList.noKonnectzInfo")}</Text>
            </Box>
          )}
          {/* <Box
            id={"scrollableDiv1"}
            sx={{
              overflow: "scroll",
              overflowX: "hidden",
              bg: "white",
              mx: "25px",
              my: "25px",
              borderRadius: "15px",
              p: "10px",
            }}
          >
            {selectedFolderName ? (
              <Box sx={{ gap: "10px", m: "10px", fontSize: "16px", color: "#152F73" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    m: "10px",
                    fontSize: "16px",
                    color: "#152F73",
                  }}
                >
                  <Box>{<FolderIcon />}</Box> {selectedFolderName}
                </Box>
                {konnectCount === 0 && (
                  <Box sx={{ px: px2vw(30), py: px2vw(20) }}>
                    <Text sx={{ fontSize: "20px", color: "app.primary.30" }}>{t("konnectzList.noKonnectzInfo")}</Text>
                  </Box>
                )}
              </Box>
            ) : null}
            
          </Box> */}
        </Flex>
      </Flex>

      <Modal
        open={openModal}
        onClose={onCloseModal}
        closeIcon={
          <Flex
            sx={{
              "&:hover": {
                transform: "scale(1.2)",
              },
              transition: "transform 0.25s",
              cursor: "pointer",
              width: ["20px", "22px", "24px", "26px"],
              fill: "red",
            }}
          >
            {svg.xmark}
          </Flex>
        }
        center
        styles={{ modal: { padding: "20px", borderRadius: "16px" } }}
      >
        <DeleteModal closeModal={onCloseModal} onDeleteClick={deleteKonnect} />
      </Modal>
      <Modal
        open={openCreateFolderModal}
        onClose={() => setOpenCreateFolderModal(false)}
        center
        closeIcon={
          <Flex
            sx={{
              "&:hover": {
                transform: "scale(1.2)",
              },
              transition: "transform 0.25s",
              cursor: "pointer",
              width: ["20px", "22px", "24px", "26px"],
              fill: "red",
            }}
          >
            {svg.xmark}
          </Flex>
        }
        styles={{ modal: { padding: "20px", borderRadius: "16px", minWidth: "400px" } }}
      >
        <CreateFolderModal closeModal={() => setOpenCreateFolderModal(false)} createFolderName={createFolderName} />
      </Modal>
      <Modal
        open={openMoveFolderModal}
        onClose={() => setOpenMoveFolderModal(false)}
        closeIcon={
          <Flex
            sx={{
              "&:hover": {
                transform: "scale(1.2)",
              },
              transition: "transform 0.25s",
              cursor: "pointer",
              width: ["20px", "22px", "24px", "26px"],
              fill: "red",
            }}
          >
            {svg.xmark}
          </Flex>
        }
        center
        styles={{ modal: { padding: "20px", borderRadius: "16px", minWidth: "600px" } }}
      >
        <MoveFolderModal
          foldersList={foldersList}
          moveFolder={moveFolder}
          closeIcon={<Cross />}
          closeModal={() => setOpenMoveFolderModal(false)}
          createFolderName={createFolderName}
        />
      </Modal>
      <Modal
        open={openRenameFolderModal}
        onClose={() => setOpenRenameFolderModal(false)}
        center
        closeIcon={
          <Flex
            sx={{
              "&:hover": {
                transform: "scale(1.2)",
              },
              transition: "transform 0.25s",
              cursor: "pointer",
              width: ["20px", "22px", "24px", "26px"],
              fill: "red",
            }}
          >
            {svg.xmark}
          </Flex>
        }
        styles={{ modal: { padding: "20px", borderRadius: "16px", minWidth: "400px" } }}
      >
        <RenameFolderModal
          folderId={folderId}
          foldersList={foldersList}
          closeModal={() => setOpenRenameFolderModal(false)}
          renameFolder={renameFolder}
        />
      </Modal>
      <Modal
        open={openDeleteFolderModal}
        onClose={() => setOpenDeleteFolderModal(false)}
        closeIcon={
          <Flex
            sx={{
              "&:hover": {
                transform: "scale(1.2)",
              },
              transition: "transform 0.25s",
              cursor: "pointer",
              width: ["20px", "22px", "24px", "26px"],
              fill: "red",
            }}
          >
            {svg.xmark}
          </Flex>
        }
        center
        styles={{ modal: { padding: "20px", borderRadius: "16px", minWidth: "400px" } }}
      >
        <DeleteFolderModal closeModal={() => setOpenDeleteFolderModal(false)} deleteFolder={deleteFolder} />
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  )
}

const CheckboxSelection = ({ index, konnect, updateKonnectsSelection, showMoveIcon }) => {
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    if (!showMoveIcon) {
      setChecked(false)
    }
  }, [showMoveIcon])

  return (
    <Box sx={{ alignSelf: "center" }}>
      <input
        type="checkbox"
        id={`custom-checkbox-${index}`}
        onChange={e => {
          setChecked(!checked)
          updateKonnectsSelection(konnect.id, !checked)
        }}
        checked={checked}
        name={konnect.id}
        value={konnect.id}
      />
    </Box>
  )
}

export default ReactflowCanvas
