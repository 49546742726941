import UnlimitedSteps from "assets/images/features/unlimited-steps.png"
import VisualizeEverything from "assets/images/features/visualize-everything.png"
import IntegrationLibrary from "assets/images/features/integration-library.png"
import SimpleAutomation from "assets/images/features/simple-automation.png"
import PlatformForEveryone from "assets/images/features/platform-for-everyone.png"
import SeamlessAppsIntegration from "assets/images/features/seamless-apps-integration.png"

export const es = {
  translation: {
    common: {
      app: "Konnectz",
    },
    header: {
      topNavigations: {
        tipsAndTricks: "Consejos y trucos",
        pricing: "Precios",
        blogs: "blogs",
        helpCenter: "Centro de ayuda",
      },
      searchAppTextPlaceholder: "Busca aquí...",
      createKonnectzBtnLabel: "Crear Konnectz",
      taskhistory: "Historial de tareas",
      success: "Éxito",
      error: "Error",
      notask: "No se encontró ninguna tarea",
      tryagain: "Ajusta tu filtro e inténtalo de nuevo"
    },
    errorMessages: {
      SENT_RESET_INSTRUCTIONS_SUCCESS: "Las instrucciones para restablecer la contraseña se han enviado por correo electrónico, verifique su correo electrónico.",
      SENT_RESET_INSTRUCTIONS_FAIL: "Solicitud no autorizada!",
      VERIFY_EMAIL_FAIL: "Lo sentimos, no se pudo confirmar su correo electrónico.",
      PASSWORD_NOT_UPDATED: "Lo sentimos, no se pudo actualizar su cuenta",
      GET_FORGOT_PASSWORD_LINK_SUCCESS: "",
      GET_FORGOT_PASSWORD_LINK_FAIL: "Error al enviar las instrucciones de la contraseña. Por favor, inténtalo después de un tiempo.",
      AUTH_SIGN_UP_FAIL: "Algo salió mal. Por favor, inténtalo después de un tiempo.",
      somethingWentWrong: "Algo salió mal. Por favor, inténtalo después de un tiempo.",
    },
    successMessages: {
      PASSWORD_UPDATED: "La contraseña ha sido actualizada. ¡Inicie sesión con su nueva contraseña!",
    },
    history:{
      task:{
        task: "tarea",
        tasks: "Tareas",
        taskdate: "fecha de la tarea:",
        user: "Usuario:"
      }
  },
    auth: {
      signup: {
        createPassword: {
          title: "Crear contraseña",
          subtitle: "Escríbalos DOS VECES para confirmar",
          passwordErrorMsg: "Contraseña invalida !",
          confirmPasswordErrorMsg: "Contraseña invalida !",
          passwordMatchingErrorMsg: "¡Ambas contraseñas no coinciden!",
          continueBtnLabel: "SEGUIR",
          createPasswordPlaceholder: "Crear contraseña",
          confirmPasswordPlaceholder: "Confirmar contraseña",
          passwordRule:
            "Sugerencia: su contraseña debe tener entre 8 y 15 caracteres y debe contener al menos una mayúscula, una minúscula, un dígito y un carácter especial.",
          passwordRule1: "Su contraseña debe tener entre 8 y 15 caracteres.",
          passwordRule2: "Su contraseña debe contener al menos una mayúscula.",
          passwordRule3: "Su contraseña debe contener al menos un dígito numérico.",
          passwordRule4: "Su contraseña debe contener al menos un carácter especial.",
        },
        verificationSent: {
          title: "VERIFICACIÓN",
          subtitle: "Confirme su dirección de correo electrónico",
          verificationText1: "Enviamos enlace de verificación en",
          verificationText2: ", abra el correo electrónico y verifique su dirección de correo electrónico.",
          didntReceiveEmailText: "¿No recibiste ningún correo?",
          resendText: "reenviar",
        },
        verifySuccess: {
          title: "Verificación Completada",
          verifyFailedTitle: "Fallo en la verificación",
        },
        signupSuccess: {
          firstName: "Primer nombre",
          firstNameErrorMsg: "Se requiere el primer nombre !",
          lastName: "Apellido",
          lastNameErrorMsg: "¡Se requiere el apellido!",
          verifyErrorMsg: "Algo salió mal. Intente iniciar sesión si su cuenta ya ha sido verificada.",
        },
        title: "Crea tu cuenta",
        subtitle: "Hola bienvenida",
        verifyingMessage: "Verificándote",
        emailInvalid: "El correo electrónico es invalido !",
        emailExists: "La dirección de correo electrónico que has introducido ya está registrada",
        teamSignup: "¿Registrarse como equipo?",
        continueBtnLabel: "Continuar",
        alreadyHaveAccountText: "Tener una cuenta ?",
        loginBtnLabel: "Registrarse",
        emailPlaceholder: "Ingrese correo electrónico",
        features: {
          feature1: {
            title: "Automatización sencilla",
            image: SimpleAutomation,
            description: "Cree un flujo de trabajo complejo de manera simple y fácil en minutos usando nuestro generador de Konnectz visual",
          },
          feature2: {
            title: "Plataforma para todas",
            image: PlatformForEveryone,
            description:
              "No es una herramienta de automatización regular solo para programadores, cualquiera puede construir automatización con KonnectzIT",
          },
          feature3: {
            title: "Integración perfecta de aplicaciones",
            image: SeamlessAppsIntegration,
            description: "Conecte aplicaciones ilimitadas desde nuestra biblioteca de integración y tareas diarias automatizadas",
          },
        },
      },
      login: {
        title: "Iniciar sesión en su cuenta",
        subtitle: "¡Hola, bienvenido de nuevo!",
        emailPlaceholder: "Ingrese correo electrónico",
        emailInvalid: "Email inválido !",
        passwordRequired: "Se requiere contraseña !",
        passwordPlaceholder: "Introducir la contraseña",
        rememberMe: "Recuérdame",
        loginBtnLabel: "INICIA SESIÓN AHORA",
        forFirstTimeSignup: "Por primera vez ?",
        forgotPasswordLbl: "Has olvidado tu contraseña",
        alreadyHaveAccountText: "¿No tienes una cuenta?",
        clickingContinueText: "Al hacer clic en continuar, usted acepta nuestro",
        termsAndConditionsText: "Términos y condiciones.",
        signupNowText: "Inscribirse",
        features: {
          feature1: {
            title: "Pasos ilimitados",
            image: UnlimitedSteps,
            description: "Conecte múltiples aplicaciones y cree pasos ilimitados para una automatización perfecta.",
          },
          feature2: {
            title: "visualizar todo",
            image: VisualizeEverything,
            description: "Cree sus flujos de trabajo y visualice la automatización utilizando un generador de Konnectz visual fácilmente.",
          },
          feature3: {
            title: "Biblioteca de integración",
            image: IntegrationLibrary,
            description: "Colección de aplicaciones en la nube para combinar y enviar datos entre ellas para ahorrar tiempo.",
          },
        },
        loginFailMsg: "¡El nombre de usuario o la contraseña no son válidos!",
        captchaErrorMsg: "Error de CAPTCHA",
      },
      forgot: {
        title: "Has olvidado tu contraseña",
        forgtoBtnLabel: "Obtener enlace Olvidé mi contraseña",
        successSubTitle: "Email enviado",
        features: {
          feature1: {
            title: "Automatización sencilla",
            image: SimpleAutomation,
            description: "Cree un flujo de trabajo complejo de manera simple y fácil en minutos usando nuestro generador de Konnectz visual",
          },
          feature2: {
            title: "Plataforma para todas",
            image: PlatformForEveryone,
            description:
              "No es una herramienta de automatización regular solo para programadores, cualquiera puede construir automatización con KonnectzIT",
          },
          feature3: {
            title: "Integración perfecta de aplicaciones",
            image: SeamlessAppsIntegration,
            description: "Conecte aplicaciones ilimitadas desde nuestra biblioteca de integración y tareas diarias automatizadas",
          }
        },
        emailInvalid: "¡Email inválido!",
      },
      reset: {
        title: "Restablecer la contraseña",
        subTitle: "Crear nueva contraseña",
      },
    },
    konnect: {
      sidebar: {
        search_apps: "Búsqueda...",
        choose_trigger: "Elija disparador",
        choose_trigger_header: "Elija el evento de la aplicación",
        no_triggers_available: "No hay disparadores disponibles",
        choose_format_header: "Opción de formato",
        choose_config_header: "Elija Configuración",
        select_trigger_action_dropdown_header: "Select",
        choose_option_header: "Definir opción",
        no_options_available: "No hay opciones disponibles",
        choose_linked_account: "Seleccionar cuenta",
        customize_form_header: "Configurar formulario",
        customize_form_partial: "Escoger",
        configure_response: "Personalizar respuesta",
        basic_auth: "Autenticación básica",
        api_key: "Clave API",
        api_token: "Secreto de la API",
        webhook_url: "URL del webhook",
        webhook_url_toast: "¡URL de webhook copiada al portapapeles!",
        webhook_response: "Respuesta de webhook",
        choose_action_event: "Elija evento de acción",
        endpoint_url: "URL de punto final",
        payload_type: "Tipo de carga útil",
        wrap_request_array: "Envolver solicitud en matriz",
        set_params: "Establecer parámetros",
        add_header: "Añadir encabezado",
        set_paramter: "Establecer parámetro",
        expiry_time: "Recuento de línea de tiempo",
        expiry_val: "Cronología",
        segmentIndex: "Índice de segmento",
        validate: "Validar",
        generate: "Generar",
      },
      UI: {
        betaText: "Beta",
        webhookDuplicateToastMsg: "¡La aplicación Webhook ya existe!",
      },
      header: {
        backBtnLabel: "atrás",
        saveBtnLabel: "Ahorrar",
        publishBtnLabel: "Publicar",
        nameOfKonnectz: "Nombre de la Konnectz",
      },
      testAndReview: {
        btnLabel: "Revisión de la prueba",
      },
      proceed: {
        btnLabel: "CONTINUAR",
      },
      additionalConfigsModal: {
        cancelBtnLabel: "Cancelar",
        okBtnLabel: "OK",
      },
      conditions: {
        addNewConditionBtnLabel: "Agregar nueva condición +",
        cancelBtnLabel: "Cancelar",
        okBtnLabel: "OK",
        operatorAndLabel: "Y",
        operatorOrLabel: "O",
        headerConditionText: "Condición",
        selectFieldHeaderTitle: "Seleccionar campo",
        selectConditionHeaderTitle: "Seleccionar condición",
        validateBtnLabel: "Validar",
        conditionsHeaderText: "Condiciones",
      },
      publishSuccessMsg: "Konnect se publica con éxito !",
      saveSuccessMsg: "Konnect se ha guardado con éxito.",
    },
    apps: {
      authenticatedAppsTitle: "Aplicaciones autenticadas",
      aunthenticateNowTitle: "Autenticar ahora",
      noAppsToAunthenticate: "No hay aplicaciones para autenticar en este momento.",
      authenticateNow: "No tienes ninguna aplicación autenticada. Autenticar ahora.",
      addAccountText: "Añadir cuenta",
      betaText: "Beta",
      oAuthHandlerText: "KonnectzIT OAuth Handler, por favor espere...",
      cancelBtnText: "Cancelar",
      yesContinueText: "si, continuar",
      requiredText: "(Requerida)",
      accessAPIModalHeaderText1: "Permita que KonnectzIT acceda",
      accessAPIModalHeaderText2: "¿cuenta?",
      accessAPIModalMainText1: "Puedes encontrar tu",
      accessAPIModalMainText2: "navegando hacia",
      accessAPIModalMainText3: "página de integración",
      authorized: "Aplicaciones autorizadas"
    },
    konnectzList: {
      homeText: "Casa",
      noKonnectzInfo: "No konnectz en este momento!",
      deleteModalBodyText: "¿Estás segura de que quieres eliminar?",
      deleteModalNoText: "No",
      deleteModalDeleteText: "Borrar",
      folders: {
        createFolderTitleText: "Dale un nombre a tu carpeta",
        createFolderInputPlaceholder: "Nombre de la carpeta",
        createFolderBtn: "Crear",
        deleteFolderConfirmationText: "¿Está seguro de que desea eliminar esta carpeta?",
        deleteBtn: "Borrar",
        selectFolderInfo: "Seleccione la carpeta en la que desea mover sus conexiones seleccionadas",
        moveFolderCancelBtn: "Cancelar",
        moveBtn: "Muevete",
        renameSaveBtn: "Ahorrar",
        renameYourFolderText: "Renombra tu carpeta",
        createFolderBtn: "Crear carpeta"
      }
    },
    dashboard: {
      widgets: {
        latestApps: {
          headerTitle: "Últimas aplicaciones",
        },
        totalKonnectz: {
          headerTitle: "Konnectz total",
          activeKonnectz: "Konnectz activa",
          inactiveKonnectz: "Konnectz inactivo",
        },
        latestKonnectz: {
          headerTitle: "Último Konnectz",
        },
        subscription: {
          contentText: "Desbloquea más potencia actualizando Pro",
          upgradeBtnLabel: "ACTUALIZAR AHORA",
        },
        taskConsumption: {
          headerTitle: "Consumo de tareas",
        },
      },
    },
    account: {
      countryRequiredText: "¡El país es obligatorio!",
      addressLine1RequiredText: "¡Se requiere la línea de dirección 1!",
      cityRequiredText: "Se requiere ciudad!",
      stateRequiredText: "¡Se requiere estado!",
      zipCodeRequiredText: "¡Se requiere el código postal!",
      taxIdRequiredText: "¡Se requiere identificación fiscal!",
      cityLabel: "Ciudad",
      stateLabel: "Estado",
      zipCodeLabel: "Código postal",
      taxIdLabel: "Identificación del impuesto",
      addressLine2Label: "Línea de dirección 2",
      addressLine1Label: "Dirección Línea 1",
      countryLabel: "País",
      billingtext:"Dirección de Envio",
      myProfileText: "Mi perfil",
      emailAddressLabel: "Dirección de correo electrónico",
      firstNameLabel: "Primer nombre",
      lastNameLabel: "Apellido",
      firstNameRequiredText: "¡Se requiere el primer nombre!",
      lastNameRequiredText: "¡Se requiere apellido!",
      companyNameLabel: "nombre de empresa",
      timeZoneLabel: "Zona horaria",
      changeLanguageLabel: "Cambiar idioma",
      changePasswordHeaderText: "Cambia la contraseña",
      currentPasswordLabel: "contraseña actual",
      currentPasswordRequiredText: "¡Se requiere contraseña actual!",
      newPasswordLabel: "Nueva contraseña",
      newPasswordRequiredText: "¡Se requiere una nueva contraseña!",
      confirmPasswordLabel: "Confirmar contraseña",
      confirmPasswordRequiredText: "¡Se requiere confirmar la contraseña!",
      updateBtnLabel: "Actualizar",
      editUpdateAccountSideBarText: "Editar/Actualizar cuenta",
      changePasswordSideBarText: "Cambia la contraseña",
      billingText: "Facturación",
      teamText: "Equipo",
      agencyText: "Agencia",
      sideBarHeaderAccountText: "Cuenta",
      profileUpdatedSuccessfullyText: "¡Perfil actualizado con éxito!",
      addTeamMemberText: "Agregar miembro del equipo",
      addSubAccountsText: "Agregar subcuentas",

      billing: {
        couponCodeText: "Código",
        applyCodeBtnLbl: "Aplica el código",
        tasksText: "Tareas",
        redeemedOnText: "Canjeado el",
        expiresOnText: "Expira el",
        noCouponsAppliedText: "Sin licencia aplicada",
        plansAndFeaturesText: "Planes y características",
        currentPlanText: "Plan actual",
        upgradeText: "Ascender de categoría",
        billingText: "Facturación",
        licenseerror:"¡Licencia invalida!",
        completionofprofile:"Complete su perfil antes de canjear el código. Esto es necesario para GPDR/procesamiento de datos y fines de facturación",
      },

      team: {
        name: "Nombre",
        firstName: "Primer nombre",
        lastName: "Apellido",
        access: "Acceso",
        email: "Correo electrónico",
        addBtn: "Agregar",
        cancelBtn: "Cancelar",
        assignAccessLbl: "Asignar acceso",
        noUsersInfo: "No hay usuarios disponibles",
        teamMembersHeaderText: "Miembros del equipo",
      },

      agency: {
        name: "Nombre del cliente :",
        firstName: "Primer nombre",
        lastName: "Apellido",
        tasks: "Tarea asignada :",
        consumed:"Tarea consumida:",
        totalSubaccounts: "Total de subcuentas:",
        email: "Correo electrónico :",
        active: "Activa:",
        inActive: "Inactiva:",
        addBtn: "Agregar",
        cancelBtn: "Cancelar",
        noAgenciesInfo: "No hay agencias disponibles",
        agencyMembersHeaderText: "Subcuentas",
        total:"Tareas totales:",
        update:"Actualizar",
        updateTask: "Actualizar tarea",
        delete: "Borrar",
        available: "Tarea disponible",
        deactivate: "Activar",
        activate: "Desactivar",
        subaccounterror:"¡Error al actualizar los detalles de la subcuenta!"

      },
    },
    sideNav: {
      homeLabel: "Casa",
      appsLabel: "aplicaciones",
      konnectzLabel: "Konnectz",
      historyLabel: "Historia",
      accountLabel: "Cuenta",
      notificationsLabel: "Notificaciones",
      logoutLabel: "Cerrar sesión",
      getHelpLabel: "¿Consigue ayuda?",
      konnectzITLogoText: "KonnectzIT",
    }
  },
}
