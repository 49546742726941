import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import AuthShellContainer from 'src/features/auth/shell/Shell.container'
import { VerifyEmailView } from './VerifyEmail.view'
import signupFeatures from "src/config/features/signup-features"
import { verifyEmail } from "src/store/actions/appActions"
import AuthTokenService from 'utils/AuthTokenService';

function VerifyEmailContainer(props) {
  const [registrationToken, setRegistrationToken] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    setLoading(true)
    props.verifyEmail({ confirmation_token: props.match.params.token }).then((res) => {
      setLoading(false)
      if (res.payload ?.key === 'VERIFY_EMAIL_SUCCESS') {
        AuthTokenService.storeToken({ token: res.payload.auth_token, lang: "en" })
        setRegistrationToken(res.payload.auth_token)
      } else if (res.payload ?.key === 'VERIFY_EMAIL_FAIL') {
        setShowError(true)
      } else {
        setShowError(true)
      }
    })
  }, [])

  function onContinueClick() {
    props.history.push('/apps', 'registered')
  }

  return (
    <>
      <AuthShellContainer {...props} features={signupFeatures.features}>
        <VerifyEmailView
          isVerified={registrationToken}
          loading={loading}
          showError={showError}
          onContinueClick={onContinueClick}
        />
      </AuthShellContainer>
    </>
  )
}

const mapStateToProps = state => {
  return {
    registrationToken: state.getIn(['app', 'registrationToken'])
  }
}

const mapDispatchToProps = dispatch => {
  return {
    verifyEmail: data => dispatch(verifyEmail(data))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyEmailContainer)
