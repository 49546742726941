import React, { useContext, createContext, useReducer, useEffect, useState } from "react"
import AuthTokenService from "utils/AuthTokenService"
import axios from "axios"
import { v4 } from "uuid"
import { isEqual, isEmpty, set, includes } from "lodash"
var tokenObj = AuthTokenService.get()
import { v4 as uuidv4 } from "uuid"
import { useHistory } from "react-router-dom"

import { useNodesState, useEdgesState } from "react-flow-renderer"
import { object } from "prop-types"
import GetAppType from "../Component/konnect/Node/NodeWrapper" //"./Component/konnect/Node/NodeWrapper"

//creating context
const CanvasContext = createContext()
//provider

function CanvasContextProvider({ children }) {
  const apiAppInitialState = {
    endpointURL: "",
    payloadType: [{ id: 1, name: "JSON", selected: true }],
    wrapRequestInArray: [
      { id: 1, name: "NO", selected: true },
      { id: 2, name: "YES", selected: false },
    ],
    headers: [{ key: "" }],
    params: [{ key: "" }],
  }

  const couponInitialState = {
    expiryNum: "",
    duration: [
      { id: 1, name: "Days", selected: true },
      { id: 2, name: "Week", selected: false },
      { id: 3, name: "Months", selected: false },
      { id: 4, name: "Years", selected: false },
    ],
  }

  const textSplitterInitialState = {
    segmentIndex: [
      { id: 1, name: "First", selected: true },
      { id: 2, name: "Second", selected: false },
      { id: 3, name: "Last", selected: false },
      { id: 4, name: "Second to Last", selected: false },
      { id: 5, name: "All", selected: false },
    ],
  }

  const initState = {
    editorMeta: {
      sidebar: { hidden: false },
      deleteLink: false,
      onWheel: true,
      deleteLinkPos: {
        x: 0,
        y: 0,
      },
      link: null,
      dirty: false,
      canPublish: false,
      canEdit: false,
    },
    apps: {},
    addOnApps: [],
    konnects: [],
    authorisedApps: [],
    appEvents: [],
    conditionsList: [],
    appEventConfig: [],
    appEventConfigDetails: [],
    history: {
      /*
              maintain only the props that needs to be tracked. Merge to state when needed
              {
              nodes: [],
              ports: [],
              links: [],  
              payload: {}
              }
              nodes: [],
              ports: [],
              links: [],
              */
      past: [],
      future: [],
      present: {
        nodes: [],
        handles: [],
        edges: [],

        payload: {},
      },
    },

    apiAppInitialState: apiAppInitialState,
    customAppData: {
      coupon: couponInitialState,
      textSplitter: textSplitterInitialState,
    },
    gSheetPopUp: false,
    payload: {},
    testAndReviewLoader: false,
    editorState: {
      offset: {
        x: -5000,
        y: -5000,
      },
      scale: 1,
      handles: [],
      links: [],
      nodes: [],

      selected: {},
      hovered: {},
      konnect_activity_id: null,
      konnect_name: "",
      konnect_id: null,
      canvas_name: "ReactFlow",
    },
    canedit: false,
    //enableCaptureWebhookResponseBtn: true
  }

  const [historyCount, setHistoryCount] = useState(0)
  const [inValue, setInValue] = useState(initState)
  const [nodes, setNodes, onNodesChange] = useNodesState(inValue.editorState.nodes)
  const [accounts, setAccounts] = useState([])
  const [appEventId, setAppEventId] = useState(null)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [accountId, setAccountId] = useState(null)
  const [edges, setEdges, onEdgesChange] = useEdgesState(inValue.editorState.links)
  const [selectedApp, setSelectedApp] = useState(null)
  const [tokenModal, setTokenModal] = useState(false)
  const [apiModal, setApiModal] = useState(false)
  const [update, setUpdate] = useState(false)
  const [addOn, setAddOn] = useState([])
  const [foldersList, setFoldersList] = useState({})
  const [value, setValue] = useState(null)
  const [eventSequence, setEventSequence] = useState(null)
  const [konnectid, setKonnectId] = useState(null)
  const [konnectTitle, setKonnectTitle] = useState("")
  const [tokenFieldsData, setTokenFieldsData] = useState([])
  const [edit, setEdit] = useState(false)
  const [nodeidd, setNodeidd] = useState([])
  const [folderKonnects, setFolderKonnects] = useState([])
  const [editId, setEditId] = useState(null)
  const [reactFlowInstance, setReactFlowInstance] = useState(null)
  const [aa, setAa] = useState(null)
  const [searchValue, setSearchValue] = useState(null)
  const [pageSize, setPageSize] = useState(20)
  const [editt, setEditt] = useState(false)
  const [count, setCount] = useState(0)
  const [propValue, setPropValue] = useState()
  const getId = () => uuidv4() //id for nodes
  const history1 = useHistory()

  const FlattenJSON = json => {
    let newData = []
    const extract = (obj, parentKey = []) => {
      if (!obj) return
      Object.keys(obj).map(key => {
        const __key = v4()
        if (typeof obj[key] === "object") {
          const k = `${[...parentKey, key].join(".$")}`
          const _parentKey = parentKey.map(x => `${x[0].toUpperCase()}${x.slice(1)}`)
          const nodeLabel = `${[..._parentKey, key[0] ? `${key[0].toUpperCase()}${key.slice(1)}` : "Undefined"].join(
            " > "
          )}`
          obj[key] &&
            newData.push({
              id: k,
              type: "node",
              parent: parentKey,
              label: nodeLabel,
              level: parentKey.length,
              items: { ...obj[key] },
              k,
              children: Object.keys(obj[key]).map(z => `${[k, z].join(".$")}`),
              isOpen: false,
              isAvailable: false,
              uiKey: __key,
            })

          extract(obj[key], [...parentKey, key])
        } else {
          const k = `${[...parentKey, key].join(".$")}`

          newData.push({
            id: k,
            label: key[0] ? `${key[0]?.toUpperCase()}${key?.slice(1)}` : "Undefined",
            value: obj[key],
            config_key: k,
            type: "leaf",
            parent: `${[...parentKey].join(".$")}`,
            level: parentKey.length,
            isOpen: false,
            uiKey: __key,
          })
        }
        return false
      })
    }
    extract(json)

    return newData
  }
  const clearState = id => {
    return setAa(id)
  }

  let k
  const [p, setP] = useState((k = 0))

  const getAppEvents = async props => {
    var tokenObj1 = AuthTokenService.get()

    const { data } = props
    var UrlInfo1 = {
      url: `https://stagingapp.konnectzit.com/api/v1/apps/${data.id}/app_events`, //api/v1/apps/authorized_apps1
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
    }

    try {
      let result = await axios(UrlInfo1)
      let response = await result.data
      const node = nodes.find(x => x.data.nodeid === data.nodeid)

      if (data) {
        const nodeIdx = nodes.length

        let _events
        // response.filter(x => x.side === "right")

        if (nodeIdx === 0) {
          _events = response.filter(x => x.side === "left")
        } else {
          _events = response.filter(x => x.side === "right")
        }

        data.appEvents = _events
        data.configResponse = []
      }

      let newEdges = edges.filter(x => x.target !== data.nodeid && x.source !== data.nodeid)
      setEdges(newEdges)
      setHistoryCount(c => c + 1)
    } catch (error) {
      console.log(error)
    }
    // const _state = changeHistory(inValue, "getAppEvents", inValue.editorState)
  }
  const [appsForChat, setAppsForChat] = useState([])
  const getAuthApps = async (searchQuery, authorizedOnly) => {
    let tokenObj1 = AuthTokenService.get()
    var UrlInfo = {
      url: `https://stagingapp.konnectzit.com/api/v1/apps`, //api/v1/apps/authorized_apps1
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
    }
    let qs
    if (searchQuery) {
      qs = Object.keys(searchQuery)
        .map(key => `${key}=${searchQuery[key]}`)
        .join("&")

      UrlInfo.url = UrlInfo.url + `?${qs}`
    }
    try {
      let result = await axios(UrlInfo)
      let response = await result.data
      let apps = []
      setInValue(inValue => ({ ...inValue, apps: response }))
    } catch (error) {
      console.log(error)
    }
  }
  const getAppsforGpt = async (searchQuery, authorizedOnly) => {
    let tokenObj1 = AuthTokenService.get()
    var UrlInfo = {
      url: `https://stagingapp.konnectzit.com/api/v1/apps`, //api/v1/apps/authorized_apps1
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
    }
    if (searchQuery) {
      const qs = Object.keys(searchQuery)
        .map(key => `${key}=${searchQuery[key]}`)
        .join("&")

      UrlInfo.url = UrlInfo.url + `?${qs}`
    }
    try {
      let result = await axios(UrlInfo)
      let response = await result.data.apps
      return response
    } catch (error) {
      console.log(error)
    }
  }

  const getAddOnApps = async () => {
    let tokenObj1 = AuthTokenService.get()

    var UrlInfo = {
      url: `https://stagingapp.konnectzit.com/api/v1/apps/add_on_apps`, //api/v1/apps/authorized_apps1
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
    }

    let result = await axios(UrlInfo)
    let response = await result.data
    // let apps = []
    setAddOn(response)

    return setInValue(inValue => ({ ...inValue, addOnApps: response }))
  }

  const getFoldersList = async id => {
    let tokenObj1 = AuthTokenService.get()
    let UrlInfo3 = {
      url: `https://stagingapp.konnectzit.com/api/v1/folders/list`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
    }
    let value
    const response = await axios(UrlInfo3)
    const result = await response.data

    const { folders } = result

    setFoldersList(folders)
  }

  const CaptureWebHookResponse = async props => {
    const { data } = props
    const { webhook_url: webhookUrl, id: nodeid, enabled, apiKey, apiSecret, appEvent } = data
    const identifiers = webhookUrl.split("/").pop()
    data.configResponses = []
    data.configResponsesOrig = []
    let params = ""
    // const _node = editorState.nodes.find(x => x.nodeId === node.nodeId)
    const payload = {}
    if (webhookUrl) {
      params += `?webhookUrl=${webhookUrl}`
      payload.webhookUrl = webhookUrl
    }

    if (nodeid) {
      params += `&left_app_id=${nodeid}`
      payload.left_app_id = nodeid
    }

    if (appEvent) {
      params += `&left_app_event_id=${appEvent}`
      payload.left_app_event_id = appEvent
    }

    if (enabled) {
      params += `&auth_enabled=true&api_key=${apiKey}&api_secret=${apiSecret}`
      payload.auth_enabled = true
      payload.api_key = apiKey
      payload.api_secret = apiSecret
    }

    if (nodes && nodes[0].data.konnect_id) {
      params += `&konnect_id=${nodes[0].data.konnect_id}`
      payload.konnect_id = nodes[0].data.konnect_id
    }

    // params += `&canvas_json=${encodeURI(JSON.stringify(EditorState))}`
    params += `&canvas_json=${encodeURI(JSON.stringify(inValue.editorState))}`
    payload.canvas_json = inValue.editorState
    // payload.canvas_json = EditorState
    let awaitingWebhookResponse = !!data.awaitingWebhookResponse
    let tokenObj1 = AuthTokenService.get()
    let UrlInfo3 = {
      url: `https://stagingapp.konnectzit.com/api/v1/konnect_webhooks/capture_webhook_response/${identifiers}`,
      method: "POST",
      data: payload,
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
    }
    const response = await axios(UrlInfo3)
    const result = await response.data

    if (result.message && result.message.toUpperCase() === "WAITING") {
      data.awaitingWebhookResponse = true
    } else {
      const { raw_response, konnect_id, konnect_activity_id } = result
      const { controller, identifier, action, ...rest } = raw_response
      let obj = {
        controller,
        identifier,
        action,
      }
      data.awaitingWebhookResponse = false
      const res = JSON.parse(JSON.stringify(raw_response).replace(/\:null/gi, ':""'))
      data.konnect_id = konnect_id
      data.webHookRawResponse = res
      let arr = FlattenJSON(obj)

      data.configResponsesOrig = FlattenJSON(raw_response)
      data.configResponses = FlattenJSON(raw_response)
      data.konnect_activity_id = konnect_activity_id
    }
    const { test_status: test, display_message } = result
    data.tested = true
    data.reviewed = test.toLowerCase() === "success"
    data.display_message = display_message

    return result
  }
  const onDeleteNode = props => {
    const { nodeid } = props
    setNodeidd(n => n.concat(nodeid))
    let newNodes = nodes.filter(x => x.id != nodeid)
    let newEdges = edges.filter(x => !(x.source === nodeid || x.target === nodeid))
    // const _state = changeHistory(inValue, "onDeleteNode", inValue.editorState)
    return (
      setHistoryCount(c => c + 1),
      setNodes(newNodes),
      setEdges(newEdges),
      setInValue(inValue => ({
        ...inValue,
        editorState: { ...inValue.editorState, nodes: newNodes, links: newEdges },
      }))
    )
  }
  const toggleBasicAuth = props => {
    const { enabled, nodeId, data } = props
    const node = nodes.find(x => x.id === nodeId)
    setHistoryCount(c => c + 1)
    // const _state = changeHistory(inValue, "toggleBasicAuth", inValue.editorState)

    return [(data.enabled = enabled), (data.apiKey = ""), (data.secretKey = "")]
  }
  const onWebhookAuthChange = props => {
    const { type, nodeId, input, data } = props
    const node = nodes.find(x => x.id === nodeId)
    setHistoryCount(c => c + 1)
    // const _state = changeHistory(inValue, "onWebhookAuthChange", inValue.editorState)

    return [(data[type] = input), (node.data.reviewed = false), (node.data.tested = false)]
  }

  const getAppEventConfigDetail = async props => {
    const {
      data,
      appId = "",
      nodeId = null,
      eventId = "",
      eventConfigId = "",
      source = false,
      prevSequence = null,
      app_account_id = null,
      config_key = "",
    } = props

    let tokenObj1 = AuthTokenService.get()

    let url = `https://stagingapp.konnectzit.com/api/v1/apps/${appId}/app_events/${eventId}/event_config/${
      source ? "left" : "right"
    }/${eventConfigId}/details?`

    let params = ""
    if (data.appEventSequences) {
      data.appEventSequences.map(x => {
        params += `&${x.config_key}=${x.id}`
      })
    }
    if (prevSequence) {
      url = `${url}${prevSequence.config_key}=${prevSequence.id}${params}`
    }

    if (app_account_id) url += `&app_account_id=${app_account_id}`

    // if (app_account_id) {
    //   // endpoint += params ? "&" : "?"
    //   url = url + `&app_account_id=${app_account_id}`
    // }
    let UrlInfo5 = {
      url: url,
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
      extra: {
        ...props,
      },
    }
    try {
      const response = await axios(UrlInfo5)
      const res = await response.data
      data.appEventConfigurationDetails = data.appEventConfigurationDetails.filter(c => c.config_key !== config_key) //.push("custom value")
      const configDetails = res.map(x => {
        return { ...x, config_key: config_key }
      })

      data.reviewed = false
      data.tested = false
      data.appEventConfigurationDetails = [...data.appEventConfigurationDetails, ...configDetails]
      setHistoryCount(c => c + 1)
      // const _state = changeHistory(inValue, "getAppEventConfigDetail", inValue.editorState)
    } catch (error) {
      console.log(error)
    }
    setUpdate(c => c + 1)

    // data.appEventConfigurationDetails = [res]
  }
  let l
  const [A, setA] = useState((l = 0))
  const getAppEventConfig = async props => {
    const { appId = "", nodeId = null, eventId = "", source = false, app_account_id = null, data } = props

    let url = `https://stagingapp.konnectzit.com/api/v1/apps/${appId}/app_events/${eventId}/event_config/${
      source ? "left" : "right"
    }`
    if (app_account_id) url += `?app_account_id=${app_account_id}`
    let UrlInfo4 = {
      url: url,
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj,
      },
      extra: {
        ...props,
      },
    }
    try {
      const response = await axios(UrlInfo4)
      const res = await response.data
      data.Type = props.type //have to work

      data.reviewed = false
      data.tested = false

      const configs = res.sort((x, y) => {
        return x.sequence - y.sequence
      })
      const hasSeqZero = configs.filter(x => x.sequence === 0).length
      if (hasSeqZero) {
        let _configs = configs.filter(x => x.sequence === 0)
        if (data.type === "ADD_ON" && data.provider.toLowerCase() === "dateformatter") {
          _configs = _configs.reverse()
        }

        data.configResponses = [..._configs]
        data.configResponsesOrig = [..._configs]
      } else {
        if (data.type != "ADD_ON" && data.provider != "api") {
          data.configResponses = []
          data.configResponsesOrig = []
        }
      }
      data.appEventConfigurations = configs
      if (data.provider.toLowerCase() !== "scheduler") {
        data.appEventConfigurations = configs.filter(x => x.sequence !== 0)
      }
      // data.appEventConfigurations = configs.filter(x => x.sequence !== 0)
      data.appEvent = eventId
      data.appEventSequences = []
      data.appEventConfigurationDetails = []
      let newEdges = edges.filter(x => x.target !== data.nodeid && x.source !== data.nodeid)
      setHistoryCount(c => c + 1)

      return (
        setInValue(inValue => ({ ...inValue, editorState: { ...inValue.editorState, links: newEdges } })),
        setEdges(newEdges)
      )
    } catch (error) {
      console.log(error)
    }
  }
  const setConfigMenuState = ({ ...props }) => {
    const { isOpen } = props
    inValue.editorMeta.onWheel = !isOpen
    return setUpdate(c => c + 1)
  }
  let c
  const getLinkedAccounts = async props => {
    var tokenObj1 = AuthTokenService.get()

    const { data } = props

    let UrlInfo = {
      url: `https://stagingapp.konnectzit.com/api/v1/apps/${data.id}/authorized_app_accounts`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
    }
    try {
      let result = await axios(UrlInfo)
      let res = await result.data
      const { authorized_app_accounts: response } = res
      data.linkedAccounts = response
      if (response.length === 1) {
        data.selectedAccount = response[0]
      }
    } catch (error) {
      console.log(error)
    }
    // const _state = changeHistory(inValue, "getLinkedAccounts", inValue.editorState)
    setHistoryCount(c => c + 1)
    setUpdate(c => c + 1)
  }
  const setLinkedAccount = props => {
    const { selected, data } = props
    data.selectedAccount = selected
    // data.configResponse = []
    // data.configResponses = []
    //  data.configResponsesOrig = []
    // data.appEventSequences = []
    // data.appEventConfigurations = []
    //data.appEventConfigurationDetails = []
    setHistoryCount(c => c + 1)
    // const _state = changeHistory(inValue, "setLinkedAccount", inValue.editorState)
  }

  const saveKonnect = async () => {
    const payload = {
      canvas_name: true,
      konnect_id: inValue.editorState.nodes[0].data.konnect_id,
      canvas_json: inValue.editorState,
      konnect_name: inValue.editorState.konnect_name,
      canvasname: "ReactFlow",
    }

    payload.canvas_json.canvas_name = "ReactFlow"
    const allReviewed = inValue.editorState.nodes.every(node => node.data.reviewed)
    let url =
      inValue.editorMeta.canPublish && allReviewed
        ? `https://stagingapp.konnectzit.com/api/v1/konnects/publish_konnect`
        : `https://stagingapp.konnectzit.com/api/v1/konnects/save_konnect`

    let urlSaveKonnect = {
      url: url,
      method: "POST",
      data: payload,
      headers: {
        Authorization: "Bearer " + tokenObj,
      },
    }
    let result = await axios(urlSaveKonnect)
    let res = await result.data
  }
  const onDeleteNodeV2 = async props => {
    let UrlInfo = {
      url: `https://stagingapp.konnectzit.com/api/v1//konnects/delete_activity`,
      data: props,
      method: "POST",
      headers: {
        Authorization: "Bearer " + tokenObj,
      },
    }
    let result = await axios(UrlInfo)
    let response = await result.data
    return setA(a => a + 1)
  }

  const payloadMaker = props => {
    const { editorState, appDetails } = props
    editorState.nodes = editorState.nodes.map((x, idx) => {
      x.data.order = idx
      return x
    })
    // const allReviewed = data.nodes.every(node => node.reviewed)
    const leftNode = editorState.nodes[0]
    const rightNodes = editorState.nodes.slice(1)
    let payload = {}
    let condition_activity_id = 0
    let pass_through_condition = false
    if (leftNode) {
      payload.left_app_id = leftNode.data.id
      payload.webhookUrl = leftNode.data.webhook_url ? leftNode.data.webhook_url : null
      payload.app_account_id = leftNode.data.selectedAccount ? leftNode.data.selectedAccount.id : null
      payload.left_app_event_id = leftNode.data.appEvent

      payload.order = 0
      payload.left_config = {}
      leftNode.data.appEventSequences.map(x => {
        payload.left_config[x.config_key] = x.id
      })
      payload.right_apps = []
      rightNodes.map((x, idx) => {
        const config = {}
        const textFormatterConfig = []
        const conditionsFormatterConfig = []
        // configresponses to filter the connections
        //let configResponses = x.data.configResponses ? x.data.configResponses.filter(x => !!x.port) : []
        if (x.data.type === "ADD_ON" && x.data.provider === "conditioner") {
          let operator = null
          x.data.conditions.map(e => {
            const condition = {
              attribute: {
                type: "key-map",
                value: e.field.value,
                konnect_activity_id: x.data.parent_konnect_activity_id,
              },
              condition: e.operator,
              condition_value: e.value.value
                ? e.value
                : {
                    type: "user-defined",
                    value: e.value,
                    // konnect_activity_id: x.parent_konnect_activity_id,
                  },
            }
            if (operator) condition.concat_value = operator
            operator = e.queryOperator ? e.queryOperator.toUpperCase() : null
            conditionsFormatterConfig.push(condition)
          })
        } else if (
          (x.data.type === "ADD_ON" &&
            (x.data.provider === "TextFormatter" ||
              x.data.provider === "DateFormatter" ||
              x.data.provider === "Converter")) ||
          x.data.provider === "NumberFormatter" ||
          x.provider === "scheduler"
        ) {
          x.data.fromPorts.map(e => {
            const n = inValue.editorState.nodes.find(u => u.data.nodeid === e.info.node)
            textFormatterConfig.push({
              konnect_activity_id: n.data.konnect_activity_id,
              display: e.display,
              id: e.id,
              config_key: n.data.configResponses.find(i => i.id === e.id).config_key,
            })
          })
        }
        x.data.configResponses &&
          x.data.configResponses.map(y => {
            if (y.value) {
              config[y.config_key] = {
                type: "user-defined",
                //value: y._id ? y._id : y.value,
                value: y.value,
              }
            } else {
              config[y.config_key] = {
                port: y.port,
                // port: y.port,
              }
            }
          })

        payload.right_apps[idx] = {
          app_id: x.data.id,
          nodeId: x.data.nodeid,

          config: { ...config },
          type: x.data.type,
          provider: x.data.provider || "",
          konnect_id: leftNode.data.konnect_id,
        }
        if (x.data.appEvent) {
          payload.right_apps[idx].app_event_id = x.data.appEvent
        }
        if (x.data.type !== "ADD_ON") {
          payload.right_apps[idx].app_account_id = x.data.selectedAccount.id
        }

        if (
          x.data.type === "ADD_ON" &&
          x.data.provider !== "conditioner" &&
          x.data.provider !== "api" &&
          x.provider !== "scheduler" &&
          x.data.provider !== "generator" &&
          x.data.provider.toLowerCase() !== "textsplitter"
        ) {
          let string = payload.right_apps[idx].config.text.value + ""
          //let string = payload.right_apps[idx].data.config.text.value + ""
          textFormatterConfig.map(d => {
            const f = `@[${d.display}]`
            const mapValue = { type: "key-map", value: d.id, konnect_activity_id: d.konnect_activity_id }
            const g = `@[${d.display}](${JSON.stringify(mapValue)})`
            string = string.replace(f, g)
          })
          payload.right_apps[idx].config.text.value = string
        }

        x.data.appEventSequences.map(u => {
          if (!payload.right_apps[idx].config) payload.right_apps[idx].config = {}
          payload.right_apps[idx].config[u.config_key] = u.id
        })

        if (x.data.type === "ADD_ON" && x.data.provider === "conditioner") {
          payload.right_apps[idx].config = [...conditionsFormatterConfig]
        } else if (x.data.type === "ADD_ON" && x.data.provider === "api") {
          payload.right_apps[idx].config.apiDetails = appDetails
        } else if (x.data.type === "ADD_ON" && x.data.provider === "generator") {
          payload.right_apps[idx].config.appDetails = appDetails
        } else if (x.data.type === "ADD_ON" && x.data.provider.toLowerCase() === "textsplitter") {
          if (appDetails.textSplitter)
            payload.right_apps[idx].config.segmentIndex = appDetails.textSplitter.segmentIndex
        }
      })
      payload.right_apps.map(x => {
        if (x.type === "ADD_ON") {
          if (x.provider != "generator" && x.provider != "api" && x.provider.toLowerCase() != "textsplitter") {
            return
          }
        }

        edges.map(y => {
          const fromNode = nodes.find(n => n.data.nodeid === y.source)
          const toNode = nodes.find(n => n.data.nodeid === y.target)

          if (fromNode.data.type !== "WEBHOOK")
            fromNode.data.configResponses = fromNode.data.configResponses.filter(x => !!x.port)
          if (toNode.data.type !== "WEBHOOK")
            toNode.data.configResponses = toNode.data.configResponses.filter(x => !!x.port)

          const sourcePortInfo = JSON.parse(y.sourceHandle.split("|")[1])
          const targetPortInfo = JSON.parse(y.targetHandle.split("|")[1])
          let custom = custom

          const {
            node: sourceNode,
            app: sourceAppId,
            appEvent: sourceAppEvent,
            port: sourceConfig_key,
          } = sourcePortInfo
          const SourcePortid = `${sourceNode.toString()}|${sourceAppId.toString()}|${
            sourceAppEvent === null || sourceAppEvent === undefined ? "undefined" : sourceAppEvent.toString()
          }|${sourceConfig_key.toString()}`

          const {
            node: TargetNode,
            app: TargetAppId,
            appEvent: TargetAppeventId,
            port: TargetConfig_key,
            id: TargetId,
          } = targetPortInfo
          const TargetPortid = `${TargetNode.toString()}|${TargetAppId.toString()}|${
            TargetAppeventId === null || TargetAppeventId === undefined ? "undefined" : TargetAppeventId.toString()
          }|${TargetConfig_key.toString()}`
          let customPortsource = `${sourceNode.toString()}` | 80 | 80 | custom

          const fromPort = fromNode.data.configResponses.find(r => r.port && r.port.id === SourcePortid)
          const toPort = toNode.data.configResponses.find(r => r.port && TargetPortid.indexOf(r.port.id) > -1)

          const toNodeIdx = inValue.editorState.nodes.findIndex(n => n.data.nodeid === TargetNode)
          const fromPortIdx = inValue.editorState.nodes.findIndex(n => n.data.nodeid === sourceNode)

          if (x.config[toPort.config_key] || x.config[toPort.id]) {
            if (fromPort.value && fromNode.data.type !== "WEBHOOK" && fromNode.data.type !== "WEBHOOK_API") {
              if (toPort.port.id.indexOf(x.nodeId) !== -1) {
                x.config[toPort.config_key] = {
                  type: "key-map",
                  value: fromPort.id,
                  // fromPort.mapped || fromNode.appType === "ADD_ON" || fromNode.appType === "WEBHOOK_API"
                  //   ? fromPort.id
                  //   : fromPort.value,
                }
                if (fromNode.data.konnect_activity_id !== undefined) {
                  x.config[toPort.config_key].konnect_activity_id = fromNode.data.konnect_activity_id
                    ? fromNode.data.konnect_activity_id
                    : 0
                }
              }
              // toNode.appEventSequences.map(u => {
              //   x.config[toPort.config_key][u.config_key] = u.id
              // })
            } else {
              if (toPort.port.id.indexOf(x.nodeId) !== -1) {
                x.config[toPort.config_key] = {
                  type: "key-map",
                  value: fromPort.id,
                }
              }
              x.config[toPort.config_key].konnect_activity_id = fromNode.data.konnect_activity_id
                ? fromNode.data.konnect_activity_id
                : 0

              toNode.data.appEventSequences.map(y => {
                x.config[y.config_key] = y.id
              })

              x.left_app_id = fromNode.appId
              x.left_app_event_id = fromNode.appEvent
              x.level = toNodeIdx - fromPortIdx
            }

            // if (fromNode.pass_through_condition && x.config[toPort.config_key]) {
            //   x.config[toPort.config_key].konnect_activity_id = fromNode.konnect_activity_id
            // }
          }

          if (toPort.port.id.indexOf(x.nodeId) !== -1) {
            x.config.pass_through_condition = !!fromNode.data.pass_through_condition
            x.config.condition_activity_id = !!fromNode.data.pass_through_condition
              ? fromNode.data.condition_activity_id
              : null

            toNode.data.pass_through_condition = !!fromNode.data.pass_through_condition
            toNode.data.condition_activity_id = !!fromNode.data.pass_through_condition
              ? fromNode.data.condition_activity_id
              : null
          }
        })
      })
    }

    payload.right_apps = payload.right_apps.map(x => {
      const y = { ...x }
      Object.keys(y.config).map(z => {
        if (z.includes(">")) {
          let k = z.indexOf(">")
          z = z.slice(0, k)
        }
        if (y.config[z] && y.config[z].port) {
          delete y.config[z]
        }
        if (z === "pass_through_condition" && !y.config[z]) delete y.config[z]
        if (z === "condition_activity_id" && !y.config[z]) delete y.config[z]
      })

      Object.keys(y.config).map(x => {
        if (x.includes(">")) {
          let k = x.indexOf(">")
          let m = x.slice(0, k)
          delete y.config[m]
        }
      })

      Object.keys(y.config).map(x => {
        if (x.includes(">")) {
          let k = x.indexOf(">")
          let m = x.slice(0, k)
          y.config[m] = y.config[x]
          delete y.config[x]
        }
      })

      Object.keys(y.config).map(x => {
        if (x.includes(">")) {
          let k = x.indexOf(">")
          let m = x.slice(0, k)
          delete y.config[m]
        }
      })

      Object.keys(y.config).map(x => {
        if (x.includes(">")) {
          let k = x.indexOf(">")
          let m = x.slice(0, k)
          y.config[m] = y.config[x]
          delete y.config[x]
        }
      })

      return y
    })

    return payload
  }

  const appTestAndReview = async props => {
    var tokenObj1 = AuthTokenService.get()

    const { data, nodeIdx, editorState, appDetails = [], schedulerData, delay_config } = props
    data.isloading = true
    let payload = {}
    let UrlInfo = {}
    if (nodeIdx === 0) {
      const config = {}
      data.appEventSequences.map(x => {
        config[x.config_key] = x.id
      })
      payload = {
        left_app_id: data.id,
        left_app_event_id: data.appEvent,
        left_config: data.type === "ADD_ON" && data.provider === "scheduler" ? { ...config, ...schedulerData } : config,
        canvas_json: editorState,
        app_account_id: data.selectedAccount ? data.selectedAccount.id : null,
      }
      if (appDetails) {
        payload.appDetails = appDetails
      }

      UrlInfo = {
        url: `https://stagingapp.konnectzit.com/api/v1/konnects/test_and_review`,
        method: "POST",
        data: { ...payload },
        extra: {
          nodeId: data.nodeid,
        },

        headers: {
          Authorization: "Bearer " + tokenObj1,
        },
      }
    } else {
      const _payload = payloadMaker({ editorState, appDetails, delay_config })
      payload = _payload.right_apps.find(x => x.nodeId === data.nodeid)

      delete payload.left_app_id
      delete payload.left_app_event_id
      delete payload.nodeId
      delete payload.type
      payload.canvas_json = editorState
      // if (editorState.konnect_activity_id) payload.konnect_activity_id = editorState.konnect_activity_id
      if (delay_config) {
        payload.delay_config = delay_config
        //  const nodeIdx = editorState.nodes.find(x =>)
        payload.delay_activity_id = data.konnect_activity_id
        payload.parse_delay_config = true
      }
      if (data.konnect_activity_id) {
        payload.konnect_activity_id = data.konnect_activity_id
      }
      if (data.type === "ADD_ON" && data.provider === "conditioner") {
        payload.konnect_activity_id = data.konnect_activity_id
      }
      if (data.type === "ADD_ON" && data.provider === "api" && data.provider === "generator") {
        payload.konnect_activity_id = data.parent_konnect_activity_id
      }
      if (data.type === "ADD_ON" && data.provider === "api" && data.provider.toLowerCase() === "textsplitter") {
        payload.konnect_activity_id = data.parent_konnect_activity_id
      }
      let dirty = false
      editorState.nodes[nodeIdx].data.configResponses = editorState.nodes[nodeIdx].data.configResponses.map(x => {
        if (x.config_key_required && !x.value) {
          x.dirty = true
          dirty = true
        }

        return x
      })

      if (dirty) {
        new Promise((resolve, reject) => {
          editorState.ts = new Date().getTime()
          inValue.editorMeta.dirty = true
          reject("workspace dirty")
        })
      }

      UrlInfo = {
        url: `https://stagingapp.konnectzit.com/api/v1/konnects/test_and_review`,
        method: "POST",
        data: { ...payload },
        extra: {
          nodeId: data.nodeid,
        },

        headers: {
          Authorization: "Bearer " + tokenObj1,
        },
      }
    }

    try {
      let result = await axios(UrlInfo)
      let response = await result.data

      const {
        condition_activity_id,
        config_fields,
        display_message,
        error,
        konnect_activity_id,
        konnect_id,
        pass_through_condition,
        raw_response,
        test_status,
      } = response
      const nodeIdx = nodes.findIndex(x => x.data.nodeid === data.nodeid)
      const currentLinks = edges.filter(x => x.target === data.nodeid)
      let parentNode = null
      data.tested = true
      data.isloading = false
      data.reviewed = test_status ? test_status.toLowerCase() === "success" : false
      data.display_message = display_message
      data.additionalResponses = FlattenJSON(raw_response)
      data.condition_activity_id = condition_activity_id
      data.config_fields = config_fields
      data.error = error
      data.konnect_activity_id = konnect_activity_id
      data.konnect_id = konnect_id
      data.pass_through_condition = pass_through_condition
      data.raw_response = raw_response
      data.test_status = test_status
      inValue.editorMeta.canPublish = true
      data.configResponses = data.configResponses.map(q => {
        return {
          ...q,
          error: null,
        }
      })
      data.configResponses = data.configResponses.filter(q => !q.additional)

      if (data.type === "ADD_ON" && data.provider === "conditioner") {
        if (nodes[nodeIdx].data.configResponses.length === 1 && currentLinks.length) {
          parentNode = nodes.find(x => x.data.nodeid === currentLinks[0].source)
          const newConfigResponses = parentNode.data.configResponses.map(x => {
            const props = _.pick(x, [
              "app_event_id",
              "app_id",
              "config_key",
              "id",
              "label",
              "type",
              "value",
              "isOpen",
              "level",
              "children",
            ])
            return props
          })
          nodes[nodeIdx].data.configResponses = [...nodes[nodeIdx].data.configResponses, ...newConfigResponses]
          nodes[nodeIdx].data.additionalResponses = parentNode.additionalResponses
            ? [...parentNode.additionalResponses]
            : []
          if (data.reviewed) {
            //data = nodes[nodeIdx].data

            data.pass_through_condition = true
            data.condition_activity_id = response.konnect_activity_id
          }
        }
      }
      if (data.pass_through_condition) {
        data.pass_through_condition = true
        data.condition_activity_id = response.konnect_activity_id
        data.konnect_activity_id = response.konnect_activity_id
      }
      if (data.pass_through_condition) {
        data.pass_through_condition = true
        data.condition_activity_id = response.konnect_activity_id
        data.konnect_activity_id = response.konnect_activity_id
      }
      let gsheetPop = false
      if ((data.provider === "google-form" || data.provider === "google_sheet") && data.konnect_custom) {
        gsheetPop = true
      }
      if (!data.reviewed) {
        if (response.errors) {
          Object.keys(response.errors).map(o => {
            const field = data.configResponses.findIndex(p => p.config_key === o)
            if (field !== -1) {
              data.configResponses[field].error =
                response.errors[o].message || `${data.configResponses[field].label} is not valid`
            }
          })
        }
      }

      const configFields = data.reviewed
        ? typeof config_fields === "string"
          ? JSON.parse(config_fields)
          : config_fields
        : {}

      data.configResponses = data.configResponses.map(x => {
        return {
          ...x,
          ts: new Date().getTime(),
          value: configFields[x.config_key] || configFields[x.id] || x.value || null,
          mapped: !!(configFields[x.config_key] || configFields[x.id]),
        }
      })

      if (data.type === "ADD_ON" && data.provider === "api") {
        if (!(response.errors && response.errors.message) && response.test_status != "Failure") {
          let response = typeof raw_response === "string" ? JSON.parse(raw_response) : raw_response
          data.additionalResponses = []
          data.additionalResponses = FlattenJSON(response)
        }
      }
    } catch (error) {
      data.isloading = false

      console.log(error)
    }
    // const _state = changeHistory(inValue, "appTestAndReview", inValue.editorState)
    setHistoryCount(c => c + 1)
    setP(p => p + 1)
  }

  const onLinkComplete = props => {
    const { id, sourceHandle, targetHandle, source, target } = props
    // const chart = inValue.editorState
    const sourcePortInfo = JSON.parse(sourceHandle.split("|")[1])
    const targetPortInfo = JSON.parse(targetHandle.split("|")[1])

    const config = {}
    const linkId = id
    const fromNodeId = source //node source
    const fromPortId = `${sourcePortInfo.node}|${sourcePortInfo.app}|${sourcePortInfo.app_event_id}|${
      sourceHandle.split("|")[0]
    }`
    //const fromPortId = params.sourceHandle.split("|")[0]
    const toNodeId = target
    const toPortId = `${targetPortInfo.node}|${targetPortInfo.app}|${targetPortInfo.appEvent}|${
      targetHandle.split("|")[0]
    }`
    //const toPortId = params.targetHandle.split("|")[0]
    const currentLink = inValue.editorState.links.findIndex(x => x.id === linkId)

    let linkExists = false
    //all links except current one
    const allLinks = edges.filter(x => x.id !== linkId)
    //early exit; if from same source
    if (fromNodeId === toNodeId) {
      return (
        setInValue(inValue => ({
          ...inValue,
          editorState: { ...inValue.editorState, links: allLinks },
        })),
        setEdges(allLinks)
      )
    }

    // early exit; if target already linked

    const toNode = nodes.find(x => x.data.nodeid === toNodeId).data
    const fromNode = nodes.find(z => z.data.nodeid === fromNodeId).data
    const { type = "", provider = "" } = toNode
    linkExists = inValue.editorState.links.filter(x => x.targetHandle === targetHandle).length

    if (
      (linkExists && type !== "ADD_ON") ||
      (linkExists && type === "ADD_ON" && provider.toLowerCase() === "dateformatter")
    ) {
      //  chart.links = allLinks
      // const _chart = fromJS(chart)
      return (
        setInValue(inValue => ({
          ...inValue,
          editorState: { ...inValue.editorState, links: allLinks },
        })),
        setEdges(allLinks)
      )
    }

    if (type === "ADD_ON" && (provider.toLowerCase() === "conditioner" || provider.toLowerCase() === "api")) {
      let isFromSameNode = false

      const fromNodeLinks = inValue.editorState.links.filter(d => d.target === toNode.nodeid)
      if (fromNodeLinks.length && fromNodeLinks[0].source === fromNode.nodeid) {
        isFromSameNode = true
      }
      if (fromNodeLinks.length && !isFromSameNode) {
        // chart.links = allLinks
        // const _chart = fromJS(chart)
        return (
          setInValue(inValue => ({
            ...inValue,
            editorState: { ...inValue.editorState, links: allLinks },
          })),
          setEdges(allLinks)
        )
      } else {
        if (!toNode.fromFields) toNode.fromFields = []
        toNode.fromFields.push({ ...sourcePortInfo, linkId })
        toNode.parentNode = fromNodeId
        toNode.parent_konnect_activity_id = fromNode.konnect_activity_id
      }
    }

    if (type === "ADD_ON" && sourcePortInfo.type === "source") {
      if (!toNode.fromPorts) {
        toNode.fromPorts = []
      }

      const newEntry = {
        info: sourcePortInfo,
        id: sourcePortInfo.port,
        display: sourcePortInfo.label,
        linkId,
      }
      if (toNode.fromPorts.filter(h => isEqual(newEntry, h)).length) {
        inValue.editorState.links.pop()
        // const _chart = fromJS(chart)
        return
      }
      toNode.fromPorts.push(newEntry)
    }

    const configResponse = toNode.configResponses.find(
      x => x.config_key === targetHandle.split("|")[0] || x.id === targetHandle.split("|")[0]
    )
    configResponse.dirty = false

    if (configResponse.value && type !== "ADD_ON") {
      // chart.links = allLinks
      // const _chart = fromJS(chart)
      return (
        setInValue(inValue => ({
          ...inValue,
          editorState: { ...inValue.editorState, links: allLinks },
        })),
        setEdges(allLinks)
      )
    }

    linkExists = inValue.editorState.links.find(x => x.id === linkId)

    if (linkExists) {
      linkExists.target = toNodeId
      linkExists.targetHandle = targetHandle
    }
    toNode.tested = false
    toNode.reviewed = false
    const editorMeta = inValue.editorMeta
    editorMeta.canPublish = false
    const fromField = fromNode.configResponses.find(
      x => x.config_key === sourceHandle.split("|")[0] || x.id === sourceHandle.split("|")[0]
    )
    const toField = toNode.configResponses.find(
      x => x.config_key === targetHandle.split("|")[0] || x.id === targetHandle.split("|")[0]
    )
    // if (fromNode.appType === "ADD_ON" && fromNode.provider === "TextFormatter") {
    //   toField.value = fromField.key_value_type
    // } else {

    toField.value = fromField.value

    const nodeIdx = inValue.editorState.nodes.findIndex(x => x.data.nodeid === fromNode.nodeId)

    if (fromNode.type === "WEBHOOK_API" && nodeIdx) {
      toField._id = fromField.id
    }

    // console.log(!linkExists,"linkExists",allLinks)
    // if(linkExists){
    //   let edgesss=inValue.editorState.links.slice(0,inValue.editorState.links.length).filter(x=>x.targetHandle !==targetHandle)
    //   console.log(edgesss,"edgessss")
    //   setEdges(edgesss.concat({

    //       id: id,
    //       source: source,
    //       sourceHandle: sourceHandle,
    //       target: target,
    //       targetHandle: targetHandle,
    //       type: "buttonedge",

    //   }))
    //   setInValue(inValue => ({
    //     ...inValue,
    //     editorState: {
    //       ...inValue.editorState,
    //       links: [
    //         ...edgesss,
    //         {
    //           id: id,
    //           source: source,
    //           sourceHandle: sourceHandle,
    //           target: target,
    //           targetHandle: targetHandle,
    //           type: "buttonedge",
    //         },
    //       ],
    //     },
    //   }))

    // }
    // if(!linkExists) {
    //   alert("hello")
    //   setInValue(inValue => ({
    //     ...inValue,
    //     editorState: {
    //       ...inValue.editorState,
    //       links: [
    //         ...inValue.editorState.links,
    //         {
    //           id: id,
    //           source: source,
    //           sourceHandle: sourceHandle,
    //           target: target,
    //           targetHandle: targetHandle,
    //           type: "buttonedge",
    //         },
    //       ],
    //     },
    //   }))
    // }

    // if (
    //   (linkExists && type !== "ADD_ON") ||
    //   (linkExists && type === "ADD_ON" && provider.toLowerCase() === "dateformatter")
    // ) {
    //   setInValue(inValue => ({
    //     ...inValue,
    //     editorState: { ...inValue.editorState, links: allLinks },
    //   }))
    // }

    // if (type === "ADD_ON" && (provider.toLowerCase() === "conditioner" || provider.toLowerCase() === "api")) {
    //   let isFromSameNode = false

    //   const fromNodeLinks = inValue.editorState.links.filter(d => d.to.port === toNode.nodeid)
    //   if (fromNodeLinks.length && fromNodeLinks[0].source === fromNode.nodeid) {
    //     isFromSameNode = true
    //   }
    //   if (fromNodeLinks.length && !isFromSameNode) {
    //     setInValue(inValue => ({
    //       ...inValue,
    //       editorState: { ...inValue.editorState, links: allLinks },
    //     }))
    //   } else {
    //     if (!toNode.fromFields) toNode.fromFields = []
    //     toNode.fromFields.push({ ...sourcePortInfo, linkId })
    //     toNode.parentNode = fromNodeId
    //     toNode.parent_konnect_activity_id = fromNode.konnect_activity_id
    //   }
    // }

    // if (type === "ADD_ON" && sourcePortInfo.type === "source") {
    //   if (!toNode.fromPorts) {
    //     toNode.fromPorts = []
    //   }

    //   const newEntry = {
    //     info: sourcePortInfo,
    //     id: sourcePortInfo.port,
    //     display: sourcePortInfo.label,
    //     linkId,
    //   }
    //   if (toNode.fromPorts.filter(h => isEqual(newEntry, h)).length) {
    //     edges.pop()
    //     inValue.editorState.links.pop()
    //     // const _chart = fromJS(chart)
    //     // return state.set("editorState", _chart)
    //   }
    //   toNode.fromPorts.push(newEntry)
    // }

    // const configResponse = toNode.configResponses.find(
    //   x => x.config_key === targetHandle.split("|")[0] || x.id === targetHandle.split("|")[0]
    // )
    // configResponse.dirty = false

    // if (configResponse.value && type !== "ADD_ON") {
    //   setInValue(inValue => ({
    //     ...inValue,
    //     editorState: { ...inValue.editorState, links: allLinks },
    //   }))
    // }

    // linkExists = inValue.editorState.links.find(x => x.id === linkId)

    // if (linkExists) {
    //   linkExists.target = toNodeId
    //   linkExists.targetHandle = targetHandle
    // }

    //     toNode.tested = false
    //     toNode.reviewed = false
    //     const editorMeta = inValue.editorMeta
    //     editorMeta.canPublish = false
    //     const fromField = fromNode.configResponses.find(
    //       x => x.config_key === sourceHandle.split("|")[0] || x.id === sourceHandle.split("|")[0]
    //     )
    //     const toField = toNode.configResponses.find(
    //       x => x.config_key === targetHandle.split("|")[0] || x.id === targetHandle.split("|")[0]
    //     )
    //     // if (fromNode.appType === "ADD_ON" && fromNode.provider === "TextFormatter") {
    //     //   toField.value = fromField.key_value_type
    //     // } else {
    // console.log(toField.value,"toFieldValue")
    // if( toField.value===undefined){
    //   toField.value = fromField.value

    // }
    // else {
    //   toField.value = fromField.value

    // }
    //     console.log(toField.value,"toFieldValue1")
    //     const nodeIdx = inValue.editorState.nodes.findIndex(x => x.data.nodeid === fromNode.nodeId)

    //     if (fromNode.type === "WEBHOOK_API" && nodeIdx) toField.id = fromField.id

    // const { target, targetHandle } = b
    //const targetPortInfo = JSON.parse(targetHandle.split("|")[1])

    const { app, appEvent, config_key, key_value_type, label, node, port } = targetPortInfo
    const Node = nodes.find(n => n.id === node)
    const { data } = Node
    const { appEventSequences, selectedAccount } = data
    if (config_key.includes(">")) {
      getAppEventConfigDetailCustomKeymap({
        data,
        app: data.id,
        appEvent: data.appEvent,
        node,
        eventConfigId: fromField.value,
        app_account_id: data.selectedAccount.id,
        targetPortInfo,
      })

      let selectedConfigKey = data.appEventSequences.filter(x => x.eventConfigId === eventSequence)[0].config_key

      let selectedEventConfigId = data.configResponses.filter(
        x => x.config_key.slice(0, x.config_key.indexOf(">")) === selectedConfigKey
      )[0].value

      if (
        data.appEventConfigurations.findIndex(x => x.sequence === eventSequence) ===
        data.appEventConfigurations.length - 1
      ) {
        getAppEventConfigDetailFetchKeyMap({
          data,
          nodeId: data.nodeid,
          appId: data.id,
          eventId: data.appEvent,

          selectedEventConfigId: fromField.value,

          app_account_id: data.selectedAccount.id,
        })
      }
    }

    // const _state = changeHistory(inValue, "onLinkComplete", inValue.editorState)
    // return setInValue(inValue => ({
    //   ...inValue,
    //   editorState: { ...inValue.editorState, links: [...inValue.editorState.links, props] },
    // }))
    if (linkExists && type !== "ADD_ON") {
      setInValue(inValue => ({
        ...inValue,
        editorState: { ...inValue.editorState, links: allLinks },
      }))
    } else {
      setInValue(inValue => ({
        ...inValue,
        editorState: { ...inValue.editorState, links: [...inValue.editorState.links, props] },
      }))
    }

    return setA(a => a + 1)
  }

  const onDeleteLink = id => {
    const linkData = edges.find(x => x.id === id)
    const fromNodeId = linkData.source
    const toNodeId = linkData.target

    const newedges = edges.filter(e => e.id !== id)

    const nodeIdx = nodes.findIndex(c => c.data.nodeid === toNodeId)

    if (nodeIdx !== -1 && nodes[nodeIdx].data.fromPorts) {
      nodes[nodeIdx].data.fromPorts = nodes[nodeIdx].data.fromPorts.filter(port => port.linkId !== id)
    }

    const currentLinks = edges.filter(x => x.target === toNodeId)
    const currentNodeIdx = nodes.findIndex(x => x.data.nodeid === toNodeId)
    const currentNode = nodes[currentNodeIdx]

    if (currentNode.data.type === "ADD_ON" && currentNode.data.provider === "conditioner") {
      nodes[currentNodeIdx].data.fromFields = nodes[currentNodeIdx].data.fromFields.filter(x => x.linkId !== id)

      nodes[currentNodeIdx].data.conditions = nodes[currentNodeIdx].data.conditions
        ? nodes[currentNodeIdx].data.conditions.filter(condition => condition.field.key !== id)
        : []

      let conditionsTxt = nodes[currentNodeIdx].data.conditions.map(condition => {
        let queryOperatorText = condition.queryOperator === undefined ? "" : condition.queryOperator.toUpperCase()
        return (
          condition.field.label + " " + condition.operator + " " + condition.value + " " + queryOperatorText + "\r\n"
        )
      })
      nodes[currentNodeIdx].data.conditionsText = conditionsTxt
      if (!currentLinks.length) {
        nodes[currentNodeIdx].data.additionalResponses = []
        const firstPort = { ...nodes[currentNodeIdx].data.configResponses[0] }
        nodes[currentNodeIdx].data.configResponses = [firstPort]
        // links = edges.filter(x => x.source !== toNodeId)
        nodes[currentNodeIdx].data.conditions = []
        nodes[currentNodeIdx].data.conditionsText = ""
        nodes[currentNodeIdx].data.reviewed = false
        nodes[currentNodeIdx].data.tested = false
        nodes[currentNodeIdx].data.showTestAndReview = false
      }
    }
    const targethandle = JSON.parse(linkData.targetHandle.split("|")[1])
    const { node, app, appEvent, config_key, port } = targethandle
    const portid = `${node.toString()}|${app.toString()}|${
      appEvent === null || appEvent === undefined ? "undefined" : appEvent.toString()
    }|${config_key.toString()}`

    const portid2 = `${node.toString()}|${app.toString()}|${
      appEvent === null || appEvent === undefined ? "undefined" : appEvent.toString()
    }|${port.toString()}`

    const configIdx = nodes[currentNodeIdx].data.configResponses.findIndex(
      c => c.port.id === portid || c.port.id === portid2 || c.port.id === portid2
    )
    if (configIdx !== -1) {
      nodes[currentNodeIdx].data.configResponses[configIdx].value = ""
    }
    if (
      config_key.includes(">") &&
      currentNode.data.appEventConfigurations.indexOf ===
        currentNode.data.appEventConfigurations[currentNode.data.appEventConfigurations.length - 1]
    ) {
      currentNode.data.configResponses = []
    }
    setEdges(newedges)
    const filteredLinks = inValue.editorState.links.filter(link => link.id !== id)
    setHistoryCount(c => c + 1)
    // const _state = changeHistory(inValue, "onDeleteLink", (nodes, edges))

    return setInValue(inValue => ({
      ...inValue,
      editorState: { ...inValue.editorState, links: filteredLinks },
    }))
  }

  const setEventsConfig = props => {
    const {
      data,
      nodeId,
      appId,
      eventId,
      eventConfigId,
      source,
      sequence,
      selectedEventConfigId,
      selectedEventConfigName,
    } = props
    //  const node = nodes.find(x => x.data.nodeid === data.nodeid)
    const eventSeq = data.appEventConfigurations.find(x => x.sequence === sequence.sequence)

    eventSeq.selected = { ...sequence }
    let seq = data.appEventSequences.findIndex(
      x => x.sequence === sequence.sequence && x.config_key === sequence.config_key
    )

    if (seq !== -1) {
      data.appEventSequences[seq] = sequence
      data.appEventSequences = data.appEventSequences.slice(0, seq + 1)
      data.configResponses = []
    } else {
      data.appEventSequences.push(sequence)
    }
    inValue.editorMeta.canPublish = false
    setHistoryCount(c => c + 1)
    // const _state = changeHistory(inValue, "setEventsConfig", inValue.editorState)
    return setUpdate(c => c + 1)
  }

  const getAppEventConfigDetailFetch = async props => {
    const {
      appId = "",
      data,
      nodeId = null,
      eventId = "",
      eventConfigId = "",
      source = false,
      app_account_id = null,
      selectedConfigKey = "",
      selectedEventConfigId,
    } = props
    let params = ""
    if (data.appEventSequences) {
      data.appEventSequences.map(x => {
        if (x.config_key !== "sheet_id") params += `&${x.config_key}=${x.id}`
      })
    }
    if (selectedConfigKey) {
      params = `?${selectedConfigKey}=${selectedEventConfigId}${params}`
    }

    let url = `https://stagingapp.konnectzit.com/api/v1/apps/${appId}/app_events/${eventId}/event_config/${
      source ? "left" : "right"
    }/${eventConfigId}/fetch-fields`

    url += `${params}`

    if (app_account_id) url += `&app_account_id=${app_account_id}`
    let UrlInfo5 = {
      url: url,
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj,
      },
      extra: {
        ...props,
      },
    }
    let response = await axios(UrlInfo5)
    let result = await response.data
    const sourceFields = result.map(x => {
      return {
        app_event_id: eventId,
        app_id: appId,
        config_key: x.config_key ? x.config_key : x.name,
        config_key_required: x.config_key_required,
        id: x.id,
        label: x.name,
        placeholder: x.placeholder || x.name,
        key_value_type: x.key_value_type ? x.key_value_type : "display",
        type: "source",
      }
    })
    const node = nodes.find(x => x.data.nodeid === data.nodeid)
    node.data.configResponses = []

    node.data.reviewed = false
    node.data.tested = false
    const _configs = [...sourceFields]
    if (node.data.type === "ADD_ON" && data.provider.toLowerCase() === "dateformatter") {
      _configs = _configs.reverse()
    }

    inValue.editorMeta.canPublish = false

    node.data.configResponses = node.data.configResponses.concat([..._configs])
    node.data.configResponsesOrig = node.data.configResponsesOrig.concat([..._configs])

    return setUpdate(c => c + 1)
  }

  const createAdditionalFields = props => {
    const { nodeId, fields, formElements } = props
    const node = nodes.find(x => x.data.nodeid === nodeId)
    const newFields = fields.map(f => `out.${f.config_key}`)
    const additionalFields = node.data.configResponses.filter(d => d.additional).map(d => d.config_key)
    const diff = additionalFields.filter(c => !newFields.includes(c))

    //remove links
    diff.map(p => {
      const port = node.data.configResponses.find(q => q.config_key === p).port.id
      //have to check for links
      let links = edges.filter(l => l.source !== port)
      setEdges(links)
    })

    node.data.configResponses = node.data.configResponses.filter(g => !diff.includes(g.config_key))
    fields.map(f => {
      if (!node.data.configResponses.find(h => h.config_key === `out.${f.config_key}`))
        node.data.configResponses.push({
          app_event_id: node.data.appEvent,
          app_id: node.data.id,
          config_key: `out.${f.config_key}`,
          config_key_required: false,
          id: `out.${f.id}`,
          key_value_type: "input",
          label: f.label,
          sequence: 0,
          side: "right",
          additional: true,
          value: f.value,
          disabled: true,
          mapped: true,
        })
    })

    node.data.configResponses = node.data.configResponses.map(x => {
      return { ...x, ts: new Date().getTime() }
    })

    let t = edges.map(x => {
      return { ...x, ts: new Date().getTime() }
    })
    // formElements = formElements.concat(node.data.configResponses)
    setEdges(t)
  }
  const getAppEventConfigDetailCustom = async props => {
    const { data, config_key = "", input, port: eventConfigId = "", prevSequence = null } = props
    const { id, appEvent, appEventSequences, selectedAccount } = data
    let source = false
    //     const port =data.configResponses.find(d=>d.config_key===config_key).portId
    let url = `https://stagingapp.konnectzit.com/api/v1/apps/${id}/app_events/${appEvent}/event_config/${
      source ? "left" : "right"
    }/${eventConfigId + 1}/details?`

    let params = ""

    data.appEventSequences.filter(
      d => d.config_key === config_key.slice(0, config_key.indexOf(">"))
    )[0].sequence.id = input
    data.appEventSequences.filter(d => d.config_key === config_key.slice(0, config_key.indexOf(">")))[0].id = input

    if (data.appEventSequences) {
      data.appEventSequences.map(x => {
        let ind = x.config_key.indexOf(">")
        params += `${x.config_key}=${input}`
      })
    }
    let newConfigKey = data.appEventConfigurations[eventSequence].config_key

    if (prevSequence) {
      url = `${url}${prevSequence.config_key}=${prevSequence.id}${params}`
    }

    if (selectedAccount.id) url += `${params}&${params}&app_account_id=${selectedAccount.id}`
    let UrlInfo5 = {
      url: url,
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj,
      },
      extra: {
        ...props,
      },
    }
    try {
      const response = await axios(UrlInfo5)
      const res = await response.data
      data.appEventConfigurationDetails = data.appEventConfigurationDetails.filter(c => c.config_key !== config_key)

      const configDetails = res.map(x => {
        return { ...x, config_key: newConfigKey }
      })

      data.reviewed = false
      data.tested = false
      data.appEventConfigurationDetails = [...data.appEventConfigurationDetails, ...configDetails]
      //  data.appEventConfigurationDetails = data.appEventConfigurationDetails.filter(c => c.config_key !== config_key)

      // const configDetails = res.map(x => {
      //   return { ...x, config_key: config_key }
      // })
      data.reviewed = false
      data.tested = false
      //  data.appEventConfigurationDetails = [...data.appEventConfigurationDetails, ...configDetails]
      // const _state = changeHistory(inValue, getAppEventConfigDetail, inValue.editorState)
    } catch (error) {
      console.log(error)
    }
    return setUpdate(c => c + 1)

    // data.appEventConfigurationDetails = [res]
  }

  const getAppEventConfigDetailFetchKeyMap = async props => {
    const {
      data,
      nodeId,
      appId,
      eventId,
      selectedEventConfigId,

      app_account_id,
    } = props
    //we have to set value of id in data.appeventsequence from cLinkonfigResponse
    const nodeIdx = nodes.findIndex(x => x.id === data.nodeid)
    let source = nodeIdx === 0
    let params = ""
    if (data.appEventSequences) {
      data.appEventSequences.map(x => {
        if (x.config_key !== "sheet_id") params += `&${x.config_key}=${x.id}`
      })
    }
    let selectedConfigKey = data.appEventSequences[eventSequence - 1].config_key
    //let selectedEventConfigId='0$,Sheet1$,958$,26'
    //data.configResponses.filter(x=>x.config_key.slice(0,x.config_key.indexOf(">"))===selectedConfigKey)[0].value
    // if(data.configResponses.filter(d => d.config_key.slice(0,d.config_key.indexOf(">")) === selectedConfigKey)[0].value){
    //   //data.appEventSequences.filter(d => d.config_key === config_key.slice(0, config_key.indexOf(">")))[0].sequence.id=data.configResponses.filter(d => d.config_key === config_key)[0].value
    //   selectedEventConfigId=data.configResponses.filter(d => d.config_key === selectedConfigKey)[0].value

    // }
    if (selectedConfigKey) {
      params = `?${selectedConfigKey}=${selectedEventConfigId}${params}`
    }

    let url = `https://stagingapp.konnectzit.com/api/v1/apps/${appId}/app_events/${eventId}/event_config/${
      source ? "left" : "right"
    }/${selectedEventConfigId}/fetch-fields`

    url += `${params}`

    if (app_account_id) url += `&app_account_id=${app_account_id}`

    let UrlInfo5 = {
      url: url,
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj,
      },
      extra: {
        ...props,
      },
    }
    let response = await axios(UrlInfo5)
    let result = await response.data
    const sourceFields = result.map(x => {
      return {
        app_event_id: eventId,
        app_id: appId,
        config_key: x.config_key ? x.config_key : x.name,
        config_key_required: x.config_key_required,
        id: x.id,
        label: x.name,
        placeholder: x.placeholder || x.name,
        key_value_type: x.key_value_type ? x.key_value_type : "display",
        type: "source",
      }
    })
    const node = nodes.find(x => x.data.nodeid === data.nodeid)

    node.data.reviewed = false
    node.data.tested = false
    const _configs = [...sourceFields]
    if (node.data.type === "ADD_ON" && data.provider.toLowerCase() === "dateformatter") {
      _configs = _configs.reverse()
    }

    inValue.editorMeta.canPublish = false

    node.data.configResponses = node.data.configResponses.concat([..._configs])
    node.data.configResponsesOrig = node.data.configResponsesOrig.concat([..._configs])
    return setUpdate(c => c + 1)
  }

  const getAppEventConfigDetailCustomKeymap = async props => {
    const { data, app, appEvent, node, eventConfigId, app_account_id, targetPortInfo } = props
    const { target, targetHandle } = props
    //const targetPortInfo = JSON.parse(targetHandle.split("|")[1])
    const { config_key, id, key_value_type, label, type } = targetPortInfo
    const Node = nodes.find(n => n.id === node)
    const port = data.configResponses.find(d => d.config_key === config_key).portId
    let prevSequence = false
    let source = false
    let url = `https://stagingapp.konnectzit.com/api/v1/apps/${app}/app_events/${appEvent}/event_config/${
      source ? "left" : "right"
    }/${`${Number(port) + 1}`}/details?`
    // `https:stagingapp.konnectzit.com/api/v1/apps/5/app_events/344/event_config/
    // right/1371/details?spreadsheet_id=140m-vH3EnaMVE04ON3zhDZFBBZ0yIM6mL6PudwMB_VM&
    // spreadsheet_id=140m-vH3EnaMVE04ON3zhDZFBBZ0yIM6mL6PudwMB_VM&app_account_id=1148`
    // }/${`${Number(port) + 1}`}/details?`
    let params = ""

    if (data.configResponses.filter(d => d.config_key === config_key)[0].value) {
      data.appEventSequences.filter(
        d => d.config_key === config_key.slice(0, config_key.indexOf(">"))
      )[0].sequence.id = data.configResponses.filter(d => d.config_key === config_key)[0].value
      data.appEventSequences.filter(
        d => d.config_key === config_key.slice(0, config_key.indexOf(">"))
      )[0].id = data.configResponses.filter(d => d.config_key === config_key)[0].value
    }

    if (data.appEventSequences) {
      data.appEventSequences.map(x => {
        let ind = x.config_key.indexOf(">")
        params += `${x.config_key}=${eventConfigId}`
      })
    }
    if (prevSequence) {
      url = `${url}${prevSequence.config_key}=${prevSequence.id}${params}`
    }

    // url+=params&params

    if (app_account_id) url += `${params}&${params}&app_account_id=${app_account_id}`
    let UrlInfo5 = {
      url: url,
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj,
      },
      extra: {
        ...props,
      },
    }

    const response = await axios(UrlInfo5)
    const res = await response.data
    data.appEventConfigurationDetails = data.appEventConfigurationDetails.filter(c => c.config_key !== config_key)
    let newConfigKey = data.appEventConfigurations[data.appEventConfigurations.length - 1].config_key
    let Abc
    if (config_key) {
      if (config_key.includes(">")) {
        Abc = config_key.slice(0, config_key.indexOf(">"))
      } else {
        Abc = config_key
      }
    }
    const configDetails = res.map(x => {
      return { ...x, config_key: "sheet_id" }
    })
    data.reviewed = false
    data.tested = false
    data.appEventConfigurationDetails = [...data.appEventConfigurationDetails, ...configDetails]
    //  data.appEventConfigurationDetails = data.appEventConfigurationDetails.filter(c => c.config_key !== config_key)

    // const configDetails = res.map(x => {
    //   return { ...x, config_key: config_key }
    // })
    data.reviewed = false
    data.tested = false
    return setUpdate(c => c + 1)
  }

  const getAppEventConfigDetailFetchuserDefined = async props => {
    const {
      data,
      nodeId,
      appId,
      eventId,
      //eventConfigId: selected.id,
      // source: nodeIdx === 0,
      selectedEventConfigId,
      ///  selectedEventConfigName: selected.name,
      selectedConfigKey,
      app_account_id,
      input,
    } = props

    const nodeIdx = nodes.findIndex(x => x.id === data.nodeid)
    let source = nodeIdx === 0
    let params = ""
    if (data.appEventSequences) {
      data.appEventSequences.map(x => {
        if (x.config_key !== "sheet_id") params += `&${x.config_key}=${x.id}`
      })
    }
    if (selectedConfigKey) {
      params = `?${selectedConfigKey}=${selectedEventConfigId}${params}`
    }

    let url = `https://stagingapp.konnectzit.com/api/v1/apps/${appId}/app_events/${eventId}/event_config/${
      source ? "left" : "right"
    }/${selectedEventConfigId}/fetch-fields`

    url += `${params}`

    if (app_account_id) url += `&app_account_id=${app_account_id}`
    let UrlInfo5 = {
      url: url,
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj,
      },
      extra: {
        ...props,
      },
    }
    let response = await axios(UrlInfo5)
    let result = await response.data
    const sourceFields = result.map(x => {
      return {
        app_event_id: eventId,
        app_id: appId,
        config_key: x.config_key ? x.config_key : x.name,
        config_key_required: x.config_key_required,
        id: x.id,
        label: x.name,
        placeholder: x.placeholder || x.name,
        key_value_type: x.key_value_type ? x.key_value_type : "display",
        type: "source",
      }
    })
    const node = nodes.find(x => x.data.nodeid === data.nodeid)

    node.data.reviewed = false
    node.data.tested = false
    const _configs = [...sourceFields]
    if (node.data.type === "ADD_ON" && data.provider.toLowerCase() === "dateformatter") {
      _configs = _configs.reverse()
    }

    inValue.editorMeta.canPublish = false

    node.data.configResponses = node.data.configResponses.concat([..._configs])
    node.data.configResponsesOrig = node.data.configResponsesOrig.concat([..._configs])
    return setUpdate(c => c + 1)
  }
  const onPortTextChange = props => {
    const { data, nodeId, portId, config_key, input } = props
    const node = nodes.find(x => x.data.nodeid === nodeId)
    const config = data.configResponses.findIndex(c => c.config_key === config_key)
    data.configResponses[config].value = input
    setValue(input)
    data.tested = false
    data.reviewed = false
    inValue.editorMeta.canPublish = false
    setHistoryCount(c => c + 1)
    if (data.configResponses[config].config_key.includes(">")) {
      const port = data.configResponses.find(d => d.config_key === config_key).portId

      getAppEventConfigDetailCustom({ data, nodeId, port, config_key, input })

      let configKey = data.appEventSequences.filter(
        x => x.config_key === config_key.slice(0, config_key.indexOf(">"))
      )[0].config_key
      if (
        data.appEventConfigurations.findIndex(x => x.sequence === eventSequence) ===
        data.appEventConfigurations.length - 1
      ) {
        getAppEventConfigDetailFetchuserDefined({
          data,
          nodeId: data.nodeid,
          appId: data.id,
          eventId: data.appEvent,
          //eventConfigId: selected.id,
          // source: nodeIdx === 0,
          selectedEventConfigId: input,
          ///  selectedEventConfigName: selected.name,
          selectedConfigKey: configKey,
          app_account_id: data.selectedAccount.id,
          input,
        })
      }
    }
    // const _state = changeHistory(inValue, onPortTextChange, inValue.editorState)
    setA(a => a + 1)
    return setP(p => p + 1)
  }
  const updateDuration = props => {}
  // const updateSegmentIndex = props => {
  //   textSplitterInitialState.segmentIndex = props
  //   setInValue(inValue => ({
  //     ...inValue,
  //     customAppData: {
  //       ...inValue.customAppData,
  //       textSplitter: { ...inValue.customAppData.textSplitter, Result: props },
  //     },
  //   }))
  // }
  const updateExpiryNum = props => {
    couponInitialState.expiryNum = props
    setInValue(inValue => ({
      ...inValue,
      customAppData: {
        ...inValue.customAppData,
        coupon: { ...inValue.customAppData.coupon, expiryNum: props },
      },
    }))
  }
  const updateUrl = props => {
    const { value, nodeIdx, data } = props
    //const node =nodes.filter(n=>n.data.nodeid===data.id)
    nodes[nodeIdx].data.api.endpointURL = value
    apiAppInitialState.endpointURL = value
  }
  const updateWrapRequestInArray = props => {
    const { value, nodeIdx, data } = props
    data.api.wrapRequestInArray = value
  }
  const updatePayloadType = props => {
    const { value, nodeIdx, data } = props
    data.api.payloadType = value
  }
  const updateHeadersAndParams = props => {
    const { headers, params, nodeIdx, newNodeDetails, nodeId } = props
    const node = nodes.filter(n => n.data.nodeid === nodeId)
    node[0].data.api.headers = headers
    node[0].data.api.params = params
    node[0].data.configResponses = newNodeDetails
  }
  const getConditionsList = async () => {
    //conditionsList=inValue.conditionsList
    let UrlInfo = {
      url: "https://stagingapp.konnectzit.com/api/v1/konnects/conditions_list",
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj,
      },
    }
    try {
      let res = await axios(UrlInfo)
      let result = res.data
      setInValue(inValue => ({ ...inValue, conditionsList: result }))
    } catch (error) {
      console.log(error)
    }
  }

  const expandCollapseNode = props => {
    const { nodeId, sectionId } = props
    const node = nodes.find(x => x.data.nodeid === nodeId)

    const section = node.data.configResponses.find(x => x.id === sectionId)

    const collapseAll = parentId => {
      const section = node.data.configResponses.findIndex(x => x.id === parentId)
      node.data.configResponses[section].isOpen = false
      if (node.data.configResponses[section].type === "node") {
        node.data.configResponses[section].children.map(childKey => {
          let child = node.data.configResponses.findIndex(x => x.id === childKey)
          if (node.data.configResponses[child].type === "leaf") {
            node.data.configResponses[child].isOpen = false
          } else {
            node.data.configResponses[child].isAvailable = false
            node.data.configResponses[child].isOpen = false

            node.data.configResponses[child].children.map(x => {
              collapseAll(x)
            })
          }
        })
      }
    }
    if (section.isOpen) {
      //close all children; including sub children

      collapseAll(section.id)
    } else {
      //open up to its immediate child
      section.isOpen = true
      section.children.map(x => {
        const item = node.data.configResponses.find(y => y.id === x)

        if (item.type === "node") {
          item.isAvailable = true
        } else {
          item.isOpen = true
        }
      })
    }

    node.data.configResponses = node.data.configResponses.map(x => {
      return { ...x, ts: new Date().getTime() }
    })
    edges = edges.map(x => {
      return { ...x, ts: new Date().getTime() }
    })
  }
  const modifyConditions = props => {
    const { data, conditionsText, conditions } = props

    const currentLinks = edges.filter(x => x.target === data.nodeid)
    const currentNodeIdx = nodes.findIndex(x => x.data.nodeid === data.nodeid)

    let parentNode = null
    nodes[currentNodeIdx].data.conditionsText = conditionsText
    nodes[currentNodeIdx].data.conditions = conditions
    nodes[currentNodeIdx].data.showTestAndReview = true
    setHistoryCount(c => c + 1)
    // const _state = changeHistory(inValue, "modifyConditions", inValue.editorState)
  }
  const onCanvasDrop = props => {
    return (
      setNodes(nds => nds.concat(props)),
      setInValue(inValue => ({
        ...inValue,
        editorState: { ...inValue.editorState, nodes: [...inValue.editorState.nodes, props] },
      }))
    )
  }
  const getOauthData = async payload => {
    let UrlInfo3 = {
      url: `https://stagingapp.konnectzit.com/api/v1/auth-url`,
      method: "POST",
      data: payload,
      headers: {
        Authorization: "Bearer " + tokenObj,
      },
    }
    const response = await axios(UrlInfo3)
    const result = await response.data
    setTokenFieldsData(result.fields)
  }

  const saveOauthData = async payload => {
    let UrlInfo3 = {
      url: `https://stagingapp.konnectzit.com/api/v1/authorization`,
      method: "POST",
      data: payload,
      headers: {
        Authorization: "Bearer " + tokenObj,
      },
    }
    const response = await axios(UrlInfo3)
    const result = await response.data
    setTokenFieldsData(result.fields)
  }

  const updateAppStatus = async payload => {
    //const request = makeRequestActionCreator(UPDATE_APP_STATUS)
    let UrlInfo3 = {
      url: `https://stagingapp.konnectzit.com/api/v1/app_users/${payload.appId}/update_status`,
      method: "POST",
      data: { status: payload.status },
      headers: {
        Authorization: "Bearer " + tokenObj,
      },
    }
    const response = await axios(UrlInfo3)
    const result = await response.data
  }

  const updateKonnectStatus = async payload => {
    var tokenObj1 = AuthTokenService.get()
    //const request = makeRequestActionCreator(UPDATE_APP_STATUS)
    let UrlInfo3 = {
      url: `https://stagingapp.konnectzit.com/api/v1/konnects/${payload.id}/update_status`,
      method: "POST",
      data: { status: payload.status },
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
    }
    const response = await axios(UrlInfo3)
    const result = await response.data
  }
  const onLinkStart = props => {
    const { nodeId, handleId, handleType } = props
  }

  const changeHistory = state => {
    const history = state.history

    const editorState = state.editorState
    const editorMeta = state.editorMeta

    editorMeta.canPublish = nodes.every(x => x.data.tested && x.data.reviewed)

    let curr = {
      nodes,
      edges,
    }

    const lastHistory = inValue.history.present
    if (lastHistory && !isEqual(lastHistory, curr)) {
      history.past.push(history.present)
      history.present = curr
    }

    history.future = []

    return setInValue(inValue => ({
      ...inValue,
      history: history,
    }))
  }
  const UNDO = () => {
    const history = inValue.history
    const currentState = {
      nodes: nodes,
      links: edges,
    }

    if (!history.past.length && !history.future.length && history.present) {
      return nodes, edges
    }

    if (!history.past.length) {
      return nodes, edges
    }

    // history.future.push(history.present)
    let yy = history.past.pop()
    const { nodes, edges } = yy
    let result
    let newNodes = nodes.map(n => {
      nodeidd.map(node => {
        if (n.id === node) {
          result = {
            ...n,
            data: {
              id: n.data.id,
              name: n.data.name,
              image: n.data.image,
              background: n.data.background,
              associatedAccounts: n.data.associatedAccounts,
              webhook_enabled: n.webhook_enabled === true ? true : false,
              webhook_instructions: "",
              webhook_url: n.data.webhook_url ? n.data.webhook_url : "",
              provider: n.data.provider,
              type: n.data.type,
              nodeid: n.data.nodeid,
              appEvent: null,
              appEventConfigurations: [],
              appEventSequences: [],
              appEvents: n.data.appEvents,
              webhookUrl: n.data.webhookUrl ? n.data.webhookUrl : "",
              webhook: n.webhook ? n.webhook : "",
              configResponses: [],
              tested: false,
              reviewed: false,
              isloading: false,
              showTestAndReview: true,
              linkedAccounts: n.data.linkedAccounts,

              configResponse: [],
            },
          }
        } else {
          result = n
        }
      })
      return result
    })
    let DeletedNodes = newNodes.filter(n => n === undefined)
    //let newedges = edges.filter(e => e.target !== nodeidd)

    history.present = { nodes, edges }
    setInValue(inValue => ({
      ...inValue,
      history: { ...inValue.history, future: [...inValue.history.future, history.present] },
    }))
    // history.future.push(history.present)

    return (
      setEdges(edges),
      setNodes(DeletedNodes && DeletedNodes.length ? nodes : newNodes),
      setInValue(inValue => ({
        ...inValue,
        editorState: { ...inValue.editorState, nodes: DeletedNodes && DeletedNodes.length ? nodes : newNodes },
      }))
    )
  }

  const REDO = () => {
    const history = inValue.history

    if (!history.past.length && !history.future.length && history.present) {
      return nodes, edges
    }

    if (!history.future.length) {
      return nodes, edges
    }
    history.present = history.future.pop()

   
    setNodes(history.present.nodes),
      setEdges(history.present.edges),
      setInValue(inValue => ({
        ...inValue,
        history: { ...inValue.history, past: [...inValue.history.past, history.present] },
      }))
    return setA(a => a + 1)
  }
  // const UNDO = () => {
  //   const history = inValue.history
  //   const currentState = {
  //     nodes: nodes,
  //     links: edges,
  //   }

  //   if (!history.past.length && !history.future.length && history.present) {
  //     return nodes, edges
  //   }

  //   if (!history.past.length) {
  //     return nodes, edges
  //   }

  //   // history.future.push(history.present)
  //   let yy = history.past.pop()
  //   const { nodes, edges } = yy
  //   let result
  //   let newNodes = nodes.map(n => {
  //     nodeidd.map(node => {
  //       if (n.id === node) {
  //         result = {
  //           ...n,
  //           data: {
  //             id: n.data.id,
  //             name: n.data.name,
  //             image: n.data.image,
  //             background: n.data.background,
  //             associatedAccounts: n.data.associatedAccounts,
  //             webhook_enabled: n.webhook_enabled === true ? true : false,
  //             webhook_instructions: "",
  //             webhook_url: n.data.webhook_url ? n.data.webhook_url : "",
  //             provider: n.data.provider,
  //             type: n.data.type,
  //             nodeid: n.data.nodeid,
  //             appEvent: null,
  //             appEventConfigurations: [],
  //             appEventSequences: [],
  //             appEvents: n.data.appEvents,
  //             webhookUrl: n.data.webhookUrl ? n.data.webhookUrl : "",
  //             webhook: n.webhook ? n.webhook : "",
  //             configResponses: [],
  //             tested: false,
  //             reviewed: false,
  //             isloading: false,
  //             showTestAndReview: true,
  //             linkedAccounts: n.data.linkedAccounts,

  //             configResponse: [],
  //           },
  //         }
  //       } else {
  //         result = n
  //       }
  //     })
  //     return result
  //   })
  //   let DeletedNodes = newNodes.filter(n => n === undefined)
  //   //let newedges = edges.filter(e => e.target !== nodeidd)

  //   history.present = { nodes, edges }
  //   setInValue(inValue => ({
  //     ...inValue,
  //     history: { ...inValue.history, future: [...inValue.history.future, history.present] },
  //   }))
  //   // history.future.push(history.present)
  //   return (
  //     setEdges(edges),
  //     setNodes(DeletedNodes && DeletedNodes.length ? nodes : newNodes),
  //     setInValue(inValue => ({
  //       ...inValue,
  //       editorState: { ...inValue.editorState, nodes: DeletedNodes && DeletedNodes.length ? nodes : newNodes },
  //     }))
  //   )
  // }

  // const REDO = () => {
  //   const history = inValue.history

  //   if (!history.past.length && !history.future.length && history.present) {
  //     return nodes, edges
  //   }

  //   if (!history.future.length) {
  //     return nodes, edges
  //   }
  //   history.present = history.future.pop()

  //   setInValue(inValue => ({
  //     ...inValue,
  //     history: { ...inValue.history, past: [...inValue.history.past, history.present] },
  //   }))

  //   //  history.past.push(history.present)
  //   // const { nodes, edges } = yy
  //   // let newNodes = nodes.map(n => {
  //   //   console.log(n.data, "AJAY")
  //   //   console.log(nodeidd, "Bidhan")
  //   //   if (n.id === nodeidd) {
  //   //     console.log(n, "AJAYBIDHAN")
  //   //     return {
  //   //       ...n,
  //   //       data: {
  //   //         id: n.data.id,
  //   //         name: n.data.name,
  //   //         image: n.data.image,
  //   //         background: n.data.background,
  //   //         associatedAccounts: n.data.associatedAccounts,
  //   //         webhook_enabled: n.webhook_enabled === true ? true : false,
  //   //         webhook_instructions: "",
  //   //         webhook_url: n.data.webhook_url ? n.data.webhook_url : "",
  //   //         provider: n.data.provider,
  //   //         type: n.data.type,
  //   //         nodeid: n.data.nodeid,
  //   //         appEvent: null,
  //   //         appEventConfigurations: [],
  //   //         appEventSequences: [],
  //   //         appEvents: n.data.appEvents,
  //   //         webhookUrl: n.data.webhookUrl ? n.data.webhookUrl : "",

  //   //         configResponses: [],
  //   //         tested: false,
  //   //         reviewed: false,
  //   //         isloading: false,
  //   //         showTestAndReview: true,
  //   //         linkedAccounts: n.data.linkedAccounts,

  //   //         configResponse: [],
  //   //       },
  //   //     }
  //   //   } else {
  //   //     return n
  //   //   }
  //   // })
  //   // let newedges = edges.filter(e => e.target !== nodeidd)
  //   // history.present = { newNodes, edges }

  //   return setNodes(history.present.nodes), setEdges(history.present.edges)
  // }

  const getKonnectsList = async searchQuery => {
    let tokenObj1 = AuthTokenService.get()
    let UrlInfo3 = {
      url: `https://stagingapp.konnectzit.com/api/v1/konnects`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
    }
    if (searchQuery) {
      const qs = Object.keys(searchQuery)
        .map(key => `${key}=${searchQuery[key]}`)
        .join("&")

      UrlInfo3.url = UrlInfo3.url + `?${qs}`

      // if (authorizedOnly) {
      //   UrlInfo.url = UrlInfo.url + "&authorized_only=" + authorizedOnly
      // }
    }
    const res = await axios(UrlInfo3)
    const result = res
    const { data } = result
    return setInValue(inValue => ({
      ...inValue,
      konnects: data,
    }))
  }
  const getKonnectsByFolderId = async id => {
    let tokenObj1 = AuthTokenService.get()
    let UrlInfo3 = {
      url: `https://stagingapp.konnectzit.com/api/v1/folders/${id}/konnects`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
    }
    const response = await axios(UrlInfo3)
    const result = await response.data
    const { konnects } = result
    setFolderKonnects(konnects)
  }

  const createFolder = async folderName => {
    let tokenObj1 = AuthTokenService.get()
    let url = "api/v1/folders/add"
    let UrlInfo3 = {
      url: `https://stagingapp.konnectzit.com/api/v1/folders/add`,
      method: "POST",
      data: {
        folder_name: folderName,
      },
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
    }
    const response = await axios(UrlInfo3)
    const result = await response.data
  }

  const moveKonnectsToFolder = async (folderId, konnects) => {

    let filteredData = konnects.map(kon => {
      return kon.id
    })
    let da = filteredData.toString()
    let tokenObj1 = AuthTokenService.get()
    let url = "api/v1/folders/" + folderId + "/assign"
    let UrlInfo3 = {
      url: `https://stagingapp.konnectzit.com/api/v1/folders/${folderId}/assign`,
      method: "POST",
      data: {
        konnects: da,
      },
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
    }
    const response = await axios(UrlInfo3)
    const result = await response.data
  }

  const renameKonnectFolder = async (folderId, folderName) => {
    let UrlInfo3 = {
      url: `https://stagingapp.konnectzit.com/api/v1/folders/${folderId}/rename`,
      method: "PUT",
      data: {
        folder_name: folderName,
      },
      headers: {
        Authorization: "Bearer " + tokenObj,
      },
    }
    const response = await axios(UrlInfo3)
    const result = await response.data
  }

  const deleteKonnectFolder = async folderId => {
    let url = "api/v1/folders/" + folderId + "/remove"
    let UrlInfo3 = {
      url: `https://stagingapp.konnectzit.com/api/v1/folders/${folderId}/remove`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj,
      },
    }
    const response = await axios(UrlInfo3)
    const result = await response.data
    return result
  }

  const addCustom = props => {
    const { idToFunction: data, seq } = props
    let params
    let num
    if (seq === null) {
      num = 0
    } else {
      num = seq.sequence
    }

    if (data.appEventSequences) {
      data.appEventSequences.map(x => {
        params = x.config_key
      })
    }
    let hasSequence =
      data.appEventConfigurations && data.appEventConfigurations.filter(x => x.sequence !== 0).length ? true : false

    let configForms = []

    if (hasSequence) {
      configForms = data.appEventConfigurations[seq]
    }
    data.configResponses.push({
      app_event_id: Number(`${configForms.app_event_id}`),
      app_id: Number(`${configForms.app_id}`),
      config_key: `${configForms.config_key}` + ">>" + "custom",
      config_key_type: "custom",
      sequence: Number(`${configForms.sequence}`),

      config_key_required: false,
      fetch_fields: false,
      id: `${configForms.config_key}`,
      portId: Number(`${configForms.id}`),
      key_value_type: "list",
      label: `${configForms.label}` + ">>" + "custom",
    })
    // data.appEventConfigurationDetails.push({
    //   app_event_id: `${configForms[configForms.length - 1].app_event_id}`,
    //   app_id: `${configForms[configForms.length - 1].app_id}`,
    //   config_key: `${configForms[configForms.length - 1].config_key}` + ">>" + "custom",
    //   config_key_type: "custom",
    //   sequence: `${configForms[configForms.length - 1].sequence}`,

    //   config_key_required: false,
    //   fetch_fields: false,
    //   id: `${configForms[configForms.length - 1].id}`,
    //   key_value_type: "list",
    //   label: `${configForms[configForms.length - 1].label}` + ">>" + "custom",
    // })
    // data.appEventConfigurations.push({
    //   app_event_id: Number(`${configForms[configForms.length - 1].app_event_id}`),
    //   app_id: Number(`${configForms[configForms.length - 1].app_id}`),
    //   config_key: `${configForms[configForms.length - 1].config_key}` + ">>" + "custom",
    //   sequence: Number(`${configForms[configForms.length - 1].sequence}`),

    //   config_key_required: true,
    //   fetch_fields: false,
    //   id: Number(`${configForms[configForms.length - 1].id}`),
    //   key_value_type: "list",
    //   label: `${configForms[configForms.length - 1].label}` + ">>" + "custom",
    //   side: `${configForms[configForms.length - 1].side}`,
    //   type: "custom",
    // })
    data.appEventSequences.push({
      config_key: `${configForms.config_key}`,
      //appId: data.id,
      //data,
      eventConfigId: Number(`${configForms.sequence}`),
      nodeId: data.nodeid,
      //selectedEventConfigId: "",
      //selectedEventConfigId: "",
      eventId: data.appEvent,
      sequence: {
        sequence: `${configForms.sequence}`,
        id: "",
        name: "",
        config_key: `${configForms.config_key}`,
      },
      id: "",
      source: false,
    })

    // setCustom({
    //   app_event_id: 3,
    //   app_id: data.id,
    //   config_key: "custom",

    //   config_key_required: true,
    //   fetch_firlds: false,
    //   id: "custom",
    //   key_value_type: "input",
    //   label: "custom",
    // })

    return setA(a => a + 1)
  }
  const getKonnect = async id => {
    var tokenObj1 = AuthTokenService.get()

    let UrlInfo3 = {
      url: `https://stagingapp.konnectzit.com/api/v1/konnects/${id}/edit`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
    }
    let value
    const response = await axios(UrlInfo3)
    const result = await response.data
    const { canvas_json } = result
    value = canvas_json

    setA(a => a + 1)
    return value
  }
  const handleRefresh = data => {
    let node = nodes.find(x => x.id === data.nodeid)

    //   let x = nodes
    // let {data} = x[nodeIdx]
    let data1 = {
      id: data.id,
      name: data.name,
      image: data.image,
      background: data.background,
      associatedAccounts: data.associatedAccounts ? data.associatedAccounts : "",
      webhook_enabled: false,
      webhook_instructions: data.webhook_instructions ? data.webhook_instructions : "",
      webhook_url: data.webhook_url ? data.webhook_url : "",
      provider: data.provider,
      type: data.type,
      auth_type: data.auth_type ? data.auth_type : "",
      nodeid: data.nodeid,
      appEvent: null,
      appEventConfigurations: [],
      appEventSequences: [],
      appEvents: data.appEvents ? data.appEvents : "",
      configResponses: [],
      tested: false,
      reviewed: false,
      isloading: false,
      showTestAndReview: true,
      linkedAccounts: data.linkedAccounts ? data.linkedAccounts : "",
      configResponse: [],
    }
    node.data = data1
    let newEdges = edges.filter(e => e.target !== data.nodeid && e.source !== data.nodeid)
    setEdges(newEdges)
    setCount(count + 1)
    setInValue(inValue => ({
      ...inValue,
      editorState: { ...inValue.editorState, nodes: nodes, links: newEdges },
    }))

    getLinkedAccounts()
  }
  const getEventGpt = async props => {
    var tokenObj1 = AuthTokenService.get()

    const { id, action, side } = props
    var UrlInfo1 = {
      url: `https://stagingapp.konnectzit.com/api/v1/apps/${id}/app_events`, //api/v1/apps/authorized_apps1
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
    }

    try {
      let result = await axios(UrlInfo1)
      let response = await result.data

      return response
    } catch (error) {
      console.log(error)
    }
    // const _state = changeHistory(inValue, "getAppEvents", inValue.editorState)
  }
  const getLinkedAccountsforGpt = async props => {
    var tokenObj1 = AuthTokenService.get()

    let UrlInfo = {
      url: `https://stagingapp.konnectzit.com/api/v1/apps/${props.id}/authorized_app_accounts`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
    }
    try {
      let result = await axios(UrlInfo)
      let res = await result.data
      const { authorized_app_accounts: response } = res
      return response
    } catch (error) {
      console.log(error)
    }
  }
  const showOnCanvas = async props => {
    let x = props.proptApp[0].name
    let appsss = await getAppsforGpt({ search_query: x })
    let aaa = appsss.filter(y => y.name.toLowerCase() === props.proptApp[0].name.toLowerCase())
    let rightapp = props.x.name

    let secondApp = await getAppsforGpt({ search_query: rightapp })
    let secApp = secondApp.filter(y => y.name.toLowerCase() === props.x.name.toLowerCase())
    let getEventLeftData = await getEventGpt(props.proptApp[0])
    let getEventLeft = getEventLeftData.filter(x => x.side === "left")
    let accountsLeft = await getLinkedAccountsforGpt(props.proptApp[0])
    let getEventRightData = await getEventGpt(props.x)
    let getEventRight = getEventRightData.filter(x => x.side === "right")
    let accountsRight = await getLinkedAccountsforGpt(props.x)

    let newNode = {
      id: getId(),
    }
    let newNode1 = {
      id: getId(),
    }
    let data = {
      id: aaa[0].id,
      name: aaa[0].name,
      auth_type: aaa[0].auth_type,
      background: aaa[0].background_color,
      image: aaa[0].image_url,
      provider: aaa[0].provider,
      associatedAccounts: aaa[0].account_count,
      webhook_enabled: aaa[0].webhook_enabled,
      webhook_instructions: aaa[0].webhook_instructions,
      type: aaa[0].app_type,
      webhook_url: aaa[0].webhook_url,
    }

    let data1 = {
      id: secApp[0].id,
      name: secApp[0].name,
      auth_type: secApp[0].auth_type,
      background: secApp[0].background_color,
      image: secApp[0].image_url,
      provider: secApp[0].provider,
      associatedAccounts: secApp[0].account_count,
      webhook_enabled: secApp[0].webhook_enabled,
      webhook_instructions: secApp[0].webhook_instructions,
      type: secApp[0].app_type,
      webhook_url: secApp[0].webhook_url,
    }
    let newNodes = [
      {
        id: newNode.id,
        position: { x: 500, y: 300 },
        data: {
          id: aaa[0].id,
          name: aaa[0].name,
          auth_type: aaa[0].auth_type,
          background: aaa[0].background_color,
          image: aaa[0].image_url,
          provider: aaa[0].provider,
          associatedAccounts: aaa[0].account_count,
          webhook_enabled: aaa[0].webhook_enabled,
          webhook_instructions: aaa[0].webhook_instructions,
          type: aaa[0].app_type,
          webhook_url: aaa[0].webhook_url,
          nodeid: newNode.id,
          linkedAccounts: accountsLeft ? accountsLeft : null,
          appEvent: getEventLeft.find(x => x.name.toLowerCase() === props.x.trigger.toLowerCase()).id,
          appEventConfigurations: [],
          appEventSequences: [],
          appEvents: getEventLeft ? getEventLeft : [],
          configResponses: [],
          tested: false,
          reviewed: false,
          isloading: false,
          showTestAndReview: aaa[0].app_type === "ADD_ON" && aaa[0].provider === "conditioner" ? false : true,
        },
        type: GetAppType({ data, update }),
      },
      {
        id: newNode1.id,
        position: { x: 900, y: 300 },
        data: {
          id: secApp[0].id,
          name: secApp[0].name,
          auth_type: secApp[0].auth_type,
          background: secApp[0].background_color,
          image: secApp[0].image_url,
          provider: secApp[0].provider,
          associatedAccounts: secApp[0].account_count,
          webhook_enabled: secApp[0].webhook_enabled,
          webhook_instructions: secApp[0].webhook_instructions,
          type: secApp[0].app_type,
          webhook_url: secApp[0].webhook_url,
          nodeid: newNode1.id,
          // auth_type:null,
          linkedAccounts: accountsRight ? accountsRight : null,
          appEvent: getEventRight.find(x => x.name.toLowerCase() === props.x.action.toLowerCase()).id,
          appEventConfigurations: [],
          appEventSequences: [],
          appEvents: getEventRight,
          configResponses: [],
          tested: false,
          reviewed: false,
          isloading: false,
          showTestAndReview: secApp[0].app_type === "ADD_ON" && secApp[0].provider === "conditioner" ? false : true,
        },
        type: GetAppType({ data: data1, update }),
      },
    ]

    return (
      setNodes(newNodes),
      setInValue(inValue => ({
        ...inValue,
        editorState: { ...inValue.editorState, nodes: [...inValue.editorState.nodes, newNodes] },
      }))
    )
  }

  return (
    <>
      <CanvasContext.Provider
        value={{
          inValue,
          setInValue,
          CaptureWebHookResponse,
          nodes,
          setNodes,
          onNodesChange,
          accounts,
          setAccountId,
          setAppEventId,
          onDeleteNodeV2,
          onDeleteNode,
          onLinkComplete,
          toggleBasicAuth,
          onWebhookAuthChange,
          //  getAppEventConfig,
          setConfigMenuState,
          getAuthApps,
          getLinkedAccounts,
          onDeleteNode,
          appTestAndReview,
          getAppEventConfigDetail,
          setLinkedAccount,
          setEventsConfig,
          saveKonnect,
          isCollapsed,
          setIsCollapsed,
          setConfigMenuState,
          createAdditionalFields,
          getAppEventConfigDetailFetch,
          getAppEventConfig,
          onPortTextChange,
          edges,
          setEdges,
          onDeleteLink,
          onEdgesChange,
          couponInitialState,
          updateExpiryNum,
          updateDuration,
          apiAppInitialState,
          updateUrl,
          updateWrapRequestInArray,
          updateHeadersAndParams,
          getConditionsList,
          expandCollapseNode,
          modifyConditions,
          getAppEvents,
          getLinkedAccounts,
          onCanvasDrop,
          update,
          updatePayloadType,
          onLinkStart,
          setUpdate,
          REDO,
          UNDO,
          selectedApp,
          setSelectedApp,
          tokenModal,
          setTokenModal,
          setApiModal,
          apiModal,
          getOauthData,
          updateAppStatus,
          getKonnectsList,
          getKonnect,
          setKonnectId,
          konnectid,
          setKonnectTitle,
          konnectTitle,
          changeHistory,
          historyCount,
          addCustom,
          getAddOnApps,
          addOn,
          saveOauthData,
          tokenFieldsData,
          foldersList,
          addCustom,
          getAppEventConfigDetailCustomKeymap,
          setEventSequence,
          eventSequence,
          getAppEventConfigDetailFetchKeyMap,
          edit,
          setEdit,
          setEditId,
          editId,
          reactFlowInstance,
          setReactFlowInstance,
          clearState,
          setAa,
          aa,
          setSearchValue,
          searchValue,
          pageSize,
          setPageSize,
          createFolder,
          getFoldersList,
          moveKonnectsToFolder,
          renameKonnectFolder,
          getKonnectsByFolderId,
          setFolderKonnects,
          folderKonnects,
          deleteKonnectFolder,
          editt,
          setEditt,
          updateKonnectStatus,
          handleRefresh,
          count,
          showOnCanvas,
          getAppsforGpt,
          setPropValue,
          propValue,
        }}
      >
        {children}
      </CanvasContext.Provider>
    </>
  )
}

const useData = () => {
  return useContext(CanvasContext)
}

export { CanvasContext, CanvasContextProvider, useData }
