import UnlimitedSteps from "assets/images/features/unlimited-steps.png"
import VisualizeEverything from "assets/images/features/visualize-everything.png"
import IntegrationLibrary from "assets/images/features/integration-library.png"
import SimpleAutomation from "assets/images/features/simple-automation.png"
import PlatformForEveryone from "assets/images/features/platform-for-everyone.png"
import SeamlessAppsIntegration from "assets/images/features/seamless-apps-integration.png"

export const en = {
  translation: {
    common: {
      app: "Konnectz",
    },
    header: {
      topNavigations: {
        tipsAndTricks: "Tips and Tricks",
        pricing: "Pricing",
        blogs: "Blogs",
        helpCenter: "Help Center",
      },
      searchAppTextPlaceholder: "Search Your App",
      createKonnectzBtnLabel: "Create Konnectz",
      taskhistory: "Task History",
      success: "Success",
      error: "Error",
      notask: "No Task Found",
      tryagain: "Adjust your filter and try again",
    },
    errorMessages: {
      SENT_RESET_INSTRUCTIONS_SUCCESS: "Password reset instructions have been emailed, please check you email.",
      SENT_RESET_INSTRUCTIONS_FAIL: "Unauthorized request !",
      VERIFY_EMAIL_FAIL: "Sorry, Unable to confirm your email.",
      PASSWORD_NOT_UPDATED: "Sorry, failed to update your account",
      GET_FORGOT_PASSWORD_LINK_SUCCESS: "",
      GET_FORGOT_PASSWORD_LINK_FAIL: "Failed to send the password instructions. Please try after some time !",
      AUTH_SIGN_UP_FAIL: "Something went wrong. Please try after some time !",
      somethingWentWrong: "Something went wrong. Please try after some time !",
    },
    successMessages: {
      PASSWORD_UPDATED: "Password has been updated. Please login with your new password !",
    },
    history: {
      task: {
        task: "task",
        tasks: "tasks",
        taskdate: "taskdate : ",
        user: "User : ",
      },
    },
    auth: {
      signup: {
        createPassword: {
          title: "Create Password",
          subtitle: "Type them TWICE to confirm",
          passwordErrorMsg: "Invalid password !",
          confirmPasswordErrorMsg: "Invalid Password !",
          passwordMatchingErrorMsg: "Both passwords are not matching !",
          continueBtnLabel: "CONTINUE",
          createPasswordPlaceholder: "Create password",
          confirmPasswordPlaceholder: "Confirm password",
          passwordRule:
            "Hint: Your password must be between 8 and 15 characters, and must contain at least one uppercase, one lowercase, one digit and one special character. ",
          passwordRule1: "Your password must be between 8 and 15 characters.",
          passwordRule2: "Your password must contain at least one uppercase.",
          passwordRule3: "Your password must contain at least one number digit.",
          passwordRule4: "Your password must contain at least one special character.",
        },
        verificationSent: {
          title: "VERIFICATION",
          subtitle: "Verify Email Address",
          verificationText1: "We sent verification link on ",
          verificationText2: ", open email and verify your email address.",
          didntReceiveEmailText: "Didn't recive any mail? ",
          resendText: "Resend",
        },
        verifySuccess: {
          title: "Verification Completed",
          verifyFailedTitle: "Verification Failed",
        },
        signupSuccess: {
          firstName: "First Name",
          firstNameErrorMsg: "First Name is required !",
          lastName: "Last Name",
          lastNameErrorMsg: "Last Name is required !",
          verifyErrorMsg: "Something went wrong. Please try logging in if your account has already been verified.",
        },
        title: "Create Your Account",
        subtitle: "Hey, Welcome",
        verifyingMessage: "Verifying You",
        emailInvalid: "Email is invalid !",
        emailExists: "The email address you have entered is already registered",
        teamSignup: "Signing up as a team ?",
        continueBtnLabel: "Continue",
        alreadyHaveAccountText: "Have an account ? ",
        loginBtnLabel: "Sign in",
        emailPlaceholder: "Enter email",
        features: {
          feature1: {
            title: "Simple Automation",
            image: SimpleAutomation,
            description: "Build Complex Workflow simply and easily in minutes using our visual connect builder",
          },
          feature2: {
            title: "Platform for Everyone",
            image: PlatformForEveryone,
            description:
              "Not a regular automation tool only for programmers, anyone can build automation with KonnectzIT",
          },
          feature3: {
            title: "Seamless Apps Integration",
            image: SeamlessAppsIntegration,
            description: "Connect unlimited applications from our integration library and automated daily tasks",
          },
        },
      },
      login: {
        title: "Login In Your Account",
        subtitle: "Hey, Welcome back!",
        emailPlaceholder: "Enter email",
        emailInvalid: "Invalid email !",
        passwordRequired: "Password is required !",
        passwordPlaceholder: "Enter password",
        rememberMe: "Remember Me",
        loginBtnLabel: "LOGIN NOW",
        forFirstTimeSignup: "For first time ? ",
        forgotPasswordLbl: "Forgot Password",
        alreadyHaveAccountText: "Don't have an account? ",
        clickingContinueText: "By clicking continue, you agree our ",
        termsAndConditionsText: "Terms & Conditions.",
        signupNowText: "Sign up",
        features: {
          feature1: {
            title: "Unlimited Steps",
            image: UnlimitedSteps,
            description: "Connect multiple applications and build unlimited steps for seamless automation.",
          },
          feature2: {
            title: "Visualize Everything",
            image: VisualizeEverything,
            description: "Build your workflows and envision the automation using a visual connect builder easily.",
          },
          feature3: {
            title: "Integration Library",
            image: IntegrationLibrary,
            description: "Collection of cloud applications to merge and send data between them to save your time.",
          },
        },
        loginFailMsg: "Username or password is invalid!",
        captchaErrorMsg: "Captcha Error",
      },
      forgot: {
        title: "Forgot Password",
        forgtoBtnLabel: "Get Forgot password link",
        successSubTitle: "Email Sent",
        features: {
          feature1: {
            title: "Simple Automation",
            image: SimpleAutomation,
            description: "Build Complex Workflow simply and easily in minutes using our visual connect builder",
          },
          feature2: {
            title: "Platform for Everyone",
            image: PlatformForEveryone,
            description:
              "Not a regular automation tool only for programmers, anyone can build automation with KonnectzIT",
          },
          feature3: {
            title: "Seamless Apps Integration",
            image: SeamlessAppsIntegration,
            description: "Connect unlimited applications from our integration library and automated daily tasks",
          },
        },
        emailInvalid: "Invalid email!",
      },
      reset: {
        title: "Reset Password",
        subTitle: "Create new password",
      },
    },
    konnect: {
      sidebar: {
        search_apps: "Search...",
        choose_trigger: "Choose Trigger",
        choose_trigger_header: "Choose App event",
        no_triggers_available: "No Triggers available",
        choose_format_header: "Format Option",
        choose_config_header: "Choose Configuration",
        select_trigger_action_dropdown_header: "Select",
        choose_option_header: "Define Option",
        no_options_available: "No Options available",
        choose_linked_account: "Select Account",
        customize_form_header: "Configure Form",
        customize_form_partial: "Choose ",
        configure_response: "Customize Response",
        basic_auth: "Basic Authentication",
        api_key: "API Key",
        api_token: "API Secret",
        webhook_url: "Webhook URL",
        webhook_url_toast: "Webhook URL copied to clipboard!",
        webhook_response: "Webhook Response",
        choose_action_event: "Choose Action",
        endpoint_url: "Endpoint URL",
        payload_type: "Payload Type",
        wrap_request_array: "Wrap Request in Array",
        set_params: "Set Params",
        add_header: "Add Header",
        set_paramter: "Set Parameter",
        expiry_time: "Timeline Count",
        expiry_val: "Timeline",
        segmentIndex: "Segment Index",
        validate: "Validate",
        generate: "Generate",
      },
      UI: {
        betaText: "Beta",
        webhookDuplicateToastMsg: "Webhook app already exists!",
      },
      header: {
        backBtnLabel: "Back",
        saveBtnLabel: "Save",
        publishBtnLabel: "Publish",
        nameOfKonnectz: "Name of the Konnectz",
      },
      testAndReview: {
        btnLabel: "Test & Review",
      },
      proceed: {
        btnLabel: "PROCEED",
      },
      additionalConfigsModal: {
        cancelBtnLabel: "Cancel",
        okBtnLabel: "OK",
      },
      conditions: {
        addNewConditionBtnLabel: "Add New Condition +",
        cancelBtnLabel: "Cancel",
        okBtnLabel: "OK",
        operatorAndLabel: "AND",
        operatorOrLabel: "OR",
        headerConditionText: "Condition",
        selectFieldHeaderTitle: "Select Field",
        selectConditionHeaderTitle: "Select Condition",
        validateBtnLabel: "Validate",
        conditionsHeaderText: "Conditions",
      },
      publishSuccessMsg: "Konnect is successfully published !",
      saveSuccessMsg: "Konnect is successfully saved !",
    },
    apps: {
      authenticatedAppsTitle: "Authenticated apps",
      aunthenticateNowTitle: "Authenticate Now",
      noAppsToAunthenticate: "No apps to authenticate at the moment.",
      authenticateNow: "You don't have any authenticated apps. Authenticate now.",
      addAccountText: "Add Account",
      betaText: "Beta",
      oAuthHandlerText: "KonnectzIT OAuth Handler, Please Wait..",
      cancelBtnText: "Cancel",
      yesContinueText: "Yes, continue",
      requiredText: "(Required)",
      accessAPIModalHeaderText1: "Allow KonnectzIT to access ",
      accessAPIModalHeaderText2: " account?",
      accessAPIModalMainText1: " ",
      accessAPIModalMainText2: " is available in",
      accessAPIModalMainText3: " integration page",
      authorized: "Authorized Apps",
    },
    konnectzList: {
      homeText: "Home",
      noKonnectzInfo: "No konnectz at the moment!",
      deleteModalBodyText: "Are you sure you want to delete ?",
      deleteModalNoText: "No",
      deleteModalDeleteText: "Delete",
      folders: {
        createFolderTitleText: "Folder Name",
        createFolderInputPlaceholder: "Enter the Folder name",
        createFolderBtn: "Create",
        deleteFolderConfirmationText: "Are you sure you want to delete this folder?",
        cancelBtn:"Cancel",
        deleteBtn: "Delete",
        selectFolderInfo: "Select the folder , to which you want to move your file",
        moveFolderCancelBtn: "Cancel",
        moveBtn: "Move Folder",
        renameSaveBtn: "Rename Folder",
        renameYourFolderText: "Rename your folder",
        createFolderBtn: "Create Folder",
      },
    },
    dashboard: {
      widgets: {
        latestApps: {
          headerTitle: "Latest Apps",
        },
        totalKonnectz: {
          headerTitle: "Total Konnectz",
          activeKonnectz: "Active Konnectz",
          inactiveKonnectz: "Inactive Konnectz",
        },
        latestKonnectz: {
          headerTitle: "Latest Konnectz",
        },
        subscription: {
          contentText: "Unlock more power by upgrading Pro",
          upgradeBtnLabel: "UPGRADE NOW",
        },
        taskConsumption: {
          headerTitle: "Task Consumption",
        },
      },
    },
    account: {
      myProfileText: "My Profile",
      billingtext:"Billing Address",
      emailAddressLabel: "Email Address",
      firstNameLabel: "First Name",
      lastNameLabel: "Last Name",
      firstNameRequiredText: "First Name is required!",
      lastNameRequiredText: "Last Name is required!",
      countryRequiredText: "Country is required!",
      addressLine1RequiredText: "Address Line 1 is required!",
      cityRequiredText: "City is required!",
      stateRequiredText: "State is required!",
      zipCodeRequiredText: "Zip Code is required!",
      taxIdRequiredText: "Tax ID is required!",
      companyNameLabel: "Company Name",
      currentPositionLabel: "Current Position",
      countryLabel: "Country",
      addressLine1Label: "Address Line 1",
      addressLine2Label: "Address Line 2",
      cityLabel: "City",
      stateLabel: "State",
      zipCodeLabel: "Zip Code",
      taxIdLabel: "Tax ID",
      timeZoneLabel: "Time Zone",
      changeLanguageLabel: "Change Language",
      changePasswordHeaderText: "Change Password",
      currentPasswordLabel: "Current Password",
      currentPasswordRequiredText: "Current password is required!",
      newPasswordLabel: "New Password",
      newPasswordRequiredText: "New password is required!",
      confirmPasswordLabel: "Confirm Password",
      confirmPasswordRequiredText: "Confirm password is required!",
      updateBtnLabel: "Update",
      editUpdateAccountSideBarText: "Edit/Update Account",
      changePasswordSideBarText: "Change Password",
      billingText: "Billing",
      teamText: "Team",
      agencyText: "Agency",
      sideBarHeaderAccountText: "Account",
      profileUpdatedSuccessfullyText: "Profile updated successfully!",
      addTeamMemberText: "Add Team Member",
      addSubAccountsText: "Add Sub Accounts",

      billing: {
        couponCodeText: "Code",
        applyCodeBtnLbl: "Apply code",
        tasksText: "Tasks",
        redeemedOnText: "Redeemed On",
        expiresOnText: "Expires On",
        noCouponsAppliedText: "No license applied",
        plansAndFeaturesText: "Plans and Features",
        currentPlanText: "Current Plan",
        upgradeText: "Upgrade",
        billingText: "Billing",
        licenseerror:"Invalid License!",
        completionofprofile:"Please complete your profile before redeeming the code. This is required for GPDR/Data processing & Billing Purpose",
      },
      team: {
        name: "Name",
        firstName: "First Name",
        lastName: "Last Name",
        access: "Access",
        email: "Email",
        addBtn: "Add",
        cancelBtn: "Cancel",
        assignAccessLbl: "Assign Access",
        noUsersInfo: "No users available",
        teamMembersHeaderText: "Team Members",
      },
      agency: {
        name: "Client Name :",
        firstName: "First Name",
        lastName: "Last Name",
        tasks: "Task Assigned :",
        consumed: "Task Consumed ",
        totalSubaccounts: "Total Subaccounts :",
        email: "Email :",
        active: "Active :",
        inActive: "Inactive :",
        addBtn: "Add",
        cancelBtn: "Cancel",
        noAgenciesInfo: "No agencies available",
        agencyMembersHeaderText: "Sub Accounts",
        total: "Total Tasks :",
        update: "Update",
        updateTask: "Update Task",
        delete: "Delete",
        available: "Task available",
        deactivate: "Activate",
        activate: "De-Activate",
        subaccounterror: "Failed to update the subaccount details!",
      },
    },
    sideNav: {
      homeLabel: "Home",
      appsLabel: "Apps",
      konnectzLabel: "Konnectz",
      historyLabel: "History",
      accountLabel: "Account",
      notificationsLabel: "Notifications",
      logoutLabel: "Logout",
      getHelpLabel: "Get Help?",
      konnectzITLogoText: "KonnectzIT",
    },
  },
}
