import React, { useState } from "react"
import { Box, Text, Flex, Link, Image } from "rebass"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw"
import { Span, Icon } from "src/components"
import { TaskDetails } from "./taskDetails"
import ReactTooltip from "react-tooltip"
import { Modal } from "react-responsive-modal"
import { TaskDetailsModal } from "./TaskDetailModal"
import { DecoratorIcon } from "../../components/Icon/Icon"
import { faTimes, faSearch, faExpand } from "@fortawesome/free-solid-svg-icons"
import Cross from "../../assets/images/cross.svg"
import { svg_1 } from "../../assets/images/svg"
import { svg } from "../../assets/images/svg"
export const TaskListItem = ({
  task,
  setExpandTask,
  expandedItem,
  setTaskLogs,
  fetchMoreLogs,
  taskDetail,
  taskDetailsId,
  setTaskDetailsId,
  taskLogs,
  logsList,
  historyType,
  setHistoryType,
  logsPageIndex,
  setLogsPageIndex,
  setKonnectId,
}) => {
  const { t } = useTranslation()
  const [expandItem, setExpandItem] = useState(false)
  const [openMoveFolderModal, setOpenMoveFolderModal] = useState(false)
  const [newId, setNewId] = useState(null) // const onItemClick = () => {

  //     setExpandItem(!expandItem)
  // }

  const onMoveFolderIconClick = () => {
    setHistoryType(historyType)
    setKonnectId(task.id)
    setExpandTask(newId)
    setTaskDetailsId(newId)
    setOpenMoveFolderModal(true)
  }
  const onCloseModal = () => {
    setOpenMoveFolderModal(false)
    setTaskLogs([])
    setLogsPageIndex(1)
  }
  return (
    <>
      <Box
        sx={{
          cursor: "pointer",
          zIndex: "900",
        }}
        // onClick={onItemClick}
      >
        {console.log(task.left_app.name)}

        <Flex
          // key={idx}
          p={{ _: "14px", sm: "14px", md: "16px", lg: "18px", xl: "20px" }}
          sx={{
            width: "100%",
            backgroundColor: "#DBE5FF",
            borderRadius: "16px",
            zIndex: "900",
            flexDirection: "row",
            justifyContent: "space-between",
            boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
            "&:hover": {
              boxShadow: "0px 0px 3px 1px rgb(81,142,248)",
            },
          }}
        >
          <Flex width="70%" sx={{ flexDirection: "column", gap: ["6px", "8px", "10px", "12px"] }}>
            <Box fontSize={{ _: 10, sm: 10, md: 12, lg: 12, xl: 16 }}>
              {task.konnect_name ? (
                task.konnect_name
              ) : (
                <>
                  {task.left_app.name}
                  {"  "}
                  {task.right_apps.map(x => {
                    return <>{" - " + x.app_name + "  "}</>
                  })}
                </>
              )}
            </Box>
            <Flex width="100%" sx={{ position: "relative" }}>
              <Image width="5%" src={task.left_app.image_url} />
              {task.right_apps.length > 0 && (
                          <Flex
                            sx={{
                              m: "auto 0px",
                              fill: "black",
                            }}
                          >
                            {svg_1.dashed_1}
                          </Flex>
                        )}
              {task.right_apps.length > 0 &&
                task.right_apps.map((rightApp, index) => {
                  return (
                    <>
                                {index !== 0 && (
                                  <Flex
                                    sx={{
                                      m: "auto 0px",
                                      fill: "black",
                                    }}
                                  >
                                    {svg_1.dashed_1}
                                  </Flex>
                                )}

                                <Image width="5%" src={rightApp.image_url} />
                              </>
                  )
                })}
            </Flex>
          </Flex>
          <Flex sx={{ alignItems: "center", gap: "50px" }}>
            <Flex sx={{ gap: ["20px", "20px", "30px", "40px"] }}>
              <Flex flexDirection="column">
                <Box sx={{ textAlign: "center" }}>
                  <Text fontSize={{ _: 12, sm: 12, md: 12, lg: 14, xl: 16 }} sx={{ fontWeight: "500", color: "#000" }}>
                    {task.task_count}
                  </Text>
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <Text fontSize={{ _: 12, sm: 12, md: 12, lg: 14, xl: 16 }} sx={{ fontWeight: "500", color: "#000" }}>
                    {task.task_count === 1 ? t("history.task.task") : t("history.task.tasks")}
                  </Text>
                </Box>
              </Flex>
              <Flex
                onClick={onMoveFolderIconClick}
                // onClick={() => deleteKonnectModal(konnect.id)}
                width={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
                height={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
                sx={{
                  cursor: "pointer",
                  m: "auto",
                  "&:hover": {
                    transform: "scale(1.2)",
                  },
                  transition: "transform 0.25s",
                }}
              >
                {svg_1.expand}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Modal
          open={openMoveFolderModal}
          onClose={onCloseModal}
          closeIcon={      <Flex
            sx={{
              "&:hover": {
                transform: "scale(1.2)",
              },
              transition: "transform 0.25s",
              cursor: "pointer",
              width: ["20px", "22px", "24px", "26px"],
              fill:"red"
            }}
          >
            {svg_1.xmark}
          </Flex>}
          center
          styles={{ modal: { padding: "20px", borderRadius: "16px", minWidth: "1100px", height: "753px" } }}
        >
          <TaskDetailsModal
            historyType={historyType}
            setHistoryType={setHistoryType}
            task={task}
            logsList={logsList}
            fetchMoreLogs={fetchMoreLogs}
            taskLogs={taskLogs}
            taskDetail={taskDetail}
            closeModal={onCloseModal}
            logsPageIndex={logsPageIndex}
            setLogsPageIndex={setLogsPageIndex}
            setExpandTask={setExpandTask}
            setNewId={setNewId}
            newId={newId}
          />
        </Modal>
      </Box>
    </>
  )
}
