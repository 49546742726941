import React from "react"

import { Box, Text, Flex, Link } from "rebass"
export const NewItem = ({task,setExpandTask,setNewId}) => {
   
    const onItemClick = () => {
        setNewId(task.id)
        setExpandTask(task.id)
    }
  return (
    <>
    {task && task.id? <Box
                    //  onClick={handleChangeId(x.id)} 
                    sx={{
                      width: "350px",
                      height: "80px",
                      backgroundColor: "#F3F7FF",
                      margin: "10px",
                      p: "10px",
                      cursor:"pointer",
                      borderRadius: "12px",
                      color: task.status == "SUCCESS" ? "#1E419D" : "red",
                      "&:hover": {
                        color: "#ffffff",
                        backgroundColor: task.status == "SUCCESS" ? "#1E419D" : "red",
                      },
                 
                    }}
                    onClick={onItemClick}
                  >
                    <Text sx={{ fontSize: "21px", fontWeight: "bold" }}> {task.status} #{task.event_description}</Text>
                    <Text sx={{ fontSize: "12px" }}> {task.task_date} </Text>
                  </Box>:null}
   
    </>
  )
    
}