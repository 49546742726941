import roundCorners from "./generateRoundCorners"

export const generateCurvePath = (startPos, endPos) => {
  const width = Math.abs(startPos.x - endPos.x)
  const height = Math.abs(startPos.y - endPos.y)
  const leftToRight = startPos.x < endPos.x
  const isHorizontal = width > height || true
  const mid = width / 2
  let cb = leftToRight

  let stepStartPos = {
    x: startPos.x + mid,
    y: startPos.y,
  }
  let stepEndPos = {
    x: endPos.x - mid,
    y: endPos.y,
  }

  let start = startPos
  let end = endPos

  const curve = isHorizontal ? width / 2 : height / 2
  const curveX = isHorizontal ? curve : 0
  const curveY = isHorizontal ? 0 : curve

  if (cb)
    return `M${start.x},${start.y} C ${start.x + curveX},${start.y + curveY} ${end.x - curveX},${end.y - curveY} ${
      end.x
    },${end.y}`

  const line = `M
  ${startPos.x},${startPos.y}
  L${stepStartPos.x},${stepStartPos.y}
  L${stepEndPos.x},${stepEndPos.y}
  L${endPos.x},${endPos.y}`

  const roundedLine = roundCorners(line, 50)
  return roundedLine
}
