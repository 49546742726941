import React, { useState } from "react"
import { Box, Text, Flex, Link } from "rebass"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw";
import { Button, Input, Label } from "src/components/index";
import Select from "react-select"
import Check from "../../assets/images/check.svg"
import EditIcon from "../../assets/images/Edit_icon.svg"
import DeleteIcon from "../../assets/images/Delete_icon.svg"
import Account from "../../assets/images/Account.svg"
export const AddTeamMemberModal = ({ closeModal, onAddingTeamMember }) => {
    const { t } = useTranslation()
    const [ firstName, setFirstName ] = useState("")
    const [ lastName, setLastName ] = useState("")
    const [ email, setEmail ] = useState("")
    const [isValid, setIsValid] = useState(true)
    const [ access, setAccess ] = useState({
        "value": "Create",
        "label": "Create"
    })

    const accessList = [
        {
            "value": "Create",
            "label": "Create"
        },
        {
            "value": "Create/Delete",
            "label": "Create/Delete"
        },
        {
            "value": "FULL",
            "label": "FULL"
        }
    ]

    return (
        <>
         <Flex flexDirection="column" sx={{  borderRadius: "16px", px: px2vw(60), padding: "0px" ,minWidth: "500px"}}>
      <Flex flexDirection="row" sx={{ borderRadius: "16px", px: px2vw(20), padding: "0px" }}>
      <Box sx={{ p:"0px 8px 0px 0px", justifyContent: "left", textAlign: "left" }}>
            <Account />
            {/* <img width="20px" height="20px" src={CreateFolderImg} /> */}
          </Box>
          <Text
            fontSize={[20]}
            fontWeight="semi-bold"
            color="app.primary.30"
            textAlign="left"
            sx={{ pt: 2.5, justifyContent: "left", textAlign: "left", mr: px2vw(3) }}
          >
            Add New Sub-Account
          </Text>
          
        </Flex>
        <Box sx={{ background: "#BCC6CC", width: "inherit", height: "1px", margin: "14px -20px" }}></Box>
        <Box sx={{ color: "#000000", p: px2vw(10) }}>

        <Flex>
            <Flex sx={{ width: "50%" }}>
              <Box>
                <img
                  style={{ borderRadius: "16px", border: "1px solid #B7CBFF" }}
                  width="140px"
                  height="140px"
                  // src={profile.image_url}
                />
              </Box>
              <Box sx={{ display: "grid", m: "7px 10px 10px 10px"}}>
                <EditIcon
                  style={{ cursor: "pointer", margin: "inherit" }}
                  data-tip={"Edit"}
                  // onClick={() => fileRef.current.click()}
                />
                <DeleteIcon
                  data-tip="Remove"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#ea4f4f",
                    borderRadius: "50%",
                    padding: "4px 7px",
                    height: "30px",
                    marginLeft: "15px",
                  }}
                  size="xl"
                  // onClick={removeImage}
                />
              </Box>
            </Flex>
            <Box sx={{ width: "50%" }}>
              {" "}
              <Input
            label={t("account.agency.firstName")}
      
            sxProps={{
              height: "40px !important",
              backgroundColor: "#E6EDFF",
              border: "1px solid #E6EDFF",
              borderRadius: "18px",
              color:"#152F73"
            }}
            type={"text"}
            value={firstName}
            // defaultValue={user.first_name}
            onChange={e => {
              setFirstName(e.currentTarget.value)
            }}
            setError={Boolean(firstName === "" && !isValid)}
            errorMessage={"Field should not be empty!"}
          />
          <Input
            label={t("account.agency.lastName")}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#E6EDFF",
              border: "1px solid #E6EDFF",
              borderRadius: "18px",
              color:"#152F73"
            }}
            type={"text"}
            value={lastName}
            // derfaultValue={user.last_name}
            onChange={e => {
              setLastName(e.currentTarget.value)
            }}
            setError={Boolean(lastName === "" && !isValid)}
            errorMessage={"Field should not be empty!"}
          />
            </Box>
          </Flex>
          
          <Input
            label={t("account.agency.email")}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#E6EDFF",
              border: "1px solid #E6EDFF",
              borderRadius: "18px",
              color:"#152F73"
            }}
            type={"text"}
            value={email}
            // defaultValue={user.email}
            onChange={e => {
              setEmail(e.currentTarget.value)
            }}
            setError={Boolean(email === "" && !isValid)}
            errorMessage={"Field should not be empty!"}
          />
             <Label>{t("account.team.assignAccessLbl")}</Label>
                    <Select 
                        options={accessList}
                        value={access}
                        onChange={(item) => {
                            setAccess(item)
                        }}
                        menuShouldScrollIntoView={true}
                        menuPosition="fixed"
                    />
        </Box>
        <Box sx={{ p: px2vw(10), mt: 20 }}>
          <Flex flexDirection="row" sx={{ justifyContent: "center" }}>
            <Box>
         
              

                <Box      onClick={() => onAddingTeamMember(firstName, lastName, email, access.value)} sx={{ cursor: "pointer", m: "1px 0 0 10px",display:"flex",alignItems:"center",bg:"#F7C546",color:"#152F73",p:"3px 10px 3px 3px",borderRadius:"10px" }}><span><Check style={{margin:"10px 12px 5px 5px"}}/></span><span> Add Team Member</span></Box>
            
             
            </Box>
          </Flex>
        </Box>
      </Flex>
            {/* <Flex flexDirection="column" sx={{ width: "400px", borderRadius: "16px", px: px2vw(60), padding: '0px' }}>
                <Box sx={{ color: "#000000", p: px2vw(10) }}>
                    <Input
                        label={t("account.team.firstName")}
                        sxProps={{
                            height: "40px !important",
                            backgroundColor: "#E6EDFF",
                            border: "1px solid #B7CBFF",
                            borderRadius: "8px",
                        }}
                        type={"text"}
                        value={firstName}
                        onChange={e => {
                            setFirstName(e.currentTarget.value)
                        }}
                        setError={Boolean(firstName === "")}
                        errorMessage={"Field should not be empty!"}
                    />
                    <Input
                        label={t("account.team.lastName")}
                        sxProps={{
                            height: "40px !important",
                            backgroundColor: "#E6EDFF",
                            border: "1px solid #B7CBFF",
                            borderRadius: "8px",
                        }}
                        type={"text"}
                        value={lastName}
                        onChange={e => {
                            setLastName(e.currentTarget.value)
                        }}
                        setError={Boolean(lastName === "")}
                        errorMessage={"Field should not be empty!"}
                    />
                    <Input
                        label={t("account.team.email")}
                        sxProps={{
                            height: "40px !important",
                            backgroundColor: "#E6EDFF",
                            border: "1px solid #B7CBFF",
                            borderRadius: "8px",
                        }}
                        type={"text"}
                        value={email}
                        onChange={e => {
                            setEmail(e.currentTarget.value)
                        }}
                        setError={Boolean(email === "")}
                        errorMessage={"Field should not be empty!"}
                    />
                    <Label>{t("account.team.assignAccessLbl")}</Label>
                    <Select 
                        options={accessList}
                        value={access}
                        onChange={(item) => {
                            setAccess(item)
                        }}
                        menuShouldScrollIntoView={true}
                        menuPosition="fixed"
                    />
                </Box>
                <Box sx={{ p: px2vw(10), mt: 20 }}>
                    <Flex flexDirection="row" sx={{ justifyContent: 'space-between' }}>
                        <Box sx={{ textAlign: 'center', justifyContent: 'center' }}>
                            <Button fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                                sx={{
                                    background: 'none',
                                    boxShadow: 'none',
                                    fontWeight: 'normal',
                                    cursor: 'pointer',
                                    color: "app.secondary.90",
                                    "&:hover": {
                                        background: "none",
                                        color: "app.secondary.90"
                                    }
                                }}
                                onClick={() => closeModal()}>{t("account.team.cancelBtn")}</Button>
                        </Box>
                        <Box>
                            <Button 
                                variant="primary" 
                                disabled={firstName === "" || lastName === "" || email === ""}
                                fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }} 
                                sx={{ fontWeight: 'normal', px: px2vw(20), borderRadius: '0px' }} 
                                onClick={() => onAddingTeamMember(firstName, lastName, email, access.value)}>
                                {t("account.team.addBtn")}
                            </Button>
                        </Box>
                    </Flex>
                </Box>
            </Flex> */}
        </>
    )
}
