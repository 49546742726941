import React, { useRef, useState, useEffect } from "react"
import { HeaderBackButton, HeaderInput, Konnectheader } from "src/components/Konnect/UI"
import { Flex, Text, Box, Button } from "rebass"
import px2vw from "utils/px2vw"
import { Modal } from "react-responsive-modal"
import { useData } from "../../Context/canvasContext"
import { useHistory } from "react-router-dom"
import { IconWrapper } from "../konnect/UI" //"../../components/Konnect/UI"
import { faArrowLeft, faUndo, faRedo } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import { useRefContext } from "../../store/context/index"
import { useDispatch, useSelector } from "react-redux"
import { updateNode } from "../../store/slice/canvasSlice"
import { TopbarToast, updateToast } from "../../features/toast/index"
import { undo, redo, saveKonnectName, clearState } from "../../store/slice/canvasSlice"
import { saveKonnect, getKonnect } from "../../features/index"
import { useThemeContext } from "../../store/context/themeContext"
export const KonnectTopbar = () => {
  const { editorMeta, editorState } = useSelector(state => state.canvas.canvas)
  const { nodes } = editorState
  const dispatch = useDispatch()
  const { setSelected } = useThemeContext()
  const { topbarContainer, sidebarContainer } = useRefContext()
  const flexEndContainer = useRef()

  const [openModal, setOpenModal] = useState(false)

  const [saved, setSaved] = useState(false)
  const [konnectTitle, setKonnectTitle] = useState(editorState.konnect_name ? editorState.konnect_name : "")

  const history = useHistory()
  const konnectIdPresent = nodes.length > 0 ? nodes[0].data.konnect_id : null
  const canPublish = editorState.nodes.length>0 && editorState.nodes?.every(x => x.data.tested && x.data.reviewed) 
  const { t } = useTranslation()
  const history1 = useHistory()
  let idd = history1.location.pathname.split("/").pop()
  useEffect(() => {
    if (idd && !isNaN(idd)) {
      dispatch(getKonnect(idd)).then(res => {
        setKonnectTitle(res.payload.canvas_json.konnect_name)
      })
    }
  }, [])
  const onSaveKonnect = () => {
    dispatch(saveKonnect({ editorState: editorState, editorMeta: editorMeta,publish:false}))

    setSaved(true)
    console.log("hiiSave")
    updateToast(dispatch, {
      message: t("konnect.saveSuccessMsg"),
      status: "success",
    })
    showSaveModal(t("konnect.publishSuccessMsg"))
  }
  const onPublishKonnect = () => {
    dispatch(saveKonnect({ editorState: editorState, editorMeta: editorMeta ,publish:true}))
    updateToast(dispatch, {
      message: t("konnect.publishSuccessMsg"),
      status: "success",
    })
    console.log("hii")
    showSaveModal(t("konnect.publishSuccessMsg"))
  }
  const actions = [
    {
      icon: faUndo,
      clickFunc: () => {
        dispatch(undo())
      },
      label: "UNDO",
    },
    {
      icon: faRedo,
      clickFunc: () => {
        dispatch(redo())
      },
      label: "REDO",
    },
    {
      disabled: !konnectIdPresent,
      label: t("konnect.header.saveBtnLabel"),
      clickFunc: onSaveKonnect,
    },
    {
      disabled: !canPublish,
      label: t("konnect.header.publishBtnLabel"),
      clickFunc: onPublishKonnect,
    },
  ]

  const showSaveModal = (message, res) => {
    setOpenModal(true)
  }
  const onCloseModal = list => {
    setOpenModal(false)
  }

  const searchKonnect = e => {
    let x = e.target.value
    setKonnectTitle(e.target.value)
    dispatch(saveKonnectName(x))
  }

  return (
    <>
      <Konnectheader
        ref={topbarContainer}
        height="10%"
        width="100%"
        sx={{ boxShadow: "0px 0px 5px 2px rgb(214,214,214, 0.6)", zIndex: 3 }}
      >
        <HeaderBackButton
          onClick={() => {
            if( editorState.nodes.slice(1)?.every(x=>x.data.konnect_activity_id )){
              dispatch(saveKonnect({ editorState: editorState, editorMeta: editorMeta,publish:false}))
            }
            dispatch(clearState())
            let route = localStorage.getItem("selected-route")
            setSelected(route)
            history.push(route)
          }}
        >
          <IconWrapper icon={faArrowLeft} />
          <Text>{t("konnect.header.backBtnLabel")}</Text>
        </HeaderBackButton>
        <Flex
          height="100%"
          width="350px"
          sx={{ position: "absolute", right: flexEndContainer?.current?.getBoundingClientRect().width + 40 }}
        >
          <TopbarToast />
        </Flex>
        <Flex ref={flexEndContainer} sx={{ justifyContent: "space-between", gap: px2vw(20), alignItems: "center" }}>
          {actions.map(action => {
            if (action.icon) {
              return (
                <Flex
                  onClick={() => action.clickFunc()}
                  sx={{
                    cursor: "pointer",
                    flexDirection: "column",
                    "&:hover": {
                      "& #icon": {
                        transform: "scale(1.2)",
                      },
                    },
                  }}
                >
                  <IconWrapper id="icon" icon={action.icon} style={{ transition: "transform 0.25s" }} />
                  <Text fontSize={{ _: 8, sm: 8, md: 10, lg: 10, xl: 10 }}>{action.label}</Text>
                </Flex>
              )
            }
          })}
          <HeaderInput
            id="konnectnameInput"
            width="368px"
            value={konnectTitle}
            onChange={e => searchKonnect(e)}
            placeholder={t("konnect.header.nameOfKonnectz")}
          />
          {actions.map(action => {
            if (!action.icon) {
              return (
                <Button
                  disabled={action.disabled}
                  onClick={action.clickFunc}
                  sx={{
                    fontStyle: "normal",
                    fontWeight: 500,
                    height: "100%",
                    cursor: action.disabled ? "not-allowed" : "pointer",
                    backgroundColor: action.disabled ? "#7e7e7e" : "#f7c545",
                    "&:hover": {
                      backgroundColor: "#152f73",
                      color: "#f7c545",
                    },
                    p: "8px 20px 8px 20px",
                    color: "#000",
                    border: "none",
                    borderRadius: "8px",
                    alignSelf: "center",
                  }}
                >
                  {action.label}
                </Button>
              )
            }
          })}
        </Flex>
      </Konnectheader>
      <Modal
        open={openModal}
        onClose={onCloseModal}
        onAnimationEnd={() => scrollTo(0, 0)}
        center
        styles={{ modal: { padding: "20px", borderRadius: "16px" } }}
      >
        <Flex flexDirection="column" sx={{ borderRadius: "16px", px: px2vw(60), padding: "0px" }}>
          <Box sx={{ color: "#000000", p: px2vw(10), m: "5" }}>
            <Text variant="title" fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}>
              {saved ? "Konnect is successfully saved" : "Konnect is successfully published"}
            </Text>
          </Box>
          <Box sx={{ p: px2vw(10) }}>
            <Flex flexDirection="row" sx={{ justifyContent: "center", marginTop: "10px" }}>
              <Box>
                <Button
                  variant="primary"
                  fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                  sx={{ fontWeight: "normal", px: px2vw(20), borderRadius: "0px" }}
                  onClick={() => {
                    dispatch(clearState())

                    history.push("/Konnectz")
                  }}
                >
                  OK
                </Button>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Modal>
    </>
  )
}
