/**
 * App config reducer to handle the team api responses.
 */

import { ADD_TEAM_MEMBER, GET_TEAM_USERS_LIST, UPDATE_TEAM_MEMBER, DELETE_TEAM_MEMBER } from "config/Constants"

const initState = {
  teamUsersList: [],
}

const SUCCESS = "_SUCCESS"
const FAIL = "_FAIL"

const teamReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_TEAM_USERS_LIST + SUCCESS:
      return state.set("teamUsersList", action.payload.team_users)
    case GET_TEAM_USERS_LIST + FAIL:
      return state.set("teamUsersList", [])
    case UPDATE_TEAM_MEMBER + SUCCESS:
      return state
    case UPDATE_TEAM_MEMBER + FAIL:
      return state

    case DELETE_TEAM_MEMBER + SUCCESS:
      return state
    default:
      return state
  }
}

export default teamReducer
