import React, { useEffect, useState } from "react"
import { Box, Text, Flex, Link } from "rebass"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw"
import {
  faEllipsisH,
  faEdit,
  faTrash,
  faFileImport,
  faICursor,
  faFolderPlus,
  faFolder,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons"
import { EllipsisIconWrapper } from "../../components/Konnect/UI"
import { Span, Icon, Button, Checkbox } from "src/components"
import ReactTooltip from "react-tooltip"
import Switch from "react-switch"
import InfiniteScroll from "react-infinite-scroll-component"
import { KonnectItem } from "./KonnectItem"

export const KonnectzView = ({
  konnectsList,
  foldersList,
  currentFolderId,
  setExpandKonnect,
  showMoveIcon,
  onRenameFolderIconClick,
  onDeleteFolderIconClick,
  filterKonnects,
  updateKonnectsSelection,
  onMoveFolderIconClick,
  expandedItem,
  onCreateFolderBtnClick,
  deleteKonnect,
  changeStatus,
  showLoader,
  fetchMoreKonnects,
  onEditKonnect,
}) => {
  const { t } = useTranslation()
  const [showRenameIcon, setShowRenameIcon] = useState(false)
  const [folderId, setFolderId] = useState(currentFolderId ? currentFolderId : null)
  const [konnects, setKonnects] = useState(konnectsList.filter(konnect => konnect.status !== "DELETED"))
  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    setFolderId(currentFolderId)
    let folderBtnElements = Array.from(document.getElementsByClassName("folder-btn"))
    folderBtnElements.map(element => {
      if (currentFolderId && currentFolderId.toString() === element.getAttribute("data-folderid")) {
        element.style.backgroundColor = "#152F73"
        element.style.color = "#F7C545"
      }
    })
  }, [currentFolderId])

  useEffect(() => {
    let konnectsArr = konnectsList.filter(konnect => konnect.status !== "DELETED")
    setKonnects(konnectsArr)
  }, [konnectsList])

  const filterKonnectsByFolderId = (folderId, event) => {
    showLoader(true)
    if (!folderId) {
      var ele = document.getElementsByClassName("folder-btn")
      for (var i = 0; i < ele.length; i++) {
        ele[i].style.backgroundColor = "#FFFFFF"
        ele[i].style.color = "#827E7E"
      }
      setShowRenameIcon(false)
      setFolderId(null)
      filterKonnects(folderId, true)
      return
    }
    let folderBtnElements = Array.from(document.getElementsByClassName("folder-btn"))
    folderBtnElements.map(element => {
      if (event.currentTarget !== element) {
        element.style.backgroundColor = "#FFFFFF"
        element.style.color = "#827E7E"
      }
    })
    if (
      event.currentTarget.style.backgroundColor === "" ||
      event.currentTarget.style.backgroundColor === "rgb(255, 255, 255)"
    ) {
      event.currentTarget.style.backgroundColor = "#152F73"
      event.currentTarget.style.color = "#F7C545"
      setShowRenameIcon(true)
      setFolderId(folderId)
      filterKonnects(folderId, false)
    } else {
      event.currentTarget.style.backgroundColor = "#FFFFFF"
      event.currentTarget.style.color = "#827E7E"
      setShowRenameIcon(false)
      setFolderId(null)
      filterKonnects(folderId, true)
    }
  }
  const toggleForDropdown = p => {
    setExpandKonnect(p)
    setToggle(!toggle)
  }

  return (
    <>
      <Flex
        id="scrollableDiv1"
        flexDirection="column"
        sx={{ backgroundColor: "#f3f7ff", height: "100%", overflow: "scroll", overflowX: "hidden" }}
      >
        <Box sx={{ py: px2vw(20), px: px2vw(30) }}>
          <Text
            onClick={() => filterKonnectsByFolderId(null, null)}
            fontSize={{ _: 14, sm: 14, md: 18, lg: 20, xl: 24 }}
            sx={{ fontWeight: "bold", color: "#222222", cursor: "pointer" }}
          >
            {t("konnectzList.homeText")}
          </Text>
        </Box>
        <Flex flexDirection={"row"} sx={{ py: px2vw(20), px: px2vw(30), width: "100%" }}>
          <Flex
            sx={{ flexDirection: "row", flexWrap: "wrap", width: foldersList && foldersList.length > 0 ? "60%" : "0%" }}
          >
            {foldersList &&
              foldersList.length > 0 &&
              foldersList.map((folder, index) => (
                <Box key={"folder" + index} sx={{ mr: "20px", mt: "10px" }}>
                  <Button
                    data-folderid={folder.id}
                    className="folder-btn"
                    onClick={event => filterKonnectsByFolderId(folder.id, event)}
                    sx={{
                      backgroundColor: folderId === folder.id ? "152F73" : "#FFFFFF",
                      color: folderId === folder.id ? "#F7C545" : "#7E7E7E",
                      borderRadius: "8px",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                    iconRight={faFolder}
                  >
                    {folder.name}
                  </Button>
                </Box>
              ))}
          </Flex>
          <Flex
            sx={{
              justifyContent: "flex-start",
              flexDirection: "row",
              mt: "10px",
              width: foldersList && foldersList.length > 0 ? "15%" : "50%",
            }}
          >
            <Box sx={{ ml: "20px", mr: "10px", width: "250px" }}>
              <Button
                // disabled={foldersList.length >= 5}
                sx={{
                  backgroundColor: "#FFFFFF",
                  color: "#7E7E7E",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
                onClick={onCreateFolderBtnClick}
                iconRight={faFolderPlus}
              >
                {t("konnectzList.folders.createFolderBtn")}
              </Button>
            </Box>
          </Flex>
          <Flex
            sx={{
              justifyContent: "flex-end",
              flexDirection: "row",
              mt: "10px",
              width: foldersList && foldersList.length > 0 ? "25%" : "50%",
            }}
          >
            {showMoveIcon && (
              <Box sx={{ textAlign: "right", mt: "5px", mr: "10px" }}>
                <Icon
                  data-tip={"Move Konnectz"}
                  data-for={"folderActionIcons_move"}
                  sx={{ cursor: "pointer", mr: "20px" }}
                  icon={faFileImport}
                  onClick={onMoveFolderIconClick}
                />
                <ReactTooltip id={"folderActionIcons_move"} effect="solid" place="bottom"></ReactTooltip>
              </Box>
            )}
            {showRenameIcon && !showMoveIcon && (
              <>
                <Box sx={{ textAlign: "right", mt: "5px", mr: "10px" }}>
                  <Icon
                    data-tip={"Rename folder"}
                    data-for={"folderActionIcons_rename"}
                    sx={{ cursor: "pointer", mr: "20px" }}
                    icon={faEdit}
                    onClick={() => onRenameFolderIconClick(folderId)}
                  />
                  <ReactTooltip id={"folderActionIcons_rename"} effect="solid" place="bottom"></ReactTooltip>
                </Box>
                <Box sx={{ textAlign: "right", mt: "5px", mr: "10px" }}>
                  <Icon
                    data-tip={"Delete folder"}
                    data-for={"folderActionIcons_delete"}
                    sx={{ cursor: "pointer" }}
                    icon={faTrash}
                    onClick={() => onDeleteFolderIconClick(folderId)}
                  />
                  <ReactTooltip id={"folderActionIcons_delete"} effect="solid" place="bottom"></ReactTooltip>
                </Box>
              </>
            )}
          </Flex>
        </Flex>

        <InfiniteScroll
          style={{ display: "flex", flexDirection: "column" }}
          dataLength={konnects.length || 0} //This is important field to render the next data
          next={fetchMoreKonnects}
          hasMore={true}
          pullDownToRefresh={false}
          scrollableTarget="scrollableDiv1"
          //  loader={<h4>Loading...</h4>}
        >
          {konnects.length > 0 &&
            konnects.map((konnect, index) => {
              if (konnect.status !== "DELETED") {
                ;<KonnectItem konnect={konnect} i={index} />
              }

              // if(konnect.status !== "DELETED"){
              return (
                <Flex width="100%" flexDirection="row" key={"konnect-id-" + konnect.id} sx={{ px: "10px" }}>
                  <CheckboxSelection
                    index={index}
                    konnect={konnect}
                    updateKonnectsSelection={updateKonnectsSelection}
                    showMoveIcon={showMoveIcon}
                  />
                  <Box width="100%">
                    <Box
                      sx={{
                        m: px2vw(16),
                        p: px2vw(30),
                        backgroundColor: "konnectzList.itemBg",
                        borderRadius: "16px",
                        cursor: "pointer",
                        zIndex: "900",
                      }}
                      onClick={() => toggleForDropdown(konnect.id)}
                    >
                      {/* <EllipsisIconWrapper icon={faEdit} onClick={() => show()} /> */}
                      <Box px={2}>
                        <Text
                          data-tip={konnect.konnect_name}
                          data-for={"konnectText_" + konnect.id}
                          margin="-20px 0px 10px 0px"
                          position="absolute"
                          width="303px"
                          height="21px"
                          left="375px"
                          top="182px"
                          fontFamily="Poppins"
                          fontStyle="normal"
                          fontWeight="500"
                          fontSize="14px"
                          lineHeight="21px"
                          color="#152F73"
                        >
                          {konnect.konnect_name
                            ? konnect.konnect_name.length > 25
                              ? konnect.konnect_name.substring(0, 25) + "..."
                              : konnect.konnect_name
                            : ""}
                        </Text>
                        <ReactTooltip id={"konnectText_" + konnect.id} effect="solid" place="right"></ReactTooltip>
                      </Box>
                      <Flex flexDirection="row" sx={{ flexGrow: "1" }}>
                        <Box
                          width={{ _: 40, sm: 40, md: 45, lg: 50, xl: 60 }}
                          height={{ _: 40, sm: 40, md: 45, lg: 50, xl: 60 }}
                          sx={{
                            width: "60px",
                            height: "60px",
                            p: "10px",
                            borderRadius: "12px",
                            backgroundColor: konnect.left_app.background_color,
                            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.08)",
                          }}
                        >
                          <img width="100%" height="100%" src={konnect.left_app.image_url} />
                        </Box>
                        <Box
                          width={{ _: 40, sm: 40, md: 45, lg: 50, xl: 60 }}
                          sx={{
                            border: "3px dashed #152F73",
                            height: "0px",
                            textAlign: "center",
                            position: "relative",
                            top: ["18px", "22px", "24px", "26px"],
                          }}
                        />
                        {konnect.right_apps.length > 0 &&
                          konnect.right_apps.map((rightApp, index) => {
                            return (
                              <React.Fragment key={"rightApp-id-" + index}>
                                <Box
                                  width={{ _: 40, sm: 40, md: 45, lg: 50, xl: 60 }}
                                  height={{ _: 40, sm: 40, md: 45, lg: 50, xl: 60 }}
                                  sx={{
                                    p: "10px",
                                    borderRadius: "12px",
                                    backgroundColor: rightApp.background_color,
                                    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.08)",
                                  }}
                                >
                                  <img width="100%" height="100%" src={rightApp.image_url} />
                                </Box>
                                {index !== konnect.right_apps.length - 1 && (
                                  <Box
                                    width={{ _: 40, sm: 40, md: 45, lg: 50, xl: 60 }}
                                    sx={{
                                      border: "3px dashed #152F73",
                                      height: "0px",
                                      textAlign: "center",
                                      position: "relative",
                                      top: ["18px", "22px", "24px", "26px"],
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            )
                          })}
                        <Flex
                          flexDirection="row"
                          sx={{
                            flexGrow: "1",
                            justifyContent: "flex-end",
                            position: "relative",
                            top: "26px",
                            zIndex: "800",
                          }}
                        >
                          <EllipsisIconWrapper icon={faEllipsisH} onClick={() => setExpandKonnect(konnect.id)} />
                        </Flex>
                      </Flex>
                    </Box>
                    {toggle ? (
                      <Box
                        sx={{
                          backgroundColor: "#FFF",
                          borderRadius: "16px",
                          mx: px2vw(16),
                          px: px2vw(30),
                          pt: px2vw(40),
                          pb: px2vw(20),
                          position: "relative",
                          top: px2vw(-30),
                          display: expandedItem === konnect.id ? "block" : "none",
                          transition: "visibility 0s, opacity 0.5s linear",
                        }}
                      >
                        <Flex flexDirection="row" sx={{ justifyContent: "space-between" }}>
                          <Flex fontSize={{ _: 10, sm: 10, md: 11, lg: 11, xl: 12 }} sx={{ width: "30%" }}>
                            <Span>{konnect.konnect_description}</Span>
                          </Flex>
                          <Flex sx={{ justifyContent: "flex-start" }}>
                            <Link
                              to={`/konnect1/${konnect.id}`}
                              sx={{
                                cursor: "pointer",
                              }}
                            >
                              <Icon ml="30px" mr="40px" icon={faEdit} onClick={() => onEditKonnect(konnect.id)} />
                            </Link>

                            <Icon
                              ml="30px"
                              mr="40px"
                              icon={faTrash}
                              sx={{ cursor: "pointer" }}
                              onClick={() => deleteKonnect(konnect.id)}
                            />
                          </Flex>
                          <Flex>
                            <Switch
                              onChange={() => changeStatus(konnect.id, konnect.status)}
                              checked={konnect.status === "ACTIVE"}
                            />
                          </Flex>
                        </Flex>
                      </Box>
                    ) : null}
                  </Box>
                </Flex>
              )
              //}
            })}
        </InfiniteScroll>
        {konnects.length === 0 && (
          <Box sx={{ px: px2vw(30), py: px2vw(20) }}>
            <Text sx={{ fontSize: "20px", color: "app.primary.30" }}>{t("konnectzList.noKonnectzInfo")}</Text>
          </Box>
        )}
      </Flex>
    </>
  )
}

const CheckboxSelection = ({ index, konnect, updateKonnectsSelection, showMoveIcon }) => {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (!showMoveIcon) {
      setChecked(false)
    }
  }, [showMoveIcon])

  return (
    <Box sx={{ alignSelf: "center" }}>
      <input
        type="checkbox"
        id={`custom-checkbox-${index}`}
        onChange={e => {
          setChecked(!checked)
          updateKonnectsSelection(konnect.id, !checked)
        }}
        checked={checked}
        name={konnect.id}
        value={konnect.id}
      />
    </Box>
  )
}
