import React from "react"
import { injectGlobal } from 'emotion'

injectGlobal`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  :focus: { outline: none; }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#cover-spin {
  position:fixed;
  width:100%;
  left:0;right:0;top:0;bottom:0;
  background: transparent;
  z-index:9999;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
  display:block;
}

@-webkit-keyframes spin {
from {-webkit-transform:rotate(0deg);}
to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
from {transform:rotate(0deg);}
to {transform:rotate(360deg);}
}

.rec-carousel {
  height: 520px !important;
}

#cover-spin::after {
  content:'';
  display:block;
  position:absolute;
  left:48%;top:40%;
  width:40px;height:40px;
}

.link{
  color: #222222
}

.link:hover {
  color: #0BE0A0
}

.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-image-source: linear-gradient(to right, #C9CFDF, #152363, #C9CFDF);
}

.konnect-name::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #C9CFDF;
  opacity: 1; /* Firefox */
}

.konnect-name::-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #C9CFDF;
}

.konnect-name::-ms-input-placeholder { /* Microsoft Edge */
  color: #C9CFDF;
}
.feature-desc {
  font-size: '18px'
  @media all and (min-width: 1280px) and (max-width: 1440px) { 
    font-size: '15px'
  }
  @media all and (min-width: 1024px) and (max-width: 1280px) { 
    font-size: '10px'
  }
}

`
