/**
 * Root reducer that combines all the reducers present in the App & encapsulates the Store data into Immutable object.
 */

import { fromJS } from "immutable"
import appReducer from "./appReducer"
import konnectReducer from "./konnectReducer"
import appsReducer from "./appsReducer"
import profileReducer from "./profileReducer"
import dashboardReducer from "./dashboardReducer"
import historyReducer from "./historyReducer"
import billingReducer from "./billingReducer"
import teamReducer from "./teamReducer"
import foldersReducer from "./foldersReducer"
import agencyReducer from "./agencyReducer"
import resellerReducer from "./resellerReducer"

export default (state, action) => {
  return fromJS({
    app: appReducer(state.get("app"), action),
    dashboard: dashboardReducer(state.get("dashboard"), action),
    konnect: konnectReducer(state.get("konnect"), action),
    apps: appsReducer(state.get("apps"), action),
    profile: profileReducer(state.get("profile"), action),
    history: historyReducer(state.get("history"), action),
    billing: billingReducer(state.get("billing"), action),
    team: teamReducer(state.get("team"), action),
    agency: agencyReducer(state.get("agency"), action),
    reseller: resellerReducer(state.get("reseller"), action),
    folders: foldersReducer(state.get("folders"), action)
  })
}
