/**
 * Sign-up page UI.
 */
import React from "react"
import { Box, Text, Flex } from "rebass"
import { faEnvelope, faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { Checkbox, Label } from "@rebass/forms"
import { Input, Button } from "src/components"
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next"
import { ToastContainer, toast } from 'react-toastify';
import { isEmail } from "src/utils"
import px2vw from 'utils/px2vw'
import { AuthTitle, AuthSubtitle } from 'src/components/Typography/Typography'

export const SignupView = ({ error, state, onSubmit, onContinueClick, validation }) => {
    const errorProps = error ? { error, icon: faExclamationCircle } : {}
    const [email, setEmail] = state.email
    const [emailError, setEmailError] = state.emailError
    const [emailErrorMsg, setEmailErrorMsg] = state.emailErrorMsg
    const [isAdmin, setIsAdmin] = state.isAdmin
    const [captcha, setCaptcha] = state.captcha

    const { t } = useTranslation()

    function handleGoogleCaptcha(value) {
        setCaptcha(value ? true : false)
    }

    function displayError(e) {
        toast.error(t("auth.login.captchaErrorMsg"));
    }

    function submit() {
        if (!isEmail(email)) {
            setEmailError(true)
        } else {
            setEmailError(false)
            onContinueClick()
        }
    }

    return (
        <>
            <Box
                sx={{
                    textAlign: 'center'
                }}
            >
                <AuthSubtitle>{t("auth.signup.subtitle")}</AuthSubtitle>
            </Box>
            <Box className="border-gradient" sx={{ width: '121px', height: '0px', margin: '0px auto', mb: px2vw(10), mt: px2vw(5) }} />
            <Box
                sx={{
                    textAlign: 'center'
                }}
            >
                <AuthTitle>{t("auth.signup.title")}</AuthTitle>
            </Box>
            <Box
                sx={{
                    //pt: px2vw(52),
                    mx: px2vw(18),
                    mt: px2vw(38)
                }}
            >
                <Input
                    id="email"
                    name="email"
                    sxProps={{
                        "& ~ span": { color: "#B7CBFF !important" },
                    }}
                    setError={emailError}
                    errorMessage={t(emailErrorMsg)}
                    defaultValue={email}
                    onChange={e => {
                        setEmail(e.currentTarget.value)
                        setEmailErrorMsg('')
                    }}
                    required
                    iconLeft={faEnvelope}
                    placeholder={t("auth.signup.emailPlaceholder")}
                />
            </Box>

            <Flex
                variant="flex.center"
                sx={{
                    px: "0px",
                    mx: px2vw(18),
                }}
            >

                <Label variant="wrapperLabel" sx={{ fontSize: ["xxs", "xs", "sm"], ml: px2vw(8), color: "app.secondary.20" }}>
                    <Checkbox
                        name="isAdmin"
                        onChange={e => {
                            setIsAdmin(!isAdmin)
                        }}
                        value={isAdmin}
                    />
                    {t("auth.signup.teamSignup")}
                </Label>
            </Flex>
            <Box sx={{ pt: px2vw(12), ml: px2vw(18), mt: px2vw(28), pb: px2vw(10), textAlign: 'center' }}>
                <ReCAPTCHA
                    sitekey={process.env.GOOGLE_CAPTCHA_SITE_KEY}
                    onChange={handleGoogleCaptcha}
                />
            </Box>

            <Button
                height={{ _: 40, sm: 40, md: 40, lg: 40, xl: 60 }}
                fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }}
                sx={{ borderRadius: '0px', mx: px2vw(18), my: px2vw(20), fontStyle: 'normal' }}
                stretch
                data-tracking="click"
                data-category="Signup"
                data-action="User signup click"
                disabled={!(isEmail(email) && captcha)}
                onClick={captcha ? submit : displayError}
            >
                {t("auth.signup.continueBtnLabel")}
            </Button>
            <Flex flexDirection="column" sx={{ flexGrow: 1, justifyContent: "center" }}>
                <Box sx={{ pt: px2vw(18), textAlign: 'center' }}>
                    <Text fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }}
                        sx={{ display: "inline-block", fontStyle: 'normal', color: 'app.secondary.20' }}
                    >
                        {t("auth.signup.alreadyHaveAccountText")}<a href='/login' style={{ color: '#F7C545' }}>{t("auth.signup.loginBtnLabel")}</a>
                    </Text>
                </Box>
                <Box sx={{ pt: px2vw(20), textAlign: 'center' }}>
                    <Text fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }}
                        sx={{ display: "inline-block", fontStyle: 'normal', color: 'app.secondary.20' }}
                    >
                        {t("auth.login.clickingContinueText")}<a href='/signup' style={{ color: '#F7C545' }}>{t("auth.login.termsAndConditionsText")}</a>
                    </Text>
                </Box>
            </Flex>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                closeButton={false}
                pauseOnHover
            />

        </>

    )
}
