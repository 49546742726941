import React from "react"
export default ({ fill = "#B7CBFF" }) => {
  return (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.25 10.375C1.25 11.0625 1.8125 11.625 2.5 11.625H7.5C8.1875 11.625 8.75 11.0625 8.75 10.375V2.875H1.25V10.375ZM9.375 1H7.1875L6.5625 0.375H3.4375L2.8125 1H0.625V2.25H9.375V1Z"
        fill={fill}
      />
    </svg>
  )
}
