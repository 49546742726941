import React, { memo, useState, useMemo, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Dropdown } from "../Dropdown"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { Modal } from "react-responsive-modal"
import { Handle } from "react-flow-renderer"
import px2vw from "../../../../../utils/px2vw"
import ReactModal from "react-modal"
import ReactTooltip from "react-tooltip"
import AuthTokenService from "utils/AuthTokenService"
import { OAUTH_REDIRECT_URL } from "config/Constants"
import { useHistory } from "react-router-dom"
import { SyncOutlined } from "@ant-design/icons"
import "./style.css"
import GsheetModal from "../nodes/modals/gsheetModal"
import {
  NodeOuter,
  AppInfo,
  EventSelectionGroup,
  SelectionHeader,
  ConnectorPortLabel,
  ConnectorPortOuter,
  ConnectorPortText,
  ConnectorPortInput,
  TestAndReviewButton,
  WrappedPort,
  AddMoreFieldButton,
  ConnectorPortError,
  ParamsButton,
} from "../UI"
import Cross from "../../../../../assets/images/cross.svg"
import EditIcon from "../../../../../assets/images/Edit_icon.svg"
import DeleteIcon from "../../../../../assets/images/Delete_icon.svg"
import SaveIcon from "../../../../../assets/images/Save_icon.svg"
import PlusIcon from "../../../../../assets/images/Plus_icon.svg"
import Key from "../../../../../assets/images/keyIcon.svg"
import { ToastContainer, toast } from "react-toastify"
import { Box, Flex, Text, Image } from "rebass"
import { Icon, Preloader, Span } from "src/components"
import { CommonOuter } from "../NodeInner/CommonOuter"
import { AdditionalConfigsModal } from "../../../../../components/Konnect/Base/Components/NodeInner/modals/additionalConfigsModal" //./modals/additionalConfigsModal
import { RightHandConfigModal } from "./modals/RightHandConfigModal"
import { Input, Button } from "src/components"
import { DelayModal } from "./delay"
import {
  onDeleteNodeV2,
  getAppEventConfig,
  getAppEventConfigDetail,
  getAppEventConfigDetailFetch,
  appTestAndReview,
  getOauthData,
  updateAppStatus,
  saveOauthData,
  getLinkedAccounts,
  getAppEventConfigDetailCustom,
  getAppEventConfigDetailFetchuserDefined,
  saveAuthLabel,
} from "../../../features/index"
import { svg } from "../../../asset/svg.js"
let browser = window
let popup = null
let timer = null
import { useSelector, useDispatch } from "react-redux"
import { updateToast } from "../../../features/toast/index"
import {
  onDeleteNode,
  handleRefresh,
  setConfigMenuState,
  setLinkedAccount,
  setEventsConfig,
  onPortTextChange,
  enableTestReviewLoader,
  createAdditionalFields,
  addCustom,
  filterLinks,
  filterConfigs,
  onAppEventChange,
  setDelayConfigInNode,
} from "../../../store/slice/canvasSlice"
import ChildWindow_ from "./Ghelper"

export const NodeInner = memo(({ data, isConnectable }, i) => {
  const { editorState, canedit, gSheetPopUp } = useSelector(state => state.canvas.canvas)
  const { links, nodes } = editorState
  const initStateDelay = {
    delay_type: "",
    delay_unit: "",
    delay_value: "",
  }
  const dispatch = useDispatch()
  const [IsDeleteShown, setIsDeleteShown] = useState(false)
  const { t } = useTranslation()
  const [searchAccounts, setSearchAccounts] = useState("")
  const [searchEventConfigurations, setSearchEventConfigurations] = useState("")
  const [searchEvents, setSearchEvents] = useState("")
  const [nodeReviewed, setNodeReviewed] = useState(data.tested ? data.reviewed : null)
  const [testAndReviewLinked, setTestAndReviewLinked] = useState(false)
  const [fieldsData, setFieldsData] = useState({})
  const [delayConfig, setDelayConfig] = useState(data.delayConfigs ? data.delayConfigs : initStateDelay)
  const [reAuthorise, setReAuthorise] = useState(false)
  const [appUserId, setAppUserId] = useState(null)
  const [reAuth, setReAuth] = useState(true)
  const [tokenModal, setTokenModal] = useState(false)
  const [apiModal, setApiModal] = useState(false)
  const [selectedApp, setSelectedApp] = useState(null)
  const [nodeTested, setNodeTested] = useState(data.tested ? data.tested : null)
  const [openAdditionalConfigs, setOpenAdditionalConfigs] = useState(false)
  const [openGsheetModal, setOpenGsheetModal] = useState(false)
  const [openAdditionalParams, setOpenAdditionalParams] = useState(false)
  const [openDelayModal, setOpenDelayModal] = useState(false)
  const [paramKeys, setParamKeys] = useState([])
  const [addedKeys, setAddedKeys] = useState([])
  const [tokenFields, setTokenFields] = useState([])
  const [eventSequence, setEventSequence] = useState(null)
  const [tokenFieldsData, setTokenFieldsData] = useState([])
  const [show, setShow] = useState(false)
  const [showCustom, setShowCustom] = useState(false)
  const [arr, setArr] = useState([])
  const [appTypeToken, setAppTypeToken] = useState(false)
  const [value, setValue] = useState(false)

  const [flag, setFlag] = useState(true)

  const node = nodes.find(n => n.id === data.nodeid)
  const nodeIdx = nodes.findIndex(x => x.id === data.nodeid)
  const source = nodeIdx === 0 || nodes.length > 2
  const target = nodeIdx !== 0
  const debounce = func => {
    let timeout = null
    return function executedFunction(...args) {
      const later = () => {
        timeout = null
        func(...args)
      }

      clearTimeout(timeout)
      timeout = setTimeout(later, 1000)
    }
  }

  const saveLabel = debounce((id, val) => {
    dispatch(saveAuthLabel({ id: id, label: val }))
    dispatch(getLinkedAccounts(node))
  })

  const selectedTrigger = !data.appEventConfiguration
    ? t("konnect.sidebar.customize_form_partial")
    : data.appEventConfigurations.find(x => x.id === data.appEventConfiguration).service_name
  let hasSequence =
    data.appEventConfigurations && data.appEventConfigurations.filter(x => x.sequence !== 0).length ? true : false
  let configForms = []
  if (hasSequence) {
    configForms = data.appEventConfigurations

      .filter(x => x.type !== "custom")
      .slice(0, data.appEventSequences.length + 1)
  }

  let customConfigs = []
  if (hasSequence) {
    customConfigs = data.appEventConfigurations
      .filter(x => x.type === "custom")
      .slice(0, data.appEventSequences.length + 1)
  }
  const gsheetFields = data.konnect_custom
    ? data.konnect_custom.appDetails && data.konnect_custom.appDetails.fields
    : data.configResponsesOrig

  const closeGsheetModal = () => {
    setOpenGsheetModal(false)
  }

  const handleGsheetModal = () => {
    setTestAndReviewLinked(true)
    setOpenGsheetModal(true)
  }

  const prepareElements = (responses, additional) => {
    let formElements = []
    if (responses) {
      formElements = responses.map((x, idx) => {
        const portInfo = {
          node: data.nodeid,
          app: x.app_id,
          appEvent: x.app_event_id,
          port: x.id,
          label: x.label,
          id: x.id,
          config_key: x.config_key,
        }

        let ConnectorPort = x.key_value_type === "text" ? ConnectorPortText : ConnectorPortInput
        const __key = x.config_key
          .replace(/[^a-zA-Z ]/g, "")
          .split(" ")
          .join("_")

        if (nodeIdx === 0 || x.disabled) {
          //  disable = true
        }
        let Data = []

        let Link1
        let infoToShow
        if (x.label.includes("https")) {
          Link1 = x.label.slice(x.label.indexOf("<a"), x.label.indexOf("/a>") + 3)
          infoToShow = x.label.slice(x.label.indexOf("[") + 1, x.label.indexOf("]")).split(",")
        }
        return (
          <div key={idx}>
            <ConnectorPortLabel style={{ marginTop: "16px" }}>
              {/* {x.label} */}
              {x.label.includes("https") ? x.label.slice(0, x.label.indexOf("[")) : x.label}
              {x.config_key_required ? <span style={{ color: "#F74545" }}>*</span> : ""}
              {infoToShow ? (
                <span style={{ color: "#666699", fontSize: "11px", paddingBottom: "5px" }}>{infoToShow[0]} </span>
              ) : null}

              {<a dangerouslySetInnerHTML={{ __html: Link1 }}></a> ? (
                <span style={{ color: "red", fontSize: "11px" }}>
                  {<a dangerouslySetInnerHTML={{ __html: Link1 }}></a>}
                </span>
              ) : null}
              {/* {x.label.includes("https")?<a href={Link1} target='_blank'>{" "}link here</a>:null} */}
            </ConnectorPortLabel>
            <Flex
              sx={{
                flexDirection: "column",
              }}
            >
              <Flex key={idx}>
                {target && !x.additional ? (
                  <ConnectorPortOuter placement="left" type={x.key_value_type}>
                    <Handle
                      type="target"
                      position="left"
                      // isConnectable="true"
                      isConnectable={true}
                      style={{
                        height: "20px",
                        background: "#152f73",
                        width: "20px",
                        borderRadius: "60%",
                        border: "1px solid #152f73",
                      }}
                      id={x.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "target" })}
                    />
                  </ConnectorPortOuter>
                ) : null}
                <ConnectorPort
                  rows={4}
                  dirty={x.value ? false : !!x.dirty}
                  key={`${x.app_id}_${__key}`}
                  onMouseDown={e => {
                    e.stopPropagation()
                  }}
                  value={x.value ? x.value : ""}
                  placeholder={
                    !x.dirty
                      ? x.label.includes("https")
                        ? x.label.slice(0, x.label.indexOf("["))
                        : x.label
                      : `${x.label.slice(0, x.config_key.length)}*`
                  }
                  onChange={e => {
                    dispatch(
                      onPortTextChange({
                        data,
                        nodeId: data.nodeid,
                        portId: x.id,
                        config_key: x.config_key,
                        input: e.target.value,
                        eventSequence: eventSequence,
                      })
                    )
                    if (x.config_key.includes(">")) {
                      dispatch(
                        getAppEventConfigDetailCustom({
                          data,
                          nodeId: data.nodeid,
                          port: x.portId,
                          config_key: x.config_key,
                          input: e.target.value,
                          eventSequence,
                          node
                        })
                      )

                      if (
                        data.appEventConfigurations.findIndex(x => x.sequence === eventSequence) ===
                        data.appEventConfigurations.length - 1
                      ) {
                        dispatch(
                          getAppEventConfigDetailFetchuserDefined({
                            data,
                            nodeId: data.nodeid,
                            appId: data.id,
                            eventId: data.appEvent,

                            selectedEventConfigId: e.target.value,
                            selectedConfigKey: x.config_key,
                            app_account_id: data.selectedAccount.id,
                            input: e.target.value,
                            nodes: editorState.nodes,
                          })
                        )
                      }
                    }
                  }}
                  data={Data}
                  additional={x.additional}
                  appEventSequences
                ></ConnectorPort>

                <ConnectorPortOuter placement="right" type={x.key_value_type}>
                  <Handle
                    type="source"
                    position="right"
                    // isConnectable="true"
                    style={{
                      height: "20px",
                      background: "#152f73",
                      width: "20px",
                      borderRadius: "60%",
                      border: "1px solid #152f73",
                    }}
                    isConnectable={data.tested}
                    id={x.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "source" })}
                  />
                </ConnectorPortOuter>
              </Flex>
              <Flex
                sx={{
                  alignSelf: "end",
                  mt: "8px",
                }}
              >
                {x.error ? <ConnectorPortError>{x.error}</ConnectorPortError> : null}
              </Flex>
            </Flex>
          </div>
        )
      })
      return formElements
    }
  }

  let first1 = data.configResponses.filter(x => x.config_key_required === true)
  let second = data.configResponses.filter(x => !x.config_key_required & !x.additional)
  let third = data.configResponses.filter(x => x.config_key_type === "custom")
  let fourth = data.configResponses.filter(x => (x.additional === true) & (x.mapped === true))
  let formElements = prepareElements(data.configResponses)
  const publishAppScript = data => {
    let appDetails = {
      fields: data.data,
      isAndCondition: data.isAndCondition,
    }
    dispatch(
      appTestAndReview({
        data: node.data,
        nodeIdx: nodeIdx,
        editorState: editorState,
        appDetails: appDetails,
        dispatch,
        enableTestReviewLoader,
      })
    )
    setOpenGsheetModal(false)
  }

  const handleAdditionalConfigsClick = () => {
    setOpenAdditionalConfigs(true)
  }

  let formElements1 = formElements.filter(x => x.props.children[0].props.children[1]?.props?.children === "*")
  let formElements2 = formElements.filter(
    x => x.props.children[0].props.children[1] === "" && !x.props.children[0].props.children[0].includes(">")
  )
  let formElements3 = formElements.filter(x => x.props.children[0].props.children[1] === "custom")
  let impConfigs = []

  impConfigs = formElements2.filter(el => {
    return second.find(element => {
      return (
        (element.label.includes("https")
          ? element.label.slice(0, element.label.indexOf("["))
          : element.label === el.props.children[0].props.children[0]) &
        !el.props.children[1].props.children[0].props.children[1].props.additional
      )
    })
  })

  let cust = []
  cust = formElements.filter(el => {
    return third.find(element => {
      return element.label === el.props.children[0].props.children[0]
    })
  })

  const onCloseModal = list => {
    setOpenAdditionalConfigs(false)
    const selected = list.filter(x => x.selected)
    setAddedKeys(selected)
    dispatch(createAdditionalFields({ fields: selected, nodeId: data.nodeid, formElements }))
  }
  const onLinkedAccountChange = selected => {
    setNodeReviewed(null)
    setNodeTested(false)
    dispatch(setLinkedAccount({ selected, data }))
  }

  const createAdditionalParams = props => {
    const { fields } = props

    setArr(fields)
  }
  let res = []
  res =formElements1.length<5 && flag && data.editable===false? 
  formElements2.filter(el => {
    return impConfigs.slice(0,5-formElements1.length).find(element => {
      return (
        (element.props.children[0].props.children[0] === el.props.children[0].props.children[0]) &
        !el.props.children[1].props.children[0].props.children[1].props.additional
      )
    })
  })
  : formElements2.filter(el => {
    return arr.find(element => {
      return (
        (element.props.children[0].props.children[0] === el.props.children[0].props.children[0]) &
        !el.props.children[1].props.children[0].props.children[1].props.additional
      )
    })
  })
  let add = []
  add = formElements.filter(el => {
    return fourth.find(element => {
      return (
        (element.label === el.props.children[0].props.children[0]) &
        el.props.children[1].props.children[0].props.children[1].props.additional
      )
    })
  })
  const handleConditionsModal = () => {
    setOpenAdditionalParams(true)
  }
  const handleDelayModal = () => {
    setDelayConfig(delayConfig)
    setOpenDelayModal(true)
  }

  const onCloseDelayModal = props => {
    // setDelayConfig(delayConfig)
    setOpenDelayModal(false)
    dispatch(setDelayConfigInNode({ data: data, delayConfigs: props }))
  }
  const onCloseAdditionalConfigsModal = list => {
    setOpenAdditionalParams(false)
    // const selected = list.filter(x => x.selected)
    // setParamKeys(selected)
    // createAdditionalParams({ fields: selected, nodeId: data.nodeid })
   // setFlag(false)
  }
  const onCloseRightHandConfigsModal = list => {
    setOpenAdditionalParams(false)
    const selected = list.filter(x => x.selected)
    setParamKeys(selected)
    createAdditionalParams({ fields: selected, nodeId: data.nodeid })
    setFlag(false)
  }
  useEffect(() => {
    setNodeTested(data.tested ? data.tested : null)
    setNodeReviewed(data.tested ? data.reviewed : null)
    //setParamKeys([])
  }, [{ ...data }])
  useEffect(() => {
    // if(node.appType === "WEBHOOK") {
    const testReviewLinked = links ? links.filter(link => link.source === data.nodeid).length > 0 : false
    setTestAndReviewLinked(testReviewLinked)
    //}
  }, [links])

  useEffect(() => {
    setArr([])

    setParamKeys([])
  }, [data.selectedAccount, data.appEvent])

  const watcher = () => {
    if (popup === null) {
      clearInterval(timer)
      timer = null
    } else if (popup !== null && !popup.closed) {
      popup.focus()
    } else if (popup !== null && popup.closed) {
      clearInterval(timer)
      browser.focus()
      browser.onClose("child was closed")
      toast.info("App Connecttion process completed!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
      timer = null
      popup = null
    }
  }

  //place in css file
  const gridToggleInvalid = {
    width: "100%",
    gridGap: "5%",
    display: "grid",
    gridTemplateColumns: "45% 45%",
    marginLeft: "2%",
  }

  const gridToggleValid = {
    width: "90%",
    gridGap: "5%",
    display: "grid",
    gridTemplateColumns: "100%",
    marginLeft: "2%",
  }
  const checkData = data => {
    let flag = true
    for (let i = 0; i < tokenFieldsData.length; i++) {
      if (!fieldsData[tokenFieldsData[i].id] || fieldsData[tokenFieldsData[i].id].length == 0) {
        flag = false
        break
      }
    }
    return flag
  }

  const handleAppId = (id, re) => {
    localStorage.setItem("app_user_id", id)
    localStorage.setItem("re-auth", reAuth)
  }
  const saveTokenData = () => {
    if (fieldsData && checkData(fieldsData)) {
      dispatch(
        saveOauthData(
          reAuthorise
            ? {
                app_id: data.id,
                app_user_id: appUserId,
                auth_response: fieldsData,
              }
            : { app_id: data.id, auth_response: fieldsData }
        )
      ).then(res => {
        updateToast(dispatch, {
          message: reAuthorise ? "Re-Authhorsiation completed Successfully" : "Account Added Successfully",
          status: "success",
        })
        dispatch(getLinkedAccounts(node))
        setTokenModal(false)
        setApiModal(false)
        setFieldsData({})
        setSelectedApp(null)
      })
    } else {
      updateToast(dispatch, {
        message: res.payload.status,
        status: "error",
      })
      toast.error("Enter the missing data!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }
  }
  const launchTokenModal = app => {
    dispatch(
      getOauthData({
        app_id: app.id,
        redirect_url: OAUTH_REDIRECT_URL,
      })
    ).then(data => {
      // setTokenModal(true)

      setTokenFieldsData(data.payload.fields)
      setTokenFields(tokenFieldsData)
    })
  }

  const launchAuth = app => {
    /// launchTokenModal(app)
    if (tokenModal && apiModal) {
      setSelectedApp(app)
    }

    if (app.auth_type === "Token") {
      setAppTypeToken(true)
      launchTokenModal(app)
    } else {
      if (popup && !popup.closed) {
        popup.focus()
        return
      }

      const name = "OAuth Permissions"
      const opts = `dependent=${1}, alwaysOnTop=${1}, alwaysRaised=${1}, alwaysRaised=${1}, width=800, height=600`

      popup = browser.open(`${OAUTH_REDIRECT_URL}/${app.id}/${AuthTokenService.get()}`, name, opts)

      setTimeout(() => {
        popup.opener.onOpen("child was opened")
      }, 0)

      if (timer === null) {
        timer = setInterval(watcher, 2000)
      }
    }
  }

  const history1 = useHistory()

  let idd = history1.location.pathname.split("/").pop()
  let exImpConfigs = []
  if (!isNaN(idd) && flag && data.editable===true) {
    let impHandles = []
    if (links && links.length) {
      impHandles = links.map(e => {
        return JSON.parse(e.targetHandle.split("|")[1]), JSON.parse(e.targetHandle.split("|")[1])
      })
    }
    let newArray123 = data.configResponses.filter(
      x => x.value && !x.additional //&& x.config_key_required===false
    )
    let newArray1234=newArray123.filter(x=>x.config_key_required ?x.config_key_required===false: !x.config_key_required)

    let yy = []

    yy = fourth.filter(f => {
      return impHandles.find(q => {
        return q.label === f.label && q.node === data.nodeid
      })
    })

    res = formElements2.filter(el => {
      return newArray1234.find(element => {
        return (
          (element.label.includes("https")
            ? element.label.slice(0, element.label.indexOf("["))
            : element.label === el.props.children[0].props.children[0]) 
        )
      })
    })

    //let newArray1234 = data.configResponses.filter(x => x.value && x.additional === true)

    // add = formElements2.filter(el => {
    //   return yy.find(element => {
    //     return (
    //       (element.label.includes("https")
    //         ? element.label.slice(0, element.label.indexOf("["))
    //         : element.label === el.props.children[0].props.children[0])
    //     )
    //   })
    // })
  }
  //const hasDelay = node.type==="NodeInner"?true:false
  const hasRefresh = data.type === "API" || data.type === "WEBHOOK_API"
  useEffect(() => {
    if (!isNaN(idd) && flag) {
      let newList = data.configResponses.filter(
        x => x.value && x.additional !== true && x.config_key_required === false
      )

      let list1 = res.map(l => {
        return { ...l, selected: true }
      })
      setParamKeys(list1)
      if (add.length) {
        let newList = data.configResponses.filter(x => x.value && x.additional === true).map(d=>{return {...d,selected:true}})
       
        setAddedKeys(newList)
      }
    }
  }, [])
  return (
    <div key={i}>
      {data && data.konnect_custom && data.konnect_custom.script_id && gSheetPopUp === true && (
        <ChildWindow_ scriptId={data.konnect_custom.script_id} />
      )}
      <CommonOuter node={data} reviewed={nodeReviewed} nodeTested={nodeTested} />

      <NodeOuter
        onMouseEnter={() => setIsDeleteShown(true)}
        onMouseLeave={() => setIsDeleteShown(false)}
        isFirst={!nodeIdx}
        reviewed={nodeReviewed}
      >
        <AppInfo
          title={data.name}
          subtitle={data.account}
          image={data.image}
          className="DraggableHandle"
          background={data.background}
          hasDelete={IsDeleteShown && (nodeIdx !== 0 || nodes.length === 1)}
          onDelete={() => {
            if (data.tested || data.konnect_activity_id) {
              dispatch(
                onDeleteNodeV2({
                  konnect_id: nodes[0].data.konnect_id,
                  konnect_activity_id: data.konnect_activity_id,
                  canvas_json: editorState,
                  nodeId: data.nodeid,
                  delay_activity_id: data.delay_activity_id,
                })
              ).then(res => {
                dispatch(onDeleteNode(data))
              })
            } else {
              dispatch(onDeleteNode(data))
            }
          }}
          hasRefresh={hasRefresh && (nodeIdx !== 0 || nodes.length === 1)}
          // hasDelay={hasDelay}
          onDelay={handleDelayModal}
          onRefresh={() => {
            setTestAndReviewLinked(false)
            dispatch(handleRefresh(data))
          }}
          delayConfig={delayConfig}
        ></AppInfo>

        <div className="nowheel">
          {data.appEvents ? (
            <EventSelectionGroup>
              <SelectionHeader>
                {nodeIdx === 0 ? t("konnect.sidebar.choose_trigger") : t("konnect.sidebar.choose_action_event")}
              </SelectionHeader>
              <Dropdown
                searchable={true}
                searchValue={searchEvents}
                onFilterChange={text => {
                  setSearchEvents(text)
                }}
                inputPlaceholder={!searchEvents ? "Search field" : ""}
                disableIfEmpty
                headerTitle={t("konnect.sidebar.select_trigger_action_dropdown_header")}
                items={data.appEvents}
                renderKey="name"
                selected={
                  data.appEvent && data.appEvents.length ? data.appEvents.find(x => x.id === data.appEvent).name : null
                }
                onChange={selected => {
                  dispatch(onAppEventChange({ data, selected }))
                  setShow(true)

                  setSearchEvents("")
                  // setEvent(false)
                }}
                onChangeState={isOpen => {
                  dispatch(setConfigMenuState({ isOpen }))
                }}
              />
            </EventSelectionGroup>
          ) : null}
        </div>
        <div className="nowheel">
          {data.linkedAccounts && data.linkedAccounts.length === 0 && data.appEvent ? (
            <>
              {" "}
              <Flex
                sx={{
                  border: "1.5px solid #152f73",
                  borderRadius: "5px",
                  padding: "5px",
                  width: "94%",
                  margin: "10px",
                  justifyContent: "center",
                  color: "#152f73",
                }}
                onClick={() => {
                  setSelectedApp(true)
                  setApiModal(true)
                  launchAuth(data)
                }}
              >
                + {t("apps.addAccountText")}
              </Flex>
            </>
          ) : data.linkedAccounts && data.linkedAccounts.length >= 1 && data.appEvent ? (
            <EventSelectionGroup>
              <SelectionHeader>{t("konnect.sidebar.choose_linked_account")}</SelectionHeader>
              <Dropdown
                searchable={true}
                searchValue={searchAccounts}
                onFilterChange={text => {
                  setSearchAccounts(text)
                }}
                inputPlaceholder={!searchAccounts ? "Search field" : ""}
                headerTitle={t("konnect.sidebar.choose_linked_account")}
                items={data.linkedAccounts}
                renderKey="name"
                selected={data.selectedAccount ? data.selectedAccount.name : ""}
                onChange={selected => {
                  onLinkedAccountChange(selected)
                  setSearchAccounts("")
                  dispatch(
                    getAppEventConfig({
                      data,
                      nodeId: data.nodeid,
                      appId: data.id,
                      eventId: data.selectedEvent.id,
                      source: nodeIdx === 0,
                      type:
                        data.selectedEvent.side === "left"
                          ? "source"
                          : data.selectedEvent.side === "right"
                          ? "target"
                          : "both",
                      //  app_account_id: data.selectedAccount.id,
                    })
                  )
                }}
                setTokenModal={setTokenModal}
                onChangeState={isOpen => {
                  dispatch(setConfigMenuState({ isOpen }))
                }}
                selectedApp={selectedApp}
                setSelectedApp={setSelectedApp}
                tokenModal={tokenModal}
                addAccount={true}
              />
            </EventSelectionGroup>
          ) : null}
        </div>

        {data.selectedAccount ? (
          <>
            <EventSelectionGroup>
              {data.configResponses ? (
                <>
                  {(data.provider === "google_sheet" || data.provider === "google-form") && nodeIdx === 0 ? (
                    <Flex
                      sx={{
                        justifyContent: data.isloading ? "space-between" : "center",
                        gap: "10px",
                        marginTop: "20px",
                        mb: "20px",
                      }}
                    >
                      <TestAndReviewButton disabled={testAndReviewLinked} onClick={handleGsheetModal}>
                        {t("konnect.testAndReview.btnLabel")}
                      </TestAndReviewButton>
                      {data.isloading && (
                        <Preloader
                          width={{ _: 40, sm: 40, md: 44, lg: 48, xl: 52 }}
                          height={{ _: 40, sm: 40, md: 44, lg: 48, xl: 52 }}
                          loading={data.isloading}
                          position="static"
                        />
                      )}
                    </Flex>
                  ) : (
                    <Flex
                      sx={{
                        justifyContent: data.isloading ? "space-between" : "center",
                        gap: "10px",
                        marginTop: "20px",
                      }}
                    >
                      <TestAndReviewButton
                        disabled={testAndReviewLinked}
                        onClick={async () => {
                          setTestAndReviewLinked(true)
                          await dispatch(
                            appTestAndReview({
                              data,
                              nodeIdx: nodeIdx,
                              editorState: editorState,
                              delay_config: data.delayConfigs,
                              dispatch,
                              enableTestReviewLoader,
                            })
                          )
                          setTestAndReviewLinked(false)
                        }}
                      >
                        {t("konnect.testAndReview.btnLabel")}
                      </TestAndReviewButton>
                      {data.isloading && (
                        <Preloader
                          width={{ _: 40, sm: 40, md: 44, lg: 48, xl: 52 }}
                          height={{ _: 40, sm: 40, md: 44, lg: 48, xl: 52 }}
                          loading={data.isloading}
                          position="static"
                        />
                      )}
                    </Flex>
                  )}
                </>
              ) : null}
            </EventSelectionGroup>
            <EventSelectionGroup>
              <div className="nowheel">
                {hasSequence && data.appEvent && data.selectedAccount ? (
                  <EventSelectionGroup sx={{ mt: "10px", mb: "20px" }}>
                    {data.appEventConfigurations.map((x, i) => {
                      let trigger = data.appEventSequences.find(y => x.sequence === y.sequence)
                      const currSequenceIdx = data.appEventConfigurations.findIndex(y => y.sequence === x.sequence)
                      const prevSequence = data.appEventConfigurations[currSequenceIdx - 1]
                      let prevSeq = null
                      if (prevSequence) {
                        prevSeq = data.appEventSequences.find(c => c.sequence === prevSequence.sequence)
                      }
                      let title = trigger ? (
                        trigger.name
                      ) : x.config_key_required ? (
                        <>
                          <span style={{ color: "#F74545" }}>
                            <span style={{ color: "black" }}>{selectedTrigger + x.label}</span> *
                          </span>
                        </>
                      ) : (
                        `${selectedTrigger} ${x.label} `
                      )
                      return (
                        <Flex sx={{ mt: "10px" }}>
                          <Dropdown
                            searchable={true}
                            searchValue={searchEventConfigurations}
                            onFilterChange={text => {
                              setSearchEventConfigurations(text)
                            }}
                            inputPlaceholder={!searchEventConfigurations ? "Search field" : ""}
                            key={i}
                            index={i}
                            headerTitle={title}
                            items={data.appEventConfigurationDetails.filter(c => c.config_key === x.config_key)}
                            renderKey="name"
                            onOpen={() => {
                              const currSequenceIdx = data.appEventConfigurations.findIndex(
                                y => y.sequence === x.sequence
                              )
                              const prevSequence = data.appEventConfigurations[currSequenceIdx - 1]
                              let prevSeq = null
                              if (prevSequence) {
                                prevSeq = data.appEventSequences.find(c => c.sequence === prevSequence.sequence)
                              }
                              setShow(true)
                              dispatch(
                                getAppEventConfigDetail({
                                  data,
                                  nodeId: data.nodeid,
                                  appId: data.id,
                                  eventId: data.appEvent,
                                  eventConfigId: x.id,
                                  source: nodeIdx === 0,
                                  config_key: x.config_key,
                                  prevSequence: prevSeq,
                                  app_account_id: data.selectedAccount.id,
                                })
                              )
                              setEventSequence(x.sequence ? x.sequence : x.config_id) // setEventConfig(true)
                            }}
                            onChange={selected => {
                              dispatch(
                                setEventsConfig({
                                  data,
                                  nodeId: data.nodeid,
                                  appId: data.id,
                                  eventId: data.appEvent,
                                  eventConfigId: eventSequence,
                                  source: nodeIdx === 0,
                                  sequence: {
                                    sequence: eventSequence,
                                    id: selected.id,
                                    name: selected.name,
                                    config_key: selected.config_key,
                                  },
                                  selectedEventConfigId: selected.id,
                                  selectedEventConfigName: selected.name,
                                  eventSequence: eventSequence,
                                })
                              )

                              if (
                                data.appEventConfigurations.findIndex(x => x.sequence === eventSequence) ===
                                data.appEventConfigurations.length - 1
                              ) {
                                dispatch(
                                  getAppEventConfigDetailFetch({
                                    data,
                                    nodeId: data.nodeid,
                                    appId: data.id,
                                    eventId: data.appEvent,
                                    eventConfigId: selected.id,
                                    source: nodeIdx === 0,
                                    selectedEventConfigId: selected.id,
                                    selectedEventConfigName: selected.name,
                                    selectedConfigKey: selected.config_key,
                                    app_account_id: data.selectedAccount.id,
                                  })
                                )
                              }
                               ("")

                              // setEventConfig(false)
                            }}
                            onChangeState={isOpen => {
                              dispatch(setConfigMenuState({ isOpen }))
                            }}
                            inputFields={true}
                            OnClickOnInput={addCustom}
                            idToFunction={data}
                            setShowCustom={setShowCustom}
                            seq={currSequenceIdx}
                            eventSequence={eventSequence}
                          />
                        </Flex>
                      )
                    })}
                    {cust.map(r => r)}
                  </EventSelectionGroup>
                ) : null}
              </div>
            </EventSelectionGroup>
            <EventSelectionGroup>
              <div className="nowheel">
                {data.appEventSequences &&
                data.appEventSequences.length &&
                data.configResponses.filter(x => x.config_key_type === "custom").length ? (
                  <ParamsButton
                    onClick={() => {
                      setShowCustom(false)
                      dispatch(filterLinks(data))

                      dispatch(filterConfigs(data))
                    }}
                  >
                    Back to Configurations
                  </ParamsButton>
                ) : (
                  ""
                )}
              </div>
            </EventSelectionGroup>
            {nodeIdx !== 0 && formElements1 ? formElements1.map(r => r) : null}
            {nodeIdx !== 0 && res ? res.map(a => a) : null}

           
            {nodeIdx !== 0 && add ? add.map(a => a) : null}

          

            {nodeIdx === 0 ? formElements.map(f => f) : null}
          </>
        ) : null}
        <>
          {impConfigs && impConfigs.length && data.configResponses && data.selectedAccount && nodeIdx !== 0 ? (
            <ParamsButton style={{ marginTop: "10px" }} onClick={handleConditionsModal}>
              Select Additional Fields
            </ParamsButton>
          ) : (
            ""
          )}
        </>
      </NodeOuter>

      {data.reviewed && data.additionalResponses && data.additionalResponses.length ? (
        <AddMoreFieldButton onClick={handleAdditionalConfigsClick}></AddMoreFieldButton>
      ) : null}
      <Modal
        open={openAdditionalConfigs}
        onClose={onCloseModal}
        onAnimationEnd={() => scrollTo(0, 0)}
        center
        closeIcon={
          <Flex
            sx={{
              "&:hover": {
                transform: "scale(1.2)",
              },
              transition: "transform 0.25s",
              cursor: "pointer",
              width: ["20px", "22px", "24px", "26px"],
              fill: "red",
            }}
          >
            {svg.xmark}
          </Flex>}
        styles={{ modal: { padding: "0px", borderRadius: "16px", minWidth: "1100px", minHeight: "700px",overflow:"hidden"  } }}
      >
        <AdditionalConfigsModal
          closeModal={onCloseModal}
          addedKeys={addedKeys}
          data={data.additionalResponses}
          appIcon={data.image}
        />
      </Modal>
      <Modal
        open={openAdditionalParams}
        onClose={onCloseAdditionalConfigsModal}
        onAnimationEnd={() => scrollTo(0, 0)}
        center
        closeIcon={
          <Flex
            sx={{
              "&:hover": {
                transform: "scale(1.2)",
              },
              transition: "transform 0.25s",
              cursor: "pointer",
              width: ["20px", "22px", "24px", "26px"],
              fill: "red",
            }}
          >
            {svg.xmark}
          </Flex>}
        styles={{ modal: { padding: "0px", borderRadius: "16px", minWidth: "1100px", minHeight: "700px",overflow:"hidden" } }}
      >
        <RightHandConfigModal
          closeModal={onCloseRightHandConfigsModal}
          addedKeys={formElements1.length<5 && data.editable===false && flag?impConfigs.slice(0,5-formElements1.length):paramKeys}
          data={impConfigs}
          appIcon={data.image}
        />
      </Modal>
      <ReactModal
        isOpen={openDelayModal}
        onRequestClose={onCloseDelayModal}
        ariaHideApp={false}
        style={{
          overlay: { display: "flex", alignItems: "center", justifyContent: "center" },
          content: {
            top: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "initial",
            width: px2vw(500),
            minHeight: px2vw(300),
            maxHeight: px2vw(600),
            borderRadius: "8px",
            position: "relative",
          },
        }}
      >
        <DelayModal
          onCloseDelayModal={onCloseDelayModal}
          setOpenDelayModal={setOpenDelayModal}
          delayConfig={delayConfig}
          setDelayConfig={setDelayConfig}
        />
      </ReactModal>
      {openGsheetModal ? (
        <GsheetModal
          open={openGsheetModal}
          data={gsheetFields}
          isTested={data.konnect_custom ? true : false}
          publishAppScript={publishAppScript}
          onCloseModal={closeGsheetModal}
          translator={t}
        />
      ) : null}
      {selectedApp && (
        <ReactModal
          isOpen={tokenModal}
          parentSelector={() => document.querySelector("body")}
          onRequestClose={() => setTokenModal(false)}
          style={{
            overlay: { display: "flex", alignItems: "center", justifyContent: "center" },
            content: {
              top: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflow: "initial",
              width: px2vw(700),
              minHeight: px2vw(300),
              maxHeight: px2vw(600),
              borderRadius: "8px",
              position: "relative",
            },
          }}
        >
          <Flex
            sx={{
              width: "100%",
              alignItems: "center",
              flexDirection: "row",

              position: "relative",
            }}
          >
            <Flex sx={{ flexDirection: "row", alignItems: "center" }}>
              <Image width="18%" src={data.image} sx={{ boxShadow: "0px 0px 5px 1px #c1d2ff" }} />

              <Text
                fontSize={[24]}
                fontWeight="semi-bold"
                color="app.primary.30"
                textAlign="left"
                sx={{ p: 2.5, justifyContent: "left", textAlign: "left", ml: "10px" }}
              >
                {data.name}
              </Text>
              <Text
                sx={{
                  width: "16px",
                  height: "16px",
                  textAlign: "center",
                  fontSize: "10px",
                  borderRadius: "10px",
                  backgroundColor: "red",
                  color: "white",
                  marginLeft: "4px",
                }}
              >
                {data.linkedAccounts ? data.linkedAccounts.length : null}
              </Text>
            </Flex>
            <Box
              sx={{ cursor: "pointer", position: "absolute", right: "0" }}
              onClick={() => {
                setTokenModal(false)
                setSelectedApp(null)
                dispatch(getLinkedAccounts(node))
              }}
            >
              <Box sx={{ mt: "6px" }}>
                <Flex
                  sx={{
                    "&:hover": {
                      transform: "scale(1.2)",
                    },
                    transition: "transform 0.25s",
                    cursor: "pointer",
                    width: ["20px", "22px", "24px", "26px"],
                    fill: "red",
                  }}
                >
                  {svg.xmark}
                </Flex>
              </Box>
            </Box>
          </Flex>
          <Box sx={{ background: "#BCC6CC", width: "inherit", height: "1px", top: "170px", m: "10px -20px" }}></Box>
          <Flex
            sx={{ width: "100%", m: px2vw(30), flexDirection: "column", overflowY: "scroll", pr: "30px", gap: "20px" }}
          >
            {data &&
              data.linkedAccounts &&
              data.linkedAccounts.map((app, idx) => (
                <>
                  <AccountItem
                    index={idx + 1}
                    key={idx}
                    app={app}
                    updateAppStatus={(appId, status, parent) => {
                      dispatch(updateAppStatus({ appId: appId, status: status })).then(data => {
                        if (data.payload.message === "FAILURE") {
                          toast.info(data.payload.details, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                          })
                        } else {
                          dispatch(getLinkedAccounts(node))
                        }
                      })
                    }}
                    selectedApp={selectedApp}
                    setReAuthorise={setReAuthorise}
                    setApiModal={setApiModal}
                    setTokenModal={setTokenModal}
                    launchAuth={launchAuth}
                    data={data}
                    setAppUserId={setAppUserId}
                    setReAuth={setReAuth}
                    handleAppId={handleAppId}
                    reAuthorise={reAuthorise}
                    saveLabel={saveLabel}
                  />
                </>
              ))}
          </Flex>
          <Flex flexDirection="row" sx={{ justifyContent: "center" }}>
            <Button
              variant="primary"
              fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
              sx={{
                backgroundColor: "app.secondary.110",
                boxShadow: "none",
                fontWeight: "normal",
                cursor: "pointer",
                color: "app.primary.30",
                "&:hover": {
                  fill: "app.secondary.80",
                  color: "app.secondary.80",
                },
                display: "flex",
                // m: "20px",
                alignItems: "end",
              }}
              onClick={() => {
                localStorage.removeItem("re-auth")
                localStorage.removeItem("app_user_id")
                if (data.auth_type === "Token") {
                  setTokenModal(false)
                }

                setApiModal(true)
                setReAuthorise(false)
                setReAuth(false)
                //  setSelectedApp(null)
                launchAuth(data)
              }}
            >
              {" "}
              <Box sx={{ pt: "4px" }}>
                <PlusIcon />
              </Box>
              <Box sx={{ cursor: "pointer", ml: "10px", p: "4px", fontWeight: "500" }}> {t("apps.addAccountText")}</Box>
            </Button>
          </Flex>
        </ReactModal>
      )}

      {appTypeToken && (
        <ReactModal
          isOpen={apiModal}
          parentSelector={() => document.querySelector("body")}
          onRequestClose={() => {
            setApiModal(false)
            setTokenModal(false)
            setAppTypeToken(null)
            setSelectedApp(false)
          }}
          style={{
            overlay: { display: "flex", alignItems: "center", justifyContent: "center" },
            content: {
              maxHeight: px2vw(650),

              top: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              padding: "0px",
              alignItems: "center",
              borderRadius: "12px",
              position: "relative",
              overflowY: "auto",
            },
          }}
        >
          <Box sx={{ padding: "20px", width: "900px" }}>
            <Flex
              width="100%"
              onClick={() => {
                setTokenModal(false)
                setApiModal(false)
                //  setSelectedApp(null)
                setFieldsData({})
              }}
              sx={{ justifyContent: "space-between", cursor: "pointer" }}
            >
              <Flex sx={{ p: px2vw(1), justifyContent: "space-between", textAlign: "left" }}>
                <Image width="18%" src={data.image} sx={{ boxShadow: "0px 0px 5px 1px #c1d2ff" }} />

                <Text
                  fontSize={[24]}
                  fontWeight="semi-bold"
                  color="app.primary.30"
                  textAlign="left"
                  sx={{ p: 2.5, justifyContent: "left", textAlign: "left", ml: "10px" }}
                >
                  API Integration
                </Text>
                {/* <img width="20px" height="20px" src={CreateFolderImg} /> */}
              </Flex>
              <Box sx={{ mt: "6px" }}>
                <Flex
                  sx={{
                    "&:hover": {
                      transform: "scale(1.2)",
                    },
                    transition: "transform 0.25s",
                    cursor: "pointer",
                    width: ["20px", "22px", "24px", "26px"],
                    fill: "red",
                  }}
                >
                  {svg.xmark}
                </Flex>
              </Box>
            </Flex>

            <Box sx={{ background: "#BCC6CC", width: "inherit", height: "1px", top: "170px", m: "8px -20px" }}></Box>
            <Text
              sx={{
                fontSize: "h3",
                color: "app.primary.30",
                width: "80%",
                fontWeight: "normal",
                textAlign: "center",
                mt: "40px",
                mb: "40px",
                textAlign: "center",
                width: "50%",
                margin: "auto",
                padding: "35px",
              }}
            >
              {t("apps.accessAPIModalHeaderText1")} {data.name} {t("apps.accessAPIModalHeaderText2")}
            </Text>
            <div style={tokenFieldsData && tokenFieldsData.length > 2 ? gridToggleInvalid : gridToggleValid}>
              {tokenFieldsData &&
                tokenFieldsData.map((token, i) => (
                  <div key={i}>
                    <Text sx={{ fontSize: "lg", color: "app.primary.30" }}>
                      {token.name} <span style={{ color: "#E84539" }}>{t("apps.requiredText")}</span>
                    </Text>
                    <Box sx={{ display: "flex", border: "0px solid red", height: "50px" }}>
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          textAlign: "center",
                          fontSize: "10px",
                          borderRadius: "20px",
                          backgroundColor: "app.primary.70",
                          color: "app.secondary.80",
                          margin: "10px",
                          paddingTop: "4px",
                        }}
                      >
                        {" "}
                        <Key />
                      </Box>

                      <Box sx={{ width: "100%" }}>
                        <Input
                          type={token.name === "Password" ? "password" : "text"}
                          defaultValue={fieldsData[token.id]}
                          sxProps={{
                            "& ~ span": {
                              color: "#B7CBFF !important",
                              padding: "0px",
                              width: "80%",
                              fontSize: "12px",
                              border: "0px solid red",
                            },
                          }}
                          width="100%"
                          className="map"
                          placeholder={`Enter your ${token.name}`}
                          onChange={evt => setFieldsData({ ...fieldsData, [token.id]: evt.currentTarget.value })}
                        />
                      </Box>
                    </Box>
                    {token.instructions ? (
                      <div>Please follow below instructions to get API TOKEN</div>
                    ) : (
                      <Text className="text">
                        {t("apps.accessAPIModalMainText1")} {data.name} {t("apps.accessAPIModalMainText2")} {data.name}{" "}
                        {t("apps.accessAPIModalMainText3")}
                      </Text>
                    )}
                    {/* {token.instructions ? (
                      <Box sx={{ ml: "30px", p: "5px" }}>
                        <ul>
                          {eval(token.instructions).map(name => (
                            <li key={name}> {name} </li>
                          ))}
                        </ul>
                      </Box>
                    ) : null} */}
                        {token.instructions ? (
                    <Box sx={{ ml: "30px", p: "5px" }}>
                      <ul>
                        {eval(token.instructions).map(name => (
                          <li dangerouslySetInnerHTML={{ __html: name }}></li>
                          // <li key={name}> {name} </li>
                        ))}
                      </ul>
                    </Box>
                  ) : null}
                  </div>
                ))}
            </div>

            <Flex flexDirection="row" sx={{ justifyContent: "center" }}>
              <Button
                variant="primary"
                fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                sx={{
                  backgroundColor: "app.secondary.110",
                  boxShadow: "none",
                  fontWeight: "normal",
                  cursor: "pointer",
                  color: "app.primary.30",
                  "&:hover": {
                    fill: "app.secondary.80",
                    color: "app.secondary.80",
                  },
                  display: "flex",
                  // m: "45px 20px 20px 20px",
                  alignItems: "end",
                }}
                onClick={saveTokenData}
              >
                {" "}
                <Icon sx={{ cursor: "pointer", justifyContent: "center" }} icon={faCheckCircle} pb="3px" />
                <Box sx={{ cursor: "pointer", ml: "10px", p: "4px" }}> {t("apps.yesContinueText")}</Box>
              </Button>
            </Flex>
          </Box>
        </ReactModal>
      )}
    </div>
  )
})

export const AccountItem = ({
  app,
  updateAppStatus,
  setAppUserId,
  handleAppId,
  setReAuth,
  reAuthorise,
  selectedApp,
  saveLabel,
  index,
  setFetchData,
  setSelectedApp,
  setApiModal,
  data,
  setTokenModal,
  launchAuth,
  setReAuthorise,
}) => {
  const [editMode, setEditMode] = useState(false)
  const [label, setLabel] = useState(app.name)
  return (
    <Flex
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        gap: "20px",
      }}
      key={app.id}
    >
      <Flex width="70%" sx={{ gap: ["6px", "6px", "8px", "8px"] }}>
        <Flex
          sx={{
            width: ["16px", "18px", "20px", "22px"],
            height: ["16px", "18px", "20px", "22px"],
            textAlign: "center",
            m: "auto 0px",
            borderRadius: "10px",
            backgroundColor: "app.primary.70",
            color: "app.secondary.80",
          }}
        >
          <Text fontSize={{ _: 10, sm: 10, md: 12, lg: 12, xl: 14 }} sx={{ m: "auto" }}>
            {index}
          </Text>
        </Flex>
        {editMode ? (
          <Input
            width="340px"
            sxProps={{
              border: "1px solid #B7CBFF",
            }}
            marginLeft="7px"
            defaultValue={`${label}`}
            onChange={e => {
              setLabel(e.target.value)
              saveLabel(app.id, e.target.value)
            }}
          />
        ) : (
          <Text
            sx={{
              backgroundColor: "app.secondary.60",
              minWidth: "340px",
              border: "1px solid #b7cbff",
              p: "8px",
              borderRadius: "30px",
              marginRight: "23px",
            }}
          >
            {label}
          </Text>
        )}
      </Flex>

      <Flex width="30%" sx={{ justifyContent: "space-between" }}>
        {!editMode ? (
          <Flex
            onClick={() => {
              setEditMode(!editMode)
            }}
            data-tip={"Edit"}
            width={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
            height={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
            sx={{
              cursor: "pointer",
              "&:hover": {
                transform: "scale(1.2)",
              },
              transition: "transform 0.25s",
            }}
          >
            {svg.edit}
          </Flex>
        ) : (
          <Flex
            width={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
            height={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
            sx={{
              cursor: "pointer",
              "&:hover": {
                transform: "scale(1.2)",
              },
              transition: "transform 0.25s",
            }}
            onClick={() => {
              setEditMode(!editMode)
            }}
          >
            {svg.save}
          </Flex>
        )}
        {app.status === "DISCONNECTED" && (
          <>
            <Flex
              data-tip="Connect"
              onClick={() => updateAppStatus(app.id, "CONNECTED", selectedApp.id)}
              width={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
              height={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  transform: "scale(1.2)",
                },
                transition: "transform 0.25s",
              }}
            >
              {svg.edit}
            </Flex>
            <ReactTooltip />
          </>
        )}
        {app.status === "CONNECTED" && (
          <>
            <Flex
              onClick={() => {
                setReAuth(true)
                setReAuthorise(true)
                setApiModal(true)
                setTokenModal(false)
                // setSelectedApp(null)
                launchAuth(data)
                setAppUserId(app.id)
                handleAppId(app.id, reAuthorise)
              }}
              data-tip="Re-authorise"
              width={{ _: 38, sm: 38, md: 41, lg: 44, xl: 47 }}
              height={{ _: 38, sm: 38, md: 41, lg: 44, xl: 47 }}
              sx={{
                cursor: "pointer",
                fill: "#1e419d",
                "&:hover": {
                  transform: "scale(1.2)",
                },
                transition: "transform 0.25s",
              }}
            >
              {svg.sync}
            </Flex>

            <ReactTooltip />
          </>
        )}
        <Flex
          onClick={() => updateAppStatus(app.id, "REMOVE", selectedApp.id)}
          width={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
          height={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
          data-tip="Remove"
          sx={{
            cursor: "pointer",
            "&:hover": {
              transform: "scale(1.2)",
            },
            transition: "transform 0.25s",
          }}
        >
          {svg.delete}
        </Flex>
        <ReactTooltip />
      </Flex>
    </Flex>
  )
}
