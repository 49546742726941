import { v4 } from "uuid"
// export const FlattenJSON = json => {
//   let newData = {}
//   const extract = (obj, parentKey = []) => {
//     Object.keys(obj).map(key => {
//       if (typeof obj[key] === "object") {
//         const k = `${[...parentKey, key].join(".$")}`
//         newData[k] = {
//           id: k,
//           type: "node",
//           parent: parentKey,
//           level: parentKey.length,
//           items: { ...obj[key] },
//           k,
//           children: Object.keys(obj[key]).map(z => `${[k, z].join(".$")}`),
//           isOpen: false,
//           isAvailable: false,
//         }

//         extract(obj[key], [...parentKey, key])
//       } else {
//         const k = `${[...parentKey, key].join(".$")}`
//         newData[k] = {
//           id: key,

//           label: key,
//           value: obj[key],
//           config_key: k,
//           type: "leaf",
//           parent: `${[...parentKey].join(".$")}`,
//           level: parentKey.length,
//           isOpen: false,
//         }
//       }
//       return false
//     })
//   }
//   extract(json)
//   console.log(newData)
//   return newData
// }

export const FlattenJSON = json => {
  let newData = []
  const extract = (obj, parentKey = []) => {
    if (!obj) return
    Object.keys(obj).map(key => {
      const __key = v4()
      if (typeof obj[key] === "object") {
        const k = `${[...parentKey, key].join(".$")}`
        const _parentKey = parentKey.map(x => `${x[0].toUpperCase()}${x.slice(1)}`)
        const nodeLabel = `${[..._parentKey, key[0] ? `${key[0].toUpperCase()}${key.slice(1)}` : 'Undefined'].join(" > ")}`
        obj[key] &&
          newData.push({
            id: k,
            type: "node",
            parent: parentKey,
            label: nodeLabel,
            level: parentKey.length,
            items: { ...obj[key] },
            k,
            children: Object.keys(obj[key]).map(z => `${[k, z].join(".$")}`),
            isOpen: false,
            isAvailable: false,
            uiKey: __key,
          })

        extract(obj[key], [...parentKey, key])
      } else {
        const k = `${[...parentKey, key].join(".$")}`

        newData.push({
          id: k,
          label: key[0] ? `${key[0]?.toUpperCase()}${key?.slice(1)}` : 'Undefined',
          value: obj[key],
          config_key: k,
          type: "leaf",
          parent: `${[...parentKey].join(".$")}`,
          level: parentKey.length,
          isOpen: false,
          uiKey: __key,
        })
      }
      return false
    })
  }
  extract(json)

  return newData
}
