/**
 * Redux store setup.
 * Includes the axios config.
 */
import axios from "axios"
import axiosMiddleware from "redux-axios-middleware"
import thunk from "redux-thunk"
import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import rootReducer from "./reducers"
import { fromJS } from "immutable"
import AuthTokenService from "src/utils/AuthTokenService"
// Axios middleware interceptors to handle api success/error globally
const axiosMiddlewareOptions = {
  interceptors: {
    request: [
      function({ getState, dispatch, getSourceAction }, config) {
        config.headers.Authorization = `Bearer ${AuthTokenService.get()}`
        return config
      },
    ],
    response: [
      {
        success: ({ getState, dispatch, getSourceAction }, res) => {
          return res.data
        },
        error: ({ getState, dispatch, getSourceAction }, error) => {
          return Promise.reject(error)
        },
      },
    ],
  },
}

// Axios setup
const client = axios.create({
  baseURL: process.env.API_BASE_URL,
  responseType: "json",
})

const mockClient = axios.create({
  baseURL: "http://localhost:4004/",
  responseType: "json",
})

const initialState = fromJS({})

// Redux-Store initialisation
const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk, axiosMiddleware(client, axiosMiddlewareOptions)))
)

export default store
