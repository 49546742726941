/**
 * Task logs page.
 */
import React, { useState } from "react"
import { Box, Text, Flex, Link, Button, Image } from "rebass"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw"
import { faTimes, faSearch, faExpand } from "@fortawesome/free-solid-svg-icons"
import { Input } from "src/components"
import { TaskListItem } from "src/components/History/taskListItem"
import DateRangePicker from "@wojtekmaj/react-daterange-picker"
import StyledLayer from "./datePicker"
import NoTasksFoundImg from "assets/images/no-tasks-found.png"

import { Icon, DecoratorIcon } from "../../components/Icon/Icon"
import { faTimesCircle, faCheckCircle, faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import SelectSearch, { useSelect } from "react-select-search"
import styled from "@emotion/styled"
import Cross from "../../assets/images/ErrorInHistory.svg"
import Success from "../../assets/images/SuccessInHistory.svg"
import "./style.css"
import InfiniteScroll from "react-infinite-scroll-component"
import { svg } from "../ReactFlow/asset/svg"
import { svg_1 } from "../../assets/images/svg"
const SearchStyles = styled.div`
  .select-search {
    position: relative;
    border: 1px solid #aaaaaa;
    height: 49px;
    .select-search__select {
      position: absolute;
      z-index: 1001;
    }
  }
`

const option = [
  {
    key: 1,
    label: "Success",
    content: "success",
    icon: svg.success,
  },
  {
    key: 2,
    label: "Error",
    content: "error",
    icon: svg.error,
  },
]

export const HistoryView = ({
  loading,
  tasksList,
  taskDetails,
  setExpandTask,
  expandedItem,
  onDateRange,
  dateRange,
  historyType,
  setHistoryType,
  searchApp,
  setSelectedApp,
  selectedApp,
  fetchMore,
  logsList,
  setTaskLogs,
  fetchMoreLogs,
  setPageIndex,
  setTaskDetailsId,
  taskDetail,
  taskLogs,
  logsPageIndex,
  setLogsPageIndex,
  setSearchValue,
  setKonnectId,
}) => {
  const { t } = useTranslation()
  const [activeOption, setActiveOption] = useState(1)
  const [options, setOptions] = useState([])
  const getOptions = query => {
    return new Promise((resolve, reject) => {
      searchApp(query).then(res => {
        if (res.type === "QUERY_APP_SUCCESS") {
          const apps = []
          res.payload.map(category => {
            const appsList = []
            category[0].apps.map(app => {
              apps.push({ name: app.name, value: app.id })
            })
          })

          resolve(apps)
        } else {
          reject(false)
        }
      })
    })
  }
  const [snapshot, valueProps, optionProps] = useSelect({
    options,
    value: selectedApp,
  })
  return (
    <>
      <Flex
        flexDirection="column"
        height="90%"
        width="100%"
        p={{ _: 20, sm: 20, md: 22, lg: 24, xl: 24 }}
        sx={{
          backgroundColor: "#F3F7FF",
        }}
      >
        <Flex
          p={{ _: 14, sm: 14, md: 16, lg: 18, xl: 20 }}
          sx={{
            flexDirection: "column",
            height: "100%",
            width: "100%",
            boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
            backgroundColor: "#fff",
            borderRadius: "16px",
            gap: [14, 16, 18, 20],
          }}
        >
          <Flex sx={{ justifyContent: "space-between", width: "100%" }}>
            <Flex width="20%">
              {option.map((option, i) => {
                return (
                  <Flex
                    className="button"
                    key={i}
                    onClick={() => {
                      setActiveOption(option.key)
                      setHistoryType(option.content)
                      setPageIndex(option.key)
                    }}
                    fontSize={{ _: 16, sm: 16, md: 18, lg: 20, xl: 22 }}
                    sx={{
                      cursor: "pointer",
                      transition: "all 0.5s ease",
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "0px",
                      backgroundColor: "inherit",
                      borderBottom:
                        activeOption === option.key && option.key === 1
                          ? "3px solid #F7C545"
                          : activeOption === option.key && option.key === 2
                          ? "3px solid red"
                          : "3px solid #fff",
                      color:
                        activeOption === option.key && option.key === 1
                          ? "#F7C545"
                          : activeOption === option.key && option.key === 2
                          ? "red"
                          : "#B8BBC1",
                      fill:
                        activeOption === option.key && option.key === 1
                          ? "#F7C545"
                          : activeOption === option.key && option.key === 2
                          ? "red"
                          : "#B8BBC1",
                    }}
                  >
                    <Flex
                      fontSize={{ _: 18, sm: 18, md: 20, lg: 22, xl: 22 }}
                      sx={{ m: "auto", gap: ["6px", "6px", "8px", "10px"] }}
                    >
                      <Flex width={{ _: 16, sm: 16, md: 18, lg: 20, xl: 22 }}> {option.icon}</Flex>
                      {option.label} 
                    </Flex>{" "}
                  </Flex>
                )
              })}
            </Flex>
            <Flex width="80%" sx={{ justifyContent: "flex-end", alignItems: "center" }}>
              <Input
                width={{ _: "300px", sm: "300px", md: "340px", lg: "400px", xl: "475px" }}
                sxProps={{
                  borderRadius: "12px",
                  border: "1px solid #B7CBFF",
                  bg: "#edf2ff",
                  "& ~ span": { color: "gray !important" },
                }}
                // defaultValue={searchValue}
                hideAdditional={true}
                iconLeft={faSearch}
                onChange={(e) =>setSearchValue(e.target.value)}
                placeholder="Search Konnectz"
              />
            </Flex>
          </Flex>
          <Flex sx={{ position: "relative", width: "100%", height: "100%" }}>
            <Flex
              id="scrollableDiv1"
              sx={{
                position: "absolute",
                top: "0px",
                bottom: "0px",
                right: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                gap: [14, 16, 18, 20],
                overflowY: "auto",
                p: "3px",
              }}
            >
              {tasksList.length === 0 ? (
                <Flex
                  flexDirection="column"
                  sx={{ m: "auto", height: "100%", alignItems: "center", justifyContent: "center" }}
                >
                  <Flex
                    flexDirection="column"
                    sx={{ width: "424px", height: "221px", backgroundColor: "app.secondary.60", alignItems: "center" }}
                  >
                    <Box sx={{ mt: "68px" }}>
                      <img width="36" height="36" src={NoTasksFoundImg} />
                    </Box>
                    <Box sx={{ mt: "42px" }}>
                      <Text
                        fontSize={{ _: 12, sm: 12, md: 12, lg: 14, xl: 16 }}
                        sx={{ fontWeight: "500", color: "#000" }}
                      >
                        {t("header.notask")}
                      </Text>
                    </Box>
                    <Box sx={{}}>
                      <Text
                        fontSize={{ _: 11, sm: 11, md: 11, lg: 12, xl: 14 }}
                        sx={{ fontWeight: "500", color: "#AAA" }}
                      >
                        {t("header.tryagain")}
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              ) : (
                <InfiniteScroll
                  style={{ display: "flex", flexDirection: "column", gap: "20px", padding: "3px" }}
                  dataLength={tasksList.length || 0} //This is important field to render the next data
                  next={fetchMore}
                  hasMore={true}
                  pullDownToRefresh={false}
                  scrollableTarget="scrollableDiv1"
                  loader={
                    !loading && (
                      // !searchValue && (
                      <Flex
                        width={{ _: 32, sm: 32, md: 34, lg: 38, xl: 45 }}
                        height={{ _: 32, sm: 32, md: 34, lg: 38, xl: 45 }}
                        sx={{
                          cursor: "pointer",
                          fill: "#152f73",
                          m: "20px auto",
                          animation: "rotation 2s infinite linear",
                        }}
                      >
                        {svg.load}
                      </Flex>
                    )
                    // )
                  }
                >
                  {tasksList.map((task, idx) => (
                    <Flex flexDirection="column" sx={{ mt: "0px" }}>
                    <TaskListItem
                      key={task.id + "-" + idx}
                      setKonnectId={setKonnectId}
                      task={task}
                      setTaskLogs={setTaskLogs}
                      logsList={logsList}
                      fetchMoreLogs={fetchMoreLogs}
                      historyType={historyType}
                      setHistoryType={setHistoryType}
                      taskDetails={taskDetails}
                      setExpandTask={setExpandTask}
                      taskDetail={taskDetail}
                      expandedItem={expandedItem}
                      setTaskDetailsId={setTaskDetailsId}
                      taskLogs={taskLogs}
                      logsPageIndex={logsPageIndex}
                      setLogsPageIndex={setLogsPageIndex}
    
                    />
                  </Flex>
                  ))}
                </InfiniteScroll>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}





