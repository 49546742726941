import * as React from "react"
import * as ReactDOM from "react-dom"
import Draggable from "react-draggable"
import ResizeObserver from "react-resize-observer"

import { noop } from "../"
import { NodeDefault } from "../../../UI"

import styled from "@emotion/styled"

export const NodeWrapper = ({
  config,
  node,
  chart,
  onDragNodeStart,
  onDragNode,
  onDragNodeStop,
  onNodeClick,
  isSelected,
  Component = NodeDefault,
  onNodeSizeChange,
  onNodeMouseEnter,
  onNodeMouseLeave,
  NodeInner,
  Links,
  offset,
  selected,
  selectedLink,
  hovered,
  hoveredLink,
  onPortPositionChange,
  onLinkStart,
  onLinkMove,
  onLinkComplete,
  onLinkCancel,
  editorActions,
  testAndReviewLoader
}) => {
  const [size, setSize] = React.useState({ width: 0, height: 0 })

  const isDragging = React.useRef(false)

  const onStart = React.useCallback(
    (event, data) => {
      // Stop propagation so the canvas does not move
      event.stopPropagation()
      isDragging.current = false

      onDragNodeStart({ event, data, nodeId: node.nodeId })
    },
    [onDragNodeStart, node.nodeId]
  )

  const onDrag = React.useCallback(
    (event, data) => {
      isDragging.current = true
      onDragNode({ event, data, nodeId: node.nodeId })
    },
    [onDragNode, node.nodeId]
  )

  const onStop = React.useCallback(
    (event, data) => {
      isDragging.current = false
      onDragNodeStop({ config, event, data, nodeId: node.nodeId })
    },
    [onDragNodeStop, node.nodeId]
  )

  const onClick = React.useCallback(
    e => {
      if (!config.readonly) {
        e.stopPropagation()
        if (!isDragging.current) {
          onNodeClick({ nodeId: node.nodeId })
        }
      }
     // console.log("clicked")
    },
    [config, node.nodeId]
  )

  const onMouseEnter = React.useCallback(() => {
    onNodeMouseEnter({ nodeId: node.nodeId })
   // console.log("mouse is entered")
  }, [config, node.nodeId])

  const onMouseLeave = React.useCallback(() => {
    onNodeMouseLeave({ nodeId: node.nodeId })
   // console.log("mouse is leaved")
  }, [config, node.nodeId])

  const compRef = React.useRef(null)

  // TODO: probably should add an observer to track node component size changes
  React.useLayoutEffect(() => {
    const el = ReactDOM.findDOMNode(compRef.current)

    if (el) {
      if ((node.size && node.size.width) !== el.offsetWidth || (node.size && node.size.height) !== el.offsetHeight) {
        const newSize = { width: el.offsetWidth, height: el.offsetHeight }
        setSize(newSize)
        onNodeSizeChange({ config, nodeId: node.nodeId, size: newSize })
      }
    }
  }, [node, compRef.current, size.width, size.height])

  const children = (  
    <>
      <ResizeObserver
        onResize={rect => {
          const newSize = { width: rect.width, height: rect.height }
          setSize(newSize)
        }}
      />
      <NodeInner
        node={node}
        config={config}
        editorActions={editorActions}
        chart={chart}
        offset={offset}
        selected={selected}
        selectedLink={selectedLink}
        hoveredLink={hoveredLink}
        hovered={hovered}
        onPortPositionChange={onPortPositionChange}
        onLinkStart={config.readonly ? noop : onLinkStart}
        onLinkMove={config.readonly ? noop : onLinkMove}
        onLinkComplete={onLinkComplete}
        onLinkCancel={onLinkCancel}
        Links={Links}
        testAndReviewLoader = {testAndReviewLoader}
      />
    </>
  )

  return (
    <Draggable
      bounds="parent"
      axis="both"
      position={node.position}
      grid={[1, 1]}
      onStart={onStart}
      onDrag={onDrag}
      onStop={onStop}
      disabled={config.readonly}
      // handle=".DraggableHandle"
    >
      <Component
        config={config}
        ref={compRef}
        children={children}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        isSelected={isSelected}
        node={node}
      />
    </Draggable>
  )
}
