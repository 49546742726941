import React, { useState } from "react"
import { Box, Flex, Text, Image } from "rebass"
import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { size } from "./dimensions"
import { colors } from "src/theme/colors"
import px2vw from "utils/px2vw"
//import style from "../../../node_modules/dom-helpers/cjs/css"
import { faPlay, faPlus, faTrash, faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons"
import { DecoratorIcon } from "src/components"
import { Icon } from "src/components"
import DeleteIcon from "src/assets/images/delete-node"
import { MentionInput } from "../MentionInput/index"
import { useTranslation } from "react-i18next"
import { ClockCircleOutlined, ClockCircleTwoTone, ReloadOutlined } from "@ant-design/icons"
export const KonnectWrapper = styled(Flex)`
  height: 100%;
  background: ${colors.konnectEditor.editor.background};
  padding-top: initial;
  & > *::selection {
    color: red;
    background: black;
  }
  //overflow: hidden;
`

export const Konnectheader = styled(Flex)`
  // height: 14%;
  background: ${colors.konnectEditor.editor.header.background};
 justify-content: flex-end;
  // padding-right: ${size.header.paddingSide};
  // padding-left: ${size.header.paddingSide};
  // padding-top: ${size.header.paddingSide};
  // padding-bottom: ${size.header.paddingTop};
  // justify-content: space-between;
  align-items: center;
  &::selection {
    color: red;
    background: black;
  }
`

export const EditorWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  overflow: auto;
  background: #f3f7ff;
`
const EditorPlaceholderOuter = styled.div`
  height: 100vh;
  width: 100vw;
  background: #f3f7ff;
  display: flex;
  align-items: center;
  justify-content: center;
`

const EditorPlaceholderInnerDashed = styled.div`
  height: ${size.editor.placeHolder.heightD};
  width: ${size.editor.placeHolder.widthD};
  border: 4px dashed #ffffff;
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const EditorPlaceholderInner = styled.div`
  height: ${size.editor.placeHolder.height};
  width: ${size.editor.placeHolder.width};
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const EditorPlaceholder = () => (
  <EditorPlaceholderOuter>
    <EditorPlaceholderInnerDashed>
      <EditorPlaceholderInner>
        <DecoratorIcon icon={faPlus} size={28} iconColor="#F3F7FF"></DecoratorIcon>
      </EditorPlaceholderInner>
    </EditorPlaceholderInnerDashed>
  </EditorPlaceholderOuter>
)
export const SidebarHeader = styled(Flex)`
  justify-content: space-between;
  padding: 20px 0px 20px 20px;
  position: relative;
  background: ${colors.konnectEditor.sidebar.background};
  width: ${size.sidebar.width};
`
export const SidebarWrapper = styled(Flex)`
  // height: 90%;
  width: ${size.sidebar.width};
  background: ${colors.konnectEditor.sidebar.background};
  padding: ${size.sidebar.padding};
  flex-direction: column;
  display: ${props => (props.hidden ? "none" : "block")};
  // position: absolute;
  z-index: 100;
  overflow-y: auto;
`
export const BackButton = styled(Flex)`
  gap: 15px;
  align-items: center;
  cursor: pointer;
`
export const BackButtonContainer = styled(Flex)`
  width: ${size.sidebar.width};
`

export const SearchWrapper = styled(Flex)`
  position: relative;
  height: ${size.sidebar.search.height};
  width: ${size.sidebar.swidth};
  background: ${colors.konnectEditor.sidebar.search.background};
  border-radius: 16px;
  align-items: center;
  padding: 13px;
`

export const IconWrapper = styled(FontAwesomeIcon)`
margin: auto;
  height: ${size.sidebar.search.icon.size};
  z-index: 3,
  width: ${size.sidebar.search.icon.size};
  // color: ${colors.konnectEditor.sidebar.search.icon};
`

export const EllipsisIconWrapper = styled(FontAwesomeIcon)`
  height: ${size.appsList.search.icon.height};
  width: ${size.appsList.search.icon.height};
  color: "#000";
`

export const SearchFilter = styled(Box)`
  height: ${size.appsList.search.height};
  width: 100%;
  background: ${colors.konnectEditor.sidebar.searchFilter};
  border-radius: 16px;
`

export const SearchInput = styled.input`
  height: 100%;
  width:100%;
  outline: none;
  border: 0px;
  background: #f3f7ff;
  font-size: ${size.sidebar.search.fontSize};
  line-height: ${size.sidebar.search.lineHeight};
  font-weight: 500;
  padding-left: ${size.appsList.search.padding};
  // padding-right: ${size.appsList.search.padding};
  color: ${colors.konnectEditor.sidebar.search.text};
  &::placeholder {
    color: ${colors.konnectEditor.sidebar.search.icon};
  }
`

const AppsListOuter = styled(Flex)`
  flex: 1;
  width: 100%;
  background: ${colors.konnectEditor.sidebar.list};
  margin-top: 13px;
  flex-wrap: wrap;
`

export const AppsListHeader = styled(Text)`
  font-size: ${px2vw(18)};
  line-height: ${px2vw(27)};
  margin-top: ${px2vw(20)};
  font-style: normal;
  font-weight: 500;
`

export const AppsListWrapper = ({ name, children }) => (
  <>
    <AppsListHeader>{name}</AppsListHeader>
    <AppsListOuter>{children}</AppsListOuter>
  </>
)

export const AppWrapper = styled.div`
  display: flex;
  position: relative;
  height: ${size.sidebar.list.tile.height};
  width: ${size.sidebar.list.tile.height};
  background: ${colors.konnectEditor.sidebar.appTile.background};
  right: ${size.appsList.search.padding};
  // box-shadow: ${colors.konnectEditor.sidebar.appTile.boxShadow};
  border-radius: ${px2vw(12)};
  margin-top: ${px2vw(4)};
  margin-bottom: ${px2vw(4)};
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 17%;
  margin-left: ${px2vw(4)};
  margin-right: ${px2vw(4)};
`

export const AppImage = styled.img`
  height: 100%;
  width: 100%;
`

const AppTileBadgeOuter = styled.div`
  position: absolute;
  background: ${colors.konnectEditor.sidebar.appTile.badge.background};
  height: ${size.sidebar.list.badge.size};
  width: ${size.sidebar.list.badge.size};
  border-radius: ${size.sidebar.list.badge.borderRadius};
  top: ${px2vw(-5)};
  right: ${px2vw(-5)};
  display: flex;
  align-item: center;
  justify-content: center;
  align-items: center;
`

const AppTileBadgeInner = styled.span`
  color: ${colors.konnectEditor.sidebar.appTile.badge.foreground};
`

const AppBetaBadgeOuter = styled.div`
  position: absolute;
  background: #2196F3;
  // height: ${size.sidebar.list.badge.size};
  // width: ${size.sidebar.list.badge.size};
  border-top-left-radius: ${size.sidebar.list.badge.borderRadius};
  border-bottom-right-radius: ${size.sidebar.list.badge.borderRadius};
  top: ${px2vw(3)};
  left: ${px2vw(3)};
  display: flex;
  align-item: center;
  justify-content: center;
  align-items: center;
  padding-left:${px2vw(5)};
  padding-right:${px2vw(5)};
  padding-top:${px2vw(2)};
  padding-bottom:${px2vw(2)};
`

const AppBetaBadgeInner = styled.span`
  color: ${colors.konnectEditor.sidebar.appTile.badge.foreground};
  font-size: ${px2vw(8)};
`

export const AppItem = ({
  image,
  name,
  id,
  background = "#e9f7ec",
  badge = null,
  last = false,
  beta = false,
  ...props
}) => {
  const { t } = useTranslation("translation")
  return (
    <Flex
      px={3}
      py={3}
      width={1 / 3}
      sx={{ flexDirection: "column", textAlign: "-webkit-center", alignItems: "center", zIndex: 1 }}
    >
      <Flex
        height={{ _: "50px", sm: "50px", md: "50px", lg: "60px", xl: "60px" }}
        width={{ _: "50px", sm: "50px", md: "50px", lg: "60px", xl: "60px" }}
        sx={{
          textAlign: "center",
          m: "auto 0px",
          borderRadius: 12,
          backgroundColor: "#FFF",
          cursor: "pointer",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          "&:hover": {
            transform: "scale(1.1)",
          },
          padding: 0,
        }}
      >
        {badge && badge > 1 ? (
          <Flex
            width={{ _: 12, sm: 12, md: 14, lg: 16, xl: 20 }}
            height={{ _: 12, sm: 12, md: 14, lg: 16, xl: 20 }}
            fontSize={{ _: 5, sm: 5, md: 6, lg: 7, xl: 8 }}
            sx={{
              borderRadius: "100%",
              position: "absolute",
              top: "-5px",
              right: "-5px",
              background: "#F74545",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
            }}
          >
            {badge}
          </Flex>
        ) : null}

        {beta ? (
          <Flex
            fontSize={{ _: 5, sm: 5, md: 6, lg: 7, xl: 8 }}
            sx={{
              borderTopLeftRadius: "35%",
              borderBottomRightRadius: "35%",
              position: "absolute",
              top: "0px",
              left: "0px",
              background: "#2196F3",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              paddingLeft: px2vw(2),
              paddingRight: px2vw(2),
              paddingTop: px2vw(2),
              paddingBottom: px2vw(2),
            }}
          >
            {t("konnect.UI.betaText")}
          </Flex>
        ) : null}
        <AppImage src={image} {...props} last={last} />
      </Flex>
      <Text
        // title={name}
        fontSize={{ _: 6, sm: 6, md: 8, lg: 10, xl: 10 }}
        sx={{ mt: "5px", fontWeight: "500", color: "#444444" }}
      >
        {name.length > 10 ? name.substring(0, 10) + ".." : name}
      </Text>
    </Flex>

    // </AppWrapper>
  )
}

export const AppTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: ${size.editor.nodeTitle.fontSize};
  line-height: ${size.editor.nodeTitle.lineHeight};
`

export const NodeInfoImageWrapper = styled.div`
  // background: ${props => props.background};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${size.editor.node.info.iconPlaceholder};
  width: ${size.editor.node.info.iconPlaceholder};
  border-radius: 4px;
`

export const NodeDeleteWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${size.editor.node.info.iconPlaceholder};
  width: ${size.editor.node.info.iconPlaceholder};
  border-radius: 8px;
  border: 1px solid #b7cbff;
  &:hover {
    border: 1px solid #152f73;
    svg: {
      path: {
        fill: #152f73;
      }
    }
  }
`

export const NodeInfoImage = styled.img`
  height: 100%;
  width: 100%;
`

export const NodeInfoTitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-left: ${size.editor.node.info.titles.padding};
  padding-right: ${size.editor.node.info.titles.padding};
  flex-direction: column;
  height: 100%;
`

export const NodeInfoTitle = styled.p`
  font-style: normal;

  font-weight: 500;
  font-size: ${size.editor.node.info.titles.title.font};
  line-height: ${size.editor.node.info.titles.title.lineHeight};
`
export const NodeInfoSubTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: ${size.editor.node.info.titles.subTitle.font};
  line-height: ${size.editor.node.info.titles.subTitle.lineHeight};
  color: #888888;
`

export const AppInfo = ({
  image,
  title = "",
  subtitle = "",
  id,
  background = "#e9f7ec",
  hasDelete = false,
  onDelete = null,
  onRefresh = null,
  onDelay = null,
  hasDelay = false,
  hasRefresh = false,
  delayConfig = {},
  ...props
}) => {
  const [IsDeleteShown, setIsDeleteShown] = useState(false)
  return (
    <Flex
      {...props}
      style={
        {
          // cursor: "move",
        }
      }
    >
      <NodeInfoImageWrapper background={background} {...props}>
        <NodeInfoImage src={image} />
      </NodeInfoImageWrapper>

      <NodeInfoTitleWrapper>
        <NodeInfoTitle>{title}</NodeInfoTitle>
      </NodeInfoTitleWrapper>
      <Box style={{ marginLeft: "auto", display: "flex", height: "fit-content" }}>
        {hasDelay ? (
       <Box
          sx={{
            border: delayConfig && delayConfig.delay_type ? "1px solid #1890FF" : "1px solid #B7CBFF",
            p: "6px 2px 2px 2px",
            borderRadius: "10px",
            color: "#B7CBFF",
            "&:hover": {
              border: "1px solid #152F73",
            },
          }}
          onClick={() => {
            onDelay && onDelay()
          }}
        >
          {delayConfig.delay_type ? (
            <ClockCircleTwoTone
             // spin
              style={{ color: delayConfig.delay_type ? "green" : "#B7CBFF", width: "30px", height: "20px" }}
            />
          ) : (
            <ClockCircleOutlined
              style={{ color: delayConfig.delay_type ? "green" : "#B7CBFF", width: "30px", height: "20px" }}
            />
          )}
        </Box>
        ) : 
        null}
        {hasRefresh ? (
          <Box
            sx={{
              border: "1px solid #b7cbff",
              p: "6px 2px 2px 2px",
              borderRadius: "10px",
              color: "#B7CBFF",
              "&:hover": {
                border: "1px solid #152F73",
              },
              ml: "5px",
              mr: "5px",
            }}
          >
            <ReloadOutlined
              style={{ width: "30px", height: "20px", ml: "3px", mr: "3px" }}
              onClick={() => {
                onRefresh && onRefresh()
              }}
            />
          </Box>
        ) : null}
        {hasDelete ? (
          <Box
            style={
              {
                //  marginLeft: "auto",
              }
            }
            onClick={() => {
              onDelete && onDelete()
            }}
            onMouseEnter={() => setIsDeleteShown(true)}
            onMouseLeave={() => setIsDeleteShown(false)}
          >
            <NodeDeleteWrapper background={background} {...props}>
              <DeleteIcon fill={IsDeleteShown ? "#152F73" : "#B7CBFF"} />
            </NodeDeleteWrapper>
          </Box>
        ) : null}
      </Box>
    </Flex>
  )
}

export const EventSelectionGroup = styled(Flex)`
  flex-direction: column;
  gap: 10px;
`

export const SelectionHeader = styled(Text)`
  font-weight: 500;
  font-size: ${size.editor.node.selectors.header.fontSize};
  line-height: ${size.editor.node.selectors.header.lineHeight};
  padding-top: ${size.editor.node.selectors.header.paddingTop};
  padding-bottom: ${size.editor.node.selectors.header.paddingTop};
`

export const ItemsGroup = styled.div`
  margin-bottom: ${size.editor.itemGroups.marginBottom};
`

const AppEventsGroupHeader = styled(Text)`
  font-size: ${size.editor.nodeTitle.fontSize};
  line-height: ${size.editor.nodeTitle.lineHeight};
`

const AppEventsItemOuter = styled(Flex)`
  font-size: ${size.editor.nodeTitle.fontSize};
  line-height: ${size.editor.nodeTitle.lineHeight};
  border-radius: ${size.editor.eventsItemOuter.borderRadius};
  box-sizing: border-box;
  border: 1px solid #000000;
`

export const AppEventsItemText = styled.p`
  font-size: ${size.editor.nodeTitle.fontSize};
  line-height: ${size.editor.nodeTitle.lineHeight};
  flex: 1;
  padding-left: ${size.editor.eventsItemOuter.text.paddingLeft};
  padding-top: ${size.editor.eventsItemOuter.text.paddingTop};
  padding-bottom: ${size.editor.eventsItemOuter.text.paddingBottom};
  cursor: pointer;
`

const AppEventsItemIcon = styled.div`
  font-size: ${size.editor.nodeTitle.fontSize};
  line-height: ${size.editor.nodeTitle.lineHeight};
  padding-right: ${size.editor.eventsItemOuter.icon.paddingRight};
  align-self: center;
`

export const AppEvents = ({ text = "", events = null, onclick, type, placeholder, spaceBetween, ...props }) => {
  return events ? (
    <Flex
      flexDirection="column"
      {...props}
      style={{
        marginBottom: spaceBetween ? 18 : 0,
      }}
    >
      <Box>
        <AppEventsItemOuter flexDirection="row">
          <AppEventsItemText>{placeholder}</AppEventsItemText>
          <AppEventsItemIcon>+</AppEventsItemIcon>
        </AppEventsItemOuter>
      </Box>
    </Flex>
  ) : (
    <> </>
  )
}

export const NodeGroupHeader = ({ text = "" }) => (
  <Flex
    flexDirection="column"
    style={{
      marginBottom: size.editor.itemGroups.header.marginBottom,
    }}
  >
    <Box>
      <AppEventsGroupHeader>{text}</AppEventsGroupHeader>
    </Box>
  </Flex>
)

export const ConnectorOuter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: ${props => size.editor.connector.outer.height * (props.type === "text" ? 3 : 1)};
  margin-bottom: ${size.editor.connector.outer.marginBottom};
`

export const ConnectorPortOuter = styled.div`
  position: relative;
  width: 9%;
  background-color: transparent;
  display: flex;
  align-items: ${props => (props.type === "text" ? "flex-start" : "center")};
  padding-top: ${props => (props.type === "text" ? size.editor.connector.outer.port.paddingLeft : 0)};
  justify-content: ${props => (props.placement === "left" ? "flex-start" : "flex-end")};
`

export const ConnectorPortInput = styled.input`
  flex: 1;
  border: 0px;
  width: 100%;
  box-sizing: border-box;
  border-radius: ${size.editor.connector.outer.port.borderRadius};
  font-size: ${size.editor.connector.outer.port.fontSize};
  line-height: ${size.editor.connector.outer.port.lineHeight};
  padding: ${size.editor.connector.outer.port.paddingLeft};
  background: ${props => (props.dirty ? "#FFF6F3" : props.additional ? "#E6EEFF" : "#f3f7ff")};
  border: 1px solid #b7cbff;
  &::placeholder {
    color: ${props => (props.dirty ? "#F74545" : "#000000")};
  }
  &:disabled {
    background: ${props => (props.additional ? "#E6EEFF" : "#f3f7ff")};
  }
  box-sizing: border-box;
`

export const ConnectorPortText = styled.textarea`
  flex: 1;
  border: 0px;
  width: 100%;
  height: 100px;
  border-radius: 8px;
  box-sizing: border-box;
  border-radius: ${size.editor.connector.outer.port.borderRadius};
  font-size: ${size.editor.connector.outer.port.fontSize};
  line-height: ${size.editor.connector.outer.port.lineHeight};
  padding: ${size.editor.connector.outer.port.paddingLeft};

  resize: none;
  background: ${props => (props.dirty ? "#FFF6F3" : "#f3f7ff")};
  border: ${props => (props.dirty ? "1px solid #F74545" : "1px solid #b7cbff")};
  &::placeholder {
    color: ${props => (props.dirty ? "#F74545" : "#000000")};
  }
  &:disabled {
    background: #f3f7ff;
  }
  box-sizing: border-box;
`

export const ConnectorPortTextConditions = styled.span`
  flex: 1;
  border: 0px;
  width: 100%;
  min-height: 80px;
  border-radius: 8px;
  box-sizing: border-box;
  border-radius: ${size.editor.connector.outer.port.borderRadius};
  font-size: ${size.editor.connector.outer.port.fontSize};
  line-height: ${size.editor.connector.outer.port.lineHeight};
  padding: ${size.editor.connector.outer.port.paddingLeft};

  resize: none;
  background: ${props => (props.dirty ? "#FFF6F3" : "#f3f7ff")};
  border: ${props => (props.dirty ? "1px solid #F74545" : "0px")};
  &::placeholder {
    color: ${props => (props.dirty ? "#F74545" : "#000000")};
  }
  &:disabled {
    background: #f3f7ff;
  }
  box-sizing: border-box;
`

export const ConnectorPortMentions = styled(MentionInput)`
  flex: 1;
  border: 0px;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  border-radius: ${size.editor.connector.outer.port.borderRadius};
  font-size: ${size.editor.connector.outer.port.fontSize};
  line-height: ${size.editor.connector.outer.port.lineHeight};
  padding: ${size.editor.connector.outer.port.paddingLeft};

  resize: none;
  background: ${props => (props.dirty ? "#FFF6F3" : "#f3f7ff")};
  border: ${props => (props.dirty ? "1px solid #F74545" : "0px")};
  &::placeholder {
    color: ${props => (props.dirty ? "#F74545" : "#000000")};
  }
  &:disabled {
    background: #f3f7ff;
  }
  box-sizing: border-box;
`

export const ConnectorPortLabel = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #888888;
`

export const ConnectorPortError = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #f65a4f;
  text-align: right;
`

export const NodeDefault = styled.div`
  position: absolute;
  transition: 0.3s ease box-shadow, 0.3s ease margin-top;
  // background: ${colors.konnectEditor.editor.nodeBackground};
  border-radius: ${size.editor.nodeRadius};
  width: ${size.editor.nodeWidth};
`
export const NodeOuter = styled.div`
  width: ${size.editor.nodeWidth};
  min-height: ${size.editor.nodeMinHeight};
  padding: ${size.editor.nodePadding};
  border-radius: ${size.editor.nodeRadius};
  box-shadow: ${props => (props.isFirst ? "0px 0px 3px 1px #B7CBFF;" : "0px 0px 3px 1px rgba(0, 0, 0, 0.08)")};
  background: ${colors.konnectEditor.editor.nodeBackground};
  cursor: pointer;
  border: ${props =>
    props.reviewed === null ? "0px solid #ffffff" : `0px solid ${props.reviewed ? "#C2F7E5" : "#f65a4f"}`};
  box-sizing: border-box;
`

export const NodeInput = styled.input`
  width: 100%;
  height: ${size.editor.nodeInput.height};
  border: 1px solid ${colors.konnectEditor.editor.node.input};
  border-radius: ${size.editor.nodeInput.borderRadius};
  font-size: ${size.editor.nodeInput.fontSize};
  color: ${colors.konnectEditor.editor.node.input};
  outline: none;
  margin-bottom: ${size.editor.nodeInput.marginBottom};
`

export const NodeSelect = styled.select`
  width: 100%;
  height: ${size.editor.nodeInput.height};

  border: 1px solid ${colors.konnectEditor.editor.node.input};
  border-radius: ${size.editor.nodeInput.borderRadius};
  font-size: ${size.editor.nodeInput.fontSize};
  color: ${colors.konnectEditor.editor.node.input};
  outline: none;
  margin-bottom: ${size.editor.nodeInput.marginBottom};
`

// export const PortDefaultOuter = styled.div`
//   width: ${size.editor.port.outer.height};
//   height: ${size.editor.port.outer.height};
//   border-radius: 50%;
//   background: #ffffff;
//   border: ${props => `${px2vw(2)} solid ${props.active ? "#F7C545" : "#152F73"}`};
//   cursor: pointer;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   &:hover > div {
//     background: #f7c545;
//   }
//   &:hover {
//     border: ${props => `${px2vw(2)} solid #F7C545`};
//   }
// `

// export const PortDefaultInner = styled.div`
//   width: ${size.editor.port.inner.height};
//   height: ${size.editor.port.inner.height};
//   border-radius: 50%;
//   background: ${props => (props.active ? "#F7C545" : "#152F73")};
//   cursor: pointer;
//   padding: ${props => px2vw(2)};
// `

export const PortDefaultO = styled.div``

export const AppEventsDropdownOuter = styled.select``

export const AppEventsDropdownOptions = styled.option``

// const PortIndicatorOuter = styled.div`
//   position: ${props => props.position};
//   right: -10px;
//   ${props =>
//     props.position === "relative"
//       ? `
//     right: -30px;
//     top:-25px;
//   `
//       : ""}
// `

const PortIndicatorOuterL = styled.div`
  position: absolute;
  left: -15px;
  top: 0px;
  visibility: ${props => (props.show ? "" : "hidden")};
`

const PortIndicatorOuterR = styled.div`
  position: absolute;
  right: -15px;
  top: 0px;
  visibility: ${props => (props.show ? "" : "hidden")};
`

export const PortIndicatorWrapper = styled(FontAwesomeIcon)`
  height: ${size.sidebar.search.icon.size};
  width: ${size.sidebar.search.icon.size};
  color: #f7c545;
  margin: 0px;
`

export const PortIndicator = ({ pType, show = false }) => {
  if (pType === "source")
    return (
      <PortIndicatorOuterR show={show} pType={pType}>
        <PortIndicatorWrapper icon={pType === "source" ? faAngleRight : faAngleLeft} color="#F7C545" />
      </PortIndicatorOuterR>
    )
  return (
    <PortIndicatorOuterL show={show} pType={pType}>
      <PortIndicatorWrapper icon={pType === "source" ? faAngleRight : faAngleLeft} color="#F7C545" />
    </PortIndicatorOuterL>
  )
}

export const Instructions = styled.div`
  width: ${size.editor.instructionsPopup.width};
  height: ${size.editor.instructionsPopup.height};
  padding-top: ${size.editor.instructionsPopup.paddingTop};
`
const CopyToClipboardInputOuter = styled(Flex)`
  height: ${size.sidebar.search.height};
  width: 100%;
  background: ${colors.konnectEditor.sidebar.search.background};
  border-radius: 16px;
  align-items: center;
  padding-left: 13px;
  justify-content: space-between;
`

const CopyToClipboardInput = styled.input`
  border: 0px;
  flex: 1;
  background: #f3f7ff;
  border-radius: 8px;
  box-sizing: border-box;
  border-radius: ${size.editor.connector.outer.port.borderRadius};
  font-size: ${size.editor.connector.outer.port.fontSize};
  line-height: ${size.editor.connector.outer.port.lineHeight};
  padding: ${size.editor.connector.outer.port.paddingLeft};
  &:disabled {
    background: #f3f7ff;
  }
  width: 100%;
`

const CopyToClipboardButton = styled.button`
  border: 0px;
  background: ${colors.konnectEditor.editor.node.button};
  border-radius: 8px;
  box-sizing: border-box;
  border-radius: ${size.editor.connector.outer.port.borderRadius};
  font-size: ${size.editor.connector.outer.port.fontSize};
  line-height: ${size.editor.connector.outer.port.lineHeight};
  padding: ${size.editor.connector.outer.port.paddingLeft};
  color: #ffffff;
  cursor: pointer;
`

export const CopyToClipboard = ({ buttonText = "", text = "", disabled = true, ...props }) => (
  <CopyToClipboardInputOuter>
    <CopyToClipboardInput value={text} disabled={disabled} />
    <CopyToClipboardButton {...props}>{buttonText} </CopyToClipboardButton>
  </CopyToClipboardInputOuter>
)

export const NodeInfoHelper = ({ children, ...props }) => {
  return (
    <Text sx={{ mt: px2vw(10), mb: px2vw(20), cursor: "pointer", color: "#5E5E5E" }} fontSize="sm" {...props}>
      {children}
    </Text>
  )
}

export const NodeButtonOutline = styled.button`
  border: 0px;
  background: ${props => (props.disabled ? "#EBEBE4" : "#FFFFFF")};
  border-radius: 8px;
  box-sizing: border-box;
  border-radius: ${size.editor.connector.outer.port.borderRadius};
  font-size: ${size.editor.connector.outer.port.fontSize};
  line-height: ${size.editor.connector.outer.port.lineHeight};
  padding: ${size.editor.connector.outer.port.paddingLeft};
  color: ${props => colors.konnectEditor.editor.node.button};
  border: 1px solid ${props => colors.konnectEditor.editor.node.button};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`

export const DeleteNode = ({ onDelete = null }) => {
  return (
    <div
      style={{
        width: 40,
        height: 40,
        backgroundColor: "powderblue",
      }}
      onClick={e => {
        onDelete && onDelete()
      }}
    >
      <DecoratorIcon icon={faTrash} size={28} iconColor="red"></DecoratorIcon>
    </div>
  )
}

export const SetAsLeft = ({ onSetAsFirst = null }) => {
  return (
    <div
      style={{
        width: 40,
        height: 40,
        backgroundColor: "powderblue",
      }}
      onClick={e => {
        onSetAsFirst && onSetAsFirst()
      }}
    >
      <DecoratorIcon icon={faPlay} size={28} iconColor="green"></DecoratorIcon>
    </div>
  )
}

export const DeleteLink = ({
  background = "#e9f7ec",
  onLinkDelete = null,
  x = 0,
  y = 0,
  onMouseLeave = null,
  onMouseEnter = null,
  show,
  ...props
}) => {
  const [IsDeleteShown, setIsDeleteShown] = useState(false)

  return (
    <Box
      style={
        {
          // position: "absolute",
          // // transform: `translate(${x}px, ${y}px)`,
          // top: y,
          // left: x,
        }
      }
      onClick={() => {
        onDelete && onDelete()
      }}
      // onClick={e => {
      //   onLinkDelete && onLinkDelete()
      // }}
      onMouseEnter={() => {
        onMouseEnter && onMouseEnter()
      }}
      onMouseLeave={() => {
        onMouseLeave && onMouseLeave()
        setIsDeleteShown(false)
      }}
    >
      {show || IsDeleteShown ? (
        // <NodeDeleteWrapper background={background} {...props}>
        <DeleteIcon fill={IsDeleteShown ? "#152F73" : "#B7CBFF"} />
      ) : // </NodeDeleteWrapper>
      null}
    </Box>
  )
}

export const TestAndReviewButton = styled.button`
  border: 0px;
  background: ${props => (props.disabled ? "#EBEBE4" : "#f7c545")};
  border-radius: 8px;
  box-sizing: border-box;
  border-radius: ${size.editor.connector.outer.port.borderRadius};
  font-size: ${size.editor.connector.outer.port.fontSize};
  line-height: ${size.editor.connector.outer.port.lineHeight};
  padding: ${size.editor.connector.outer.port.paddingLeft};
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  width: 100%;
`

export const PublishButton = styled(TestAndReviewButton)`
  padding: 10px;
  color: #152f73;
`

const AddMorePortsOuter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  top: -14px;
  position: relative;
  cursor: pointer;
`

const AddMorePorts = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: #ffffff;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 14px;
`

export const AddMoreFieldButton = ({ onClick = null }) => {
  return (
    <AddMorePortsOuter
      onClick={() => {
        onClick && onClick()
      }}
    >
      <AddMorePorts>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM15 11H11V15H9V11H5V9H9V5H11V9H15V11Z"
            fill="#152F73"
          />
        </svg>
      </AddMorePorts>
    </AddMorePortsOuter>
  )
}

export const ConditionsButton = styled.button`
  border: 0px;
  background: #ffffff;
  border: 1px dashed #000000;
  box-sizing: border-box;
  border-radius: ${size.editor.connector.outer.port.borderRadius};
  font-size: ${size.editor.connector.outer.port.fontSize};
  line-height: ${size.editor.connector.outer.port.lineHeight};
  padding: ${size.editor.connector.outer.port.paddingLeft};
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 20px;
  color: #152f73;
  cursor: pointer;
  width: 100%;
`

export const ParamsButton = styled(ConditionsButton)`
  background: #f3f7ff;
  border: 1px solid #b7cbff;
`

export const CouponWrapper = styled.div`
  display: flex;
`
export const CouponDayWrapper = styled.div`
  flex-basis: 100%;
  flex-grow: 0;
  margin-right: 4%;
`

export const GsheetModalButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const GsheetButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  border-radius: 8px;
  background: #f3f7ff;
  padding: 6px;
`

export const TestAndReviewButtonWrapper = styled.div`
  margin: 20px;
  margin-right: 38px;
`
