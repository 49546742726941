import React, { useState } from "react"
import { Box, Text, Flex } from "rebass"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw"
import { faPen, faSave, faTimes, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import { Input, Icon, Span, Button } from "src/components"
import ReactModal from "react-modal"
import Sheild from "src/assets/images/sheild"
import ProfileSvg from "src/assets/images/profile"
import ReactTooltip from "react-tooltip"
import { Modal } from "react-responsive-modal"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import PlusIcon from "src/assets/images/Plus_icon.svg"
import Cross from "../../assets/images/cross.svg"
import EditIcon from "src/assets/images/Edit_icon.svg"
import DisconnectIcon from "src/assets/images/Disconnect_icon.svg"
import DeleteIcon from "src/assets/images/Delete_icon.svg"
import SaveIcon from "src/assets/images/Save_icon.svg"

export const AppItem = ({
  app,
  index,
  selectedAuth,
  selectedApp,
  setSelectedApp,
  setSelectedAuth,
  getAppDetails,
  setFetchData,
  setTokenModal,
  setOpenInstructions
}) => {
  const { t } = useTranslation()

  return (
    <>
      {app && (
        <Flex
          p={10}
          width={1 / 12}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <React.Fragment key={app.id}>
            <Flex
              data-tip={app.name}
              width="100%"
              p={10}
              data-for={app.id + ""}
              sx={{
                borderRadius: 12,
                boxShadow: "0px 0px 4px 1px #c1d2ff",
                flexDirection: "column",
                textAlign: "-webkit-center",
                alignItems: "center",
                textAlign: "center",
                border: "1px solid #152F73",
                borderWidth: selectedAuth === index ? "1px" : 0,
                "&:hover": {
                  "& #image": {
                    transform: "scale(1.2)",
                  },
                },
              }}
            >
              <Flex
                key={"auth" + index}
                width="100%"
                id="image"
                sx={{
                  textAlign: "center",
                  borderRadius: 12,
                  backgroundColor: "#FFF",
                  cursor: "pointer",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "relative",
                  padding: 0,
                }}
                onClick={() => {
                  if(app.auth_type.toLowerCase() === "" ){
                   
                    setOpenInstructions(true)
                    setTokenModal(false)
                  }
                  if(app.auth_type){
                    setTokenModal(true)
                  }
                  
                
                  setSelectedAuth(index)
                  getAppDetails(app.id).then(res => {
                    setSelectedApp(app)
                    setFetchData(app)
                  })
                }}
              >
                {parseInt(app.account_count) > 1 && (
                  <Flex
                    width={{ _: 12, sm: 12, md: 14, lg: 16, xl: 20 }}
                    height={{ _: 12, sm: 12, md: 14, lg: 16, xl: 20 }}
                    fontSize={{ _: 5, sm: 5, md: 6, lg: 7, xl: 8 }}
                    sx={{
                      borderRadius: "100%",
                      position: "absolute",
                      top: "-5px",
                      right: "-5px",
                      background: "#F74545",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#fff",
                    }}
                  >
                    {app.account_count}
                  </Flex>
                )}

                {app.status === "Beta" && (
                  <Flex
                    fontSize={{ _: 5, sm: 5, md: 6, lg: 7, xl: 8 }}
                    sx={{
                      borderTopLeftRadius: "35%",
                      borderBottomRightRadius: "35%",
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      background: "#2196F3",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#fff",
                      paddingLeft: px2vw(2),
                      paddingRight: px2vw(2),
                      paddingTop: px2vw(2),
                      paddingBottom: px2vw(2),
                    }}
                  >
                    {t("apps.betaText")}
                  </Flex>
                )}

                <img width="100%" height="100%" src={app.image_url} />
              </Flex>
              <Text
                fontSize={{ _: 8, sm: 9, md: 10, lg: 12, xl: 12 }}
                sx={{
                  mt: "10px",
                  fontWeight: "500",
                  color: "#444444",
                  alignItems: "center",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  // height: "30px",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {/* {app.name} */}
                {app.name.length > 9 ? app.name.substring(0, 8) + ".." : app.name}
              </Text>
            </Flex>
            <ReactTooltip id={app.id + ""} effect="solid" place="bottom"></ReactTooltip>
          </React.Fragment>
        </Flex>
      )}
    </>
  )
}
