import { makeRequestActionCreator } from 'utils/redux'
import { GET_PLANS_LIST, GET_REDEEMED_COUPONS, REDEEM_CODE } from 'config/Constants'

export const getPlansList = () => dispatch => {
    const request = makeRequestActionCreator(GET_PLANS_LIST)

    return dispatch(
        request({
            url: 'api/v1/products/list',
            method: 'GET'
        })
    )
}

export const getRedeemedCoupons = () => dispatch => {
    const request = makeRequestActionCreator(GET_REDEEMED_COUPONS)

    return dispatch(
        request({
            url: 'api/v1/products/redeemed_coupons',
            method: 'GET'
        })
    )
}

export const redeemCode = (coupon) => dispatch => {
    const request = makeRequestActionCreator(REDEEM_CODE)

    return dispatch(
        request({
            url: 'api/v1/products/redeem_coupon',
            method: 'POST',
            data: { code: coupon }
        })
    )
}