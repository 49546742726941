/**
 * Verification email sent page.
 */
/* eslint-disable */
import React, { useState, useEffect } from "react"
import { Box, Text, Flex, Image } from "rebass"
import { useTranslation } from "react-i18next"
import Switch from "react-switch"
import "antd/dist/antd.css"
import "./style.css"
import { Link, Button } from "src/components"
import BackgroundPattern from "assets/images/pattern.png"
import ReactTooltip from "react-tooltip"
import styled, { useTheme } from "styled-components"
import { useHistory } from "react-router-dom"
import GreaterThan from "../../../assets/images/greater-than-icon.svg"
import { Modal } from "react-responsive-modal"
import { DeleteModal } from "../../konnectz/modals/DeleteModal.view"
import { ToastContainer, toast } from "react-toastify"
import { svg_1 } from "assets/images/svg"
import { useThemeContext } from "../../ReactFlow/store/context/themeContext"
import { Link as RouterLink } from "react-router-dom"

const StyledBackground = styled(Flex)`
  background-image: url(${BackgroundPattern});
  background-repeat: repeat;
  border-radius: 16px;
  background-size: contain;
  width: 100%;
`

export const LatestKonnectz = props => {
  const { changeStatus, konnects, deleteKonnectModal } = props
  const { t } = useTranslation()
  const { bP, setSelected } = useThemeContext()
  const [id,setId]=useState(null)
  const [openModal, setOpenModal] = useState(false)
  const history = useHistory()

  const onCloseModal = () => {
    setOpenModal(false)
  }

  /* In case if you need expanding of the items and editing the konnect */
  const history1 = useHistory()

  const onEditKonnect = id => {
    setId(id)
   // history.push("/konnect/" + id)
  }
  /* In case if you need expanding of the items and editing the konnect */
  useEffect(() => {
    localStorage.setItem("editKonnectId", JSON.stringify(id))
    if (id > 0) {
      history1.push("/konnect/" + id)
    }
  }, [id])
  return (
    <>
      <Flex sx={{ position: "relative", width: "100%", height: "100%" }}>
        <Flex
          p={{ _: 20, sm: 20, md: 22, lg: 24, xl: 24 }}
          sx={{
            flexDirection: "Column",
            position: "absolute",
            top: "0px",
            left: "0px",
            bottom: "0px",
            right: "0px",
            boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
            backgroundColor: "#FFF",
            borderRadius: "16px",
            gap: ["14px", "16px", "18px", "20px"],
          }}
        >
          {konnects && konnects.length > 0 && (
            <Flex sx={{ justifyContent: "space-between" }}>
              <Text fontSize={{ _: 18, sm: 18, md: 20, lg: 22, xl: 22 }} sx={{ fontWeight: "500", color: "#152F73" }}>
                {t("dashboard.widgets.latestKonnectz.headerTitle")}
              </Text>
              <RouterLink to={"/Konnectz"} style={{ textDecoration: "none", outline: "none" }}>
                <Text
                  fontSize={{ _: 16, sm: 16, md: 16, lg: 16, xl: 18 }}
                  sx={{ fontWeight: "500", color: "#7482A6", cursor: "pointer" }}
                  onClick={() => setSelected("/Konnectz")}
                >
                  View All <GreaterThan />
                </Text>
              </RouterLink>
            </Flex>
          )}
          <Flex
            id="scrollableDiv1"
            sx={{
              flexDirection: "Column",
              zIndex: "1",
              gap: [14, 16, 18, 20],
              p: "3px",
              overflowY: "auto",
            }}
          >
            {konnects.length > 0 ? (
              konnects.map((konnect, idx) => (
                <>
                  {" "}
                  <Flex
                    key={konnect.id}
                    p={{ _: "14px", sm: "14px", md: "16px", lg: "18px", xl: "20px" }}
                    sx={{
                      width: "100%",
                      backgroundColor: "#DBE5FF",
                      borderRadius: "16px",
                      zIndex: "900",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
                      "&:hover": {
                        boxShadow: "0px 0px 3px 1px rgb(81,142,248)",
                      },
                    }}
                    // onClick={() => handleExpandKonnect(konnect.id)} - In case if you need expanding of the items and editing the konnect
                  >
                    <Flex width="70%" sx={{ flexDirection: "column", gap: ["6px", "8px", "10px", "12px"] }}>
                      <Box key={idx} fontSize={{ _: 10, sm: 10, md: 12, lg: 12, xl: 16 }}>
                        {konnect.konnect_name ? (
                          konnect.konnect_name
                        ) : (
                          <>
                            <Flex sx={{ flexDirection: "row" }}>
                              {konnect.left_app.name}
                              {"  "}
                              {konnect.right_apps.map(x => {
                                return <>{" - " + x.app_name + "  "}</>
                              })}
                              {/* <div>{konnect.left_app.name}</div>
                              {konnect.right_apps.map((x, idx) => {
                                return <div key={x.app_name}>{" - " + x.app_name + "  "}</div>
                              })} */}
                            </Flex>
                          </>
                        )}
                      </Box>
                      <Flex width="100%" sx={{ position: "relative" }}>
                        <Image key={idx} width="5%" src={konnect.left_app.image_url} />
                        {konnect.right_apps.length > 0 && (
                          <Flex
                            sx={{
                              m: "auto 0px",
                              fill: "black",
                            }}
                          >
                            {svg_1.dashed_1}
                          </Flex>
                        )}
                        {konnect.right_apps.length > 0 &&
                          konnect.right_apps.map((rightApp, index) => {
                            return (
                              <>
                                {index !== 0 && (
                                  <Flex
                                    sx={{
                                      m: "auto 0px",
                                      fill: "black",
                                    }}
                                  >
                                    {svg_1.dashed_1}
                                  </Flex>
                                )}

                                <Image width="5%" src={rightApp.image_url} />
                              </>
                            )
                          })}
                      </Flex>
                      <ReactTooltip id={"konnectText_" + konnect.id} effect="solid" place="right"></ReactTooltip>
                    </Flex>
                    <Flex sx={{ alignItems: "center", gap: "50px" }}>
                      <Switch
                        checked={konnect.status === "ACTIVE" ? true : false}
                        offColor="#B6B9BF"
                        onColor="#1E419D"
                        offHandleColor="#fff"
                        onHandleColor="#F7C545"
                        handleDiameter={bP([18, 20, 22, 22])}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={bP([28, 30, 32, 32])}
                        width={bP([60, 62, 64, 66])}
                        onChange={() => changeStatus(konnect.id, konnect.status)}
                      />
                      <Flex sx={{ gap: "20px" }}>
                        <Flex
                          onClick={() => onEditKonnect(konnect.id)}
                          width={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
                          height={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              transform: "scale(1.2)",
                            },
                            transition: "transform 0.25s",
                          }}
                        >
                          {svg_1.edit}
                        </Flex>
                        <Flex
                          onClick={() => deleteKonnectModal(konnect.id)}
                          width={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
                          height={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              transform: "scale(1.2)",
                            },
                            transition: "transform 0.25s",
                          }}
                        >
                          {svg_1.delete}
                        </Flex>
                      </Flex>
                    </Flex>

                    {/* <Flex flexDirection="row" sx={{ justifyContent: "space-between" }}>
                      <Flex>
                        {konnect.right_apps.length > 0 &&
                          konnect.right_apps.map((rightApp, index) => {
                            return (
                              <React.Fragment key={"rightApp-id-" + index}>
                                <Box
                                  width={{ _: 40, sm: 35, md: 40, lg: 40, xl: 45 }}
                                  height={{ _: 40, sm: 35, md: 40, lg: 40, xl: 45 }}
                                  sx={{
                                    p: "5px",
                                    borderRadius: "12px",
                                    backgroundColor: rightApp.background_color,
                                    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.08)",
                                  }}
                                >
                                  <img width="100%" height="100%" src={rightApp.image_url} />
                                </Box>
                                {index !== konnect.right_apps.length - 1 && (
                                  <Box
                                    width={{ _: 40, sm: 35, md: 40, lg: 40, xl: 45 }}
                                    sx={{
                                      border: "3px dashed #152F73",
                                      height: "0px",
                                      textAlign: "center",
                                      position: "relative",
                                      top: ["18px", "22px", "24px", "26px"],
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            )
                          })}
                      </Flex>
                      <Box sx={{ gap: "10px", display: "inline-flex" }}>
                        <Box sx={{ mt: "5px" }}>
                          <Switch
                            checked={konnect.status === "ACTIVE"}
                            onChange={() => changeStatus(konnect.id, konnect.status)}
                          />
                        </Box>
                        <EditIcon
                          sx={{ cursor: "pointer", mr: "5px" }}
                          data-tip={"Edit"}
                          onClick={() => onEditKonnect(konnect.id)}
                        />
                        <DeleteIcon
                          sx={{ cursor: "pointer" }}
                          data-tip="Remove"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#ea4f4f",
                            borderRadius: "50%",
                            padding: "4px 7px",
                            height: "30px",
                          }}
                          size="xl"
                          onClick={() => deleteKonnectModal(konnect.id)}
                          //   onClick={() => updateAppStatus(app.id, "REMOVE", selectedApp.id)}
                        />
                      </Box>
                    </Flex> */}
                  </Flex>
                  <Modal
                    open={openModal}
                    onClose={onCloseModal}
                    // closeIcon={<Cross />}
                    center
                    styles={{ modal: { padding: "20px", borderRadius: "16px" } }}
                  >
                    <Box sx={{ padding: "20px" }}>
                      {" "}
                      <DeleteModal closeModal={onCloseModal} />
                    </Box>
                  </Modal>
                </>
              ))
            ) : (
              <Flex sx={{ width: "100%", flexDirection: "Column" }}>
                <Text
                  fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                  sx={{ fontWeight: "500", color: "#000", marginBottom: "28px", width: "100%", textAlign: "center" }}
                >
                  Select Konnect Automate
                </Text>
                <Flex
                  flexDirection="Column"
                  alignItems="center"
                  sx={{ flexGrow: 1, justifyContent: "flex-end", height: "50%" }}
                >
                  <Link
                    to={"/konnect1"}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <Button
                      variant="secondary"
                      width={{ _: 199, sm: 199, md: 199, lg: 199, xl: 199 }}
                      height={{ _: 35, sm: 35, md: 40, lg: 45, xl: 51 }}
                      fontSize={{ _: 10, sm: 10, md: 12, lg: 14, xl: 16 }}
                      sx={{
                        border: "none",
                        borderRadius: "12px",
                        backgroundColor: "#152F73",
                        height: "32px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        color: "#FFF",
                      }}
                    >
                      {"Create Konnectz"}
                    </Button>
                  </Link>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </Flex>
    </>
  )
}

// const mapStateToProps = state => {
//     return { activeWidgets: state.getIn(['dashboard', 'activeWidgets']) }
//   }

// const mapDispatchToProps = dispatch => {
//     return {
//       getKonnectsList: searchQuery => dispatch(getKonnectsList(searchQuery)),
//       getFoldersList: () => dispatch(getFoldersList()),
//       createFolder: folderName => dispatch(createFolder(folderName)),
//       renameKonnectFolder: (folderId, folderName) => dispatch(renameKonnectFolder(folderId, folderName)),
//       deleteKonnectFolder: folderId => dispatch(deleteKonnectFolder(folderId)),
//       moveKonnectsToFolder: (folderId, konnects) => dispatch(moveKonnectsToFolder(folderId, konnects)),
//       getKonnectsByFolderId: id => dispatch(getKonnectsByFolderId(id)),
//       updateKonnectStatus: (id, status) => dispatch(updateKonnectStatus(id, status)),
//     }
//   }

export default LatestKonnectz
