import React, { useState, useEffect } from "react"
import { Box, Text, Flex, Link } from "rebass"
import { useTranslation } from "react-i18next"
import { Checkbox, Label } from "@rebass/forms"
import px2vw from "utils/px2vw"
import DeleteIconSvg from "src/assets/images/deleteIcon"
import { Button } from "src/components/index"
import "./stylee.css"

import { Icon } from "src/components"
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons"
export const RightHandConfigModal = ({ closeModal, addedKeys = [], data = [], appIcon }, index) => {
  const { t } = useTranslation()
  const [list, setList] = useState([])
  const [showValue, setShowValue] = useState("")
  const [searchValue, setSearchValue] = useState("")
  const [expandedSection, setExpandedSection] = useState([])
  const [a, setA] = useState(null)
  const [checked, setChecked] = useState([])

  const allTrue = list.every(item => Boolean(item.selected))
  useEffect(() => {
    let listData = data.map(item => {

      const l = addedKeys.findIndex(
        y => y.props.children[0].props.children[0] === item.props.children[0].props.children[0]
      )
      return { ...item, selected: l > -1 }
    })
    setList(listData)
    return () => {
      setList(data)
    }
  }, [data])

  const handleCheckAllChange = e => {
    if (e.target.checked) {
      const updatedData = list.map(item => ({ ...item, selected: true }))
      const allCountries = list.map(c => c.label)

      setChecked(updatedData)
      setList(updatedData)
    } else {
      setChecked([])
      const updatedData1 = list.map(item => ({ ...item, selected: false }))
      setList(updatedData1)
    }
  }
  let formElements = []

  let sections = []

  const onMouseOverItem = value => {
    setShowValue(value)
  }

  const onMouseOutItem = () => {
    setShowValue("")
  }


  if (list) {
    list
    .filter(a => {
      let x= a.props.children[0].props.children[0]
    console.log(a,"asd")
      return x.toLowerCase().includes(searchValue.toLowerCase())
    })
    .map(q => {  
      let displayValue = q.selected ? true : showValue === q.label

     

      formElements.push(
        <Flex
          onMouseOver={() => onMouseOverItem(q.label)}
          onMouseOut={onMouseOutItem}
          variant="flex.center"
          sx={{
            flexDirection: "row",
            marginBottom: "20px",
            padding: "5px",
            marginRight: "20px",
            backgroundColor: displayValue ? "#F3F7FF" : "#FFFFFF",
            border: displayValue ? "1px solid #B7CBFF" : "1px solid #FFFFFF",
            borderRadius: "16px",
            "&:hover": {
              backgroundColor: displayValue ? "#F3F7FF" : "#FFFFFF",
              border: displayValue ? "1px solid #B7CBFF" : "1px solid #FFFFFF",
              borderRadius: "16px",
            },
          }}
        >
          <Label variant="wrapperLabel" sx={{ fontSize: ["xxs", "xs", "sm"], ml: px2vw(8), color: "app.secondary.20" }}>
            <Checkbox
              name="isAdmin"
              onChange={e => {

                const l = list.findIndex(y => y === q)
                list[l] = { ...q, selected: !q.selected }

                setList(list)
                const { name, checked } = e.target
                if (checked) {
                  setChecked([...checked, name])
                } else {
                  setChecked(checked.filter(item => item.selected !== name))
                }
                return setA(a=>a+1)
              }}
             
              checked={q.selected} 
            />
          
            <Box sx={{ marginLeft: "10px" }}>
              <span style={{ color: "#000000", fontSize: "14px", fontWeight: "500" }}>
                {q.props.children[0].props.children[0]}
              </span>
              {displayValue && (
                <span
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "500",
                    "&:hover": {
                      color: "#000000",
                    },
                  }}
                >
                
                </span>
              )}
            </Box>
          </Label>
        </Flex>
      )
    
    })
  }

  return (
    <div key={index}>
      <Flex
        sx={{
          textAlign: "left",
          cursor: "pointer",
          flexDirection: "column",
          backgroundColor: "app.primary.110",
        }}
      >
        <Box
          className={"modal-scroller"}
          sx={{
            width: "95%",
            height: "100%",
            marginTop: "40px",
            marginLeft: "35px",
            minHeight: "600px",
            maxHeight: "600px",
          }}
        >
             <div style={{ display: "flex", marginBottom: "20px", justifyContent: "center" }}>
          <div>
            {" "}
            <input
              type="text"
              width={"600px"}
              value={searchValue}
              placeholder="Search Label Name"
              onChange={e => {
                setSearchValue(e.target.value)
              }}
              style={{ padding: "12px", border: "1px solid lightgray", borderRadius: "10px", width: "500px" }}
            />
          </div>

          <div></div>
        </div>
        <div style={{marginBottom:"8px"}}>
        <Label variant="wrapperLabel" sx={{ fontSize: ["xxs", "xs", "sm"], ml: px2vw(8), color: "app.secondary.20" }}>
          <Checkbox
            className="form-check-input"
            type="checkbox"
            id="selectAll"
            checked={checked.length === list.length || allTrue}
            onChange={handleCheckAllChange}
            sx={{ ml: "7px", mb: "10px" }}
          />
          <Box sx={{ marginLeft: "10px" }}>
            <span style={{ color: "#000000", fontSize: "14px", fontWeight: "500" }}>Select All</span>
          </Box>
        </Label>
        </div>
          {formElements}
        </Box>
        <Box sx={{ height: "60px", boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.08);", textAlign: "center" }}>
          <Flex flexDirection="row" sx={{ justifyContent: "center", marginTop: "10px" }}>
            <Box sx={{ textAlign: "center", justifyContent: "center" }}>
              <Button
                fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                sx={{
                  background: "none",
                  boxShadow: "none",
                  fontWeight: "normal",
                  cursor: "pointer",
                  color: "app.secondary.70",
                  marginRight: "20px",
                  "&:hover": {
                    background: "none",
                    color: "app.secondary.70",
                  },
                }}
                onClick={closeModal}
              >
                {t("konnect.additionalConfigsModal.cancelBtnLabel")}
              </Button>
            </Box>
            <Box>
              <Button
                variant="primary"
                fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                sx={{ fontWeight: "normal", px: px2vw(20), borderRadius: "0px" }}
                onClick={() => closeModal(list)}
              >
                {t("konnect.additionalConfigsModal.okBtnLabel")}
              </Button>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </div>
  )
}