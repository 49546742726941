import React, { useState, useEffect } from 'react'
import { NotificationsView } from './Notifications.view'
import { SearchHeader } from "src/components"

export const NotificationsContainer = (props) => {

    useEffect(() => {

    }, [])

    return (
        <>
            <SearchHeader />
            <NotificationsView />
        </>
    )
}
