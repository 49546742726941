import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Box, Flex, Text } from "rebass"
import queryString from "query-string"
import AuthTokenService from "utils/AuthTokenService"
import { OAUTH_REDIRECT_URL } from "config/Constants"
import { useTranslation } from "react-i18next"

import { getApps, getOauthData, saveOauthData } from "src/store/actions"
import { Reauth } from "./Apps.view"

export const Container = props => {
  const { t } = useTranslation()

  const { oauthData, getOauthData, saveOauthData } = props
  const [selectedAppId,setSelectedAppId] = useState(null)
  console.log(props,"props11")
  const values = props.location.search ? queryString.parse(props.location.search) : queryString.parse(props.location.hash)
  console.log(values,"values",AuthTokenService)
  if (oauthData && oauthData.type) {
    window.location.href = oauthData.auth_url
  }
  const idd = localStorage.getItem("app_user_id")
  const id = Number(idd)
  const reAut =   localStorage.getItem("re-auth")
  const reAuth = Boolean(reAut)
  useEffect(() => {
    if (props.match.params.appId) {
      AuthTokenService.storeToken({
        token: props.match.params.token,
        keepmeLoggedIn: false,
        appId: props.match.params.appId,

      })
  
      window.appId = props.match.params.appId
      console.log(values,"values",props)
      getOauthData({
        app_id: props.match.params.appId,
        redirect_url: OAUTH_REDIRECT_URL,
      })
    } else if (AuthTokenService.getAppId()) {
      console.log(values,"values",props,AuthTokenService.getAppId())
      saveOauthData(reAuth?{
        app_id: AuthTokenService.getAppId(),
        redirect_url: OAUTH_REDIRECT_URL,
        app_user_id:id,
        auth_response: values,
      }:{
        app_id: AuthTokenService.getAppId(),
        redirect_url: OAUTH_REDIRECT_URL,
     
        auth_response: values,
      }
      ).then((res) =>{
        window.close()
        console.log(res,"response in omniAuth")
      } )
      window.appId = null
    }
  }, [])

console.log(selectedAppId,"selectedApp",localStorage.getItem("app_user_id"),localStorage.getItem("re-auth"),reAut,reAuth,id)
  return (
    <Flex justifyContent="center" my="24px">
     <Reauth setSelectedAppId={setSelectedAppId}/>
    </Flex>
  )
}

const mapStateToProps = state => {
  const { apps = {} } = state.getIn(["konnect"]).toJS()

  const { oauthData = {} } = state.getIn(["apps"]).toJS()

  return {
    apps,
    oauthData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getApps: () => dispatch(getApps()),
    getOauthData: pay => dispatch(getOauthData(pay)),
    saveOauthData: pay => dispatch(saveOauthData(pay)),
  }
}

export const AppsOauthContainer = connect(mapStateToProps, mapDispatchToProps)(Container)
