import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { Flex, Box } from "rebass"
import { getTeamUsersList } from "src/store/actions"
import { TeamUsersList } from "src/components/Team/teamUsersList"
import { ToastContainer } from "react-toastify"

export const TeamContainer_ = ({ getTeamUsersList, teamUsersList,addTeamMemberBtnClick }) => {

    useEffect(() => {
        getTeamUsersList()
    }, [])

    return (
        <>
            <Flex flexDirection="column" sx={{ p: '20px' }}>
                <TeamUsersList users={teamUsersList} addTeamMemberBtnClick={addTeamMemberBtnClick} getTeamUsersList={getTeamUsersList}/>
            </Flex>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
        </>
    )
}

const mapStateToProps = state => {
    return {
        teamUsersList: state.getIn(["team", "teamUsersList"])
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTeamUsersList: () => dispatch(getTeamUsersList())
    }
}

export const TeamContainer = connect(mapStateToProps, mapDispatchToProps)(TeamContainer_)