import React from "react"
import { addNode, updateEdge, updateNode, updateNodes } from "../store/slice/canvasSlice"
import { createSlice, createAsyncThunk, current, getState } from "@reduxjs/toolkit"
import { ToastContainer, toast } from "react-toastify"

import GetAppType from "../Component/konnect/Node/NodeWrapper"
import AuthTokenService from "../../../utils/AuthTokenService"
import axios from "../../../../node_modules/axios/index"
import { v4 } from "uuid"
import {
  onCanvasDrop,
  enableTestReviewLoader,
  marksDirty,
  onLinkComplete,
  showChatGpt,
  moveActionToInitialState,
} from "../store/slice/canvasSlice"
import { useSelector, useDispatch } from "react-redux"
import { updateToast } from "./toast/index"
import { v4 as uuidv4 } from "uuid"

const getId = () => uuidv4() //id for nodes

const tokenObj = AuthTokenService.get()

export const onDragOver = e => {
  e.preventDefault()
  e.dataTransfer.dropEffect = "move"
}

export const onDrop = (
  e,
  reactFlowInstance,
  sidebarContainer,
  topbarContainer,
  getId,
  dispatch,
  nodes,
  apiAppInitialState
) => {
  e.preventDefault()
  let droppedElement = JSON.parse(e.dataTransfer.getData("application/reactflow"))
  let isWebHookExists = !!nodes.find(e => e.data.type === "WEBHOOK")
  if (droppedElement.type === "WEBHOOK" && isWebHookExists) {
    return updateToast(dispatch, {
      message: "Webhook Already Exist",
      status: "success",
    })
  }
  if (droppedElement.type === "WEBHOOK" && nodes && nodes.length && nodes[0].data.type === "API") {
    // toast.info("Try With Other App Cannot Add Webhook Here!!!"),
    return updateToast(dispatch, {
      message: "Try with other App!",
      status: "success",
    })
  }
  const position = reactFlowInstance.project({
    x: e.clientX - sidebarContainer.current.getBoundingClientRect().width,
    y: e.clientY - topbarContainer.current.getBoundingClientRect().height,
  })
  if (droppedElement.webhook_enabled) {
    droppedElement.webhook = true
    droppedElement.instructions = droppedElement.webhook_instructions
    droppedElement.webhookUrl = droppedElement.webhook_url
  }

  let newNode, newNodeData
  // console.log("DROPPED ELEMENT: ", droppedElement)
  if (droppedElement) {
    newNodeData = {
      position,
      data: {
        ...droppedElement,
        nodeid: getId(),
        appEvent: null,
        appEventConfigurations: [],
        appEventSequences: [],
        appEvents: [],
        configResponses: [],
        tested: false,
        reviewed: false,
        isloading: false,
        showTestAndReview: droppedElement.type === "ADD_ON" && droppedElement.provider === "conditioner" ? false : true,
      },
    }
    newNode = {
      id: newNodeData.data.nodeid,
      ...newNodeData,
      type: GetAppType({ droppedElement, nodes }),
    }
    if (droppedElement.provider.toLowerCase() !== "webhook") {
      dispatch(getAppEvents(newNode))
    }
    if (droppedElement.type !== "WEBHOOK") {
      dispatch(getLinkedAccounts(newNode))
    }

    return dispatch(onCanvasDrop(newNode))
  }

  if (droppedElement.type === "ADD_ON" && droppedElement.provider.toLowerCase() === "api") {
    droppedElement.api = { ...apiAppInitialState, apiAppInitialState }
  }
}

export const getAppEvents = createAsyncThunk("getAppEvents", async props => {
  const tokenObj = AuthTokenService.get()
  const { data } = props
  var UrlInfo1 = {
    url: `https://stagingapp.konnectzit.com/api/v1/apps/${data.id}/app_events`, //api/v1/apps/authorized_apps1
    method: "GET",
    headers: {
      Authorization: "Bearer " + tokenObj,
    },
  }

  try {
    let result = await axios(UrlInfo1)
    let response = await result.data
    return response
  } catch (error) {
    console.log(error)
  }
})
// Action 3
export const captureWebHookResponse = createAsyncThunk("captureWebHookResponse", async props => {
  const tokenObj = AuthTokenService.get()
  const { data, editorState, dispatch } = props
  //const state=getState()
  dispatch(moveActionToInitialState())
  const { webhook_url: webhookUrl, id: nodeid, enabled, apiKey, apiSecret, appEvent } = data
  const identifiers = webhookUrl.split("/").pop()

  let params = ""
  const payload = {}
  if (webhookUrl) {
    params += `?webhookUrl=${webhookUrl}`
    payload.webhookUrl = webhookUrl
  }

  if (nodeid) {
    params += `&left_app_id=${nodeid}`
    payload.left_app_id = nodeid
  }

  if (appEvent) {
    params += `&left_app_event_id=${appEvent}`
    payload.left_app_event_id = appEvent
  }

  if (enabled) {
    params += `&auth_enabled=true&api_key=${apiKey}&api_secret=${apiSecret}`
    payload.auth_enabled = true
    payload.api_key = apiKey
    payload.api_secret = apiSecret
  }

  if (editorState.nodes && editorState.nodes[0].data.konnect_id) {
    params += `&konnect_id=${editorState.nodes[0].data.konnect_id}`
    payload.konnect_id = editorState.nodes[0].data.konnect_id
  }

  params += `&canvas_json=${encodeURI(JSON.stringify(editorState))}`
  payload.canvas_json = editorState

  let awaitingWebhookResponse = !!data.awaitingWebhookResponse
  let UrlInfo3 = {
    url: `https://stagingapp.konnectzit.com/api/v1/konnect_webhooks/capture_webhook_response/${identifiers}`,
    method: "POST",
    data: payload,
    extra: {
      ...data,
    },
    headers: {
      Authorization: "Bearer " + tokenObj,
    },
  }
  const response = await axios(UrlInfo3)
  const result = await response.data
  return result
})

export const FlattenJSON = json => {
  let newData = []
  const extract = (obj, parentKey = []) => {
    if (!obj) return
    Object.keys(obj).map(key => {
      const __key = v4()
      if (typeof obj[key] === "object") {
        const k = `${[...parentKey, key].join(".$")}`
        const _parentKey = parentKey.map(x => `${x[0].toUpperCase()}${x.slice(1)}`)
        const nodeLabel = `${[..._parentKey, key[0] ? `${key[0].toUpperCase()}${key.slice(1)}` : "Undefined"].join(
          " > "
        )}`
        obj[key] &&
          newData.push({
            id: k,
            type: "node",
            parent: parentKey,
            label: nodeLabel,
            level: parentKey.length,
            items: { ...obj[key] },
            k,
            children: Object.keys(obj[key]).map(z => `${[k, z].join(".$")}`),
            isOpen: false,
            isAvailable: false,
            uiKey: __key,
          })

        extract(obj[key], [...parentKey, key])
      } else {
        const k = `${[...parentKey, key].join(".$")}`

        newData.push({
          id: k,
          label: key[0] ? `${key[0]?.toUpperCase()}${key?.slice(1)}` : "Undefined",
          value: obj[key],
          config_key: k,
          type: "leaf",
          parent: `${[...parentKey].join(".$")}`,
          level: parentKey.length,
          isOpen: false,
          uiKey: __key,
        })
      }
      return false
    })
  }
  extract(json)

  return newData
}
export const getAppEventConfig = createAsyncThunk("getAppEventConfig", async props => {
  const tokenObj = AuthTokenService.get()
  const { appId = "", nodeId = null, eventId = "", source = false, app_account_id = null, data } = props

  let url = `https://stagingapp.konnectzit.com/api/v1/apps/${appId}/app_events/${eventId}/event_config/${
    source ? "left" : "right"
  }`
  if (app_account_id) url += `?app_account_id=${app_account_id}`
  let UrlInfo4 = {
    url: url,
    method: "GET",
    headers: {
      Authorization: "Bearer " + tokenObj,
    },
    extra: {
      ...data,
    },
  }
  try {
    const response = await axios(UrlInfo4)
    const res = await response.data
    return res
  } catch (error) {
    console.log(error)
  }
})
export const getLinkedAccounts = createAsyncThunk("getLinkedAccounts", async props => {
  const tokenObj = AuthTokenService.get()
  const { data } = props

  let UrlInfo = {
    url: `https://stagingapp.konnectzit.com/api/v1/apps/${data.id}/authorized_app_accounts`,
    method: "GET",
    extra: {
      ...data,
    },
    headers: {
      Authorization: "Bearer " + tokenObj,
    },
  }
  try {
    let result = await axios(UrlInfo)
    let res = await result.data

    return res
  } catch (error) {
    console.log(error)
  }
})
export const onDeleteNodeV2 = createAsyncThunk("onDeleteNodeV2", async props => {
  const tokenObj = AuthTokenService.get()
  let UrlInfo = {
    url: `https://stagingapp.konnectzit.com/api/v1//konnects/delete_activity`,
    data: props,
    method: "POST",
    headers: {
      Authorization: "Bearer " + tokenObj,
    },
  }
  let result = await axios(UrlInfo)
  let response = await result.data
  return response
})
export const getAppEventConfigDetail = createAsyncThunk("getAppEventConfigDetails", async props => {
  const tokenObj = AuthTokenService.get()
  const {
    data,
    appId = "",
    nodeId = null,
    eventId = "",
    eventConfigId = "",
    source = false,
    prevSequence = null,
    app_account_id = null,
    config_key = "",
  } = props
  let url = `https://stagingapp.konnectzit.com/api/v1/apps/${appId}/app_events/${eventId}/event_config/${
    source ? "left" : "right"
  }/${eventConfigId}/details?`

  let params = ""
  if (data.appEventSequences) {
    data.appEventSequences.map(x => {
      params += `&${x.config_key}=${x.id}`
    })
  }
  if (prevSequence) {
    url = `${url}${prevSequence.config_key}=${prevSequence.id}${params}`
  }

  if (app_account_id) url += `&app_account_id=${app_account_id}`

  let UrlInfo5 = {
    url: url,
    method: "GET",
    headers: {
      Authorization: "Bearer " + tokenObj,
    },
    extra: {
      ...props,
    },
  }
  try {
    const response = await axios(UrlInfo5)
    const res = await response.data

    return res
  } catch (error) {
    console.log(error)
  }
})
export const getAppEventConfigDetailFetch = createAsyncThunk("getAppEventConfigDetailFetch", async props => {
  const tokenObj = AuthTokenService.get()
  const {
    appId = "",
    data,
    nodeId = null,
    eventId = "",
    eventConfigId = "",
    source = false,
    app_account_id = null,
    selectedConfigKey = "",
    selectedEventConfigId,
  } = props
  let params = ""
  if (data.appEventSequences) {
    data.appEventSequences.map(x => {
      
      if (x.config_key !== "sheet_id" ) params += `&${x.config_key}=${x.id}`
    })
  }
  if (selectedConfigKey) {
    params = `?${selectedConfigKey}=${selectedEventConfigId}${params}`
  }

  let url = `https://stagingapp.konnectzit.com/api/v1/apps/${appId}/app_events/${eventId}/event_config/${
    source ? "left" : "right"
  }/${eventConfigId}/fetch-fields`

  url += `${params}`

  if (app_account_id) url += `&app_account_id=${app_account_id}`
  let UrlInfo5 = {
    url: url,
    method: "GET",
    headers: {
      Authorization: "Bearer " + tokenObj,
    },
    extra: {
      ...props,
    },
  }
  try {
    let response = await axios(UrlInfo5)
    let result = await response.data
    return result
  } catch (error) {
    console.log(error)
  }
})

const payloadMaker = props => {
  const props1 = JSON.parse(JSON.stringify(props))
  const { editorState, delay_config, appDetails } = props1
  editorState.nodes = editorState.nodes.map((x, idx) => {
    x.order = idx
    return x
  })
  const leftNode = editorState.nodes[0]
  const rightNodes = editorState.nodes.slice(1)
  let payload = {}
  let condition_activity_id = 0
  let pass_through_condition = false
  if (leftNode) {
    payload.left_app_id = leftNode.data.id
    payload.webhookUrl = leftNode.data.webhook_url ? leftNode.data.webhook_url : null
    payload.app_account_id = leftNode.data.selectedAccount ? leftNode.data.selectedAccount.id : null
    payload.left_app_event_id = leftNode.data.appEvent

    payload.order = 0
    payload.left_config = {}

    leftNode.data.appEventSequences.map(x => {
      payload.left_config[x.config_key] = x.id
    })

    payload.right_apps = []

    rightNodes.map((x, idx) => {
      const config = {}
      const textFormatterConfig = []
      const conditionsFormatterConfig = []
      // configresponses to filter the connections
      //let configResponses = x.data.configResponses ? x.data.configResponses.filter(x => !!x.port) : []

      if (x.data.type === "ADD_ON" && x.data.provider === "conditioner") {
        let operator = null
        x.data.conditions.map(e => {
          const condition = {
            attribute: {
              type: "key-map",
              value: e.field.value,
              konnect_activity_id: x.data.parent_konnect_activity_id,
            },
            condition: e.operator,
            condition_value: e.value.value
              ? e.value
              : {
                  type: "user-defined",
                  value: e.value,
                  // konnect_activity_id: x.parent_konnect_activity_id,
                },
          }
          if (operator) condition.concat_value = operator
          operator = e.queryOperator ? e.queryOperator.toUpperCase() : null
          conditionsFormatterConfig.push(condition)
        })
      } else if (
        (x.data.type === "ADD_ON" &&
          (x.data.provider === "TextFormatter" ||
            x.data.provider === "DateFormatter" ||
            x.data.provider === "Converter")) ||
        x.data.provider === "NumberFormatter" ||
        x.provider === "scheduler"
      ) {
        x.data.fromPorts?.map(e => {
          const n = editorState.nodes.find(u => u.data.nodeid === e.info.node)
          textFormatterConfig.push({
            konnect_activity_id: n.data.konnect_activity_id,
            display: e.display,
            id: e.id,
            config_key: n.data.configResponses.find(i => i.id === e.id).config_key,
          })
        })
      }

      x.data.configResponses &&
        x.data.configResponses.map(y => {
          if (y.value) {
            config[y.config_key] = {
              type: "user-defined",
              //value: y._id ? y._id : y.value,
              value: y.value,
            }
          } else {
            config[y.config_key] = {
              port: y.port,
              // port: y.port,
            }
          }
        })

      payload.right_apps[idx] = {
        app_id: x.data.id,
        nodeId: x.data.nodeid,

        config: { ...config },
        type: x.data.type,
        provider: x.data.provider || "",
        konnect_id: leftNode.data.konnect_id,
      }
      if (x.data.appEvent) {
        payload.right_apps[idx].app_event_id = x.data.appEvent ? x.data.appEvent : null
      }

      if (x.data.type !== "ADD_ON") {
        payload.right_apps[idx].app_account_id = x.data.selectedAccount ? x.data.selectedAccount.id : null
      }

      if (
        x.data.type === "ADD_ON" &&
        x.data.provider !== "conditioner" &&
        x.data.provider !== "api" &&
        x.provider !== "scheduler" &&
        x.data.provider !== "generator" &&
        x.data.provider.toLowerCase() !== "textsplitter"
      ) {
        let string = payload.right_apps[idx].config.text.value + ""
        //let string = payload.right_apps[idx].data.config.text.value + ""
        textFormatterConfig.map(d => {
          const f = `@[${d.display}]`
          const mapValue = { type: "key-map", value: d.id, konnect_activity_id: d.konnect_activity_id }
          const g = `@[${d.display}](${JSON.stringify(mapValue)})`
          string = string.replace(f, g)
        })
        payload.right_apps[idx].config.text.value = string
      }

      x.data.appEventSequences.map(u => {
        if (!payload.right_apps[idx].config) payload.right_apps[idx].config = {}
        payload.right_apps[idx].config[u.config_key] = u.id
      })

      if (x.data.type === "ADD_ON" && x.data.provider === "conditioner") {
        payload.right_apps[idx].config = [...conditionsFormatterConfig]
      } else if (x.data.type === "ADD_ON" && x.data.provider === "api") {
        payload.right_apps[idx].config.apiDetails = appDetails
      } else if (x.data.type === "ADD_ON" && x.data.provider === "generator") {
        payload.right_apps[idx].config.appDetails = appDetails
      } else if (x.data.type === "ADD_ON" && x.data.provider.toLowerCase() === "textsplitter") {
        if (appDetails.textSplitter) payload.right_apps[idx].config.segmentIndex = appDetails.textSplitter.segmentIndex
      }
    })
    payload.right_apps.map(x => {
      if (x.type === "ADD_ON") {
        if (x.provider != "generator" && x.provider != "api" && x.provider.toLowerCase() != "textsplitter") {
          return
        }
      }

      editorState.links.map(y => {
        const fromNode = editorState.nodes.find(n => n.data.nodeid === y.source)
        const toNode = editorState.nodes.find(n => n.data.nodeid === y.target)
        if (fromNode.data.type !== "WEBHOOK")
          fromNode.data.configResponses = fromNode.data.configResponses.filter(x => !!x.port)
        if (toNode.data.type !== "WEBHOOK")
          toNode.data.configResponses = toNode.data.configResponses.filter(x => !!x.port)

        const sourcePortInfo = JSON.parse(y.sourceHandle.split("|")[1])
        const targetPortInfo = JSON.parse(y.targetHandle.split("|")[1])

        const { node: sourceNode, app: sourceAppId, appEvent: sourceAppEvent, port: sourceConfig_key } = sourcePortInfo
        const SourcePortid = `${sourceNode.toString()}|${sourceAppId.toString()}|${
          sourceAppEvent === null || sourceAppEvent === undefined ? "undefined" : sourceAppEvent.toString()
        }|${sourceConfig_key.toString()}`

        const {
          node: TargetNode,
          app: TargetAppId,
          appEvent: TargetAppeventId,
          port: TargetConfig_key,
          id: TargetId,
        } = targetPortInfo
        let TargetPortid
        TargetPortid = `${TargetNode.toString()}|${TargetAppId.toString()}|${
          TargetAppeventId === null || TargetAppeventId === undefined ? "undefined" : TargetAppeventId.toString()
        }|${TargetConfig_key.toString()}`

        const fromPort = fromNode.data.configResponses.find(r => r.port && r.port.id === SourcePortid)
        const toPort = toNode.data.configResponses.find(r => r.port && TargetPortid.indexOf(r.port.id) > -1)

        const toNodeIdx = editorState.nodes.findIndex(n => n.data.nodeid === TargetNode)
        const fromPortIdx = editorState.nodes.findIndex(n => n.data.nodeid === sourceNode)

        if (x.config[toPort.config_key] || x.config[toPort.id]) {
          if (fromPort.value && fromNode.data.type !== "WEBHOOK" && fromNode.data.type !== "WEBHOOK_API") {
            if (toPort.port.id.indexOf(x.nodeId) !== -1) {
              x.config[toPort.config_key] = {
                type: "key-map",
                value: fromPort.id,
                // fromPort.mapped || fromNode.appType === "ADD_ON" || fromNode.appType === "WEBHOOK_API"
                //   ? fromPort.id
                //   : fromPort.value,
              }
              if (fromNode.data.konnect_activity_id !== undefined) {
                x.config[toPort.config_key].konnect_activity_id = fromNode.data.konnect_activity_id
                  ? fromNode.data.konnect_activity_id
                  : 0
              }
            }
            // toNode.appEventSequences.map(u => {
            //   x.config[toPort.config_key][u.config_key] = u.id
            // })
          } else {
            if (toPort.port.id.indexOf(x.nodeId) !== -1) {
              x.config[toPort.config_key] = {
                type: "key-map",
                value: fromPort.id,
              }
            }
            x.config[toPort.config_key].konnect_activity_id = fromNode.data.konnect_activity_id
              ? fromNode.data.konnect_activity_id
              : 0

            toNode.data.appEventSequences.map(y => {
              x.config[y.config_key] = y.id
            })

            x.left_app_id = fromNode.appId
            x.left_app_event_id = fromNode.appEvent
            x.level = toNodeIdx - fromPortIdx
          }

          // if (fromNode.pass_through_condition && x.config[toPort.config_key]) {
          //   x.config[toPort.config_key].konnect_activity_id = fromNode.konnect_activity_id
          // }
        }

        if (toPort.port.id.indexOf(x.nodeId) !== -1) {
          x.config.pass_through_condition = !!fromNode.data.pass_through_condition
          x.config.condition_activity_id = !!fromNode.data.pass_through_condition
            ? fromNode.data.condition_activity_id
            : null

          toNode.data.pass_through_condition = !!fromNode.data.pass_through_condition
          toNode.data.condition_activity_id = !!fromNode.data.pass_through_condition
            ? fromNode.data.condition_activity_id
            : null
        }
      })
    })
  }

  payload.right_apps = payload.right_apps.map(x => {
    const y = { ...x }
    Object.keys(y.config).map(z => {
      if (z.includes(">")) {
        let k = z.indexOf(">")
        z = z.slice(0, k)
      }
      if (y.config[z] && y.config[z].port) {
        delete y.config[z]
      }
      if (z === "pass_through_condition" && !y.config[z]) delete y.config[z]
      if (z === "condition_activity_id" && !y.config[z]) delete y.config[z]
    })

    Object.keys(y.config).map(x => {
      if (x.includes(">")) {
        let k = x.indexOf(">")
        let m = x.slice(0, k)
        delete y.config[m]
      }
    })

    Object.keys(y.config).map(x => {
      if (x.includes(">")) {
        let k = x.indexOf(">")
        let m = x.slice(0, k)
        y.config[m] = y.config[x]
        delete y.config[x]
      }
    })

    Object.keys(y.config).map(x => {
      if (x.includes(">")) {
        let k = x.indexOf(">")
        let m = x.slice(0, k)
        delete y.config[m]
      }
    })

    Object.keys(y.config).map(x => {
      if (x.includes(">")) {
        let k = x.indexOf(">")
        let m = x.slice(0, k)
        y.config[m] = y.config[x]
        delete y.config[x]
      }
    })

    return y
  })

  return payload
}
export const appTestAndReview = createAsyncThunk("appTestAndReview", async props => {
  const tokenObj = AuthTokenService.get()
  const { data, nodeIdx, editorState, appDetails = [], schedulerData, delay_config, dispatch } = props
  dispatch(enableTestReviewLoader(nodeIdx))
  let dirty = false
  let payload = {}
  let UrlInfo = {}
  if (nodeIdx === 0) {
    // dispatch(moveActionToInitialState())
    const config = {}
    data.appEventSequences.map(x => {
      config[x.config_key] = x.id
    })

    payload = {
      left_app_id: data.id,
      left_app_event_id: data.appEvent,
      left_config: data.type === "ADD_ON" && data.provider === "scheduler" ? { ...config, ...schedulerData } : config,
      canvas_json: editorState,
      app_account_id: data.selectedAccount ? data.selectedAccount.id : null,
    }

    if (appDetails) {
      payload.appDetails = appDetails
    }

    UrlInfo = {
      url: `https://stagingapp.konnectzit.com/api/v1/konnects/test_and_review`,
      method: "POST",
      data: { ...payload },
      extra: {
        nodeId: data.nodeid,
      },

      headers: {
        Authorization: "Bearer " + tokenObj,
      },
    }
  } else {
    const _payload = payloadMaker({ editorState, appDetails, delay_config })
    payload = _payload.right_apps.find(x => x.nodeId === data.nodeid)
    delete payload.left_app_id
    delete payload.left_app_event_id
    delete payload.nodeId
    delete payload.type
    payload.canvas_json = editorState
    // if (editorState.konnect_activity_id) payload.konnect_activity_id = editorState.konnect_activity_id
    if (delay_config) {
      payload.delay_config = delay_config
      payload.delay_activity_id = data.delay_activity_id
      payload.parse_delay_config = true
    }
    if (data.konnect_activity_id) {
      payload.konnect_activity_id = data.konnect_activity_id
    }
    if (data.type === "ADD_ON" && data.provider === "conditioner") {
      payload.konnect_activity_id = data.konnect_activity_id
    }
    if (data.type === "ADD_ON" && data.provider === "api" && data.provider === "generator") {
      payload.konnect_activity_id = data.parent_konnect_activity_id
    }
    if (data.type === "ADD_ON" && data.provider === "api" && data.provider.toLowerCase() === "textsplitter") {
      payload.konnect_activity_id = data.parent_konnect_activity_id
    }

    editorState.nodes[nodeIdx].data.configResponses.map(x => {
      if (x.config_key_required && !x.value) {
        return (dirty = true)
      }
    })
    // if (dirty) {
    //   console.log("hello")
    //     dispatch(marksDirty(editorState))

    // }

    UrlInfo = {
      url: `https://stagingapp.konnectzit.com/api/v1/konnects/test_and_review`,
      method: "POST",
      data: { ...payload },
      extra: {
        nodeId: data.nodeid,
      },

      headers: {
        Authorization: "Bearer " + tokenObj,
      },
    }
  }
  if (dirty) {
    return { test_status: "Error", display_message: "Key Value Missing" }
  } else {
    try {
      let result = await axios(UrlInfo)
      let response = await result.data
      return response
    } catch (error) {
      console.log(error)
    }
  }
})
export const getConditionsList = createAsyncThunk("getConditionsList", async props => {
  const tokenObj = AuthTokenService.get()
  let UrlInfo = {
    url: `https://stagingapp.konnectzit.com/api/v1/konnects/conditions_list`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + tokenObj,
    },
  }
  let result = await axios(UrlInfo)
  let response = await result.data
  return response
})
export const generateportsId = props => {
  const { data, response } = props
  let newResponse = response.map(q => ({
    ...q,
    port: {
      id: `${data.nodeid.toString()}|${data.id.toString()}|${
        q.app_event_id ? q.app_event_id : data.appEvent ? data.appEvent : "undefined"
      }|${q.id.toString()}`,
      source:
        `${data.nodeid.toString()}|${data.id.toString()}|${
          q.app_event_id ? q.app_event_id : data.appEvent ? data.appEvent : "undefined"
        }|${q.id.toString()}` +
        "|" +
        "source",
      target:
        `${data.nodeid.toString()}|${data.id.toString()}|${
          q.app_event_id ? q.app_event_id : data.appEvent ? data.appEvent : "undefined"
        }|${q.id.toString()}` +
        "|" +
        "target",
    },
    source: {
      id:
        `${data.nodeid.toString()}|${data.id.toString()}|${
          q.app_event_id ? q.app_event_id : data.appEvent ? data.appEvent : "undefined"
        }|${q.id.toString()}` +
        "|" +
        "source",
    },
    target: {
      id:
        `${data.nodeid.toString()}|${data.id.toString()}|${
          q.app_event_id ? q.app_event_id : data.appEvent ? data.appEvent : "undefined"
        }|${q.id.toString()}` +
        "|" +
        "target",
    },
  }))
  return newResponse
}
export const getOauthData = createAsyncThunk("getOauthData", async props => {
  const tokenObj = AuthTokenService.get()
  let UrlInfo3 = {
    url: `https://stagingapp.konnectzit.com/api/v1/auth-url`,
    method: "POST",
    data: props,
    headers: {
      Authorization: "Bearer " + tokenObj,
    },
  }
  const response = await axios(UrlInfo3)
  const result = await response.data
  return result
})
export const updateAppStatus = createAsyncThunk("updateAppStatus", async props => {
  const tokenObj = AuthTokenService.get()
  let UrlInfo3 = {
    url: `https://stagingapp.konnectzit.com/api/v1/app_users/${props.appId}/update_status`,
    method: "POST",
    data: { status: props.status },
    headers: {
      Authorization: "Bearer " + tokenObj,
    },
  }
  const response = await axios(UrlInfo3)
  const result = await response.data
  return result
})

export const saveAuthLabel = createAsyncThunk("saveAuthLabel", async props => {
  const tokenObj = AuthTokenService.get()
  let UrlInfo3 = {
    url: `https://stagingapp.konnectzit.com/api/v1/app_users/${props.id}/update_label`,
    method: "POST",
    data: { label: props.label },
    headers: {
      Authorization: "Bearer " + tokenObj,
    },
  }
  const response = await axios(UrlInfo3)
  const result = await response.data

  return result
})

export const saveOauthData = createAsyncThunk("saveOauthData", async payload => {
  const tokenObj = AuthTokenService.get()
  let UrlInfo3 = {
    url: `https://stagingapp.konnectzit.com/api/v1/authorization`,
    method: "POST",
    data: payload,
    headers: {
      Authorization: "Bearer " + tokenObj,
    },
  }
  const response = await axios(UrlInfo3)
  const result = await response.data
  return result
})
export const getConditionsList1 = async props => {
  const tokenObj = AuthTokenService.get()
  let UrlInfo = {
    url: `https://stagingapp.konnectzit.com/api/v1/konnects/conditions_list`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + tokenObj,
    },
  }
  let result = await axios(UrlInfo)
  let response = await result.data
  return response
}

export const saveKonnect = createAsyncThunk("saveKonnect", async props => {
  const tokenObj = AuthTokenService.get()
  console.log(props, "987hyg")
  let { editorState, editorMeta } = props
  const newEditorState = {
    ...editorState,
    konnect_id: editorState.nodes[0].data.konnect_id,
  }
  const payload = {
    canvas_name: true,
    konnect_id: editorState.nodes[0].data.konnect_id,
    canvas_json: newEditorState,

    canvasname: "ReactFlow",
  }

  const allReviewed = editorState.nodes.every(node => node.data.reviewed)

  let url =
    props.publish && allReviewed
      ? `https://stagingapp.konnectzit.com/api/v1/konnects/publish_konnect`
      : `https://stagingapp.konnectzit.com/api/v1/konnects/save_konnect`

  let urlSaveKonnect = {
    url: url,
    method: "POST",
    data: payload,
    headers: {
      Authorization: "Bearer " + tokenObj,
    },
  }
  let result = await axios(urlSaveKonnect)
  let res = await result.data
  return res
})
export const getKonnect = createAsyncThunk("getKonnect", async props => {
  var tokenObj = AuthTokenService.get()

  let UrlInfo3 = {
    url: `https://stagingapp.konnectzit.com/api/v1/konnects/${props}/edit`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + tokenObj,
    },
  }
  const response = await axios(UrlInfo3)
  const result = await response.data
  return result
})

export const getAppEventConfigDetailCustomKeymap = createAsyncThunk(
  "getAppEventConfigDetailCustomKeymap",
  async props => {
    const tokenObj1 = AuthTokenService.get()

    const { data, app, appEvent, node, eventConfigId, app_account_id, targetPortInfo, editorState } = props

    const { nodes, links } = editorState

    const { target, targetHandle } = props

    const { config_key, id, key_value_type, label, type } = targetPortInfo
    const Node = nodes.find(n => n.id === data.nodeid)
    const port = data.configResponses.find(d => d.config_key === config_key).portId
    let prevSequence = false
    let source = false

    let url = `https://stagingapp.konnectzit.com/api/v1/apps/${app}/app_events/${appEvent}/event_config/${
      source ? "left" : "right"
    }/${`${Number(port) + 1}`}/details?`

    let params = ""

    if (data.configResponses.filter(d => d.config_key === config_key)[0].value) {
      data.appEventSequences.filter(
        d => d.config_key === config_key.slice(0, config_key.indexOf(">"))
      )[0].sequence.id = data.configResponses.filter(d => d.config_key === config_key)[0].value
      data.appEventSequences.filter(
        d => d.config_key === config_key.slice(0, config_key.indexOf(">"))
      )[0].id = data.configResponses.filter(d => d.config_key === config_key)[0].value
    }

    if (data.appEventSequences) {
      data.appEventSequences.map(x => {
        let ind = x.config_key.indexOf(">")
        params += `${x.config_key}=${eventConfigId}`
      })
    }
    if (prevSequence) {
      url = `${url}${prevSequence.config_key}=${prevSequence.id}${params}`
    }

    // url+=params&params

    if (app_account_id) url += `${params}&${params}&app_account_id=${app_account_id}`
    let UrlInfo5 = {
      url: url,
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
      extra: {
        ...props,
      },
    }

    const response = await axios(UrlInfo5)
    const res = await response.data
    return res
  }
)

export const getAppEventConfigDetailCustom = createAsyncThunk("getAppEventConfigDetailCustom", async props => {
  const tokenObj1 = AuthTokenService.get()
  const { data, config_key = "", input, port: eventConfigId = "", prevSequence = null, eventSequence } = props
  const { id, appEvent, appEventSequences, selectedAccount } = data
  let source = false
  //     const port =data.configResponses.find(d=>d.config_key===config_key).portId
  let url = `https://stagingapp.konnectzit.com/api/v1/apps/${id}/app_events/${appEvent}/event_config/${
    source ? "left" : "right"
  }/${eventConfigId + 1}/details?`

  let params = ""

  if (data.appEventSequences) {
    data.appEventSequences.map(x => {
      let ind = x.config_key.indexOf(">")
      params += `${x.config_key}=${input}`
    })
  }

  let newConfigKey = data.appEventConfigurations[eventSequence].config_key

  if (prevSequence) {
    url = `${url}${prevSequence.config_key}=${prevSequence.id}${params}`
  }

  if (selectedAccount.id) url += `$${params}&${params}&app_account_id=${selectedAccount.id}`
  let UrlInfo5 = {
    url: url,
    method: "GET",
    headers: {
      Authorization: "Bearer " + tokenObj1,
    },
    extra: {
      ...props,
    },
  }
  try {
    const response = await axios(UrlInfo5)
    const res = await response.data
    return res
  } catch (error) {
    console.log(error)
  }
})

export const getAppEventConfigDetailFetchuserDefined = createAsyncThunk(
  "getAppEventConfigDetailFetchuserDefined",
  async props => {
    const {
      data,
      nodeId,
      appId,
      eventId,

      selectedEventConfigId,
      selectedConfigKey,
      app_account_id,
      input,
      nodes,
    } = props
const tokenObj1 = AuthTokenService.get()

    const nodeIdx = nodes.findIndex(x => x.id === data.nodeid)
    let source = nodeIdx === 0
    let params = ""
    if (
      data.appEventSequences //&& data.provider !=="crove_new"
    ) {
      data.appEventSequences.map(x => {
        if (x.config_key !== "sheet_id" && x.id!=="") params += `&${x.config_key}=${x.id }`
      })
    }

    if (selectedConfigKey) {
      params = `?${selectedConfigKey.slice(0, selectedConfigKey.indexOf(">"))}=${selectedEventConfigId}${params}`
    }

    let url = `https://stagingapp.konnectzit.com/api/v1/apps/${appId}/app_events/${eventId}/event_config/${
      source ? "left" : "right"
    }/${selectedEventConfigId}/fetch-fields`

    url += `${params}`

    if (app_account_id) url += `&app_account_id=${app_account_id}`
    let UrlInfo5 = {
      url: url,
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
      extra: {
        ...props,
      },
    }
    let response = await axios(UrlInfo5)
    let result = await response.data
    return result
  }
)

export const getAppEventConfigDetailFetchKeyMap = createAsyncThunk(
  "getAppEventConfigDetailFetchKeyMap",
  async props => {
const tokenObj1 = AuthTokenService.get()

    const { data, nodeId, appId, eventId, selectedEventConfigId, app_account_id, editorState } = props
    const { nodes, links } = editorState
    const nodeIdx = nodes.findIndex(x => x.id === data.nodeid)
    let source = nodeIdx === 0
    let params = ""
    if (data.appEventSequences) {
      data.appEventSequences.map(x => {
        if (x.config_key !== "sheet_id" && x.id!=="") params += `&${x.config_key}=${x.id}`
      })
    }
    let selectedConfigKey = data.appEventSequences[data.eventSequence - 1].config_key

    if (selectedConfigKey) {
      params = `?${selectedConfigKey}=${selectedEventConfigId}${params}`
    }

    let url = `https://stagingapp.konnectzit.com/api/v1/apps/${appId}/app_events/${eventId}/event_config/${
      source ? "left" : "right"
    }/${selectedEventConfigId}/fetch-fields`

    url += `${params}`

    if (app_account_id) url += `&app_account_id=${app_account_id}`

    let UrlInfo5 = {
      url: url,
      method: "GET",
      headers: {
        Authorization: "Bearer " + tokenObj1,
      },
      extra: {
        ...props,
      },
    }
    let response = await axios(UrlInfo5)
    let result = await response.data
    return result
  }
)

export const onConnect = props => {
  const { EdgeType, c, dispatch, editorState } = props
  const { links, nodes } = editorState

  const { id, sourceHandle, targetHandle, source, target } = c
  // const node = nodes.find(n => n.id === target)
  // console.log("Node", node)
  const sourcePortInfo = JSON.parse(sourceHandle.split("|")[1])
  const targetPortInfo = JSON.parse(targetHandle.split("|")[1])
  let attachedLinked = false
  // if (node.data.type !== "ADD_ON") {
  //   links?.map(link => {
  //     if (
  //       JSON.parse(link.targetHandle.split("|")[1]).node === JSON.parse(c.targetHandle.split("|")[1]).node &&
  //       JSON.parse(link.targetHandle.split("|")[1]).id === JSON.parse(c.targetHandle.split("|")[1]).id
  //     ) {
  //       attachedLinked = true
  //     }
  //   })
  // }
  // if (!attachedLinked) {
  dispatch(onLinkComplete({ c, EdgeType }))
  const fromNodeId = source //node source

  const toNodeId = target

  const toNode = nodes.find(x => x.data.nodeid === toNodeId).data
  const fromNode = nodes.find(z => z.data.nodeid === fromNodeId).data

  const { app, appEvent, config_key, label, node, port } = targetPortInfo
  const Node = nodes.find(n => n.id === node)
  const { data } = Node
  const { eventSequence } = data

  const { appEventSequences, selectedAccount } = data
  const fromField = fromNode.configResponses.find(
    x => x.config_key === sourceHandle.split("|")[0] || x.id === sourceHandle.split("|")[0]
  )
  const toField = toNode.configResponses.find(
    x => x.config_key === targetHandle.split("|")[0] || x.id === targetHandle.split("|")[0]
  )
  if (config_key.includes(">")) {
    dispatch(
      getAppEventConfigDetailCustomKeymap({
        data,
        app: data.id,
        appEvent: data.appEvent,
        node,
        eventConfigId: fromField.value,
        app_account_id: data.selectedAccount.id,
        targetPortInfo,
        editorState,
      })
    )
    let selectedConfigKey = data.appEventSequences.filter(x => x.eventConfigId === eventSequence)[0].config_key

    let selectedEventConfigId = data.configResponses.filter(
      x => x.config_key.slice(0, x.config_key.indexOf(">")) === selectedConfigKey
    )[0].value

    if (
      data.appEventConfigurations.findIndex(x => x.sequence === eventSequence) ===
      data.appEventConfigurations.length - 1
    ) {
      dispatch(
        getAppEventConfigDetailFetchKeyMap({
          data,
          nodeId: data.nodeid,
          appId: data.id,
          eventId: data.appEvent,
          selectedEventConfigId: fromField.value,
          app_account_id: data.selectedAccount.id,
          editorState,
        })
      )
    }
    // }
  }
}
export const getAppsforGpt = async (searchQuery, authorizedOnly) => {
  let tokenObj1 = AuthTokenService.get()
  var UrlInfo = {
    url: `https://stagingapp.konnectzit.com/api/v1/apps`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + tokenObj1,
    },
  }
  if (searchQuery) {
    const qs = Object.keys(searchQuery)
      .map(key => `${key}=${searchQuery[key]}`)
      .join("&")

    UrlInfo.url = UrlInfo.url + `?${qs}`
  }
  try {
    let result = await axios(UrlInfo)
    let response = await result.data.apps
    return response
  } catch (error) {
    console.log(error)
  }
}
const getLinkedAccountsforGpt = async props => {
  var tokenObj1 = AuthTokenService.get()

  let UrlInfo = {
    url: `https://stagingapp.konnectzit.com/api/v1/apps/${props.id}/authorized_app_accounts`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + tokenObj1,
    },
  }
  try {
    let result = await axios(UrlInfo)
    let res = await result.data
    const { authorized_app_accounts: response } = res
    return response
  } catch (error) {
    console.log(error)
  }
}
const getEventGpt = async props => {
  var tokenObj1 = AuthTokenService.get()

  const { id, action, side } = props
  var UrlInfo1 = {
    url: `https://stagingapp.konnectzit.com/api/v1/apps/${id}/app_events`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + tokenObj1,
    },
  }

  try {
    let result = await axios(UrlInfo1)
    let response = await result.data

    return response
  } catch (error) {
    console.log(error)
  }
}

export const showOnCanvas = async payload => {
  const { items, dispatch } = payload
  const { app1, app2, app3, use_case, action1, action2, trigger } = items
  const idx = use_case.indexOf("when")
  let newString = use_case.slice(idx)
  let newarray = [app1, app2, app3]
  let leftApp
  let filteredArray = newarray.map(n => {
    if (newString.toLowerCase().includes(n.name.toLowerCase())) {
      leftApp = n
    }
  })
  let rightAppsArray = newarray.filter(r => r.name.toLowerCase() !== leftApp.name.toLowerCase())
  let x = leftApp.name
  let appsss = await getAppsforGpt({ search_query: x })
  let aaa = appsss.filter(y => y.name.toLowerCase() === leftApp.name.toLowerCase())

  let secondApp = await getAppsforGpt({ search_query: rightAppsArray[0].name })
  let secApp = secondApp.filter(y => y.name.toLowerCase() === rightAppsArray[0].name.toLowerCase())
  let thirdapp = await getAppsforGpt({ search_query: rightAppsArray[1].name })
  let thirapp = thirdapp.filter(y => y.name.toLowerCase() === rightAppsArray[1].name.toLowerCase())
  let getEventLeftData = await getEventGpt(leftApp)
  let getEventLeft = getEventLeftData.filter(x => x.side === "left")
  let accountsLeft = await getLinkedAccountsforGpt(leftApp)
  let getEventRightData = await getEventGpt(rightAppsArray[0])
  let getEventRight = getEventRightData.filter(x => x.side === "right")
  let getEventRight3Data = await getEventGpt(rightAppsArray[1])
  let getEventRight3 = getEventRight3Data.filter(x => x.side === "right")

  let accountsRight = await getLinkedAccountsforGpt(rightAppsArray[0])
  let accountsthird = await getLinkedAccountsforGpt(rightAppsArray[1])
  let newNode = {
    id: getId(),
  }
  let newNode1 = {
    id: getId(),
  }
  let newNode2 = {
    id: getId(),
  }
  let data = {
    id: aaa[0].id,
    name: aaa[0].name,
    auth_type: aaa[0].auth_type,
    background: aaa[0].background_color,
    image: aaa[0].image_url,
    provider: aaa[0].provider,
    associatedAccounts: aaa[0].account_count,
    webhook_enabled: aaa[0].webhook_enabled,
    webhook_instructions: aaa[0].webhook_instructions,
    type: aaa[0].app_type,
    webhook_url: aaa[0].webhook_url,
  }

  let data1 = {
    id: secApp[0].id,
    name: secApp[0].name,
    auth_type: secApp[0].auth_type,
    background: secApp[0].background_color,
    image: secApp[0].image_url,
    provider: secApp[0].provider,
    associatedAccounts: secApp[0].account_count,
    webhook_enabled: secApp[0].webhook_enabled,
    webhook_instructions: secApp[0].webhook_instructions,
    type: secApp[0].app_type,
    webhook_url: secApp[0].webhook_url,
  }
  let data2 = {
    id: thirapp[0].id,
    name: thirapp[0].name,
    auth_type: thirapp[0].auth_type,
    background: thirapp[0].background_color,
    image: thirapp[0].image_url,
    provider: thirapp[0].provider,
    associatedAccounts: thirapp[0].account_count,
    webhook_enabled: thirapp[0].webhook_enabled,
    webhook_instructions: thirapp[0].webhook_instructions,
    type: thirapp[0].app_type,
    webhook_url: thirapp[0].webhook_url,
  }
  let newNodes = [
    {
      id: newNode.id,
      position: { x: 500, y: 300 },
      data: {
        id: aaa[0].id,
        name: aaa[0].name,
        auth_type: aaa[0].auth_type,
        background: aaa[0].background_color,
        image: aaa[0].image_url,
        provider: aaa[0].provider,
        associatedAccounts: aaa[0].account_count,
        webhook_enabled: aaa[0].webhook_enabled,
        webhook_instructions: aaa[0].webhook_instructions,
        type: aaa[0].app_type,
        webhook_url: aaa[0].webhook_url,
        nodeid: newNode.id,
        linkedAccounts: accountsLeft ? accountsLeft : null,
        appEvent: getEventLeft ? getEventLeft.find(x => x.name.toLowerCase() === trigger.toLowerCase())?.id : null,
        appEventConfigurations: [],
        appEventSequences: [],
        selectedEvent: getEventLeft ? getEventLeft.find(x => x.name.toLowerCase() === trigger.toLowerCase()) : null,
        appEvents: getEventLeft ? getEventLeft : [],
        configResponses: [],
        tested: false,
        reviewed: false,
        isloading: false,
        showTestAndReview: aaa[0].app_type === "ADD_ON" && aaa[0].provider === "conditioner" ? false : true,
      },
      type: GetAppType({ droppedElement: data, nodes: [] }),
    },
    {
      id: newNode1.id,
      position: { x: 1000, y: 300 },
      data: {
        id: secApp[0].id,
        name: secApp[0].name,
        auth_type: secApp[0].auth_type,
        background: secApp[0].background_color,
        image: secApp[0].image_url,
        provider: secApp[0].provider,
        associatedAccounts: secApp[0].account_count,
        webhook_enabled: secApp[0].webhook_enabled,
        webhook_instructions: secApp[0].webhook_instructions,
        type: secApp[0].app_type,
        webhook_url: secApp[0].webhook_url,
        nodeid: newNode1.id,
        linkedAccounts: accountsRight ? accountsRight : null,
        selectedEvent: getEventRight
          .filter(s => s.side === "right")
          .find(x =>
            x.name.toLowerCase() === action2.toLowerCase()
              ? x.name.toLowerCase() === action2.toLowerCase()
              : x.name.toLowerCase() === action1.toLowerCase()
          )
          ? getEventRight
              .filter(s => s.side === "right")
              .find(x =>
                x.name.toLowerCase() === action2.toLowerCase()
                  ? x.name.toLowerCase() === action2.toLowerCase()
                  : x.name.toLowerCase() === action1.toLowerCase()
              )
          : null,
        appEvent: getEventRight
          .filter(s => s.side === "right")
          .find(x =>
            x.name.toLowerCase() === action2.toLowerCase()
              ? x.name.toLowerCase() === action2.toLowerCase()
              : x.name.toLowerCase() === action1.toLowerCase()
          )?.id
          ? getEventRight
              .filter(s => s.side === "right")
              .find(x =>
                x.name.toLowerCase() === action2.toLowerCase()
                  ? x.name.toLowerCase() === action2.toLowerCase()
                  : x.name.toLowerCase() === action1.toLowerCase()
              )?.id
          : null,
        appEventConfigurations: [],
        appEventSequences: [],
        appEvents: getEventRight,
        configResponses: [],
        tested: false,
        reviewed: false,
        isloading: false,
        showTestAndReview: secApp[0].app_type === "ADD_ON" && secApp[0].provider === "conditioner" ? false : true,
      },
      type: GetAppType({ droppedElement: data1, nodes: [1] }),
    },
    {
      id: newNode2.id,
      position: { x: 1600, y: 300 },
      data: {
        id: thirapp[0].id,
        name: thirapp[0].name,
        auth_type: thirapp[0].auth_type,
        background: thirapp[0].background_color,
        image: thirapp[0].image_url,
        provider: thirapp[0].provider,
        associatedAccounts: thirapp[0].account_count,
        webhook_enabled: thirapp[0].webhook_enabled,
        webhook_instructions: thirapp[0].webhook_instructions,
        type: thirapp[0].app_type,
        webhook_url: thirapp[0].webhook_url,
        nodeid: newNode2.id,
        linkedAccounts: accountsthird,
        appEvent: getEventRight3.find(n =>
          n.name.toLowerCase() === action1.toLowerCase()
            ? n.name.toLowerCase() === action1.toLowerCase()
            : n.name.toLowerCase() === action2.toLowerCase()
        )?.id
          ? getEventRight3.find(n =>
              n.name.toLowerCase() === action1.toLowerCase()
                ? n.name.toLowerCase() === action1.toLowerCase()
                : n.name.toLowerCase() === action2.toLowerCase()
            )?.id
          : null,
        selectedEvent: getEventRight3.find(n =>
          n.name.toLowerCase() === action1.toLowerCase()
            ? n.name.toLowerCase() === action1.toLowerCase()
            : n.name.toLowerCase() === action2.toLowerCase()
        )
          ? getEventRight3.find(n =>
              n.name.toLowerCase() === action1.toLowerCase()
                ? n.name.toLowerCase() === action1.toLowerCase()
                : n.name.toLowerCase() === action2.toLowerCase()
            )
          : null,
        appEventConfigurations: [],
        appEventSequences: [],
        appEvents: getEventRight3,
        configResponses: [],
        tested: false,
        reviewed: false,
        isloading: false,
        showTestAndReview: thirapp[0].app_type === "ADD_ON" && thirapp[0].provider === "conditioner" ? false : true,
      },
      type: GetAppType({ droppedElement: data2, nodes: [1, 2] }),
    },
  ]

  return dispatch(showChatGpt(newNodes))
}
