import React from "react"
import styled from "@emotion/styled"

const StyledLayer = styled.div`
  .datePickerInput {
    background: white;
    border: 1px solid #aaaaaa;
    border-radius: 8px;
    padding: 10px;
    .react-daterange-picker__wrapper {
      border: 0px;
    }
    .react-calendar {
      background: white;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
    .react-daterange-picker__calendar {
      z-index: 1000;
    }
  }
`

export default StyledLayer
