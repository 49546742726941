import React, { memo, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Box, Text, Flex, Link } from "rebass"
import ReactTooltip from "react-tooltip"
import { Modal } from "react-responsive-modal"
import { useData } from "../../../Context/canvasContext"
import { ApiModal } from "./modals/apimodal"

import {
  AppInfo,
  ConnectorOuter,
  ConnectorPortOuter,
  ConnectorPortLabel,
  ConnectorPortInput,
  ConnectorPortText,
  NodeOuter,
  SelectionHeader,
  EventSelectionGroup,
  TestAndReviewButton,
  ParamsButton,
  AddMoreFieldButton,ConnectorPortError
} from "../../konnect/UI"
import { ConditionsModal } from "./modals/apimodal"
import { CommonOuter } from "../NodeInner/CommonOuter"
import { AdditionalConfigsModal } from "../../../../../components/Konnect/Base/Components/NodeInner/modals/additionalConfigsModal" //./modals/additionalConfigsModal
import { Handle } from "react-flow-renderer"

import { Dropdown } from "../../../../../components/Konnect/DropdownMenu"
import { useSelector, useDispatch } from "react-redux"
import {
  onDeleteNode,
  setConfigMenuState,
  
  onPortTextChange,
  updatePayloadType,
  updateUrl,
  updateWrapRequestInArray,
  createAdditionalFields,
  updateHeadersAndParams,addFieldstoconfig,
} from "../../../store/slice/canvasSlice"
import {
  onDeleteNodeV2,
  getAppEventConfig,
 
  appTestAndReview,
} from "../../../features/index"

export const ApiInner = memo(({ data, isConnectable }) => {
  const dispatch = useDispatch()
  const { editorState, customAppData } = useSelector(state => state.canvas.canvas)
  const { nodes } = editorState
  const { t } = useTranslation()
  const [event, setEvent] = useState(!!data.appEvent)

  const [openConditions, setOpenConditions] = useState(false)
  const [IsDeleteShown, setIsDeleteShown] = useState(false)
  const [openAdditionalConfigs, setOpenAdditionalConfigs] = useState(false)
  const [addedKeys, setAddedKeys] = useState([])
  const [nodeReviewed, setNodeReviewed] = useState(data.tested ? data.reviewed : null)
  const [nodeTested, setNodeTested] = useState(data.tested ? data.tested : null)
  const [searchEvents, setSearchEvents] = useState("")
  const [searchPayloadTypes, setSearchPayloadTypes] = useState("")
  const [searchWrapRequests, setSearchWrapRequests] = useState("")
  const [addit, setAddit] = useState([])
 
  const { endpointURL, payloadType, wrapRequestInArray, headers, params } = data.api

  const nodeIdx = nodes.findIndex(x => x.data.nodeid === data.nodeid)
  const target = nodeIdx !== 0

  let hasSequence =
    data.appEventConfigurations && data.appEventConfigurations.filter(x => x.sequence !== 0).length ? true : false

  let configForms = []

  if (hasSequence) {
    configForms = data.appEventConfigurations.slice(0, data.appEventSequences.length + 1)
  }

  const onCloseModal = list => {
    setOpenConditions(false)
  }

  const handleConditionsModal = event => {
    setOpenConditions(true)
  }


  let sections = []
  const extract = (obj, parentKey) => {
    Object.keys(obj).map(key => {
      if (typeof obj[key] === "object") {
        extract(obj[key], key)
        sections.push({ label: key, key: parentKey ? parentKey + "$." + key : key, items: { ...obj[key] } })
        delete obj[key]
      }
    })
  }

  const getConditionsInfo = () => {
    return conditions[0].key
  }

  const handleAdditionalConfigsClick = e => {
    setOpenAdditionalConfigs(true)
  }
 
  const prepareElements = (responses, additional) => {
    let formElements = []
    if (responses) {
      formElements = responses.map((x,idx) => {
        const portInfo = {
          node: data.nodeid,
          app: x.app_id,
          appEvent: data.appEvent,
          port: x.id,
          label: x.label,
          id: x.id, 
          config_key: x.config_key,
        }
        let ConnectorPort = x.key_value_type === "text" ? ConnectorPortText : ConnectorPortInput
        const __key = x.config_key
          .replace(/[^a-zA-Z ]/g, "")
          .split(" ")
          .join("_")

        if (nodeIdx === 0 || x.disabled) {
        // disable = true
        }
        let Data = []

        return (
          <>
            <ConnectorPortLabel>{x.label}</ConnectorPortLabel>
            <Flex
              sx={{
                flexDirection: "column",
              }} key ={idx}
            >
              <Flex >
                {target && !x.additional ? (
                  <ConnectorPortOuter placement="left" type={x.key_value_type}>
                    <Handle
                      type="target"
                      position="left"
                      // isConnectable="true"
                      isConnectable={true}
                      style={{
                        height: "20px",
                        background: "#152f73",
                        width: "20px",
                        borderRadius: "60%",
                        border: "1px solid #152f73",
                      }}
                      id={x.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "target" })}
                    />
                  </ConnectorPortOuter>
                ) : null}
                <ConnectorPort
                  rows={4}
                  dirty={x.value ? false : !!x.dirty}
                  key={`${x.app_id}_${__key}`}
                  onMouseDown={e => {
                    e.stopPropagation()
                  }}
                  defaultValue={x.value ? x.value : ""}
                  placeholder={
                    !x.dirty ? x.label : `${x.label}*`
                  }
                  onChange={e => {
                    dispatch(
                      onPortTextChange({
                        data,
                        nodeId: data.nodeid,
                        portId: x.id,
                        config_key: x.config_key,
                        input: e.target.value,
                      })
                    )
                  
                  }}
                  data={Data}
                  additional={x.additional}
                  appEventSequences
                ></ConnectorPort>

                <ConnectorPortOuter placement="right" type={x.key_value_type}>
                  <Handle
                    type="source"
                    position="right"
                    // isConnectable="true"
                    style={{
                      height: "20px",
                      background: "#152f73",
                      width: "20px",
                      borderRadius: "60%",
                      border: "1px solid #152f73",
                    }}
                    isConnectable={data.tested}
                    id={x.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "source" })}
                  />
                </ConnectorPortOuter>
              </Flex>
              <Flex
                sx={{
                  alignSelf: "end",
                  mt: "8px",
                }}
              >
                {x.error ? <ConnectorPortError>{x.error}</ConnectorPortError> : null}
              </Flex>
            </Flex>
          </>
        )
      })
      return formElements
    }
  }
  let formResponseElements = prepareElements(data.configResponses)
  
  const onCloseAdditionalConfigsModal = list => {
    setOpenAdditionalConfigs(false)
    const selected = list.filter(x => x.selected)
    setAddedKeys(selected)
    dispatch(createAdditionalFields({ fields: selected, nodeId: data.nodeid }))
  }

  const onDropDownChange = (list, val, name) => {
    let list1 = JSON.parse(JSON.stringify(list))

    list1.forEach(obj => {
      if (obj.id === val.id) {
        obj.selected = true
      } else {
        obj.selected = false
      }
    })
    if (name === "payload") {
      dispatch(updatePayloadType({ value: list1, nodeIdx: nodeIdx, data }))
    } else if (name === "wrap_request") {
      dispatch(updateWrapRequestInArray({ value: list1, nodeIdx: nodeIdx, data }))
    }
  }

  const updateHeaderAndParamsDetails = (headersList, paramsList) => {
    const headerNodeDetails = prepareNodeConfigDetails(headersList, "Header")
    const paramNodeDetails = prepareNodeConfigDetails(paramsList, "Param")
    dispatch(
      updateHeadersAndParams({
        headers: headersList,
        params: paramsList,
        nodeId: data.nodeid,
        nodeIdx: nodeIdx,
        newNodeDetails: headerNodeDetails.concat(paramNodeDetails),
      })
    )
    onCloseModal()
  }

  const prepareNodeConfigDetails = (list, text) => {
    const nodes = []
    list.forEach(obj => {
      if (obj.key !== "") {
        nodes.push({
         

         
          sequence: 0,
          config_key: obj.key,
          error: null,
          key_value_type: "input",
          value: "",
          mapped: true,
          label: `${text}- ${obj.key}`,
          side: "left",
          app_id: data.id,
        

          id: obj.key,
          config_key_required: true,
          app_event_id: data.appEvent,
          fetch_fields: false,
          ts: new Date().getTime(),
        })
      }
    })
    return nodes
  }

  useEffect(() => {
    setNodeTested(data.tested ? data.tested : null)
    setNodeReviewed(data.tested ? data.reviewed : null)
  }, [data.tested, data.reviewed])
  return (
    <>
      <CommonOuter node={data} reviewed={nodeReviewed} nodeTested={nodeTested} />
      <NodeOuter
        onMouseEnter={() => setIsDeleteShown(true)}
        onMouseLeave={() => setIsDeleteShown(false)}
        isFirst={!nodeIdx}
        reviewed={nodeReviewed}
      >
        <AppInfo
          title={data.name}
          subtitle={data.account}
          image={data.image}
          className="DraggableHandle"
          background={data.background}
          hasDelete={IsDeleteShown && (nodeIdx !== 0 || nodes.length === 1)}
          onDelete={() => {
            if (data.tested || data.konnect_activity_id) {
              dispatch(
                onDeleteNodeV2({
                  konnect_id: nodes[0].data.konnect_id,
                  konnect_activity_id: data.konnect_activity_id,
                  canvas_json: editorState,
                  nodeId: data.nodeid,
                })
              ).then(res => {
                dispatch(onDeleteNode(data))
              })
            } else {
              dispatch(onDeleteNode(data))
            }
          }}
        ></AppInfo>
        <>
        <div className="nowheel">

          <EventSelectionGroup>
            <SelectionHeader>
              {data.appEvents  && nodeIdx !== 0
                ? t("konnect.sidebar.choose_action_event")
                : t("konnect.sidebar.choose_trigger")}
            </SelectionHeader>
            <Dropdown
              searchable={true}
              searchValue={searchEvents}
              onFilterChange={text => {
                setSearchEvents(text)
              }}
              inputPlaceholder={!searchEvents ? "Search field" : ""}
              disableIfEmpty
              headerTitle={t("konnect.sidebar.select_trigger_action_dropdown_header")}
              items={data.appEvents}
              renderKey="name"
              selected={data.appEvent ? data.appEvents.find(x => x.id === data.appEvent).name : null}
              onChange={selected => {
                dispatch(
                  getAppEventConfig({
                    data,
                    nodeId: data.nodeid,
                    appId: data.id,
                    eventId: selected.id,
                    source: nodeIdx === 0,
                    type: selected.side === "left" ? "source" : selected.side === "right" ? "target" : "both",
                  })
                )
                setSearchEvents("")
              }}
              onChangeState={isOpen => {
              dispatch(  setConfigMenuState({ isOpen }))
              }}
            />
          </EventSelectionGroup>
          </div>

          {data.appEvent != null ? (
            <>
              <EventSelectionGroup>
                <SelectionHeader>{t("konnect.sidebar.endpoint_url")}</SelectionHeader>
                <ConnectorPortInput
                 
                  onChange={e => {
                    dispatch(updateUrl({ value: e.target.value, nodeIdx: nodeIdx }))
                  }}
                  defaultValue={endpointURL ? endpointURL : ""}
                  
                />
              </EventSelectionGroup>

              <EventSelectionGroup>
                <SelectionHeader>{t("konnect.sidebar.payload_type")}</SelectionHeader>
                <Dropdown
                  searchable={true}
                  searchValue={searchPayloadTypes}
                  onFilterChange={text => {
                    setSearchPayloadTypes(text)
                  }}
                  inputPlaceholder={!searchPayloadTypes ? "Search field" : ""}
                  disableIfEmpty
                  items={payloadType}
                  renderKey="name"
                  selected={payloadType.find(x => x.selected === true).name}
                  onChange={selected => {
                    
                    onDropDownChange(payloadType, selected, "payload")
                    setSearchPayloadTypes("")
                  }}
                  onChangeState={isOpen => {
                    dispatch(setConfigMenuState({ isOpen }))
                  }}
                />
              </EventSelectionGroup>

              <EventSelectionGroup>
                <SelectionHeader>{t("konnect.sidebar.wrap_request_array")}</SelectionHeader>
                <Dropdown
                  searchable={true}
                  searchValue={searchWrapRequests}
                  onFilterChange={text => {
                    setSearchWrapRequests(text)
                  }}
                  inputPlaceholder={!searchWrapRequests ? "Search field" : ""}
                  disableIfEmpty
                  items={wrapRequestInArray}
                  renderKey="name"
                  selected={wrapRequestInArray.find(x => x.selected === true).name}
                  onChange={selected => {
                    onDropDownChange(wrapRequestInArray, selected, "wrap_request")
                    setSearchWrapRequests("")
                  }}
                  onChangeState={isOpen => {
                    dispatch(setConfigMenuState({ isOpen }))
                  }}
                />
              </EventSelectionGroup>

              <EventSelectionGroup>
                <>
                  <SelectionHeader></SelectionHeader>
                  <ParamsButton onClick={handleConditionsModal}>{t("konnect.sidebar.set_params")}</ParamsButton>
                </>
              </EventSelectionGroup>

              <EventSelectionGroup>
                <>
                  <SelectionHeader></SelectionHeader>
                  <TestAndReviewButton
                    onClick={() => {
                     dispatch( appTestAndReview({
                        data,
                        nodeIdx: nodeIdx,
                        editorState: editorState,
                        appDetails: data.api,
                        dispatch,
                      }))
                    }}
                  >
                    Test & Review
                  </TestAndReviewButton>
                  <SelectionHeader />
                  {formResponseElements.map(form => form)}
                </>
              </EventSelectionGroup>
            </>
          ) : null}
        </>
      </NodeOuter>
      <Modal
        open={openConditions}
        onClose={onCloseModal}
        onAnimationEnd={() => {
          scrollTo(0, 0)
        }}
        showCloseIcon={false}
        center
        styles={{ modal: { padding: "0px", borderRadius: "16px", minWidth: "1100px", minHeight: "700px" } }}
      >
        <ApiModal
          closeModal={onCloseModal}
          updateHeaderAndParamsDetails={updateHeaderAndParamsDetails}
          headers={headers}
          fromFields={data.fromFields}
          params={params}
          konnect_activity_id={data.parent_konnect_activity_id}
        />
      </Modal>
      {data.additionalResponses && data.additionalResponses.length ? (
        <AddMoreFieldButton
          onClick={e => {
            handleAdditionalConfigsClick(e)
          }}
        ></AddMoreFieldButton>
      ) : null}
      <Modal
        open={openAdditionalConfigs}
        onClose={onCloseAdditionalConfigsModal}
        onAnimationEnd={() => scrollTo(0, 0)}
        center
        styles={{ modal: { padding: "0px", borderRadius: "16px", minWidth: "1100px", minHeight: "700px" } }}
      >
        <AdditionalConfigsModal
          closeModal={onCloseAdditionalConfigsModal}
          addedKeys={addedKeys}
          data={data.additionalResponses}
          appIcon={data.image}
        />
      </Modal>
    </>
  )
})
