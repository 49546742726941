import React, { useEffect, useState } from "react"
import { Box, Text, Flex, Link } from "rebass"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw"
import { Button, Input } from "src/components/index"
import { Dropdown } from "../Dropdown"
import { SelectionHeader } from "../UI"
import { DatePicker, Space, TimePicker } from "antd"
import moment from "moment"
import { svg } from "../../../asset/svg.js"

export const DelayModal = ({
  closeModal,
  onDeleteClick,
  onCloseDelayModal,
  setOpenDelayModal,
  setDelayConfig,
  delayConfig,
}) => {
  const [delayValues, setDelayValues] = useState("")
  const [delayUnit, setDelayUnit] = useState("")
 // const [dateString, setDateString] = useState("")
  const [delayConfigInModal, setDelayConfigInModal] = useState(delayConfig)
  const today = moment()
  const todayDate = today.format("DD/MM/YYYY HH:mm:ss")
  const value = [
    {
      id: 515,
      name: "Delay for",
    },
    {
      id: 328,
      name: "Delay Until",
    },
  ]

  const delayUnitValues = [
    {
      id: 514,
      name: "Minutes",
    },
    {
      id: 322,
      name: "Hours",
    },
    {
      id: 511,
      name: "Days",
    },
    {
      id: 3228,
      name: "Weeks",
    },
  ]
  const onChangeDatePicker = (value, dateString) => {
    console.log(value,"yuguyegveyf",dateString)
    // setDateString(dateString)
    setDelayConfigInModal(delayConfig => ({
      ...delayConfig,
      delay_unit: dateString,
    }))
  }

  const handleSave = () => {
    setOpenDelayModal(false)
    setDelayConfig(delayConfigInModal)
  onCloseDelayModal(delayConfigInModal)

  }

  const { t } = useTranslation()
  return (
    <>
      <Flex flexDirection="column" sx={{ fontWeight: "400", borderRadius: "16px", px: px2vw(60), padding: "0px",width:"100%" }}>
        <Flex flexDirection="row" sx={{justifyContent:"space-between"}}>
          <Text fontSize={20} sx={{ color: "#152F73" }}>
            Delay
          </Text>
          <Flex
            sx={{
              "&:hover": {
                transform: "scale(1.2)",
              },
              transition: "transform 0.25s",
              cursor: "pointer",
              width: ["20px", "22px", "24px", "26px"],
              fill: "red",
            }}
            onClick={() => {
              setOpenDelayModal(false)
              setDelayConfig(delayConfig)

            }}
          >
            {svg.xmark}
          </Flex>
        </Flex>
        <Box sx={{ background: "#BCC6CC", width: "auto", height: "1px", margin: "14px -20px" }}></Box>
        <Box sx={{ p: px2vw(20), justifyContent: "center", textAlign: "center" }}>
          <SelectionHeader style={{ fontWeight: "400", display: "flex", justifyContent: "left" }}>
            {"How much delay you want"}
          </SelectionHeader>

          <Dropdown
            searchable={true}
            searchValue={delayValues}
            onFilterChange={text => {
              setDelayValues(text)
            }}
            inputPlaceholder={!delayValues ? "Search field" : ""}
            headerTitle={"Select Value"}
            items={value}
            renderKey="name"
            selected={delayConfigInModal && delayConfigInModal.delay_type ? delayConfigInModal.delay_type : ""}
            onChange={selected => {
              setDelayConfigInModal(delayConfig => ({
                delay_unit: "",
                delay_value: "",
                delay_type: selected.name,
              }))
              // if (selected.name === "Delay Until") {
              //   setDelayConfigInModal(delayConfig => ({
              //     ...delayConfig,
              //     delay_unit: "",
              //   }))
              // }
            }}
            onChangeState={()=>{}}

          />

          {delayConfigInModal &&
          delayConfigInModal.delay_type === "Delay for" &&
          delayConfigInModal.delay_type !== "Delay Until" ? (
            <>
              {" "}
              <SelectionHeader style={{ fontWeight: "400", display: "flex", justifyContent: "left" }}>
                {"How much delay you want"}
              </SelectionHeader>
              <Dropdown
                searchable={true}
                searchValue={delayUnit}
                onFilterChange={text => {
                  setDelayUnit(text)
                }}
                inputPlaceholder={!delayUnit ? "Search field" : ""}
                headerTitle={"Select Value"}
                items={delayUnitValues}
                renderKey="name"
                selected={
                  delayConfigInModal.delay_unit && delayConfigInModal.delay_type === "Delay for"
                    ? delayConfigInModal.delay_unit
                    : ""
                }
                onChange={selected => {
                  setDelayConfigInModal(delayConfig => ({
                    ...delayConfig,

                    delay_unit: selected.name ? selected.name : dateString,
                  }))
                  if (selected.name === delayConfig.delay_unit) {
                    // alert("assd")
                    setDelayConfigInModal(delayConfig => ({
                      ...delayConfig,
                      delay_unit: "",
                    }))
                  }
                }}
              onChangeState={()=>{}}

              />
              <Box sx={{ mt: "0px" }}>
                <SelectionHeader style={{ fontWeight: "400", display: "flex", justifyContent: "left" }}>
                  {"How much delay you want"}
                </SelectionHeader>
                <Input
                  type="number"
                  defaultValue={delayConfigInModal.delay_value ? delayConfigInModal.delay_value : ""}
                  sxProps={{
                    "& ~ span": {
                      color: "#B7CBFF !important",
                      //   paddingTop: "0px",
                      pl: "5px",
                      width: "80%",
                      fontSize: "12px",
                      border: "0px solid red",
                    },
                  }}
                  width="100%"
                  placeholder={"Enter Value"}
                  onChange={evt => {
                    setDelayConfigInModal(delayConfig => ({
                      ...delayConfig,

                      delay_value: evt.target.value,
                    }))
                  }}
                />
              </Box>
              <button
                style={{
                  width: "100%",
                  backgroundColor: "#F7C545",
                  marginTop: "20px",
                  border: "none",
                  height: "40px",
                  fontSize: "18px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={handleSave}
              >
                Save
              </button>
            </>
          ) : null}
        </Box>

        {
        (delayConfigInModal && delayConfigInModal.delay_type === "Delay Until") ? (
          <>
            <Box sx={{ p: "0px 20px 0px 20px" }}>
              <SelectionHeader style={{ fontWeight: "400", display: "flex", justifyContent: "left" }}>
                {"How much delay you want"}
              </SelectionHeader>
              <Space direction="vertical" size={16}>
                <DatePicker
                  showTime
                  onChange={onChangeDatePicker}
                  size="large"
                  format="DD/MM/YYYY HH:mm:ss"
                  defaultValue={moment(todayDate, "DD/MM/YYYY HH:mm:ss")}
                  value={moment(
                    delayConfigInModal && delayConfigInModal.delay_unit
                      ? delayConfigInModal.delay_unit
                      : moment(todayDate, "DD/MM/YYYY HH:mm:ss"),
                    "DD/MM/YYYY HH:mm:ss"
                  )}
                  style={{ width: "420px", marginTop: "1%", antPickerCell: { color: "red" } }}
                />
              </Space>
            </Box>
            <button
              style={{
                backgroundColor: "#F7C545",
                padding: "0px 20px 0px 20px",
                margin: "20px",
                border: "none",
                height: "40px",
                fontSize: "18px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onClick={handleSave}
            >
              Save
            </button>
          </>
        ) : null}
      </Flex>
    </>
  )
}
