import { v4 } from "uuid"
import { isEqual, isEmpty } from "lodash"
import { FlattenJSON } from "./utils"
import { fromJS } from "immutable"
import { rotate } from "../../components/Konnect/Base/Components/"
import {
  GET_ALL_APPS,
  GET_KONNECTS_LIST,
  GET_AUTHORISED_APPS,
  GET_APP_EVENTS,
  GET_ASSOCIATED_ACCOUNTS,
  SET_LINKED_ACCOUNT,
  GET_CONDITIONS_LIST,
  GET_EVENT_CONFIGURATIONS,
  GET_EVENT_CONFIGURATIONS_DETAILS,
  GET_EVENT_CONFIGURATIONS_DETAILS_FETCH,
  SET_APP_EVENT_CONFIG_VALUE,
  GET_EDITOR_STATE,
  SET_EDITOR_STATE,
  SET_KONNECT_NAME,
  GET_KONNECT_NAME,
  SUCCESS,
  FAIL,
  GET_ADDON_APPS,
  ONDRAGNODE,
  ONDRAGNODESTOP,
  ONDRAGNODESTART,
  ONCANVASDROP,
  ONLINKSTART,
  ONLINKMOVE,
  ONLINKCOMPLETE,
  ONLINKCANCEL,
  ONPORTPOSITIONCHANGE,
  ONLINKMOUSEENTER,
  ONLINKMOUSELEAVE,
  ONLINKCLICK,
  ONCANVASCLICK,
  ONNODECLICK,
  ONNODEMOUSEENTER,
  ONNODEMOUSELEAVE,
  ONNODESIZECHANGE,
  ONPORTTEXTCHANGE,
  TOGGLESIDEBAR,
  EDITORSCALE,
  EDITORREDO,
  EDITORUNDO,
  CLEARSTATE,
  ONDRAGCANVAS,
  ONDRAGCANVASSTOP,
  GETKONNECT,
  BASIC_AUTH,
  ONWEBHOOKAUTHCHANGE,
  CAPTUREWEBHOOKRESPONSE,
  ONDELETENODE,
  ONSETASFIRSTNODE,
  ONDELETELINK,
  MARKSTOREDIRTY,
  EXPANDCOLLAPSENODE,
  APPTESTANDREVIEW,
  CREATEADDITIONALFIELDS,
  SET_CONFIG_MENU_STATE,
  ONDELETENODEV2,
  MODIFYCONDITIONS,
  MODIFY_HTTP_METHOD,
  MODIFY_URL_ENDPOINT,
  MODIFY_PAYLOAD_TYPE,
  MODIFY_WRAP_REQUEST_IN_ARRAY,
  MODIFY_HEADERS_AND_PARAMS,
  MODIFY_DURATION,
  MODIFY_EXPIRY_NUM,
  MODIFY_SEGMENT_INDEX,
  UPDATE_GSHEET_POP_UP,
  ENABLE_TEST_REVIEW_LOADER,
} from "config/Constants"

const apiAppInitialState = {
  endpointURL: "",
  payloadType: [{ id: 1, name: "JSON", selected: true }],
  wrapRequestInArray: [
    { id: 1, name: "NO", selected: true },
    { id: 2, name: "YES", selected: false },
  ],
  headers: [{ key: "" }],
  params: [{ key: "" }],
}
const couponInitialState = {
  expiryNum: "",
  duration: [
    { id: 1, name: "Days", selected: true },
    { id: 2, name: "Week", selected: false },
    { id: 3, name: "Months", selected: false },
    { id: 4, name: "Years", selected: false },
  ],
}
const textSplitterInitialState = {
  segmentIndex: [
    { id: 1, name: "First", selected: true },
    { id: 2, name: "Second", selected: false },
    { id: 3, name: "Last", selected: false },
    { id: 4, name: "Second to Last", selected: false },
    { id: 5, name: "All", selected: false },
  ],
}
const initState = {
  editorMeta: {
    sidebar: { hidden: false },
    deleteLink: false,
    onWheel: true,
    deleteLinkPos: {
      x: 0,
      y: 0,
    },
    link: null,
    dirty: false,
    canPublish: false,
  },
  apps: {},
  konnects: [],
  authorisedApps: [],
  appEvents: [],
  conditionsList: [],
  appEventConfig: [],
  appEventConfigDetails: [],
  history: {
    /*
    maintain only the props that needs to be tracked. Merge to state when needed
    {
    nodes: [],
    ports: [],
    links: [],  
    payload: {}
    }
    nodes: [],
    ports: [],
    links: [],
    */
    past: [],
    future: [],
    present: {
      nodes: [],
      ports: [],
      links: [],
      payload: {},
    },
  },
  editorState: {
    offset: {
      x: -5000,
      y: -5000,
    },
    scale: 1,
    nodes: [],
    ports: [],
    links: [],
    selected: {},
    hovered: {},
    konnect_activity_id: null,
    konnect_name: "",
  },

  apiAppInitialState: apiAppInitialState,
  customAppData: {
    coupon: couponInitialState,
    textSplitter: textSplitterInitialState,
  },
  gSheetPopUp: false,
  payload: {},
  testAndReviewLoader: false,
  //enableCaptureWebhookResponseBtn: true
}
//what is changehisyory function

const changeHistory = (state, event, data) => {
  const history = state.get("history").toJS()
  const editorState = state.get("editorState").toJS()
  const editorMeta = state.get("editorMeta").toJS()

  editorMeta.canPublish = editorState.nodes.every(x => x.tested && x.reviewed) //??????
  const _editorMeta = fromJS(editorMeta)
  const { nodes = [], ports = [], links = [] } = data

  let curr = {
    nodes,
    links,
    ports,
  }

  // let enableCaptureWebhookResponseBtn = true

  // /* Check if there is webhook node in the nodes list */
  // let webhookNodeId = null
  // nodes.map(node => {
  //   if(node.provider === "webhook") {
  //     webhookNodeId = node.nodeId
  //   }
  // })

  // /* Check if the webhook node's ports connected to any ports of other node */
  // if(webhookNodeId && links.length > 0) {
  //   links.map(link => {
  //     if(link.from.node === webhookNodeId) {
  //       enableCaptureWebhookResponseBtn = false
  //     }
  //   })
  // }
  switch (event) {
    case ONCANVASDROP:
    case ONDRAGNODESTOP:
   // case SET_LINKED_ACCOUNT:
   // case GET_EVENT_CONFIGURATIONS:
   // case GET_EVENT_CONFIGURATIONS_DETAILS_FETCH:
    //case SET_APP_EVENT_CONFIG_VALUE:
    case ONPORTTEXTCHANGE:
    //case APPTESTANDREVIEW:
    case ONLINKCOMPLETE:
    case BASIC_AUTH:
    case ONWEBHOOKAUTHCHANGE:
   // case ONDELETENODE:
    case ONSETASFIRSTNODE:
    case ONDELETELINK:
    case MODIFYCONDITIONS:
      {
        const lastHistory = history.present

        if (lastHistory && !isEqual(lastHistory, curr)) {
          history.past.push(history.present)
          history.present = curr
        }
      }
      break
   // case GET_APP_EVENTS:
  //  case GET_ASSOCIATED_ACCOUNTS:
      const _last = history.present
      {
        history.present = curr
      }
      break
  }
  history.future = []
  const _history = fromJS(history)

  return state.set("history", _history).set("editorMeta", _editorMeta)
}

const konnectReducer = (state = initState, action) => {
  switch (action.type) {
    case TOGGLESIDEBAR:
      const editorState = state.get("editorMeta").toJS() //why not editormeta here

      editorState.sidebar.hidden = editorState.sidebar.hidden
      const _editorState = fromJS(editorState)
      return state.set("editorMeta", _editorState)
    case MARKSTOREDIRTY: {
      let editorState = state.get("editorState").toJS()
      const editorMeta = state.get("editorMeta").toJS()
      const { data } = action
      editorState = data
      editorState.ts = new Date().getTime()
      editorMeta.dirty = true
      const _editorMeta = fromJS(editorMeta)
      const _editorState = fromJS(editorState)
      return state.set("editorMeta", _editorMeta).set("editorState", _editorState)
    }
    case GET_ADDON_APPS + SUCCESS:
      // let apps2 = state.get("addon_apps")
      // apps2 = action.payload
      // const searchQueryy = action.meta.previousAction.payload
      // console.log(searchQueryy, "searchQuery1",action)
      return state.set("addon_apps", action.payload ? action.payload : [])

      case GET_ADDON_APPS + FAIL:
        return state.set("addon_apps", [])
  
    case GET_ALL_APPS + SUCCESS:
      let apps1 = state.get("apps")
      const searchQuery1 = action.meta.previousAction.payload.request.searchQuery
      if (searchQuery1.page_size === 20 ) {
        apps1 = action.payload.apps
      } else apps1 = [...apps1, ...action.payload.apps]
      const _tasks = fromJS(apps1)
      return state.set("apps",apps1)
     // return state.set("apps", action.payload ? action.payload : [])

    case GET_ALL_APPS + FAIL:
      return state.set("apps", [])

    case GET_KONNECTS_LIST + SUCCESS:

    let konnects = state.get("konnects")
      const { searchQuery }= action.meta.previousAction.payload.request
      if (searchQuery.page_index === 1 ) {
        konnects = action.payload.konnects
      } else konnects = [...konnects, ...action.payload.konnects]
      // if (searchQuery1.page_index === 1 && task_log_type === "ERROR") {
      //   logs = action.payload.task_logs
      // } else logs = [...logs, ...action.payload.task_logs]
      const _tasks1 = fromJS(konnects)
      return state.set("konnects",konnects)
     // return state.set("konnects", action.payload ? action.payload.konnects : [])

    case GET_KONNECTS_LIST + FAIL:
      return state.set("konnects", [])

    case GET_AUTHORISED_APPS + SUCCESS:
      //flatten the response to limit the changes
      let apps = []
      const response = action.payload || {
        latest_apps: [],
        most_favourite: [],
      }
      Object.keys(action.payload).map(key => {
        const _apps = action.payload[key]
        _apps.map(x => {
          const found = apps.find(y => y.id === x.id)
          if (found) {
            found.tag = found.tag || []
            found.tag.push(key)
          } else {
            x.tag = [key]
            apps.push(x)
          }
        })
      })
      return state.set("authorisedApps", action.payload)

    case GET_AUTHORISED_APPS + FAIL:
      return state.set("authorisedApps", [])

    case GET_ASSOCIATED_ACCOUNTS + SUCCESS: {
      const chart = state.get("editorState").toJS()

      const nodeInfo = { ...action.meta.previousAction.payload.request.extra }
      const node = chart.nodes.find(x => x.nodeId === nodeInfo.nodeId)
      if (node) {
        node.linkedAccounts = action.payload.authorized_app_accounts
        if (action.payload.authorized_app_accounts.length === 1) {
          node.selectedAccount = action.payload.authorized_app_accounts[0]
        }
      }

      const _chart = fromJS(chart)
    //  const _state = changeHistory(state, GET_ASSOCIATED_ACCOUNTS, chart)

      return state.set("editorState", _chart)
    }
    case GET_ASSOCIATED_ACCOUNTS + FAIL: {
      return state.set("appEvents", [])
    }

    case SET_LINKED_ACCOUNT: {
      //?????????? //reverrse debug
      const { selected } = action.data
      const chart = state.get("editorState").toJS()

      const nodeInfo = { ...action.data.node }
      const node = chart.nodes.find(x => x.nodeId === nodeInfo.nodeId)
      node.selectedAccount = selected
      node.configResponse = []
      node.configResponses = []
      node.configResponsesOrig = []
      node.appEvents = []
      node.appEventSequences = []
      node.appEventConfigurations = []
      node.appEventConfigurationDetails = []
      const _chart = fromJS(chart)

     // const _state = changeHistory(state, SET_LINKED_ACCOUNT, chart)

      return state.set("editorState", _chart)
    }
    case GET_APP_EVENTS + SUCCESS: {
      const chart = state.get("editorState").toJS()

      const nodeInfo = { ...action.meta.previousAction.payload.request.extra }

      const node = chart.nodes.find(x => x.nodeId === nodeInfo.nodeId)
      if (node) {
        const nodeIdx = chart.nodes.findIndex(x => x.nodeId === nodeInfo.nodeId)
        let _events = action.payload.filter(x => x.side === "right")

        if (nodeIdx === 0) {
          _events = action.payload.filter(x => x.side === "left")
        }

        node.appEvents = _events
        node.configResponse = []
      }

      chart.links = chart.links.filter(x => x.to.node !== node.nodeId && x.from.node !== node.nodeId)

      const _chart = fromJS(chart)

    //  const _state = changeHistory(state, GET_APP_EVENTS, chart)
      return state.set("editorState", _chart)
    }

    case GET_APP_EVENTS + FAIL:
      return state.set("appEvents", [])

    case GET_EVENT_CONFIGURATIONS + SUCCESS: {
      const chart = state.get("editorState").toJS()
      const editorMeta = state.get("editorMeta").toJS()
      const nodeInfo = { ...action.meta.previousAction.payload.request.extra }
      const node = chart.nodes.find(x => x.nodeId === nodeInfo.nodeId)
      node.reviewed = false
      node.tested = false
      const configs = action.payload.sort((x, y) => {
        //increasing order
        return x.sequence - y.sequence
      })

      node.type = nodeInfo.type
      node.reviewed = false
      node.tested = false
      const hasSeqZero = configs.filter(x => x.sequence === 0).length
      if (hasSeqZero) {
        let _configs = configs.filter(x => x.sequence === 0)
        if (node.appType === "ADD_ON" && node.provider.toLowerCase() === "dateformatter") {
          _configs = _configs.reverse()
        }
        node.configResponses = [..._configs]
        node.configResponsesOrig = [..._configs]
      } else {
        if (node.appType != "ADD_ON" && node.provider != "api") {
          node.configResponses = []
          node.configResponsesOrig = []
        }
      }

      node.appEventConfigurations = configs.filter(x => x.sequence !== 0)
      node.appEvent = nodeInfo.eventId
      node.appEventSequences = []
      node.appEventConfigurationDetails = []

      chart.links = chart.links.filter(x => x.to.node !== node.nodeId && x.from.node !== node.nodeId)

      editorMeta.canPublish = false

      const _chart = fromJS(chart)
      const _editorMeta = fromJS(editorMeta)
    //  const _state = changeHistory(state, GET_EVENT_CONFIGURATIONS, chart)
      return state.set("editorState", _chart).set("editorMeta", _editorMeta)
    }

    case GET_EVENT_CONFIGURATIONS + FAIL:
      return state.set("appEventConfig", [])

    case GET_EVENT_CONFIGURATIONS_DETAILS + SUCCESS:
      {
        const chart = state.get("editorState").toJS()
        const editorMeta = state.get("editorMeta").toJS()
        const nodeInfo = { ...action.meta.previousAction.payload.request.extra }
        const node = chart.nodes.find(x => x.nodeId === nodeInfo.nodeId)
        node.appEventConfigurationDetails = node.appEventConfigurationDetails.filter(
          c => c.config_key !== nodeInfo.config_key
        )

        const configDetails = action.payload.map(x => {
          return { ...x, config_key: nodeInfo.config_key }
        })

        node.reviewed = false
        node.tested = false

        node.appEventConfigurationDetails = [...node.appEventConfigurationDetails, ...configDetails]
        chart.links = chart.links.filter(x => x.to.node !== node.nodeId && x.from.node !== node.nodeId)
        editorMeta.canPublish = false
        const _chart = fromJS(chart)
        const _editorMeta = fromJS(editorMeta)
        return state.set("editorState", _chart).set("editorMeta", _editorMeta)
      }
      c

    case GET_EVENT_CONFIGURATIONS_DETAILS_FETCH + SUCCESS: {
      const chart = state.get("editorState").toJS()
      const editorMeta = state.get("editorMeta").toJS()
      const nodeInfo = { ...action.meta.previousAction.payload.request.extra }
      const node = chart.nodes.find(x => x.nodeId === nodeInfo.nodeId)
      const sourceFields = action.payload.map(x => {
        return {
          app_event_id: nodeInfo.eventId,
          app_id: nodeInfo.appId,
          config_key: x.config_key ? x.config_key : x.name,
          config_key_required: x.config_key_required,
          id: x.id,
          label: x.name,
          placeholder: x.placeholder || x.name,
          key_value_type: x.key_value_type ? x.key_value_type : "display",
          type: "source",
        }
      })

      const targetFields = action.payload.map(x => {
        return {
          app_event_id: nodeInfo.eventId,
          app_id: nodeInfo.appId,
          config_key: x.config_key ? x.config_key : x.name,
          id: x.id,
          label: x.name,
          placeholder: x.placeholder || x.name,
          key_value_type: x.key_value_type ? x.key_value_type : "display",
          type: "target",
        }
      })

      node.reviewed = false
      node.tested = false
      const _configs = [...sourceFields]
      if (node.appType === "ADD_ON" && node.provider.toLowerCase() === "dateformatter") {
        _configs = _configs.reverse()
      }

      editorMeta.canPublish = false

      node.configResponses = [..._configs]
      node.configResponsesOrig = [..._configs]
      const _chart = fromJS(chart)
      const _editorMeta = fromJS(editorMeta)
      //const _state = changeHistory(state, GET_EVENT_CONFIGURATIONS_DETAILS_FETCH, chart)
      return state.set("editorState", _chart).set("editorMeta", _editorMeta)
    }
    case APPTESTANDREVIEW + SUCCESS: {
      const chart = state.get("editorState").toJS()
      const editorMeta = state.get("editorMeta").toJS()
      const response = action.payload
      const nodeInfo = { ...action.meta.previousAction.payload.request.extra }
      let node = chart.nodes.find(x => x.nodeId === nodeInfo.nodeId)
      const nodeIdx = chart.nodes.findIndex(x => x.nodeId === nodeInfo.nodeId)
      const currentLinks = chart.links.filter(x => x.to.node === nodeInfo.nodeId)
      let parentNode = null
      node.tested = true
      node.isloading = false
      node.reviewed = response.test_status ? response.test_status.toLowerCase() === "success" : false
      node.additionalResponses = FlattenJSON(response.raw_response)
      node = { ...node, ...response }

      node.configResponses = node.configResponses.map(q => {
        return {
          ...q,
          error: null,
        }
      })

      node.configResponses = node.configResponses.filter(q => !q.additional)

      if (node.appType === "ADD_ON" && node.provider === "conditioner") {
        if (chart.nodes[nodeIdx].configResponses.length === 1 && currentLinks.length) {
          parentNode = chart.nodes.find(x => x.nodeId === currentLinks[0].from.node)
          const newConfigResponses = parentNode.configResponses.map(x => {
            const props = _.pick(x, [
              "app_event_id",
              "app_id",
              "config_key",
              "id",
              "label",
              "type",
              "value",
              "isOpen",
              "level",
              "children",
            ])
            return props
          })
          chart.nodes[nodeIdx].configResponses = [...chart.nodes[nodeIdx].configResponses, ...newConfigResponses]
          chart.nodes[nodeIdx].additionalResponses = parentNode.additionalResponses
            ? [...parentNode.additionalResponses]
            : []
          if (node.reviewed) {
            node = chart.nodes[nodeIdx]

            node.pass_through_condition = true
            node.condition_activity_id = response.konnect_activity_id
          }
        }
      }
      if (node.pass_through_condition) {
        node.pass_through_condition = true
        node.condition_activity_id = response.konnect_activity_id
        node.konnect_activity_id = response.konnect_activity_id
      }
      let gsheetPop = false
      if ((node.provider === "google-form" || node.provider === "google_sheet") && node.konnect_custom) {
        gsheetPop = true
      }
      if (!node.reviewed) {
        if (response.errors) {
          Object.keys(response.errors).map(o => {
            const field = node.configResponses.findIndex(p => p.config_key === o)
            if (field !== -1) {
              node.configResponses[field].error =
                response.errors[o].message || `${node.configResponses[field].label} is not valid`
            }
          })
        }
      }

      const configFields = node.reviewed
        ? typeof action.payload.config_fields === "string"
          ? JSON.parse(action.payload.config_fields)
          : action.payload.config_fields
        : {}
      node.configResponses = node.configResponses.map(x => {
        return {
          ...x,
          ts: new Date().getTime(),
          value: configFields[x.config_key] || configFields[x.id] || x.value || null,
          mapped: !!(configFields[x.config_key] || configFields[x.id]),
        }
      })

      if (node.appType === "ADD_ON" && node.provider === "api") {
        if (!(response.errors && response.errors.message) && response.test_status != "Failure") {
          let response =
            typeof action.payload.raw_response === "string"
              ? JSON.parse(action.payload.raw_response)
              : action.payload.raw_response
          node.additionalResponses = []
          node.additionalResponses = FlattenJSON(response)
        }
      }
      chart.links = chart.links.map(x => {
        return { ...x, ts: new Date().getTime() }
      })

      chart.nodes[nodeIdx] = node

      editorMeta.canPublish = chart.nodes.every(x => x.reviewed)

      if (node.reviewed) chart.konnect_activity_id = response.konnect_activity_id

      const _chart = fromJS(chart)
      const _editorMeta = fromJS(editorMeta)
      //const _state = changeHistory(state, APPTESTANDREVIEW, chart)

      return state
        .set("editorState", _chart)
        .set("editorMeta", _editorMeta)
        .set("gSheetPopUp", gsheetPop)
        .set("testAndReviewLoader", false)
    }

    case APPTESTANDREVIEW + FAIL: {
      const chart = state.get("editorState").toJS()
      const editorMeta = state.get("editorMeta").toJS()
      const response = action.payload
      const nodeInfo = { ...action.meta.previousAction.payload.request.extra }
      let node = chart.nodes.find(x => x.nodeId === nodeInfo.nodeId)
console.log(node,"node2222",action)
      return state.set("testAndReviewLoader", false)
    }

    case GET_CONDITIONS_LIST + SUCCESS: {
      return state.set("conditionsList", action.payload ? action.payload : [])
    }

    case GET_CONDITIONS_LIST + FAIL: {
      return state.set("conditionsList", [])
    }

    case SET_APP_EVENT_CONFIG_VALUE: {
      const chart = state.get("editorState").toJS()
      const editorMeta = state.get("editorMeta").toJS()
      const nodeInfo = { ...action.data }
      const node = chart.nodes.find(x => x.nodeId === nodeInfo.nodeId)

      const eventSeq = node.appEventConfigurations.find(x => x.sequence === nodeInfo.sequence.sequence)

      eventSeq.selected = { ...nodeInfo.sequence }

      let seq = node.appEventSequences.findIndex(
        x => x.sequence === nodeInfo.sequence.sequence && x.config_key === nodeInfo.sequence.config_key
      )

      if (seq !== -1) {
        node.appEventSequences[seq] = nodeInfo.sequence
        node.appEventSequences = node.appEventSequences.slice(0, seq + 1)
        node.configResponses = []
      } else {
        node.appEventSequences.push(nodeInfo.sequence)
      }

      editorMeta.canPublish = false
      const _editorMeta = fromJS(editorMeta)
      const _chart = fromJS(chart)
      //const _state = changeHistory(state, SET_APP_EVENT_CONFIG_VALUE, chart)
      return state.set("editorState", _chart).set("editorMeta", _editorMeta)
    }

    case GET_EVENT_CONFIGURATIONS_DETAILS + FAIL:
    case GET_EVENT_CONFIGURATIONS_DETAILS_FETCH + FAIL:
      return state.set("appEventConfigDetails", [])

    case GET_EDITOR_STATE:
      return state.set("appEventConfig", [])

    case SET_EDITOR_STATE:
      return state.set("editorState", action.data)

    case SET_KONNECT_NAME:
      const chart = state.get("editorState").toJS()

      let _chart = {
        ...chart,
        konnect_name: action.data,
      }

      return state.set("editorState", fromJS(_chart))

    case ONDRAGNODE: {
      const chart = state.get("editorState").toJS()
      const { scale } = chart
      const { event, data, nodeId } = action.data
      const node = chart.nodes.findIndex(x => x.nodeId === nodeId)

      if (node !== -1) {
        chart.nodes[node] = {
          ...chart.nodes[node],
          position: { x: data.x, y: data.y },
        }
      }
      const _chart = fromJS(chart)
      return state.set("editorState", _chart)
    }

    case ONDRAGNODESTART: {
      const chart = state.get("editorState").toJS()
      const { scale } = chart
      const { event, data, nodeId } = action.data
      const node = chart.nodes.findIndex(x => x.nodeId === nodeId)

      if (node !== -1) {
        chart.nodes[node] = {
          ...chart.nodes[node],
          position: { x: data.x, y: data.y },
        }
      }
      const _chart = fromJS(chart)
      return state.set("editorState", _chart)
    }

    case ONDRAGNODESTOP: {
      const chart = state.get("editorState").toJS()
      const _state = changeHistory(state, ONDRAGNODESTOP, chart)

      return _state
    }
    case ONCANVASDROP: {
      const chart = state.get("editorState").toJS()
      const editorMeta = state.get("editorMeta").toJS()
      const customAppData = state.get("customAppData").toJS()
      customAppData.coupon = couponInitialState
      customAppData.textSplitter = textSplitterInitialState
      const { data, position, recent, nodeId } = action.data

      const node = {
        nodeId,
        position,
        appId: data.id,
        background: data.background,
        name: data.name,
        image: data.image,
        appEventConfigurations: [],
        appEvents: [],
        appEventSequences: [],
        configResponses: [],
        appEvent: null,
        provider: data.provider,
        appType: data.type || "API",
        tested: false,
        reviewed: false,
        isloading: false,
        showTestAndReview: data.type === "ADD_ON" && data.provider === "conditioner" ? false : true,
      }

      const existingNode = chart.nodes.find(x => x.position.x && x.position.y === position.y)
      if (existingNode) return state

      if (data.webhook_enabled) {
        node.webhook = true
        node.instructions = data.webhook_instructions
        node.webhookUrl = data.webhook_url
        // payload.webhookUrl = data.webhook_url //todo
      }
      if (node.appType === "ADD_ON" && node.provider === "conditioner") {
        node.configResponses = [
          {
            app_event_id: 3,
            app_id: node.appId,
            config_key: "conditions_setup",
            config_key_required: false,
            fetch_fields: false,
            id: "conditions_setup",
            key_value_type: "button",
            label: "",
            sequence: 0,
            side: "left",
          },
        ]
      }

      if (node.appType === "ADD_ON" && node.provider === "api") {
        node.api = apiAppInitialState
      }

      chart.nodes.push({ ...node })
      editorMeta.canPublish = false
      const _editorMeta = fromJS(editorMeta)
      const _chart = fromJS(chart)
      const _customAppData = fromJS(customAppData)

      const _state = changeHistory(state, ONCANVASDROP, chart)

      return _state
        .set("editorState", _chart)
        .set("editorMeta", _editorMeta)
        .set("customAppData", _customAppData)
    }
    case ONLINKSTART: {
      const chart = state.get("editorState").toJS()
      const { linkId, fromNodeId, fromPortInfo, fromPortId } = action.data
      const { type } = fromPortInfo
      if (type === "target") {
        const _chart = fromJS(chart)
        return state.set("editorState", _chart)
      }
      const currentLink = chart.links.findIndex(x => x.id === linkId)
      if (currentLink !== -1) {
        chart.links[currentLink] = {
          id: linkId,
          from: {
            node: fromNodeId,
            port: fromPortId,
          },
          to: {},
        }
      } else {
        chart.links.push({
          id: linkId,
          from: {
            node: fromNodeId,
            port: fromPortId,
            type: type,
          },
          to: {},
        })
      }

      const _chart = fromJS(chart)
      return state.set("editorState", _chart)
    }

    case ONLINKMOVE: {
      const chart = state.get("editorState").toJS()
      const { linkId, toPosition } = action.data

      const link = chart.links.find(x => x.id === linkId)

      if (link) link.to.position = toPosition

      const _chart = fromJS(chart)
      return state.set("editorState", _chart)
    }
    case ONLINKCOMPLETE: {
      const chart = state.get("editorState").toJS()

      const {
        config,
        linkId,
        startEvent,
        fromNodeId,
        fromPortId,
        toNodeId,
        toPortId,
        toPortInfo,
        fromPortInfo,
      } = action.data
      let linkExists = false
      //all links except current one
      const allLinks = chart.links.filter(x => x.id !== linkId)
      //early exit; if from same source
      if (fromNodeId === toNodeId) {
        chart.links = allLinks
        const _chart = fromJS(chart)
        return state.set("editorState", _chart)
      }

      // early exit; if target already linked

      const toNode = chart.nodes.find(x => x.nodeId === toNodeId)
      const fromNode = chart.nodes.find(z => z.nodeId === fromNodeId)
      const { appType = "API", provider = "" } = toNode
      linkExists = chart.links.filter(x => x.to.port === toPortId).length
      console.log(linkExists,"hello")
      if (
        (linkExists && appType !== "ADD_ON") ||
        (linkExists && appType === "ADD_ON" && provider.toLowerCase() === "dateformatter")
      ) {
        chart.links = allLinks
        const _chart = fromJS(chart)
        return state.set("editorState", _chart)
      }

      if (appType === "ADD_ON" && (provider.toLowerCase() === "conditioner" || provider.toLowerCase() === "api")) {
        let isFromSameNode = false

        const fromNodeLinks = chart.links.filter(d => d.to.node === toNode.nodeId)
        if (fromNodeLinks.length && fromNodeLinks[0].from.node === fromNode.nodeId) {
          isFromSameNode = true
        }
        if (fromNodeLinks.length && !isFromSameNode) {
          chart.links = allLinks
          const _chart = fromJS(chart)
          return state.set("editorState", _chart)
        } else {
          if (!toNode.fromFields) toNode.fromFields = []
          toNode.fromFields.push({ ...fromPortInfo, linkId })
          toNode.parentNode = fromNodeId
          toNode.parent_konnect_activity_id = fromNode.konnect_activity_id
        }
      }

      if (appType === "ADD_ON" && fromPortInfo.type === "source") {
        if (!toNode.fromPorts) {
          toNode.fromPorts = []
        }

        const newEntry = {
          info: fromPortInfo,
          id: fromPortInfo.port,
          display: fromPortInfo.label,
          linkId,
        }
        if (toNode.fromPorts.filter(h => isEqual(newEntry, h)).length) {
          chart.links.pop()
          const _chart = fromJS(chart)
          return state.set("editorState", _chart)
        }
        toNode.fromPorts.push(newEntry)
      }

      const configResponse = toNode.configResponses.find(x => x.port && x.port.id === toPortId)
      configResponse.dirty = false

      if (configResponse.value && appType !== "ADD_ON") {
        chart.links = allLinks
        const _chart = fromJS(chart)
        return state.set("editorState", _chart)
      }

      linkExists = chart.links.find(x => x.id === linkId)

      if (linkExists) {
        linkExists.to.node = toNodeId
        linkExists.to.port = toPortId
      }

      toNode.tested = false
      toNode.reviewed = false
      const editorMeta = state.get("editorMeta").toJS()
      editorMeta.canPublish = false
      const _editorMeta = fromJS(editorMeta)
      const fromField = fromNode.configResponses.find(x => x.port && x.port.id === fromPortId)
      const toField = toNode.configResponses.find(x => x.port && x.port.id === toPortId)

      // if (fromNode.appType === "ADD_ON" && fromNode.provider === "TextFormatter") {
      //   toField.value = fromField.key_value_type
      // } else {
      toField.value = fromField.value

      const nodeIdx = chart.nodes.findIndex(x => x.nodeId === fromNode.nodeId)

      if (fromNode.appType === "WEBHOOK_API" && nodeIdx) toField._id = fromField.id
      // }

      const _chart = fromJS(chart)

      const _state = changeHistory(state, ONLINKCOMPLETE, chart)
      return _state.set("editorState", _chart).set("editorMeta", _editorMeta)
    }
    case ONLINKCANCEL: {
      const chart = state.get("editorState").toJS()
      const { linkId } = action.data

      const links = chart.links.filter(x => {
        return x.id !== linkId
      })
      chart.links = links

      const _chart = fromJS(chart)
      return state.set("editorState", _chart)
    }

    case ONPORTTEXTCHANGE: {
      const chart = state.get("editorState").toJS()

      const { nodeId, portId, config_key, input, webhook = false } = action.data
      const node = chart.nodes.find(x => x.nodeId === nodeId)
      const config = node.configResponses.findIndex(c => c.config_key === config_key)

      node.configResponses[config].value = input
      node.tested = false
      node.reviewed = false
      chart.links = chart.links.filter(x => x.from.node !== node.nodeId)
      const _chart = fromJS(chart)
      const _state = changeHistory(state, ONPORTTEXTCHANGE, chart)
      const editorMeta = state.get("editorMeta").toJS()
      editorMeta.canPublish = false
      const _editorMeta = fromJS(editorMeta)

      return _state.set("editorState", _chart).set("editorMeta", _editorMeta)
    }
    case ONPORTPOSITIONCHANGE: {
      const chart = state.get("editorState").toJS()

      if (isEmpty(action.data, true)) {
        return state
      }

      const { node, port, el, nodesEl, config, type } = action.data

      let currPortId = el.getAttribute && el.getAttribute("data-port-id")
      let currNodeId = el.getAttribute && el.getAttribute("data-node-id")
      let currPortInfo = JSON.parse(el.getAttribute && el.getAttribute("data-port-info"))

      const _nodeToUpdate = chart.nodes.find(x => x.nodeId === node.nodeId)
      if (_nodeToUpdate.size) {
        // rotate the port's position based on the node's orientation prop (angle)

        const center = {
          x: _nodeToUpdate.size.width / 2,
          y: _nodeToUpdate.size.height / 2,
        }
        const current = {
          x: el.offsetLeft + nodesEl.offsetLeft + el.offsetWidth / 2,
          y: el.offsetTop + nodesEl.offsetTop + el.offsetHeight / 2,
        }
        const angle = _nodeToUpdate.orientation || 0
        const position = rotate(center, current, angle)

        const _port = _nodeToUpdate.configResponses.find(x => {
          return isEqual(x, port)
        })

        if (!_port) {
          return state
        }

        if (type === "source") {
          _port.source = {
            position: {
              x: position.x,
              y: position.y,
            },
            id: `${currPortId}|source`,
          }
        }

        if (type === "target") {
          _port.target = {
            position: {
              x: position.x,
              y: position.y,
            },
            id: `${currPortId}|target`,
          }
        }

        _port.port = {
          source: `${currPortId}|source`,
          target: `${currPortId}|target`,
          id: currPortId,
        }
      }
      const _chart = fromJS(chart)
      return state.set("editorState", _chart)
    }
    case ONLINKMOUSEENTER: {
      const { pos = { x: 0, y: 0 }, link = null, retain = false } = action.data

      const editorMeta = state.get("editorMeta").toJS()

      if (retain) {
        editorMeta.deleteLinkretain = true
      } else {
        editorMeta.deleteLinkretain = false

        editorMeta.deleteLink = true
        editorMeta.link = link
        editorMeta.deleteLinkPos.x = pos.x
        editorMeta.deleteLinkPos.y = pos.y
      }
      const _editorMeta = fromJS(editorMeta)
      return state.set("editorMeta", _editorMeta)
    }
    case ONLINKMOUSELEAVE:
      const editorMeta = state.get("editorMeta").toJS()
      editorMeta.deleteLink = false
      editorMeta.link = null
      editorMeta.deleteLinkPos.x = 0
      editorMeta.deleteLinkPos.y = 0
      const _editorMeta = fromJS(editorMeta)
      return state.set("editorMeta", _editorMeta)
    case ONLINKCLICK:
    case ONCANVASCLICK:

    case ONNODECLICK: {
      // const chart = state.get("editorState").toJS()
      // const { nodeId } = action.data
      // if (chart.selected.id !== nodeId || chart.selected.type !== "node") {
      //   chart.selected = {
      //     type: "node",
      //     id: nodeId,
      //   }
      // }
      // const _chart = fromJS(chart)
      // return state.set("editorState", _chart)

      return state
    }
    case ONNODEMOUSEENTER:
    case ONNODEMOUSELEAVE: {
      return state
    }
    case ONNODESIZECHANGE: {
      const { size, nodeId } = action.data

      const chart = state.get("editorState").toJS()
      const node = chart.nodes.find(x => x.nodeId === nodeId)

      if (node) {
        node.size = size
      }
      const _chart = fromJS(chart)
      return state.set("editorState", _chart)
    }

    case EDITORSCALE: {
      const chart = state.get("editorState").toJS()
      const { scale, offset } = action.data

      if (scale) chart.scale = scale
      if (offset) chart.offset = offset
      const _chart = fromJS(chart)
      return state.set("editorState", _chart)
    }

    case ONDRAGCANVASSTOP:
    case ONDRAGCANVAS: {
      const chart = state.get("editorState").toJS()
      const scale = chart.scale
      const { data } = action.data

      chart.offset = { x: data.positionX, y: data.positionY }
      const _chart = fromJS(chart)
      return state.set("editorState", _chart)
    }

    case BASIC_AUTH: {
      const chart = state.get("editorState").toJS()

      const { enabled, nodeId, source } = action.data
      const node = chart.nodes.find(x => x.nodeId === nodeId)

      node.enabled = enabled
      node.apiKey = ""
      node.secretKey = ""

      const _chart = fromJS(chart)

      const _state = changeHistory(state, ONCANVASDROP, chart)

      return _state.set("editorState", _chart)
    }

    case ONWEBHOOKAUTHCHANGE: {
      const chart = state.get("editorState").toJS()

      const { type, nodeId, input } = action.data
      const node = chart.nodes.find(x => x.nodeId === nodeId)

      node[type] = input
      node.reviewed = false
      node.tested = false
      const editorMeta = state.get("editorMeta").toJS()
      editorMeta.canPublish = false
      const _editorMeta = fromJS(editorMeta)

      const _chart = fromJS(chart)

      const _state = changeHistory(state, ONWEBHOOKAUTHCHANGE, chart)

      return _state.set("editorState", _chart).set("editorMeta", _editorMeta)
    }

    case ONDELETENODE: {
      const chart = state.get("editorState").toJS()
      const apiAppState = apiAppInitialState

      const { nodeId } = action.data
      chart.nodes = chart.nodes.filter(x => x.nodeId !== nodeId)

      chart.links = chart.links.filter(x => {
        return !(x.from.node === nodeId || x.to.node === nodeId)
      })
      const _chart = fromJS(chart)

      const _state = changeHistory(state, ONDELETENODE, chart)
      const _apiAppState = fromJS(apiAppState)

      return _state.set("editorState", _chart).set("apiAppInitialState", _apiAppState)
    }

    case ONSETASFIRSTNODE: {
      const chart = state.get("editorState").toJS()

      const { nodeId } = action.data

      const currentIdx = chart.nodes.findIndex(x => x.nodeId === nodeId)
      if (currentIdx > 0) {
        const currNode = chart.nodes[currentIdx]
        let nodes = chart.nodes.filter(x => x.nodeId !== nodeId)
        nodes = [currNode, ...nodes]
        chart.nodes = nodes

        chart.links = chart.links.filter(x => {
          return !(x.from.node === nodeId || x.to.node === nodeId)
        })
      }
      const _chart = fromJS(chart)

      const _state = changeHistory(state, ONSETASFIRSTNODE, chart)

      return _state.set("editorState", _chart)
    }

    case ONDELETELINK: {
      const chart = state.get("editorState").toJS()
      const { id } = action.data
      const linkData = chart.links.find(x => x.id === id)
      chart.links = chart.links.filter(x => x.id !== id)
      const fromNodeId = linkData.from.node
      const toNodeId = linkData.to.node
      const nodeIdx = chart.nodes.findIndex(c => c.nodeId === linkData.to.node)
      if (nodeIdx !== -1 && chart.nodes[nodeIdx].fromPorts) {
        chart.nodes[nodeIdx].fromPorts = chart.nodes[nodeIdx].fromPorts.filter(port => port.linkId !== id)
      }

      const currentLinks = chart.links.filter(x => x.to.node === toNodeId)
      const currentNodeIdx = chart.nodes.findIndex(x => x.nodeId === toNodeId)
      const currentNode = chart.nodes[currentNodeIdx]

      if (currentNode.appType === "ADD_ON" && currentNode.provider === "conditioner") {
        chart.nodes[currentNodeIdx].fromFields = chart.nodes[currentNodeIdx].fromFields.filter(x => x.linkId !== id)

        /* Update conditions and conditions text */
        //let currentConditions = chart.nodes[currentNodeIdx].conditions

        chart.nodes[currentNodeIdx].conditions = chart.nodes[currentNodeIdx].conditions
          ? chart.nodes[currentNodeIdx].conditions.filter(condition => condition.field.key !== id)
          : []

        let conditionsTxt = chart.nodes[currentNodeIdx].conditions.map(condition => {
          let queryOperatorText = condition.queryOperator === undefined ? "" : condition.queryOperator.toUpperCase()
          return (
            condition.field.label + " " + condition.operator + " " + condition.value + " " + queryOperatorText + "\r\n"
          )
        })
        chart.nodes[currentNodeIdx].conditionsText = conditionsTxt

        if (!currentLinks.length) {
          chart.nodes[currentNodeIdx].additionalResponses = []
          const firstPort = { ...chart.nodes[currentNodeIdx].configResponses[0] }
          chart.nodes[currentNodeIdx].configResponses = [firstPort]
          chart.links = chart.links.filter(x => x.from.node !== toNodeId)
          chart.nodes[currentNodeIdx].conditions = []
          chart.nodes[currentNodeIdx].conditionsText = ""
          chart.nodes[currentNodeIdx].reviewed = false
          chart.nodes[currentNodeIdx].tested = false
          chart.nodes[currentNodeIdx].showTestAndReview = false
        }
      }

      const configIdx = chart.nodes[currentNodeIdx].configResponses.findIndex(c => c.port.id === linkData.to.port)
      if (configIdx !== -1) {
        chart.nodes[currentNodeIdx].configResponses[configIdx].value = ""
      }

      const _chart = fromJS(chart)

      const _state = changeHistory(state, ONDELETELINK, chart)

      return _state.set("editorState", _chart)
    }

    case EDITORUNDO: {
      const history = state.get("history").toJS()
      const chart = state.get("editorState").toJS()

      const currentState = {
        nodes: chart.nodes,
        links: chart.links,
        ports: chart.ports,
      }

      if (!history.past.length && !history.future.length && history.present) {
        return state
      }

      if (!history.past.length) {
        return state
      }

      history.future.push(history.present)
      history.present = history.past.pop()

      const currentChart = {
        ...chart,
        ...history.present,
      }

      const _history = fromJS(history)
      const _chart = fromJS(currentChart)

      return state.set("editorState", _chart).set("history", _history)
    }

    case EDITORREDO: {
      const history = state.get("history").toJS()
      const chart = state.get("editorState").toJS()

      if (!history.past.length && !history.future.length && history.present) {
        return state
      }

      if (!history.future.length) {
        return state
      }

      history.past.push(history.present)
      history.present = history.future.pop()

      const currentChart = {
        ...chart,
        ...history.present,
      }

      const _history = fromJS(history)
      const _chart = fromJS(currentChart)

      return state.set("editorState", _chart).set("history", _history)
    }

    case CLEARSTATE: {
      const _history = fromJS(initState.history)
      const _chart = fromJS(initState.editorState)

      return state.set("editorState", _chart).set("history", _history)
    }

    case GETKONNECT + SUCCESS: {
      const chart = action.payload.canvas_json
      // let nodesArr = []
      // chart.nodes.map(node => {
      //   node.tested = false
      //   node.reviewed = false

      //   nodesArr.push(node)
      // })
      const history = {
        past: [],
        present: {
          nodes: chart.nodes,
          ports: chart.ports,
          links: chart.links,
        },
        future: [],
      }

      const _chart = fromJS(chart)
      const _history = fromJS(history)

      return state.set("editorState", _chart).set("history", _history)
    }

    case GETKONNECT + FAIL:
      return state

    case CAPTUREWEBHOOKRESPONSE + SUCCESS: {
      const chart = state.get("editorState").toJS()
      const nodeInfo = { ...action.meta.previousAction.payload.request.extra }
      let node = chart.nodes.find(x => x.nodeId === nodeInfo.nodeId)
      let nodeIdx = chart.nodes.findIndex(x => x.nodeId === nodeInfo.nodeId)

      if (action.payload.message && action.payload.message.toUpperCase() === "WAITING") {
        node.awaitingWebhookResponse = true
      } else {
        node.awaitingWebhookResponse = false

        const response = JSON.parse(JSON.stringify(action.payload.raw_response).replace(/\:null/gi, ':""'))
        node.webhookRawResponse = response
        // node.webhookResponse = FlattenJSON(response)
        node.configResponsesOrig = FlattenJSON(response)
        node.configResponses = FlattenJSON(response)
      }

      const { raw_response, test_response, ...rest } = action.payload

      node.tested = true
      node.reviewed = action.payload.test_status.toLowerCase() === "success"
      chart.nodes[nodeIdx] = { ...node, ...rest }
      const _chart = fromJS(chart)
      return state.set("editorState", _chart)
    }
    case CAPTUREWEBHOOKRESPONSE + FAIL: {
      return state
    }

    case EXPANDCOLLAPSENODE: {
      const chart = state.get("editorState").toJS()

      const { nodeId, sectionId } = { ...action.data }
      const node = chart.nodes.find(x => x.nodeId === nodeId)

      const section = node.configResponses.find(x => x.id === sectionId)

      const collapseAll = parentId => {
        const section = node.configResponses.findIndex(x => x.id === parentId)
        node.configResponses[section].isOpen = false
        if (node.configResponses[section].type === "node") {
          node.configResponses[section].children.map(childKey => {
            let child = node.configResponses.findIndex(x => x.id === childKey)
            if (node.configResponses[child].type === "leaf") {
              node.configResponses[child].isOpen = false
            } else {
              node.configResponses[child].isAvailable = false
              node.configResponses[child].isOpen = false

              node.configResponses[child].children.map(x => {
                collapseAll(x)
              })
            }
          })
        }
      }
      if (section.isOpen) {
        //close all children; including sub children

        collapseAll(section.id)
      } else {
        //open up to its immediate child
        section.isOpen = true
        section.children.map(x => {
          const item = node.configResponses.find(y => y.id === x)

          if (item.type === "node") {
            item.isAvailable = true
          } else {
            item.isOpen = true
          }
        })
      }

      node.configResponses = node.configResponses.map(x => {
        return { ...x, ts: new Date().getTime() }
      })
      chart.links = chart.links.map(x => {
        return { ...x, ts: new Date().getTime() }
      })

      const _chart = fromJS(chart)
      return state.set("editorState", _chart)
    }

    case CREATEADDITIONALFIELDS: {
      const chart = state.get("editorState").toJS()

      const { nodeId, fields } = { ...action.data }
      const node = chart.nodes.find(x => x.nodeId === nodeId)
      const newFields = fields.map(f => `out.${f.config_key}`)
      const additionalFields = node.configResponses.filter(d => d.additional).map(d => d.config_key)
      const diff = additionalFields.filter(c => !newFields.includes(c))
      //remove links
      diff.map(p => {
        const port = node.configResponses.find(q => q.config_key === p).port.id
        chart.links = chart.links.filter(l => l.from.port !== port)
      })

      node.configResponses = node.configResponses.filter(g => !diff.includes(g.config_key))
      fields.map(f => {
        if (!node.configResponses.find(h => h.config_key === `out.${f.config_key}`))
        
          node.configResponses.push({
            app_event_id: node.appEvent,
            app_id: node.appId,
            config_key: `out.${f.config_key}`,
            config_key_required: false,
            id: `out.${f.id}`,
            key_value_type: "input",
            label: f.label,
            sequence: 0,
            side: "right",
            additional: true,
            value: f.value,
            disabled: true,
            mapped: true,
          })
      })

      node.configResponses = node.configResponses.map(x => {
        return { ...x, ts: new Date().getTime() }
      })
      chart.links = chart.links.map(x => {
        return { ...x, ts: new Date().getTime() }
      })

      const _chart = fromJS(chart)
      return state.set("editorState", _chart)
    }
    case SET_CONFIG_MENU_STATE: {
      const chartMeta = state.get("editorMeta").toJS()
      const { isOpen } = action.data
      chartMeta.onWheel = !isOpen
      const _chart = fromJS(chartMeta)
      return state.set("editorMeta", _chart)
    }

    case MODIFYCONDITIONS: {
      const chart = state.get("editorState").toJS()

      const { node, conditionsText, conditions } = action.data

      const currentLinks = chart.links.filter(x => x.to.node === node.nodeId)
      const currentNodeIdx = chart.nodes.findIndex(x => x.nodeId === node.nodeId)

      let parentNode = null
      chart.nodes[currentNodeIdx].conditionsText = conditionsText
      chart.nodes[currentNodeIdx].conditions = conditions
      chart.nodes[currentNodeIdx].showTestAndReview = true
      // if (chart.nodes[currentNodeIdx].configResponses.length === 1 && currentLinks.length) {
      //   parentNode = chart.nodes.find(x => x.nodeId === currentLinks[0].from.node)

      //   const newConfigResponses = parentNode.configResponses.map(x => {
      //     const props = _.pick(x, ["app_event_id", "app_id", "config_key", "id", "label", "value"])
      //     return props
      //   })

      //   chart.nodes[currentNodeIdx].configResponses = [
      //     ...chart.nodes[currentNodeIdx].configResponses,
      //     ...newConfigResponses,
      //   ]

      //   chart.nodes[currentNodeIdx].additionalResponses = [...parentNode.additionalResponses]
      // }

      const _state = changeHistory(state, MODIFYCONDITIONS, chart)
      const _chart = fromJS(chart)
      return _state.set("editorState", _chart)
    }

    case MODIFY_HTTP_METHOD: {
      const { value, nodeIdx } = { ...action.data }
      const chart = state.get("editorState").toJS()
      chart.nodes[nodeIdx].api.httpMethods = value
      const _chart = fromJS(chart)
      return state.set("editorState", _chart)
    }

    case MODIFY_URL_ENDPOINT: {
      const { value, nodeIdx } = { ...action.data }
      const chart = state.get("editorState").toJS()
      chart.nodes[nodeIdx].api.endpointURL = value
      const _chart = fromJS(chart)
      return state.set("editorState", _chart)
    }

    case MODIFY_PAYLOAD_TYPE: {
      const { value, nodeIdx } = { ...action.data }
      const chart = state.get("editorState").toJS()
      chart.nodes[nodeIdx].api.payloadType = value
      const _chart = fromJS(chart)
      return state.set("editorState", _chart)
    }

    case MODIFY_WRAP_REQUEST_IN_ARRAY: {
      const { value, nodeIdx } = { ...action.data }
      const chart = state.get("editorState").toJS()
      chart.nodes[nodeIdx].api.wrapRequestInArray = value
      const _chart = fromJS(chart)
      return state.set("editorState", _chart)
    }

    case MODIFY_HEADERS_AND_PARAMS: {
      const { headers, params, nodeIdx, newNodeDetails } = { ...action.data }
      const chart = state.get("editorState").toJS()
      chart.nodes[nodeIdx].api.headers = headers
      chart.nodes[nodeIdx].api.params = params
      chart.nodes[nodeIdx].configResponses = newNodeDetails
      const _chart = fromJS(chart)
      return state.set("editorState", _chart)
    }

    case MODIFY_EXPIRY_NUM: {
      let customAppData = state.get("customAppData").toJS()
      customAppData.coupon.expiryNum = action.data
      const data = fromJS(customAppData)
      return state.set("customAppData", data)
    }

    case MODIFY_DURATION: {
      let customAppData = state.get("customAppData").toJS()
      customAppData.coupon.duration = action.data
      const data = fromJS(customAppData)
      return state.set("customAppData", data)
    }

    case MODIFY_SEGMENT_INDEX: {
      let customAppData = state.get("customAppData").toJS()
      customAppData.textSplitter.segmentIndex = action.data
      const data = fromJS(customAppData)
      return state.set("customAppData", data)
    }
    case UPDATE_GSHEET_POP_UP: {
      let isPopupEnable = state.get("gSheetPopUp").toJS
      isPopupEnable = action.data
      const data = fromJS(isPopupEnable)
      return state.set("gSheetPopUp", data)
    }
    case ENABLE_TEST_REVIEW_LOADER: {
      const chart = state.get("editorState").toJS()
      let node = chart.nodes[action.payload]
      node.isloading = true
      chart.nodes[action.payload] = node
      const _chart = fromJS(chart)

      return state.set("editorState", _chart)
    }

    default:
      return state
  }
}

export default konnectReducer
