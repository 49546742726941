/**
 * Subscription Widget.
 */
import React, { useState, useEffect } from "react"
import { Box, Text, Flex, Image } from "rebass"
import { Link, Button } from "src/components"
import { useTranslation } from "react-i18next"
import UpgradeNowBg from "assets/images/UpgradeNow.png"
import styled from "styled-components"

const StyledBackground = styled(Flex)`
  background-image: url(${UpgradeNowBg});
  background-repeat: no-repeat;
  border-radius: 16px;
  z-index: 1;
  background-size: contain;
`

export const Subscription = props => {
  const { getWidgetDetails, history } = props
  const { t } = useTranslation()
  useEffect(() => {
    getWidgetDetails("subscription").then(res => {
      if (res.type === "GET_WIDGET_DETAILS_SUCCESS") {
      }
    })
  }, [])

  return (
    <>
      <Flex
        p={{
          _: "20px",
          sm: "20px",
          md: "22px",
          lg: "24px",
          xl: "24px",
        }}
        sx={{
          width: "70%",
          flexDirection: "Column",
          backgroundColor: "#152f73",
          borderRadius: "16px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Flex
          sx={{
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: "100%",
            height: "50%",
            gap: ["10px", "12px", "14px", "16px"],
          }}
        >
          <Image width={{ _: 72, sm: 72, md: 74, lg: 78, xl: 82 }} src={UpgradeNowBg} />
          <Text
            fontSize={{ _: 10, sm: 10, md: 12, lg: 12, xl: 16 }}
            sx={{ fontWeight: "500", color: "#FFF", width: "50%", m: "auto" }}
          >
            Unlock more power by Upgrading to Pro
          </Text>
        </Flex>
        <Button
          onClick={() => {
            window.open("https://i.kntz.it/upgrade", "_blank")
          }}
          variant="secondary"
          fontSize={{ _: 14, sm: 14, md: 16, lg: 16, xl: 22 }}
          sx={{
            display: "flex",
            border: "none",
            borderRadius: "12px",
            fontStyle: "normal",
            fontWeight: 500,
            cursor: "pointer",
            p: "10px 20px",
          }}
        >
          {t("dashboard.widgets.subscription.upgradeBtnLabel")}
        </Button>
      </Flex>
    </>
  )
}
