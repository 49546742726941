import React, { useEffect, useRef, useState } from "react"
import { Flex } from "rebass"
import { KonnectSidebar } from "./Component/sidebar/index"
import { KonnectTopbar } from "./Component/topbar/index"
import { Provider } from "react-redux"
import { store } from "./store/index"
import { FlowCanvasContainer } from "./Component/canvas/index"
import { RefProvider } from "./store/context/index"

export const KonnectCanvas = () => {
  const [reactFlowInstance, setReactFlowInstance] = useState(null)
  return (
    <Provider store={store}>
      <RefProvider>
        <Flex width="100%" height="100%" sx={{ backgroundColor: "rgb(243,247,255)", flexDirection: "column" }}>
          <KonnectTopbar />
          <Flex width="100%" height="90%">
            <KonnectSidebar />
            <FlowCanvasContainer reactFlowInstance={reactFlowInstance} setReactFlowInstance={setReactFlowInstance} />
          </Flex>
        </Flex>
      </RefProvider>
    </Provider>
  )
}
