import React from "react"

import { EditorWrapper } from "./UI"

import { BaseEditor } from "./Base/Components"
import { DeleteLink } from "./UI"
import { isEqual } from "lodash"
class EditorClass extends React.Component {
  constructor(props) {
    super(props)
  }

  shouldComponentUpdate(prevProps) {
    return !isEqual(prevProps, this.props)
     }

  render() {
    const { editorState, editorActions, editorMeta = {}, testAndReviewLoader } = this.props
    // console.log("editorStsate", JSON.stringify(editorState))
    return (
      <EditorWrapper>
        {/* kiss ass work starts here */}
        <BaseEditor
          editorState={editorState}
          editorActions={editorActions}
          editorMeta={editorMeta}
          testAndReviewLoader={testAndReviewLoader}
        />
      </EditorWrapper>
    )
  }
}

export const Editor = EditorClass
