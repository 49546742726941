/**
 * App config reducer to handle the global config api responses.
 */

import { GET_PLANS_LIST, GET_REDEEMED_COUPONS, REDEEM_CODE } from 'config/Constants'

const initState = {
    plans: [],
    redeemedCoupons: []
}

const SUCCESS = '_SUCCESS'
const FAIL = '_FAIL'

const billingReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_PLANS_LIST + SUCCESS:
            return state.set('plans', action.payload)
        case GET_PLANS_LIST + FAIL:
            return state.set("plans", [])

        case GET_REDEEMED_COUPONS + SUCCESS:
            return state.set('redeemedCoupons', action.payload.redeemed_coupons)
        case GET_REDEEMED_COUPONS + FAIL:
            return state.set("redeemedCoupons", [])

        case REDEEM_CODE + SUCCESS:
            return state.set('plans', action.payload)
        case REDEEM_CODE + FAIL:
            return state.set("plans", [])
        default:
            return state
    }
}

export default billingReducer
