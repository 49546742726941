import React, { useContext, createContext, useRef } from "react"

export const RefContext = createContext()

export const RefProvider = props => {
  const sidebarContainer = useRef()
  const topbarContainer = useRef()

  return (
    <RefContext.Provider
      value={{
        sidebarContainer,
        topbarContainer,
      }}
    >
      {props.children}
    </RefContext.Provider>
  )
}

export const useRefContext = () => {
  return useContext(RefContext)
}
