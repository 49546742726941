import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { Flex, Box } from "rebass"
import { getAgencyUsersList } from "src/store/actions"
import { ToastContainer, toast } from "react-toastify"
import { AgencyUsersList } from "src/components/Agency/agencyUsersList"

export const AgencyContainer_ = ({
  addAgencyMemberBtnClick,
  getAgencyUsersList,
  agencyUsersList,
  totalSubAccounts,
  allowedSubaccountsLimit,
  totalTasks,
  activeSubAccounts,
  inActiveSubAccounts,
  taskConsumed,
  updateAgencyMember,
}) => {
  useEffect(() => {
    getAgencyUsersList()
  }, [])
  return (
    <>
      <Flex flexDirection="row" sx={{ textAlign: "end" }}>
        <AgencyUsersList
          addAgencyMemberBtnClick={addAgencyMemberBtnClick}
          users={agencyUsersList}
          totalSubAccounts={totalSubAccounts}
          allowedSubaccountsLimit={allowedSubaccountsLimit}
          totalTasks={totalTasks}
          activeSubAccounts={activeSubAccounts}
          inActiveSubAccounts={inActiveSubAccounts}
          taskConsumed={taskConsumed}
          a
        />
      </Flex>
      {/* <Flex sx={{ flexDirection: 'Column', textAlign:"end"}} width={{ _: 550, sm: 550, md: 300, lg: 500, xl: 1120 }}>
         
         <AgencyUsersList users={agencyUsersList} />
     </Flex>  */}
      {/* <Flex flexDirection="column" width={{ _: 300, sm: 300, md: 300, lg: 300, xl: 550}}>
                <AgencyUsersList users={agencyUsersList} />
            </Flex> */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    agencyUsersList: state.getIn(["agency", "agencyUsersList"]),
    totalTasks: state.getIn(["agency", "totalTasks"]),
    activeSubAccounts: state.getIn(["agency", "activeSubAccounts"]),
    inActiveSubAccounts: state.getIn(["agency", "inActiveSubAccounts"]),
    taskConsumed: state.getIn(["agency", "taskConsumed"]),
    totalSubAccounts: state.getIn(["agency", "totalSubAccounts"]),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAgencyUsersList: () => dispatch(getAgencyUsersList()),
  }
}

export const AgencyContainer = connect(mapStateToProps, mapDispatchToProps)(AgencyContainer_)
