import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { Flex, Box } from "rebass"
import { getPlansList, getRedeemedCoupons, redeemCode } from "src/store/actions"
import { ApplyCoupon } from "src/components/Billing/applyCoupon"
import { CouponsList } from "src/components/Billing/couponsList"
import { FeaturesList } from "src/components/Billing/featuresList"
import { ProductsList } from "src/components/Billing/productsList"
import { ToastContainer, toast } from "react-toastify"
import { useTranslation } from "react-i18next"


export const BillingContainer_ = ({
  getPlansList,
  getRedeemedCoupons,
  redeemedCoupons,
  plans,
  redeemCode,
  history,
}) => {
  useEffect(() => {
    getPlansList()
    getRedeemedCoupons()
  }, [])
  const { t, i18n } = useTranslation("translation")

  const applyCoupon = coupon => {
    redeemCode(coupon)
      .then(res => {
        getRedeemedCoupons()
        getPlansList()
        if (res.payload.status === "FAILED") {
          history.push("/dashboard")
          history.push("/account")
          toast.error(
            t("account.billing.completionofprofile"),
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          )
          // setTimeout(() => {
          // }, 1000)
          return
        }
        toast.info(res.payload.error ? res.payload.error : res.payload.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch(() => {
        toast.info(t("account.billing.licenseerror"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  return (
    <>
      <Flex flexDirection="row" sx={{ p: "20px" ,gap:"50px",width:"100%",height:"100%"}}>
        <Box>

       
        <CouponsList coupons={redeemedCoupons} />
        <ApplyCoupon applyCoupon={applyCoupon} />
        </Box>
        {/* <FeaturesList plans={plans} /> */}
        <ProductsList plans={plans} />
      </Flex>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    plans: state.getIn(["billing", "plans"]),
    redeemedCoupons: state.getIn(["billing", "redeemedCoupons"]),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPlansList: () => dispatch(getPlansList()),
    getRedeemedCoupons: () => dispatch(getRedeemedCoupons()),
    redeemCode: coupon => dispatch(redeemCode(coupon)),
  }
}

export const BillingContainer = connect(mapStateToProps, mapDispatchToProps)(BillingContainer_)
