import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { PortWrapper, PortDefault } from "../"
import ReactTooltip from "react-tooltip"

import {
  AppInfo,
  ConnectorOuter,
  ConnectorPortOuter,
  ConnectorPortLabel,
  ConnectorPortMentions,
  NodeOuter,
  SelectionHeader,
  EventSelectionGroup,
  TestAndReviewButton,
} from "../../../UI"
import { Dropdown } from "src/components/Konnect/DropdownMenu"
import { CommonOuter } from "./CommonOuter"
const WrappedPort = props => {
  return (
    <PortWrapper
      config={props.config}
      offset={props.offset}
      selected={props.selected}
      selectedLink={props.selectedLink}
      hoveredLink={props.hoveredLink}
      hovered={props.hovered}
      node={props.node}
      port={props.port}
      Component={props.PortDefault}
      onPortPositionChange={props.onPortPositionChange}
      onLinkStart={props.onLinkStart}
      onLinkMove={props.onLinkMove}
      onLinkComplete={props.onLinkComplete}
      onLinkCancel={props.onLinkCancel}
      chart={props.chart}
      pType={props.pType}
      active={props.active}
    />
  )
}
export const AddOnInnerDefault = ({
  node,
  chart,
  config,
  offset,
  selected,
  selectedLink,
  hoveredLink,
  hovered,
  onPortPositionChange,
  onLinkStart,
  onLinkMove,
  onLinkComplete,
  onLinkCancel,
  editorActions,
  Links,
}) => {
  const { t } = useTranslation()

  const [event, setEvent] = useState(!!node.appEvent)
  const [eventConfig, setEventConfig] = useState(!!node.appEventConfiguration)
  const [eventSequence, setEventSequence] = useState(null)
  const [openInstructions, setOpenInstructions] = useState(false)
  const [IsDeleteShown, setIsDeleteShown] = useState(false)
  const [nodeReviewed, setNodeReviewed] = useState(node.tested ? node.reviewed : null)
  const [nodeTested, setNodeTested] = useState(node.tested ? node.tested : null)
  const [searchEvents, setSearchEvents] = useState("")
  const [searchEventConfigurations, setSearchEventConfigurations] = useState("")
  

  const ports = chart.ports.filter(x => x.node === node.id)

  const selectedEvent = !node.appEvent
    ? t("konnect.sidebar.choose_trigger")
    : node.appEvents.find(x => x.id === node.appEvent).name

  const selectedTrigger = !node.appEventConfiguration
    ? t("konnect.sidebar.customize_form_partial")
    : node.appEventConfigurations.find(x => x.id === node.appEventConfiguration).service_name

  const nodeIdx = chart.nodes.findIndex(x => x.nodeId === node.nodeId)
  const source = nodeIdx === 0 || chart.nodes.length > 2
  // nodeIdx === 0 || node.type === "source" || node.type === "both" || (nodeIdx !== 0 && chart.nodes.length > 2)
  const target = nodeIdx !== 0
  // && (node.type === "target" || node.type === "both")

  let hasSequence =
    node.appEventConfigurations && node.appEventConfigurations.filter(x => x.sequence !== 0).length ? true : false

  let configForms = []

  if (hasSequence) {
    configForms = node.appEventConfigurations.slice(0, node.appEventSequences.length + 1)
  }

  const { appType = "API" } = node

  let formElements = []

  let sections = []
  const extract = (obj, parentKey) => {
    Object.keys(obj).map(key => {
      if (typeof obj[key] === "object") {
        extract(obj[key], key)
        sections.push({ label: key, key: parentKey ? parentKey + "$." + key : key, items: { ...obj[key] } })
        delete obj[key]
      }
    })
  }

  if (node.configResponses) {
    formElements = node.configResponses.map(x => {

      
      let ConnectorPort = ConnectorPortMentions
      let data = node.fromPorts ? node.fromPorts : []
      const isActive = !!Links.find(c => {
        return x.port && (c.to.port === x.port.id || c.from.port === x.port.id)
      })

      const __key = x.config_key
        .replace(/[^a-zA-Z ]/g, "")
        .split(" ")
        .join("_")

      let disable = false

      return (
        <>
          <ConnectorPortLabel>{x.label}</ConnectorPortLabel>
          <ConnectorOuter key={`${x.app_id}_${__key}`} type={x.key_value_type}>
            {target ? (
              <ConnectorPortOuter placement="left" type={x.key_value_type}>
                {
                  <WrappedPort
                    pType="target"
                    config={config}
                    offset={offset}
                    selected={selected}
                    selectedLink={selectedLink}
                    hoveredLink={hoveredLink}
                    hovered={hovered}
                    node={node}
                    port={x}
                    Component={PortDefault}
                    onPortPositionChange={onPortPositionChange}
                    onLinkStart={onLinkStart}
                    onLinkMove={onLinkMove}
                    onLinkComplete={onLinkComplete}
                    onLinkCancel={onLinkCancel}
                    chart={chart}
                    active={isActive}
                  />
                }
              </ConnectorPortOuter>
            ) : null}

            <span
              style={{
                width: "100%",
              }}
              data-for={`${x.app_id}_${__key}`}
              data-tip={x.label ? x.label : ""}
              data-iscapture="true"
            >
              <ConnectorPort
                rows={4}
                dirty={x.value ? false : !!x.dirty}
                placeholder={!x.dirty ? x.label : `${x.label}*`}
                key={`${x.app_id}_${__key}`}
                onMouseDown={(e) => {
                  e.stopPropagation()
                }}
                disabled={x.key_value_type === "display" || disable}
                onChange={val => {
                  editorActions.onPortTextChange({
                    nodeId: node.nodeId,
                    portId: x.portId,
                    config_key: x.config_key,
                    input: val,
                  })
                }}
                value={x.value ? x.value : ""}
                data={data}
              />
            </span>

            <ReactTooltip
              id={`${x.app_id}_${__key}`}
              // id="zzzzzz"
              place={"bottom"}
              type={"dark"}
              effect={"solid"}
              multiline={true}
              overridePosition={({ left, top }) => {
                return { left: left / chart.scale, top: top / chart.scale }
              }}
            />

            {source ? (
              <ConnectorPortOuter placement="right" type={x.key_value_type}>
                <WrappedPort
                  pType="source"
                  config={config}
                  offset={offset}
                  selected={selected}
                  selectedLink={selectedLink}
                  hoveredLink={hoveredLink}
                  hovered={hovered}
                  node={node}
                  port={x}
                  Component={PortDefault}
                  onPortPositionChange={onPortPositionChange}
                  onLinkStart={onLinkStart}
                  onLinkMove={onLinkMove}
                  onLinkComplete={onLinkComplete}
                  onLinkCancel={onLinkCancel}
                  chart={chart}
                  active={isActive}
                />
              </ConnectorPortOuter>
            ) : null}
          </ConnectorOuter>
        </>
      )
    })
  }

  useEffect(() => {
    setNodeTested(node.tested ? node.tested : null)
    setNodeReviewed(node.tested ? node.reviewed : null)
  }, [node.tested, node.reviewed])

  //let nodeReviewed = node.tested ? node.reviewed : null
  return (
    <>
      <CommonOuter node={node} reviewed={nodeReviewed} nodeTested={nodeTested} />
      <NodeOuter
        onMouseEnter={() => setIsDeleteShown(true)}
        onMouseLeave={() => setIsDeleteShown(false)}
        isFirst={!nodeIdx}
        reviewed={nodeReviewed}
      >
        <AppInfo
          title={node.name}
          subtitle={node.account}
          image={node.image}
          className="DraggableHandle"
          background={node.background}
          hasDelete={IsDeleteShown && (nodeIdx !== 0 || chart.nodes.length === 1)}
          onDelete={() => {
            if (node.tested || node.konnect_activity_id) {
              editorActions
                .onDeleteNodeV2({
                  konnect_id: chart.nodes[0].konnect_id,
                  konnect_activity_id: node.konnect_activity_id,
                  canvas_json: chart,
                  nodeId: node.nodeId,
                })
                .then(data => {
                  editorActions.onDeleteNode({
                    nodeId: node.nodeId,
                  })
                })
            } else {
              editorActions.onDeleteNode({
                nodeId: node.nodeId,
              })
            }
          }}
        ></AppInfo>
        <>
          <EventSelectionGroup>
            <SelectionHeader>{t("konnect.sidebar.choose_format_header")}</SelectionHeader>
            <Dropdown
              searchable={true}
              searchValue={searchEvents}
              onFilterChange={text => {
                setSearchEvents(text)
              }}
              inputPlaceholder={!searchEvents ? "Search field" : ""}
              disableIfEmpty
              headerTitle={
                node.appEvents.length
                  ? t("konnect.sidebar.choose_option_header")
                  : t("konnect.sidebar.no_options_available")
              }
              items={node.appEvents}
              renderKey="name"
              selected={node.appEvent ? node.appEvents.find(x => x.id === node.appEvent).name : null}
              onChange={selected => {
                editorActions.getAppEventConfig({
                  nodeId: node.nodeId,
                  appId: node.appId,
                  eventId: selected.id,
                  source: nodeIdx === 0,
                  type: selected.side === "left" ? "source" : selected.side === "right" ? "target" : "both",
                })
                setEvent(false)
                setSearchEvents("")
              }}
              onChangeState={isOpen => {
                editorActions.setConfigMenuState({ isOpen })
              }}
            />
          </EventSelectionGroup>

          {hasSequence && node.appEvent && (
            <EventSelectionGroup>
              <SelectionHeader>{t("konnect.sidebar.choose_option_header")}</SelectionHeader>
              {configForms.map((x, i) => {
                const trigger = node.appEventSequences.find(y => x.sequence === y.sequence)
                return (
                  <Dropdown
                    searchable={true}
                    searchValue={searchEventConfigurations}
                    onFilterChange={text => {
                      setSearchEventConfigurations(text)
                    }}
                    inputPlaceholder={!searchEventConfigurations ? "Search field" : ""}
                    key={i}
                    index={i}
                    headerTitle={trigger ? trigger.name : `${selectedTrigger} ${x.label}`}
                    items={node.appEventConfigurationDetails.filter(c => c.config_key === x.config_key)}
                    renderKey="name"
                    onOpen={() => {
                      const currSequenceIdx = node.appEventConfigurations.findIndex(y => y.sequence === x.sequence)
                      const prevSequence = node.appEventConfigurations[currSequenceIdx - 1]
                      let prevSeq = null
                      if (prevSequence) {
                        prevSeq = node.appEventSequences.find(c => c.sequence === prevSequence.sequence)
                      }
                      editorActions.getAppEventConfigDetail({
                        node,
                        nodeId: node.nodeId,
                        appId: node.appId,
                        eventId: node.appEvent,
                        eventConfigId: x.id,
                        source: nodeIdx === 0,
                        config_key: x.config_key,
                        prevSequence: prevSeq,
                      })
                      setEventSequence(x.sequence ? x.sequence : x.config_id)
                      setEventConfig(true)
                    }}
                    onChange={selected => {
                      editorActions.setEventsConfig({
                        nodeId: node.nodeId,
                        appId: node.appId,
                        eventId: node.appEvent,
                        eventConfigId: eventSequence,
                        source: nodeIdx === 0,
                        sequence: {
                          sequence: eventSequence,
                          id: selected.id,
                          name: selected.name,
                          config_key: selected.config_key,
                        },
                        selectedEventConfigId: selected.id,
                        selectedEventConfigName: selected.name,
                      })

                      if (
                        node.appEventConfigurations.findIndex(x => x.sequence === eventSequence) ===
                        node.appEventConfigurations.length - 1
                      ) {
                        editorActions.getAppEventConfigDetailFetch({
                          node,
                          nodeId: node.nodeId,
                          appId: node.appId,
                          eventId: node.appEvent,
                          eventConfigId: selected.id,
                          source: nodeIdx === 0,
                          selectedEventConfigId: selected.id,
                          selectedEventConfigName: selected.name,
                          selectedConfigKey: selected.config_key,
                        })
                      }
                      setSearchEventConfigurations("")
                      // setEventConfig(false)
                    }}
                  />
                )
              })}
            </EventSelectionGroup>
          )}
          {formElements.length ? (
            <EventSelectionGroup>
              <SelectionHeader>{t("konnect.sidebar.configure_response")}</SelectionHeader>
              {formElements.map(form => form)}
              {node.configResponses ? (
                <>
                  <SelectionHeader></SelectionHeader>

                  <TestAndReviewButton
                    onClick={() => {
                      editorActions.appTestAndReview({
                        node,
                        nodeIdx: nodeIdx,
                        editorState: chart,
                      })
                    }}
                  >
                    Validate
                  </TestAndReviewButton>
                </>
              ) : null}
            </EventSelectionGroup>
          ) : null}
        </>
      </NodeOuter>
    </>
  )
}
