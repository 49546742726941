import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Box, Flex, Text, Image } from "rebass"
import styled from "@emotion/styled"
import { AppsView } from "./Apps.view"
import ReactModal from "react-modal"
import px2vw from "utils/px2vw"
import { ToastContainer, toast } from "react-toastify"
import { faKey } from "@fortawesome/free-solid-svg-icons"
import { SearchHeader, Preloader } from "src/components"
import AuthTokenService from "utils/AuthTokenService"
import AccessAccountSVG from "src/assets/images/access-account"
import { OAUTH_REDIRECT_URL } from "config/Constants"
import { useTranslation } from "react-i18next"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { Input, Button, Icon } from "src/components"
import PlusIcon from "src/assets/images/Plus_icon.svg"
import Cross from "../../assets/images/cross.svg"
import Key from "../../assets/images/keyIcon.svg"
import {
  getApps,
  getOauthData,
  saveOauthData,
  getAppDetails,
  updateAppStatus,
  searchApps,
  saveAuthLabel,
} from "src/store/actions"
import { useThemeContext } from "../../features/ReactFlow/store/context/themeContext"
import { svg } from "../../features/ReactFlow/asset/svg"
import { svg_1 } from "../../assets/images/svg"
let browser = window
let popup = null
let timer = null

export const Container = props => {
  const { t } = useTranslation()
  const { bP } = useThemeContext()
  const {
    apps,
    getApps,
    getOauthData,
    saveOauthData,
    getAppDetails,
    appDetails,
    updateAppStatus,
    saveAuthLabel,
    closeModal,
  } = props
  const [selectedApp, setSelectedApp] = useState(null)
  const [tokenModal, setTokenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState(null)
  const [styles, setStyles] = useState(false)
  const [tokenFields, setTokenFields] = useState([])
  const [fieldsData, setFieldsData] = useState({})
  const [pageSize, setPageSize] = useState(bP([55, 55, 55, 55]))
  const [newApps, setNewApps] = useState([])
  const [appUserId, setAppUserId] = useState(null)
  const [reAuthorise, setReAuthorise] = useState(false)
  console.log(tokenFields, "tokenFields")
  const popUpEventsHandler = msg => {}
  const watcher = () => {
    if (popup === null) {
      clearInterval(timer)
      timer = null
    } else if (popup !== null && !popup.closed) {
      popup.focus()
    } else if (popup !== null && popup.closed) {
      clearInterval(timer)
      browser.focus()
      browser.onClose("child was closed")
      toast.info(reAuthorise ? "App Connection completed!" : "App Connection process completed!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
      timer = null
      popup = null
      getApps()
    }
  }

  useEffect(() => {
    // setLoading(true)
    getApps(searchValue ? { page_size: pageSize, search_query: searchValue } : { page_size: pageSize }).then(res => {
      //setLoading(false)
      if (res.type === "GET_ALL_APPS_SUCCESS") {
        if (res.payload.apps.length > 0) {
          setNewApps(res.payload.apps)
        } else setNewApps(["not-found"])
      }

      if (res.type === "GET_ALL_APPS_FAIL") {
        if (res.error.response.status === 422) {
          AuthTokenService.clear()
          props.history.push("/login")
        }
      }

      //  setNewApps(res.payload.apps)
      // setLoading(false)
      // setLoading(true)
    })

    browser = window.self
    browser.onSuccess = res => {
      popUpEventsHandler(res)
    }
    browser.onError = error => {
      popUpEventsHandler(error)
    }
    browser.onOpen = message => {
      popUpEventsHandler(message)
    }
    browser.onClose = message => {
      popUpEventsHandler(message)
    }
  }, [pageSize, searchValue])
  useEffect(() => {
    let params = {
      page_size: pageSize,
    }
    let params1 = {
      page_size: pageSize,
      search_query: searchValue,
    }

    getApps(searchValue ? { ...params1 } : { ...params })

    // if(searchValue){
    //     getKonnectsList({ ...params1 })
    // }
  }, [pageSize, searchValue])

  const handleAppAuth = app => {
    setSelectedApp(app)
    if (app.auth_type === "Token") {
      launchTokenModal(app)
    } else {
      launchAuth(app)
    }
  }

  const launchTokenModal = app => {
    getOauthData({
      app_id: app.id,
      redirect_url: OAUTH_REDIRECT_URL,
    }).then(data => {
      setTokenFields(data.payload.fields)
      setTokenModal(true)
    })
  }

  const launchAuth = app => {
    setSelectedApp(app)
    if (app.auth_type === "Token") {
      launchTokenModal(app)
    } else {
      if (popup && !popup.closed) {
        popup.focus()
        return
      }

      const name = "OAuth Permissions"
      const opts = `dependent=${1}, alwaysOnTop=${1}, alwaysRaised=${1}, alwaysRaised=${1}, width=800, height=600`

      popup = browser.open(`${OAUTH_REDIRECT_URL}/${app.id}/${AuthTokenService.get()}`, name, opts)

      setTimeout(() => {
        popup.opener.onOpen("child was opened")
      }, 0)

      if (timer === null) {
        timer = setInterval(watcher, 2000)
      }
    }
  }

  const checkData = data => {
    let flag = true
    for (let i = 0; i < tokenFields.length; i++) {
      if (!fieldsData[tokenFields[i].id] || fieldsData[tokenFields[i].id].length == 0) {
        flag = false
        break
      }
    }
    return flag
  }

  const saveTokenData = () => {
    if (fieldsData && checkData(fieldsData)) {
      saveOauthData(
        reAuthorise
          ? {
              app_id: selectedApp.id,
              app_user_id: appUserId,
              auth_response: fieldsData,
            }
          : { app_id: selectedApp.id, auth_response: fieldsData }
      ).then(() => {
        setTokenModal(false)
        setFieldsData({})
        setSelectedApp(null)
        toast.info(reAuthorise ? "App Connection completed!" : "App Connection process completed!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        getApps()
      })
    } else {
      toast.error("Enter the missing data!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }
  }

  const onSearchItem = e => {
    setSearchValue(e.currentTarget.value)
    //getApps(e.currentTarget.value, false)
  }

  // if(tokenFields.length >2){
  //   setStyles("45% 45%")
  // }else{
  //   setStyles("100%")
  // }

  const gridToggleInvalid = {
    width: "100%",
    gridGap: "5%",
    display: "grid",
    gridTemplateColumns: "45% 45%",
    margin: "0px auto",
  }

  const gridToggleValid = {
    width: "90%",
    gridGap: "5%",
    display: "grid",
    gridTemplateColumns: "100%",
    margin: "0px auto",
  }

  const fetchMoreApps = () => {
    setPageSize(pageSize + bP([48, 48, 60, 60]))
  }

  return (
    <>
      <SearchHeader searchValue={searchValue} onSearchItem={onSearchItem} appsPage={true} />
      {loading ? (
        <Preloader loading={loading} />
      ) : (
        <AppsView
          handleAppAuth={handleAppAuth}
          apps={newApps}
          searchValue={searchValue}
          fetchMoreApps={fetchMoreApps}
          newApps={newApps}
          authenticatedApps={apps.authorized_apps ? apps.authorized_apps : []}
          unAuthenticatedApps={apps.unauthorized_apps ? apps.unauthorized_apps : []}
          getAppDetails={getAppDetails}
          appDetails={appDetails}
          launchAuth={launchAuth}
          setReAuthorise={setReAuthorise}
          reAuthorise={reAuthorise}
          setAppUserId={setAppUserId}
          updateAppStatus={(appId, status, parent) => {
            updateAppStatus({ appId: appId, status: status }).then(data => {
              if (data.payload.message === "FAILURE") {
                toast.info(data.payload.details, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                })
              } else {
                getApps()
                getAppDetails(parent)
              }
            })
          }}
          saveAuthLabel={saveAuthLabel}
        />
      )}

      {selectedApp && (
        <ReactModal
          isOpen={tokenModal}
          parentSelector={() => document.querySelector("body")}
          onRequestClose={() => setTokenModal(false)}
          style={{
            overlay: { display: "flex", alignItems: "center", justifyContent: "center" },
            content: {
              top: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minWidth: bP([650, 750, 800, 950]),
              maxWidth: bP([950, 950, 1000, 1050]),
              maxHeight: px2vw(600),
              borderRadius: "8px",
              boxShadow: "0px 0px 15px 4px rgb(0,0,0,0.5)",
              position: "relative",
              alignItems: "normal",
              padding: "0px",
            },
          }}
        >
          <Flex width="100%" height="100%" sx={{ flexDirection: "column", padding: ["20px"], gap: "20px" }}>
            <Flex
              width="100%"
              sx={{
                width: "100%",
                alignContent: "center",
                justifyContent: "space-between",
                position: "relative",
                pb: ["10px", "12px", "14px", "16px"],
                borderBottom: "2px solid #152f73",
              }}
            >
              <Flex
                sx={{ flexDirection: "row", alignItems: "center", gap: ["10px", "12px", "14px", "16px"], width: "80%" }}
              >
                <Image width="8%" src={selectedApp.image_url} sx={{ boxShadow: "0px 0px 5px 1px #c1d2ff" }} />
                <Text
                  fontSize={{ _: 18, sm: 18, md: 20, lg: 22, xl: 22 }}
                  fontWeight="semi-bold"
                  color="app.primary.30"
                  textAlign="left"
                  sx={{ justifyContent: "left", textAlign: "left" }}
                >
                  API Integration
                </Text>
              </Flex>
              <Flex
                sx={{
                  m: "auto",
                  width: "20%",
                  height: "100%",
                  fill: "red",

                  justifyContent: "flex-end",
                }}
              >
                <Flex
                  onClick={() => {
                    setTokenModal(false)
                    setSelectedApp(null)
                    setFieldsData({})
                  }}
                  sx={{
                    "&:hover": {
                      transform: "scale(1.2)",
                    },
                    transition: "transform 0.25s",
                    cursor: "pointer",
                    width: ["20px", "22px", "24px", "26px"],
                  }}
                >
                  {svg.xmark}
                </Flex>
              </Flex>
            </Flex>
            <Text
              sx={{
                fontSize: "h3",
                color: "app.primary.30",
                fontWeight: "normal",
                textAlign: "center",
              }}
            >
              {t("apps.accessAPIModalHeaderText1")} {selectedApp.name} {t("apps.accessAPIModalHeaderText2")}
            </Text>
            <Flex style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
              {tokenFields &&
                tokenFields.map((token, index) => {
                  if (index < 2 || index + 1) {
                    {
                      console.log(token, "222")
                    }
                    return (
                      <div
                        key={index}
                        style={{ width: "50%", marginBottom: "10px", textAlign: "center", padding: "1%" }}
                      >
                        <Text sx={{ fontSize: "lg", color: "app.primary.30" }}>
                          {token.name} <span style={{ color: "#E84539" }}>{t("apps.requiredText")}</span>
                        </Text>
                        <Box sx={{ display: "flex", border: "0px solid red", height: "50px" }}>
                          <Box
                            sx={{
                              width: "20px",
                              height: "20px",
                              textAlign: "center",
                              fontSize: "10px",
                              borderRadius: "20px",
                              backgroundColor: "app.primary.70",
                              color: "app.secondary.80",
                              margin: "10px",
                              paddingTop: "4px",
                            }}
                          >
                            {" "}
                            <Key />
                          </Box>

                          <Box sx={{ width: "100%" }}>
                            <Input
                              type={token.name === "Password" ? "password" : "text"}
                              defaultValue={fieldsData[token.id]}
                              sxProps={{
                                "& ~ span": {
                                  color: "#B7CBFF !important",
                                  padding: "0px",
                                  width: "80%",
                                  fontSize: "12px",
                                  border: "0px solid red",
                                },
                              }}
                              width="100%"
                              className="map"
                              placeholder={`Enter your ${token.name}`}
                              onChange={evt => setFieldsData({ ...fieldsData, [token.id]: evt.currentTarget.value })}
                            />
                          </Box>
                        </Box>
                        <Box>
                          {token.instructions ? (
                            <div style={{  textAlign: "left", marginTop: "10px" }}>
                              Please follow the instructions for more reference
                            </div>
                          ) : (
                            <Text className="text">
                              {t("apps.accessAPIModalMainText1")} {token.name} {t("apps.accessAPIModalMainText2")}{" "}
                              {selectedApp.name} {t("apps.accessAPIModalMainText3")}
                            </Text>
                          )}
                          {token.instructions ? (
                            <Box sx={{  p: "5px", textAlign: "left" }}>
                              <ul>
                                {eval(token.instructions).map((name, i) => (
                                  <div style={{ display: "flex" }}>
                                    <p>{i + 1 + ". " + " "}&nbsp;</p>
                                    <div style={{ margin: "0" }} dangerouslySetInnerHTML={{ __html: name }}></div>
                                  </div>

                                  // <li key={name}> {name} </li>
                                ))}
                              </ul>
                            </Box>
                          ) : null}
                        </Box>
                      </div>
                    )
                  } else if (tokenFields?.length % 2 !== 0) {
                    return (
                      <div
                        key={index}
                        style={{ width: "100%", marginBottom: "10px", textAlign: "center", padding: "2%" }}
                      >
                        <Text sx={{ fontSize: "lg", color: "app.primary.30" }}>
                          {token.name} <span style={{ color: "#E84539" }}>{t("apps.requiredText")}</span>
                        </Text>
                        <Box sx={{ display: "flex", border: "0px solid red", height: "50px" }}>
                          <Box
                            sx={{
                              width: "20px",
                              height: "20px",
                              textAlign: "center",
                              fontSize: "10px",
                              borderRadius: "20px",
                              backgroundColor: "app.primary.70",
                              color: "app.secondary.80",
                              margin: "10px",
                              paddingTop: "4px",
                            }}
                          >
                            {" "}
                            <Key />
                          </Box>

                          <Box sx={{ width: "100%" }}>
                            <Input
                              type={token.name === "Password" ? "password" : "text"}
                              defaultValue={fieldsData[token.id]}
                              sxProps={{
                                "& ~ span": {
                                  color: "#B7CBFF !important",
                                  padding: "0px",
                                  width: "80%",
                                  fontSize: "12px",
                                  border: "0px solid red",
                                },
                              }}
                              width="100%"
                              className="map"
                              placeholder={`Enter your ${token.name}`}
                              onChange={evt => setFieldsData({ ...fieldsData, [token.id]: evt.currentTarget.value })}
                            />
                          </Box>
                        </Box>
                        {token.instructions ? (
                          <div>Please follow the instructions for more reference</div>
                        ) : (
                          <Text className="text">
                            {t("apps.accessAPIModalMainText1")} {token.name} {t("apps.accessAPIModalMainText2")}{" "}
                            {selectedApp.name} {t("apps.accessAPIModalMainText3")}
                          </Text>
                        )}
                        {token.instructions ? (
                          <Box sx={{ ml: "31px", p: "5px" }}>
                            <ul>
                              {eval(token.instructions).map(name => (
                                <li dangerouslySetInnerHTML={{ __html: name }}></li>
                                // <li key={name}> {name} </li>
                              ))}
                            </ul>
                          </Box>
                        ) : null}
                      </div>
                    )
                  }
                })}
            </Flex>
            <Flex flexDirection="row" sx={{ justifyContent: "center" }}>
              <Box>
                <Button
                  variant="primary"
                  fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                  sx={{
                    backgroundColor: "app.secondary.110",
                    boxShadow: "none",
                    fontWeight: "normal",
                    cursor: "pointer",
                    fill: "#152f73",
                    color: "app.primary.30",
                    "&:hover": {
                      fill: "app.secondary.80",
                      color: "app.secondary.80",
                    },
                    display: "flex",
                    p: "10px",
                    gap: ["6px", "6px", "8px", "10px"],
                    alignItems: "center",
                  }}
                  onClick={saveTokenData}
                >
                  <Flex sx={{ width: ["16px", "18px", "20px", "22px"] }}>{svg_1.correct}</Flex>
                  <Text sx={{ m: "auto 0px", fontWeight: "500" }}> {t("apps.yesContinueText")}</Text>
                </Button>
              </Box>
            </Flex>
          </Flex>
        </ReactModal>
      )}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  )
}

const mapStateToProps = state => {
  const { apps = {} } = state.getIn(["konnect"]).toJS()
  const { oauthData = {}, appDetails } = state.getIn(["apps"]).toJS()

  return {
    apps,
    oauthData,
    appDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getApps: (searchQuery, authorizedOnly) => dispatch(getApps(searchQuery, authorizedOnly)),
    searchApps: () => dispatch(searchApps()),
    getOauthData: pay => dispatch(getOauthData(pay)),
    saveOauthData: pay => dispatch(saveOauthData(pay)),
    getAppDetails: pay => dispatch(getAppDetails(pay)),
    updateAppStatus: pay => dispatch(updateAppStatus(pay)),
    saveAuthLabel: pay => dispatch(saveAuthLabel(pay)),
  }
}

export const AppsContainer = connect(mapStateToProps, mapDispatchToProps)(Container)
