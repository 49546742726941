import React, { useState, useEffect } from "react"
import { Box, Text, Flex, Link } from "rebass"
import { Checkbox, Label } from "@rebass/forms"
import { useTranslation } from "react-i18next"
import { Button } from "src/components/index"
import px2vw from "utils/px2vw"
import "./styles.css"
import { Dropdown } from "src/components/Konnect/DropdownMenu/index"
import { Input } from "src/components/Input/Input"
import { ConditionItem } from "./components/conditionItem"
import { v4 } from "uuid"

export const ConditionsModal = ({
  fromFields,
  closeModal,
  operatorsList,
  conditionsList,
  updateConditions,
  konnect_activity_id,
}) => {
  const { t } = useTranslation()
  const [conditions, setConditions] = useState(
    conditionsList && conditionsList.length > 0 ? conditionsList : [{ key: v4(), field: "", operator: "", value: "" }]
  )

  const updateCondition = (conditionObj, shouldDelete) => {
    if (shouldDelete) {
      let filteredConditions = conditions.filter(condition => condition !== conditionObj)
      setConditions(
        filteredConditions.length === 0 ? [{ key: v4(), field: "", operator: "", value: "" }] : filteredConditions
      )
    } else {
      let index = conditions.findIndex(condition => condition.key === conditionObj.key)
      index > -1 ? (conditions[index] = { ...conditionObj }) : conditions.push(conditionObj)
      setConditions([...conditions])
    }
  }

  const addNewCondition = () => {
    setConditions(conditions => [...conditions, { key: v4(), field: "", operator: "", value: "" }])
  }
  return (
    <>
      <Flex
        sx={{
          textAlign: "left",
          cursor: "pointer",
          flexDirection: "column",
          backgroundColor: "app.primary.110",
        }}
      >
        <Box
          className={"modal-scroller"}
          sx={{
            width: "95%",
            height: "100%",
            marginTop: "40px",
            marginLeft: "35px",
            minHeight: "600px",
            maxHeight: "600px",
          }}
        >
          {conditions &&
            conditions.map((condition, i) => (
              <ConditionItem
                key={i}
                condition={condition}
                itemIndex={i}
                operatorsList={operatorsList}
                fromFields={fromFields}
                updateCondition={updateCondition}
                showQueryOperators={conditions.length > 0 && i !== conditions.length - 1}
                konnect_activity_id={konnect_activity_id}
              />
            ))}
          <Button
            fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
            sx={{
              background: "none",
              boxShadow: "none",
              fontWeight: "normal",
              mt: "20px",
              cursor: "pointer",
              color: "app.secondary.70",
              marginRight: "20px",
              "&:hover": {
                background: "none",
                color: "app.secondary.70",
              },
            }}
            onClick={addNewCondition}
          >
            {t("konnect.conditions.addNewConditionBtnLabel")}
          </Button>
        </Box>
        <Box sx={{ height: "60px", textAlign: "center" }}>
          <Flex flexDirection="row" sx={{ justifyContent: "center", marginTop: "10px" }}>
            <Box sx={{ textAlign: "center", justifyContent: "center" }}>
              <Button
                fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                sx={{
                  background: "none",
                  boxShadow: "none",
                  fontWeight: "normal",
                  cursor: "pointer",
                  color: "app.secondary.70",
                  marginRight: "20px",
                  "&:hover": {
                    background: "none",
                    color: "app.secondary.70",
                  },
                }}
                onClick={closeModal}
              >
                {t("konnect.conditions.cancelBtnLabel")}
              </Button>
            </Box>
            <Box>
              <Button
                variant="primary"
                fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                sx={{ fontWeight: "normal", px: px2vw(20), borderRadius: "0px", color: "#FFF" }}
                onClick={() => updateConditions(conditions)}
              >
                {t("konnect.conditions.okBtnLabel")}
              </Button>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}
