import React, { memo, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Box, Text, Flex, Link } from "rebass"
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from "react-tooltip"
import { ConditionsModal } from "./modals/conditionsModal"
import { Modal } from "react-responsive-modal"

import {
  AppInfo,
  ConnectorOuter,
  ConnectorPortOuter,
  ConnectorPortLabel,
  ConnectorPortInput,
  ConnectorPortText,
  ConnectorPortTextConditions,
  NodeOuter,
  SelectionHeader,
  EventSelectionGroup,
  TestAndReviewButton,
  ConditionsButton,
  AddMoreFieldButton,
} from "../../konnect/UI"
//import { Apimodal } from "./modals/apimodal"
import { CommonOuter } from "../NodeInner/CommonOuter"
import { AdditionalConfigsModal } from "../../../../../components/Konnect/Base/Components/NodeInner/modals/additionalConfigsModal" //./modals/additionalConfigsModal
import { Handle } from "react-flow-renderer"

import { v4 } from "uuid"
import { Icon } from "src/components"
import { onDeleteNodeV2, appTestAndReview, getConditionsList } from "../../../features/index"
import {
  onDeleteNode,
  handleRefresh,
  setConfigMenuState,
  setLinkedAccount,
  setEventsConfig,
  onPortTextChange,
  enableTestReviewLoader,
  expandCollapseNode,
  modifyConditions,
} from "../../../store/slice/canvasSlice"
import { useSelector, useDispatch } from "react-redux"
export const Conditions = memo(({ data, isConnectable }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [openConditions, setOpenConditions] = useState(false)
  const [IsDeleteShown, setIsDeleteShown] = useState(false)
  const [openAdditionalConfigs, setOpenAdditionalConfigs] = useState(false)
  const [addedKeys, setAddedKeys] = useState([])
  const [nodeReviewed, setNodeReviewed] = useState(data.tested ? data.reviewed : null)
  const [nodeTested, setNodeTested] = useState(data.tested ? data.tested : null)
  const [conditionsText, setConditionsText] = useState("") // Conditions text displayed in the port text area
  const [conditionsList, setConditionsList] = useState([{ key: v4(), field: "", operator: "", value: "" }])
  const [addit, setAddit] = useState([])
  const { editorState, conditionsList: operatorList } = useSelector(state => state.canvas.canvas)
  const { links, nodes } = editorState
  const nodeIdx = nodes.findIndex(x => x.data.nodeid === data.nodeid)
  function findSubstringWithTenAlphabets(text) {
    const pattern = /[A-Za-z]+/g // Regular expression to match consecutive alphabetic characters
    const matches = text.match(pattern) // Find all matches of consecutive alphabetic characters

    if (text) {
      for (let i = 0; i < text.length; i++) {
        if (text[i].length > 35) {
          return text[i] // Found a substring with more than 10 alphabets, return it
        }
      }
    }

    return null // No substring with more than 10 alphabets found
  }

  // Example usage:
  const text =
    "This is a sample string with a longsubstringofcharacters. You can find a substring of length greater than 10 alphabets."
  const substring = findSubstringWithTenAlphabets(text)
  let hasSequence =
    data.appEventConfigurations && data.appEventConfigurations.filter(x => x.sequence !== 0).length ? true : false

  let configForms = []

  if (hasSequence) {
    configForms = data.appEventConfigurations.slice(0, data.appEventSequences.length + 1)
  }
  let operationList
  useEffect(() => {
    dispatch(getConditionsList())
  }, [])
  let formConditions = []
  let formElements = []
  const handleConditionsModal = event => {
    setOpenConditions(true)
  }
  const onCloseModal = list => {
    setOpenConditions(false)
  }
  const handleAdditionalConfigsClick = () => {
    setOpenAdditionalConfigs(true)
  }
  const createAdditionalFields = props => {
    const { fields, formElements } = props
    const newFeilds = fields
    // const newFeilds = fields.filter((x)=>{x.id !=formElements.array.forEach(element => {
    //   element.id
    // });})
    setAddit(newFeilds)
  }
  // if (addit.length > 0) {
  //   addit.map((q, idx) => {
  //     // let x = response[q]

  //     let k = idx.toString() //id for handle bcoz id to be passed in form of string

  //     formElements.push(
  //       <div key={idx}>
  //         <ConnectorPortLabel>{q.label}</ConnectorPortLabel>
  //         <div style={{ display: "flex", position: "relative" }}>
  //           <ConnectorPortInput
  //             type="text"
  //             placeholder={q.value}
  //             id={idx}
  //             onChange={e => {
  //               e.preventDefault()
  //             }}
  //           />
  //           <Handle
  //             type="source"
  //             style={{
  //               height: "20px",
  //               background: "#152f73",
  //               width: "20px",
  //               borderRadius: "60%",
  //               border: "1px solid #152f73",
  //             }}
  //             position="right"
  //             id={k}
  //             isConnectable={isConnectable}
  //           />
  //         </div>
  //       </div>
  //     )
  //   })
  // }
  const onCloseAdditionalConfigsModal = list => {
    setOpenAdditionalConfigs(false)
    const selected = list.filter(x => x.selected)
    setAddedKeys(selected)
    createAdditionalFields({ fields: selected, nodeId: data.nodeid })
    //
  }

  const updateConditions = conditions => {
    let conditionsText = conditions.map(condition => {
      let queryOperatorText = condition.queryOperator === undefined ? "" : condition.queryOperator.toUpperCase()
      return (
        "<h1>" +
        condition.field.label +
        " " +
        condition.operator +
        " " +
        condition.value +
        " " +
        queryOperatorText +
        "\r\n" +
        "</h1>"
      )
    })
    setConditionsList(conditions)
    setConditionsText(conditionsText)
    setOpenConditions(false)
    dispatch(
      modifyConditions({
        data,
        nodeIdx: nodeIdx,
        conditions,
        conditionsText,
      })
    )
  }

  let sections = []
  const extract = (obj, parentKey) => {
    Object.keys(obj).map(key => {
      if (typeof obj[key] === "object") {
        extract(obj[key], key)
        sections.push({ label: key, key: parentKey ? parentKey + "$." + key : key, items: { ...obj[key] } })
        delete obj[key]
      }
    })
  }
  const Section = ({ info, setExpanded, index, isExpanded, parent = [] }) => {
    return (
      <>
        <Flex
          width="100%"
          height="40px"
          sx={{
            background: "#F3F7FF",
            borderRadius: "8px",
            alignItems: "center",
            px: "10px",
            mb: "10px",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch(
              expandCollapseNode({
                nodeId: data.nodeid,
                sectionId: info.id,
              })
            )
          }}
        >
          {info.label}
          <Icon icon={isExpanded ? faChevronUp : faChevronDown} size="lg" />
        </Flex>
      </>
    )
  }
  if (data.configResponses) {
    let ConnectorPort = ConnectorPortTextConditions
    formConditions = data.configResponses.filter(res => res.config_key === "conditions_setup")
    formConditions = formConditions.map(x => {
      let data1 = data.fromPorts ? data.fromPorts : []
      const __key = x.config_key
        .replace(/[^a-zA-Z ]/g, "")
        .split(" ")
        .join("_")

      let disable = false
      let _id = data.nodeid
      let conditions = data.conditions || []

      const portInfo = {
        node: data.nodeid,
        app: x.app_id,
        appEvent: data.appEvent,
        port: x.id,
        label: x.label,
        id: x.id,
        config_key: x.config_key,
      }

      return (
        <>
          <ConnectorOuter key={`${x.app_id}_${__key}`} type={x.key_value_type}>
            <ConnectorPortOuter placement="left" type={x.key_value_type}>
              <Handle
                type="target"
                position="left"
                isConnectable="true"
                style={{
                  height: "20px",
                  background: "#152f73",
                  width: "20px",
                  borderRadius: "60%",
                  border: "1px solid #152f73",
                }}
                id={x.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "source" })}
              />
            </ConnectorPortOuter>

            <ConnectorPort>
              {conditions &&
                conditions.length > 0 &&
                conditions.map((condition, i) => {
                  let queryOperatorText =
                    condition.queryOperator === undefined ? "" : condition.queryOperator.toUpperCase()
                  // Handling empty entries in the condition
                  if (condition.field === "" || condition.operator === "") {
                    return ""
                  }
                  // Remove the query operator AND or OR if this is the last item
                  if (i === conditions.length - 1) {
                    queryOperatorText = ""
                  }
                  let res = condition.field.label
                  let res1 =    condition.value.name ? condition.value.name : condition.value
                  const string = condition && res.length > 35 || findSubstringWithTenAlphabets(res)
                  const string1 = condition && res1.length > 35 ||  findSubstringWithTenAlphabets(
                   res1
                  )

                  return (
                    <Flex sx={{ flexDirection: "column", paddingLeft:"5px", }}>
                      <span
                      data-tip={string?condition.field.label:null}
                        style={{
                          color: "#152F73",
                          fontSize: "18px",
                         
                          overflow: string ? "hidden" : null,
                          textOverflow: string ? "ellipsis" : "none",
                          whiteSpace: string ? "nowrap" : "none",
                        }}
                      >
                      {condition.field.label}
                      </span>
                      <span style={{ color: "#909395", fontSize: "18px" }}>{condition.operator}</span>

                      <span
                            data-tip={string1?condition.value.name ? condition.value.name : condition.value:null}
                        style={{
                          color: "#152F73",
                          fontSize: "18px",
                          overflow: string1 ? "hidden" : null,
                          textOverflow: string1 ? "ellipsis" : "none",
                          whiteSpace: string1 ? "nowrap" : "none",
                        }}
                      >
                        {" " +
                          ` ${condition.value.name ? condition.value.name : condition.value}` +
                          " " +
                          queryOperatorText}{" "}
                      </span>


                
                   <ReactTooltip
         
              // id="zzzzzz"
              place={"top"}
             
             
            /> 
                      {/* {condition.field.label + " "}
                          <span style={{ color: "#909395", fontSize: "18px" }}>{condition.operator}</span>
                          {" " +
                            ` ${condition.value.name ? condition.value.name : condition.value}` +
                            " " +
                            queryOperatorText} */}
                    </Flex>
                  )
                })}
            </ConnectorPort>

            <ReactTooltip
              id={`${x.app_id}_${__key}`}
              // id="zzzzzz"
              place={"bottom"}
              type={"dark"}
              effect={"solid"}
              multiline={true}
              overridePosition={({ left, top }) => {
                return { left: left / chart.scale, top: top / chart.scale }
              }}
            />
          </ConnectorOuter>
        </>
      )
    })

    formElements = data.configResponses.filter(res => res.config_key !== "conditions_setup")
    formElements = formElements.map(x => {
      let ConnectorPort = x.key_value_type === "text" ? ConnectorPortText : ConnectorPortInput
      let data1 = data.fromPorts ? data.fromPorts : []

      const portInfo = {
        node: data.nodeid,
        app: data.id,
        appEvent: x.app_event_id?x.app_event_id:"undefined",
        port: x.id,
        label: x.label,
        id: x.id,
        config_key: x.config_key,
      }

      const __key = x.config_key
        ? x.config_key
        : ""
            .replace(/[^a-zA-Z ]/g, "")
            .split(" ")
            .join("_")

      let disable = false
      if (x.type === "leaf" || x.level === 0) {
        if (
          x.type === "leaf" &&
          // isActive ||
          (x.isOpen || x.level === 0)
        ) {
          return (
            <>
              <ConnectorPortLabel>{x.label}</ConnectorPortLabel>
              <ConnectorOuter key={`${x.app_id}_${__key}`} type={x.key_value_type}>
                <span
                  style={{
                    width: "100%",
                  }}
                  data-for={`${x.app_id}_${__key}`}
                  data-tip={x.label ? x.label : ""}
                  data-iscapture="true"
                >
                  <ConnectorPort
                    rows={4}
                    dirty={x.value ? false : !!x.dirty}
                    placeholder={!x.dirty ? x.label : `${x.label}*`}
                    key={`${x.app_id}_${__key}`}
                    disabled={x.key_value_type === "display" || disable}
                    onMouseDown={e => {
                      e.stopPropagation()
                    }}
                    onChange={e => {
                      dispatch(
                        onPortTextChange({
                          nodeId: data.nodeid,
                          // portId: x.portId,
                          config_key: x.config_key,
                          input: e.target.value,
                        })
                      )
                    }}
                    value={x.value ? x.value : ""}
                    data={data1}
                    additional={x.additional}
                  />
                </span>

                <ConnectorPortOuter placement="right" type={x.key_value_type}>
                  <Handle
                    type="source"
                    position="right"
                    isConnectable={isConnectable}
                    style={{
                      height: "20px",
                      background: "#152f73",
                      width: "20px",
                      borderRadius: "60%",
                      border: "1px solid #152f73",
                    }}
                    id={x.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "source" })}
                  />
                </ConnectorPortOuter>
              </ConnectorOuter>
            </>
          )
        } else if (x.isAvailable || x.isOpen || x.level === 0) {
          return <Section info={x} isExpanded={x.isOpen}></Section>
        }
      } else {
        return (
          <>
            <ConnectorPortLabel>{x.label}</ConnectorPortLabel>
            <ConnectorOuter key={`${x.app_id}_${__key}`} type={x.key_value_type}>
              <span
                style={{
                  width: "100%",
                }}
                data-for={`${x.app_id}_${__key}`}
                data-tip={x.label ? x.label : ""}
                data-iscapture="true"
              >
                <ConnectorPort
                  rows={4}
                  dirty={x.value ? false : !!x.dirty}
                  placeholder={!x.dirty ? x.label : `${x.label}*`}
                  key={`${x.app_id}_${__key}`}
                  disabled={x.key_value_type === "display" || disable}
                  onMouseDown={e => {
                    e.stopPropagation()
                  }}
                  onChange={e => {
                    dispatch(
                      onPortTextChange({
                        nodeId: data.nodeid,
                        // portId: x.portId,
                        config_key: x.config_key,
                        input: e.target.value,
                      })
                    )
                  }}
                  value={x.value ? x.value : ""}
                  data={data1}
                  additional={x.additional}
                />
              </span>

              <ConnectorPortOuter placement="right" type={x.key_value_type}>
                <Handle
                  type="source"
                  position="right"
                  isConnectable={isConnectable}
                  id={x.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "target" })}
                  style={{
                    height: "20px",
                    background: "#152f73",
                    width: "20px",
                    borderRadius: "60%",
                    border: "1px solid #152f73",
                  }}
                />
              </ConnectorPortOuter>
            </ConnectorOuter>
          </>
        )
      }
    })
  }

  useEffect(() => {
    setNodeTested(data.tested ? data.tested : null)
    setNodeReviewed(data.tested ? data.reviewed : null)
  }, [data.tested, data.reviewed])
  return (
    <>
      <CommonOuter node={data} reviewed={nodeReviewed} nodeTested={nodeTested} />
      <NodeOuter
        onMouseEnter={() => setIsDeleteShown(true)}
        onMouseLeave={() => setIsDeleteShown(false)}
        isFirst={!nodeIdx}
        reviewed={data.tested ? data.reviewed : null}
      >
        <AppInfo
          title={data.name}
          subtitle={data.account}
          image={data.image}
          className="DraggableHandle"
          background={data.background}
          hasDelete={IsDeleteShown && (nodeIdx !== 0 || nodes.length === 1)}
          onDelete={() => {
            if (data.tested || data.konnect_activity_id) {
              dispatch(
                onDeleteNodeV2({
                  konnect_id: nodes[0].data.konnect_id,
                  konnect_activity_id: data.konnect_activity_id,
                  canvas_json: editorState,
                  nodeId: data.nodeid,
                })
              ).then(res => {
                dispatch(onDeleteNode(data))
              })
            } else {
              dispatch(onDeleteNode(data))
            }
          }}
        ></AppInfo>
        <>
          <EventSelectionGroup>
            <SelectionHeader></SelectionHeader>

            {formConditions.length ? (
              <EventSelectionGroup>{formConditions.map(form => form)}</EventSelectionGroup>
            ) : null}
          </EventSelectionGroup>

          <EventSelectionGroup>
            {/* <SelectionHeader></SelectionHeader> */}

            <ConditionsButton onClick={handleConditionsModal}>
              {t("konnect.conditions.conditionsHeaderText")}
            </ConditionsButton>
          </EventSelectionGroup>

          <EventSelectionGroup>
            {/* <SelectionHeader></SelectionHeader> */}

            {data.configResponses && data.showTestAndReview ? (
              <>
                <SelectionHeader></SelectionHeader>

                <TestAndReviewButton
                  onClick={() => {
                    dispatch(
                      appTestAndReview({
                        data,
                        nodeIdx: nodeIdx,
                        editorState: editorState,
                        enableTestReviewLoader,

                        dispatch,
                      })
                    )
                  }}
                >
                  Test & Review
                </TestAndReviewButton>
              </>
            ) : null}
          </EventSelectionGroup>

          {formElements.length ? (
            <EventSelectionGroup>
              <SelectionHeader>{t("konnect.sidebar.configure_response")}</SelectionHeader>

              {formElements.map(form => form)}
            </EventSelectionGroup>
          ) : null}
        </>
      </NodeOuter>
      <Modal
        open={openConditions}
        onClose={onCloseModal}
        onAnimationEnd={() => {
          scrollTo(0, 0)
        }}
        showCloseIcon={false}
        center
        styles={{ modal: { padding: "0px", borderRadius: "16px", minWidth: "1100px", minHeight: "700px" } }}
      >
        <ConditionsModal
          closeModal={onCloseModal}
          operatorsList={operatorList}
          conditionsList={data.conditions}
          fromFields={data.fromFields}
          updateConditions={updateConditions}
          // konnect_activity_id={nodes[nodeIdx].data.parent_konnect_activity_id}
        />
      </Modal>
      {data.additionalResponses && data.additionalResponses.length ? (
        <AddMoreFieldButton onClick={handleAdditionalConfigsClick}></AddMoreFieldButton>
      ) : null}
      <Modal
        open={openAdditionalConfigs}
        onClose={onCloseAdditionalConfigsModal}
        onAnimationEnd={() => scrollTo(0, 0)}
        center
        styles={{ modal: { padding: "0px", borderRadius: "16px", minWidth: "1100px", minHeight: "700px" } }}
      >
        <AdditionalConfigsModal
          closeModal={onCloseAdditionalConfigsModal}
          addedKeys={addedKeys}
          data={data.additionalResponses}
          appIcon={data.image}
        />
      </Modal>
    </>
  )
})
