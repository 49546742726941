// import React from "react"
// import { Box, Text, Flex, Link } from "rebass"
// import { useTranslation } from "react-i18next"
// import px2vw from "utils/px2vw";
// import DeleteIconSvg from "src/assets/images/deleteIcon"
// import { Button } from "src/components/index";

// export const DeleteModal = ({ }) => {
//     const { t } = useTranslation()
//     return (
//         <>
//             <Flex flexDirection="column" sx={{ borderRadius: "16px", px: px2vw(60), padding: '0px' }}>
//                 <Box sx={{ p: px2vw(20), justifyContent: 'center', textAlign: 'center' }}>
//                     <DeleteIconSvg />
//                 </Box>
//                 <Box sx={{ color: "#000000", p: px2vw(10) }}>
//                     <Text variant="title" fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}>{t("konnectzList.deleteModalBodyText")}</Text>
//                 </Box>
//                 <Box sx={{ p: px2vw(10) }}>
//                     <Flex flexDirection="row" sx={{ justifyContent: 'space-between' }}>
//                         <Box>
//                             <Button variant="primary" fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }} sx={{ fontWeight: 'normal', px: px2vw(20), borderRadius: '0px' }} onClick={() => onCloseDeleteModal()}>{t("konnectzList.deleteModalNoText")}</Button>
//                         </Box>
//                         <Box sx={{ textAlign: 'center', justifyContent: 'center' }}>
//                             <Button fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
//                                 sx={{
//                                     background: 'none',
//                                     boxShadow: 'none',
//                                     fontWeight: 'normal',
//                                     cursor: 'pointer',
//                                     color: "app.secondary.90",
//                                     "&:hover": {
//                                         background: "none",
//                                         color: "app.secondary.90"
//                                     }
//                                 }}
//                                  onClick={() => deletemember(userId)}
//                                 >{t("konnectzList.deleteModalDeleteText")}
//                                 </Button>
//                         </Box>
//                     </Flex>
//                 </Box>
//             </Flex>
//         </>
//     )
// }

import React, { useState } from "react"
import { Box, Text, Flex, Link } from "rebass"
import { Input, Button, Icon } from "src/components"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw"
import TrashBinImg from "src/assets/images/trash_bin.png"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import DeleteIcon from "../../assets/images/Delete_icon.svg"
export const DeleteModal = ({ onCloseDeleteModal,name, userId, deletemember }) => {
  const { t } = useTranslation()

  return (
    <>
      <Flex flexDirection="column" sx={{ borderRadius: "16px", px: px2vw(60), padding: "0px", minWidth: "500px" }}>
        <Flex flexDirection="row" sx={{ borderRadius: "16px", px: px2vw(20), padding: "0px" }}>
          <Box
            sx={{
              p: "6px 3px 0px 5px",
              m: "2px",
              justifyContent: "left",
              textAlign: "left",
              boxShadow: "1px 1px 1px 1px #e6e2e2",
              borderRadius: "5px",
            }}
          >
            <img width="20px" height="21px" src={TrashBinImg} />
          </Box>
          <Text
            fontSize={[20]}
            fontWeight="normal"
            color="app.primary.30"
            textAlign="left"
            sx={{ pt: 2.5, justifyContent: "left", textAlign: "left", mr: px2vw(3), ml: "12px" }}
          >
            Delete
          </Text>
        </Flex>
        <Box sx={{ background: "#BCC6CC", width: "inherit", height: "1px", margin: "14px -20px" }}></Box>
        <Box sx={{ p: px2vw(20), justifyContent: "center", textAlign: "center" }}>
          <img width="90px" height="95px" src={TrashBinImg} />
        </Box>
        <Box sx={{ color: "#000000", p: px2vw(10) }}>
          <Text
            sx={{
              mb: px2vw(18),
              fontSize: "20px",
              color: "app.primary.30",
              fontWeight: "normal",
              textAlign: "center",
              width: "50%",
              margin: "auto",
            }}
          >
            Are you sure you want to
delete Sub-Account    <span style={{fontWeight:"bold"}}>{name}</span>   
          </Text>
        </Box>
        <Box sx={{ p: px2vw(10) }}>
          <Flex flexDirection="row" sx={{ justifyContent: "center", gap: "30px", mb: "10px", mt: "10px" }}>
            <Box sx={{ textAlign: "center", justifyContent: "center" }}>
              <Button
                fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                sx={{
                  backgroundColor: "app.secondary.10",
                  padding: "8px 13px 8px 13px",
                  fontWeight: "normal",
                  cursor: "pointer",
                  borderWidth: "2px",
                  color: "app.secondary.60",
                  "&:hover": {
                    backgroundColor: "app.secondary.10",
                    color: "app.secondary.60",
                  },
                }}
                onClick={() => onCloseDeleteModal()}
              >
                {t("konnectzList.folders.cancelBtn")}
              </Button>
            </Box>
            <Box sx={{ textAlign: "center", justifyContent: "center" }}>
              <Button
                fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                sx={{
                  backgroundColor: "app.secondary.120",
                  boxShadow: "none",
                  display: "flex",
                  fontWeight: "normal",
                  cursor: "pointer",
                  color: "app.secondary.60",
                  "&:hover": {
                    backgroundColor: "app.secondary.120",
                    color: "app.secondary.60",
                  },
                  padding: "8px 0px 8px 8px",
                }}
                onClick={() => {
                  deletemember(userId)
                  onCloseDeleteModal()
                }}
              >
                <Box sx={{ cursor: "pointer", justifyContent: "center" }} pt="1px">
                  <DeleteIcon width="20" height="16" />
                </Box>

                <Box sx={{ cursor: "pointer", m: "1px 20px 0 10px" }}>{t("konnectzList.folders.deleteBtn")}</Box>
              </Button>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}
