import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { DashboardView } from "./Dashboard.view"
import { SearchHeader, Preloader } from "src/components"
import {
  getActiveWidgets,
  getWidgetDetails,
  updateKonnectStatus,
  getKonnectsList,
  updateToggleToast,
  updateToastContent,
  updateToast,
} from "src/store/actions"
import AuthTokenService from "utils/AuthTokenService"
import { ToastContainer, toast } from "react-toastify"
import { Modal } from "react-responsive-modal"
import { DeleteModal } from "../konnectz/modals/DeleteModal.view"
import Cross from "../../assets/images/cross.svg"
import { Box, Text, Flex, Link } from "rebass"
import { useHistory } from "react-router-dom"

export const Container = props => {
  const {
    getActiveWidgets,
    getWidgetDetails,
    activeWidgets,
    updateKonnectStatus,
    updateToggleToast,
    updateToastContent,
    toggleToast,
    updateToast,
    toastContent,
  } = props
  const [loading, setLoading] = useState(false)
  const [konnects, setKonnects] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [active, setActive] = useState("ACTIVE")
  const [toasty, setToasty] = useState(null)
  const [folderId, setFolderId] = useState(null)
  const [konnectId, setKonnectId] = useState(null)
  useEffect(() => {
    setLoading(true)
    getActiveWidgets().then(res => {
      if (res.type === "GET_ACTIVE_WIDGETS_FAIL") {
        if (res.error.response.status === 422) {
          AuthTokenService.clear()
          props.history.push("/login")
        }
      }
      setLoading(false)
    })
  }, [])
  useEffect(() => {
    // changeStatus()
    getWidget()
  }, [])

  const getWidget = () => {
    //  setLoading(true)
    getWidgetDetails("latest_konnectz").then(res => {
      if (res.type === "GET_WIDGET_DETAILS_SUCCESS") {
        let details = res.payload
        setKonnects(details.konnects)
      }
      //   setLoading(false)
    })
  }

  const changeStatus = (konnectId, status, checked) => {
    //   setLoading(true)

    updateKonnectStatus(konnectId, status === "ACTIVE" ? "INACTIVE" : "ACTIVE").then(res => {
      if (res.payload.message === "FAILED") {
        setToasty(true)
      } else {
        getWidget()
        setLoading(false)
        setToasty(true)

        setKonnects(konnects)
        setLoading(false)
      }

      if (status === "Draft") {
        // updateToast("OO DWEAH")
        toast.info("Failed  because status is Draft"),
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
      }
      if (status === "ACTIVE") {
        // updateToast({ message: "OO AWEAH", toggle: true })
        // setTimeout(() => {
        //   updateToast({ message: null, toggle: false })
        // }, 2000)
        toast.info("Status  changed to INACTIVE"),
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
      }
      if (status === "INACTIVE") {
        // updateToast("OO IWEAH")
        // setTimeout(() => {
        //   updateToast({ message: null, toggle: false })
        // }, 2000)
        toast.info("Status  changed to ACTIVE"),
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
      }

      //  setLoading(false)
      //getWidgetDetails()
    })
  }

  // if(toasty==="SUCCESS"){
  //   toast.info("SUCCESS ,  status is Changed"), {
  //     position: "top-center",
  //     autoClose: 5000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //   }
  // }

  const deleteKonnectModal = konnectId => {
    setOpenModal(true)
    setKonnectId(konnectId)
  }
  const deleteKonnect = status => {
    setLoading(true)
    updateKonnectStatus(konnectId, status).then(() => {
      if (folderId) {
        getKonnectsByFolderId(folderId).then(() => {
          getWidget()
          setLoading(false)
        })
      } else {
        getWidget()
        setKonnects(konnects)
        setLoading(false)
      }
      onCloseModal()
    })
  }
  const onCloseModal = () => {
    setOpenModal(false)
  }
  return (
    <>
      {loading && <Preloader loading={loading} />}
      <SearchHeader
        style={{ width: "100%" }}
        hideSearch={true}
        home={true}
        toggleToast={toggleToast}
        toastContent={toastContent}
      />
      <DashboardView
        {...props}
        activeWidgets={activeWidgets}
        getWidgetDetails={getWidgetDetails}
        updateKonnectStatus={updateKonnectStatus}
        changeStatus={changeStatus}
        active={active}
        konnects={konnects}
        deleteKonnectModal={deleteKonnectModal}
      />
      <Modal
        open={openModal}
        onClose={onCloseModal}
        closeIcon={<Cross />}
        center
        styles={{ modal: { padding: "20px", borderRadius: "16px" } }}
      >
        <Box sx={{ padding: "20px" }}>
          {" "}
          <DeleteModal closeModal={onCloseModal} onDeleteClick={deleteKonnect} />
        </Box>
      </Modal>
    </>
  )
}

const mapStateToProps = state => {
  return {
    activeWidgets: state.getIn(["dashboard", "activeWidgets"]),
    toggleToast: state.getIn(["dashboard", "toggleToast"]),
    toastContent: state.getIn(["dashboard", "toastContent"]),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateToast: data => dispatch(updateToast(data)),
    updateToggleToast: data => dispatch(updateToggleToast(data)),
    updateToastContent: data => dispatch(updateToastContent(data)),
    getActiveWidgets: () => dispatch(getActiveWidgets()),
    getWidgetDetails: widgetKey => dispatch(getWidgetDetails(widgetKey)),
    updateKonnectStatus: (id, status) => dispatch(updateKonnectStatus(id, status)),
    deleteKonnectFolder: folderId => dispatch(deleteKonnectFolder(folderId)),
    getKonnectsByFolderId: id => dispatch(getKonnectsByFolderId(id)),
  }
}

export const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(Container)
