export const forSuccess = {
  event_description: "Request on KonnectzIT webhook from Webhook will trigger Create Spreadsheet Row on Google Sheet.",
  id: 4246,
  left_app: { name: "Webhook", background_color: "#e9f7ec" },
  right_apps: [{ app_name: "Google Sheet", background_color: "#e9f7ec" }],
  status: "SUCCESS",
  task_count: 1,
  task_date: "2022-09-19T10:10:24.923Z",
  task_user: "P K",
  new_success: [
    {
      id: 1,
      created: "11-07-2022 19:52:29 +00:00(the time and date when task is updated)",
      data: [
        {
          app_name: "gmail",
          form: "***token***",
          time: "11-07-2022 19:52:29 +00:00(the time and date when app is updated or firstly added",
          name: "v",
          email: "a@gmail.com",
          Form: "templates-basic-EN-Contactform",
          fields_with_no_value: "querystring",
        },
		{
			app_name: "google sheet",
			form: "***token***",
			time: "11-07-2022 19:52:29 +00:00(the time and date when app is updated or firstly added",
			name: "v",
			email: "a@gmail.com",
			Form: "templates-basic-EN-Contactform",
			fields_with_no_value: "querystring",
		  },
		  {
			app_name: "gmail",
			form: "***token***",
			time: "11-07-2022 19:52:29 +00:00(the time and date when app is updated or firstly added",
			name: "v",
			email: "a@gmail.com",
			Form: "templates-basic-EN-Contactform",
			fields_with_no_value: "querystring",
		  },
		  {
			  app_name: "google sheet",
			  form: "***token***",
			  time: "11-07-2022 19:52:29 +00:00(the time and date when app is updated or firstly added",
			  name: "v",
			  email: "a@gmail.com",
			  Form: "templates-basic-EN-Contactform",
			  fields_with_no_value: "querystring",
			},
      ],
    },
  ],
}
export   const tasks = [1, 2, 3, 4, 5,6,7,8,9,10,11,12,13,14,15,16]

export const forError = {
	event_description: "Request on KonnectzIT webhook from Webhook will trigger Create Spreadsheet Row on Google Sheet.",
	id: 4246,
	left_app: { name: "Webhook", background_color: "#e9f7ec" },
	right_apps: [{ app_name: "Google Sheet", background_color: "#e9f7ec" }],
	status: "SUCCESS",
	task_count: 1,
	task_date: "2022-09-19T10:10:24.923Z",
	task_user: "P K",
	new_error: [
	  {
		id: 1,
		created: "11-07-2022 19:52:29 +00:00(the time and date when task is updated)",
		data: [
		  {
			app_name: "gmail",
			form: "***token***",
			time: "11-07-2022 19:52:29 +00:00(the time and date when app is updated or firstly added",
			name: "v",
			email: "a@gmail.com",
			Form: "templates-basic-EN-Contactform",
			fields_with_no_value: "querystring",
		  },
		  {
			  app_name: "google sheet",
			  form: "***token***",
			  time: "11-07-2022 19:52:29 +00:00(the time and date when app is updated or firstly added",
			  name: "v",
			  email: "a@gmail.com",
			  Form: "templates-basic-EN-Contactform",
			  fields_with_no_value: "querystring",
			},
			{
				app_name: "gmail",
				form: "***token***",
				time: "11-07-2022 19:52:29 +00:00(the time and date when app is updated or firstly added",
				name: "v",
				email: "a@gmail.com",
				Form: "templates-basic-EN-Contactform",
				fields_with_no_value: "querystring",
			  },
			  {
				  app_name: "google sheet",
				  form: "***token***",
				  time: "11-07-2022 19:52:29 +00:00(the time and date when app is updated or firstly added",
				  name: "v",
				  email: "a@gmail.com",
				  Form: "templates-basic-EN-Contactform",
				  fields_with_no_value: "querystring",
				},
		],
	  },
	],
  }

  
   export const taskLogss= { task_logs: [
        {
            "id": 4312,
            "event_description": 1,
            "task_date": "2022-10-12T07:37:06.388Z",
            "status": "SUCCESS"
        },
        {
            "id": 4311,
            "event_description": 2,
            "task_date": "2022-10-12T07:35:01.462Z",
            "status": "SUCCESS"
        },
        {
            "id": 4310,
            "event_description": 3,
            "task_date": "2022-10-12T07:33:02.566Z",
            "status": "SUCCESS"
        },
        {
            "id": 4309,
            "event_description": 4,
            "task_date": "2022-10-12T07:31:04.453Z",
            "status": "SUCCESS"
        },
        {
            "id": 4308,
            "event_description": 5,
            "task_date": "2022-10-12T07:29:06.663Z",
            "status": "SUCCESS"
        },
		{
            "id": 4312,
            "event_description": 1,
            "task_date": "2022-10-12T07:37:06.388Z",
            "status": "SUCCESS"
        },
        {
            "id": 4311,
            "event_description": 2,
            "task_date": "2022-10-12T07:35:01.462Z",
            "status": "SUCCESS"
        },
        {
            "id": 4310,
            "event_description": 3,
            "task_date": "2022-10-12T07:33:02.566Z",
            "status": "SUCCESS"
        },
        {
            "id": 4309,
            "event_description": 4,
            "task_date": "2022-10-12T07:31:04.453Z",
            "status": "SUCCESS"
        },
        {
            "id": 4308,
            "event_description": 5,
            "task_date": "2022-10-12T07:29:06.663Z",
            "status": "SUCCESS"
        },
		{
            "id": 4312,
            "event_description": 1,
            "task_date": "2022-10-12T07:37:06.388Z",
            "status": "SUCCESS"
        },
        {
            "id": 4311,
            "event_description": 2,
            "task_date": "2022-10-12T07:35:01.462Z",
            "status": "SUCCESS"
        },
        {
            "id": 4310,
            "event_description": 3,
            "task_date": "2022-10-12T07:33:02.566Z",
            "status": "SUCCESS"
        },
        {
            "id": 4309,
            "event_description": 4,
            "task_date": "2022-10-12T07:31:04.453Z",
            "status": "SUCCESS"
        },
        {
            "id": 4308,
            "event_description": 5,
            "task_date": "2022-10-12T07:29:06.663Z",
            "status": "SUCCESS"
        },
		{
            "id": 4312,
            "event_description": 1,
            "task_date": "2022-10-12T07:37:06.388Z",
            "status": "SUCCESS"
        },
        {
            "id": 4311,
            "event_description": 2,
            "task_date": "2022-10-12T07:35:01.462Z",
            "status": "SUCCESS"
        },
        {
            "id": 4310,
            "event_description": 3,
            "task_date": "2022-10-12T07:33:02.566Z",
            "status": "SUCCESS"
        },
        {
            "id": 4309,
            "event_description": 4,
            "task_date": "2022-10-12T07:31:04.453Z",
            "status": "SUCCESS"
        },
        {
            "id": 4308,
            "event_description": 5,
            "task_date": "2022-10-12T07:29:06.663Z",
            "status": "SUCCESS"
        },
		{
            "id": 4312,
            "event_description": 1,
            "task_date": "2022-10-12T07:37:06.388Z",
            "status": "SUCCESS"
        },
        {
            "id": 4311,
            "event_description": 2,
            "task_date": "2022-10-12T07:35:01.462Z",
            "status": "SUCCESS"
        },
        {
            "id": 4310,
            "event_description": 3,
            "task_date": "2022-10-12T07:33:02.566Z",
            "status": "SUCCESS"
        },
        {
            "id": 4309,
            "event_description": 4,
            "task_date": "2022-10-12T07:31:04.453Z",
            "status": "SUCCESS"
        },
        {
            "id": 4308,
            "event_description": 5,
            "task_date": "2022-10-12T07:29:06.663Z",
            "status": "SUCCESS"
        },
		{
            "id": 4312,
            "event_description": 1,
            "task_date": "2022-10-12T07:37:06.388Z",
            "status": "SUCCESS"
        },
        {
            "id": 4311,
            "event_description": 2,
            "task_date": "2022-10-12T07:35:01.462Z",
            "status": "SUCCESS"
        },
        {
            "id": 4310,
            "event_description": 3,
            "task_date": "2022-10-12T07:33:02.566Z",
            "status": "SUCCESS"
        },
        {
            "id": 4309,
            "event_description": 4,
            "task_date": "2022-10-12T07:31:04.453Z",
            "status": "SUCCESS"
        },
        {
            "id": 4308,
            "event_description": 5,
            "task_date": "2022-10-12T07:29:06.663Z",
            "status": "SUCCESS"
        }
    ]

}
  
  
  
