import React, { useState } from "react"
import styled from "@emotion/styled"
import px2vw from "utils/px2vw"

import { DecoratorIcon, Text } from "src/components"
import { size } from "../dimensions"

const CheckboxOuter = styled.div`
  width: 100%;
  margin-top: ${size.editor.node.selectors.checkbox.marginBottom};
  display: flex;
  align-items: center;
`

const StyledCheckbox = styled.input`
  display: flex;
  height: ${size.editor.node.selectors.checkbox.checkboxHeight};
  width: ${size.editor.node.selectors.checkbox.checkboxHeight};
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 3px;
  cursor: pointer;
  padding: ${size.editor.node.selectors.checkbox.padding};
`

const StyledText = styled(Text)`
  font-weight: 500;
  padding-left: ${size.editor.node.selectors.checkbox.fontSize};
  font-size: ${size.editor.node.selectors.checkbox.fontSize};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const Checkbox = ({ ...props }) => {
  const { title = "", enabled = false, onChange } = props

  return (
    <CheckboxOuter>
      <StyledCheckbox
        type="checkbox"
        onChange={() => {
          onChange && onChange(!enabled)
        }}
        defaultChecked={enabled}
      ></StyledCheckbox>
      <StyledText>{title}</StyledText>
    </CheckboxOuter>
  )
}
