import React, { useEffect, useState } from "react"
import { Box, Text, Flex, Image } from "rebass"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw"
import { faPen, faSave, faTimes, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import { Input, Icon, Span, Button } from "src/components"
import ReactModal from "react-modal"
import Sheild from "src/assets/images/sheild"
import ProfileSvg from "src/assets/images/profile"
import ReactTooltip from "react-tooltip"
import { Modal } from "react-responsive-modal"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import PlusIcon from "src/assets/images/Plus_icon.svg"
import Cross from "../../assets/images/cross.svg"
import EditIcon from "src/assets/images/Edit_icon.svg"
import DisconnectIcon from "src/assets/images/Disconnect_icon.svg"
import DeleteIcon from "src/assets/images/Delete_icon.svg"
import SaveIcon from "src/assets/images/Save_icon.svg"
import InfiniteScroll from "react-infinite-scroll-component"
import { SyncOutlined } from "@ant-design/icons"
import { AppItem } from "./AppItem"
import "./style.css"
import { svg } from "../../features/ReactFlow/asset/svg"
import { useThemeContext } from "../../features/ReactFlow/store/context/themeContext"
import { svg_1 } from "../../assets/images/svg"
export const AppsView = ({
  newApps,
  apps,
  fetchMoreApps,
  handleAppAuth,
  getAppDetails,
  appDetails,
  launchAuth,
  updateAppStatus,
  saveAuthLabel,
  setReAuthorise,
  reAuthorise,
  searchValue,
  setAppUserId,
}) => {
  const { t } = useTranslation()
  const { bP } = useThemeContext()
  const [selectedAuth, setSelectedAuth] = useState(-1)
  const [tokenModal, setTokenModal] = useState(false)
  const [selectedApp, setSelectedApp] = useState(null)
  const [openInstructions, setOpenInstructions] = useState(false)
  const [instructions, setInstructions] = useState("")
  const [reAuth, setReAuth] = useState(true)
  const [fetchedData, setFetchData] = useState(false)

  const debounce = func => {
    let timeout = null
    return function executedFunction(...args) {
      const later = () => {
        timeout = null
        func(...args)
      }

      clearTimeout(timeout)
      timeout = setTimeout(later, 1000)
    }
  }

  const saveLabel = debounce((id, val) => {
    saveAuthLabel({ id: id, label: val })
  })
  // const apps1 = apps.apps
  const handleAppId = (id, re) => {
    localStorage.setItem("app_user_id", id)
    localStorage.setItem("re-auth", reAuth)
  }
  return (
    <>
      <Flex
        flexDirection="column"
        height="90%"
        p={{ _: 26, sm: 26, md: 28, lg: 30, xl: 32 }}
        sx={{
          backgroundColor: "#F3F7FF",
        }}
      >
        {apps && apps.length > 0 && apps[0] !== "not-found" ? (
          <Flex
            id="scrollableDiv1"
            sx={{
              overflowY: "auto",
              height: "100%",
              boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
              backgroundColor: "#fff",
              borderRadius: "16px",
            }}
          >
            <InfiniteScroll
              dataLength={apps.length || 0} //This is important field to render the next data
              next={fetchMoreApps}
              style={{
                flexFlow: "wrap",
                overflowY: "auto",
                width: "100%",
                justifyContent: "center",
                flexDirection: "row",
                display: "flex",
                padding: bP([20, 20, 22, 24]),
              }}
              hasMore={true}
              pullDownToRefresh={false}
              scrollableTarget="scrollableDiv1"
              loader={
                !searchValue && (
                  <Flex
                    width={{ _: 32, sm: 32, md: 34, lg: 38, xl: 45 }}
                    height={{ _: 32, sm: 32, md: 34, lg: 38, xl: 45 }}
                    sx={{
                      cursor: "pointer",
                      fill: "#152f73",
                      m: "20px auto",
                      animation: "rotation 2s infinite linear",
                    }}
                  >
                    {svg.load}
                  </Flex>
                )
              }
            >
              {apps &&
                apps.length > 0 &&
                apps.map((app, index) => {
                  return (
                    <>
                      {/* <div key={index}>
              {app.id}
              </div> */}
                      <AppItem
                        key={index}
                        app={app}
                        setFetchData={setFetchData}
                        index={index}
                        setTokenModal={setTokenModal}
                        setSelectedAuth={setSelectedAuth}
                        selectedAuth={selectedAuth}
                        selectedApp={selectedApp}
                        getAppDetails={getAppDetails}
                        setSelectedApp={setSelectedApp}
                        setOpenInstructions={setOpenInstructions}
                      />
                    </>
                  )
                })}
            </InfiniteScroll>
          </Flex>
        ) : apps && apps.length === 0 ? (
          <Flex
            sx={{
              height: "100%",
              width: "100%",
              boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
              backgroundColor: "#fff",
              borderRadius: "16px",
            }}
          >
            <Flex
              width={{ _: 50, sm: 52, md: 54, lg: 56, xl: 58 }}
              height={{ _: 50, sm: 52, md: 54, lg: 56, xl: 58 }}
              sx={{
                cursor: "pointer",
                fill: "#152f73",
                m: "auto",
                animation: "rotation 2s infinite linear",
              }}
            >
              {svg.load}
            </Flex>{" "}
          </Flex>
        ) : (
          <Flex width="100%" height="100%" flexDirection="column" sx={{ gap: "20px", alignItems: "center" }}>
            <Flex sx={{ m: "auto", flexDirection: "column" }}>
              <Flex
                width={{ _: 50, sm: 52, md: 54, lg: 56, xl: 58 }}
                height={{ _: 50, sm: 52, md: 54, lg: 56, xl: 58 }}
                sx={{
                  cursor: "pointer",
                  fill: "#ffd43b",
                  m: "auto",
                }}
              >
                {svg.alert_1}
              </Flex>
              <Text textAlign="center" fontSize={{ _: 16, sm: 16, md: 16, lg: 16, xl: 18 }}>
                Not Found
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
      <ReactModal
        isOpen={tokenModal  }
        parentSelector={() => document.querySelector("body")}
        onRequestClose={() => {
          setSelectedApp(null)
          setSelectedAuth(null)
          setFetchData(null)
          setTokenModal(false)
        }}
        style={{
          overlay: { display: "flex", alignItems: "center", justifyContent: "center" },
          content: {
            top: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: bP([600, 650, 700, 750]),
            maxHeight: px2vw(600),
            borderRadius: "8px",
            boxShadow: "0px 0px 15px 4px rgb(0,0,0,0.5)",
            position: "relative",
            alignItems: "normal",
            padding: "0px",
          },
        }}
      >
        <Flex width="100%" height="100%" sx={{ flexDirection: "column", padding: ["20px"], gap: "20px" }}>
          {fetchedData  ? (
            <>
              <Flex
                sx={{
                  width: "100%",
                  alignContent: "center",
                  justifyContent: "space-between",
                  position: "relative",
                  pb: ["10px", "12px", "14px", "16px"],
                  borderBottom: "2px solid #152f73",
                }}
              >
                <Flex
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: ["10px", "12px", "14px", "16px"],
                    width: "80%",
                  }}
                >
                  <Image width="8%" src={selectedApp.image_url} sx={{ boxShadow: "0px 0px 5px 1px #c1d2ff" }} />
                  <Text
                    fontSize={{ _: 18, sm: 18, md: 20, lg: 22, xl: 22 }}
                    fontWeight="semi-bold"
                    color="app.primary.30"
                    textAlign="left"
                    sx={{ justifyContent: "left", textAlign: "left" }}
                  >
                    {selectedApp.name}
                  </Text>
                  <Text
                    fontSize={14}
                    sx={{
                      width: "20px",
                      height: "20px",
                      textAlign: "center",

                      borderRadius: "10px",
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "4px",
                    }}
                  >
                    {appDetails.authorized_app_accounts.length}
                  </Text>
                </Flex>
                <Flex
                  sx={{
                    m: "auto",
                    width: "20%",
                    height: "100%",
                    fill: "red",

                    justifyContent: "flex-end",
                  }}
                >
                  <Flex
                    onClick={() => {
                      setSelectedApp(null)
                      setSelectedAuth(null)
                      setFetchData(null)
                      setTokenModal(false)
                    }}
                    sx={{
                      "&:hover": {
                        transform: "scale(1.2)",
                      },
                      transition: "transform 0.25s",
                      cursor: "pointer",
                      width: ["20px", "22px", "24px", "26px"],
                    }}
                  >
                    {svg.xmark}
                  </Flex>
                </Flex>
              </Flex>
              {appDetails &&  (
                <Flex sx={{ width: "100%", flexDirection: "column", gap: "20px" }}>
                  {appDetails.authorized_app_accounts.length > 0 ? (
                    appDetails.authorized_app_accounts.map((app, idx) => (
                      
                      <>
                        <AccountItem
                          index={idx}
                          key={idx}
                          app={app}
                          updateAppStatus={updateAppStatus}
                          selectedApp={selectedApp}
                          saveLabel={saveLabel}
                          setFetchData={setFetchData}
                          setSelectedApp={setSelectedApp}
                          setTokenModal={setTokenModal}
                          launchAuth={launchAuth}
                          setReAuthorise={setReAuthorise}
                          handleAppId={handleAppId}
                          reAuthorise={reAuthorise}
                          setReAuth={setReAuth}
                          setAppUserId={setAppUserId}
                        />
                      </>
                    ))
                  ) : (
                    <Flex width="100%" height="100%" flexDirection="column" sx={{ gap: "20px", alignItems: "center" }}>
                      <Flex sx={{ m: "auto", flexDirection: "row" }}>
                        <Flex
                          sx={{
                            cursor: "pointer",
                            fill: "#ffd43b",
                            m: "auto 10px auto auto",
                            width: ["20px", "22px", "24px", "26px"],
                            height: ["20px", "22px", "24px", "26px"],
                          }}
                        >
                          {svg.alert_1}
                        </Flex>
                        <Text textAlign="center" fontSize={{ _: 16, sm: 16, md: 16, lg: 16, xl: 18 }}>
                          No Account Found
                        </Text>
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              )}

              <Flex flexDirection="row" sx={{ justifyContent: "center" }}>
                <Box>
                  <Button
                    variant="primary"
                    fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                    sx={{
                      backgroundColor: "app.secondary.110",
                      boxShadow: "none",
                      fontWeight: "normal",
                      cursor: "pointer",
                      fill: "#152f73",
                      color: "app.primary.30",
                      "&:hover": {
                        fill: "app.secondary.80",
                        color: "app.secondary.80",
                      },
                      display: "flex",
                      p: "10px",
                      gap: ["6px", "6px", "8px", "10px"],
                      alignItems: "center",
                    }}
                    onClick={() => {
                      localStorage.removeItem("re-auth")
                      localStorage.removeItem("app_user_id")
                      setReAuth(false)
                      setReAuthorise(false)
                      setTokenModal(false)
                      setSelectedAuth(null)
                      setFetchData(null)
                      setSelectedApp(null)
                      launchAuth(selectedApp)
                    }}
                  >
                    <Flex sx={{ width: ["16px", "18px", "20px", "22px"] }}>{svg.add}</Flex>
                    <Text sx={{ m: "auto 0px", fontWeight: "500" }}> {t("apps.addAccountText")}</Text>
                  </Button>
                </Box>
              </Flex>
            </>
          ) :  (
            <Flex
              sx={{
                height: "100%",
                width: "100%",
                backgroundColor: "#fff",
                m: "50px auto",
                borderRadius: "16px",
              }}
            >
              <Flex
                width={{ _: 50, sm: 52, md: 54, lg: 56, xl: 58 }}
                height={{ _: 50, sm: 52, md: 54, lg: 56, xl: 58 }}
                sx={{
                  cursor: "pointer",
                  fill: "#152f73",
                  m: "auto",
                  animation: "rotation 2s infinite linear",
                }}
              >
                {svg.load}
              </Flex>
            </Flex>
          )}
        </Flex>
      </ReactModal>
    
      
      <Modal
        open={openInstructions}
        onClose={() => setOpenInstructions(false)}
        center
        styles={{ modal: { padding: "40px", paddingTop: "50px", minHeight: "300px" } }}
      >
       {fetchedData ?
       <>
        {selectedApp && selectedApp.webhook_instructions
.trim() !== "" &&
          JSON.parse(selectedApp && selectedApp.webhook_instructions).map((item, i) => (
            <Span key={i} sx={{ color: "#7E7E7E", "& a": { color: "#F7C545", textDecoration: "none" } }}>
              - <label dangerouslySetInnerHTML={{ __html: item }}></label>
              <br />
            </Span>
          ))}
</> :  <Flex
              sx={{
                height: "100%",
                width: "100%",
                backgroundColor: "#fff",
                m: "50px auto",
                borderRadius: "16px",
              }}
            >
              <Flex
                width={{ _: 50, sm: 52, md: 54, lg: 56, xl: 58 }}
                height={{ _: 50, sm: 52, md: 54, lg: 56, xl: 58 }}
                sx={{
                  cursor: "pointer",
                  fill: "#152f73",
                  m: "auto",
                  animation: "rotation 2s infinite linear",
                }}
              >
                {svg.load}
              </Flex>
            </Flex>}
      </Modal> 

      {/* <Reauth selectedApp={selectedApp}/> */}
    </>
  )
}

export const AccountItem = ({
  app,
  updateAppStatus,
  setAppUserId,
  handleAppId,
  setReAuth,
  reAuthorise,
  selectedApp,
  saveLabel,
  index,
  setFetchData,
  setSelectedApp,
  setTokenModal,
  launchAuth,
  setReAuthorise,
}) => {
  const [editMode, setEditMode] = useState(false)
  const [label, setLabel] = useState(app.name)
  return (
    <Flex
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
      key={app.id}
    >
      <Flex width="70%" sx={{ gap: ["6px", "6px", "8px", "8px"] }}>
        <Flex
          sx={{
            width: ["16px", "18px", "20px", "22px"],
            height: ["16px", "18px", "20px", "22px"],
            textAlign: "center",
            m: "auto 0px",
            borderRadius: "10px",
            backgroundColor: "app.primary.70",
            color: "app.secondary.80",
          }}
        >
          <Text fontSize={{ _: 10, sm: 10, md: 12, lg: 12, xl: 14 }} sx={{ m: "auto" }}>
            {index +1}
          </Text>
        </Flex>
        {editMode ? (
          <Input
            width="340px"
            sxProps={{
              border: "1px solid #B7CBFF",
            }}
            marginLeft="7px"
            defaultValue={`${label}`}
            onChange={e => {
              setLabel(e.target.value)
              saveLabel(app.id, e.target.value)
            }}
          />
        ) : (
          <Text
            sx={{
              backgroundColor: "app.secondary.60",
              minWidth: "340px",
              border: "1px solid #b7cbff",
              p: "8px",
              borderRadius: "30px",
              marginRight: "23px",
            }}
          >
            {label}
          </Text>
        )}
      </Flex>

      <Flex width="30%" sx={{ justifyContent: "space-between" }}>
        {!editMode ? (
          <Flex
            onClick={() => {
              setEditMode(!editMode)
            }}
            data-tip={"Edit"}
            width={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
            height={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
            sx={{
              cursor: "pointer",
              "&:hover": {
                transform: "scale(1.2)",
              },
              transition: "transform 0.25s",
            }}
          >
            {svg_1.edit}
          </Flex>
        ) : (
          <Flex
            width={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
            height={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
            sx={{
              cursor: "pointer",
              "&:hover": {
                transform: "scale(1.2)",
              },
              transition: "transform 0.25s",
            }}
            onClick={() => {
              setEditMode(!editMode)
            }}
          >
            {svg.save}
          </Flex>
        )}
        {app.status === "DISCONNECTED" && (
          <>
            <Flex
              data-tip="Connect"
              onClick={() => updateAppStatus(app.id, "CONNECTED", selectedApp.id)}
              width={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
              height={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  transform: "scale(1.2)",
                },
                transition: "transform 0.25s",
              }}
            >
              {svg_1.edit}
            </Flex>
            <ReactTooltip />
          </>
        )}
        {app.status === "CONNECTED" && (
          <>
            <Flex
              onClick={() => {
                setReAuth(true)
                setReAuthorise(true)
                setTokenModal(false)
                setFetchData(null)
                setSelectedApp(null)
                launchAuth(selectedApp)
                setAppUserId(app.id)
                handleAppId(app.id, reAuthorise)
              }}
              data-tip="Re-authorise"
              width={{ _: 38, sm: 38, md: 41, lg: 44, xl: 47 }}
              height={{ _: 38, sm: 38, md: 41, lg: 44, xl: 47 }}
              sx={{
                cursor: "pointer",
                fill: "#1e419d",
                "&:hover": {
                  transform: "scale(1.2)",
                },
                transition: "transform 0.25s",
              }}
            >
              {svg_1.sync}
            </Flex>

            <ReactTooltip />
          </>
        )}
        <Flex
          onClick={() => updateAppStatus(app.id, "REMOVE", selectedApp.id)}
          width={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
          height={{ _: 29, sm: 29, md: 32, lg: 35, xl: 38 }}
          data-tip="Remove"
          sx={{
            cursor: "pointer",
            "&:hover": {
              transform: "scale(1.2)",
            },
            transition: "transform 0.25s",
          }}
        >
          {svg_1.delete}
        </Flex>
        <ReactTooltip />
      </Flex>
    </Flex>
  )
}

export const Reauth = ({ setSelectedAppId, selectedApp }) => {
  const { t } = useTranslation()
  //  if(selectedApp){
  //   setSelectedAppId(selectedApp)
  //  }

  return (
    <>
      <h3>{t("apps.oAuthHandlerText")}</h3>
    </>
  )
}
