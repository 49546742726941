import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { PortWrapper, PortDefault } from "../"
import { Box, Text, Flex, Link } from "rebass"
import { Icon } from "src/components"
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from "react-tooltip"
import { ConditionsModal } from "./modals/conditionsModal"
import { Modal } from "react-responsive-modal"
import { connect } from "react-redux"
import { getConditionsList } from "src/store/actions"

import {
  AppInfo,
  ConnectorOuter,
  ConnectorPortOuter,
  ConnectorPortLabel,
  ConnectorPortInput,
  ConnectorPortText,
  ConnectorPortTextConditions,
  NodeOuter,
  SelectionHeader,
  EventSelectionGroup,
  TestAndReviewButton,
  ConditionsButton,
  AddMoreFieldButton,
} from "../../../UI"
import { Dropdown } from "src/components/Konnect/DropdownMenu"
import { CommonOuter } from "./CommonOuter"
import { AdditionalConfigsModal } from "./modals/additionalConfigsModal"
import { v4 } from "uuid"

const WrappedPort = props => {
  return (
    <PortWrapper
      config={props.config}
      offset={props.offset}
      selected={props.selected}
      selectedLink={props.selectedLink}
      hoveredLink={props.hoveredLink}
      hovered={props.hovered}
      node={props.node}
      port={props.port}
      Component={props.PortDefault}
      onPortPositionChange={props.onPortPositionChange}
      onLinkStart={props.onLinkStart}
      onLinkMove={props.onLinkMove}
      onLinkComplete={props.onLinkComplete}
      onLinkCancel={props.onLinkCancel}
      chart={props.chart}
      pType={props.pType}
      active={props.active}
    />
  )
}
export const ConditionsAppInnerDefault_ = ({
  node,
  chart,
  config,
  offset,
  selected,
  selectedLink,
  hoveredLink,
  hovered,
  onPortPositionChange,
  onLinkStart,
  onLinkMove,
  onLinkComplete,
  onLinkCancel,
  editorActions,
  Links,
  getConditionsList,
  operatorsList,
}) => {
  const { t } = useTranslation()

  const [event, setEvent] = useState(!!node.appEvent)
  const [eventConfig, setEventConfig] = useState(!!node.appEventConfiguration)
  const [eventSequence, setEventSequence] = useState(null)
  const [openConditions, setOpenConditions] = useState(false)
  const [IsDeleteShown, setIsDeleteShown] = useState(false)
  const [openAdditionalConfigs, setOpenAdditionalConfigs] = useState(false)
  const [addedKeys, setAddedKeys] = useState([])
  const [nodeReviewed, setNodeReviewed] = useState(node.tested ? node.reviewed : null)
  const [nodeTested, setNodeTested] = useState(node.tested ? node.tested : null)

  /* Conditions states */
  const [conditionsText, setConditionsText] = useState("") // Conditions text displayed in the port text area
  const [conditionsList, setConditionsList] = useState([{ key: v4(), field: "", operator: "", value: "" }])

  const ports = chart.ports.filter(x => x.node === node.id)

  const selectedEvent = !node.appEvent
    ? t("konnect.sidebar.choose_trigger")
    : node.appEvents.find(x => x.id === node.appEvent).name

  const selectedTrigger = !node.appEventConfiguration
    ? t("konnect.sidebar.customize_form_partial")
    : node.appEventConfigurations.find(x => x.id === node.appEventConfiguration).service_name

  const nodeIdx = chart.nodes.findIndex(x => x.nodeId === node.nodeId)
  const source = nodeIdx === 0 || chart.nodes.length > 2
  // nodeIdx === 0 || node.type === "source" || node.type === "both" || (nodeIdx !== 0 && chart.nodes.length > 2)
  const target = nodeIdx !== 0
  // && (node.type === "target" || node.type === "both")

  let hasSequence =
    node.appEventConfigurations && node.appEventConfigurations.filter(x => x.sequence !== 0).length ? true : false

  let configForms = []

  if (hasSequence) {
    configForms = node.appEventConfigurations.slice(0, node.appEventSequences.length + 1)
  }

  useEffect(() => {
    getConditionsList()
  }, [])

  const onCloseModal = list => {
    setOpenConditions(false)
  }

  const handleConditionsModal = event => {
    setOpenConditions(true)
  }
console.log(operatorsList,"operatfeifi")
  const updateConditions = conditions => {
    let conditionsText = conditions.map(condition => {
      let queryOperatorText = condition.queryOperator === undefined ? "" : condition.queryOperator.toUpperCase()
      return (
        "<h1>" +
        condition.field.label +
        " " +
        condition.operator +
        " " +
        condition.value +
        " " +
        queryOperatorText +
        "\r\n" +
        "</h1>"
        )
      })
    setConditionsList(conditions)
    setConditionsText(conditionsText)
    setOpenConditions(false)
    editorActions.modifyConditions({
      node,
      nodeIdx: nodeIdx,
      conditions,
      conditionsText,
    })
  }

  const { appType = "API" } = node

  let formConditions = []
  let formElements = []

  let sections = []
  const extract = (obj, parentKey) => {
    Object.keys(obj).map(key => {
      if (typeof obj[key] === "object") {
        extract(obj[key], key)
        sections.push({ label: key, key: parentKey ? parentKey + "$." + key : key, items: { ...obj[key] } })
        delete obj[key]
      }
    })
  }

  const getConditionsInfo = () => {
    return conditions[0].key
  }

  const handleAdditionalConfigsClick = () => {
    setOpenAdditionalConfigs(true)
  }

  const onCloseAdditionalConfigsModal = list => {
    setOpenAdditionalConfigs(false)
    const selected = list.filter(x => x.selected)
    setAddedKeys(selected)
    editorActions.createAdditionalFields({ fields: selected, nodeId: node.nodeId })
    //
  }

  const Section = ({ info, setExpanded, index, isExpanded, parent = [] }) => {
    return (
      <>
        <Flex
          width="100%"
          height="40px"
          sx={{
            background: "#F3F7FF",
            borderRadius: "8px",
            alignItems: "center",
            px: "10px",
            mb: "10px",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={() => {
            editorActions.expandCollapseNode({
              nodeId: node.nodeId,
              sectionId: info.id,
            })
          }}
        >
          {info.label}
          <Icon icon={isExpanded ? faChevronUp : faChevronDown} size="lg" />
        </Flex>
      </>
    )
  }

  if (node.configResponses) {
    let ConnectorPort = ConnectorPortTextConditions
    //let ConnectorPort = ConditionsTextComponent
    formConditions = node.configResponses.filter(res => res.config_key === "conditions_setup")
    formConditions = formConditions.map(x => {
      let data = node.fromPorts ? node.fromPorts : []

      const isActive = !!Links.find(c => {
        return x.port && (c.to.port === x.port.id || c.from.port === x.port.id)
      })

      const __key = x.config_key
        .replace(/[^a-zA-Z ]/g, "")
        .split(" ")
        .join("_")

      let disable = false
      let _id = node.nodeId
      let conditions = node.conditions || []
      
      return (
        <>
          <ConnectorOuter key={`${x.app_id}_${__key}`} type={x.key_value_type}>
            <ConnectorPortOuter placement="left" type={x.key_value_type}>
              {
                <WrappedPort
                  pType="target"
                  config={config}
                  offset={offset}
                  selected={selected}
                  selectedLink={selectedLink}
                  hoveredLink={hoveredLink}
                  hovered={hovered}
                  node={node}
                  port={x}
                  Component={PortDefault}
                  onPortPositionChange={onPortPositionChange}
                  onLinkStart={onLinkStart}
                  onLinkMove={onLinkMove}
                  onLinkComplete={onLinkComplete}
                  onLinkCancel={onLinkCancel}
                  chart={chart}
                  active={isActive}
                />
              }
            </ConnectorPortOuter>

            <ConnectorPort>
              {conditions &&
                conditions.length > 0 &&
                conditions.map((condition, i) => {
                  let queryOperatorText =
                    condition.queryOperator === undefined ? "" : condition.queryOperator.toUpperCase()
                  // Handling empty entries in the condition
                  if (condition.field === "" || condition.operator === "") {
                    return ""
                  }
                  // Remove the query operator AND or OR if this is the last item
                  if (i === conditions.length - 1) {
                    queryOperatorText = ""
                  }
                  return (
                    <Flex sx={{ flexDirection: "row" }}>
                      <span style={{ color: "#152F73", fontSize: "18px" }}>
                        {condition.field.label + " "}
                        <span style={{ color: "#909395", fontSize: "18px" }}>{condition.operator}</span>
                        {" " +
                          ` ${condition.value.name ? condition.value.name : condition.value}` +
                          " " +
                          queryOperatorText}
                      </span>
                    </Flex>
                  )
                })}
            </ConnectorPort>

            <ReactTooltip
              id={`${x.app_id}_${__key}`}
              // id="zzzzzz"
              place={"bottom"}
              type={"dark"}
              effect={"solid"}
              multiline={true}
              overridePosition={({ left, top }) => {
                return { left: left / chart.scale, top: top / chart.scale }
              }}
            />
          </ConnectorOuter>
        </>
      )
    })

    formElements = node.configResponses.filter(res => res.config_key !== "conditions_setup")
    // formElements = formElements.map(x => {
    //   let ConnectorPort = x.key_value_type === "text" ? ConnectorPortText : ConnectorPortInput
    //   let data = node.fromPorts ? node.fromPorts : []

    //   const isActive = !!Links.find(c => {
    //     return x.port && (c.to.port === x.port.id || c.from.port === x.port.id)
    //   })

    //   const __key = x.config_key ? x.config_key : ""
    //     .replace(/[^a-zA-Z ]/g, "")
    //     .split(" ")
    //     .join("_")

    //   let disable = false
    //   if(x.type === "leaf" || x.level === 0) {
        
    //     if (x.type === "leaf" && (isActive || x.isOpen || x.level === 0)) {
    //       return (
    //         <>
    //           <ConnectorPortLabel>{x.label}</ConnectorPortLabel>
    //           <ConnectorOuter key={`${x.app_id}_${__key}`} type={x.key_value_type}>
    //             <span
    //               style={{
    //                 width: "100%",
    //               }}
    //               data-for={`${x.app_id}_${__key}`}
    //               data-tip={x.label ? x.label : ""}
    //               data-iscapture="true"
    //             >
    //               <ConnectorPort
    //                 rows={4}
    //                 dirty={x.value ? false : !!x.dirty}
    //                 placeholder={!x.dirty ? x.label : `${x.label}*`}
    //                 key={`${x.app_id}_${__key}`}
    //                 disabled={x.key_value_type === "display" || disable}
    //                 onMouseDown={(e) => {
    //                   e.stopPropagation()
    //                 }}
    //                 onChange={e => {
    //                   editorActions.onPortTextChange({
    //                     nodeId: node.nodeId,
    //                     portId: x.portId,
    //                     config_key: x.config_key,
    //                     input: e.target.value,
    //                   })
    //                 }}
    //                 value={x.value ? x.value : ""}
    //                 data={data}
    //                 additional={x.additional}
    //               />
    //             </span>
    
    //             <ReactTooltip
    //               id={`${x.app_id}_${__key}`}
    //               // id="zzzzzz"
    //               place={"bottom"}
    //               type={"dark"}
    //               effect={"solid"}
    //               multiline={true}
    //               overridePosition={({ left, top }) => {
    //                 return { left: left / chart.scale, top: top / chart.scale }
    //               }}
    //             />
    
    //             <ConnectorPortOuter placement="right" type={x.key_value_type}>
    //               <WrappedPort
    //                 pType="source"
    //                 config={config}
    //                 offset={offset}
    //                 selected={selected}
    //                 selectedLink={selectedLink}
    //                 hoveredLink={hoveredLink}
    //                 hovered={hovered}
    //                 node={node}
    //                 port={x}
    //                 Component={PortDefault}
    //                 onPortPositionChange={onPortPositionChange}
    //                 onLinkStart={onLinkStart}
    //                 onLinkMove={onLinkMove}
    //                 onLinkComplete={onLinkComplete}
    //                 onLinkCancel={onLinkCancel}
    //                 chart={chart}
    //                 active={isActive}
    //               />
    //             </ConnectorPortOuter>
    //           </ConnectorOuter>
    //         </>
    //       )
    //     } else if (x.isAvailable || x.isOpen || x.level === 0) {
    //       return (
    //         <Section info={x} isExpanded={x.isOpen}></Section>
    //       )
    //     }
    //   }else {
    //     return (
    //       <>
    //           <ConnectorPortLabel>{x.label}</ConnectorPortLabel>
    //           <ConnectorOuter key={`${x.app_id}_${__key}`} type={x.key_value_type}>
    //             <span
    //               style={{
    //                 width: "100%",
    //               }}
    //               data-for={`${x.app_id}_${__key}`}
    //               data-tip={x.label ? x.label : ""}
    //               data-iscapture="true"
    //             >
    //               <ConnectorPort
    //                 rows={4}
    //                 dirty={x.value ? false : !!x.dirty}
    //                 placeholder={!x.dirty ? x.label : `${x.label}*`}
    //                 key={`${x.app_id}_${__key}`}
    //                 disabled={x.key_value_type === "display" || disable}
    //                 onMouseDown={(e) => {
    //                   e.stopPropagation()
    //                 }}
    //                 onChange={e => {
    //                   editorActions.onPortTextChange({
    //                     nodeId: node.nodeId,
    //                     portId: x.portId,
    //                     config_key: x.config_key,
    //                     input: e.target.value,
    //                   })
    //                 }}
    //                 value={x.value ? x.value : ""}
    //                 data={data}
    //                 additional={x.additional}
    //               />
    //             </span>
    
    //             <ReactTooltip
    //               id={`${x.app_id}_${__key}`}
    //               // id="zzzzzz"
    //               place={"bottom"}
    //               type={"dark"}
    //               effect={"solid"}
    //               multiline={true}
    //               overridePosition={({ left, top }) => {
    //                 return { left: left / chart.scale, top: top / chart.scale }
    //               }}
    //             />
    
    //             <ConnectorPortOuter placement="right" type={x.key_value_type}>
    //               <WrappedPort
    //                 pType="source"
    //                 config={config}
    //                 offset={offset}
    //                 selected={selected}
    //                 selectedLink={selectedLink}
    //                 hoveredLink={hoveredLink}
    //                 hovered={hovered}
    //                 node={node}
    //                 port={x}
    //                 Component={PortDefault}
    //                 onPortPositionChange={onPortPositionChange}
    //                 onLinkStart={onLinkStart}
    //                 onLinkMove={onLinkMove}
    //                 onLinkComplete={onLinkComplete}
    //                 onLinkCancel={onLinkCancel}
    //                 chart={chart}
    //                 active={isActive}
    //               />
    //             </ConnectorPortOuter>
    //           </ConnectorOuter>
    //         </>
    //     )
    //   }
    // })
  }

  const x = {
    port: {
      source: "570530d1-5ce5-4fe5-a4f4-8ea90bb2d0e1|8|40|id|source",
      target: "570530d1-5ce5-4fe5-a4f4-8ea90bb2d0e1|8|40|id|target",
      id: "570530d1-5ce5-4fe5-a4f4-8ea90bb2d0e1|8|40|id",
    },
    sequence: 0,
    config_key: "id",
    error: null,
    key_value_type: "input",
    value: 4031301480,
    mapped: true,
    label: "ID",
    side: "left",
    app_id: 8,
    source: { position: { x: 392, y: 339.5 }, id: "570530d1-5ce5-4fe5-a4f4-8ea90bb2d0e1|8|40|id|source" },
    id: "id",
    config_key_required: true,
    app_event_id: 40,
    fetch_fields: false,
    ts: 1600199076090,
  }
  useEffect(() => {
    setNodeTested(node.tested ? node.tested : null)
    setNodeReviewed(node.tested ? node.reviewed : null)
  }, [node.tested, node.reviewed])

  return (
    <>
      <CommonOuter node={node} reviewed={nodeReviewed} nodeTested={nodeTested} />
      <NodeOuter
        onMouseEnter={() => setIsDeleteShown(true)}
        onMouseLeave={() => setIsDeleteShown(false)}
        isFirst={!nodeIdx}
        reviewed={node.tested ? node.reviewed : null}
      >
        <AppInfo
          title={node.name}
          subtitle={node.account}
          image={node.image}
          className="DraggableHandle"
          background={node.background}
          hasDelete={IsDeleteShown && (nodeIdx !== 0 || chart.nodes.length === 1)}
          onDelete={() => {
            if (node.tested || node.konnect_activity_id) {
              editorActions
                .onDeleteNodeV2({
                  konnect_id: chart.nodes[0].konnect_id,
                  konnect_activity_id: node.konnect_activity_id,
                  canvas_json: chart,
                  nodeId: node.nodeId,
                })
                .then(data => {
                  editorActions.onDeleteNode({
                    nodeId: node.nodeId,
                  })
                })
            } else {
              editorActions.onDeleteNode({
                nodeId: node.nodeId,
              })
            }
          }}
        ></AppInfo>
        <>
          <EventSelectionGroup>
            <SelectionHeader></SelectionHeader>

            {formConditions.length ? (
              <EventSelectionGroup>{formConditions.map(form => form)}</EventSelectionGroup>
            ) : null}
          </EventSelectionGroup>

          <EventSelectionGroup>
            {/* <SelectionHeader></SelectionHeader> */}

            <ConditionsButton onClick={handleConditionsModal}>
              {t("konnect.conditions.conditionsHeaderText")}
            </ConditionsButton>
          </EventSelectionGroup>

          <EventSelectionGroup>
            {/* <SelectionHeader></SelectionHeader> */}

            {node.configResponses && node.showTestAndReview ? (
              <>
                <SelectionHeader></SelectionHeader>

                <TestAndReviewButton
                  onClick={() => {
                    editorActions.appTestAndReview({
                      node,
                      nodeIdx: nodeIdx,
                      editorState: chart,
                    })
                  }}
                >
                  {t("konnect.conditions.validateBtnLabel")}
                </TestAndReviewButton>
              </>
            ) : null}
          </EventSelectionGroup>

          {formElements.length ? (
            <EventSelectionGroup>
              <SelectionHeader>{t("konnect.sidebar.configure_response")}</SelectionHeader>

              {formElements.map(form => form)}
            </EventSelectionGroup>
          ) : null}
        </>
      </NodeOuter>
      <Modal
        open={openConditions}
        onClose={onCloseModal}
        onAnimationEnd={() => {
          scrollTo(0, 0)
        }}
        showCloseIcon={false}
        center
        styles={{ modal: { padding: "0px", borderRadius: "16px", minWidth: "1100px", minHeight: "700px" } }}
      >
        <ConditionsModal
          closeModal={onCloseModal}
          operatorsList={operatorsList}
          conditionsList={node.conditions}
          fromFields={node.fromFields}
          updateConditions={updateConditions}
          konnect_activity_id={chart.nodes[nodeIdx].parent_konnect_activity_id}
        />
      </Modal>
      {node.additionalResponses && node.additionalResponses.length ? (
        <AddMoreFieldButton onClick={handleAdditionalConfigsClick}></AddMoreFieldButton>
      ) : null}
      <Modal
        open={openAdditionalConfigs}
        onClose={onCloseAdditionalConfigsModal}
        onAnimationEnd={() => scrollTo(0, 0)}
        center
        styles={{ modal: { padding: "0px", borderRadius: "16px", minWidth: "1100px", minHeight: "700px" } }}
      >
        <AdditionalConfigsModal
          closeModal={onCloseAdditionalConfigsModal}
          addedKeys={addedKeys}
          data={node.additionalResponses}
          appIcon={node.image}
        />
      </Modal>
    </>
  )
}

const mapStateToProps = state => {
  return { operatorsList: state.getIn(["konnect", "conditionsList"]) }
}

const mapDispatchToProps = dispatch => {
  return {
    getConditionsList: () => dispatch(getConditionsList()),
  }
}

export const ConditionsAppInnerDefault = connect(mapStateToProps, mapDispatchToProps)(ConditionsAppInnerDefault_)
