import React, { memo, useState, useMemo, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { PortWrapper, PortDefault } from "../"
import { Box, Text, Flex, Link } from "rebass"
import ReactTooltip from "react-tooltip"
//import { ConditionsModal } from "./modals/conditionsModal"
//mport { ApiModal } from "./modals/apiModal"
import { Modal } from "react-responsive-modal"
//import { connect } from "react-redux"
import { Preloader } from "src/components"

import {
  AppInfo,
  ConnectorOuter,
  ConnectorPortOuter,
  ConnectorPortLabel,
  ConnectorPortInput,
  ConnectorPortText,
  NodeOuter,
  SelectionHeader,
  EventSelectionGroup,
  TestAndReviewButton,
  AddMoreFieldButton,
  CouponWrapper,
  CouponDayWrapper,
} from "../UI"
import {
  onDeleteNodeV2,
  getAppEventConfig,
  getAppEventConfigDetail,
  getAppEventConfigDetailFetch,
  appTestAndReview,
  getOauthData,
  updateAppStatus,
  saveOauthData,
  getLinkedAccounts,
} from "../../../features/index"
import { useSelector, useDispatch } from "react-redux"

import {
  onDeleteNode,
  handleRefresh,
  setConfigMenuState,
  setLinkedAccount,
  setEventsConfig,
  onPortTextChange,
  enableTestReviewLoader,
  createAdditionalFields,
  addFieldstoconfig,
  modifySegmentIndex,
} from "../../../store/slice/canvasSlice"
import { Dropdown } from "src/components/Konnect/DropdownMenu"
import { CommonOuter } from "../NodeInner/CommonOuter"
import { useData } from "../../../Context/canvasContext"
import { Handle } from "react-flow-renderer"
import { AdditionalConfigsModal } from "./modals/additionalConfigsModal"

export const TextSplitterDefault = memo(({ data, isConnectable }) => {
  const dispatch = useDispatch()
  const { editorState, customAppData } = useSelector(state => state.canvas.canvas)
  const { links, nodes } = editorState
  const [openAdditionalConfigs, setOpenAdditionalConfigs] = useState(false)
  const [addedKeys, setAddedKeys] = useState([])
  const [addit, setAddit] = useState([])
  const { t } = useTranslation()
  const target = nodeIdx !== 0

  const [IsDeleteShown, setIsDeleteShown] = useState(false)
  const [nodeReviewed, setNodeReviewed] = useState(data.tested ? data.reviewed : null)
  const [nodeTested, setNodeTested] = useState(data.tested ? data.tested : null)
  const [searchEvents, setSearchEvents] = useState("")
  const [searchSegmentIndex, setSearchSegmentIndex] = useState("")
  const [searchAccounts, setSearchAccounts] = useState("")
  const nodeIdx = nodes.findIndex(x => x.id === data.nodeid)
  const source = nodeIdx === 0 || nodes.length > 2
  const appDetails = data.appDetails
  const segmentIndex = appDetails.textSplitter.segmentIndex

  // nodeIdx === 0 || node.type === "source" || node.type === "both" || (nodeIdx !== 0 && chart.nodes.length > 2)
  // const target = nodeIdx !== 0
  // && (node.type === "target" || node.type === "both")
  let hasSequence =
    data.appEventConfigurations && data.appEventConfigurations.filter(x => x.sequence !== 0).length ? true : false
  let configForms = []

  if (hasSequence) {
    configForms = data.appEventConfigurations.slice(0, data.appEventSequences.length + 1)
  }

  let sections = []
  const extract = (obj, parentKey) => {
    Object.keys(obj).map(key => {
      if (typeof obj[key] === "object") {
        extract(obj[key], key)
        sections.push({ label: key, key: parentKey ? parentKey + "$." + key : key, items: { ...obj[key] } })
        delete obj[key]
      }
    })
  }

  const handleAdditionalConfigsClick = () => {
    setOpenAdditionalConfigs(true)
  }

  // const createAdditionalFields = props => {
  //   const { fields } = props
  //   const newFeilds = fields
  //   // const newFeilds = fields.filter((x)=>{x.id !=formElements.array.forEach(element => {
  //   //   element.id
  //   // });})
  //   // data.configResponses= data.configResponses.concat(newFeilds)
  //   dispatch(addFieldstoconfig({ data, newFeilds }))
  //   setAddit(newFeilds)
  // }

  const onCloseAdditionalConfigsModal = list => {
    setOpenAdditionalConfigs(false)
    const selected = list.filter(x => x.selected)
    setAddedKeys(selected)
    dispatch(createAdditionalFields({ fields: selected, nodeId: data.nodeid }))
  }
  const onDropDownChange = (list, val) => {
    let list1 = JSON.parse(JSON.stringify(list))
    list1.forEach(obj => {
      if (obj.id === val.id) {
        obj.selected = true
      } else {
        obj.selected = false
      }
    })
    dispatch(modifySegmentIndex({ data, list1 }))
  }

  let formResponseElements = []

  if (data.configResponses) {
    data.configResponses.map((x, idx) => {
      const portId = `${data.nodeid}|${data.id}|${data.app_event_id}`
      const portInfo = {
        node: data.nodeid,
        app: data.id,
        appEvent:data.appEvent,
        port: x.id,
        label: x.label,
        id: x.id,
        config_key: x.config_key,
      }
      let ConnectorPort = x.key_value_type === "text" ? ConnectorPortText : ConnectorPortInput
      let k = idx.toString() //id for handle bcoz id to be passed in form of string
      const __key = x.config_key
        .replace(/[^a-zA-Z ]/g, "")
        .split(" ")
        .join("_")

      formResponseElements.push(
        <div key={idx}>
        <ConnectorPortLabel>{x.label}</ConnectorPortLabel>
        <Flex>
        {target && !x.additional ? (
                  <ConnectorPortOuter placement="left" type={x.key_value_type}>
                    <Handle
                      type="target"
                      position="left"
                      // isConnectable="true"
                      isConnectable={true}
                      style={{
                        height: "20px",
                        background: "#152f73",
                        width: "20px",
                        borderRadius: "60%",
                        border: "1px solid #152f73",
                      }}
                      id={x.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "target" })}
                    />
                  </ConnectorPortOuter>
                ) : null}
          <ConnectorPort
            rows={4}
            dirty={x.value ? false : !!x.dirty}
            key={`${x.app_id}_${__key}`}
            onMouseDown={e => {
              e.stopPropagation()
            }}
            defaultValue={x.value ? x.value : ""}
            placeholder={!x.dirty ? x.label : `${x.label}*`}
            onChange={e => {
              dispatch(
                onPortTextChange({
                  data,
                  nodeId: data.nodeid,
                  portId: x.id,
                  config_key: x.config_key,
                  input: e.target.value,
                })
              )
            }}
            additional={x.additional}
          ></ConnectorPort>
          <ConnectorPortOuter placement="left" type={x.key_value_type}>
            <Handle
              type="source"
              position="right"
              isConnectable="true"
              style={{
                height: "20px",
                background: "#152f73",
                width: "20px",
                borderRadius: "60%",
                border: "1px solid #152f73",
              }}
              id={x.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "source" })}
            />
          </ConnectorPortOuter>
        </Flex>
      </div>
      )
    })
  }
  if (addit.length > 0) {
    addit.map((q, idx) => {
      const portInfo = {
        node: data.nodeid,
        app: data.id,
        appEvent: data.appEvent,
        port: q.id, // Inject this in context
        label: q.label,
        id: q.id, // Inject this in context
        config_key: q.config_key,
      }
      formResponseElements.push(
        <div key={idx}>
          <ConnectorPortLabel>{q.label}</ConnectorPortLabel>
          <div style={{ display: "flex", position: "relative" }}>
            <ConnectorPortInput
              type="text"
              placeholder={q.value}
              id={idx}
              onChange={e => {
                e.preventDefault()
              }}
            />
            <Handle
              type="source"
              style={{
                height: "20px",
                background: "#152f73",
                width: "20px",
                borderRadius: "60%",
                border: "1px solid #152f73",
              }}
              position="right"
              isConnectable={isConnectable}
              id={q.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "source" })}
            />
          </div>
        </div>
      )
    })
  }
  useEffect(() => {
    setNodeTested(data.tested ? data.tested : null)
    setNodeReviewed(data.tested ? data.reviewed : null)
  }, [data.tested, data.reviewed])
  return (
    <>
      <CommonOuter node={data} reviewed={nodeReviewed} nodeTested={nodeTested} />
      <NodeOuter
        onMouseEnter={() => setIsDeleteShown(true)}
        onMouseLeave={() => setIsDeleteShown(false)}
        isFirst={!nodeIdx}
      >
        <AppInfo
          title={data.name}
          subtitle={data.account}
          image={data.image}
          className="DraggableHandle"
          //  background={node.background}
          hasDelete={IsDeleteShown && (nodeIdx !== 0 || nodes.length === 1)}
          onDelete={() => {
            if (data.tested || data.konnect_activity_id) {
              dispatch(
                onDeleteNodeV2({
                  konnect_id: nodes[0].data.konnect_id,
                  konnect_activity_id: data.konnect_activity_id,
                  canvas_json: editorState,
                  nodeId: data.nodeid,
                })
              ).then(res => {
                dispatch(onDeleteNode(data))
              })
            } else {
              dispatch(onDeleteNode(data))
            }
          }}
        ></AppInfo>
        <>
          <EventSelectionGroup>
            <SelectionHeader>
              {data.appEvents && nodeIdx !== 0
                ? t("konnect.sidebar.choose_action_event")
                : t("konnect.sidebar.choose_trigger")}
            </SelectionHeader>
            <Dropdown
              searchable={true}
              searchValue={searchAccounts}
              onFilterChange={text => {
                setSearchAccounts(text)
              }}
              inputPlaceholder={!searchAccounts ? "Search field" : ""}
              disableIfEmpty
              headerTitle={t("konnect.sidebar.select_trigger_action_dropdown_header")}
              items={data.appEvents}
              renderKey="name"
              selected={data.appEvent ? data.appEvents.find(x => x.id === data.appEvent).name : null}
              onChangeState={open => {}}
              onChange={selected => {
                dispatch(
                  getAppEventConfig({
                    data,
                    nodeId: data.nodeid,
                    appId: data.id,
                    eventId: selected.id,
                    source: nodeIdx === 0,
                    type: selected.side === "left" ? "source" : selected.side === "right" ? "target" : "both",
                  })
                )
                setSearchEvents("")
                // setEvent(false)
              }}
            />
          </EventSelectionGroup>

          <EventSelectionGroup>
            <>
              <SelectionHeader></SelectionHeader>
              <div>
                {data.isloading ? (
                  <div style={{ display: "flex" }}>
                    <Preloader loading={data.isloading} position="static" />
                  </div>
                ) : (
                  <TestAndReviewButton
                    onClick={() => {
                      dispatch(
                        appTestAndReview({
                          data,
                          nodeIdx: nodeIdx,
                          editorState: editorState,
                          appDetails: appDetails,
                          dispatch,
                        })
                      )
                    }}
                  >
                    Test & Review
                  </TestAndReviewButton>
                )}
              </div>

              {formResponseElements.length > 0 ? (
                <EventSelectionGroup>
                  <SelectionHeader>{t("konnect.sidebar.segmentIndex")}</SelectionHeader>
            <div className="nowheel">

                  <CouponWrapper>

                    <Dropdown
                      searchable={true}
                      searchValue={searchSegmentIndex}
                      onFilterChange={text => {
                        setSearchSegmentIndex(text)
                      }}
                      inputPlaceholder={!searchSegmentIndex ? "Search field" : ""}
                      disableIfEmpty
                      items={segmentIndex}
                      renderKey="name"
                      selected={segmentIndex.find(x => x.selected === true).name}
                      onChange={selected => {
                        onDropDownChange(segmentIndex, selected)
                        setSearchSegmentIndex("")
                      }}
                      onChangeState={isOpen => {
                        dispatch(setConfigMenuState({ isOpen }))
                      }}
                    />
                  </CouponWrapper>
                  </div>

                </EventSelectionGroup>
              ) : null}

              <SelectionHeader />
              {formResponseElements.map(form => form)}
            </>
          </EventSelectionGroup>
        </>
      </NodeOuter>
      {data.additionalResponses && data.additionalResponses.length ? (
        <AddMoreFieldButton onClick={handleAdditionalConfigsClick}></AddMoreFieldButton>
      ) : null}
      <Modal
        open={openAdditionalConfigs}
        onClose={onCloseAdditionalConfigsModal}
        onAnimationEnd={() => scrollTo(0, 0)}
        center
        styles={{ modal: { padding: "0px", borderRadius: "16px", minWidth: "1100px", minHeight: "700px" } }}
      >
        <AdditionalConfigsModal
          closeModal={onCloseAdditionalConfigsModal}
          addedKeys={addedKeys}
          data={data.additionalResponses}
          appIcon={data.image}
        />
      </Modal>
    </>
  )
})
