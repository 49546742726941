/**
 * Verification email sent page.
 */
import React, { useEffect, useState } from "react"
import { Text, Flex, Box } from "rebass"
import { useTranslation } from "react-i18next"
import { PieChart as PC, Pie, Cell, Tooltip as ChartTooltip } from "recharts"
import { useThemeContext } from "../../ReactFlow/store/context/themeContext"

export const TaskConsumption = ({ getWidgetDetails }) => {
  const { t } = useTranslation()
  const [data, setData] = useState(null)
  const { bP } = useThemeContext()
  useEffect(() => {
    getWidgetDetails("task_consumption").then(res => {
      if (res.type === "GET_WIDGET_DETAILS_SUCCESS") {
        let details = res.payload
        let d = [
          { name: t("account.agency.consumed"), value: details.task_consumed, color: "#518EF8" },
          { name: t("account.agency.available"), value: details.task_available, color: "#F7C545" },
        ]
        setData(d)
      }
    })
  }, [])
  /* In case if the customised label is needed */
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  const RADIAN = Math.PI / 180
  /* In case if the customised label is needed */

  return (
    <>
      <Flex
        p={{
          _: "20px",
          sm: "20px",
          md: "22px",
          lg: "24px",
          xl: "24px",
        }}
        sx={{
          width: "100%",
          flexDirection: "column",
          backgroundColor: "#FFF",
          borderRadius: "16px",
          boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
        }}
      >
        <Text
          fontSize={{ _: 18, sm: 18, md: 20, lg: 22, xl: 22 }}
          sx={{ fontWeight: "500", color: "#152F73", marginBottom: "8px" }}
        >
          {t("dashboard.widgets.taskConsumption.headerTitle")}
        </Text>
        <Flex sx={{ flexDirection: "Row" }}>
          <PC width={bP([90, 100, 110, 120])} height={bP([90, 100, 110, 120])}>
            <Pie
              data={data}
              labelLine={true}
              //label={renderCustomizedLabel} (Uncomment this if you need customised label)
              outerRadius={40}
              fill="#8884d8"
              dataKey="value"
            >
              {data && data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)}
            </Pie>
          </PC>
          <Flex
            ml={{ _: 20, sm: 20, md: 22, lg: 24, xl: 26 }}
            sx={{ width: "100%", flexDirection: "Column", justifyContent: "center" }}
          >
            {data &&
              data.map((entry, idx) => (
                <Flex key={"chart-labels-" + idx} sx={{ flexDirection: "Row" }}>
                  <Box
                    width={{ _: 10, sm: 10, md: 12, lg: 14, xl: 16 }}
                    height={{ _: 10, sm: 10, md: 12, lg: 14, xl: 16 }}
                    sx={{
                      borderRadius: "100px",
                      backgroundColor: entry.color,
                      mt: "5px",
                      marginRight: "10px",
                    }}
                  />
                  <Text
                    fontSize={{ _: 10, sm: 10, md: 12, lg: 12, xl: 16 }}
                    sx={{ fontWeight: "500", color: "#7482A6", marginBottom: "16px" }}
                  >
                    {entry.name} : {entry.value}
                  </Text>
                </Flex>
              ))}
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
