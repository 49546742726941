import React from "react"
import { Box, Flex, Button as RebassButton, Text } from "rebass"

import { Icon, DecoratorIcon } from "../Icon/Icon"

// type Props = ButtonProps & {
//   icon?: IconDefinition;
//   iconRight?: IconDefinition;
//   stretch?: boolean;
//   iconColor?: string;
//   iconSize?: IconSize | number;
//   type?: string;
// };

export const Button = ({ children, icon, iconRight, stretch = false, iconSize, iconColor, disabled, ...props }) => (
  <Flex>
    <RebassButton
      height="100%"
      disabled={disabled}
      tabIndex={-1}
      width={stretch ? "100%" : "auto"}
      {...props}
      {...children}
    >
      {icon && <DecoratorIcon icon={icon} small={props.variant === "text"} size={iconSize} iconColor={iconColor} />}
      {children}
      {iconRight && (
        <DecoratorIcon icon={iconRight} position="right" small={props.variant === "text"} iconColor={iconColor} />
      )}
    </RebassButton>
  </Flex>
)

// type IconButtonProps =  & {
//   icon: IconDefinition;
//   label: string;
//   size?: IconSize | number;
//   stretch?: boolean;
// };

export const IconButton = ({ icon, label, size, stretch = false, ...props }) => (
  <Box>
    <RebassButton width={stretch ? "100%" : "auto"} {...props}>
      <Icon icon={icon} size={size} />
      <Text>{label}</Text>
    </RebassButton>
  </Box>
)
