/**
 * TaskLogs actions
 */

import { makeRequestActionCreator } from "utils/redux"
import { GET_TASK_LOGS, GET_TASK_DETAILS, QUERY_APP, GET_LOGS } from "config/Constants"

export const getTaskLogs = searchQuery => dispatch => {
  let url = "api/v1/task_logs/konnects"

  if (searchQuery) {
    const qs = Object.keys(searchQuery)
      .map(key => `${key}=${searchQuery[key]}`)
      .join("&")

    url = url + `?${qs}`
  }
  const request = makeRequestActionCreator(GET_TASK_LOGS)
  return dispatch(
    request({
      url: url,
      method: "GET",
      searchQuery,
    })
  )
}


export const getLogs = searchQuery => dispatch => {
  let url = "api/v1/task_logs/logs"

  if (searchQuery) {
    const qs = Object.keys(searchQuery)
      .map(key => `${key}=${searchQuery[key]}`)
      .join("&")

    url = url + `?${qs}`

  }
  const request = makeRequestActionCreator(GET_LOGS)
  return dispatch(
    request({
      url: url,
      method: "GET",
      searchQuery,
    })
  )
}
export const getTaskDetails = taskId => dispatch => {
  const request = makeRequestActionCreator(GET_TASK_DETAILS)
  return dispatch(
    request({
      url: "api/v1/task_logs/" + taskId + "/tasks",
      method: "GET",
    })
  )
}

// export const searchApp = searchQuery => dispatch => {
//   const request = makeRequestActionCreator(QUERY_APP)
//   let url = "api/v1/apps/index1"

//   url = url + "?search_query=" + searchQuery

//   return dispatch(
//     request({
//       url: url,
//       method: "GET",
//     })
//   )
// }
