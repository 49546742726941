/**
 * Dashboard actions
 */

import { makeRequestActionCreator } from "utils/redux"
import { GET_ACTIVE_WIDGETS, GET_WIDGET_DETAILS } from "config/Constants"
const TOAST_TOGGLE = "TOAST_TOGGLE"
const TOAST_CONTENT = "TOAST_CONTENT"
const TOAST = "TOAST"
export const getActiveWidgets = () => dispatch => {
  const request = makeRequestActionCreator(GET_ACTIVE_WIDGETS)

  return dispatch(
    request({
      url: `api/v1/widgets/active`,
      method: "GET",
    })
  )
}

export const getWidgetDetails = widgetKey => dispatch => {
  const request = makeRequestActionCreator(GET_WIDGET_DETAILS)
  return dispatch(
    request({
      url: `api/v1/widgets/details/${widgetKey}`,
      method: "GET",
    })
  )
}

export const updateToggleToast = data => ({
  type: TOAST_TOGGLE,
  data: data,
})

export const updateToastContent = data => ({
  type: TOAST_CONTENT,
  data: data,
})

export const updateToast = message => dispatch => {
  console.log(message)
  return dispatch({
    type: TOAST,
    data: message,
  })
}
