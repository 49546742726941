/**
 * Shell Container
 */

import React from "react"
import { AuthShellView } from './Shell.view'

function AuthShellContainer(props) {
  return (
    <>
      <AuthShellView {...props}>
        {props.children}
      </AuthShellView>
    </>
  )
}

export default AuthShellContainer
