/**
 * Dashboard reducer to handle the dashboard api responses.
 */

import { GET_ACTIVE_WIDGETS, GET_WIDGET_DETAILS } from "config/Constants"

const initState = {
  activeWidgets: [],
  widgetDetails: null,
  toggleToast: false,
  toastContent: null,
}

const SUCCESS = "_SUCCESS"
const FAIL = "_FAIL"
const TOAST_TOGGLE = "TOAST_TOGGLE"
const TOAST_CONTENT = "TOAST_CONTENT"
const TOAST = "TOAST"

const dashboardReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ACTIVE_WIDGETS + SUCCESS:
      return state.set("activeWidgets", action.payload)

    case GET_ACTIVE_WIDGETS + FAIL:
      return state.set("activeWidgets", [])

    case GET_WIDGET_DETAILS + SUCCESS:
      return state.set("widgetDetails", true)

    case GET_WIDGET_DETAILS + FAIL:
      return state.set("widgetDetails", null)
    case TOAST_TOGGLE:
      return state.set("toggleToast", action.payload)
    case TOAST_CONTENT:
      return state.set("toastContent", action.payload)
    case TOAST:
      console.log(action)
      return state.set("toastContent", action.data.message).set("toggleToast", action.data.toggle)
  }

  return state
}

export default dashboardReducer
