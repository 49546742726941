import React from "react"
import PreloaderGif from "src/assets/images/preloader.gif"
import { unset } from "lodash"
import { Flex } from "rebass"
import { svg } from "../../features/ReactFlow/asset/svg"
export const Preloader = ({
  loading,
  position = null,
  width = { _: 50, sm: 52, md: 54, lg: 56, xl: 58 },
  height = { _: 50, sm: 52, md: 54, lg: 56, xl: 58 },
}) => {
  let style = {
    width: 80,
    height: 80,
    position: "absolute",
    top: "45%",
    left: "50%",
  }
  if (position) {
    style = { ...style, position: unset, top: unset, left: unset, width: 60, height: 60 }
  }

  return (
    <div id={position ? "" : "cover-spin"} style={{ display: loading ? "flex" : "none", margin: "auto " }}>
      {/* <img style={{ ...style }} src={PreloaderGif} /> */}
      <Flex
        width={width}
        height={height}
        sx={{
          cursor: "pointer",
          fill: "#152f73",
          m: "auto",
          animation: "rotation 2s infinite linear",
        }}
      >
        {svg.load}
      </Flex>
    </div>
  )
}
