/**
 * reducer to handle the konnects list api responses.
 */

 import { GET_FOLDERS_LIST, GET_FOLDER_KONNECTS, CREATE_FOLDER, MOVE_KONNECTS_TO_FOLDER, RENAME_FOLDER, DELETE_FOLDER } from 'config/Constants'

 const initState = {
   folders: {},
   folderKonnects: {}
 }
 
 const SUCCESS = '_SUCCESS'
 const FAIL = '_FAIL'
 
 const foldersReducer = (state = initState, action) => {
   switch (action.type) {
     case GET_FOLDERS_LIST + SUCCESS:
       return state.set('folders', action.payload.folders)
     case GET_FOLDER_KONNECTS + SUCCESS:
       return state.set('folderKonnects', action.payload.konnects)
     case CREATE_FOLDER + SUCCESS:
     case RENAME_FOLDER + SUCCESS:
     case DELETE_FOLDER + SUCCESS:
     case MOVE_KONNECTS_TO_FOLDER + SUCCESS:
       return state
     default:
       return state
   }
 }
 
 export default foldersReducer
 