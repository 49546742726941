/**
 * Verification email sent page.
 */
import React from "react"
import { Box, Text, Flex, Link } from "rebass"
import { useTranslation } from "react-i18next"
import px2vw from 'utils/px2vw'
import VerificationSentSvg from "src/assets/images/verification_sent"
import { AuthTitle, AuthSubtitle } from 'src/components/Typography/Typography'

export const VerificationSentView = ({ state }) => {

  const { t } = useTranslation()

  return (
    <>
      <Box
        sx={{
          textAlign: 'center'
        }}
      >
        <AuthSubtitle>{t("auth.signup.subtitle")}</AuthSubtitle>
      </Box>
      <Box className="border-gradient" sx={{ width: '121px', height: '0px', margin: '0px auto', mb: px2vw(10), mt: px2vw(5) }} />
      <Box
        sx={{
          textAlign: 'center'
        }}
      >
        <AuthTitle>{t("auth.signup.title")}</AuthTitle>
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          my: px2vw(60)
        }}
      >
        <Box sx={{ textAlign: "center", justifyContent: 'center' }}>
          <VerificationSentSvg />
        </Box>
        <Text fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }} sx={{ paddingTop: px2vw(32), color: "app.secondary.20", fontWeight: '600' }}>{t("auth.signup.verificationSent.verificationText1")}<span sx={{ fontSize: ["sm", "lg", "lg"], color: 'app.secondary.50', fontWeight: 'bold' }}>{state.email}</span>{t("auth.signup.verificationSent.verificationText2")}</Text>
      </Box>
      <Flex flexDirection="column" sx={{ flexGrow: 1, justifyContent: "flex-end", alignItems: "center" }}>
        <Box sx={{ textAlign: "center" }}>
          <Text fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }} sx={{ display: "inline-block", fontStyle: "normal", color: "app.secondary.20" }}>
            {t("auth.signup.alreadyHaveAccountText")}
            <Link href="/login" sx={{ color: "#F7C545" }}>
              {t("auth.signup.loginBtnLabel")}
            </Link>
          </Text>
        </Box>
        <Box sx={{ pt: px2vw(20), textAlign: "center" }}>
          <Text fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }} sx={{ display: "inline-block", fontStyle: "normal", color: "app.secondary.20" }}>
            {t("auth.login.clickingContinueText")}
            <Link href="/signup" style={{ color: "#F7C545" }}>
              {t("auth.login.termsAndConditionsText")}
            </Link>
          </Text>
        </Box>
      </Flex>
    </>

  )
}
