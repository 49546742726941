import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { PortWrapper, PortDefault } from "../"
import { Box, Text, Flex, Link } from "rebass"
import ReactTooltip from "react-tooltip"
import { ConditionsModal } from "./modals/conditionsModal"
import { ApiModal } from "./modals/apiModal"
import { Modal } from "react-responsive-modal"
import { connect } from "react-redux"
import { Preloader } from "src/components"
import { updateSegmentIndex } from "src/store/actions"

import {
  AppInfo,
  ConnectorOuter,
  ConnectorPortOuter,
  ConnectorPortLabel,
  ConnectorPortInput,
  ConnectorPortText,
  NodeOuter,
  SelectionHeader,
  EventSelectionGroup,
  TestAndReviewButton,
  AddMoreFieldButton,
  CouponWrapper,
  CouponDayWrapper,
} from "../../../UI"
import { Dropdown } from "src/components/Konnect/DropdownMenu"
import { CommonOuter } from "./CommonOuter"
import { AdditionalConfigsModal } from "./modals/additionalConfigsModal"
import { v4 } from "uuid"

const WrappedPort = props => {
  return (
    <PortWrapper
      config={props.config}
      offset={props.offset}
      selected={props.selected}
      selectedLink={props.selectedLink}
      hoveredLink={props.hoveredLink}
      hovered={props.hovered}
      node={props.node}
      port={props.port}
      Component={props.PortDefault}
      onPortPositionChange={props.onPortPositionChange}
      onLinkStart={props.onLinkStart}
      onLinkMove={props.onLinkMove}
      onLinkComplete={props.onLinkComplete}
      onLinkCancel={props.onLinkCancel}
      chart={props.chart}
      pType={props.pType}
      active={props.active}
    />
  )
}
export const TextSplitterDefault_ = ({
  node,
  chart,
  config,
  offset,
  selected,
  selectedLink,
  hoveredLink,
  hovered,
  onPortPositionChange,
  onLinkStart,
  onLinkMove,
  onLinkComplete,
  onLinkCancel,
  editorActions,
  Links,
  appDetails,
  updateSegmentIndex,
}) => {
  const [openAdditionalConfigs, setOpenAdditionalConfigs] = useState(false)
  const [addedKeys, setAddedKeys] = useState([])
  const { segmentIndex } = { ...appDetails.textSplitter }

  const { t } = useTranslation()

  const [IsDeleteShown, setIsDeleteShown] = useState(false)
  const [nodeReviewed, setNodeReviewed] = useState(node.tested ? node.reviewed : null)
  const [nodeTested, setNodeTested] = useState(node.tested ? node.tested : null)
  const [searchEvents, setSearchEvents] = useState("")
  const [searchSegmentIndex, setSearchSegmentIndex] = useState("")

  const nodeIdx = chart.nodes.findIndex(x => x.nodeId === node.nodeId)
  const source = nodeIdx === 0 || chart.nodes.length > 2
  // nodeIdx === 0 || node.type === "source" || node.type === "both" || (nodeIdx !== 0 && chart.nodes.length > 2)
  const target = nodeIdx !== 0
  // && (node.type === "target" || node.type === "both")

  let hasSequence =
    node.appEventConfigurations && node.appEventConfigurations.filter(x => x.sequence !== 0).length ? true : false

  let configForms = []

  if (hasSequence) {
    configForms = node.appEventConfigurations.slice(0, node.appEventSequences.length + 1)
  }

  let sections = []
  const extract = (obj, parentKey) => {
    Object.keys(obj).map(key => {
      if (typeof obj[key] === "object") {
        extract(obj[key], key)
        sections.push({ label: key, key: parentKey ? parentKey + "$." + key : key, items: { ...obj[key] } })
        delete obj[key]
      }
    })
  }

  const handleAdditionalConfigsClick = () => {
    setOpenAdditionalConfigs(true)
  }
  const onCloseAdditionalConfigsModal = list => {
    setOpenAdditionalConfigs(false)
    const selected = list.filter(x => x.selected)
    setAddedKeys(selected)
    editorActions.createAdditionalFields({ fields: selected, nodeId: node.nodeId })
  }

  const onDropDownChange = (list, val) => {
    list.forEach(obj => {
      if (obj.id === val.id) {
        obj.selected = true
      } else {
        obj.selected = false
      }
    })
    updateSegmentIndex(list)
  }

  const prepareElements = responses => {
    let formElements = []
    if (responses) {
      formElements = responses.map((x, i) => {
        let disable = !!chart.links.find(c => {
          return x.port && c.to.port === x.port.id
        })

        let ConnectorPort = x.key_value_type === "text" ? ConnectorPortText : ConnectorPortInput
        let data = []

        const isActive = !!Links.find(c => {
          return x.port && (c.to.port === x.port.id || c.from.port === x.port.id)
        })

        const __key = x.config_key
          .replace(/[^a-zA-Z ]/g, "")
          .split(" ")
          .join("_")

        if (nodeIdx === 0 || x.disabled) {
          disable = true
        }
        return (
          <>
            <ConnectorPortLabel>{x.label}</ConnectorPortLabel>
            <ConnectorOuter key={`${x.app_id}_${__key}`} type={x.key_value_type}>
              {target && !x.additional ? (
                <ConnectorPortOuter placement="left" type={x.key_value_type}>
                  {
                    <WrappedPort
                      pType="target"
                      config={config}
                      offset={offset}
                      selected={selected}
                      selectedLink={selectedLink}
                      hoveredLink={hoveredLink}
                      hovered={hovered}
                      node={node}
                      port={x}
                      Component={PortDefault}
                      onPortPositionChange={onPortPositionChange}
                      onLinkStart={onLinkStart}
                      onLinkMove={onLinkMove}
                      onLinkComplete={onLinkComplete}
                      onLinkCancel={onLinkCancel}
                      chart={chart}
                      active={isActive}
                    />
                  }
                </ConnectorPortOuter>
              ) : null}

              <span
                style={{
                  width: "100%",
                }}
                data-for={`${x.app_id}_${__key}`}
                data-tip={x.label ? x.label : ""}
                data-iscapture="true"
              >
                <ConnectorPort
                  rows={4}
                  dirty={x.value ? false : !!x.dirty}
                  placeholder={!x.dirty ? x.label : `${x.label}*`}
                  key={`${x.app_id}_${__key}`}
                  disabled={x.key_value_type === "display" || disable}
                  onMouseDown={e => {
                    e.stopPropagation()
                  }}
                  onChange={e => {
                    editorActions.onPortTextChange({
                      nodeId: node.nodeId,
                      portId: x.portId,
                      config_key: x.config_key,
                      input: e.target.value,
                    })
                  }}
                  value={x.value ? x.value : ""}
                  data={data}
                  additional={x.additional}
                />
              </span>

              <ReactTooltip
                id={`${x.app_id}_${__key}`}
                // id="zzzzzz"
                place={"bottom"}
                type={"dark"}
                effect={"solid"}
                multiline={true}
                overridePosition={({ left, top }) => {
                  return { left: left / chart.scale, top: top / chart.scale }
                }}
              />

              {source || x.additional ? (
                <ConnectorPortOuter placement="right" type={x.key_value_type}>
                  <WrappedPort
                    pType="source"
                    config={config}
                    offset={offset}
                    selected={selected}
                    selectedLink={selectedLink}
                    hoveredLink={hoveredLink}
                    hovered={hovered}
                    node={node}
                    port={x}
                    Component={PortDefault}
                    onPortPositionChange={onPortPositionChange}
                    onLinkStart={onLinkStart}
                    onLinkMove={onLinkMove}
                    onLinkComplete={onLinkComplete}
                    onLinkCancel={onLinkCancel}
                    chart={chart}
                    active={isActive}
                  />
                </ConnectorPortOuter>
              ) : null}
            </ConnectorOuter>
            {x.error ? <ConnectorPortError>{x.error}</ConnectorPortError> : null}
          </>
        )
      })
    }
    return formElements
  }

  let formResponseElements = prepareElements(node.configResponses)
  // let nodeReviewed = node.tested ? node.reviewed : null

  useEffect(() => {
    setNodeTested(node.tested ? node.tested : null)
    setNodeReviewed(node.tested ? node.reviewed : null)
  }, [node.tested, node.reviewed])

  return (
    <>
      <CommonOuter node={node} reviewed={nodeReviewed} nodeTested={nodeTested} />
      <NodeOuter
        onMouseEnter={() => setIsDeleteShown(true)}
        onMouseLeave={() => setIsDeleteShown(false)}
        isFirst={!nodeIdx}
        reviewed={nodeReviewed}
      >
        <AppInfo
          title={node.name}
          subtitle={node.account}
          image={node.image}
          className="DraggableHandle"
          background={node.background}
          hasDelete={IsDeleteShown && (nodeIdx !== 0 || chart.nodes.length === 1)}
          onDelete={() => {
            if (node.tested || node.konnect_activity_id) {
              editorActions
                .onDeleteNodeV2({
                  konnect_id: chart.nodes[0].konnect_id,
                  konnect_activity_id: node.konnect_activity_id,
                  canvas_json: chart,
                  nodeId: node.nodeId,
                })
                .then(data => {
                  editorActions.onDeleteNode({
                    nodeId: node.nodeId,
                  })
                })
            } else {
              editorActions.onDeleteNode({
                nodeId: node.nodeId,
              })
            }
          }}
        ></AppInfo>
        <>
          <EventSelectionGroup>
            <SelectionHeader>{t("konnect.sidebar.choose_action_event")}</SelectionHeader>
            <Dropdown
              searchable={true}
              searchValue={searchEvents}
              onFilterChange={text => {
                setSearchEvents(text)
              }}
              inputPlaceholder={!searchEvents ? "Search field" : ""}
              disableIfEmpty
              headerTitle={t("konnect.sidebar.select_trigger_action_dropdown_header")}
              items={node.appEvents}
              renderKey="name"
              selected={node.appEvent ? node.appEvents.find(x => x.id === node.appEvent).name : null}
              onChange={selected => {
                editorActions.getAppEventConfig({
                  nodeId: node.nodeId,
                  appId: node.appId,
                  eventId: selected.id,
                  source: nodeIdx === 0,
                  type: selected.side === "left" ? "source" : selected.side === "right" ? "target" : "both",
                })
                setSearchEvents("")
                // setEvent(false)
              }}
            />
          </EventSelectionGroup>

          <EventSelectionGroup>
            <>
              <SelectionHeader></SelectionHeader>
              <div>
                {node.isloading ? (
                  <div style={{ display: "flex" }}>
                    <Preloader loading={node.isloading} position="static" />
                  </div>
                ) : (
                  <TestAndReviewButton
                    onClick={() => {
                      editorActions.appTestAndReview({
                        node,
                        nodeIdx: nodeIdx,
                        editorState: chart,
                        appDetails: appDetails,
                      })
                    }}
                  >
                    {t("konnect.sidebar.generate")}
                  </TestAndReviewButton>
                )}
              </div>

              {formResponseElements.length > 0 ? (
                <EventSelectionGroup>
                  <SelectionHeader>{t("konnect.sidebar.segmentIndex")}</SelectionHeader>
                  <CouponWrapper>
                    <Dropdown
                      searchable={true}
                      searchValue={searchSegmentIndex}
                      onFilterChange={text => {
                        setSearchSegmentIndex(text)
                      }}
                      inputPlaceholder={!searchSegmentIndex ? "Search field" : ""}
                      disableIfEmpty
                      items={segmentIndex}
                      renderKey="name"
                      selected={segmentIndex.find(x => x.selected === true).name}
                      onChange={selected => {
                        onDropDownChange(segmentIndex, selected)
                        setSearchSegmentIndex("")
                      }}
                      onChangeState={isOpen => {
                        editorActions.setConfigMenuState({ isOpen })
                      }}
                    />
                  </CouponWrapper>
                </EventSelectionGroup>
              ) : null}

              <SelectionHeader />
              {formResponseElements.map(form => form)}
            </>
          </EventSelectionGroup>
        </>
      </NodeOuter>
      {node.additionalResponses && node.additionalResponses.length ? (
        <AddMoreFieldButton onClick={handleAdditionalConfigsClick}></AddMoreFieldButton>
      ) : null}
      <Modal
        open={openAdditionalConfigs}
        onClose={onCloseAdditionalConfigsModal}
        onAnimationEnd={() => scrollTo(0, 0)}
        center
        styles={{ modal: { padding: "0px", borderRadius: "16px", minWidth: "1100px", minHeight: "700px" } }}
      >
        <AdditionalConfigsModal
          closeModal={onCloseAdditionalConfigsModal}
          addedKeys={addedKeys}
          data={node.additionalResponses}
          appIcon={node.image}
        />
      </Modal>
    </>
  )
}

const mapStateToProps = state => {
  return {
    appDetails: state.getIn(["konnect", "customAppData"]).toJS(),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateSegmentIndex: data => dispatch(updateSegmentIndex(data)),
  }
}

export const TextSplitterDefault = connect(mapStateToProps, mapDispatchToProps)(TextSplitterDefault_)
