import React, { useState } from "react"
import { Box, Text, Flex, Link } from "rebass"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw"
import { Button, Input, Label } from "src/components/index"
import { Icon } from "src/components"
import Select from "react-select"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import Check from "../../assets/images/check.svg"
import EditIcon from "../../assets/images/Edit_icon.svg"
import Lock from "../../assets/images/Lock.svg"
import DeleteIcon from "../../assets/images/Delete_icon.svg"
export const UpdateTeamMemberModal = ({ users, userId, onUpdatingTeamMember }) => {
  const { t } = useTranslation()
  const user = users.find((t) => t.id === userId)
  
  const [firstName, setFirstName] = useState(user.first_name)
  const [lastName, setLastName] = useState(user.last_name)
  const [email, setEmail] = useState(user.email)
  const [access, setAccess] = useState({
    "value": user.access,
    "label": user.access
})
  const accessList = [
    {
        "value": "Create",
        "label": "Create"
    },
    {
        "value": "Create/Delete",
        "label": "Create/Delete"
    },
    {
        "value": "FULL",
        "label": "FULL"
    }
]

  return (
    <>
     <Flex flexDirection="column" sx={{  borderRadius: "16px", px: px2vw(60), padding: "0px" ,minWidth: "500px"}}>
      <Flex flexDirection="row" sx={{ borderRadius: "16px", px: px2vw(20), padding: "0px" }}>
          <Box sx={{ p: px2vw(8), justifyContent: "left", textAlign: "left" }}>
            {/* <img width="20px" height="20px" src={CreateFolderImg} /> */}
          </Box>
          <Text
            fontSize={[20]}
            fontWeight="semi-bold"
            color="app.primary.30"
            textAlign="left"
            sx={{ pt: 2.5, justifyContent: "left", textAlign: "left", mr: px2vw(3) }}
          >
           Edit Sub-Account
          </Text>
          
        </Flex>
        <Box sx={{ background: "#BCC6CC", width: "inherit", height: "1px", margin: "14px -20px" }}></Box>
        <Box sx={{ color: "#000000", p: px2vw(10) }}>


        <Flex>
            <Flex sx={{ width: "50%" }}>
              <Box>
                <img
                  style={{ borderRadius: "16px", border: "1px solid #B7CBFF" }}
                  width="140px"
                  height="140px"
                  // src={profile.image_url}
                />
              </Box>
              <Box sx={{ display: "grid", ml: "10px" }}>
                <EditIcon
                  style={{ cursor: "pointer", margin: "inherit" }}
                  data-tip={"Edit"}
                  // onClick={() => fileRef.current.click()}
                />
                <DeleteIcon
                  data-tip="Remove"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#ea4f4f",
                    borderRadius: "50%",
                    padding: "4px 7px",
                    height: "30px",
                    marginLeft: "15px",
                  }}
                  size="xl"
                  // onClick={removeImage}
                />
              </Box>
            </Flex>
            <Box sx={{ width: "50%" }}>
              {" "}
              <Input
            label={t("account.agency.firstName")}
      
            sxProps={{
              height: "40px !important",
              backgroundColor: "#E6EDFF",
              border: "1px solid #E6EDFF",
              borderRadius: "18px",
              color:"#152F73"
            }}
            type={"text"}
            value={firstName}
            defaultValue={user.first_name}
            onChange={e => {
              setFirstName(e.currentTarget.value)
            }}
            setError={Boolean(firstName === "")}
            errorMessage={"Field should not be empty!"}
          />
          <Input
            label={t("account.agency.lastName")}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#E6EDFF",
              border: "1px solid #E6EDFF",
              borderRadius: "18px",
              color:"#152F73"
            }}
            type={"text"}
            value={lastName}
            derfaultValue={user.last_name}
            onChange={e => {
              setLastName(e.currentTarget.value)
            }}
            setError={Boolean(lastName === "")}
            errorMessage={"Field should not be empty!"}
          />
            </Box>
          </Flex>
        
          <Input
            label={t("account.agency.email")}
            sxProps={{
              height: "40px !important",
              backgroundColor: "#E6EDFF",
              border: "1px solid #E6EDFF",
              borderRadius: "18px",
              color:"#152F73"
            }}
            type={"text"}
            value={email}
            defaultValue={user.email}
            onChange={e => {
              setEmail(e.currentTarget.value)
            }}
            setError={Boolean(email === "")}
            errorMessage={"Field should not be empty!"}
          />

<Label>Access</Label>
                    <Select 
                        options={accessList}
                        value={{
                            "value": user.access,
                            "label": user.access
                        }}
                        onChange={(item) => {
                            setAccess(item)
                        }}
                        menuShouldScrollIntoView={true}
                        menuPosition="fixed"
                    />
         
        </Box>
        <Box sx={{ p: px2vw(10), mt: 20 }}>
          <Flex flexDirection="row" sx={{ justifyContent: "center" }}>
            <Box>
         
              

                <Box    onClick={() => onUpdatingTeamMember(firstName, lastName, access, user.id, email)} sx={{ cursor: "pointer", m: "1px 0 0 10px",display:"flex",alignItems:"center",bg:"#F7C546",color:"#152F73",p:"3px 10px 3px 3px",borderRadius:"10px" }}><span><Check style={{margin:"10px 12px 5px 5px"}}/></span><span>Save Changes</span></Box>
            
             
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}
