/**
 * App config action to handle the global config api.
 */

import { makeRequestActionCreator } from 'utils/redux'
import { AUTH_LOGIN, AUTH_SIGN_UP, VERIFY_EMAIL, UPDATE_USER, GET_FORGOT_PASSWORD_LINK, RESET_PASSWORD, AUTH_USER_EXISTS } from 'config/Constants'

export const login = creds => dispatch => {
  const request = makeRequestActionCreator(AUTH_LOGIN)

  return dispatch(
    request({
      url: 'api/v1/auth/login',
      data: creds,
      method: 'POST'
    })
  )
}

export const signup = data => dispatch => {
  const request = makeRequestActionCreator(AUTH_SIGN_UP)

  return dispatch(
    request({
      url: 'api/v1/signup',
      data: data,
      method: 'POST'
    })
  )
}

export const userExists = data => dispatch => {
  const request = makeRequestActionCreator(AUTH_USER_EXISTS)

  return dispatch(
    request({
      url: 'api/v1/users/user_exists',
      data: data,
      method: 'POST'
    })
  )
}

export const verifyEmail = data => dispatch => {
  const request = makeRequestActionCreator(VERIFY_EMAIL)

  return dispatch(
    request({
      url: 'api/v1/users/email_confirmation',
      data: data,
      method: 'POST'
    })
  )
}

export const updateUser = (data, accessToken) => dispatch => {
  const request = makeRequestActionCreator(UPDATE_USER, accessToken)

  return dispatch(
    request({
      url: 'api/v1/users/update_user',
      data: data,
      method: 'POST'
    })
  )
}

export const getForgotPasswordLink = (data) => dispatch => {
  const request = makeRequestActionCreator(GET_FORGOT_PASSWORD_LINK)

  return dispatch(
    request({
      url: 'api/v1/users/reset_password_email',
      data: data,
      method: 'POST'
    })
  )
}

export const resetPassword = (data) => dispatch => {
  const request = makeRequestActionCreator(RESET_PASSWORD)

  return dispatch(
    request({
      url: 'api/v1/users/update_user_password',
      data: data,
      method: 'POST'
    })
  )
}
