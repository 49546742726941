// export default [
//     {"desc": "Abkhaz", "code": "ab"},
//     {"desc": "Afar", "code": "aa"},
//     {"desc": "Afrikaans", "code": "af"},
//     {"desc": "Akan", "code": "ak"},
//     {"desc": "Albanian", "code": "sq"},
//     {"desc": "Amharic", "code": "am"},
//     {"desc": "Arabic", "code": "ar"},
//     {"desc": "Aragonese", "code": "an"},
//     {"desc": "Armenian", "code": "hy"},
//     {"desc": "Assamese", "code": "as"},
//     {"desc": "Avaric", "code": "av"},
//     {"desc": "Avestan", "code": "ae"},
//     {"desc": "Aymara", "code": "ay"},
//     {"desc": "Azerbaijani", "code": "az"},
//     {"desc": "Bambara", "code": "bm"},
//     {"desc": "Bashkir", "code": "ba"},
//     {"desc": "Basque", "code": "eu"},
//     {"desc": "Belarusian", "code": "be"},
//     {"desc": "Bengali, Bangla", "code": "bn"},
//     {"desc": "Bihari", "code": "bh"},
//     {"desc": "Bislama", "code": "bi"},
//     {"desc": "Bosnian", "code": "bs"},
//     {"desc": "Breton", "code": "br"},
//     {"desc": "Bulgarian", "code": "bg"},
//     {"desc": "Burmese", "code": "my"},
//     {"desc": "Catalan", "code": "ca"},
//     {"desc": "Chamorro", "code": "ch"},
//     {"desc": "Chechen", "code": "ce"},
//     {"desc": "Chichewa, Chewa, Nyanja", "code": "ny"},
//     {"desc": "Chinese", "code": "zh"},
//     {"desc": "Chuvash", "code": "cv"},
//     {"desc": "Cornish", "code": "kw"},
//     {"desc": "Corsican", "code": "co"},
//     {"desc": "Cree", "code": "cr"},
//     {"desc": "Croatian", "code": "hr"},
//     {"desc": "Czech", "code": "cs"},
//     {"desc": "Danish", "code": "da"},
//     {"desc": "Divehi, Dhivehi, Maldivian", "code": "dv"},
//     {"desc": "Dutch", "code": "nl"},
//     {"desc": "Dzongkha", "code": "dz"},
//     {"desc": "English", "code": "en"},
//     {"desc": "Esperanto", "code": "eo"},
//     {"desc": "Estonian", "code": "et"},
//     {"desc": "Ewe", "code": "ee"},
//     {"desc": "Faroese", "code": "fo"},
//     {"desc": "Fijian", "code": "fj"},
//     {"desc": "Finnish", "code": "fi"},
//     {"desc": "French", "code": "fr"},
//     {"desc": "Fula, Fulah, Pulaar, Pular", "code": "ff"},
//     {"desc": "Galician", "code": "gl"},
//     {"desc": "Ganda", "code": "lg"},
//     {"desc": "Georgian", "code": "ka"},
//     {"desc": "German", "code": "de"},
//     {"desc": "Greek (modern)", "code": "el"},
//     {"desc": "Guaraní", "code": "gn"},
//     {"desc": "Gujarati", "code": "gu"},
//     {"desc": "Haitian, Haitian Creole", "code": "ht"},
//     {"desc": "Hausa", "code": "ha"},
//     {"desc": "Hebrew (modern)", "code": "he"},
//     {"desc": "Herero", "code": "hz"},
//     {"desc": "Hindi", "code": "hi"},
//     {"desc": "Hiri Motu", "code": "ho"},
//     {"desc": "Hungarian", "code": "hu"},
//     {"desc": "Icelandic", "code": "is"},
//     {"desc": "Ido", "code": "io"},
//     {"desc": "Igbo", "code": "ig"},
//     {"desc": "Indonesian", "code": "id"},
//     {"desc": "Interlingua", "code": "ia"},
//     {"desc": "Interlingue", "code": "ie"},
//     {"desc": "Inuktitut", "code": "iu"},
//     {"desc": "Inupiaq", "code": "ik"},
//     {"desc": "Irish", "code": "ga"},
//     {"desc": "Italian", "code": "it"},
//     {"desc": "Japanese", "code": "ja"},
//     {"desc": "Javanese", "code": "jv"},
//     {"desc": "Kalaallisut, Greenlandic", "code": "kl"},
//     {"desc": "Kannada", "code": "kn"},
//     {"desc": "Kanuri", "code": "kr"},
//     {"desc": "Kashmiri", "code": "ks"},
//     {"desc": "Kazakh", "code": "kk"},
//     {"desc": "Khmer", "code": "km"},
//     {"desc": "Kikuyu, Gikuyu", "code": "ki"},
//     {"desc": "Kinyarwanda", "code": "rw"},
//     {"desc": "Kirundi", "code": "rn"},
//     {"desc": "Komi", "code": "kv"},
//     {"desc": "Kongo", "code": "kg"},
//     {"desc": "Korean", "code": "ko"},
//     {"desc": "Kurdish", "code": "ku"},
//     {"desc": "Kwanyama, Kuanyama", "code": "kj"},
//     {"desc": "Kyrgyz", "code": "ky"},
//     {"desc": "Lao", "code": "lo"},
//     {"desc": "Latin", "code": "la"},
//     {"desc": "Latvian", "code": "lv"},
//     {"desc": "Limburgish, Limburgan, Limburger", "code": "li"},
//     {"desc": "Lingala", "code": "ln"},
//     {"desc": "Lithuanian", "code": "lt"},
//     {"desc": "Luba-Katanga", "code": "lu"},
//     {"desc": "Luxembourgish, Letzeburgesch", "code": "lb"},
//     {"desc": "Macedonian", "code": "mk"},
//     {"desc": "Malagasy", "code": "mg"},
//     {"desc": "Malay", "code": "ms"},
//     {"desc": "Malayalam", "code": "ml"},
//     {"desc": "Maltese", "code": "mt"},
//     {"desc": "Manx", "code": "gv"},
//     {"desc": "Marathi (Marāṭhī)", "code": "mr"},
//     {"desc": "Marshallese", "code": "mh"},
//     {"desc": "Mongolian", "code": "mn"},
//     {"desc": "Montenegrin", "code": ""},
//     {"desc": "Māori", "code": "mi"},
//     {"desc": "Nauruan", "code": "na"},
//     {"desc": "Navajo, Navaho", "code": "nv"},
//     {"desc": "Ndonga", "code": "ng"},
//     {"desc": "Nepali", "code": "ne"},
//     {"desc": "Northern Ndebele", "code": "nd"},
//     {"desc": "Northern Sami", "code": "se"},
//     {"desc": "Norwegian", "code": "no"},
//     {"desc": "Norwegian Bokmål", "code": "nb"},
//     {"desc": "Norwegian Nynorsk", "code": "nn"},
//     {"desc": "Nuosu", "code": "ii"},
//     {"desc": "Occitan", "code": "oc"},
//     {"desc": "Ojibwe, Ojibwa", "code": "oj"},
//     {"desc": "Old Church Slavonic, Church Slavonic,Old Bulgarian", "code": "cu"},
//     {"desc": "Oriya", "code": "or"},
//     {"desc": "Oromo", "code": "om"},
//     {"desc": "Ossetian, Ossetic", "code": "os"},
//     {"desc": "Panjabi, Punjabi", "code": "pa"},
//     {"desc": "Pashto, Pushto", "code": "ps"},
//     {"desc": "Persian (Farsi)", "code": "fa"},
//     {"desc": "Polish", "code": "pl"},
//     {"desc": "Portuguese", "code": "pt"},
//     {"desc": "Pāli", "code": "pi"},
//     {"desc": "Quechua", "code": "qu"},
//     {"desc": "Romanian", "code": "ro"},
//     {"desc": "Romansh", "code": "rm"},
//     {"desc": "Russian", "code": "ru"},
//     {"desc": "Samoan", "code": "sm"},
//     {"desc": "Sango", "code": "sg"},
//     {"desc": "Sanskrit (Saṁskṛta)", "code": "sa"},
//     {"desc": "Sardinian", "code": "sc"},
//     {"desc": "Scottish Gaelic, Gaelic", "code": "gd"},
//     {"desc": "Serbian", "code": "sr"},
//     {"desc": "Shona", "code": "sn"},
//     {"desc": "Sindhi", "code": "sd"},
//     {"desc": "Sinhala, Sinhalese", "code": "si"},
//     {"desc": "Slovak", "code": "sk"},
//     {"desc": "Slovene", "code": "sl"},
//     {"desc": "Somali", "code": "so"},
//     {"desc": "Southern Ndebele", "code": "nr"},
//     {"desc": "Southern Sotho", "code": "st"},
//     {"desc": "Spanish", "code": "es"},
//     {"desc": "Sundanese", "code": "su"},
//     {"desc": "Swahili", "code": "sw"},
//     {"desc": "Swati", "code": "ss"},
//     {"desc": "Swedish", "code": "sv"},
//     {"desc": "Tagalog", "code": "tl"},
//     {"desc": "Tahitian", "code": "ty"},
//     {"desc": "Tajik", "code": "tg"},
//     {"desc": "Tamil", "code": "ta"},
//     {"desc": "Tatar", "code": "tt"},
//     {"desc": "Telugu", "code": "te"},
//     {"desc": "Thai", "code": "th"},
//     {"desc": "Tibetan Standard, Tibetan, Central", "code": "bo"},
//     {"desc": "Tigrinya", "code": "ti"},
//     {"desc": "Tonga (Tonga Islands)", "code": "to"},
//     {"desc": "Tsonga", "code": "ts"},
//     {"desc": "Tswana", "code": "tn"},
//     {"desc": "Turkish", "code": "tr"},
//     {"desc": "Turkmen", "code": "tk"},
//     {"desc": "Twi", "code": "tw"},
//     {"desc": "Ukrainian", "code": "uk"},
//     {"desc": "Urdu", "code": "ur"},
//     {"desc": "Uyghur", "code": "ug"},
//     {"desc": "Uzbek", "code": "uz"},
//     {"desc": "Venda", "code": "ve"},
//     {"desc": "Vietnamese", "code": "vi"},
//     {"desc": "Volapük", "code": "vo"},
//     {"desc": "Walloon", "code": "wa"},
//     {"desc": "Welsh", "code": "cy"},
//     {"desc": "Western Frisian", "code": "fy"},
//     {"desc": "Wolof", "code": "wo"},
//     {"desc": "Xhosa", "code": "xh"},
//     {"desc": "Yiddish", "code": "yi"},
//     {"desc": "Yoruba", "code": "yo"},
//     {"desc": "Zhuang, Chuang", "code": "za"},
//     {"desc": "Zulu", "code": "zu"},
// ]

export default [
  { "label": "English", "value": "en" },
  { "label": "Spanish", "value": "es" },
  { "label": "French", "value": "fr" },
  { "label": "Chinese", "value": "chinese" },
  { "label": "Dutch", "value": "dutch" },
  { "label": "German", "value": "german" },

  // { "name": "Arabic", "value": "arabic" }
]