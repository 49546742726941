import React, { memo, useState, useEffect } from "react"
import { Handle } from "react-flow-renderer"
import { useTranslation } from "react-i18next"
import { Dropdown } from "src/components/Konnect/DropdownMenu"
import { Modal } from "react-responsive-modal"
import { Preloader } from "src/components"
import { CommonOuter } from "../NodeInner/CommonOuter"

import { AdditionalConfigsModal } from "../../../../../components/Konnect/Base/Components/NodeInner/modals/additionalConfigsModal" //./modals/additionalConfigsModal
import { Box, Text, Flex, Link } from "rebass"

import {
  NodeOuter,
  AppInfo,
  EventSelectionGroup,
  SelectionHeader,
  NodeButtonOutline,
  NodeInfoHelper,
  ConnectorOuter,
  ConnectorPortOuter,
  ConnectorPortInput,
  ConnectorPortText,
  ConnectorPortLabel,
  AddMoreFieldButton,
  TestAndReviewButton,
  CouponWrapper,
  CouponDayWrapper,
} from "../../konnect/UI"
import { useData } from "../../../Context/canvasContext"
import { useSelector, useDispatch } from "react-redux"
import {
  onDeleteNode,
  setConfigMenuState,
  onPortTextChange,
  updateDuration,
  updateExpiryNum,
  addFieldstoconfig,createAdditionalFields
} from "../../../store/slice/canvasSlice"
import {
  onDeleteNodeV2,
  getAppEventConfig,
  getAppEventConfigDetail,
  getAppEventConfigDetailFetch,
  appTestAndReview,
} from "../../../features/index"
export const CoupanGenerator = memo(({ data, isConnectable }) => {
  const dispatch = useDispatch()

  const { editorState, customAppData } = useSelector(state => state.canvas.canvas)
  const { nodes } = editorState
  const [IsDeleteShown, setIsDeleteShown] = useState(false)
  const [searchEvents, setSearchEvents] = useState("")
  const [openAdditionalConfigs, setOpenAdditionalConfigs] = useState(false)
  const [addedKeys, setAddedKeys] = useState([])
  const [searchDurations, setSearchDurations] = useState("")
  const [addit, setAddit] = useState([])

  const [nodeReviewed, setNodeReviewed] = useState(data.tested ? data.reviewed : null)
  const [nodeTested, setNodeTested] = useState(data.tested ? data.tested : null)

  const { t } = useTranslation()
  const nodeIdx = nodes.findIndex(x => x.data.nodeid === data.nodeid)

  let { expiryNum, duration } = data.appDetails.coupon

  const onCloseModal = list => {
    setOpenConditions(false)
  }
  const handleConditionsModal = event => {
    setOpenConditions(true)
  }

  const handleAdditionalConfigsClick = () => {
    setOpenAdditionalConfigs(true)
  }
  let formResponseElements = []

  // const createAdditionalFields = props => {
  //   const { fields } = props
  //   const newFeilds = fields
  //   dispatch(addFieldstoconfig({ data, newFeilds }))
  //   // data.configResponses = data.configResponses.concat(newFeilds)
  //   setAddit(newFeilds)
  // }

  const onCloseAdditionalConfigsModal = list => {
    setOpenAdditionalConfigs(false)
    
    const selected = list.filter(x => x.selected)
    setAddedKeys(selected)
    dispatch(createAdditionalFields({ fields: selected, nodeId: data.nodeid, data }))
  }

  const onDropDownChange = (list, val) => {
    let list1 = JSON.parse(JSON.stringify(list))
    list1.forEach(obj => {
      if (obj.id === val.id) {
        obj.selected = true
      } else {
        obj.selected = false
      }
    })
    dispatch(updateDuration({ list1, data }))
  }
  const OnChangeDate = e => {
    dispatch(updateExpiryNum({ value: e.target.value, data }))
  }

  if (data.configResponses) {
    data.configResponses.map((x, idx) => {
      const portInfo = {
        node: data.nodeid,
        app: data.id,
        appEvent: data.appEvent,
        port: x.id,
        label: x.label,
        id: x.id,
        config_key: x.config_key,
      }
      let ConnectorPort = x.key_value_type === "text" ? ConnectorPortText : ConnectorPortInput
      let k = idx.toString() //id for handle bcoz id to be passed in form of string
      const __key = x.config_key
        .replace(/[^a-zA-Z ]/g, "")
        .split(" ")
        .join("_")

      formResponseElements.push(
        <div key={idx}>
          <ConnectorPortLabel>{x.label}</ConnectorPortLabel>
          <Flex>
            <ConnectorPortOuter placement="left" type={x.key_value_type}>
              <Handle
                type="target"
                position="left"
                isConnectable="true"
                style={{
                  height: "20px",
                  background: "#152f73",
                  width: "20px",
                  borderRadius: "60%",
                  border: "1px solid #152f73",
                }}
                id={x.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "target" })}
              />
            </ConnectorPortOuter>
            <ConnectorPort
              rows={4}
              dirty={x.value ? false : !!x.dirty}
              key={`${x.app_id}_${__key}`}
              onMouseDown={e => {
                e.stopPropagation()
              }}
              defaultValue={x.value ? x.value : ""}
              placeholder={!x.dirty ? x.label : `${x.label}*`}
              onChange={e => {
                dispatch(
                  onPortTextChange({
                    data,
                    nodeId: data.nodeid,
                    portId: x.id,
                    config_key: x.config_key,
                    input: e.target.value,
                  })
                )
              }}
              additional={x.additional}
            ></ConnectorPort>
            <ConnectorPortOuter placement="left" type={x.key_value_type}>
              <Handle
                type="source"
                position="right"
                isConnectable="true"
                style={{
                  height: "20px",
                  background: "#152f73",
                  width: "20px",
                  borderRadius: "60%",
                  border: "1px solid #152f73",
                }}
                id={x.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "source" })}
              />
            </ConnectorPortOuter>
          </Flex>
        </div>
      )
    })
  }
  // let additional=data.configResponses?.filter()
  // if (addit.length > 0) {
  //   addit.map((q, idx) => {
  //     console.log(q,"coupan")
  //     let ConnectorPort = q.key_value_type === "text" ? ConnectorPortText : ConnectorPortInput

  //     // let x = response[q]
  //     const portInfo = {
  //       node: data.nodeid,
  //       app: data.id,
  //       appEvent: data.appEvent,
  //       port: q.id,
  //       label: q.label,
  //       id: q.id,
  //       config_key: q.id,
  //     }
  //     formResponseElements.push(
  //       <div key={idx}>
  //         <ConnectorPortLabel>{q.label}</ConnectorPortLabel>
  //         <Flex>
  //           <ConnectorPort
  //             style={{ backgroundColor: "#e7eeff" }}
  //             rows={4}
  //             dirty={q.value ? false : !!q.dirty}
  //             onMouseDown={e => {
  //               e.stopPropagation()
  //             }}
  //             defaultValue={q.value ? q.value : ""}
  //             placeholder={!q.dirty ? q.label : `${q.label}*`}
  //           ></ConnectorPort>
  //           <ConnectorPortOuter placement="left" type={q.key_value_type}>
  //             <Handle
  //               type="source"
  //               position="right"
  //               isConnectable="true"
  //               style={{
  //                 height: "20px",
  //                 background: "#152f73",
  //                 width: "20px",
  //                 borderRadius: "60%",
  //                 border: "1px solid #152f73",
  //               }}
  //               id={q.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "source" })}
  //             />
  //           </ConnectorPortOuter>
  //         </Flex>
  //       </div>
  //     )
  //   })
  // }
  useEffect(() => {
    setNodeTested(data.tested ? data.tested : null)
    setNodeReviewed(data.tested ? data.reviewed : null)
  }, [{ ...data }])
  return (
    <>
      <CommonOuter node={data} reviewed={nodeReviewed} nodeTested={nodeTested} />

      <NodeOuter
        onMouseEnter={() => setIsDeleteShown(true)}
        onMouseLeave={() => setIsDeleteShown(false)}
        isFirst={!nodeIdx}
        reviewed={nodeReviewed}
      >
        <AppInfo
          title={data.name}
          subtitle={data.account}
          image={data.image}
          className="DraggableHandle"
          background={data.background}
          hasDelete={IsDeleteShown && (nodeIdx !== 0 || nodes.length === 1)}
          onDelete={() => {
            dispatch(onDeleteNode(data))
          }}
        ></AppInfo>
        <>
          <EventSelectionGroup>
            <SelectionHeader>
              {data.appEvents && nodeIdx !== 0
                ? t("konnect.sidebar.choose_action_event")
                : t("konnect.sidebar.choose_trigger")}
            </SelectionHeader>
            <Dropdown
              searchable={true}
              searchValue={searchEvents}
              onFilterChange={text => {
                setSearchEvents(text)
              }}
              inputPlaceholder={!searchEvents ? "Search field" : ""}
              disableIfEmpty
              headerTitle={t("konnect.sidebar.select_trigger_action_dropdown_header")}
              items={data.appEvents}
              renderKey="name"
              selected={data.appEvent ? data.appEvents.find(x => x.id === data.appEvent).name : null}
              onChange={selected => {
                dispatch(
                  getAppEventConfig({
                    data,
                    nodeId: data.nodeid,
                    appId: data.id,
                    eventId: selected.id,
                    source: nodeIdx === 0,
                    type: selected.side === "left" ? "source" : selected.side === "right" ? "target" : "both",
                  })
                )
                // setEvent(false)
                setSearchEvents("")
              }}
            />
          </EventSelectionGroup>

          <EventSelectionGroup>
            <>
              <SelectionHeader></SelectionHeader>
              <div>
                {data.isloading ? (
                  <div style={{ display: "flex" }}>
                    <Preloader loading={data.isloading} position="static" />
                  </div>
                ) : (
                  <TestAndReviewButton
                    onClick={() => {
                      dispatch(
                        appTestAndReview({
                          data,
                          nodeIdx: nodeIdx,
                          editorState: editorState,
                          appDetails: data?.appDetails,
                          dispatch,
                        })
                      )
                    }}
                  >
                    Test & Review
                  </TestAndReviewButton>
                )}
              </div>

              {formResponseElements.length > 0 ? (
                <EventSelectionGroup>
                  <SelectionHeader>{t("konnect.sidebar.expiry_val")}</SelectionHeader>
                  <CouponWrapper>
                    <CouponDayWrapper>
                      <ConnectorPortInput
                        name="key"
                        placeholder="Number"
                        value={expiryNum}
                        onMouseDown={e => {
                          e.stopPropagation()
                        }}
                        onChange={e => OnChangeDate(e)}
                      />
                    </CouponDayWrapper>
                    <Dropdown
                      searchable={true}
                      searchValue={searchDurations}
                      onFilterChange={text => {
                        setSearchDurations(text)
                      }}
                      inputPlaceholder={!searchDurations ? "Search field" : ""}
                      disableIfEmpty
                      items={duration}
                      renderKey="name"
                      selected={duration.find(x => x.selected === true).name}
                      onChange={selected => {
                        onDropDownChange(duration, selected)
                        setSearchEvents("")
                      }}
                      onChangeState={isOpen => {
                        dispatch(setConfigMenuState({ isOpen }))
                      }}
                    />
                  </CouponWrapper>
                </EventSelectionGroup>
              ) : null}

              <SelectionHeader />
              {formResponseElements.map(form => form)}
            </>
          </EventSelectionGroup>
        </>
      </NodeOuter>
      {data.additionalResponses && data.additionalResponses.length ? (
        <AddMoreFieldButton onClick={handleAdditionalConfigsClick}></AddMoreFieldButton>
      ) : null}
      <Modal
        open={openAdditionalConfigs}
        onClose={onCloseAdditionalConfigsModal}
        onAnimationEnd={() => scrollTo(0, 0)}
        center
        styles={{ modal: { padding: "0px", borderRadius: "16px", minWidth: "1100px", minHeight: "700px" } }}
      >
        <AdditionalConfigsModal
          closeModal={onCloseAdditionalConfigsModal}
          addedKeys={addedKeys}
          data={data.additionalResponses}
          appIcon={data.image}
        />
      </Modal>
    </>
  )
})
