/**
 * Main entry point into the APp
 * Contains the App config for Redux, Theme & Routes
 */

import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import store from "store"
import { RenderRoutes } from "routes"
import "assets/styles/global"
import { AuthProvider } from "src/contexts/AuthContext"
import { ThemeProvider } from "emotion-theming"
import { theme } from "src/theme/theme"
import 'react-toastify/dist/ReactToastify.css';
import "utils/i18next"
import 'react-responsive-modal/styles.css';
const wrapper = document.getElementById("app")
wrapper
  ? ReactDOM.render(
    
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <RenderRoutes />
        </AuthProvider>
      </ThemeProvider>
    </Provider>,
    wrapper
  )
  : false
