export const getLinkPosition = (node, port, type) => {
  try {
    return {
      x: node.position.x + (port[type].position && port[type].position ? port[type].position.x : 0),
      y: node.position.y + (port[type].position && port[type].position ? port[type].position.y : 0),
    }
  } catch (err) {
    console.log(err, node, port, type)
  }
}
