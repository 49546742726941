import React from "react"
import { Box, Text, Flex, Link } from "rebass"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw"
import DeleteIconSvg from "src/assets/images/deleteIcon"
import { Button } from "src/components/index"

export const DeleteModal = ({ closeModal, onDeleteClick }) => {
  const { t } = useTranslation()
  return (
    <>
      <Flex flexDirection="column" sx={{ borderRadius: "16px", px: px2vw(60), padding: "0px" }}>
        <Box sx={{ p: px2vw(20), justifyContent: "center", textAlign: "center" }}>
          <DeleteIconSvg />
        </Box>
        <Box sx={{ color: "#000000", p: px2vw(10), margin: "0px -20px"}}>
          <Text variant="title" fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}>
            {t("konnectzList.deleteModalBodyText")}
          </Text>
        </Box>
        <Box sx={{ p: px2vw(10) }}>
          <Flex flexDirection="row" sx={{ justifyContent: "space-between" }}>
            <Box>
              <Button
                variant="primary"
                fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                sx={{ fontWeight: "normal", px: px2vw(20), borderRadius: "0px" }}
                onClick={() => closeModal()}
              >
                {t("konnectzList.deleteModalNoText")}
              </Button>
            </Box>
            <Box sx={{ textAlign: "center", justifyContent: "center" }}>
              <Button
                fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                sx={{
                  background: "none",
                  boxShadow: "none",
                  fontWeight: "normal",
                  cursor: "pointer",
                  color: "app.secondary.90",
                  "&:hover": {
                    background: "none",
                    color: "app.secondary.90",
                  },
                }}
                onClick={() => onDeleteClick("DELETED")}
              >
                {t("konnectzList.deleteModalDeleteText")}
              </Button>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}