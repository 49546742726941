import React, { useState, useRef, useEffect } from "react"
import styled from "@emotion/styled"
// import { size } from "./dimensions"
// import { colors } from "src/theme/colors"
import px2vw from "utils/px2vw"
import { Box, Flex } from "rebass"
import { faArrowLeft, faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons"
import { DecoratorIcon, Text } from "src/components"
import { size } from "../dimensions"
// import { Input } from "src/components/Input/Input"
import { Label, Input } from "@rebass/forms"
import ReactTooltip from "react-tooltip"

const DropdownOuter = styled.div`
  position: relative;
  width: 100%;
  user-select: none;
  background: #f3f7ff;
  border-radius: ${size.editor.node.selectors.dropdown.borderRadius};
  margin-top: ${props => (props.index ? size.editor.node.selectors.dropdown.marginBottom : 0)};
`

const DropdownButton = styled.div`
  display: flex;
  height: ${size.editor.node.selectors.dropdown.buttonHeight};
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
  padding: ${size.editor.node.selectors.dropdown.padding};
`

const DropdownHeader = styled.div`
  font-weight: 500;
  font-size: ${size.editor.node.selectors.dropdown.fontSize};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const DropdownListWrapper = styled.div`
  position: absolute;
  z-index: 100;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  text-align: left;
  padding-bottom: 16px;
  max-height: 200px;
  overflow-y: auto;
`

const DropdownListItems = styled.button`
  overflow: hidden;
  display: inline-block;
  width: 100%;
  height: ${px2vw(40)};
  padding-left: ${px2vw(24)};
  padding-right: ${px2vw(24)};
  padding-top: ${px2vw(10)};
  padding-bottom: ${px2vw(10)};
  background: #ffffff;
  border: 0px;
  font-size: ${size.editor.node.selectors.dropdown.fontSize};
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    background: #f3f7ff;
    color: #152f73;
  }
  text-align: left;
`
export const Dropdown = ({ ...props }) => {
  const {
    headerTitle = "",
    items = [],
    onChange,
    renderKey = null,
    selected = null,
    onOpen = null,
    index = 0,
    disableIfEmpty = false,
    onChangeState = null,
    searchable = false,
    addAccount=false,
    searchValue = "",
    onFilterChange = null,
    inputPlaceholder = "",
    _condition = {},
  } = props
  const [ListOpen, setListOpen] = useState(false)
  const wrapperRef = useRef(null)
  const inputRef = useRef(null)

  const handleClick = e => {
    if (wrapperRef.current.contains(e.target)) {
      return
    }
    if (onChangeState) {
      onChangeState(false)
    }

    setListOpen(false)
  }

  const handleEnter = e => {
    if (onChangeState) {
      onChangeState(false)
    }
    onChange(e.target.value, _condition)
    setListOpen(false)
  }

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
  const filteredItems = searchable
    ? items.filter(x => x.name ? x.name.toLowerCase().search(escapeRegExp(searchValue).toLowerCase()) !== -1 : x.label.toLowerCase().search(escapeRegExp(searchValue).toLowerCase()) !== -1)
    : items
  return (
    <DropdownOuter className="dropdown-outer" index={index} ref={wrapperRef}>
      <DropdownButton
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          if (disableIfEmpty && !items.length) {
            return false
          }
          setListOpen(!ListOpen)
          if (onOpen) {
            onOpen(!ListOpen)
          }
          if (onChangeState) {
            onChangeState(!ListOpen)
          }
        }}
      >
        <DropdownHeader>{selected ? selected : headerTitle}</DropdownHeader>
        <DecoratorIcon small icon={ListOpen ? faAngleUp : faAngleDown} />
      </DropdownButton>
      {ListOpen && (
        <DropdownListWrapper className="dropdown-menu">
          <>
            {searchable ? (
              // <Input
              //   style={{ height: "40px", borderTopRightRadius: "24px", borderTopLeftRadius: "24px" }}
              //   value={searchValue}
              //   onChange={event => {
              //     const value = event.currentTarget.value
              //     onFilterChange && onFilterChange(value)
              //   }}
              //   hideAdditional
              //   placeholder={inputPlaceholder}
              //   ref={inputRef}
              // />
              <Input
                style={{
                  height: "40px",
                  borderRadius: "5px",
                  width: "98%",
                  margin: "1%",
                  padding: "10px",
                  fontSize: "16px",
                }}
                value={searchValue}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    // code for enter
                    handleEnter(e)
                  }
                }}
                onChange={event => {
                  const value = event.currentTarget.value
                  onFilterChange && onFilterChange(value)
                }}
                placeholder={inputPlaceholder}
              />
            ) : (
              <></>
            )}
            {filteredItems.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                <DropdownListItems
                  data-tip={item.name}
                  data-for={item.id.toString()}
                  onClick={e => {
                    setListOpen(!ListOpen)
                    onChange(item)
                    onChangeState(false)
                  }}
                >
                  {renderKey ? item[renderKey] : item.name}
                </DropdownListItems>
                <ReactTooltip id={item.id.toString()} effect="solid" place="top"></ReactTooltip>
                </React.Fragment>
              )
            })}
            {addAccount ?
              <Flex sx ={{border:"1.5px solid #152f73",borderRadius:"5px" ,padding:"5px" ,width:"94%",margin:"10px",justifyContent:"center",color:"#152f73"}}
               onClick={() => {
            // setSelectedApp(true)
            // setTokenModal(true)
          }
          }
          >+ Add Account</Flex>:null}
          </> 
        </DropdownListWrapper>
      )}
    </DropdownOuter>
  )
}
