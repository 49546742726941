import React, { useState, useEffect } from "react"
import { BackButton, HeaderBackButton, HeaderInput, KonnectWrapper, Konnectheader } from "src/components/Konnect/UI"
import { Flex, Text, Box, Button } from "rebass"
import px2vw from "utils/px2vw"
import { Modal } from "react-responsive-modal"
import { Icon, Input } from "src/components"
import { useData } from "../Context/canvasContext"
import { useHistory } from "react-router-dom"
import { IconWrapper, SearchWrapper } from "../Component/konnect/UI" //"../../components/Konnect/UI"
import { faArrowLeft, faUndo, faRedo, faCompress, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import { undo } from "../store/slice/canvasSlice"
import { useDispatch } from "react-redux"
import { saveKonnect } from "../features/index"
const KonnectHeader = ({ setOpenModal, openModal }) => {
  const { inValue, setInValue, REDO, nodes, setKonnectTitle, konnectTitle,getKonnectsList} = useData()
  const [saved, setSaved] = useState(false)
  const history1 = useHistory()
  const konnectIdPresent = nodes.length > 0 ? nodes[0].data.konnect_id : null
  const canPublish = nodes.length > 0 ? inValue.editorMeta.canPublish : false
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const actions = [
    {
      icon: faUndo,
      clickFunc: dispatch(undo()),
    },
    {
      icon: faRedo,
      clickFunc: REDO,
    },
    {
      disabled: !konnectIdPresent,
      label: t("konnect.header.saveBtnLabel"),
      clickFunc: () => onSaveKonnect,
    },
    {
      disabled: !canPublish,
      label: t("konnect.header.publishBtnLabel"),
      clickFunc: onPublishKonnect,
    },
  ]

  const showSaveModal = (message, res) => {
    setOpenModal(true)
  }
  const onCloseModal = list => {
    setOpenModal(false)
  }

  const searchKonnect = e => {
    let x = e.target.value
    setKonnectTitle(e.target.value)
    setInValue(inValue => ({
      ...inValue,
      editorState: { ...inValue.editorState, konnect_name: x },
    }))
  }
  const onSaveKonnect = () => {
    setTimeout(() => {
      dispatch(saveKonnect({...inValue.editorState,publish:false}))
    }, 3000)
    setSaved(true)
    console.log("hiiasd")
    showSaveModal(t("konnect.publishSuccessMsg"))
  }
  const onPublishKonnect = () => {
    dispatch(saveKonnect({...inValue.editorState,publish:false}))

console.log("hii")
    showSaveModal(t("konnect.publishSuccessMsg"))
  }

  return (
    <>
      <Konnectheader height="10%" width="100%" sx={{ boxShadow: "0px 0px 5px 2px rgb(214,214,214, 0.6)", zIndex: 3 }}>
        <HeaderBackButton   onClick={() => {
            dispatch(clearState())
            history1.back()
          }}>
          <IconWrapper icon={faArrowLeft} />
          <Text>{t("konnect.header.backBtnLabel")}</Text>
        </HeaderBackButton>
        <Flex sx={{ justifyContent: "space-between", gap: px2vw(20), alignItems: "center" }}>
          <HeaderInput
            id="konnectnameInput"
            width="368px"
            value={konnectTitle}
            onChange={e => searchKonnect(e)}
            placeholder={t("konnect.header.nameOfKonnectz")}
          />
          {actions.map(action => {
            if (action.icon) {
              return (
                <Flex onClick={() => action.clickFunc()} sx={{ cursor: "pointer", p: px2vw(5) }}>
                  <IconWrapper icon={action.icon} />
                </Flex>
              )
            }
            return (
              <Button
                disabled={action.disabled}
                onClick={action.clickFunc}
                sx={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  height: "100%",
                  cursor: action.disabled ? "not-allowed" : "pointer",
                  backgroundColor: action.disabled ? "#7e7e7e" : "#f7c545",
                  "&:hover": {
                    backgroundColor: "#152f73",
                    color: "#f7c545",
                  },
                  p: "8px 20px 8px 20px",
                  color: "#000",
                  border: "none",
                  borderRadius: "8px",
                  alignSelf: "center",
                }}
              >
                {action.label}
              </Button>
            )
          })}
        </Flex>
      </Konnectheader>
      <Modal
        open={openModal}
        onClose={onCloseModal}
        onAnimationEnd={() => scrollTo(0, 0)}
        center
        styles={{ modal: { padding: "20px", borderRadius: "16px" } }}
      >
        <Flex flexDirection="column" sx={{ borderRadius: "16px", px: px2vw(60), padding: "0px" }}>
          <Box sx={{ color: "#000000", p: px2vw(10), m: "5" }}>
            <Text variant="title" fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}>
              {saved ? "Konnect is successfully saved" : "Konnect is successfully published"}
            </Text>
          </Box>
          <Box sx={{ p: px2vw(10) }}>
            <Flex flexDirection="row" sx={{ justifyContent: "center", marginTop: "10px" }}>
              <Box>
                <Button
                  variant="primary"
                  fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                  sx={{ fontWeight: "normal", px: px2vw(20), borderRadius: "0px" }}
                  onClick={() => {
                    history1.push("/Konnectz")
                    getKonnectsList(
                    { page_size: 40,page_index:1} 
                    )
                  }}
                >
                  OK
                </Button>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Modal>
    </>
  )
}
export default KonnectHeader
