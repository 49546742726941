import React, { useState } from "react"
import DnDFlow from "./DndFlow"
import { CanvasContextProvider } from "./Context/canvasContext"
import { KonnectCanvas } from "./index"

const ReactFlow = () => {
  return (
    <CanvasContextProvider>
      {/* <DnDFlow /> */}
      <KonnectCanvas />
    </CanvasContextProvider>
  )
}
export default ReactFlow
