import React, { useEffect, useState } from "react"

const GetAppType = props => {
  const { droppedElement: Data, nodes } = props
  switch (Data.type.toLowerCase()) {
    case "webhook":
      return "WebHookInner"

    case "api":
      return "NodeInner"
    case "add_on":
      {
        if (
          Data.provider.toLowerCase() === "textformatter" ||
          Data.provider.toLowerCase() === "numberformatter" ||
          Data.provider.toLowerCase() === "dateformatter"
        ) {
          return "addOnInner"
        }
        if (Data.provider.toLowerCase() === "generator") {
          return "coupan"
        }
        if (Data.provider.toLowerCase() === "conditioner") {
          return "conditions"
        }
        if (Data.provider.toLowerCase() === "api") {
          return "apiInner"
        }
        if (Data.provider.toLowerCase() === "scheduler") {
          return "scheduler"
        }
        if (Data.provider.toLowerCase() === "textsplitter") {
          return "textSplitter"
        }
        //  TextSplitter
        else return "addOnInner"
      }
      break

    default:
      return nodes.length > 0 && Data.type.toLowerCase() === "webhook_api" ? "NodeInner" : "WebHookInner"
    // return update &&nodes.length>0 && Data.type.toLowerCase() === "webhook_api" ? "rightHandNode" : "webhookNode"
  }
}

export default GetAppType
