import React, { memo, useState, useMemo, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Dropdown } from "../../../../../components/Konnect/DropdownMenu"

import { Handle } from "react-flow-renderer"
import "./style.css"
import {
  NodeOuter,
  AppInfo,
  EventSelectionGroup,
  SelectionHeader,
  ConnectorPortLabel,
  ConnectorPortOuter,
  TestAndReviewButton,
} from "../UI"
import { ConnectorPortMentions } from "../../../../../components/Konnect/UI"
import { Box, Flex } from "rebass"
import { CommonOuter } from "../NodeInner/CommonOuter"
import { useSelector, useDispatch } from "react-redux"
import {
  onDeleteNodeV2,
  getAppEventConfig,
  getAppEventConfigDetail,
  getAppEventConfigDetailFetch,
  appTestAndReview,
} from "../../../features/index"
import {
  onDeleteNode,
  setConfigMenuState,
  setEventsConfig,
  onPortTextChange,
  enableTestReviewLoader,
} from "../../../store/slice/canvasSlice"
export const AddOnInner = memo(({ data }) => {
  const dispatch = useDispatch()
  const [event, setEvent] = useState(!!data.appEvent)
  const [IsDeleteShown, setIsDeleteShown] = useState(false)
  const { t } = useTranslation()

  const [searchAccounts, setSearchAccounts] = useState("")

  const [searchEventConfigurations, setSearchEventConfigurations] = useState("")
  const [searchEvents, setSearchEvents] = useState("")
  const [nodeTested, setNodeTested] = useState(data.tested ? data.tested : null)
  const [nodeReviewed, setNodeReviewed] = useState(data.tested ? data.reviewed : null)
  const [eventSequence, setEventSequence] = useState(null)
  const { editorState } = useSelector(state => state.canvas.canvas)

  const { links, nodes } = editorState
  const nodeIdx = nodes.findIndex(x => x.id === data.nodeid)
  const source = nodeIdx === 0 || nodes.length > 2

  const selectedTrigger = !data.appEventConfiguration
    ? t("konnect.sidebar.customize_form_partial")
    : data.appEventConfigurations.find(x => x.id === data.appEventConfiguration).service_name
  let hasSequence =
    data.appEventConfigurations && data.appEventConfigurations.filter(x => x.sequence !== 0).length ? true : false
  let configForms = []

  if (hasSequence) {
    configForms = data.appEventConfigurations.slice(0, data.appEventSequences.length + 1)
  }

  const prepareElements = (responses, additional) => {
    let formElements = []
    if (responses) {
      formElements = responses.map((x, idx) => {
        const portInfo = {
          node: data.nodeid,
          app: data.id,
          appEvent: data.appEvent ? data.appEvent : "undefined",
          port: x.id,
          label: x.label,
          id: x.id,
          config_key: x.config_key,
        }
        let data1 = data.fromPorts ? data.fromPorts : []
        let ConnectorPort = ConnectorPortMentions
        return (
          <div key={idx}>
            <ConnectorPortLabel>{x.label}</ConnectorPortLabel>
            <Box sx={{ display: "grid", gridTemplateColumns: source ? "8% 88% 74%" : "8% 92%" }}>
              <ConnectorPortOuter placement="left" type={x.key_value_type}>
                <Handle
                  type="target"
                  position="left"
                  isConnectable="true"
                  style={{
                    height: "20px",
                    background: "#152f73",
                    width: "20px",
                    borderRadius: "60%",
                    border: "1px solid #152f73",
                  }}
                  id={x.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "target" })}
                />
              </ConnectorPortOuter>
              <ConnectorPort
                nodes={nodes}
                dispatch={dispatch}
                placeholder={!x.dirty ? x.label : `${x.label}*`}
                data={data1}
                componentData={data}
                onChange={val => {
                  dispatch(
                    onPortTextChange({
                      data,
                      nodeId: data.nodeid,
                      portId: x.id,
                      config_key: x.config_key,
                      input: val,
                    })
                  )
                }}
                value={x.value ? x.value : ""}
              ></ConnectorPort>
              <ConnectorPortOuter placement="right" type={x.key_value_type}>
                {source ? (
                  <Handle
                    type="source"
                    position="right"
                    isConnectable="true"
                    style={{
                      right: "10px",
                      height: "20px",
                      background: "#152f73",
                      width: "20px",
                      borderRadius: "60%",
                      border: "1px solid #152f73",
                    }}
                    id={x.id.toString() + "|" + JSON.stringify({ ...portInfo, type: "source" })}
                  />
                ) : null}
              </ConnectorPortOuter>
            </Box>
          </div>
        )
      })
      return formElements
    }
  }
  let formElements = prepareElements(data.configResponses)
  useEffect(() => {
    setNodeTested(data.tested ? data.tested : null)
    setNodeReviewed(data.tested ? data.reviewed : null)
  }, [data.tested, data.reviewed])

  return (
    <>
      <CommonOuter node={data} reviewed={nodeReviewed} nodeTested={nodeTested} />
      <NodeOuter onMouseEnter={() => setIsDeleteShown(true)} onMouseLeave={() => setIsDeleteShown(false)}>
        <AppInfo
          title={data.name}
          // subtitle={node.account}
          image={data.image}
          // className="DraggableHandle"
          background={data.background}
          hasDelete={IsDeleteShown && (nodeIdx !== 0 || nodes.length === 1)}
          // onDelete={() => {
          //   editorActions.onDeleteNode({
          //     nodeId: node.nodeId,
          //   })
          // }}
          onDelete={() => {
            if (data.tested || data.konnect_activity_id) {
              dispatch(
                onDeleteNodeV2({
                  konnect_id: nodes[0].data.konnect_id,
                  konnect_activity_id: data.konnect_activity_id,
                  canvas_json: editorState,
                  nodeId: data.nodeid,
                })
              ).then(res => {
                dispatch(onDeleteNode(data))
              })
            } else {
              dispatch(onDeleteNode(data))
            }
          }}
        ></AppInfo>

        {data.type === "ADD_ON" ? (
          <>
            <EventSelectionGroup>
              <SelectionHeader> {t("konnect.sidebar.choose_format_header")}</SelectionHeader>
              <Dropdown
                searchable={true}
                searchValue={searchAccounts}
                onFilterChange={text => {
                  setSearchAccounts(text)
                }}
                inputPlaceholder={!searchAccounts ? "Search field" : ""}
                headerTitle={
                  data.appEvents.length
                    ? t("konnect.sidebar.choose_option_header")
                    : t("konnect.sidebar.no_options_available")
                }
                items={data.appEvents}
                renderKey="name"
                selected={data.appEvent ? data.appEvents.find(x => x.id === data.appEvent).name : null}
                onChange={selected => {
                  dispatch(
                    getAppEventConfig({
                      data,
                      nodeId: data.nodeid,
                      appId: data.id,
                      eventId: selected.id,
                      source: nodeIdx === 0,
                      type: selected.side === "left" ? "source" : selected.side === "right" ? "target" : "both",
                    })
                  )
                  setSearchEvents("")
                  setEvent(false)
                }}
                onChangeState={isOpen => {
                  dispatch(setConfigMenuState({ isOpen }))
                }}
              />
            </EventSelectionGroup>

            {hasSequence && data.appEvent && (
              <EventSelectionGroup>
                <SelectionHeader>{t("konnect.sidebar.choose_option_header")}</SelectionHeader>
                {configForms.map((x, i) => {
                  const trigger = data.appEventSequences.find(y => x.sequence === y.sequence)
                  return (
                    <Dropdown
                      searchable={true}
                      searchValue={searchEventConfigurations}
                      onFilterChange={text => {
                        setSearchEventConfigurations(text)
                      }}
                      inputPlaceholder={!searchEventConfigurations ? "Search field" : ""}
                      key={i}
                      index={i}
                      headerTitle={trigger ? trigger.name : `${selectedTrigger} ${x.label}`}
                      items={data.appEventConfigurationDetails.filter(c => c.config_key === x.config_key)}
                      renderKey="name"
                      onOpen={() => {
                        const currSequenceIdx = data.appEventConfigurations.findIndex(y => y.sequence === x.sequence)
                        const prevSequence = data.appEventConfigurations[currSequenceIdx - 1]
                        let prevSeq = null
                        if (prevSequence) {
                          prevSeq = data.appEventSequences.find(c => c.sequence === prevSequence.sequence)
                        }
                        dispatch(
                          getAppEventConfigDetail({
                            data,
                            nodeId: data.nodeid,
                            appId: data.id,
                            eventId: data.appEvent,
                            eventConfigId: x.id,
                            source: nodeIdx === 0,
                            config_key: x.config_key,
                            prevSequence: prevSeq,
                            // app_account_id: data.selectedAccount.id,
                          })
                        )
                        setEventSequence(x.sequence ? x.sequence : x.config_id)
                        //setEventConfig(true)
                      }}
                      onChange={selected => {
                        dispatch(
                          setEventsConfig({
                            data,
                            nodeId: data.nodeid,
                            appId: data.id,
                            eventId: data.appEvent,
                            eventConfigId: eventSequence,
                            source: nodeIdx === 0,
                            sequence: {
                              sequence: eventSequence,
                              id: selected.id,
                              name: selected.name,
                              config_key: selected.config_key,
                            },
                            selectedEventConfigId: selected.id,
                            selectedEventConfigName: selected.name,
                          })
                        )

                        if (
                          data.appEventConfigurations.findIndex(x => x.sequence === eventSequence) ===
                          data.appEventConfigurations.length - 1
                        ) {
                          dispatch(
                            getAppEventConfigDetailFetch({
                              data,
                              nodeId: data.nodeid,
                              appId: data.id,
                              eventId: data.appEvent,
                              eventConfigId: selected.id,
                              source: nodeIdx === 0,
                              selectedEventConfigId: selected.id,
                              selectedEventConfigName: selected.name,
                              selectedConfigKey: selected.config_key,
                              // app_account_id:selected.id,
                            })
                          )
                        }
                        setSearchEventConfigurations("")

                        // setEventConfig(false)
                      }}
                      onChangeState={isOpen => {
                        dispatch(setConfigMenuState({ isOpen }))
                      }}
                    />
                  )
                })}
              </EventSelectionGroup>
            )}

            {formElements.length ? (
              <EventSelectionGroup>
                <SelectionHeader>{t("konnect.sidebar.configure_response")}</SelectionHeader>
                {formElements.map(form => form)}
                {data.configResponses ? (
                  <>
                    <SelectionHeader></SelectionHeader>

                    <TestAndReviewButton
                      onClick={() => {
                        dispatch(
                          appTestAndReview({
                            data,
                            nodeIdx: nodeIdx,
                            editorState: editorState,
                            enableTestReviewLoader,

                            dispatch,
                          })
                        )
                      }}
                      // onClick={() => {
                      //   editorActions.appTestAndReview({
                      //     data,
                      //     nodeIdx: nodeIdx,
                      //     editorState: chart,
                      //   })
                      // }}
                    >
                      Test & Review
                    </TestAndReviewButton>
                  </>
                ) : null}
              </EventSelectionGroup>
            ) : null}
          </>
        ) : null}
      </NodeOuter>
    </>
  )
})
