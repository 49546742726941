/**
 * App config reducer to handle the team api responses.
 */

 import { GET_AGENCY_USERS_LIST,DELETE_AGENCY_MEMBER, UPDATE_AGENCY_MEMBER } from 'config/Constants'

 const initState = {
    agencyUsersList: [],
    totalTasks: null,
    activeSubAccounts: null,
    inActiveSubAccounts: null,
    taskConsumed: null,
    totalSubAccounts: null,
    allowedSubaccountsLimit: null
 }
 
 const SUCCESS = '_SUCCESS'
 const FAIL = '_FAIL'
 
 const teamReducer = (state = initState, action) => {
     switch (action.type) {
         case GET_AGENCY_USERS_LIST + SUCCESS:
            return state.set('agencyUsersList', action.payload.sub_accounts)
            .set("totalTasks", action.payload.total_tasks)
            .set("activeSubAccounts", action.payload.active_subaccounts)
            .set("inActiveSubAccounts", action.payload.inactive_subaccounts)
            .set("taskConsumed", action.payload.task_consumed)
            .set("totalSubAccounts", action.payload.total_subaccounts)
            .set("allowedSubaccountsLimit", action.payload.allowed_subaccount_limit)
         case UPDATE_AGENCY_MEMBER + SUCCESS: 
            return state
         case UPDATE_AGENCY_MEMBER + FAIL:
            return state
         case GET_AGENCY_USERS_LIST + FAIL:
            
            return state.set("agencyUsersList", [])
            case DELETE_AGENCY_MEMBER + SUCCESS:
               return state
         default:
            return state
     }
 }
 
 export default teamReducer
 