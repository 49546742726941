/**
 * Verification email sent page.
 */
import React from "react"
import { Flex, Box } from "rebass"
import { useTranslation } from "react-i18next"
import { LatestApps } from "./widgets/LatestApps"
import { LatestKonnectz } from "./widgets/LatestKonnectz"
import { Subscription } from "./widgets/Subscription"
import { TaskConsumption } from "./widgets/TaskConsumption"
import { TotalKonnectz } from "./widgets/TotalKonnectz"
import { PromotionalTasks } from "./widgets/PromotionalTasks"
import "./style.css"
export const DashboardView = props => {
  const {
    activeWidgets,
    getWidgetDetails,
    updateKonnectStatus,
    changeStatus,
    active,
    konnects,
    deleteKonnectModal,
  } = props

  return (
    <Flex
      p={{ _: 26, sm: 26, md: 28, lg: 30, xl: 32 }}
      sx={{ flexDirection: "column", width: "100%", height: "90%", gap: [26, 28, 30, 32] }}
    >
      {/* {activeWidgets && activeWidgets.length > 0 && activeWidgets.map((item, idx) => {
                    return widgets[item.widget_key]
                })} */}
      <Flex sx={{ gap: [26, 28, 30, 32] }}>
        <TotalKonnectz getWidgetDetails={getWidgetDetails} />
        <PromotionalTasks getWidgetDetails={getWidgetDetails} />
        <TaskConsumption getWidgetDetails={getWidgetDetails} />
        <Subscription getWidgetDetails={getWidgetDetails} {...props} />
      </Flex>

      <LatestKonnectz
        getWidgetDetails={getWidgetDetails}
        konnects={konnects}
        updateKonnectStatus={updateKonnectStatus}
        changeStatus={changeStatus}
        active={active}
        deleteKonnectModal={deleteKonnectModal}
      />
    </Flex>
  )
}
