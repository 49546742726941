import React, { memo, useState, useEffect, useCallback, useRef, useMemo } from "react"
import ReactFlow, {
  Background,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  addEdge,
  updateEdge,
  Controls,
  connectOnClick,
  onNodeDragStop,
} from "react-flow-renderer"
import Sidebar from "./Component/Sidebar"
import { v4 as uuidv4 } from "uuid"
import { KonnectWrapper, Konnectheader } from "src/components/Konnect/UI"
import { Flex, Text, Box } from "rebass"
import { Button, Icon, Input } from "src/components"
import px2vw from "utils/px2vw"
import { Modal } from "react-responsive-modal"
import { ToastContainer, toast } from "react-toastify"
import { CustomEdgeButton } from "./Component/konnect/Edges"
import { useHistory } from "react-router-dom"
import { useData } from "./Context/canvasContext"
import KonnectHeader from "./Component/KonnectHeader"
import Newcanvas from "./NewCanvas"
import {onCanvasDrop} from "../ReactFlow/store/slice/canvasSlice"
import { useDispatch } from "react-redux"
import {
  Conditions,
  WebHookInner,
  NodeInner,
  AddOnNode,
  GravityFormsWebhook,
  AddOnInner,
  DateFormatterInnerDefault,
  CoupanGenerator,
  ApiInner,
  Scheduler,
  TextSplitterDefault,
} from "./Component/konnect/nodes"
import "./index.css"
import "./App.css"
import GetAppType from "./Component/konnect/Node/NodeWrapper"
import { getAuthApps } from "store/actions/konnectActions"
const connectionLineStyle = { stroke: "#f7c850" }
const snapGrid = [50, 50]
const nodeTypes = {
  webhookNode: WebHookInner,
  rightHandNode: NodeInner,
  addOnNode: AddOnNode,
  coupanGenerator: CoupanGenerator,
  addOnInner: AddOnInner,
  scheduler: Scheduler,
  GravityFormsWebhook: GravityFormsWebhook,
  apiInner: ApiInner,
  conditions: Conditions,
  coupan: CoupanGenerator,
  textSplitter: TextSplitterDefault,
}
const EdgeType = {
  buttonedge: "buttonedge",
}
const edgeTypes = {
  [EdgeType.buttonedge]: CustomEdgeButton,
}

const getId = () => uuidv4() //id for nodes

const DnDFlow = () => {
  const reactFlowWrapper = useRef(null)
  const [items, setItems] = useState([])
const dispatch=useDispatch()
  const {
    setInValue,
    onDeleteLink,
    inValue,
    nodes,
    edges,
    setEdges,
    onEdgesChange,
    setNodes,
    onLinkComplete,
    saveKonnect,
    isCollapsed,
    apiAppInitialState,
    getAppEvents,
    getLinkedAccounts,
    onCanvasDrop,
    onNodesChange,

    onLinkStart,
    getAuthApps,
    setUpdate,
    edit,
    setEdit,
    setEditId,
    editId,
    update,
    onNodeDragStop,
    changeHistory,
    historyCount,
    getKonnect,
    setKonnectTitle,

    reactFlowInstance,
    setReactFlowInstance,
    editt,
    setEditt,
    aa,
    count,
    propValue,
    showOnCanvas,
  } = useData()

  const onDragOver = event => {
    event.preventDefault()
    event.dataTransfer.dropEffect = "move"
  }
  let isWebHookExists = false

  const [historyOnDrop, setHisOnDrop] = useState(0)

  const onDrop = event => {
    event.preventDefault()
    //to check webHook Exists or not
    let webhk = nodes.find(e => e.data.type === "WEBHOOK")
    isWebHookExists = !!webhk
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()

    const value = event.dataTransfer.getData("application/reactflow")
    // check if the dropped element is valid
    const data = JSON.parse(value)
    if (typeof value === "undefined" || !value) {
      return
    }
    const position = reactFlowInstance.project({
      x: event.clientX - 400,
      y: event.clientY - 100,
    })
    let newNode
    if (data.type === "WEBHOOK" && isWebHookExists) {
      toast.info("Webhook app already exists!"),
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      return
    }
    if (data.type === "WEBHOOK" && nodes && nodes.length && nodes[0].data.type === "API") {
      toast.info("Try With Other App Cannot Add Webhook Here!!!"),
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      return
    }

    if (data.webhook_enabled) {
      data.webhook = true
      data.instructions = data.webhook_instructions
      data.webhookUrl = data.webhook_url
      // payload.webhookUrl = data.webhook_url //todo
    }
    let newEntry
    if (data) {
      newEntry = {
        position,
        data: {
          ...data,
          type: data.type,
          nodeid: getId(),
          // auth_type:null,
          appEvent: null,
          appEventConfigurations: [],
          appEventSequences: [],
          appEvents: [],
          configResponses: [],
          tested: false,
          reviewed: false,
          isloading: false,
          showTestAndReview: data.type === "ADD_ON" && data.provider === "conditioner" ? false : true,
        },
      }

      if (data.type === "ADD_ON" && data.provider === "api") {
        newEntry.data.api = apiAppInitialState
      }
      if (data.type === "ADD_ON" && data.provider === "conditioner") {
        newEntry.data.configResponses = [
          {
            app_event_id: 3,
            app_id: data.id,
            config_key: "conditions_setup",
            config_key_required: false,
            fetch_fields: false,
            id: "conditions_setup",
            key_value_type: "button",
            label: "",
            sequence: 0,
            side: "left",
          },
        ]
        newEntry.data.configResponse = []
      }

      newNode = {
        id: newEntry.data.nodeid,

        ...newEntry,
        type: GetAppType({ data, update }),
      }

      //getAppEvents(newNode)
    }
    getAppEvents(newNode).then(() => {
      onCanvasDrop(newNode)
    })

    getLinkedAccounts(newNode)
  }

  useEffect(() => {
    if (historyOnDrop > 0 || historyCount > 0) {
      changeHistory(inValue)
    }
  }, [historyOnDrop, historyCount])

  const edgeUpdateSuccessful = useRef(true)
  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false
  }, [count])

  useEffect(() => {
    getAuthApps()

    let idx = 0
    if (nodes.length >= 1) {
      setUpdate(true)
    } else {
      setUpdate(false)
    }
  }, [])

  // },[count])
  const onConnectStart = (_, { nodeId, handleId, handleType }) => {
    onLinkStart({ nodeId, handleId, handleType })
  }
  const onConnect = useCallback(params => {
    // if(isWebhookExists ) {
    let newEdge = {
      ...params,
      id: getId(),
      type: EdgeType.buttonedge,
    }

    // setInValue(inValue => ({...inValue,editorState:{...inValue.editorState,links:[...inValue.editorState.links,newEdge]}}))
    setEdges(edges => edges.concat(newEdge))
    return onLinkComplete(newEdge)
  })

  const onEdgeUpdate = (oldEdge, newConnection) => {
    edgeUpdateSuccessful.current = true

    setEdges(els => updateEdge(oldEdge, newConnection, els))
  }

  const onEdgeUpdateEnd = useCallback((_, edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges(eds => eds.filter(e => e.id !== edge.id))
      onDeleteLink(edge)
    }
    edgeUpdateSuccessful.current = true
  }, [])

  const history1 = useHistory()
  let idd = history1.location.pathname.split("/").pop()
  useEffect(() => {
    if (idd && !isNaN(idd)) {
      setEditt(true)
      getKonnect(idd).then(res => {
        if (res.canvas_name === "ReactFlow") {
          return (
            setInValue(inValue => ({ ...inValue, editorState: res })),
            setInValue(inValue => ({
              ...inValue,
              history: {
                ...inValue.history,
                present: { ...inValue.history.present, nodes: res.nodes, edges: res.links },
              },
            })),
            setNodes(res.nodes),
            setEdges(res.links),
            setKonnectTitle(res.konnect_name)
          )
        } else {
          const { nodes, links } = res
          let newNodes = nodes.map((n, i) => {
            let data = {
              Type: n.type,
              nodeid: n.nodeId,
              // auth_type:null,
              appEvent: n.appEvent,
              appEventConfigurations: n.appEventConfigurations,
              appEventSequences: n.appEventSequences,
              appEvents: n.appEvent,
              configResponses: n.configResponses,
              tested: n.tested,
              reviewed: n.reviewed,
              isloading: n.isloading,
              showTestAndReview: n.showTestAndReview,
              additionalResponses: n.additionalResponses,
              associatedAccounts: n.associatedAccounts,
              background: n.background,
              condition_activity_id: n.condition_activity_id,
              configResponsesOrig: n.configResponsesOrig,
              config_fields: n.config_fields,
              display_message: n.display_message,
              error: n.error,
              id: n.appId,
              image: n.image,
              konnect_activity_id: n.konnect_activity_id,
              konnect_id: n.konnect_id,
              linkedAccounts: n.linkedAccounts,
              name: n.name,
              order: n.order ? n.order : null,
              pass_through_condition: n.pass_through_condition,
              provider: n.provider,
              raw_response: n.raw_response,
              selectedAccount: n.selectedAccount,
              test_status: n.test_status,
              type: n.appType,
            }
            return {
              id: n.nodeId,
              position: { x: 500 + 500 * i, y: 300 },
              data: {
                Type: n.type,
                nodeid: n.nodeId,
                // auth_type:null,
                appEvent: n.appEvent,
                appEventConfigurations: n.appEventConfigurations,
                appEventSequences: n.appEventSequences,
                appEvents: n.appEvents,
                configResponses: n.configResponses,
                tested: n.tested,
                reviewed: n.reviewed,
                isloading: n.isloading,
                showTestAndReview: n.showTestAndReview,
                additionalResponses: n.additionalResponses,
                associatedAccounts: n.associatedAccounts,
                background: n.background,
                condition_activity_id: n.condition_activity_id,
                configResponsesOrig: n.configResponsesOrig,
                config_fields: n.config_fields,
                display_message: n.display_message,
                error: n.error,
                id: n.appId,
                image: n.image,
                konnect_activity_id: n.konnect_activity_id,
                konnect_id: n.konnect_id,
                linkedAccounts: n.linkedAccounts,
                name: n.name,
                order: n.order ? n.order : null,
                pass_through_condition: n.pass_through_condition,
                provider: n.provider,
                raw_response: n.raw_response,
                selectedAccount: n.selectedAccount,
                test_status: n.test_status,
                type: n.appType,
                appEventConfigurationDetails: n.appEventConfigurationDetails,
                webhook: n.webhook,
                webhook_instructions: n.webhook_instructions,
                webhook_url: n.webhookUrl,
                webhookUrl: n.webhookUrl,
              },
              type: GetAppType({ data, update }),
            }
          })
          let newEdges = links.map(e => {
            let x = {
              node: e.from.node,
              app: Number(e.from.port.split("|")[1]),
              appEvent: Number(e.from.port.split("|")[2]) ? Number(e.from.port.split("|")[2]) : "undefined",
              port: e.from.port.split("|")[3],

              port: e.from.port.split("|")[3],
              label:
                e.from.port
                  .split("|")[3]
                  .charAt(0)
                  .toUpperCase() + e.from.port.split("|")[3].slice(1),
              id: e.from.port.split("|")[3],
              config_key: e.from.port.split("|")[3],
              //  key_value_type: "input",
              //type:"source"
            }
            let y = {
              node: e.to.node,
              app: Number(e.to.port.split("|")[1]),
              appEvent: Number(e.to.port.split("|")[2]) ? Number(e.to.port.split("|")[2]) : "undefined",
              port: e.to.port.split("|")[3],
              label:
                e.to.port
                  .split("|")[3]
                  .charAt(0)
                  .toUpperCase() + e.to.port.split("|")[3].slice(1),

              id: e.to.port.split("|")[3],
              config_key: e.to.port.split("|")[3],
              // key_value_type: "input",
              // type:"target"
            }
            let source = e.from.port.split("|")[3].toString() + "|" + JSON.stringify({ ...x, type: "source" })
            let target = e.to.port.split("|")[3].toString() + "|" + JSON.stringify({ ...y, type: "target" })
            return {
              source: e.from.node,
              target: e.to.node,

              sourceHandle: source,

              targetHandle: target,
              id: e.id,

              type: EdgeType.buttonedge,
            }
          })
          return (
            setNodes(newNodes),
            setKonnectTitle(res.konnect_name),
            setEdges(newEdges),
            setInValue(inValue => ({
              ...inValue,
              editorState: { ...inValue.editorState, nodes: newNodes, links: newEdges, konnect_name: res.konnect_name },
            }))
          )
        }
      })
    }
  }, [])

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("dataKey"))
    if (items) {
      showOnCanvas(items)
    }
    return localStorage.setItem("dataKey", JSON.stringify([]))
  }, [])
  return (
    <div className="dndflow">
      <ReactFlowProvider>
        <KonnectWrapper sx={{ flexDirection: "column", alignItems: "stretch", pt: 6 }} id="konnectWrapper">
          <KonnectHeader openModal={openModal} setOpenModal={setOpenModal} nodes={nodes} />
          <Flex height="90%" width="100%">
            {/* <Box sx={{ marginTop: "0px" }}> */}
            <Sidebar />
            {/* </Box> */}
            <div
              className="reactflow-wrapper"
              ref={reactFlowWrapper}
              style={{ paddingLeft: isCollapsed ? 389 : 80, width: "100%", height: "100%" }}
            >
              <Newcanvas
                key={count}
                nodes={nodes}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                edges={edges}
                connectOnClick={true}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onDrop={onDrop}
                onNodesChange={onNodesChange}
                onEdgeUpdate={onEdgeUpdate}
                onEdgeUpdateStart={onEdgeUpdateStart}
                onEdgeUpdateEnd={onEdgeUpdateEnd}
                onDragOver={onDragOver}
                onInit={setReactFlowInstance}
                style={{
                  // display: "relative",
                  background: "rgb(243,247,255)",
                  "&.react-flow__viewport": {
                    transition: "transform 1s ease",
                  },
                }}
                //onNodeClick={onNodeDragStop}
                onConnectStart={onConnectStart}
                connectionLineStyle={connectionLineStyle}
                snapGrid={snapGrid}
                // minZoom={0.1}
                // maxZoom={0.1}
                //defaultViewport={defaultViewport}
              >
                {/* <Controls /> */}
              </Newcanvas>
            </div>
          </Flex>
        </KonnectWrapper>

        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </ReactFlowProvider>
    </div>
  )
}

export default DnDFlow
