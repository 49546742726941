import React from "react";
import ReactflowCanvas from "./ReactflowCanvas";
import { CanvasContextProvider } from "./Context/canvasContext";

const Canvas = () => {
  return (
    <CanvasContextProvider>
      <ReactflowCanvas />
    </CanvasContextProvider>
  )
}
export default Canvas
