import React, { useState } from "react"
import { Box, Text, Flex, Link } from "rebass"
import { Input, Button, Icon } from "src/components"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw"
import CreateFolderImg from "src/assets/images/create_folder.png"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

export const CreateFolderModal = ({ closeModal, createFolderName }) => {
  const { t } = useTranslation()
  const [folderName, setFolderName] = useState("")
  return (
    <>
      <Flex flexDirection="column" sx={{ borderRadius: "16px", px: px2vw(60), padding: "0px", minWidth: "500px" }}>
        <Flex flexDirection="row" sx={{ borderRadius: "16px", px: px2vw(20), padding: "0px" }}>
          <Box
            sx={{
              p: "7px 3px 0px 5px",
              m: "2px",
              justifyContent: "left",
              textAlign: "left",
              boxShadow: "1px 1px 1px 1px #e6e2e2",
              borderRadius: "5px",
            }}
          >
            <img width="20px" height="16px" src={CreateFolderImg} />
          </Box>
          <Text
            fontSize={[20]}
            fontWeight="semi-bold"
            color="app.primary.30"
            textAlign="left"
            sx={{ pt: 2.5, justifyContent: "left", textAlign: "left", mr: px2vw(3), ml: "12px" }}
          >
            New Folder
          </Text>
        </Flex>
        <Box sx={{ background: "#BCC6CC", width: "inherit", height: "1px", margin: "14px -20px" }}></Box>
        <Box sx={{ p: px2vw(20), justifyContent: "center", textAlign: "center" }}>
          <img width="135px" height="95px" src={CreateFolderImg} />
        </Box>

        <Box sx={{ color: "#000000", p: px2vw(10) }}>
          <Text sx={{ mb: px2vw(18), fontSize: "sm", color: "app.primary.30" }}>
            {t("konnectzList.folders.createFolderTitleText")}
          </Text>
          <Input
            type={"text"}
            value={folderName}
            sxProps={{
              "& ~ span": { color: "#B7CBFF !important" },
            }}
            onBlur={event =>  (event.target.placeholder = t("konnectzList.folders.createFolderInputPlaceholder"))}
            onFocus={event => (event.target.placeholder = t("konnectzList.folders.createFolderInputPlaceholder"))}
            onChange={e => setFolderName(e.target.value)}
          />
        </Box>

        <Box sx={{ p: px2vw(12) }}>
          <Flex flexDirection="row" sx={{ justifyContent: "center", mb: "10px" }}>
            {/* <Box>
                            <Button variant="primary" fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }} sx={{ fontWeight: 'normal', px: px2vw(20), borderRadius: '0px' }} onClick={() => closeModal()}>{t("konnectzList.deleteModalNoText")}</Button>
                        </Box> */}
            <Box sx={{ textAlign: "center", justifyContent: "center" }}>
              <Button
                fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                sx={{
                  backgroundColor: "app.secondary.110",
                  boxShadow: "none",
                  fontWeight: "normal",
                  cursor: "pointer",
                  color: "app.primary.30",
                  display: "flex",
                }}
                onClick={() => createFolderName(folderName)}
              >
                <Icon sx={{ cursor: "pointer", justifyContent: "center" }} icon={faCheckCircle} pt="3px" />

                <Box sx={{ cursor: "pointer", m: "1px 0 0 10px" }}>{t("konnectzList.folders.createFolderBtn")}</Box>
              </Button>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}