/**
 * Verification email sent page.
 */
import React, { useEffect, useState } from "react"
import { Text, Flex, Box } from "rebass"
import { useTranslation } from "react-i18next"
import { PieChart as PC, Pie, Cell, Tooltip as ChartTooltip } from "recharts"

export const PromotionalTasks = ({ getWidgetDetails }) => {
  const { t } = useTranslation()
  const [data, setData] = useState(null)

  // useEffect(() => {
  //   getWidgetDetails("task_consumption").then(res => {
  //     if (res.type === "GET_WIDGET_DETAILS_SUCCESS") {
  //       let details = res.payload
  //       let d = [
  //         { name: t("account.agency.consumed"), value: details.task_consumed, color: "#518EF8" },
  //         { name: t("account.agency.available"), value: details.task_available, color: "#F7C545" },
  //       ]
  //       setData(d)
  //     }
  //   })
  // }, [])

  /* In case if the customised label is needed */
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  const RADIAN = Math.PI / 180
  /* In case if the customised label is needed */

  return (
    <>
      <Flex
        p={{
          _: "20px",
          sm: "20px",
          md: "22px",
          lg: "24px",
          xl: "24px",
        }}
        sx={{
          width: "100%",
          flexDirection: "column",
          backgroundColor: "#FFF",
          borderRadius: "16px",
          boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
          whiteSpace: "nowrap",
        }}
      >
        <Text
          fontSize={{ _: 18, sm: 18, md: 20, lg: 22, xl: 22 }}
          sx={{ fontWeight: "500", color: "#152F73", marginBottom: "8px" }}
        >
          Promotional Tasks
        </Text>
        <Flex sx={{ flexDirection: "Row", p: "5px", mt: "10px", justifyContent: "flex-start", gap: "20px" }}>
          <Flex sx={{ gap: "10px" }}>
            <Text fontSize={{ _: 18, sm: 18, md: 20, lg: 22, xl: 22 }} sx={{ color: "#152F73" }}>
              0
            </Text>
            <Text
              fontSize={{ _: 10, sm: 10, md: 12, lg: 12, xl: 16 }}
              sx={{ fontWeight: "500", color: "#7482A6", m: "auto" }}
            >
              Consumed Tasks
            </Text>
          </Flex>
          <Flex sx={{ gap: "10px" }}>
            <Text fontSize={{ _: 18, sm: 18, md: 20, lg: 22, xl: 22 }} sx={{ color: "#152F73" }}>
              0
            </Text>
            <Text
              fontSize={{ _: 10, sm: 10, md: 12, lg: 12, xl: 16 }}
              sx={{ fontWeight: "500", color: "#7482A6", m: "auto" }}
            >
              Remaining Tasks
            </Text>
          </Flex>{" "}
        </Flex>
        <Flex sx={{ flexDirection: "Row", p: "5px", justifyContent: "flex-start", gap: "20px" }}>
          <Flex sx={{ gap: "10px" }}>
            <Text fontSize={{ _: 18, sm: 18, md: 20, lg: 22, xl: 22 }} sx={{ color: "#152F73" }}>
              0
            </Text>
            <Text
              fontSize={{ _: 10, sm: 10, md: 12, lg: 12, xl: 16 }}
              sx={{ fontWeight: "500", color: "#7482A6", m: "auto" }}
            >
              Consumed Tasks
            </Text>
          </Flex>
          <Flex sx={{ gap: "10px" }}>
            <Text fontSize={{ _: 18, sm: 18, md: 20, lg: 22, xl: 22 }} sx={{ color: "#152F73" }}>
              0
            </Text>
            <Text
              fontSize={{ _: 10, sm: 10, md: 12, lg: 12, xl: 16 }}
              sx={{ wordBreak: "normal", whiteSpace: "nowrap", fontWeight: "500", color: "#7482A6", m: "auto" }}
            >
              Remaining Tasks
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
