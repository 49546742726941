import React, { useEffect, useState } from "react"
import { useData } from "../Context/canvasContext"
import { withTranslation, useTranslation } from "react-i18next"
import {
  SidebarWrapper,
  SearchWrapper,
  IconWrapper,
  AppsListWrapper,
  AppItem,
  SearchInput,
  SidebarHeader,
} from "../Component/konnect/UI"
import { Box, Flex, Text, Image } from "rebass"
import { faFilter, faSearch } from "@fortawesome/free-solid-svg-icons"
import { KONNECT_FLOW_DRAGGABLE } from "config/Constants"
import Fuse from "fuse.js"
import px2vw from "utils/px2vw"
import { isEqual } from "lodash"
import ReactTooltip from "react-tooltip"
import AppsSvg from "src/assets/images/apps"
import HeartSvg from "src/assets/images/heart"
import LessThan from "../../../assets/images/lessThan.png"
import InfiniteScroll from "react-infinite-scroll-component"
export default () => {
  const [searchValue, setSearchValue] = useState(null)
  const [pageSize, setPageSize] = useState(20)
  const { inValue, getAuthApps, isCollapsed, setIsCollapsed, getAddOnApps, addOn } = useData()
  const { apps: apps1 } = inValue
  const { apps } = apps1

  const { t } = useTranslation()
  const [toggle, setToggle] = useState(true)

  const addonapp = addOn.apps

  const onSearchItem = e => {
    setSearchValue(e.currentTarget.value)
  }

  useEffect(() => {
    getAddOnApps()
    getAuthApps(searchValue ? { page_size: pageSize, search_query: searchValue } : { page_size: pageSize })
  }, [])
  useEffect(() => {
    let params = {
      page_size: pageSize,
    }
    let params1 = {
      page_size: pageSize,
      search_query: searchValue,
    }

    getAuthApps(searchValue ? { ...params1 } : { ...params })
  }, [pageSize, searchValue])

  const fetchMoreApps = () => {
    setPageSize(pageSize + 20)
  }

  return (
    <Box height={"98%"} sx={{ position: "absolute", transition: "all 400ms ease-in-out 0s" }}>
      {isCollapsed ? (
        <>
          <SidebarHeader>
            <SearchWrapper style={{ width: "88%" }}>
              <IconWrapper
                icon={faSearch}
                style={{
                  justifyContent: "flex-start",
                }}
              />
              <SearchInput
                id="search"
                name="search"
                iconLeft={faSearch}
                placeholder={t("konnect.sidebar.search_apps")}
                style={{
                  flex: 1,
                }}
                onChange={onSearchItem}
              />
              <IconWrapper
                icon={faFilter}
                style={{
                  justifyContent: "flex-end",
                }}
              />
            </SearchWrapper>

            <Flex
              sx={{
                width: "36px",
                cursor: "pointer",
                backgroundColor: "#152f73",
                height: "100%",
                padding: "8px",
                borderRadius: "30px",
                alignSelf: "center",
              }}
              onClick={() => setIsCollapsed(false)}
            >
              <img src={LessThan} width="40px" height="20px" />
            </Flex>
          </SidebarHeader>

          <SidebarWrapper id="scrollableDiv1">
            {searchValue && searchValue.length ? (
              ""
            ) : (
              <>
                <Box sx={{ mt: px2vw(20), pb: px2vw(20) }}>
                  <Text
                    fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                    sx={{ fontWeight: "500", color: "#222222", ml: "10px" }}
                  >
                    Add On Apps
                  </Text>
                </Box>
                <Flex
                  sx={{
                    flexFlow: "wrap",
                    flexDirection: "row",
                    overflowY: "auto",
                    width: "100%",
                    pt: px2vw(10),
                  }}
                >
                  {addonapp &&
                    addonapp.length > 0 &&
                    addonapp.map((app, idx) => {
                      const image = app.image_url
                        ? `${app.image_url}`
                        : "https://d33wubrfki0l68.cloudfront.net/4e5626a0b7db6b84c0b51f1c0cfd56490fc19b74/7a5ad/images/logo-fancy.svg"

                      return (
                        <React.Fragment key={app.id}>
                          <AppItem
                            data-tip={app.name}
                            data-for={app.id + ""}
                            key={app.id}
                            // title={app.name}
                            name={app.name}
                            image={image}
                            draggable={true}
                            badge={app.account_count}
                            beta={app.status === "Beta"}
                            onDragStart={event => {
                              event.dataTransfer.setData(
                                "application/reactflow",
                                JSON.stringify({
                                  id: app.id,
                                  name: app.name,
                                  image,
                                  background: app.background_color,
                                  associatedAccounts: app.account_count ? app.account_count : 1,
                                  webhook_enabled: app.webhook_enabled,
                                  webhook_instructions: app.webhook_instructions,
                                  webhook_url: app.webhook_url,
                                  provider: app.provider,
                                  type: app.app_type,
                                })
                              ) // it will go to node wrapper file  that will decide which app it is
                              event.dataTransfer.effectAllowed = "move"
                            }}
                          ></AppItem>
                          <ReactTooltip id={app.id + ""} effect="solid" place="bottom"></ReactTooltip>
                        </React.Fragment>
                      )
                    })}
                </Flex>
              </>
            )}

            <Box sx={{ mt: px2vw(20), pb: px2vw(20) }}>
              <Text
                fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                sx={{ fontWeight: "500", color: "#222222", ml: "10px" }}
              >
                Apps
              </Text>
            </Box>

            {apps && apps.length && (
              <InfiniteScroll
                style={{ display: "flex", flexDirection: "column" }}
                dataLength={apps.length || 0} //This is important field to render the next data
                next={fetchMoreApps}
                hasMore={true}
                pullDownToRefresh={false}
                scrollableTarget="scrollableDiv1"
                //  loader={<h4>Loading...</h4>}
              >
                <Flex
                  sx={{
                    flexFlow: "wrap",
                    flexDirection: "row",
                    overflowY: "auto",
                    width: "100%",
                    pt: px2vw(10),
                  }}
                >
                  {apps &&
                    apps.length > 0 &&
                    apps.map((app, idx) => {
                      const image = app.image_url
                        ? `${app.image_url}`
                        : "https://d33wubrfki0l68.cloudfront.net/4e5626a0b7db6b84c0b51f1c0cfd56490fc19b74/7a5ad/images/logo-fancy.svg"

                      return (
                        <React.Fragment key={app.id}>
                          <AppItem
                            data-tip={app.name}
                            data-for={app.id + ""}
                            key={app.id}
                            // title={app.name}
                            name={app.name}
                            image={image}
                            draggable={true}
                            badge={app.account_count}
                            beta={app.status === "Beta"}
                            onDragStart={event => {
                              event.dataTransfer.setData(
                                "application/reactflow",
                                JSON.stringify({
                                  id: app.id,
                                  name: app.name,
                                  image,
                                  background: app.background_color,
                                  associatedAccounts: app.account_count ? app.account_count : 1,
                                  webhook_enabled: app.webhook_enabled,
                                  webhook_instructions: app.webhook_instructions,
                                  webhook_url: app.webhook_url,
                                  provider: app.provider,
                                  type: app.app_type,
                                  auth_type: app.auth_type,
                                })
                              ) // it will go to node wrapper file  that will decide which app it is
                              event.dataTransfer.effectAllowed = "move"
                            }}
                          ></AppItem>
                          <ReactTooltip id={app.id + ""} effect="solid" place="bottom"></ReactTooltip>
                        </React.Fragment>
                      )
                    })}
                </Flex>
              </InfiniteScroll>
            )}
          </SidebarWrapper>
        </>
      ) : (
        <>
          <Box
            //  onClick={toggleSidebar}
            sx={{
              flexDirection: "column",
              width: "65px",
              height: "108.5%",
              backgroundColor: "#152f73",
              position: "absolute",
              zIndex: "100",
              overflowY: "auto",
            }}
          >
            <Flex sx={{ cursor: "pointer", height: "60px", alignItems: "center", justifyContent: "center" }}>
              <HeartSvg />
            </Flex>
            <Flex
              sx={{ cursor: "pointer", height: "60px", alignItems: "center", justifyContent: "center" }}
              onClick={() => setIsCollapsed(true)}
            >
              <AppsSvg />
            </Flex>
          </Box>
        </>
      )}
    </Box>
  )
}
