/**
 * App config reducer to handle the global config api responses.
 */

import { GET_PROFILE } from 'config/Constants'

const initState = {
  profile: {}
}

const SUCCESS = '_SUCCESS'
const FAIL = '_FAIL'

const profileReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_PROFILE + SUCCESS:
      return state.set('profile', action.payload)
    default:
      return state
  }
}

export default profileReducer
