import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { PortWrapper, PortDefault } from "../"
import ReactTooltip from "react-tooltip"
import {
  AppInfo,
  ConnectorOuter,
  ConnectorPortOuter,
  ConnectorPortInput,
  ConnectorPortText,
  ConnectorPortLabel,
  ConnectorPortError,
  NodeOuter,
  SelectionHeader,
  EventSelectionGroup,
  TestAndReviewButton,
  AddMoreFieldButton,
} from "../../../UI"
import { Modal } from "react-responsive-modal"
import { Dropdown } from "src/components/Konnect/DropdownMenu"

import { AdditionalConfigsModal } from "./modals/additionalConfigsModal"
import { CommonOuter } from "./CommonOuter"
import { ChildWindow } from "./Ghelper"
import { Preloader } from "src/components"
import GsheetModal from "./modals/gsheetModal"
const WrappedPort = props => {
  return (
    <PortWrapper
      config={props.config}
      offset={props.offset}
      selected={props.selected}
      selectedLink={props.selectedLink}
      hoveredLink={props.hoveredLink}
      hovered={props.hovered}
      node={props.node}
      port={props.port}
      Component={props.PortDefault}
      onPortPositionChange={props.onPortPositionChange}
      onLinkStart={props.onLinkStart}
      onLinkMove={props.onLinkMove}
      onLinkComplete={props.onLinkComplete}
      onLinkCancel={props.onLinkCancel}
      chart={props.chart}
      pType={props.pType}
      active={props.active}
    />
  )
}
export const NodeInnerDefault = ({
  node,
  chart,
  config,
  offset,
  selected,
  selectedLink,
  hoveredLink,
  hovered,
  onPortPositionChange,
  onLinkStart,
  onLinkMove,
  onLinkComplete,
  onLinkCancel,
  editorActions,
  Links,
  testAndReviewLoader,
}) => {
  const { t } = useTranslation()
  // const popup =
  const [eventSequence, setEventSequence] = useState(null)
  const [openAdditionalConfigs, setOpenAdditionalConfigs] = useState(false)
  const [addedKeys, setAddedKeys] = useState([])
  const [IsDeleteShown, setIsDeleteShown] = useState(false)
  const [openGsheetModal, setOpenGsheetModal] = useState(false)
  const [nodeReviewed, setNodeReviewed] = useState(node.tested ? node.reviewed : null)
  const [nodeTested, setNodeTested] = useState(node.tested ? node.tested : null)
  const [searchAccounts, setSearchAccounts] = useState("")
  const [searchEvents, setSearchEvents] = useState("")
  const [searchEventConfigurations, setSearchEventConfigurations] = useState("")
  const [testAndReviewLinked, setTestAndReviewLinked] = useState(false)
  const selectedTrigger = !node.appEventConfiguration
    ? t("konnect.sidebar.customize_form_partial")
    : node.appEventConfigurations.find(x => x.id === node.appEventConfiguration).service_name

  const nodeIdx = chart.nodes.findIndex(x => x.nodeId === node.nodeId)
  const source = nodeIdx === 0 || chart.nodes.length > 2
  // nodeIdx === 0 || node.type === "source" || node.type === "both" || (nodeIdx !== 0 && chart.nodes.length > 2)
  const target = nodeIdx !== 0
  // && (node.type === "target" || node.type === "both")

  let hasSequence =
    node.appEventConfigurations && node.appEventConfigurations.filter(x => x.sequence !== 0).length ? true : false

  let configForms = []

  if (hasSequence) {
    configForms = node.appEventConfigurations.slice(0, node.appEventSequences.length + 1)
  }


  const handleAdditionalConfigsClick = () => {
    setOpenAdditionalConfigs(true)
  }

  const { appType = "API" } = node

  const onCloseModal = list => {
    setOpenAdditionalConfigs(false)
    const selected = list.filter(x => x.selected)
    setAddedKeys(selected)
    editorActions.createAdditionalFields({ fields: selected, nodeId: node.nodeId })
    //
  }

  const handleGsheetModal = () => {
    setOpenGsheetModal(true)
  }

  const closeGsheetModal = () => {
    setOpenGsheetModal(false)
  }

  const publishAppScript = data => {
    let appDetails = {
      fields: data.data,
      isAndCondition: data.isAndCondition,
    }
    editorActions.appTestAndReview({
      node,
      nodeIdx: nodeIdx,
      editorState: chart,
      appDetails: appDetails,
    })
    setOpenGsheetModal(false)
  }
  const prepareElements = (responses, additional) => {
    let formElements = []
    if (responses) {
      formElements = responses.map(x => {
        let disable = !!chart.links.find(c => {
          return x.port && c.to.port === x.port.id
        })

        let ConnectorPort = x.key_value_type === "text" ? ConnectorPortText : ConnectorPortInput
        let data = []

        const isActive = !!Links.find(c => {
          return x.port && (c.to.port === x.port.id || c.from.port === x.port.id)
        })

        const __key = x.config_key
          .replace(/[^a-zA-Z ]/g, "")
          .split(" ")
          .join("_")

        if (nodeIdx === 0 || x.disabled) {
          disable = true
        }
        return (
          <>
            {node && node.konnect_custom && node.konnect_custom.script_id ? (
              <ChildWindow scriptId={node.konnect_custom.script_id} />
            ) : null}

            <ConnectorPortLabel>
              {x.label} {x.config_key_required ? <span style={{ color: "#F74545" }}>*</span> : ""}
            </ConnectorPortLabel>
            <ConnectorOuter key={`${x.app_id}_${__key}`} type={x.key_value_type}>
              {target && !x.additional ? (
                <ConnectorPortOuter placement="left" type={x.key_value_type}>
                  {
                    <WrappedPort
                      pType="target"
                      config={config}
                      offset={offset}
                      selected={selected}
                      selectedLink={selectedLink}
                      hoveredLink={hoveredLink}
                      hovered={hovered}
                      node={node}
                      port={x}
                      Component={PortDefault}
                      onPortPositionChange={onPortPositionChange}
                      onLinkStart={onLinkStart}
                      onLinkMove={onLinkMove}
                      onLinkComplete={onLinkComplete}
                      onLinkCancel={onLinkCancel}
                      chart={chart}
                      active={isActive}
                    />
                  }
                </ConnectorPortOuter>
              ) : null}

              <span
                style={{
                  width: "100%",
                }}
                data-for={`${x.app_id}_${__key}`}
                data-tip={x.label ? x.label : ""}
                data-iscapture="true"
              >
                <ConnectorPort
                  rows={4}
                  dirty={x.value ? false : !!x.dirty}
                  placeholder={!x.dirty ? x.label : `${x.label}*`}
                  key={`${x.app_id}_${__key}`}
                  disabled={disable}
                  onMouseDown={e => {
                    e.stopPropagation()
                  }}
                  onChange={e => {
                    editorActions.onPortTextChange({
                      nodeId: node.nodeId,
                      portId: x.portId,
                      config_key: x.config_key,
                      input: e.target.value,
                    })
                  }}
                  value={x.value ? x.value : ""}
                  data={data}
                  additional={x.additional}
                />
              </span>

              <ReactTooltip
                id={`${x.app_id}_${__key}`}
                // id="zzzzzz"
                place={"bottom"}
                type={"dark"}
                effect={"solid"}
                multiline={true}
                overridePosition={({ left, top }) => {
                  return { left: left / chart.scale, top: top / chart.scale }
                }}
              />

              {source || x.additional ? (
                <ConnectorPortOuter placement="right" type={x.key_value_type}>
                  <WrappedPort
                    pType="source"
                    config={config}
                    offset={offset}
                    selected={selected}
                    selectedLink={selectedLink}
                    hoveredLink={hoveredLink}
                    hovered={hovered}
                    node={node}
                    port={x}
                    Component={PortDefault}
                    onPortPositionChange={onPortPositionChange}
                    onLinkStart={onLinkStart}
                    onLinkMove={onLinkMove}
                    onLinkComplete={onLinkComplete}
                    onLinkCancel={onLinkCancel}
                    chart={chart}
                    active={isActive}
                  />
                </ConnectorPortOuter>
              ) : null}
            </ConnectorOuter>
            {x.error ? <ConnectorPortError>{x.error}</ConnectorPortError> : null}
          </>
        )
      })
    }
    return formElements
  }

  let formElements = prepareElements(node.configResponses)
  //let nodeReviewed = node.tested ? node.reviewed : null
  const gsheetFields = node.konnect_custom
    ? node.konnect_custom.appDetails && node.konnect_custom.appDetails.fields
    : node.configResponsesOrig

  const onLinkedAccountChange = selected => {
    // node.tested = false
    // node.reviewed = false
    setNodeReviewed(null)
    setNodeTested(false)
    editorActions.setLinkedAccounts({ selected, node })
    setTimeout(() => {
      editorActions.getAppEvents({ ...node })
    }, 300)
  }

  useEffect(() => {
    setNodeTested(node.tested ? node.tested : null)
    setNodeReviewed(node.tested ? node.reviewed : null)
  }, [node.tested, node.reviewed])

  useEffect(() => {
    // if(node.appType === "WEBHOOK") {
    const testReviewLinked = chart.links ? chart.links.filter(link => link.from.node === node.nodeId).length > 0 : false
    setTestAndReviewLinked(testReviewLinked)
    //}
  }, [chart.links])
  return (
    <>
      <CommonOuter node={node} reviewed={nodeReviewed} nodeTested={nodeTested} />
      <NodeOuter
        onMouseEnter={() => setIsDeleteShown(true)}
        onMouseLeave={() => setIsDeleteShown(false)}
        isFirst={!nodeIdx}
        reviewed={nodeReviewed}
      >
        <AppInfo
          title={node.name}
          subtitle={node.account}
          image={node.image}
          className="DraggableHandle"
          background={node.background}
          hasDelete={IsDeleteShown && (nodeIdx !== 0 || chart.nodes.length === 1)}
          onDelete={() => {
            if (node.tested || node.konnect_activity_id) {
              editorActions
                .onDeleteNodeV2({
                  konnect_id: chart.nodes[0].konnect_id,
                  konnect_activity_id: node.konnect_activity_id,
                  canvas_json: chart,
                  nodeId: node.nodeId,
                })
                .then(data => {
                  editorActions.onDeleteNode({
                    nodeId: node.nodeId,
                  })
                })
            } else {
              editorActions.onDeleteNode({
                nodeId: node.nodeId,
              })
            }
          }}
        ></AppInfo>
        <>
          {node.linkedAccounts && node.linkedAccounts.length > 1 ? (
            <EventSelectionGroup>
              <SelectionHeader>{t("konnect.sidebar.choose_linked_account")}</SelectionHeader>
              <Dropdown
                searchable={true}
                searchValue={searchAccounts}
                onFilterChange={text => {
                  setSearchAccounts(text)
                }}
                inputPlaceholder={!searchAccounts ? "Search field" : ""}
                headerTitle={t("konnect.sidebar.choose_linked_account")}
                items={node.linkedAccounts}
                renderKey="name"
                selected={node.selectedAccount ? node.selectedAccount.name : ""}
                onChange={selected => {
                  onLinkedAccountChange(selected)
                  setSearchAccounts("")
                }}
                onChangeState={isOpen => {
                  editorActions.setConfigMenuState({ isOpen })
                }}
              />
            </EventSelectionGroup>
          ) : null}
          {node.selectedAccount ? (
            <EventSelectionGroup>
              <SelectionHeader>

                {node.appEvents.length > 0 && node.appEvents[0].side === "right"
                  ? t("konnect.sidebar.choose_action_event")
                  : t("konnect.sidebar.choose_trigger")}
              </SelectionHeader>

              <Dropdown
                searchable={true}
                searchValue={searchEvents}
                onFilterChange={text => {
                  setSearchEvents(text)
                }}
                inputPlaceholder={!searchEvents ? "Search field" : ""}
                disableIfEmpty
                headerTitle={t("konnect.sidebar.select_trigger_action_dropdown_header")}
                items={node.appEvents}
                renderKey="name"
                selected={
                  node.appEvent && node.appEvents.length ? node.appEvents.find(x => x.id === node.appEvent).name : null
                }
                onChange={selected => {
                  editorActions.getAppEventConfig({
                    nodeId: node.nodeId,
                    appId: node.appId,
                    eventId: selected.id,
                    source: nodeIdx === 0,
                    type: selected.side === "left" ? "source" : selected.side === "right" ? "target" : "both",
                    app_account_id: node.selectedAccount.id,
                  })
                  setSearchEvents("")
                  // setEvent(false)
                }}
                onChangeState={isOpen => {
                  editorActions.setConfigMenuState({ isOpen })
                }}
              />
            </EventSelectionGroup>
          ) : null}

          {hasSequence && node.appEvent && (
            <EventSelectionGroup>
              <SelectionHeader>{t("konnect.sidebar.choose_config_header")}</SelectionHeader>
              {configForms.map((x, i) => {
                const trigger = node.appEventSequences.find(y => x.sequence === y.sequence)
                return (
                  <Dropdown
                    searchable={true}
                    searchValue={searchEventConfigurations}
                    onFilterChange={text => {
                      setSearchEventConfigurations(text)
                    }}
                   inputPlaceholder={!searchEventConfigurations ? "Search field" : ""}
                    key={i}
                    index={i}
                    headerTitle={trigger ? trigger.name : `${selectedTrigger} ${x.label}`}
                    items={node.appEventConfigurationDetails.filter(c => c.config_key === x.config_key)}
                    renderKey="name"
                    onOpen={() => {
                      const currSequenceIdx = node.appEventConfigurations.findIndex(y => y.sequence === x.sequence)
                      const prevSequence = node.appEventConfigurations[currSequenceIdx - 1]
                      let prevSeq = null
                      if (prevSequence) {
                        prevSeq = node.appEventSequences.find(c => c.sequence === prevSequence.sequence)
                      }
                      editorActions.getAppEventConfigDetail({
                        node,
                        nodeId: node.nodeId,
                        appId: node.appId,
                        eventId: node.appEvent,
                        eventConfigId: x.id,
                        source: nodeIdx === 0,
                        config_key: x.config_key,
                        prevSequence: prevSeq,
                        app_account_id: node.selectedAccount.id,
                      })
                      setEventSequence(x.sequence ? x.sequence : x.config_id)
                      // setEventConfig(true)
                    }}
                    onChange={selected => {
                      editorActions.setEventsConfig({
                        nodeId: node.nodeId,
                        appId: node.appId,
                        eventId: node.appEvent,
                        eventConfigId: eventSequence,
                        source: nodeIdx === 0,
                        sequence: {
                          sequence: eventSequence,
                          id: selected.id,
                          name: selected.name,
                          config_key: selected.config_key,
                        },
                        selectedEventConfigId: selected.id,
                        selectedEventConfigName: selected.name,
                      })

                      if (
                        node.appEventConfigurations.findIndex(x => x.sequence === eventSequence) ===
                        node.appEventConfigurations.length - 1
                      ) {
                        editorActions.getAppEventConfigDetailFetch({
                          node,
                          nodeId: node.nodeId,
                          appId: node.appId,
                          eventId: node.appEvent,
                          eventConfigId: selected.id,
                          source: nodeIdx === 0,
                          selectedEventConfigId: selected.id,
                          selectedEventConfigName: selected.name,
                          selectedConfigKey: selected.config_key,
                          app_account_id: node.selectedAccount.id,
                        })
                      }
                      setSearchEventConfigurations("")

                      // setEventConfig(false)
                    }}
                    onChangeState={isOpen => {
                      editorActions.setConfigMenuState({ isOpen })
                    }}
                  />
                )
              })}
            </EventSelectionGroup>
          )}

          {formElements.length ? (
            <EventSelectionGroup>
              <SelectionHeader>{t("konnect.sidebar.configure_response")}</SelectionHeader>

              {node.configResponses ? (
                <>
                  {(node.provider === "google_sheet" || node.provider === "google-form") && nodeIdx === 0 ? (
                    <div>
                      {node.isloading ? (
                        <div style={{ display: "flex" }}>
                          <Preloader loading={node.isloading} position="static" />
                        </div>
                      ) : (
                        <TestAndReviewButton disabled={testAndReviewLinked} onClick={handleGsheetModal}>
                          {t("konnect.testAndReview.btnLabel")}
                        </TestAndReviewButton>
                      )}
                    </div>
                  ) : (
                    <div>
                      {node.isloading ? (
                        <div style={{ display: "flex" }}>
                          <Preloader loading={node.isloading} position="static" />
                        </div>
                      ) : (
                        <TestAndReviewButton
                          disabled={testAndReviewLinked}
                          onClick={() => {
                            editorActions.appTestAndReview({
                              node,
                              nodeIdx: nodeIdx,
                              editorState: chart,
                            })
                          }}
                        >
                          {t("konnect.testAndReview.btnLabel")}
                        </TestAndReviewButton>
                      )}
                    </div>
                  )}
                  <SelectionHeader></SelectionHeader>
                </>
              ) : null}
              {formElements.map(form => form)}
            </EventSelectionGroup>
          ) : null}
        </>
      </NodeOuter>
      {node.reviewed && node.additionalResponses && node.additionalResponses.length ? (
        <AddMoreFieldButton onClick={handleAdditionalConfigsClick}></AddMoreFieldButton>
      ) : null}
      <Modal
        open={openAdditionalConfigs}
        onClose={onCloseModal}
        onAnimationEnd={() => scrollTo(0, 0)}
        center
        styles={{ modal: { padding: "0px", borderRadius: "16px", minWidth: "1100px", minHeight: "700px" } }}
      >
        <AdditionalConfigsModal
          closeModal={onCloseModal}
          addedKeys={addedKeys}
          data={node.additionalResponses}
          appIcon={node.image}
        />
      </Modal>
      {openGsheetModal ? (
        <GsheetModal
          open={openGsheetModal}
          data={gsheetFields}
          isTested={node.konnect_custom ? true : false}
          publishAppScript={publishAppScript}
          onCloseModal={closeGsheetModal}
          translator={t}
        />
      ) : null}
    </>
  )
}
