/**
 * TaskLogs reducer to handle the tasklogs api responses.
 */

import { GET_TASK_LOGS, GET_TASK_DETAILS, GET_LOGS } from "config/Constants"
import { fromJS } from "immutable"
const initState = {
  tasksList: [],
  taskDetails: [],
  logsList: [],
  Logs: []
}

const SUCCESS = "_SUCCESS"
const FAIL = "_FAIL"

const historyReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_TASK_LOGS + SUCCESS:
      let tasks = state.get("tasksList")
      const { searchQuery } = action.meta.previousAction.payload.request
      if (searchQuery.page_index === 1) {
        tasks = action.payload.konnects
      } else tasks = [...tasks, ...action.payload.konnects]

      const _tasks = fromJS(tasks)
      return state.set("tasksList", tasks)

    case GET_TASK_LOGS + FAIL:
      return state.set("tasksList", [])

    case GET_TASK_DETAILS + SUCCESS:
      return state.set("taskDetails", action.payload.tasks)

    case GET_TASK_DETAILS + FAIL:
      return state.set("taskDetails", [])
    case GET_LOGS + SUCCESS:
      let logs = state.get("logsList")
      const searchQuery1 = action.meta.previousAction.payload.request.searchQuery
      if (searchQuery1.page_index === 1 ) {
        logs = action.payload.task_logs
      } else logs = [...logs, ...action.payload.task_logs]
      // if (searchQuery1.page_index === 1 && task_log_type === "ERROR") {
      //   logs = action.payload.task_logs
      // } else logs = [...logs, ...action.payload.task_logs]
      const _tasks1 = fromJS(logs)
      return state.set("logsList", logs)

    case GET_LOGS + FAIL:
      return state.set("logsList", [])

  }

  return state
}

export default historyReducer
