import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import { SidebarWrapper, SearchWrapper, IconWrapper, AppsListWrapper, AppItem, SearchInput } from "./UI"
import { Box, Flex, Text, Image } from "rebass"
import { faFilter, faSearch } from "@fortawesome/free-solid-svg-icons"
import { KONNECT_FLOW_DRAGGABLE } from "config/Constants"
import Fuse from "fuse.js"
import px2vw from "utils/px2vw"
import { isEqual } from "lodash"
import ReactTooltip from "react-tooltip"
class SidebarClass extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            search: "",
        }
        this.fuseSearch = new Fuse([])
    }

    shouldComponentUpdate(prevProps, prevState) {
        let reRender = false
        reRender = !isEqual(prevState.search, this.state.search)
        if (reRender) return reRender

        reRender = !isEqual(prevProps.apps, this.props.apps)
        if (!isEqual(prevProps.apps, this.props.apps)) {
            this.fuseSearch = new Fuse(prevProps.apps, {
                keys: ["name"],
            })
        }
        if (reRender) return reRender

        reRender = !isEqual(prevProps.editorMeta, this.props.editorMeta)

        return reRender
    }

    render() {
        let { apps, t, editorMeta, onSearchItem } = this.props

        const { search } = this.state
        // if (search.trim() !== "") {
        //     const filtered = this.fuseSearch.search(search)
        //     apps = filtered.map(x => x.item)
        // }

        // const latest_apps = apps.filter(x => x.tag.includes("latest_apps"))
        // const most_favourite = apps.filter(x => x.tag.includes("most_favourite"))

        return (
            <>
                <SidebarWrapper hidden={editorMeta.sidebar.hidden}>
                    <SearchWrapper>
                        <IconWrapper
                            icon={faSearch}
                            style={{
                                justifyContent: "flex-start",
                            }}
                        />
                        <SearchInput
                            id="search"
                            name="search"
                            iconLeft={faSearch}
                            placeholder={t("konnect.sidebar.search_apps")}
                            style={{
                                flex: 1,
                            }}
                            onChange={onSearchItem}
                        />
                        <IconWrapper
                            icon={faFilter}
                            style={{
                                justifyContent: "flex-end",
                            }}
                        />
                    </SearchWrapper>
                    {apps.length > 0 ? (
                        apps.map((item, index) => {
                            return (<>
                                <Box sx={{ mt: px2vw(20), pb: px2vw(20) }}>
                                    <Text fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }} sx={{ fontWeight: "500", color: "#222222" }}>
                                        {item[0].label}
                                    </Text>
                                </Box>
                                <Flex
                                    sx={{
                                        flexFlow: "wrap",
                                        flexDirection: "row",
                                        overflowY: "auto",
                                        width: "100%",
                                        pt: px2vw(10),
                                    }}
                                >
                                    {item[0].apps.map((app, idx) => {
                                        const image = app.image_url
                                            ? `${app.image_url}`
                                            : "https://d33wubrfki0l68.cloudfront.net/4e5626a0b7db6b84c0b51f1c0cfd56490fc19b74/7a5ad/images/logo-fancy.svg"

                                        return (
                                            <React.Fragment key={app.id}>
                                                <AppItem
                                                    data-tip={app.name}
                                                    data-for={app.id + ""}
                                                    key={app.id}
                                                    // title={app.name}
                                                    name={app.name}
                                                    image={image}
                                                    draggable={true}
                                                    badge={app.account_count}
                                                    beta={app.status === "Beta"}
                                                    onDragStart={event => {
                                                        event.dataTransfer.setData(
                                                            KONNECT_FLOW_DRAGGABLE,
                                                            JSON.stringify({
                                                                id: app.id,
                                                                name: app.name,
                                                                image,
                                                                background: app.background_color,
                                                                associatedAccounts: app.account_count ? app.account_count : 1,
                                                                webhook_enabled: app.webhook_enabled,
                                                                webhook_instructions: app.webhook_instructions,
                                                                webhook_url: app.webhook_url,
                                                                provider: app.provider,
                                                                type: app.app_type,
                                                            })
                                                        )
                                                    }}
                                                ></AppItem>
                                                <ReactTooltip id={app.id + ""} effect="solid" place="bottom"></ReactTooltip>
                                            </React.Fragment>
                                        )
                                    })}
                                </Flex>
                            </>)
                        })

                    ) : null}
                </SidebarWrapper>
            </>
        )
    }
}

export const Sidebar = withTranslation()(SidebarClass)
