export * from "./Editor"
export * from "./Canvas"
export * from "./Node"
export * from "./NodeInner"
export * from "./Port"
export * from "./Ports"
export * from "./PortsGroup"
export * from "./Link"

export const noop = () => null

export const identity = val => val

export const SCALE_FACTOR = 5
export const MATRIX_PADDING = 5
export const NODE_PADDING = 3

export const rotate = (center, current, angle) => {
  const radians = (Math.PI / 180) * angle
  const cos = Math.cos(radians)
  const sin = Math.sin(radians)
  const x = cos * (current.x - center.x) + sin * (current.y - center.y) + center.x
  const y = cos * (current.y - center.y) - sin * (current.x - center.x) + center.y
  return { x, y }
}
