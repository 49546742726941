import React from "react"
import { Link as RouterLink, useHistory } from "react-router-dom"
import { Box } from "rebass"

import { DecoratorIcon, Text } from "src/components"

// : React.FC<
//   TextProps & {
//     color?: string
//     icon?: IconDefinition
//     to?: string
//     width?: string
//     onClick?: () => void
//     ariaLabel?: string
//     onKeyDown?: Function
//   }
// >

export const Link = ({
  width,
  height,
  color,
  alignItems,
  children,
  icon,
  to = "",
  onKeyDown = () => {},
  onClick = () => {},
  variant = "default",
  ariaLabel = "link",
  ...props
}) => {
  const history = useHistory()

  return (
    <Box
      tabIndex={0}
      onKeyDown={e => {
        if (e.keyCode === 13 || e.which === 13) {
          history.push(to)
        }
      }}
      sx={{
        width: width,
        height: height,
        position: "relative",
        alignItems: alignItems,
      }}
    >
      <RouterLink to={to} style={{ textDecoration: "none", color, width, outline: "none" }}>
        <Text
          as="span"
          size="a"
          variant={`anchor.${variant}`}
          {...props}
          onClick={onClick}
          sx={{ cursor: "pointer", textDecoration: "none" }}
        >
          {icon && <DecoratorIcon small icon={icon} />}
          {children}
        </Text>
      </RouterLink>
    </Box>
  )
}
