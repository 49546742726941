import React from "react"
export const svg = {
  collapse: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM377 271c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-87-87-87 87c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 167c9.4-9.4 24.6-9.4 33.9 0L377 271z" />
    </svg>
  ),
  expand: (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 10H7C7.55 10 8 9.55 8 9V1C8 0.45 7.55 0 7 0H1C0.45 0 0 0.45 0 1V9C0 9.55 0.45 10 1 10ZM1 18H7C7.55 18 8 17.55 8 17V13C8 12.45 7.55 12 7 12H1C0.45 12 0 12.45 0 13V17C0 17.55 0.45 18 1 18ZM11 18H17C17.55 18 18 17.55 18 17V9C18 8.45 17.55 8 17 8H11C10.45 8 10 8.45 10 9V17C10 17.55 10.45 18 11 18ZM10 1V5C10 5.55 10.45 6 11 6H17C17.55 6 18 5.55 18 5V1C18 0.45 17.55 0 17 0H11C10.45 0 10 0.45 10 1Z"
        fill="white"
      />
    </svg>
  ),
  collapse_2: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" />
    </svg>
  ),
  load: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M304 48c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zm0 416c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM48 304c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48zm464-48c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM142.9 437c18.7-18.7 18.7-49.1 0-67.9s-49.1-18.7-67.9 0s-18.7 49.1 0 67.9s49.1 18.7 67.9 0zm0-294.2c18.7-18.7 18.7-49.1 0-67.9S93.7 56.2 75 75s-18.7 49.1 0 67.9s49.1 18.7 67.9 0zM369.1 437c18.7 18.7 49.1 18.7 67.9 0s18.7-49.1 0-67.9s-49.1-18.7-67.9 0s-18.7 49.1 0 67.9z" />
    </svg>
  ),
  alert_1: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
    </svg>
  ),
  xMark: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
      <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
    </svg>
  ),
  newfolder: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11">
      <path
        id="noun-add-4024659"
        d="M110.5,35a5.5,5.5,0,1,0,5.5,5.5A5.506,5.506,0,0,0,110.5,35Zm0,2.755a.393.393,0,0,1,.393.393v1.96h1.96a.393.393,0,1,1,0,.786h-1.96v1.96a.393.393,0,1,1-.786,0v-1.96h-1.96a.393.393,0,1,1,0-.786h1.96v-1.96a.393.393,0,0,1,.393-.393Z"
        transform="translate(-105 -35)"
      />
    </svg>
  ),
  edit_1: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11">
      <path
        id="Path_4849"
        data-name="Path 4849"
        d="M249.216,169.461a1.2,1.2,0,0,0-1.693,0l-.315.315a.494.494,0,0,0,0,.7L248.735,172a.494.494,0,0,0,.7,0l.315-.315a1.2,1.2,0,0,0,0-1.693Zm-3.376,1.683-6.52,6.52a.754.754,0,0,0-.221.533v1.6a.312.312,0,0,0,.312.312h1.6a.754.754,0,0,0,.533-.221l6.52-6.52h0a.393.393,0,0,0,0-.555l-1.67-1.67a.393.393,0,0,0-.555,0Z"
        transform="translate(-239.099 -169.111)"
      />
    </svg>
  ),
  delete_1: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 11">
      <path
        id="Path_3573"
        data-name="Path 3573"
        stroke="none"
        d="M204.594,88.157a.4.4,0,1,1,0,.8h-.006a.084.084,0,0,0-.085.08l-.294,7.748a1.081,1.081,0,0,1-1.088,1.02h-5.236a1.082,1.082,0,0,1-1.088-1.02l-.294-7.748a.084.084,0,0,0-.085-.08h-.006a.4.4,0,1,1,0-.8h1.994a.115.115,0,0,0,.1-.076l.133-.455a1.205,1.205,0,0,1,1.122-.824h1.475a1.205,1.205,0,0,1,1.122.824l.133.455a.113.113,0,0,0,.1.076Zm-4.418,2.232v5.42a.327.327,0,0,0,.654,0v-5.42a.327.327,0,0,0-.654,0Zm-1.8.009.164,5.42a.327.327,0,0,0,.654-.019l-.164-5.42a.327.327,0,0,0-.654.019Zm3.6-.019-.164,5.42a.327.327,0,0,0,.654.019l.164-5.42a.327.327,0,0,0-.654-.019Zm-2.554-2.223h2.163a.057.057,0,0,0,.061-.076l-.069-.236a.4.4,0,0,0-.335-.246h-1.475a.4.4,0,0,0-.335.246l-.069.236A.057.057,0,0,0,199.422,88.157Z"
        transform="translate(-196.003 -86.802)"
      />
    </svg>
  ),
  folder: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 25">
      <g id="Group_2574" data-name="Group 2574" transform="translate(3686.45 -4575.199)">
        <path
          id="Path_3561"
          data-name="Path 3561"
          d="M365.689,80.314V103.2H335.55v-25h5.3l2.575,2.115Z"
          transform="translate(-4022 4497)"
          fill="#b7cbff"
        />
        <path
          id="Path_3562"
          data-name="Path 3562"
          d="M365.691,120.672H335.554l5.861-18.541h30.137Z"
          transform="translate(-4022.003 4479.525)"
          fill="#152f73"
        />
      </g>
    </svg>
  ),
  minimize: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM224 160c6.7 0 13 2.8 17.6 7.7l104 112c6.5 7 8.2 17.2 4.4 25.9s-12.5 14.4-22 14.4H120c-9.5 0-18.2-5.7-22-14.4s-2.1-18.9 4.4-25.9l104-112c4.5-4.9 10.9-7.7 17.6-7.7z" />
    </svg>
  ),
  maximize: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M384 480c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0zM224 352c-6.7 0-13-2.8-17.6-7.7l-104-112c-6.5-7-8.2-17.2-4.4-25.9s12.5-14.4 22-14.4l208 0c9.5 0 18.2 5.7 22 14.4s2.1 18.9-4.4 25.9l-104 112c-4.5 4.9-10.9 7.7-17.6 7.7z" />
    </svg>
  ),
  unselect: (
    <svg fill="#152f73" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M384 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H384zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" />
    </svg>
  ),
  select: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
    </svg>
  ),
  minus: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM152 232H296c13.3 0 24 10.7 24 24s-10.7 24-24 24H152c-13.3 0-24-10.7-24-24s10.7-24 24-24z" />
    </svg>
  ),
  moveFolder: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path d="M64 32C64 14.3 49.7 0 32 0S0 14.3 0 32v96V384c0 35.3 28.7 64 64 64H256V384H64V160H256V96H64V32zM288 192c0 17.7 14.3 32 32 32H544c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H445.3c-8.5 0-16.6-3.4-22.6-9.4L409.4 9.4c-6-6-14.1-9.4-22.6-9.4H320c-17.7 0-32 14.3-32 32V192zm0 288c0 17.7 14.3 32 32 32H544c17.7 0 32-14.3 32-32V352c0-17.7-14.3-32-32-32H445.3c-8.5 0-16.6-3.4-22.6-9.4l-13.3-13.3c-6-6-14.1-9.4-22.6-9.4H320c-17.7 0-32 14.3-32 32V480z" />
    </svg>
  ),
  xmark: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
      <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
    </svg>
  ),
  add: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
    </svg>
  ),
  sync: (
    <svg version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg">
      <path d="m350 17.5c-69.621 0-136.39 27.656-185.62 76.883-49.227 49.23-76.883 116-76.883 185.62s27.656 136.39 76.883 185.62c49.23 49.227 116 76.883 185.62 76.883s136.39-27.656 185.62-76.883c49.227-49.23 76.883-116 76.883-185.62 0-46.078-12.129-91.344-35.168-131.25-23.039-39.906-56.176-73.043-96.082-96.082-39.906-23.039-85.172-35.168-131.25-35.168zm0 402.5c-40.238-0.09375-78.492-17.5-105-47.773v30.273h-35v-87.5h87.5v35h-25.551c19.039 21.156 45.848 33.676 74.289 34.695 28.441 1.0195 56.078-9.5469 76.582-29.285 20.504-19.738 32.117-46.949 32.18-75.41h35c0 37.129-14.75 72.738-41.004 98.996-26.258 26.254-61.867 41.004-98.996 41.004zm140-175h-87.5v-35h25.727c-19.02-21.242-45.859-33.836-74.355-34.887-28.492-1.0508-56.188 9.5273-76.719 29.312-20.535 19.781-32.141 47.062-32.152 75.574h-35c0.054688-38.121 15.652-74.57 43.191-100.93 27.539-26.359 64.637-40.352 102.72-38.742 38.086 1.6133 73.871 18.684 99.086 47.277v-30.102h35z" />
    </svg>
  ),
  save: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <g id="Group_2598" data-name="Group 2598" transform="translate(3083 -6289)">
        <rect
          id="Rectangle_1251"
          data-name="Rectangle 1251"
          width="32"
          height="32"
          rx="16"
          transform="translate(-3083 6289)"
          fill="#f7c545"
        />
        <g id="noun-send-file-4560643" transform="translate(-3230.024 6241.497)">
          <path
            id="Path_4850"
            data-name="Path 4850"
            d="M157.778,65.585V57.641a.391.391,0,0,1,.4-.388h3.8a.394.394,0,0,1,.263.1l1.292,1.181h1.806a30.1,30.1,0,0,0-2.245-2.1A1.651,1.651,0,0,0,161.974,56h-3.8a1.647,1.647,0,0,0-1.649,1.638v7.944a1.643,1.643,0,0,0,1.649,1.638h.966v-1.25h-.966a.391.391,0,0,1-.4-.388Z"
            transform="translate(0 0)"
            fill="#152f73"
          />
          <path
            id="Path_4851"
            data-name="Path 4851"
            d="M280.294,171.23l-2.044-1.862a1.606,1.606,0,0,0-1.11-.431h-3.807a1.646,1.646,0,0,0-1.643,1.637v7.944a1.646,1.646,0,0,0,1.643,1.637h5.845a1.646,1.646,0,0,0,1.643-1.637v-6.081a1.66,1.66,0,0,0-.527-1.206Zm-1.856,4.45-1.756,1.5a.371.371,0,0,1-.608-.281v-.681h-1.919a.216.216,0,0,1-.213-.219v-1.181a.211.211,0,0,1,.213-.213h1.919v-.688a.371.371,0,0,1,.608-.281l1.756,1.488a.36.36,0,0,1,0,.556Z"
            transform="translate(-111.297 -109.151)"
            fill="#152f73"
          />
        </g>
      </g>
    </svg>
  ),
  success: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g id="Group_1900" data-name="Group 1900" transform="translate(8895 -5072)">
        <path
          id="Path_3717"
          data-name="Path 3717"
          d="M469,1068a8,8,0,1,1,8,8A8,8,0,0,1,469,1068Z"
          transform="translate(-9364 4012)"
        />
        <path
          id="Path_3718"
          data-name="Path 3718"
          d="M100.667,91.833l-1.929-1.6L98,91.1l2.738,2.259L106,88.144l-.818-.785Z"
          transform="translate(-8989 4989.641)"
          fill="#fff"
        />
      </g>
    </svg>
  ),
  edit: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <g id="Group_1842" data-name="Group 1842" transform="translate(3083 -6289)">
        <rect
          width="32"
          height="32"
          id="Rectangle_1251"
          data-name="Rectangle 1251"
          rx="16"
          transform="translate(-3083 6289)"
          fill="#1e419d"
        />
        <path
          id="Path_3375"
          data-name="Path 3375"
          d="M251.139,169.528a1.425,1.425,0,0,0-2.015,0l-.375.375a.588.588,0,0,0,0,.831l1.817,1.817a.588.588,0,0,0,.831,0l.375-.375a1.425,1.425,0,0,0,0-2.015Zm-4.018,2-7.759,7.76a.9.9,0,0,0-.263.635v1.9a.372.372,0,0,0,.372.372h1.9a.9.9,0,0,0,.634-.263l7.759-7.76h0a.467.467,0,0,0,0-.661l-1.987-1.988a.467.467,0,0,0-.661,0Z"
          transform="translate(-3312.644 6129.344)"
          fill="#fff"
        />
      </g>
    </svg>
  ),
  delete: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <g id="Group_1841" data-name="Group 1841" transform="translate(3147 -6289)">
        <rect
          width="32"
          height="32"
          id="Rectangle_1250"
          data-name="Rectangle 1250"
          rx="16"
          transform="translate(-3147 6289)"
          fill="#ea4f4f"
        />
        <path
          id="Path_3336"
          data-name="Path 3336"
          d="M207.11,88.594a.527.527,0,1,1,0,1.054H207.1a.11.11,0,0,0-.11.106L206.612,100a1.412,1.412,0,0,1-1.406,1.349h-6.77A1.412,1.412,0,0,1,197.03,100l-.381-10.245a.11.11,0,0,0-.11-.106h-.007a.527.527,0,1,1,0-1.054h2.579a.148.148,0,0,0,.134-.1l.172-.6a1.562,1.562,0,0,1,1.45-1.09h1.908a1.562,1.562,0,0,1,1.45,1.09l.172.6a.147.147,0,0,0,.134.1ZM201.4,91.545v7.167a.423.423,0,0,0,.846,0V91.545a.423.423,0,0,0-.846,0Zm-2.327.012.212,7.167a.423.423,0,0,0,.846-.025l-.212-7.167a.423.423,0,0,0-.846.025Zm4.655-.025-.212,7.167a.423.423,0,0,0,.846.025l.212-7.167a.423.423,0,0,0-.846-.025Zm-3.3-2.939h2.8a.075.075,0,0,0,.079-.1l-.09-.313a.512.512,0,0,0-.433-.325h-1.908a.513.513,0,0,0-.433.325l-.09.313A.075.075,0,0,0,200.423,88.594Z"
          transform="translate(-3332.821 6210.925)"
          fill="#fff"
        />
      </g>
    </svg>
  ),
  error: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g id="Group_1899" data-name="Group 1899" transform="translate(8895 -5104)">
        <path
          id="Path_3715"
          data-name="Path 3715"
          d="M469,1068a8,8,0,1,1,8,8A8,8,0,0,1,469,1068Z"
          transform="translate(-9364 4044)"
          // fill="#b8bbc1"
        />
        <path
          id="noun-error-1582579"
          d="M77.682,7.682A4.508,4.508,0,1,0,74.5,9,4.489,4.489,0,0,0,77.682,7.682ZM75.107,6.234a.607.607,0,0,0-.609-.609.614.614,0,0,0-.617.609.621.621,0,0,0,.617.617A.614.614,0,0,0,75.107,6.234ZM74.5,5.008a.429.429,0,0,1-.411-.411l-.206-1.835a.627.627,0,0,1,.617-.617.614.614,0,0,1,.609.617l-.2,1.835A.429.429,0,0,1,74.5,5.008Z"
          transform="translate(-8961.498 5107.502)"
          fill="#fff"
          stroke="#b8bbc1"
          stroke-width="0.2"
          fillRule="evenodd"
        />
      </g>
    </svg>
  ),
}
