import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { PortWrapper, PortDefault } from "../"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import { Icon, Preloader, Span } from "src/components"
import { Modal } from "react-responsive-modal"
import { Box, Flex } from "rebass"
import ReactTooltip from "react-tooltip"
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons"
import {
  AppInfo,
  ConnectorOuter,
  ConnectorPortOuter,
  ConnectorPortInput,
  ConnectorPortText,
  ConnectorPortLabel,
  NodeOuter,
  NodeButtonOutline,
  SelectionHeader,
  EventSelectionGroup,
  CopyToClipboard,
  NodeInfoHelper,
} from "../../../UI"
import { Dropdown } from "src/components/Konnect/DropdownMenu"
import { Checkbox } from "src/components/Konnect/Checkbox"
import { CommonOuter } from "./CommonOuter"
import { ToastContainer, toast } from "react-toastify"
import { NodeInnerDefault } from "./NodeInner.default"

let hookTimer = null
const WrappedPort = props => {
  return (
    <PortWrapper
      config={props.config}
      offset={props.offset}
      selected={props.selected}
      selectedLink={props.selectedLink}
      hoveredLink={props.hoveredLink}
      hovered={props.hovered}
      node={props.node}
      port={props.port}
      Component={props.PortDefault}
      onPortPositionChange={props.onPortPositionChange}
      onLinkStart={props.onLinkStart}
      onLinkMove={props.onLinkMove}
      onLinkComplete={props.onLinkComplete}
      onLinkCancel={props.onLinkCancel}
      chart={props.chart}
      pType={props.pType}
      active={props.active}
    />
  )
}
export const WebhookInnerDefault = ({
  node,
  chart,
  config,
  offset,
  selected,
  selectedLink,
  hoveredLink,
  hovered,
  onPortPositionChange,
  onLinkStart,
  onLinkMove,
  onLinkComplete,
  onLinkCancel,
  editorActions,
  Links,
}) => {
  useEffect(() => {
    return () => hookTimer && clearInterval(hookTimer)
  }, [])

  // useEffect(() => {
  //   setKonnectName(editorState.konnect_name)
  // }, [editorState])

  const { t } = useTranslation()

  const [event, setEvent] = useState(!!node.appEvent)
  const [eventConfig, setEventConfig] = useState(!!node.appEventConfiguration)
  const [eventSequence, setEventSequence] = useState(null)

  const [hookUrl, setHookUrl] = useState("")
  const [openInstructions, setOpenInstructions] = useState(false)
  const [IsDeleteShown, setIsDeleteShown] = useState(false)
  const [captureWebHook, setCaptureWebHook] = useState(false)
  const [webhookLinked, setWebhookLinked] = useState(false)
  const [nodeReviewed, setNodeReviewed] = useState(node.tested ? node.reviewed : null)
  const [nodeTested, setNodeTested] = useState(node.tested ? node.tested : null)
  const [expandedIndex, setExpandedIndex] = useState(-1)
  const [expandedParents, setExpandedParents] = useState([])
  const [levelIndex, setLevelIndex] = useState(0)
  const [connected, setConnected] = useState(["firstname", "lastname", "email1"])
  const [searchEvents, setSearchEvents] = useState("")
  const ports = chart.ports.filter(x => x.node === node.id)
  const selectedEvent = !node.appEvent
    ? t("konnect.sidebar.choose_trigger")
    : node.appEvents.find(x => x.id === node.appEvent).name

  const selectedTrigger = !node.appEventConfiguration
    ? t("konnect.sidebar.customize_form_partial")
    : node.appEventConfigurations.find(x => x.id === node.appEventConfiguration).service_name

  const nodeIdx = chart.nodes.findIndex(x => x.nodeId === node.nodeId)
  const source = nodeIdx === 0 || chart.nodes.length > 2
  // nodeIdx === 0 || node.type === "source" || node.type === "both" || (nodeIdx !== 0 && chart.nodes.length > 2)
  const target = nodeIdx !== 0
  // && (node.type === "target" || node.type === "both")

  let hasSequence =
    node.appEventConfigurations && node.appEventConfigurations.filter(x => x.sequence !== 0).length ? true : false

  let configForms = []

  if (hasSequence) {
    configForms = node.appEventConfigurations.slice(0, node.appEventSequences.length + 1)
  }

  let formElements = []

  let sections = []
  const Section = ({ info, setExpanded, index, isExpanded, parent = [] }) => {
    return (
      <>
        <Flex
          width="100%"
          height="40px"
          sx={{
            background: "#F3F7FF",
            borderRadius: "8px",
            alignItems: "center",
            px: "10px",
            mb: "10px",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={() => {
            editorActions.expandCollapseNode({
              nodeId: node.nodeId,
              sectionId: info.id,
            })
          }}
        >
          {info.label}
          <Icon icon={isExpanded ? faChevronUp : faChevronDown} size="lg" />
        </Flex>
      </>
    )
  }

  formElements = []
  if (node.configResponses) {
    Object.keys(node.configResponses).map(q => {
      const x = node.configResponses[q]
      const __key = `${x.id}_${x.parent}${x.uiKey}`

      const isActive = !!Links.find(c => {
        return x.port && (c.to.port === x.port.id || c.from.port === x.port.id)
      })

      if (
        x.type === "leaf" && //isActive
        //  ||
        (x.isOpen || x.level === 0)
      ) {
        let disable = !!chart.links.find(c => {
          return x.port && c.to.port === x.port.id
        })
        let ConnectorPort = x.key_value_type === "text" ? ConnectorPortText : ConnectorPortInput
        let data = []

        formElements.push(
          <>
            <ConnectorPortLabel>{x.label}</ConnectorPortLabel>
            <ConnectorOuter key={`${x.app_id}_${__key}`} type={x.key_value_type}>
              {target ? (
                <ConnectorPortOuter placement="left" type={x.key_value_type}>
                  {
                    <WrappedPort
                      pType="target"
                      config={config}
                      offset={offset}
                      selected={selected}
                      selectedLink={selectedLink}
                      hoveredLink={hoveredLink}
                      hovered={hovered}
                      node={node}
                      port={x}
                      Component={PortDefault}
                      onPortPositionChange={onPortPositionChange}
                      onLinkStart={onLinkStart}
                      onLinkMove={onLinkMove}
                      onLinkComplete={onLinkComplete}
                      onLinkCancel={onLinkCancel}
                      chart={chart}
                      // active={isActive}
                    />
                  }
                </ConnectorPortOuter>
              ) : null}

              {/* <span data-for={`${x.app_id}_${x.key}`} data-tip={x.label ? x.label : ""} data-iscapture="true">
                <ConnectorPort
                  rows={4}
                  style={{
                    width: px2vw(208),
                    marginRight: px2vw(8),
                  }}
                  placeholder={x.label}
                  key={`${x.app_id}_${x.key}`}
                  // disabled={x.key_value_type === "display" || disable}
                  value={x.label ? x.label : ""}
                  disabled
                />
              </span> */}
              <ConnectorPort
                placeholder={x.label}
                style={{
                  width: "100%",
                }}
                key={`${x.app_id}_${x.key}2`}
                disabled={x.key_value_type === "display" || disable}
                onChange={e => {
                  editorActions.onPortTextChange({
                    nodeId: node.nodeId,
                    portId: x.portId,
                    config_key: x.key,
                    input: e.target.value,
                    webhook: true,
                  })
                }}
                onMouseDown={e => {
                  e.stopPropagation()
                }}
                value={x.value ? x.value : ""}
                data-for={`${x.app_id}_${x.key}2`}
                data-tip={x.value ? x.value : ""}
                data-iscapture="true"
              />
              <ReactTooltip
                id={`${x.app_id}_${x.key}`}
                place={"bottom"}
                type={"dark"}
                effect={"solid"}
                multiline={true}
                overridePosition={({ left, top }) => {
                  return { left: left / chart.scale, top: top / chart.scale }
                }}
              />
              <ReactTooltip
                id={`${x.app_id}_${x.key}2`}
                place={"bottom"}
                type={"dark"}
                effect={"solid"}
                multiline={true}
                overridePosition={({ left, top }) => {
                  return { left: left / chart.scale, top: top / chart.scale }
                }}
              />

              {source ? (
                <ConnectorPortOuter placement="right" type={x.key_value_type}>
                  <WrappedPort
                    pType="source"
                    config={config}
                    offset={offset}
                    selected={selected}
                    selectedLink={selectedLink}
                    hoveredLink={hoveredLink}
                    hovered={hovered}
                    node={node}
                    port={x}
                    Component={PortDefault}
                    onPortPositionChange={onPortPositionChange}
                    onLinkStart={onLinkStart}
                    onLinkMove={onLinkMove}
                    onLinkComplete={onLinkComplete}
                    onLinkCancel={onLinkCancel}
                    chart={chart}
                    //active={isActive}
                  />
                </ConnectorPortOuter>
              ) : null}
            </ConnectorOuter>
          </>
        )
      } else if (x.isAvailable || x.isOpen || x.level === 0) {
        // <ConnectorOuter  type={x.key_value_type}></ConnectorOuter>
        formElements.push(<Section info={x} isExpanded={x.isOpen} key={`${x.app_id}_${__key}`}></Section>)
      }
    })
  }

  const handleHookCapture = () => {
    if (!captureWebHook) {
      setCaptureWebHook(true)
      const hitCapture = () => {
        editorActions
          .captureWebhookResponse({
            nodeId: node.nodeId,
            node,
            editorState: chart,
            konnect_name: chart.konnect_name,
          })
          .then(data => {
            if (data.payload.message !== "WAITING") {
              clearInterval(hookTimer)
              setCaptureWebHook(false)
            }
          })
      }

      if (!hookTimer) {
        hitCapture()
      }

      hookTimer = setInterval(hitCapture, 10000)
    }
  }

  //let nodeReviewed = node.tested ? node.reviewed : null

  useEffect(() => {
    if (node.appType === "WEBHOOK") {
      const webhookLinked = chart.links ? chart.links.filter(link => link.from.node === node.nodeId).length > 0 : false
      setWebhookLinked(webhookLinked)
    }
  }, [chart.links])

  useEffect(() => {
    setNodeTested(node.tested ? node.tested : null)
    setNodeReviewed(node.tested ? node.reviewed : null)
  }, [node])

  return (
    <>
      <CommonOuter node={node} reviewed={nodeReviewed} nodeTested={nodeTested} />

      {/* giving info about test reponse  */}

      {/* every  thing is here === seth ji  */}
      <NodeOuter
        onMouseEnter={() => setIsDeleteShown(true)}
        onMouseLeave={() => setIsDeleteShown(false)}
        isFirst={!nodeIdx}
        reviewed={nodeReviewed}
      >
        {/* giving info about app name or first line  */}
        <AppInfo
          title={node.name}
          subtitle={node.account}
          image={node.image}
          className="DraggableHandle"
          background={node.background}
          hasDelete={IsDeleteShown && (nodeIdx !== 0 || chart.nodes.length === 1)}
          onDelete={() => {
            clearInterval(hookTimer)
            setCaptureWebHook(false)
            if (node.tested || node.konnect_activity_id) {
              editorActions
                .onDeleteNodeV2({
                  konnect_id: chart.nodes[0].konnect_id,
                  konnect_activity_id: node.konnect_activity_id,
                  canvas_json: chart,
                  nodeId: node.nodeId,
                })
                .then(data => {
                  editorActions.onDeleteNode({
                    nodeId: node.nodeId,
                  })
                })
            } else {
              editorActions.onDeleteNode({
                nodeId: node.nodeId,
              })
            }
          }}
        ></AppInfo>

        {node.appEvents.length ? (
          <EventSelectionGroup>
            <SelectionHeader>{t("konnect.sidebar.choose_trigger")}</SelectionHeader>
            <Dropdown
              searchable={true}
              searchValue={searchEvents}
              onFilterChange={text => {
                setSearchEvents(text)
              }}
              inputPlaceholder={!searchEvents ? "Search field" : ""}
              headerTitle={t("konnect.sidebar.select_trigger_action_dropdown_header")}
              items={node.appEvents}
              renderKey="name"
              selected={node.appEvent ? node.appEvents.find(x => x.id === node.appEvent).name : null}
              onChange={selected => {
                editorActions.getAppEventConfig({
                  nodeId: node.nodeId,
                  appId: node.appId,
                  eventId: selected.id,
                  source: nodeIdx === 0,
                  type: selected.side === "left" ? "source" : selected.side === "right" ? "target" : "both",
                })
                setEvent(false)
                setSearchEvents("")
              }}
              onChangeState={isOpen => {
                editorActions.setConfigMenuState({ isOpen })
              }}
            />
          </EventSelectionGroup>
        ) : null}

        {node.provider === "webhook" ? (
          <EventSelectionGroup>
            <Checkbox
              title={t("konnect.sidebar.basic_auth")}
              enabled={!!node.enabled}
              onChange={checked => {
                editorActions.toggleBasicAuth({
                  enabled: checked,
                  node,
                  nodeId: node.nodeId,
                  appId: node.appId,
                  source: nodeIdx === 0,
                })
              }}
            ></Checkbox>
          </EventSelectionGroup>
        ) : null}
        {node.enabled && (
          <>
            <SelectionHeader></SelectionHeader>

            {/* when we click on checkbox, it will give info about apis key and secret */}
            <ConnectorOuter>
              <ConnectorPortInput
                id="search"
                name="search"
                placeholder={t("konnect.sidebar.api_key")}
                style={{
                  flex: 1,
                }}
                value={node.apiKey}
                onMouseDown={e => {
                  e.stopPropagation()
                }}
                onChange={e => {
                  editorActions.onWebhookAuthChange({
                    nodeId: node.nodeId,
                    type: "apiKey",
                    input: e.target.value,
                  })
                }}
              />
            </ConnectorOuter>
            <ConnectorOuter>
              <ConnectorPortInput
                id="search"
                name="search"
                placeholder={t("konnect.sidebar.api_token")}
                style={{
                  flex: 1,
                }}
                value={node.secretKey}
                onMouseDown={e => {
                  e.stopPropagation()
                }}
                onChange={e => {
                  editorActions.onWebhookAuthChange({
                    nodeId: node.nodeId,
                    type: "secretKey",
                    input: e.target.value,
                  })
                }}
              />
            </ConnectorOuter>
          </>
        )}

        {/* giving info about url and button */}

        <EventSelectionGroup>
          <SelectionHeader>{t("konnect.sidebar.webhook_url")}</SelectionHeader>
          <CopyToClipboard
            buttonText="Copy"
            text={node.webhookUrl}
            onClick={() => {
              let input = document.createElement("input")
              input.value = node.webhookUrl
              document.body.appendChild(input)
              input.select()
              document.execCommand("copy")
              document.body.removeChild(input)
              toast.info(t("konnect.sidebar.webhook_url_toast"), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              })
            }}
          />
        </EventSelectionGroup>

        {/* what is this, can be emitted*/}

        {node.provider.toLowerCase() !== "webhook" ? (
          <NodeInfoHelper
            onClick={() => {
              setOpenInstructions(true)
            }}
          >
            Webhook Instructions - Click <span style={{ color: "#152F73", textDecoration: "underline" }}>Here</span>{" "}
            <Icon color="#152F73" size="sm" icon={faExternalLinkAlt} />
          </NodeInfoHelper>
        ) : (
          <NodeInfoHelper></NodeInfoHelper>
        )}

        <Flex>
          <Box>
            <NodeButtonOutline onClick={handleHookCapture} disabled={captureWebHook || webhookLinked}>
              Capture webhook response{" "}
            </NodeButtonOutline>
          </Box>
          <Box>
            <Preloader loading={captureWebHook} position="static" />
          </Box>
        </Flex>
        <EventSelectionGroup></EventSelectionGroup>

        {/* this is form elements when we give inputs to url and we get it by here in form of form  */}

        {formElements.length ? (
          <EventSelectionGroup>
            <SelectionHeader>{t("konnect.sidebar.webhook_response")}</SelectionHeader>
            {formElements.map(form => form)}
          </EventSelectionGroup>
        ) : null}

        <Modal
          open={openInstructions}
          onClose={() => setOpenInstructions(false)}
          center
          styles={{ modal: { padding: "40px", paddingTop: "50px", minHeight: "300px" } }}
        >
          {/* <Instructions dangerouslySetInnerHTML={{ __html: node.instructions }}></Instructions> */}
          {node.instructions &&
            node.instructions.trim() !== "" &&
            JSON.parse(node.instructions).map(item => (
              <Span sx={{ color: "#7E7E7E", "& a": { color: "#F7C545", textDecoration: "none" } }}>
                - <label dangerouslySetInnerHTML={{ __html: item }}></label>
                <br />
              </Span>
            ))}
        </Modal>
      </NodeOuter>
    </>
  )
}
