import { makeRequestActionCreator } from 'utils/redux'
import { GET_TEAM_USERS_LIST, ADD_TEAM_MEMBER,UPDATE_TEAM_MEMBER,DELETE_TEAM_MEMBER } from 'config/Constants'

export const getTeamUsersList = () => dispatch => {
    const request = makeRequestActionCreator(GET_TEAM_USERS_LIST)

    return dispatch(
        request({
            url: 'api/v1/users/list_team_users',
            method: 'GET'
        })
    )
}

export const addTeamMember = (data) => dispatch => {
    const request = makeRequestActionCreator(ADD_TEAM_MEMBER)

    return dispatch(
        request({
            url: 'api/v1/users/add_team_user',
            method: 'POST',
            data: { first_name: data.firstName, last_name: data.lastName, email: data.email, access: data.access }
        })
    )
}


export const updateTeamMember = data => dispatch => {
    const request = makeRequestActionCreator(UPDATE_TEAM_MEMBER)
  
    return dispatch(
      request({
        url: "api/v1/users/update_team_user",
        method: "POST",
        data: {
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          sub_account_id: data.sub_account_id,
          sub_account_access: data.access,
        },
      })
    )
  }
  
  export const deleteTeammember = id => dispatch => {
    let url = `api/v1/users/remove_team_user?sub_account_id=`
  
    const request = makeRequestActionCreator(DELETE_TEAM_MEMBER)
    return dispatch(
      request({
        url: url + id,
        method: "GET",
      })
    )
  }
