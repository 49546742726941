import UnlimitedSteps from "assets/images/features/unlimited-steps.png"
import VisualizeEverything from "assets/images/features/visualize-everything.png"
import IntegrationLibrary from "assets/images/features/integration-library.png"
import SimpleAutomation from "assets/images/features/simple-automation.png"
import PlatformForEveryone from "assets/images/features/platform-for-everyone.png"
import SeamlessAppsIntegration from "assets/images/features/seamless-apps-integration.png"

export const chinese = {
  translation: {
    common: {
      app: "康内茨",
    },
    header: {
      topNavigations: {
        tipsAndTricks: "技巧和窍门",
        pricing: "价钱",
        blogs: "博客",
        helpCenter: "帮助中心",
      },
      searchAppTextPlaceholder: "在这里搜索...",
      createKonnectzBtnLabel: "创建连接",
      taskhistory: "任务历史",
      success: "成功",
      error: "错误",
      notask: "未找到任务",
      tryagain: "调整过滤器，然后重试"
    },
    errorMessages: {
      SENT_RESET_INSTRUCTIONS_SUCCESS: "密码重置说明已通过电子邮件发送，请检查您的电子邮件。",
      SENT_RESET_INSTRUCTIONS_FAIL: "未经授权的请求！",
      VERIFY_EMAIL_FAIL: "抱歉，无法确认您的电子邮件。",
      PASSWORD_NOT_UPDATED: "抱歉，未能更新您的帐户",
      GET_FORGOT_PASSWORD_LINK_SUCCESS: "",
      GET_FORGOT_PASSWORD_LINK_FAIL: "发送密码说明失败。请过一段时间再试试！",
      AUTH_SIGN_UP_FAIL: "出问题了。请过一段时间再试试！",
      somethingWentWrong: "出问题了。请过一段时间再试试！",
    },
    successMessages: {
      PASSWORD_UPDATED: "密码已更新。请使用您的新密码登录！",
    },
    history:{
      task:{
        task: "任务",
        tasks: "任务",
        taskdate: "任务日期：",
        user: "用户："
      }
  },
    auth: {
      signup: {
        createPassword: {
          title: "创建密码",
          subtitle: "输入两次以确认",
          passwordErrorMsg: "无效的密码 ！",
          confirmPasswordErrorMsg: "无效的密码 ！",
          passwordMatchingErrorMsg: "两个密码不匹配！",
          continueBtnLabel: "继续",
          createPasswordPlaceholder: "创建密码",
          confirmPasswordPlaceholder: "确认密码",
          passwordRule:
            "提示：您的密码必须在 8 到 15 个字符之间，并且必须至少包含一个大写字母、一个小写字母、一个数字和一个特殊字符。",
          passwordRule1: "您的密码必须介于 8 到 15 个字符之间。",
          passwordRule2: "您的密码必须至少包含一个大写字母。",
          passwordRule3: "您的密码必须至少包含一位数字。",
          passwordRule4: "您的密码必须至少包含一个特殊字符。",
        },
        verificationSent: {
          title: "确认",
          subtitle: "确认电子邮件地址",
          verificationText1: "我们发送了验证链接",
          verificationText2: "，打开电子邮件并验证您的电子邮件地址。",
          didntReceiveEmailText: "没收到邮件？",
          resendText: "重发",
        },
        verifySuccess: {
          title: "验证完成",
          verifyFailedTitle: "验证失败",
        },
        signupSuccess: {
          firstName: "名",
          firstNameErrorMsg: "名字是必需的！",
          lastName: "姓",
          lastNameErrorMsg: "姓氏是必需的！",
          verifyErrorMsg: "出问题了。如果您的帐户已经过验证，请尝试登录。",
        },
        title: "创建您的帐户",
        subtitle: "嘿，欢迎",
        verifyingMessage: "验证你",
        emailInvalid: "电子邮件无效！",
        emailExists: "您输入的电子邮件地址已经注册",
        teamSignup: "以团队形式报名？",
        continueBtnLabel: "继续",
        alreadyHaveAccountText: "有一个账户 ？",
        loginBtnLabel: "登入",
        emailPlaceholder: "输入电子邮件",
        features: {
          feature1: {
            title: "简单的自动化",
            image: SimpleAutomation,
            description: "使用我们的可视化连接构建器在几分钟内简单轻松地构建复杂的工作流程",
          },
          feature2: {
            title: "适合所有人的平台",
            image: PlatformForEveryone,
            description:
              "不是仅适用于程序员的常规自动化工具，任何人都可以使用 KonnectzIT 构建自动化",
          },
          feature3: {
            title: "无缝应用集成",
            image: SeamlessAppsIntegration,
            description: "从我们的集成库和自动化的日常任务中连接无限的应用程序",
          },
        },
      },
      login: {
        title: "登录您的帐户",
        subtitle: "嘿，欢迎回来！",
        emailPlaceholder: "输入电子邮件",
        emailInvalid: "不合规电邮 ！",
        passwordRequired: "密码是必需的 ！",
        passwordPlaceholder: "输入密码",
        rememberMe: "记得我",
        loginBtnLabel: "现在登录",
        forFirstTimeSignup: "第一次？",
        forgotPasswordLbl: "忘记密码",
        alreadyHaveAccountText: "没有帐户？",
        clickingContinueText: "点击继续，即表示您同意我们的",
        termsAndConditionsText: "条款和条件。",
        signupNowText: "注册",
        features: {
          feature1: {
            title: "无限步数",
            image: UnlimitedSteps,
            description: "连接多个应用程序并构建无限步骤以实现无缝自动化。",
          },
          feature2: {
            title: "可视化一切",
            image: VisualizeEverything,
            description: "使用可视化连接构建器轻松构建您的工作流程并设想自动化。",
          },
          feature3: {
            title: "集成库",
            image: IntegrationLibrary,
            description: "收集云应用程序以在它们之间合并和发送数据以节省您的时间。",
          },
        },
        loginFailMsg: "用户名或密码无效！",
        captchaErrorMsg: "验证码错误",
      },
      forgot: {
        title: "忘记密码",
        forgtoBtnLabel: "获取忘记密码链接",
        successSubTitle: "邮件已发送",
        features: {
          feature1: {
            title: "简单的自动化",
            image: SimpleAutomation,
            description: "使用我们的可视化连接构建器在几分钟内简单轻松地构建复杂的工作流程",
          },
          feature2: {
            title: "适合所有人的平台",
            image: PlatformForEveryone,
            description:
              "不是仅适用于程序员的常规自动化工具，任何人都可以使用 KonnectzIT 构建自动化",
          },
          feature3: {
            title: "无缝应用集成",
            image: SeamlessAppsIntegration,
            description: "从我们的集成库和自动化的日常任务中连接无限的应用程序",
          },
        },
        emailInvalid: "不合规电邮！",
      },
      reset: {
        title: "重设密码",
        subTitle: "创建新密码",
      },
    },
    konnect: {
      sidebar: {
        search_apps: "搜索...",
        choose_trigger: "选择触发器",
        choose_trigger_header: "选择应用事件",
        no_triggers_available: "没有可用的触发器",
        choose_format_header: "格式选项",
        choose_config_header: "选择配置",
        select_trigger_action_dropdown_header: "Select",
        choose_option_header: "定义选项",
        no_options_available: "没有可用的选项",
        choose_linked_account: "选择账户",
        customize_form_header: "配置表单",
        customize_form_partial: "选择",
        configure_response: "自定义响应",
        basic_auth: "基本认证",
        api_key: "API 密钥",
        api_token: "API 秘密",
        webhook_url: "网络挂钩网址",
        webhook_url_toast: "Webhook URL 已复制到剪贴板！",
        webhook_response: "Webhook 响应",
        choose_action_event: "选择动作事件",
        endpoint_url: "端点网址",
        payload_type: "有效载荷类型",
        wrap_request_array: "将请求包装在数组中",
        set_params: "设置参数",
        add_header: "添加标题",
        set_paramter: "设置参数",
        expiry_time: "时间线计数",
        expiry_val: "时间线",
        segmentIndex: "细分指数",
        validate: "证实",
        generate: "产生",
      },
      UI: {
        betaText: "贝塔",
        webhookDuplicateToastMsg: "Webhook 应用程序已经存在！",
      },
      header: {
        backBtnLabel: "后退",
        saveBtnLabel: "保存",
        publishBtnLabel: "发布",
        nameOfKonnectz: "康内茨人的名字",
      },
      testAndReview: {
        btnLabel: "测试与审查",
      },
      proceed: {
        btnLabel: "继续",
      },
      additionalConfigsModal: {
        cancelBtnLabel: "取消",
        okBtnLabel: "行",
      },
      conditions: {
        addNewConditionBtnLabel: "添加新条件 +",
        cancelBtnLabel: "取消",
        okBtnLabel: "行",
        operatorAndLabel: "和",
        operatorOrLabel: "或者",
        headerConditionText: "状况",
        selectFieldHeaderTitle: "选择字段",
        selectConditionHeaderTitle: "选择条件",
        validateBtnLabel: "证实",
        conditionsHeaderText: "条件",
      },
      publishSuccessMsg: "Konnect 发布成功！",
      saveSuccessMsg: "Konnect 已成功保存！",
    },
    apps: {
      authenticatedAppsTitle: "经过身份验证的应用程序",
      aunthenticateNowTitle: "立即认证",
      noAppsToAunthenticate: "目前没有要进行身份验证的应用程序。",
      authenticateNow: "您没有任何经过身份验证的应用程序。立即进行身份验证。",
      addAccountText: "新增帐户",
      betaText: "贝塔",
      oAuthHandlerText: "KonnectzIT OAuth 处理程序，请稍候..",
      cancelBtnText: "取消",
      yesContinueText: "是的，继续",
      requiredText: "（必需的）",
      accessAPIModalHeaderText1: "允许 KonnectzIT 访问",
      accessAPIModalHeaderText2: "帐户？",
      accessAPIModalMainText1: "你可以找到你的",
      accessAPIModalMainText2: "通过导航到",
      accessAPIModalMainText3: "集成页面",
      authorized: "授权应用"
    },
    konnectzList: {
      homeText: "家",
      noKonnectzInfo: "暂时没有连接！",
      deleteModalBodyText: "你确定你要删除 ？",
      deleteModalNoText: "不",
      deleteModalDeleteText: "删除",
      folders: {
        createFolderTitleText: "为您的文件夹命名",
        createFolderInputPlaceholder: "文件夹名称",
        createFolderBtn: "创建",
        deleteFolderConfirmationText: "您确定要删除此文件夹吗？",
        deleteBtn: "删除",
        selectFolderInfo: "选择要在其中移动所选连接的文件夹",
        moveFolderCancelBtn: "取消",
        moveBtn: "移动",
        renameSaveBtn: "保存",
        renameYourFolderText: "重命名您的文件夹",
        createFolderBtn: "创建文件夹"
      }
    },
    dashboard: {
      widgets: {
        latestApps: {
          headerTitle: "最新应用",
        },
        totalKonnectz: {
          headerTitle: "总科内茨",
          activeKonnectz: "活跃的康内茨",
          inactiveKonnectz: "非活动连接",
        },
        latestKonnectz: {
          headerTitle: "最新的康奈茨",
        },
        subscription: {
          contentText: "通过升级 Pro 释放更多能量",
          upgradeBtnLabel: "现在升级",
        },
        taskConsumption: {
          headerTitle: "任务消耗",
        },
      },
    },
    account: {
      countryRequiredText: "国家是必须的！",
      addressLine1RequiredText: "地址行 1 为必填项！",
      cityRequiredText: "城市是必需的！",
      stateRequiredText: "状态是必需的！",
      zipCodeRequiredText: "邮政编码是必需的！",
      taxIdRequiredText: "需要税号！",
      cityLabel: "城市",
      stateLabel: "状态",
      zipCodeLabel: "邮政编码",
      taxIdLabel: "税号",
      addressLine2Label: "地址行 2",
      addressLine1Label: "地址第一行",
      countryLabel: "国家",
      billingtext:"帐单地址",
      myProfileText: "我的简历",
      emailAddressLabel: "电子邮件地址",
      firstNameLabel: "名",
      lastNameLabel: "姓",
      firstNameRequiredText: "名字是必需的！",
      lastNameRequiredText: "姓氏是必填项！",
      companyNameLabel: "公司名",
      timeZoneLabel: "时区",
      changeLanguageLabel: "改变语言",
      changePasswordHeaderText: "更改密码",
      currentPasswordLabel: "当前密码",
      currentPasswordRequiredText: "需要当前密码！",
      newPasswordLabel: "新密码",
      newPasswordRequiredText: "需要新密码！",
      confirmPasswordLabel: "确认密码",
      confirmPasswordRequiredText: "需要确认密码！",
      updateBtnLabel: "更新",
      editUpdateAccountSideBarText: "编辑/更新帐户",
      changePasswordSideBarText: "更改密码",
      billingText: "计费",
      teamText: "团队",
      agencyText: "机构",
      sideBarHeaderAccountText: "帐户",
      profileUpdatedSuccessfullyText: "个人资料更新成功！",
      addTeamMemberText: "添加团队成员",
      addSubAccountsText: "添加子账户",

      billing: {
        couponCodeText: "代码",
        applyCodeBtnLbl: "申请代码",
        tasksText: "任务",
        redeemedOnText: "兑换日期",
        expiresOnText: "到期",
        noCouponsAppliedText: "未申请许可证",
        plansAndFeaturesText: "计划和功能",
        currentPlanText: "当前计划",
        upgradeText: "升级",
        billingText: "计费",
        licenseerror:"无效的许可证！",
        completionofprofile:"请在兑换代码之前填写您的个​​人资料。这是 GPDR/数据处理和计费目的所必需的",
      },
      team: {
        name: "名称",
        firstName: "名",
        lastName: "姓",
        access: "使用权",
        email: "电子邮件",
        addBtn: "添加",
        cancelBtn: "取消",
        assignAccessLbl: "分配访问权限",
        noUsersInfo: "没有可用的用户",
        teamMembersHeaderText: "团队成员",
      },
      agency: {
        name: "客户名称 ：",
        firstName: "名",
        lastName: "姓",
        tasks: "分配的任务：",
        consumed:"任务消耗：",
        totalSubaccounts: "子帐户总数：",
        email: "电子邮件 ：",
        active: "积极的 ：",
        inActive: "非活动：",
        addBtn: "添加",
        cancelBtn: "取消",
        noAgenciesInfo: "没有可用的代理机构",
        agencyMembersHeaderText: "子账户",
        total:"总任务：",
        update:"更新",
        updateTask: "更新任务",
        delete: "删除",
        available: "可用任务",
        deactivate: "启用",
        activate: "停用",
        subaccounterror:"更新子账号信息失败！"

      },
    },
    sideNav: {
      homeLabel: "家",
      appsLabel: "应用",
      konnectzLabel: "康内茨",
      historyLabel: "历史",
      accountLabel: "帐户",
      notificationsLabel: "通知",
      logoutLabel: "登出",
      getHelpLabel: "得到帮助？",
      konnectzITLogoText: "康内茨IT", 
    },
  },
}
