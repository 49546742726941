import React, { useState, useEffect, useRef } from "react"
import { Flex, Text } from "rebass"
import { svg } from "../../asset/svg"
import ReactTooltip from "react-tooltip"
import InfiniteScroll from "react-infinite-scroll-component"
import { useDispatch, useSelector } from "react-redux"
import { IconWrapper, AppItem } from "../../Component/konnect/UI"
import { HeaderInput } from "../../../../components/Konnect/UI"
import { faFilter, faSearch, faStopCircle } from "../../../../../node_modules/@fortawesome/free-solid-svg-icons/index"
import {
  updateAddonCollapse,
  updateSidebarCollapse,
  toggleAddonCollapse,
  updateLoadedAddOnApps,
  updateLoadedApps,
} from "../../store/slice/sidebarSlice"
import AuthTokenService from "../../../../utils/AuthTokenService"
import axios from "../../../../../node_modules/axios/index"
import { useRefContext } from "../../store/context/index"

export const KonnectSidebar = () => {
  const { sidebarContainer } = useRefContext()
  const dispatch = useDispatch()
  const { sidebarCollapse, addOnCollapse, loadedAddOnApps, loadedApps } = useSelector(state => state.sidebar)
  const [searchValue, setSearchValue] = useState(null)
  const [pageSize, setPageSize] = useState(40)
  const [displayFilter, setDisplayFilter] = useState(false)
  let tokenObj1 = AuthTokenService.get()
  const headerInputRef = useRef()
  useEffect(() => {
    const getAddOnApps = async () => {
      let UrlInfo = {
        url: `https://stagingapp.konnectzit.com/api/v1/apps/add_on_apps`, //api/v1/loadedApps/authorized_apps1
        method: "GET",
        headers: {
          Authorization: "Bearer " + tokenObj1,
        },
      }

      let result = await axios(UrlInfo).catch(e => console.log(e))
      dispatch(updateLoadedAddOnApps(result.data.apps))
    }
    getAddOnApps()
  }, [])

  useEffect(() => {
    setSearchValue(null)
  }, [sidebarCollapse])

  useEffect(() => {
    let params = {
      page_size: pageSize,
    }
    let params1 = {
      page_size: pageSize,
      search_query: searchValue,
    }
    const getAuthApps = async searchQuery => {
      var UrlInfo = {
        url: `https://stagingapp.konnectzit.com/api/v1/apps`, //api/v1/loadedApps/authorized_apps1
        method: "GET",
        headers: {
          Authorization: "Bearer " + tokenObj1,
        },
      }
      if (searchQuery) {
        const qs = Object.keys(searchQuery)
          .map(key => `${key}=${searchQuery[key]}`)
          .join("&")

        UrlInfo.url = UrlInfo.url + `?${qs}`
      }
      let result = await axios(UrlInfo)
      dispatch(updateLoadedApps(result.data.apps))
    }
    getAuthApps(searchValue ? { ...params1 } : { ...params })
  }, [pageSize, searchValue])

  const fetchMoreApps = () => {
    setPageSize(pageSize + 20)
  }
  const onSearchItem = e => {
    setSearchValue(e.currentTarget.value)
  }

  return (
    <Flex
      height="100%"
      ref={sidebarContainer}
      sx={{
        width: sidebarCollapse ? "5%" : "22%",
        boxShadow: "0px 0px 5px 2px rgb(214,214,214, 0.6)",
        zIndex: 2,
        backgroundColor: sidebarCollapse ? "#152f73" : "#fff",
        flexDirection: "column",
        transition: "width 0.35s",
      }}
    >
      {" "}
      {!sidebarCollapse ? (
        <Flex
          sx={{
            p: "20px",
            position: "relative",
            flexDirection: "column",
            gap: "20px",
            height: "100%",
          }}
        >
          <Flex sx={{ position: "relative" }}>
            <Flex sx={{ position: "relative", width: "100%" }}>
              <Flex sx={{ position: "absolute", top: "0px", left: "0px", width: "30px", height: "100%", zIndex: 4 }}>
                <Flex sx={{ m: "auto", zIndex: 4 }}>
                  <IconWrapper icon={faSearch} />
                </Flex>
              </Flex>

              <HeaderInput
                ref={headerInputRef}
                onFocus={e => {
                  dispatch(updateAddonCollapse(true))
                }}
                onChange={onSearchItem}
                id="searchbar_canvas"
                placeholder="Search by App Name"
                sx={{ zIndex: 3 }}
              />
              {/* <Flex
                sx={{
                  position: "absolute",
                  top: headerInputRef?.current?.getBoundingClientRect()?.height / 2,
                  height: displayFilter ? "170px" : "0px",
                  width: "100%",
                  zIndex: displayFilter ? 2 : -2,
                  opacity: displayFilter ? 1 : 0,
                  backgroundColor: "#F3F7FF",
                  borderBottomLeftRadius: "16px",
                  border: "1px solid #b7cbff",
                  borderBottomRightRadius: "16px",
                  transition: "height 0.25s, opacity 0.25s, padding 0.25s",
                  padding: `${headerInputRef?.current?.getBoundingClientRect()?.height / 2 + 10 + "px"} 10px 10px 10px`,
                }}
              >
                <Flex sx={{ gap: "10px", flexDirection: "column" }}>
                  <Text>App Type</Text>
                  <Text>WEBHOOK</Text>
                  <Text>WEBHOOK_API</Text>
                  <Text>API</Text>
                </Flex>
              </Flex> */}
              <Flex
                sx={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                  width: searchValue ? "60px" : "30px",
                  height: "100%",
                  zIndex: 3,
                }}
              >
                <Flex
                  // onClick={() => setDisplayFilter(c => !c)}
                  sx={{
                    cursor: "pointer",
                    margin: "auto",
                    "&:hover": {
                      // transform: "scale(1.3)",
                    },
                    transition: "transform 0.15s, margin 0.15s",
                  }}
                >
                  <IconWrapper icon={faFilter} />
                </Flex>
                <Flex
                  onClick={() => {
                    headerInputRef.current.value = null
                    headerInputRef.current.focus()
                    setSearchValue(null)
                  }}
                  sx={{
                    cursor: "pointer",
                    m: "auto",
                    width: searchValue ? "20px" : "0px",
                    height: searchValue ? "20px" : "0px",
                    fill: "#F74545",
                    "&:hover": {
                      transform: "scale(1.3)",
                      fill: "#F74545",
                    },
                    transition: "transform 0.15s, width 0.15s, height 0.15s",
                  }}
                >
                  {svg.xMark}
                </Flex>
              </Flex>
            </Flex>
            <Flex height="100%" sx={{ position: "absolute", right: "-33px", top: "0px" }}>
              <Flex
                onClick={() => dispatch(updateSidebarCollapse(true))}
                width="26px"
                sx={{
                  cursor: "pointer",
                  zIndex: 3,
                  "&:hover": {
                    transform: "scale(1.3) rotate(270deg)",
                  },
                  m: "auto",
                  transform: "rotate(270deg)",
                  transition: "transform 0.15s",
                  fill: "#152f73",
                }}
              >
                {svg.collapse}
              </Flex>
            </Flex>
          </Flex>
          <Flex
            onClick={() => dispatch(toggleAddonCollapse())}
            sx={{ justifyContent: "space-between", cursor: "pointer" }}
          >
            <Text fontSize={16}>Add-on Apps</Text>
            <Flex
              width="15px"
              sx={{
                zIndex: 0,
                transform: !addOnCollapse ? "rotate(0deg)" : "rotate(180deg)",
                transition: "transform 0.25s",
              }}
            >
              {svg.collapse_2}
            </Flex>
          </Flex>
          <Flex
            flexWrap="wrap"
            sx={{
              height: !addOnCollapse ? "100%" : "0px",
              zIndex: !addOnCollapse ? 1 : -1,
              opacity: !addOnCollapse ? 1 : 0,
              transition: "height 0.25s, z-index 0.25s, opacity 0.25s",
            }}
          >
            {loadedAddOnApps &&
              loadedAddOnApps.length > 0 &&
              loadedAddOnApps.map((app, idx) => {
                const image = app.image_url.startsWith("http")
                  ? `${app.image_url}`
                  : "https://d33wubrfki0l68.cloudfront.net/4e5626a0b7db6b84c0b51f1c0cfd56490fc19b74/7a5ad/images/logo-fancy.svg"

                return (
                  <React.Fragment key={app.id}>
                    <AppItem
                      data-tip={app.name}
                      data-for={app.id + ""}
                      key={app.id}
                      // title={app.name}
                      name={app.name}
                      image={image}
                      draggable={true}
                      badge={app.account_count}
                      beta={app.status === "Beta"}
                      onDragStart={event => {
                        event.dataTransfer.setData(
                          "application/reactflow",
                          JSON.stringify({
                            id: app.id,
                            name: app.name,
                            image,
                            background: app.background_color,
                            associatedAccounts: app.account_count ? app.account_count : 1,
                            webhook_enabled: app.webhook_enabled,
                            webhook_instructions: app.webhook_instructions,
                            webhook_url: app.webhook_url,
                            provider: app.provider,
                            type: app.app_type,
                          })
                        ) // it will go to node wrapper file  that will decide which app it is
                        event.dataTransfer.effectAllowed = "move"
                      }}
                    ></AppItem>
                    <ReactTooltip id={app.id + ""} effect="solid" place="bottom"></ReactTooltip>
                  </React.Fragment>
                )
              })}
          </Flex>
          <Flex sx={{ justifyContent: "space-between", mt: -20 }}>
            <Text fontSize={16}>Apps</Text>
            {/* <Flex width="15px" sx={{ cursor: "pointer", transform: "rotate(180deg)" }}>
                    {svg.collapse_2}
                  </Flex> */}
          </Flex>
          <Flex id="scrollableDiv1" sx={{ overflowY: "auto", width: "100%", justifyContent: "space-between" }}>
            {loadedApps && loadedApps.length > 0 ? (
              <InfiniteScroll
                style={{ display: "flex", flexDirection: "column", width: "100%" }}
                dataLength={loadedApps.length || 0} //This is important field to render the next data
                next={fetchMoreApps}
                hasMore={true}
                pullDownToRefresh={false}
                scrollableTarget="scrollableDiv1"
                loader={
                  !searchValue && (
                    <Flex
                      sx={{
                        cursor: "pointer",
                        fill: "#152f73",
                        m: "20px auto",
                        width: "25px",
                        height: "25px",
                        animation: "rotation 2s infinite linear",
                      }}
                    >
                      {svg.load}
                    </Flex>
                  )
                }
              >
                <Flex
                  sx={{
                    flexFlow: "wrap",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {loadedApps &&
                    loadedApps.length > 0 &&
                    loadedApps.map((app, idx) => {
                      const image = app.image_url.startsWith("http")
                        ? `${app.image_url}`
                        : "https://d33wubrfki0l68.cloudfront.net/4e5626a0b7db6b84c0b51f1c0cfd56490fc19b74/7a5ad/images/logo-fancy.svg"

                      return (
                        <React.Fragment key={app.id}>
                          <AppItem
                            data-tip={app.name}
                            data-for={app.id + ""}
                            key={app.id}
                            // title={app.name}
                            name={app.name}
                            image={image}
                            draggable={true}
                            badge={app.account_count}
                            beta={app.status === "Beta"}
                            onDragStart={event => {
                              event.dataTransfer.setData(
                                "application/reactflow",
                                JSON.stringify({
                                  id: app.id,
                                  name: app.name,
                                  image,
                                  background: app.background_color,
                                  associatedAccounts: app.account_count ? app.account_count : 1,
                                  webhook_enabled: app.webhook_enabled,
                                  webhook_instructions: app.webhook_instructions,
                                  webhook_url: app.webhook_url,
                                  provider: app.provider,
                                  type: app.app_type,
                                  auth_type: app.auth_type,
                                })
                              ) // it will go to node wrapper file  that will decide which app it is
                              event.dataTransfer.effectAllowed = "move"
                            }}
                          />
                          <ReactTooltip id={app.id + ""} effect="solid" place="bottom"></ReactTooltip>
                        </React.Fragment>
                      )
                    })}
                </Flex>
              </InfiniteScroll>
            ) : loadedApps == undefined || loadedApps == null ? (
              <Flex
                sx={{
                  cursor: "pointer",
                  fill: "#152f73",
                  m: "auto",
                  width: "35px",
                  height: "35px",
                  animation: "rotation 2s infinite linear",
                }}
              >
                {svg.load}
              </Flex>
            ) : (
              <Flex width="100%" height="100%" flexDirection="column" sx={{ gap: "20px" }}>
                <Flex
                  sx={{
                    cursor: "pointer",
                    fill: "#ffd43b",
                    m: "auto",
                    width: "35px",
                    height: "35px",
                  }}
                >
                  {svg.alert_1}
                </Flex>
                <Text textAlign="center">Not Found</Text>
              </Flex>

              // <AppItem
              //   data-tip={"Not Found"}
              //   data-for={1}
              //   key={1}
              //   name={"Not Found"}
              //   image={notfound}
              //   draggable={true}
              // />
            )}
          </Flex>
        </Flex>
      ) : (
        <Flex
          onClick={() => dispatch(updateSidebarCollapse(false))}
          sx={{ cursor: "pointer", p: "20px", position: "relative", height: "10%" }}
        >
          <Flex width="22px" sx={{ m: "auto" }}>
            {svg.expand}
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
