/**
 * App config reducer to handle the global config api responses.
 */

import { AUTH_LOGIN, AUTH_SIGN_UP, VERIFY_EMAIL, UPDATE_USER, GET_FORGOT_PASSWORD_LINK, RESET_PASSWORD, AUTH_USER_EXISTS } from 'config/Constants'

const initState = {
  user: null
}

const SUCCESS = '_SUCCESS'
const FAIL = '_FAIL'

const appReducer = (state = initState, action) => {
  switch (action.type) {
    case AUTH_LOGIN + SUCCESS:
      return state.set('user', action.payload ? action.payload.response : {})

    case AUTH_LOGIN + FAIL:
      return state.set('user', null)

    case AUTH_SIGN_UP + SUCCESS:
      return state

    case AUTH_SIGN_UP + FAIL:
      return state.set('user', null)

    case AUTH_USER_EXISTS + SUCCESS:
      return state

    case AUTH_USER_EXISTS + FAIL:
      return state.set('user', null)

    case VERIFY_EMAIL + SUCCESS:
      return state

    case VERIFY_EMAIL + FAIL:
      return state

    case UPDATE_USER + SUCCESS:
      return state.set('user', action.payload)

    case UPDATE_USER + FAIL:
      return state.set('user', null)

    case GET_FORGOT_PASSWORD_LINK + SUCCESS:
      return state.set("forgotApiError", false)

    case GET_FORGOT_PASSWORD_LINK + FAIL:
      return state.set("forgotApiError", true)

    case RESET_PASSWORD + SUCCESS:
      return state

    case RESET_PASSWORD + FAIL:
      return state
    default:
      return state
  }
}

export default appReducer
