/**
 * Forgot password UI page.
 */
import React from "react"
import { Box, Flex, Link, Text } from "rebass"
import { faEnvelope, faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { Input, Button } from "src/components"
import { useTranslation } from "react-i18next"
import ForgotSvg from "src/assets/images/forgot"
import { isEmail } from "src/utils"
import px2vw from 'utils/px2vw'
import ForgotPasswordEmailSvg from "src/assets/images/forgot_password_email"
import ForgotPasswordEmailSentSvg from "src/assets/images/forgot_password_email_sent"
import { AuthTitle, AuthSubtitle } from 'src/components/Typography/Typography'

export const ForgotView = ({ state, loading, onContinueClick }) => {
  const [email, setEmail] = state.email
  const [emailError, setEmailError] = state.emailError

  const { t } = useTranslation()

  //Forgot click handler.
  const continueClick = () => {
    if (!isEmail(email)) {
      setEmailError(true)
    } else {
      setEmailError(false)
      onContinueClick()
    }
  }

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <AuthSubtitle>{t("auth.login.subtitle")}</AuthSubtitle>
      </Box>
      <Box
        className="border-gradient"
        sx={{
          width: '121px',
          margin: "0px auto",
          mb: px2vw(10),
          mt: px2vw(5),
        }}
      />
      <Box sx={{ textAlign: "center" }}>
        <AuthTitle>{t("auth.forgot.title")}</AuthTitle>
      </Box>

      <Box
        sx={{
          textAlign: 'center',
          my: px2vw(60)
        }}
      >
        <Box sx={{ textAlign: "center" }} >
          <ForgotPasswordEmailSvg />
        </Box>
        <Box sx={{ pt: px2vw(40), mx: px2vw(18) }}>
          <Input
            id="username"
            name="username"
            sxProps={{
              "& ~ span": { color: "#B7CBFF !important" },
            }}
            setError={emailError}
            errorMessage={t(state.emailErrorMsg)}
            defaultValue={email}
            onChange={e => setEmail(e.currentTarget.value)}
            required
            iconLeft={faEnvelope}
            placeholder={t("auth.signup.emailPlaceholder")}
          />
        </Box>

        <Button height={{ _: 40, sm: 40, md: 40, lg: 40, xl: 60 }}
          fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }} disabled={loading} sx={{ borderRadius: '0px', mx: px2vw(18), fontStyle: "normal" }} stretch onClick={continueClick}>
          {t("auth.forgot.forgtoBtnLabel")}
        </Button>
      </Box>
      <Flex flexDirection="column" sx={{ flexGrow: 1, justifyContent: "flex-end", alignItems: "center" }}>
        <Box sx={{ textAlign: "center" }}>
          <Text fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }} sx={{ display: "inline-block", fontStyle: "normal", color: "app.secondary.20" }}>
            {t("auth.login.alreadyHaveAccountText")}
            <Link href="/signup" sx={{ color: "app.primary.30", textDecoration: "none" }}>
              <span style={{ color: "#F7C545" }}>{t("auth.login.signupNowText")}</span>
            </Link>
          </Text>
        </Box>
        <Box sx={{ pt: px2vw(20), textAlign: "center" }}>
          <Text fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }} sx={{ display: "inline-block", fontStyle: "normal", color: "app.secondary.20" }}>
            {t("auth.login.clickingContinueText")}
            <Link href="/signup" sx={{ textDecoration: "none" }}>
              <span style={{ color: "#F7C545" }}>{t("auth.login.termsAndConditionsText")}</span>
            </Link>
          </Text>
        </Box>
      </Flex>
    </>
  )
}

export const ForgotMsg = ({ state }) => {
  const { t } = useTranslation()
  return (
    <>
      <Box sx={{ textAlign: "center", mt: px2vw(20) }}>
        <AuthSubtitle>{t("auth.login.subtitle")}</AuthSubtitle>
      </Box>
      <Box
        className="border-gradient"
        sx={{
          width: '121px',
          margin: "0px auto",
          mb: px2vw(10),
          mt: px2vw(5)
        }}
      />
      <Box sx={{ textAlign: "center" }}>
        <AuthTitle>{t("auth.forgot.title")}</AuthTitle>
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          my: px2vw(60)
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <ForgotPasswordEmailSentSvg />
        </Box>
        <Box sx={{ pt: px2vw(52), mx: px2vw(18) }}>
          <Text fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }} sx={{ textAlign: "center" }}>
            We sent forgot password link on{" "}
            <Text as="span" sx={{ color: 'app.secondary.50' }}>
              {state.email[0]}
            </Text>{" "}
          </Text>
        </Box>
      </Box>
      <Flex flexDirection="column" sx={{ flexGrow: 1, justifyContent: "flex-end", alignItems: "center" }}>
        <Box sx={{ textAlign: "center" }}>
          <Text fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }} sx={{ display: "inline-block", fontStyle: "normal", color: "app.secondary.20" }}>
            {t("auth.login.alreadyHaveAccountText")}
            <Link href="/login" sx={{ color: "#F7C545", textDecoration: "none" }}>
              <span style={{ color: "#F7C545" }}>{t("auth.signup.loginBtnLabel")}</span>
            </Link>
          </Text>
        </Box>
        <Box sx={{ pt: px2vw(20), textAlign: "center" }}>
          <Text fontSize={{ _: 14, sm: 14, md: 14, lg: 14, xl: 18 }} sx={{ display: "inline-block", fontStyle: "normal", color: "app.secondary.20" }}>
            {t("auth.login.clickingContinueText")}
            <Link href="/signup" style={{ color: "#F7C545", textDecoration: "none" }}>
              <span style={{ color: "#F7C545" }}>{t("auth.login.termsAndConditionsText")}</span>
            </Link>
          </Text>
        </Box>
      </Flex>
    </>
  )
}
