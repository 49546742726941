import React ,{useState} from 'react'
import { Flex, Box } from "rebass"
import { useTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"
import {updateTeamMember,deleteTeammember,getTeamUsersList} from "../../store/actions/teamActions"
import EditIcon from "../../assets/images/Edit_icon.svg"
import Lock from "../../assets/images/Lock.svg"
import DeleteIcon from "../../assets/images/Delete_icon.svg"
import { Text, Button } from "src/components"
import Plus from "../../assets/images/Plus.svg"
import { UpdateTeamMemberModal } from './updateTeamModal'
import { Modal } from "react-responsive-modal"
import { connect } from "react-redux"
import Cross from "../../assets/images/cross.svg"
import { DeleteModal } from "./DeleteModal"
export const TeamUsersList_ = ({ users ,addTeamMemberBtnClick,getTeamUsersList, teamUsersList, updateTeamMember,deleteTeamMember}) => {
    const { t } = useTranslation("translation")
    const [ userId,setUserId ]=useState(null)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [name,setName] = useState(null)
    const [openDeleteModal,setOpenDeleteModal] = useState(false)
    const onCloseModal = () => { setOpen(false) 
      
    }
    const onOpenModal = () => {
      setOpen(true)
      // setUserId(id)
      console.log("hello")
    }
    const onEditBtn = (id,name) => {
      setOpen(true)
      setUserId(id)
      console.log(id,"id")
      setName(name)
      }
      const deletemember = id => {
        setLoading(true)
        deleteTeamMember(id).then(res => {
          setOpen(false)
          getTeamUsersList()
          setLoading(false)
        })
      }
    
      const onDeleteBtn = (id,name) => {
        setOpenDeleteModal(true)
        setUserId(id)
        setName(name)
        }
        const onCloseDeleteModal = () => {
          setOpenDeleteModal(false)
        }
    const onUpdatingTeamMember = (firstName, lastName, access, sub_account_id, email) => {
      setLoading(true)
      setOpen(false)
      setUserId(sub_account_id)
      updateTeamMember({ firstName, lastName, access, sub_account_id, email }).then(res => {
        //console.log(res)
        if (res.payload?.key === "SIGNUP_FAIL" || res.payload?.key === "UPDATE_TEAM_MEMBER_FAIL") {
          toast.error(t("account.agency.subaccounterror")),
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
        }
        getTeamUsersList()
        setLoading(false)
      })
    }
  console.log(teamUsersList,"a2")
    return (
        <>
    <Box sx={{ width: "100%" }}>
        <Flex
          fontSize={[10, 12, 16, 18]}
          sx={{
            m:"0% 0% 0% 0.5%",
            width: "98.5%",
            height: "70px",
            border: "1px soild red",
            bg: "#152F73",
            borderRadius: "10px",
            color: "white",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Box sx={{ p: "8px", color: "#45F903" }}>
            <Text sx={{ fontWeight: 300 }}>
              {/* {t("account.agency.total")} <span className="font">{totalTasks}</span> */}
            </Text>
          </Box>
          <Box sx={{ p: "8px" }}>
            <Text sx={{ fontWeight: 300 }}>
              {/* {t("account.agency.consumed")} <span className="font">{taskConsumed}</span> */}
            </Text>
          </Box>
          <Box sx={{ p: "8px" }}>
            <Text sx={{ fontWeight: 300, color: "#45F903" }}>
              {/* {t("account.agency.totalSubaccounts")} <span className="font">{totalSubAccounts}</span>/ */}
              {/* <span className="font">{allowedSubaccountsLimit}</span> */}
            </Text>
          </Box>
          <Box sx={{ p: "8px" }}>
            <Text sx={{ fontWeight: 300 }}>
              {/* {t("account.agency.active")} <span className="font">{activeSubAccounts}</span> */}
            </Text>
          </Box>
          <Box sx={{ p: "8px" }}>
            <Text sx={{ fontWeight: 300 }}>
              {/* {t("account.agency.inActive")} <span className="font">{inActiveSubAccounts}</span> */}
            </Text>
          </Box>
          <Box>
            <button
              style={{
                padding: "5px 10px 5px 10px",
                backgroundColor: "#45F903",
                border: "1px solid #45F903",
                borderRadius: "9px",
                color: "#152F73",
              }}
            >
              {t("account.billing.upgradeText")}{" "}
            </button>
          </Box>
        </Flex>
        <Flex sx={{ justifyContent: "space-between", mt: "15px", width: "98.5%" }}>
          <Text sx={{ color: "#152F73", p: "11px", fontSize: ["h1", "h1", "h2"] }}>Sub Accounts</Text>
          <Box
            onClick={addTeamMemberBtnClick}
            sx={{
              cursor: "pointer",
              m: "10px 10px 0 10px",
              display: "flex",
              alignItems: "center",
              bg: "#1E419D",
              color: "white",
              p: "3px 10px 3px 3px",
              borderRadius: "20px",
            }}
          >
            <span>
              <Plus style={{ margin: "10px 12px 5px 5px" }} />
            </span>
            <span>Add Team Member</span>
          </Box>

     
        </Flex>
        <Flex sx={{mt:"20px",gap:"18px", flexWrap:"wrap", overflowX: "hidden",

overflowY: "auto",}}>
          {users?.map((user, idx) => (
            <>
              <Flex
                fontSize={[10, 12, 16, 18]}
                sx={{
                  height: "100%",
                  border: "1px solid #F3F7FF",
                  p: "20px",
                  m: "10px",
                  bg: "#F3F7FF",
                  borderRadius: "10px",
                }}
              >
                <Box>
                  <Flex flexDirection="flex" sx={{ width: "100%", justifyContent: "flex-start",  }}>
                    <Box>
                    <img
                  style={{ borderRadius: "16px", border: "1px solid #B7CBFF" }}
                  width="40px"
                  height="40px"
                  // src={profile.image_url}
                />
                    </Box>
                    <Box sx={{m:"0 0px 0 20px"}}>
                      <Text
                        sx={{
                          fontWeight: 300,
                          fontSize: 18,
                          textAlign: "left",
                          justifyContent: "center",
                          width: 200,
                          color: "#152F73",
                        }}
                      >
                        {user.name}
                      </Text>
                      <Text
                        sx={{ fontWeight: 200, fontSize: 12, textAlign: "left", color: "gray" }}
                        data-tip={user.email}
                        data-for={user.email + ""}
                      >
                        {/* {t("account.agency.email")}{" "} */}
                        <span className="font">
                          {" "}
                          {user.email.length > 20 ? user.email.substring(0, 20) + "..." : user.email}
                        </span>
                      </Text>
                    </Box>
                    <Flex flexDirection="row" sx={{ mt: "0px", justifyContent: "space-around",alignItems:"center" }}>
                      <EditIcon
                        style={{ cursor: "pointer", marginRight: "15px" }}
                        data-tip={"Edit"}
                        
                        onClick={()=>onEditBtn(user.id,user.name)}
                        // onClick={() => fileRef.current.click()}
                      />
                      <Lock 
                      style={{cursor:"pointer",marginRight: "15px "}}
                      />
                      <DeleteIcon
                        data-tip="Remove"
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#ea4f4f",
                          borderRadius: "50%",
                          padding: "4px 7px",
                          height: "30px",
                        }}
                        size="xl"
                        onClick={() => onDeleteBtn(user.id,user.name)}
                      />
                         <Modal
                          open={openDeleteModal}
                          onClose={onCloseDeleteModal}
                          styles={{ modal: { padding: "20px", borderRadius: "16px", minWidth: "400px" } }}
                          closeIcon={<Cross />}
                          center
                        >
                          <DeleteModal name={name} userId={userId} onCloseDeleteModal={onCloseDeleteModal} deletemember={deletemember} />
                        </Modal>
                       <Modal
                          open={open}
                          onClose={onCloseModal}
                          styles={{ modal: { padding: "20px", borderRadius: "16px", minWidth: "400px" } }}
                          closeIcon={<Cross />}
                          center
                        >
                          <UpdateTeamMemberModal users={users} userId={userId} onUpdatingTeamMember={onUpdatingTeamMember} />
                        </Modal>
                    </Flex>
                  </Flex>
               
                </Box>
              </Flex>
            </>
          
          ))}
        </Flex>
      </Box>

            {/* <Text sx={{ mb: "20px", fontSize: ["h1", "h1", "h2"], fontWeight: "bold", color: "#222222" }}>{t("account.team.teamMembersHeaderText")}</Text>
            <Flex sx={{ width: 550, bg: '#fff' }} flexDirection="column">
                <Flex sx={{ width: '100%', flexDirection: "row", bg: "#B7CBFF", height: 60, alignItems: "center", pl: 20 }}>
                    <Box width="45%" sx={{ fontWeight: 600 }}>{t("account.team.email")}</Box>
                    <Box width="35%" sx={{ fontWeight: 600 }}>{t("account.team.name")}</Box>
                    <Box width="20%" sx={{ fontWeight: 600 }}>{t("account.team.access")}</Box>
                </Flex>
                {(!users || users ?.length === 0) && (<Text fontSize="18px" sx={{ ml: 10, mt: 10, fontWeight: "500" }}>{t("account.team.noUsersInfo")}</Text>)}
                {users ?.map((user, idx) => (
                    <Flex sx={{ width: '100%', pl: 10, my: 10 }}>
                        <Box data-tip={user.email} data-for={user.email + ""} width="45%">{user.email.length > 18 ? user.email.substring(0, 18) + "..." : user.email}
                            <ReactTooltip id={user.email + ""} effect="solid" place="bottom"></ReactTooltip>
                        </Box>
                        <Box width="35%">{user.name}</Box>
                        <Box width="20%">{user.access}</Box>
                    </Flex>
                ))}
                <div style={{ width: '90%', border: '1px dashed #B7CBFF', marginLeft: 20, marginTop: 20, marginBottom: 20 }}></div>
            </Flex> */}
        </>
    )
}


const mapStateToProps = state => {
  return {
    teamUsersList: state.getIn(["team", "TeamUsersList"]),
   
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getTeamUsersList: () => dispatch(getTeamUsersList()),
    updateTeamMember: data => dispatch(updateTeamMember(data)),
    deleteTeamMember: sub_account_id => dispatch(deleteTeammember(sub_account_id))
  
}
}

export const TeamUsersList = connect(mapStateToProps, mapDispatchToProps)(TeamUsersList_)
