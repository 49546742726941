const moveToLength = (movingPoint, targetPoint, amount) => {
  let width = targetPoint.x - movingPoint.x
  let height = targetPoint.y - movingPoint.y
  let distance = Math.sqrt(width * width + height * height)
  return moveToFractional(movingPoint, targetPoint, Math.min(1, amount / distance))
}

const moveToFractional = (movingPoint, targetPoint, fraction) => {
  return {
    x: movingPoint.x + (targetPoint.x - movingPoint.x) * fraction,
    y: movingPoint.y + (targetPoint.y - movingPoint.y) * fraction,
  }
}

const adjustSegment = (cmd, newPoint) => {
  if (cmd.length > 2) {
    cmd[cmd.length - 2] = newPoint.x
    cmd[cmd.length - 1] = newPoint.y
  }
}

const pointForSegment = cmd => {
  return {
    x: parseFloat(cmd[cmd.length - 2]),
    y: parseFloat(cmd[cmd.length - 1]),
  }
}

const roundCorners = (pathString, radius = 75) => {
  let pathParts = pathString.split(/[,\s]/).reduce((parts, part) => {
    let match = part.match("([a-zA-Z])(.+)")
    if (match) {
      parts.push(match[1])
      parts.push(match[2])
    } else {
      if (part) parts.push(part)
    }

    return parts
  }, [])
  let segments = pathParts.reduce((segments, part) => {
    if (parseFloat(part) == part && segments.length) {
      segments[segments.length - 1].push(part)
    } else {
      segments.push([part])
    }
    return segments
  }, [])
  let resultSegments = []

  if (segments.length > 1) {
    resultSegments.push(segments[0])

    for (let cmdIndex = 1; cmdIndex < segments.length; cmdIndex++) {
      let prevCmd = resultSegments[resultSegments.length - 1]

      let curCmd = segments[cmdIndex]

      let nextCmd = segments[cmdIndex + 1]

      if (nextCmd && prevCmd && prevCmd.length > 2 && curCmd[0] == "L" && nextCmd.length > 2 && nextCmd[0] == "L") {
        let prevPoint = pointForSegment(prevCmd)
        let curPoint = pointForSegment(curCmd)
        let nextPoint = pointForSegment(nextCmd)

        let curveStart, curveEnd

        curveStart = moveToLength(curPoint, prevPoint, radius)
        curveEnd = moveToLength(curPoint, nextPoint, radius)

        adjustSegment(curCmd, curveStart)
        curCmd.origPoint = curPoint
        resultSegments.push(curCmd)

        let startControl = moveToFractional(curveStart, curPoint, 0.5)
        let endControl = moveToFractional(curPoint, curveEnd, 0.5)

        let curveCmd = ["C", startControl.x, startControl.y, endControl.x, endControl.y, curveEnd.x, curveEnd.y]
        curveCmd.origPoint = curPoint
        resultSegments.push(curveCmd)
      } else {
        resultSegments.push(curCmd)
      }
    }
  } else {
    resultSegments = segments
  }
  return resultSegments.reduce((str, c) => {
    return str + c.join(" ") + " "
  }, "")
}

export default roundCorners
