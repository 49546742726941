import React, { useEffect } from "react"
import { Flex } from "rebass"
import ReactFlow, { Controls, ReactFlowProvider, connectOnClick } from "react-flow-renderer"
import { useDispatch, useSelector } from "react-redux"
import { onEdgesChange, onNodesChange, clearState } from "../../store/slice/canvasSlice"
import {
  Conditions,
  WebHookInner,
  NodeInner,
  AddOnNode,
  AddOnInner,
  CoupanGenerator,
  ApiInner,
  Scheduler,
  TextSplitterDefault,
} from "../../Component/konnect/nodes"
import { CustomEdgeButton } from "../../Component/konnect/Edges"
import { useRefContext } from "../../store/context/index"
import { v4 as uuidv4 } from "uuid"
import { onDragOver, onDrop, getKonnect, onConnect, showOnCanvas } from "../../features/index"

const nodeTypes = {
  WebHookInner: WebHookInner,
  NodeInner: NodeInner,
  addOnNode: AddOnNode,
  coupanGenerator: CoupanGenerator,
  addOnInner: AddOnInner,
  scheduler: Scheduler,
  apiInner: ApiInner,
  conditions: Conditions,
  coupan: CoupanGenerator,
  textSplitter: TextSplitterDefault,
}
const EdgeType = {
  buttonedge: "buttonedge",
}
const edgeTypes = {
  buttonedge: CustomEdgeButton,
}
export const FlowCanvasContainer = ({ reactFlowInstance, setReactFlowInstance }) => {
  const dispatch = useDispatch()
  const { sidebarContainer, topbarContainer } = useRefContext()
  const { sidebarCollapse } = useSelector(state => state.sidebar)
  const { editorState, apiAppInitialState, customAppData } = useSelector(state => state.canvas.canvas)
  const getId = () => uuidv4()
  const onDropFunction = e => {
    onDrop(
      e,
      reactFlowInstance,
      sidebarContainer,
      topbarContainer,
      getId,
      dispatch,
      editorState.nodes,
      editorState.links,
      apiAppInitialState,
      customAppData
    )
  }
  const items = JSON.parse(localStorage.getItem("dataKey"))
  // console.log("Editor: ", editorState)
  useEffect(() => {
    if (items) {
      showOnCanvas({ items, dispatch })
    }
    return localStorage.setItem("dataKey", JSON.stringify(null))
  }, [])

  return (
    <Flex
      height="100%"
      sx={{
        width: sidebarCollapse ? "95%" : "78%",
        backgroundColor: "#f3f7ff",
        transition: "width 0.35s",
        zIndex: 1,
      }}
    >
      <ReactFlow
        onDrop={onDropFunction}
        onDragOver={onDragOver}
        nodes={editorState.nodes}
        edges={editorState.links}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        connectOnClick={true}
        onNodesChange={c => dispatch(onNodesChange(c))}
        onEdgesChange={c => dispatch(onEdgesChange(c))}
        onConnect={c => onConnect({ c: { ...c, id: getId() }, EdgeType, dispatch, editorState: editorState })}
        onInit={setReactFlowInstance}
        minZoom={0.1}
        deleteKeyCode={null}
      >
        <Controls />
      </ReactFlow>
    </Flex>
  )
}
