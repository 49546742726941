import React, { useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
export const PanAndZoomContainer = styled.div`
  position: relative;
  width: 2000px;
  height: 2000px;
  border: 10px dashed green;
  background: yellow;
`

export const PanAndZoom = ({ children, scale, onDragCanvas, pos }) => {
  const [isPanning, setPanning] = useState(false)
  const [position, setPosition] = useState({
    oldX: 0,
    oldY: 0,
    x: 0,
    y: 0,
    z: 1,
  })

  const containerRef = useRef()

  useEffect(() => {
    setPosition({
      ...position,
      x: pos.x,
      y: pos.y,
    })
  }, [])

  const onMouseDown = e => {
    e.preventDefault()
    setPanning(true)
    setPosition({
      ...position,
      oldX: e.clientX,
      oldY: e.clientY,
    })
  }

  useEffect(() => {
    const mouseup = () => {
      setPanning(false)
    }

    const mousemove = event => {
      if (isPanning) {
        onDragCanvas({
          positionX: position.x + event.clientX - position.oldX,
          positionY: position.y + event.clientY - position.oldY,
        })
        setPosition({
          ...position,
          x: position.x + event.clientX - position.oldX,
          y: position.y + event.clientY - position.oldY,
          oldX: event.clientX,
          oldY: event.clientY,
        })
      }
    }

    window.addEventListener("mouseup", mouseup)
    window.addEventListener("mousemove", mousemove)

    return () => {
      window.removeEventListener("mouseup", mouseup)
      window.removeEventListener("mousemove", mousemove)
    }
  })

  return (
    <PanAndZoomContainer ref={containerRef} onMouseDown={onMouseDown}>
      <div
        style={{
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
      ></div>
    </PanAndZoomContainer>
  )
}
