/**
 * Notifications Screen.
 */
import React from "react"
import { Box, Text, Flex, Link } from "rebass"
import { useTranslation } from "react-i18next"
import px2vw from 'utils/px2vw'

export const NotificationsView = () => {
    const { t } = useTranslation()

    return (
        <>
            <Box sx={{ m: px2vw(24) }}>
                <Text sx={{ fontSize: ["h1", "h1", "h2"], fontWeight: 'bold', color: '#222222' }}>{'Notifications'}</Text>
            </Box>
        </>
    )
}
