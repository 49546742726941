import UnlimitedSteps from "assets/images/features/unlimited-steps.png"
import VisualizeEverything from "assets/images/features/visualize-everything.png"
import IntegrationLibrary from "assets/images/features/integration-library.png"
import SimpleAutomation from "assets/images/features/simple-automation.png"
import PlatformForEveryone from "assets/images/features/platform-for-everyone.png"
import SeamlessAppsIntegration from "assets/images/features/seamless-apps-integration.png"

export const dutch = {
  translation: {
    common: {
      app: "Konnectz",
    },
    header: {
      topNavigations: {
        tipsAndTricks: "Tips en trucs",
        pricing: "Prijzen",
        blogs: "Blogs",
        helpCenter: "Helpcentrum",
      },
      searchAppTextPlaceholder: "Zoek hier...",
      createKonnectzBtnLabel: "Konnectz maken",
      taskhistory: "Taakgeschiedenis",
      success: "Succes",
      error: "Fout",
      notask: "Geen taak gevonden",
      tryagain: "Pas je filter aan en probeer het opnieuw"
    },
    errorMessages: {
      SENT_RESET_INSTRUCTIONS_SUCCESS: "Instructies voor het opnieuw instellen van het wachtwoord zijn per e-mail verzonden, controleer uw e-mail.",
      SENT_RESET_INSTRUCTIONS_FAIL: "Ongeoorloofd verzoek!",
      VERIFY_EMAIL_FAIL: "Sorry, kan je e-mail niet bevestigen.",
      PASSWORD_NOT_UPDATED: "Sorry, het updaten van je account is mislukt",
      GET_FORGOT_PASSWORD_LINK_SUCCESS: "",
      GET_FORGOT_PASSWORD_LINK_FAIL: "Kan de wachtwoordinstructies niet verzenden. Probeer het na een tijdje!",
      AUTH_SIGN_UP_FAIL: "Er is iets fout gegaan. Probeer het na een tijdje!",
      somethingWentWrong: "Er is iets fout gegaan. Probeer het na een tijdje!",
    },
    successMessages: {
      PASSWORD_UPDATED: "Wachtwoord is bijgewerkt. Gelieve in te loggen met uw nieuwe wachtwoord!",
    },
    history:{
      task:{
        task: "taak",
        tasks: "taken",
        taskdate: "taakdatum:",
        user: "Gebruiker :"
      }
  },
    auth: {
      signup: {
        createPassword: {
          title: "Maak een wachtwoord",
          subtitle: "Typ ze TWEE KEER om te bevestigen",
          passwordErrorMsg: "Ongeldig wachtwoord !",
          confirmPasswordErrorMsg: "Ongeldig wachtwoord !",
          passwordMatchingErrorMsg: "Beide wachtwoorden komen niet overeen!",
          continueBtnLabel: "DOORGAAN",
          createPasswordPlaceholder: "Maak een wachtwoord",
          confirmPasswordPlaceholder: "Bevestig wachtwoord",
          passwordRule:
            "Tip: Uw wachtwoord moet tussen de 8 en 15 tekens lang zijn en moet minimaal één hoofdletter, één kleine letter, één cijfer en één speciaal teken bevatten.",
          passwordRule1: "Uw wachtwoord moet tussen de 8 en 15 tekens lang zijn.",
          passwordRule2: "Uw wachtwoord moet minimaal één hoofdletter bevatten.",
          passwordRule3: "Uw wachtwoord moet ten minste één cijfer bevatten.",
          passwordRule4: "Uw wachtwoord moet minimaal één speciaal teken bevatten.",
        },
        verificationSent: {
          title: "VERIFICATIE",
          subtitle: "Bevestig e-mail adres",
          verificationText1: "We hebben de verificatielink verzonden op",
          verificationText2: ", open e-mail en verifieer uw e-mailadres.",
          didntReceiveEmailText: "Geen mail ontvangen?",
          resendText: "Opnieuw versturen",
        },
        verifySuccess: {
          title: "Verificatie voltooid",
          verifyFailedTitle: "Verificatie mislukt",
        },
        signupSuccess: {
          firstName: "Voornaam",
          firstNameErrorMsg: "Voornaam is verplicht !",
          lastName: "Achternaam",
          lastNameErrorMsg: "Achternaam is verplicht!",
          verifyErrorMsg: "Er is iets fout gegaan. Probeer in te loggen als uw account al is geverifieerd.",
        },
        title: "Maak een account aan",
        subtitle: "Hé, welkom",
        verifyingMessage: "U verifiëren",
        emailInvalid: "Email is ongeldig !",
        emailExists: "Het door u ingevulde e-mailadres is al geregistreerd",
        teamSignup: "Aanmelden als team?",
        continueBtnLabel: "Doorgaan",
        alreadyHaveAccountText: "Een account hebben ?",
        loginBtnLabel: "Inloggen",
        emailPlaceholder: "Voer email in",
        features: {
          feature1: {
            title: "Eenvoudige automatisering",
            image: SimpleAutomation,
            description: "Bouw complexe workflow eenvoudig en gemakkelijk in enkele minuten met behulp van onze visuele connect builder",
          },
          feature2: {
            title: "Platform voor iedereen",
            image: PlatformForEveryone,
            description:
            "Niet een gewone automatiseringstool alleen voor programmeurs, iedereen kan automatisering bouwen met KonnectzIT",
          },
          feature3: {
            title: "Naadloze integratie van apps",
            image: SeamlessAppsIntegration,
            description: "Verbind onbeperkt applicaties uit onze integratiebibliotheek en geautomatiseerde dagelijkse taken",
          },
        },
      },
      login: {
        title: "Inloggen op uw account",
        subtitle: "Hé, welkom terug!",
        emailPlaceholder: "Voer email in",
        emailInvalid: "Ongeldig e-mail !",
        passwordRequired: "Een wachtwoord is verplicht !",
        passwordPlaceholder: "Voer wachtwoord in",
        rememberMe: "Onthoud me",
        loginBtnLabel: "LOG NU IN",
        forFirstTimeSignup: "Voor de eerste keer ?",
        forgotPasswordLbl: "Wachtwoord vergeten",
        alreadyHaveAccountText: "Heb je geen account?",
        clickingContinueText: "Door op Doorgaan te klikken, gaat u akkoord met onze",
        termsAndConditionsText: "Algemene voorwaarden.",
        signupNowText: "Inschrijven",
        features: {
          feature1: {
            title: "Onbeperkt aantal stappen",
            image: UnlimitedSteps,
            description: "Verbind meerdere applicaties en bouw onbeperkte stappen voor naadloze automatisering.",
          },
          feature2: {
            title: "Alles visualiseren",
            image: VisualizeEverything,
            description: "Bouw uw workflows en stel u de automatisering eenvoudig voor met behulp van een visuele verbindingsbouwer.",
          },
          feature3: {
            title: "Integratie Bibliotheek",
            image: IntegrationLibrary,
            description: "Verzameling van cloudapplicaties om gegevens samen te voegen en te verzenden om tijd te besparen.",
          },
        },
        loginFailMsg: "Gebruikersnaam of wachtwoord is ongeldig!",
        captchaErrorMsg: "CAPTCHA foutmelding",
      },
      forgot: {
        title: "Wachtwoord vergeten",
        forgtoBtnLabel: "Krijg wachtwoord vergeten link",
        successSubTitle: "Email verzonden",
        features: {
          feature1: {
            title: "Eenvoudige automatisering",
            image: SimpleAutomation,
            description: "Bouw complexe workflow eenvoudig en gemakkelijk in enkele minuten met behulp van onze visuele connect builder",
          },
          feature2: {
            title: "Platform voor iedereen",
            image: PlatformForEveryone,
            description:
              "Geen reguliere automatiseringstool alleen voor programmeurs, iedereen kan automatisering bouwen met KonnectzIT",
          },
          feature3: {
            title: "Naadloze integratie van apps",
            image: SeamlessAppsIntegration,
            description: "Verbind onbeperkt applicaties uit onze integratiebibliotheek en geautomatiseerde dagelijkse taken",
          },
        },
        emailInvalid: "Ongeldig e-mail!",
      },
      reset: {
        title: "Wachtwoord opnieuw instellen",
        subTitle: "Nieuw wachtwoord aanmaken",
      },
    },
    konnect: {
      sidebar: {
        search_apps: "Zoekopdracht...",
        choose_trigger: "Kies Trigger",
        choose_trigger_header: "Kies App-evenement",
        no_triggers_available: "Geen triggers beschikbaar",
        choose_format_header: "Formaat optie:",
        choose_config_header: "Kies Configuratie",
        select_trigger_action_dropdown_header: "Select",
        choose_option_header: "Definieer optie",
        no_options_available: "Geen opties beschikbaar",
        choose_linked_account: "Selecteer account",
        customize_form_header: "Formulier configureren",
        customize_form_partial: "Kiezen",
        configure_response: "Reactie aanpassen",
        basic_auth: "Basisverificatie",
        api_key: "API sleutel",
        api_token: "API-geheim",
        webhook_url: "Webhook-URL",
        webhook_url_toast: "Webhook-URL gekopieerd naar klembord!",
        webhook_response: "Webhook-reactie",
        choose_action_event: " Kies actie-evenement",
        endpoint_url: "Eindpunt-URL",
        payload_type: "Type lading",
        wrap_request_array: "Verzoek in matrix inpakken",
        set_params: "Parameters instellen",
        add_header: "Koptekst toevoegen",
        set_paramter: "Parameter instellen:",
        expiry_time: "Tijdlijntelling",
        expiry_val: "Tijdlijn",
        segmentIndex: "Segmentindex",
        validate: "Valideren",
        generate: "genereren",
      },
      UI: {
        betaText: "bèta",
        webhookDuplicateToastMsg: "Webhook-app bestaat al!",
      },
      header: {
        backBtnLabel: "Rug",
        saveBtnLabel: "Sparen",
        publishBtnLabel: "Publiceren",
        nameOfKonnectz: "Naam van de Konnectz",
      },
      testAndReview: {
        btnLabel: "Testen en beoordelen",
      },
      proceed: {
        btnLabel: "DOORGAAN",
      },
      additionalConfigsModal: {
        cancelBtnLabel: "Annuleren",
        okBtnLabel: "oke",
      },
      conditions: {
        addNewConditionBtnLabel: "Nieuwe staat toevoegen +",
        cancelBtnLabel: "Annuleren",
        okBtnLabel: "oke",
        operatorAndLabel: "EN",
        operatorOrLabel: "OF",
        headerConditionText: "Voorwaarde",
        selectFieldHeaderTitle: "Selecteer veld",
        selectConditionHeaderTitle: "Selecteer voorwaarde",
        validateBtnLabel: "Valideren",
        conditionsHeaderText: "Conditie",
      },
      publishSuccessMsg: "Konnect is succesvol gepubliceerd!",
      saveSuccessMsg: "Konnect is succesvol opgeslagen!",
    },
    apps: {
      authenticatedAppsTitle: "Geverifieerde apps",
      aunthenticateNowTitle: "Nu authenticeren",
      noAppsToAunthenticate: "Er zijn momenteel geen apps om te verifiëren.",
      authenticateNow: "U heeft geen geverifieerde apps. Verifieer nu.",
      addAccountText: "Account toevoegen",
      betaText: "bèta",
      oAuthHandlerText: "KonnectzIT OAuth-handler, even geduld a.u.b.",
      cancelBtnText: "Annuleren",
      yesContinueText: "Ja, ga verder",
      requiredText: "(Vereist)",
      accessAPIModalHeaderText1: "KonnectzIT toegang geven",
      accessAPIModalHeaderText2: "rekening?",
      accessAPIModalMainText1: "U kunt uw . vinden",
      accessAPIModalMainText2: "door te navigeren naar",
      accessAPIModalMainText3: " integratiepagina",
      authorized: "Geautoriseerde apps"
    },
    konnectzList: {
      homeText: "Huis",
      noKonnectzInfo: "Geen konnectz op dit moment!",
      deleteModalBodyText: "Weet je zeker dat je wilt verwijderen ?",
      deleteModalNoText: "Nee",
      deleteModalDeleteText: "Verwijderen",
      folders: {
        createFolderTitleText: "Geef een naam voor je map",
        createFolderInputPlaceholder: "Naam van de map",
        createFolderBtn: "Creëren",
        deleteFolderConfirmationText: "Weet u zeker dat u deze map wilt verwijderen?",
        deleteBtn: "Verwijderen",
        selectFolderInfo: "Selecteer de map waarin u uw geselecteerde Konnectz wilt verplaatsen",
        moveFolderCancelBtn: "Annuleren",
        moveBtn: "Beweging",
        renameSaveBtn: "Sparen",
        renameYourFolderText: "Hernoem je map",
        createFolderBtn: "Map aanmaken"
      }
    },
    dashboard: {
      widgets: {
        latestApps: {
          headerTitle: "Nieuwste apps",
        },
        totalKonnectz: {
          headerTitle: "Totaal aantal Konnectz",
          activeKonnectz: "Actieve Konnectz",
          inactiveKonnectz: "Inactieve Konnectz",
        },
        latestKonnectz: {
          headerTitle: "Laatste Konnectz",
        },
        subscription: {
          contentText: "Ontgrendel meer kracht door Pro . te upgraden",
          upgradeBtnLabel: "WAARDEER NU OP",
        },
        taskConsumption: {
          headerTitle: "Taakverbruik",
        },
      },
    },
    account: {
      countryRequiredText: "Land is verplicht!",
      addressLine1RequiredText: "Adresregel 1 is verplicht!",
      cityRequiredText: "Stad is verplicht!",
      stateRequiredText: "Staat is verplicht!",
      zipCodeRequiredText: "Postcode is verplicht!",
      taxIdRequiredText: "Belastingnummer is vereist!",
      cityLabel: "Stad",
      stateLabel: "Staat",
      zipCodeLabel: "Postcode",
      taxIdLabel: "BTW-nummer",
      addressLine2Label: "Adres lijn 2",
      addressLine1Label: "Adres regel 1",
      countryLabel: "Land",
      billingtext:"factuur adres",
      myProfileText: "Mijn profiel",
      emailAddressLabel: "E-mailadres",
      firstNameLabel: "Voornaam",
      lastNameLabel: "Achternaam",
      firstNameRequiredText: "Voornaam is verplicht!",
      lastNameRequiredText: "Achternaam is verplicht!",
      companyNameLabel: "Bedrijfsnaam",
      timeZoneLabel: "Tijdzone",
      changeLanguageLabel: "Taal wijzigen",
      changePasswordHeaderText: "Wachtwoord wijzigen",
      currentPasswordLabel: "huidig ​​wachtwoord",
      currentPasswordRequiredText: "Huidig ​​wachtwoord is vereist!",
      newPasswordLabel: "nieuw paswoord",
      newPasswordRequiredText: "Nieuw wachtwoord is vereist!",
      confirmPasswordLabel: "bevestig wachtwoord",
      confirmPasswordRequiredText: "Bevestig dat wachtwoord vereist is!",
      updateBtnLabel: "Bijwerken",
      editUpdateAccountSideBarText: "Account bewerken/bijwerken",
      changePasswordSideBarText: "Wachtwoord wijzigen",
      billingText: "Facturering",
      teamText: "Team",
      agencyText: "Bureau",
      sideBarHeaderAccountText: "Rekening",
      profileUpdatedSuccessfullyText: "Profiel succesvol bijgewerkt!",
      addTeamMemberText: "Teamlid toevoegen",
      addSubAccountsText: "Subaccounts toevoegen",

      billing: {
        couponCodeText: "Code",
        applyCodeBtnLbl: "Code toepassen",
        tasksText: "Taken",
        redeemedOnText: "ingewisseld op",
        expiresOnText: "Verloopt op",
        noCouponsAppliedText: "Geen licentie aangevraagd",
        plansAndFeaturesText: "Abonnementen en functies",
        currentPlanText: "Huidige plan",
        upgradeText: "Upgrade",
        billingText: "Facturering",
        licenseerror:"Ongeldige licentie!",
        completionofprofile:"Vul uw profiel in voordat u de code inwisselt. Dit is vereist voor GPDR/gegevensverwerking en factureringsdoeleinden",
      },
      team: {
        name: "Naam",
        firstName: "Voornaam",
        lastName: "Achternaam",
        access: "Toegang",
        email: "E-mail",
        addBtn: "Toevoegen",
        cancelBtn: "Annuleren",
        assignAccessLbl: "Toegang toewijzen",
        noUsersInfo: "Geen gebruikers beschikbaar",
        teamMembersHeaderText: "Leden van het team",
      },
      agency: {
        name: "Klantnaam :",
        firstName: "Voornaam",
        lastName: "Achternaam",
        tasks: "Taak toegewezen:",
        consumed:"Taak verbruikt:",
        totalSubaccounts: "Totaal aantal subaccounts:",
        email: "E-mail:",
        active: "Actief :",
        inActive: "Inactief :",
        addBtn: "Toevoegen",
        cancelBtn: "Annuleren",
        noAgenciesInfo: "Geen agentschappen beschikbaar",
        agencyMembersHeaderText: "Subaccounts",
        total:"Totaal aantal taken:",
        update:"Bijwerken",
        updateTask: "Taak bijwerken",
        delete: "Verwijderen",
        available: "Taak beschikbaar",
        deactivate: "Activeren",
        activate: "Deactiveren",
        subaccounterror:"Het bijwerken van de subaccountgegevens is mislukt!"

      },
    },
    sideNav: {
      homeLabel: "Huis",
      appsLabel: "Apps",
      konnectzLabel: "Konnectz",
      historyLabel: "Geschiedenis",
      accountLabel: "Rekening",
      notificationsLabel: "Meldingen",
      logoutLabel: "Uitloggen",
      getHelpLabel: "Hulp krijgen?",
      konnectzITLogoText: "KonnectzIT",
    },
  },
}
