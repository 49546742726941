import React, { useEffect, useState } from "react"
import { Box, Text, Flex, Link,Image } from "rebass"
import { Input, Button } from "src/components"
import { faFolder } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw"
import AccessAccountSvg from "src/assets/images/access-account"
import { Icon, DecoratorIcon } from "../../components/Icon/Icon"
import { faTimesCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { TaskDetails } from "./taskDetails"
import { forSuccess, forError, tasks } from "./1.js"
import { Dropdown } from "../../components/Konnect/DropdownMenu/Dropdown"
import InfiniteScroll from "react-infinite-scroll-component"
import Cross from "../../assets/images/ErrorInHistory.svg"
import Success from "../../assets/images/SuccessInHistory.svg"
//import './style.css'
import 'antd/dist/antd.css';
//import './index.css';
import { svg_1 } from "../../assets/images/svg"
import { Select } from 'antd';
import { NewItem } from "./item"
const { Option } = Select;

export const TaskDetailsModal = ({ task, moveFolder, taskDetail, taskLogs, historyType,
  setHistoryType, logsPageIndex, fetchMoreLogs, logsList,setNewId,setExpandTask,newId,
  setLogsPageIndex }) => {
  const { t } = useTranslation()
  const [selectedFolder, setSelectedFolder] = useState(null)
  const [updateLogs,setUpdateLogs] = useState(logsList)
  console.log(updateLogs,"updateLogs1")
  console.log(logsList, "logsList",setExpandTask)

  const fetchMore = () => {
    console.log("122")
    setLogsPageIndex(logsPageIndex + 1)
  }
  // useEffect(() => {
  //   setNewId(taskLogs[0].id)
  //   setExpandTask(newId)
  // },[])
  // const handleChangeId=(x) => {
  //   setExpandTask(x)
  //   console.log("hiii",x)
  // }
  const handleChange = (value) => {

    console.log(`selected ${value}`)
    setLogsPageIndex(logsPageIndex + 1)
  };
  console.log(taskLogs, "taskLogs",logsList,task)
  console.log(forSuccess.new_success[0].data[0].time)
  console.log(historyType, "historyType")
  return (
    <>
      <Flex flexDirection="column">
      <NewItem />
        <Flex flexDirection="row" sx={{ borderRadius: "16px", px: px2vw(20), padding: "5px",alignItems:"center" }}>
          <Box
            sx={{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",

              boxShadow: "1px 1px 1px 1px #e6e2e2",
              borderRadius: "5px",
           
            }}
          >
            {historyType === "success" ? <Success style={{ margin: "6px" }} height="20px" width="20px" fill={"orange"} /> :
              <Cross style={{ margin: "6px" }} height="20px" width="20px" fill={"#ff464b"} />}

          </Box>

          <Text sx={{ m: "10px", color: historyType === "success" ? "orange" : "red", fontSize: "1rem" }}>{historyType === "success" ? "Success -" : "Error -"}</Text>
          <Box sx={{ m: "10px", color: "#152F73", fontSize: "17px", fontWeight: "semi-bold" }}>
            {task.left_app.name}
            {" Entry funnel "}
            {task.right_apps.map(x => {
              return <>{" - " + x.app_name + "  "}</>
            })}
          </Box>
          <Flex  sx={{ flexDirection: "column", gap: ["6px", "8px", "10px", "12px"] }}>
 
  <Flex width="100%" sx={{ position: "relative" }}>
    <Image width="40px"  height="40px" src={task.left_app.image_url} />
    {task.right_apps.length > 0 && (
                          <Flex
                            sx={{
                              m: "auto 0px",
                              fill: "black",
                            }}
                          >
                            {svg_1.dashed_1}
                          </Flex>
                        )}
    {task.right_apps.length > 0 &&
      task.right_apps.map((rightApp, index) => {
        return (
          <>
          {index !== 0 && (
            <Flex
              sx={{
                m: "auto 0px",
                fill: "black",
              }}
            >
              {svg_1.dashed_1}
            </Flex>
          )}

          <Image width="40px"  height="40px"src={rightApp.image_url} />
        </>
        )
      })}
  </Flex>
</Flex>
        </Flex>
        <Box sx={{ background: "#BCC6CC", width: "inherit", height: "1px", margin: "2px -20px" }}></Box>

        <Flex>
          <Box
            id="scrollableDiv1"
            sx={{
              margin: "4px, 4px",
              padding: "4px",
              m: "15px",
              width: "480px",
              height: "550px",
              overflowX: "hidden",

              overflowY: "auto",
              scrollbarWidth: "none",
              textAlign: "justify",
            }}

          >
            <Flex sx={{ justifyContent: "space-between" }}  >
              <Text sx={{ color: historyType == "success" ? "#1E419D" : "red", margin: "20px", fontSize: "21px", fontWeight: "bold" }}>
                {historyType ==="success"?
                "Successes" :"Errors" }#{"("}
                {logsList.length}
                {")"}
              </Text>

              {/* <Text sx={{ color: "gray", margin: "20px" }} className="select">
                <select style={{border:"none" ,background:"white",padding:"5px",borderRadius:"10px" }} id="slct">
                <option value="All" selected>
                    All
                  </option>
                <option value="Last Day" style={{border:"1px solid red" }}>Last Day</option>
                <option value="Last Week">Last Week</option>
                  <option value="Last Month">Last Month</option>

                  
                </select>
              </Text> */}
              {/* <Box sx={{ m: "20px" }}  >
                <Select
                  defaultValue="All"
                  style={{
                    width: 140,
                  }}
                  onChange={handleChange}
                >
                  <Option value="All">All</Option>
                  <Option value="Last day">Last Day</Option>

                  <Option value="Last Week">Last Week</Option>
                  <Option value="Last Month">Last Month</Option>
                </Select>
              </Box> */}
            </Flex>

            <InfiniteScroll
              style={{ display: "flex", flexDirection: "column" }}
              dataLength={logsList && logsList.length || 0} //This is important field to render the next data
              next={fetchMoreLogs}
              hasMore={true}
              pullDownToRefresh={false}
              scrollableTarget="scrollableDiv1"
            //  loader={<h4>Loading...</h4>}
            >

              {logsList && logsList.map((val, i) => (
                
                 <NewItem task={val} i={i}  key={val.id + "-" + i} setExpandTask={setExpandTask} setNewId={setNewId}/>
                
              ))}
            </InfiniteScroll>
   
          </Box>
          <Box
            sx={{
              margin: "4px, 4px",
              padding: "4px",
              backgroundColor: "#F3F7FF",
              width: "650px",
              height: "550px",
              overflowX: "hidden",
              overflowY: "auto",
              textAlign: "justify",
              borderRadius: "12px",
              margin: "16px",
            }}
          >
            {console.log(Object.keys(taskDetail))}
            {/* <Flex flexDirection="column" sx={{ my: '5px' }}>
                        {Object.keys(taskDetail.data).length > 0 && Object.keys(taskDetail.data).map((item, idx) => (
                            <Text fontSize={{ _: 12, sm: 12, md: 12, lg: 14, xl: 16 }} sx={{ fontWeight: "500", color: "#5E5E5E" }}>{item + ' : ' + taskDetail.data[Object.keys(taskDetail.data)[idx]]}</Text>
                        ))}
                    </Flex> */}
            {/* {Object.keys(taskDetail).forEach((i) => {
              console.log(i,taskDetail[i],"2321")
            })}
           {taskDetail.map(({ key, value }) => ({ [key]: value }))} */}
            
               <Flex flexDirection="column" sx={{ justifyContent: "space-between" ,p:"15px" }}>
            {taskDetail && taskDetail.length > 0 && taskDetail.map((detail, idx) => (
                <Flex key={idx + '_' + detail.id} flexDirection="column" sx={{ my: '20px' }}>
                     <Text sx={{ fontSize: "24px", fontWeight: "bold" }}>Found 1 Response in {detail.app}</Text>
                     <Text sx={{ fontSize: "16px ", color: "gray" }}>Created :{detail.task_date}</Text>

<Text sx={{ fontSize: "16px ", color: "gray" }}>form :{detail.data.coupon_code}</Text>
<Text sx={{ fontSize: "16px ", color: "gray" }}>User Name : {detail.user}</Text>                  
                    <Flex flexDirection="column" sx={{ my: '5px' }}>
                        {Object.keys(detail.data).length > 0 && Object.keys(detail.data).map((item, idx) => (
                            <Text sx={{ fontSize: "16px ", color: "gray" }}>{item + ' : ' + detail.data[Object.keys(detail.data)[idx]]}</Text>
                           
                        ))}
                    </Flex>
                </Flex>
            ))}
        </Flex>
            {/* {forSuccess.new_success[0].data[0].time} */}
            {/* {tasks.map(x => (
              <>
                <Box
                  sx={{
                    width: "400px",
                    h: "50px",
                    backgroundColor: "#F3F7FF",
                    margin: "20px",
                    p: "10px",
                    borderRadius: "12px",
                    
                    color:"#1E419D",
                    "&:hover": {
                      color: "#ffffff",
                      backgroundColor: "#1E419D"
                    },
                  }}
                >
                  <Text sx={{
                      
                    }}> Success #{x}</Text>
                  <Text sx={{fontSize:"12px"}}> 11-07-2022 19:52:29 +00:00 </Text>
                </Box>
              </>
            ))} */}
          </Box>
          <Box>{/* <TaskDetails taskDetails= {taskDetails}/> */}</Box>
        </Flex>
      </Flex>
    </>
  )
}
