import { createSlice, current, store } from "../../../../../node_modules/@reduxjs/toolkit/dist"
import { applyNodeChanges, applyEdgeChanges, addEdge as addFlowEdge } from "react-flow-renderer"
import { v4 as uuidv4 } from "uuid"
import { isEqual, isEmpty, set, includes } from "lodash"
import GetAppType from "../../Component/konnect/Node/NodeWrapper"
import { CustomEdgeButton } from "../../Component/konnect/Edges"
const EdgeType = {
  buttonedge: "buttonedge",
}
const edgeTypes = {
  buttonedge: CustomEdgeButton,
}
import {
  captureWebHookResponse,
  FlattenJSON,
  getAppEventConfig,
  getAppEvents,
  getLinkedAccounts,
  onDeleteNodeV2,
  getAppEventConfigDetail,
  getAppEventConfigDetailFetch,
  appTestAndReview,
  getConditionsList,
  generateportsId,
  getConditionsList1,
  getKonnect,
  getAppEventConfigDetailCustomKeymap,
  getAppEventConfigDetailFetchKeyMap,
  getAppEventConfigDetailCustom,
  getAppEventConfigDetailFetchuserDefined,
} from "src/features/ReactFlow/features/index"
const apiAppInitialState = {
  endpointURL: "",
  payloadType: [{ id: 1, name: "JSON", selected: true }],
  wrapRequestInArray: [
    { id: 1, name: "NO", selected: true },
    { id: 2, name: "YES", selected: false },
  ],
  headers: [{ key: "" }],
  params: [{ key: "" }],
}

const couponInitialState = {
  expiryNum: "",
  duration: [
    { id: 1, name: "Days", selected: true },
    { id: 2, name: "Week", selected: false },
    { id: 3, name: "Months", selected: false },
    { id: 4, name: "Years", selected: false },
  ],
}

const textSplitterInitialState = {
  segmentIndex: [
    { id: 1, name: "First", selected: true },
    { id: 2, name: "Second", selected: false },
    { id: 3, name: "Last", selected: false },
    { id: 4, name: "Second to Last", selected: false },
    { id: 5, name: "All", selected: false },
  ],
}

const initialState = {
  canvas: {
    editorMeta: {
      sidebar: { hidden: false },
      deleteLink: false,
      onWheel: true,
      deleteLinkPos: {
        x: 0,
        y: 0,
      },
      link: null,
      dirty: false,
      canPublish: false,
      canEdit: false,
    },
    apps: {},
    addOnApps: [],
    konnects: [],
    authorisedApps: [],
    appEvents: [],
    conditionsList: [],
    appEventConfig: [],
    appEventConfigDetails: [],
    history: {
      /*
              maintain only the props that needs to be tracked. Merge to state when needed
              {
              nodes: [],
              ports: [],
              links: [],  
              payload: {}
              }
              nodes: [],
              ports: [],
              links: [],
              */
      past: [],
      future: [],
      present: {
        nodes: [],
        links: [],
      },
    },

    apiAppInitialState: apiAppInitialState,
    customAppData: {
      coupon: couponInitialState,
      textSplitter: textSplitterInitialState,
    },
    gSheetPopUp: false,
    payload: {},
    testAndReviewLoader: false,
    editorState: {
      offset: {
        x: -5000,
        y: -5000,
      },
      scale: 1,
      handles: [],
      links: [],
      nodes: [],

      selected: {},
      hovered: {},
      konnect_activity_id: null,
      konnect_name: "",
      konnect_id: null,
      canvas_name: "ReactFlow",
    },
    canedit: false,
    //enableCaptureWebhookResponseBtn: true
  },
  toggleToast: false,
  toastContent: null,
  reactFlowInstance: null,
  RedoUndoNodes: [],
}

export const canvasSlice = createSlice({
  name: "canvas",
  initialState,
  reducers: {
    onCanvasDrop: (state, action) => {
      const { id: nodeId, data } = action.payload
data.editable=false
      if (data.type === "ADD_ON" && data.provider.toLowerCase() === "textsplitter") {
        data.appDetails = state.canvas.customAppData
      }
      if (data.type === "ADD_ON" && data.provider.toLowerCase() === "api") {
        data.api = state.canvas.apiAppInitialState
      }
      if (data.type === "ADD_ON" && data.provider.toLowerCase() === "generator") {
        data.appDetails = state.canvas.customAppData //= couponInitialState
      }
      if (data.type === "ADD_ON" && data.provider === "conditioner") {
        data.appEvent = 3
        data.configResponses = [
          {
            app_event_id: 3,
            app_id: data.id,
            config_key: "conditions_setup",
            config_key_required: false,
            fetch_fields: false,
            id: "conditions_setup",
            key_value_type: "button",
            label: "",
            sequence: 0,
            side: "left",
            port: {
              id: `${data.nodeid.toString()}|${data.id.toString()}|3|conditions_setup`,
              source: `${data.nodeid.toString()}|${data.id.toString()}|3|"conditions_setup"` + "|" + "source",
              target: `${data.nodeid.toString()}|${data.id.toString()}|3|"conditions_setup"` + "|" + "target",
            },
            source: {
              id: `${data.nodeid.toString()}|${data.id.toString()}|undefined|"conditions_setup"` + "|" + "source",
            },
            target: {
              id: `${data.nodeid.toString()}|${data.id.toString()}|undefined|"conditions_setup"` + "|" + "target",
            },
          },
        ]
      }

      state.canvas.editorState.nodes.push(action.payload)
      let curr = {
        nodes: state.canvas.editorState.nodes,
        links: state.canvas.editorState.links,
      }
      {
        const lastHistory = state.canvas.history.present

        if (lastHistory && !isEqual(lastHistory, curr)) {
          state.canvas.history.past.push(state.canvas.history.present)
          state.canvas.history.present = curr
        }
      }
    },
    updateNode: (state, action) => {
      state.canvas.editorState.nodes = state.canvas.editorState.nodes.map(node => {
        if (node.data.id === action.payload.data.id) {
          return { ...node, data: action.payload.data }
        }
        return node
      })
    },
    updateNodes: (state, action) => {
      state.canvas.editorState.nodes = action.payload
    },
    updateEdge: (state, action) => {
      state.canvas.editorState.links = action.payload
    },
    updateToggleToast: (state, action) => {
      state.toggleToast = action.payload
    },
    updateToastContent: (state, action) => {
      state.toastContent = action.payload
    },
    onNodesChange: (state, action) => {
      state.canvas.editorState.nodes = applyNodeChanges(action.payload, state.canvas.editorState.nodes)
    },
    onEdgesChange: (state, action) => {
      state.canvas.editorState.links = applyEdgeChanges(action.payload, state.canvas.editorState.links)
    },
    onToggleDrag: (state, action) => {
      const node = state.canvas.editorState.nodes.find(x => x.id === action.payload.nodeid)
      node.className = action.payload.focused
    },
    onLinkComplete: (state, action) => {
      const { c, EdgeType } = action.payload
      const getId = () => uuidv4() //id for nodes
      let newEdge = {
        ...c,
        type: EdgeType.buttonedge,
        // className: "nodrag"
      }
      const nodes = state.canvas.editorState.nodes
      const links = state.canvas.editorState.links
      //  state.canvas.editorState.links = [...state.canvas.editorState.links, newEdge]
      const { id, sourceHandle, targetHandle, source, target } = c
      // const chart = inValue.editorState
      const sourcePortInfo = JSON.parse(sourceHandle.split("|")[1])
      const targetPortInfo = JSON.parse(targetHandle.split("|")[1])

      const config = {}
      const linkId = id
      const fromNodeId = source //node source
      const fromPortId = `${sourcePortInfo.node}|${sourcePortInfo.app}|${sourcePortInfo.app_event_id}|${
        sourceHandle.split("|")[0]
      }`
      const toNodeId = target

      let linkExists = false
      //all links except current one
      const allLinks = state.canvas.editorState.links.filter(x => x.id !== linkId)
      //early exit; if from same source
      if (fromNodeId === toNodeId) {
        state.canvas.editorState.links = allLinks
      }

      // early exit; if target already linked

      const toNode = nodes.find(x => x.data.nodeid === toNodeId).data
      const fromNode = nodes.find(z => z.data.nodeid === fromNodeId).data
      const { type = "", provider = "" } = toNode
      linkExists = links.filter(x => x.id === id).length //? true : false

      if (
        (linkExists && type !== "ADD_ON") ||
        (linkExists && type === "ADD_ON" && provider.toLowerCase() === "dateformatter")
      ) {
        state.canvas.editorState.links = allLinks
      }
      if (type === "ADD_ON" && (provider.toLowerCase() === "conditioner" || provider.toLowerCase() === "api")) {
        let isFromSameNode = false

        const fromNodeLinks = state.canvas.editorState.links.filter(d => d.target === toNode.nodeid)
        if (fromNodeLinks.length && fromNodeLinks[0].source === fromNode.nodeid) {
          isFromSameNode = true
        }

        if (fromNodeLinks.length && !isFromSameNode) {
          // chart.links = allLinks
          // const _chart = fromJS(chart)
          state.canvas.editorState.links = allLinks
        } else {
          if (!toNode.fromFields) toNode.fromFields = []
          toNode.fromFields.push({ ...sourcePortInfo, linkId })
          toNode.parentNode = fromNodeId
          toNode.parent_konnect_activity_id = fromNode.konnect_activity_id
        }
      }

      if (type === "ADD_ON" && sourcePortInfo.type === "source") {
        if (!toNode.fromPorts) {
          toNode.fromPorts = []
        }

        const newEntry = {
          info: sourcePortInfo,
          id: sourcePortInfo.port,
          display: sourcePortInfo.label,
          linkId,
        }
        if (toNode.fromPorts.filter(h => isEqual(newEntry, h)).length) {
          state.canvas.editorState.links = links.pop()(
            (state.canvas.editorState.links = state.canvas.editorState.links)
          )
          // const _chart = fromJS(chart)
        }
        toNode.fromPorts.push(newEntry)
      }

      const configResponse = toNode.configResponses.find(
        x => x.config_key === targetHandle.split("|")[0] || x.id === targetHandle.split("|")[0]
      )
      configResponse.dirty = false

      if (configResponse.value && type !== "ADD_ON") {
        state.canvas.editorState.links = allLinks
      }

      linkExists = links.find(x => x.id === linkId)

      if (linkExists) {
        linkExists.target = toNodeId
        linkExists.targetHandle = targetHandle
      }

      linkExists = links.filter(x => x.targetHandle === targetHandle).length ? true : false
      if (linkExists && toNode.type !== "ADD_ON") {
        // toNode.tested = false
        // toNode.reviewed = false
        // let editorMeta = state.canvas.editorMeta
        // editorMeta.canPublish = false
        // const fromField = fromNode.configResponses.find(
        //   x => x.config_key === sourceHandle.split("|")[0] || x.id === sourceHandle.split("|")[0]
        // )
        // const toField = toNode.configResponses.find(
        //   x => x.config_key === targetHandle.split("|")[0] || x.id === targetHandle.split("|")[0]
        // )

        // toField.value = fromField.value

        // const nodeIdx = nodes.findIndex(x => x.data.nodeid === fromNode.nodeId)

        // if (fromNode.type === "WEBHOOK_API" && nodeIdx) {
        //   toField._id = fromField.id
        // }
        state.canvas.editorState.links = allLinks
      } else {
        toNode.tested = false
        toNode.reviewed = false
        let editorMeta = state.canvas.editorMeta
        editorMeta.canPublish = false
        const fromField = fromNode.configResponses.find(
          x => x.config_key === sourceHandle.split("|")[0] || x.id === sourceHandle.split("|")[0]
        )
        const toField = toNode.configResponses.find(
          x => x.config_key === targetHandle.split("|")[0] || x.id === targetHandle.split("|")[0]
        )

        toField.value = fromField.value

        const nodeIdx = nodes.findIndex(x => x.data.nodeid === fromNode.nodeId)

        if (fromNode.type === "WEBHOOK_API" && nodeIdx) {
          toField._id = fromField.id
        }
        state.canvas.editorState.links = [...state.canvas.editorState.links, newEdge]
      }

      // state.canvas.editorState.links = [...state.canvas.editorState.links, newEdge]

      // state.canvas.editorState.links = [...state.canvas.editorState.links, newEdge]
      let curr = {
        nodes: state.canvas.editorState.nodes,
        links: state.canvas.editorState.links,
      }
      {
        const lastHistory = state.canvas.history.present

        if (lastHistory && !isEqual(lastHistory, curr)) {
          state.canvas.history.past.push(state.canvas.history.present)
          state.canvas.history.present = curr
        }
      }
    },
    onDeleteNode: (state, action) => {
      state.canvas.editorState.nodes = state.canvas.editorState.nodes.filter(n => n.id !== action.payload.nodeid)
      state.canvas.editorState.links = state.canvas.editorState.links.filter(
        l => l.source !== action.payload.nodeid && l.target !== action.payload.nodeid
      )
      let curr = {
        nodes: state.canvas.editorState.nodes,
        links: state.canvas.editorState.links,
      }
      {
        const lastHistory = state.canvas.history.present

        if (lastHistory && !isEqual(lastHistory, curr)) {
          state.canvas.history.past.push(state.canvas.history.present)
          state.canvas.history.present = curr
        }
      }

      // state.canvas.history.present.nodes= state.canvas.editorState.nodes
    },
    toggleBasicAuth: (state, action) => {
      const { enabled, nodeId, data } = action.payload
      const node = state.canvas.editorState.nodes.find(x => x.id === nodeId)
      // const _state = changeHistory(inValue, "toggleBasicAuth", inValue.editorState)
      ;(node.data.enabled = enabled), (node.data.apiKey = ""), (node.data.secretKey = "")
      let curr = {
        nodes: state.canvas.editorState.nodes,
        links: state.canvas.editorState.links,
      }
      {
        const lastHistory = state.canvas.history.present

        if (lastHistory && !isEqual(lastHistory, curr)) {
          state.canvas.history.past.push(state.canvas.history.present)
          state.canvas.history.present = curr
        }
      }
    },
    onWebhookAuthChange: (state, action) => {
      const { type, nodeId, input, data } = action.payload
      const node = state.canvas.editorState.nodes.find(x => x.id === nodeId)
      node.data[type] = input
      node.data.reviewed = false
      node.data.tested = false
      let curr = {
        nodes: state.canvas.editorState.nodes,
        links: state.canvas.editorState.links,
      }
      {
        const lastHistory = state.canvas.history.present

        if (lastHistory && !isEqual(lastHistory, curr)) {
          state.canvas.history.past.push(state.canvas.history.present)
          state.canvas.history.present = curr
        }
      }
    },
    updateToastContent: (state, action) => {
      state.toastContent = action.payload
    },
    updateToggleToast: (state, action) => {
      state.toggleToast = action.payload
    },
    setEventsConfig: (state, action) => {
      const {
        data,
        nodeId,
        appId,
        eventId,
        eventConfigId,
        source,
        sequence,
        selectedEventConfigId,
        selectedEventConfigName,
        eventSequence,
      } = action.payload
      const node = state.canvas.editorState.nodes.find(x => x.id === nodeId)
      const eventSeq = node.data.appEventConfigurations.find(x => x.sequence === sequence.sequence)
      node.data.eventSequence = eventSequence ? eventSequence : null
      eventSeq.selected = sequence
      let seq = node.data.appEventSequences.findIndex(
        x => x.sequence === sequence.sequence && x.config_key === sequence.config_key
      )

      if (seq !== -1) {
        node.data.appEventSequences[seq] = sequence
        node.data.appEventSequences = node.data.appEventSequences.slice(0, seq + 1)
        // node.data.configResponses = []
      } else {
        node.data.appEventSequences.push(sequence)
      }
      state.canvas.editorMeta.canPublish = false
    },
    handleRefresh: (state, action) => {
      let data = action.payload
      const node = state.canvas.editorState.nodes.find(n => n.id === action.payload.nodeid)
      const ndx = state.canvas.editorState.nodes.findIndex(n => n.id === action.payload.nodeid)
      let newNode = {
        ...node,
        data: {
          id: data.id,
          name: data.name,
          image: data.image,
          background: data.background,
          associatedAccounts: data.associatedAccounts ? data.associatedAccounts : "",
          webhook_enabled: false,
          webhook_instructions: data.webhook_instructions ? data.webhook_instructions : "",
          webhook_url: data.webhook_url ? data.webhook_url : "",
          provider: data.provider,
          type: data.type,
          auth_type: data.auth_type ? data.auth_type : "",
          nodeid: data.nodeid,
          appEvent: null,
          appEventConfigurations: [],
          appEventSequences: [],
          appEvents: data.appEvents ? data.appEvents : "",
          configResponses: [],
          tested: false,
          reviewed: false,
          isloading: false,
          showTestAndReview: true,
          linkedAccounts: data.linkedAccounts ? data.linkedAccounts : "",
          configResponse: [],
        },
      }
      state.canvas.editorState.nodes[ndx] = newNode
      //also add code for links here to delete on refresh
    },
    setConfigMenuState: (state, action) => {
      state.canvas.editorMeta.onWheel = !action.payload
    },
    turnWebhookResNull: (state, action) => {
      const nodeIdx = state.canvas.editorState.nodes.findIndex(n => n.id === action.payload.nodeid)
      state.canvas.editorState.nodes[nodeIdx].data.configResponses = []
      state.canvas.editorState.nodes[nodeIdx].data.configResponsesOrig = []
      state.canvas.editorState.nodes[nodeIdx].data.display_message = null
      state.canvas.editorState.nodes[nodeIdx].data.reviewed = false
    },

    setLinkedAccount: (state, action) => {
      const { selected, data } = action.payload
      const nodeIdx = state.canvas.editorState.nodes.findIndex(n => n.id === data.nodeid)
      state.canvas.editorState.nodes[nodeIdx].data.selectedAccount = selected
      state.canvas.editorState.nodes[nodeIdx].data.tested = false
      state.canvas.editorState.nodes[nodeIdx].data.reviewed = false
      state.canvas.editorState.nodes[nodeIdx].data.appEventSequences = []
      state.canvas.editorState.nodes[nodeIdx].data.appEventConfigurations = []
      state.canvas.editorState.nodes[nodeIdx].data.appEventConfigurationDetails = []
      state.canvas.editorState.nodes[nodeIdx].data.configResponse = []
      state.canvas.editorState.nodes[nodeIdx].data.configResponses = []
      state.canvas.editorState.nodes[nodeIdx].data.configResponsesOrig = []
      state.canvas.editorState.links = state.canvas.editorState.links.filter(
        l => l.source !== data.nodeid && l.target !== data.nodeid
      )
      state.canvas.editorState.nodes[nodeIdx].data.display_message = null
      state.canvas.editorState.nodes[nodeIdx].data.tested = false

      let curr = {
        nodes: state.canvas.editorState.nodes,
        links: state.canvas.editorState.links,
      }
      {
        const lastHistory = state.canvas.history.present

        if (lastHistory && !isEqual(lastHistory, curr)) {
          state.canvas.history.past.push(state.canvas.history.present)
          state.canvas.history.present = curr
        }
      }
    },
    onPortTextChange: (state, action) => {
      const { nodeId, portId, config_key, input, webhook = false, eventSequence } = action.payload
      const node = state.canvas.editorState.nodes.find(x => x.id === nodeId)
      const config = node.data.configResponses.findIndex(c => c.config_key === config_key)
      node.data.tested = false
      node.data.reviewed = false
      node.data.display_message = ""
      node.data.configResponses[config].value = input
      let deletedLinks = state.canvas.editorState.links?.filter(x => x.source === node.id)
      state.canvas.editorState.links = state.canvas.editorState.links.filter(x => x.source !== node.id)
      // console.log(current(deletedLinks),"deletedLinks")
      let curr = {
        nodes: state.canvas.editorState.nodes,
        links: state.canvas.editorState.links,
      }
      {
        const lastHistory = state.canvas.history.present

        if (lastHistory && !isEqual(lastHistory, curr)) {
          state.canvas.history.past.push(state.canvas.history.present)
          state.canvas.history.present = curr
        }
      }
    },
    onDeleteLink: (state, action) => {
      const id = action.payload
      const linkData = state.canvas.editorState.links.find(x => x.id === id)
      const fromNodeId = linkData.source
      const toNodeId = linkData.target

      const nodeIdx = state.canvas.editorState.nodes.findIndex(c => c.id === toNodeId)
      const nodetobe = state.canvas.editorState.nodes.find(c => c.id === toNodeId)
      nodetobe.data.test_status = null
      nodetobe.data.display_message = ""
      let nodes = state.canvas.editorState.nodes
      let links = state.canvas.editorState.links
      if (nodeIdx !== -1 && nodes[nodeIdx].data.fromPorts) {
        nodes[nodeIdx].data.fromPorts = nodes[nodeIdx].data.fromPorts.filter(port => port.linkId !== id)
      }

      const currentLinks = links.filter(x => x.target === toNodeId)
      const currentNodeIdx = nodes.findIndex(x => x.data.nodeid === toNodeId)
      const currentNode = nodes[currentNodeIdx]

      if (currentNode.data.type === "ADD_ON" && currentNode.data.provider === "conditioner") {
        nodes[currentNodeIdx].data.fromFields = nodes[currentNodeIdx].data.fromFields.filter(x => x.linkId !== id)

        nodes[currentNodeIdx].data.conditions = nodes[currentNodeIdx].data.conditions
          ? nodes[currentNodeIdx].data.conditions.filter(condition => condition.field.key !== id)
          : []

        let conditionsTxt = nodes[currentNodeIdx].data.conditions.map(condition => {
          let queryOperatorText = condition.queryOperator === undefined ? "" : condition.queryOperator.toUpperCase()
          return (
            condition.field.label + " " + condition.operator + " " + condition.value + " " + queryOperatorText + "\r\n"
          )
        })
        nodes[currentNodeIdx].data.conditionsText = conditionsTxt
        if (!currentLinks.length) {
          nodes[currentNodeIdx].data.additionalResponses = []
          const firstPort = { ...nodes[currentNodeIdx].data.configResponses[0] }
          nodes[currentNodeIdx].data.configResponses = [firstPort]
          // links = edges.filter(x => x.source !== toNodeId)
          nodes[currentNodeIdx].data.conditions = []
          nodes[currentNodeIdx].data.conditionsText = ""
          nodes[currentNodeIdx].data.reviewed = false
          nodes[currentNodeIdx].data.tested = false
          nodes[currentNodeIdx].data.showTestAndReview = false
        }
      }

      const targethandle = JSON.parse(linkData.targetHandle.split("|")[1])
      const { node, app, appEvent, config_key, port, id: targetid } = targethandle
      const portid = `${node.toString()}|${app.toString()}|${
        appEvent === null || appEvent === undefined ? "undefined" : appEvent.toString()
      }|${targetid.toString()}`

      const portid2 = `${node.toString()}|${app.toString()}|${
        appEvent === null || appEvent === undefined ? "undefined" : appEvent.toString()
      }|${port.toString()}`
      const configIdx = nodes[currentNodeIdx].data.configResponses.findIndex(
        c => c.port.id === portid || c.port.id === portid2
      )
      if (configIdx !== -1) {
        nodetobe.data.configResponses[configIdx].value = ""
      }
      if (
        config_key.includes(">") &&
        currentNode.data.appEventConfigurations.indexOf ===
          currentNode.data.appEventConfigurations[currentNode.data.appEventConfigurations.length - 1]
      ) {
        currentNode.data.configResponses = []
      }
      state.canvas.editorState.links = state.canvas.editorState.links.filter(e => e.id !== id)
      state.canvas.editorState.nodes = nodes
      let curr = {
        nodes: state.canvas.editorState.nodes,
        links: state.canvas.editorState.links,
      }
      {
        const lastHistory = state.canvas.history.present

        if (lastHistory && !isEqual(lastHistory, curr)) {
          state.canvas.history.past.push(state.canvas.history.present)
          state.canvas.history.present = curr
        }
      }
    },
    enableTestReviewLoader: (state, action) => {
      let node = state.canvas.editorState.nodes[action.payload]
      node.data.isloading = true
    },
    marksdirty: (state, action) => {
      // state.canvas.editorState = action.payload
      // state.canvas.editorState.ts = new Date().getTime()
      // state.canvas.editorMeta.dirty = true
    },
    updateDuration: (state, action) => {
      let node = state.canvas.editorState.nodes.find(n => n.id === action.payload.data.nodeid)
      node.data.appDetails.coupon.duration = action.payload.list1
    },
    updateExpiryNum: (state, action) => {
      let node = state.canvas.editorState.nodes.find(n => n.id === action.payload.data.nodeid)
      node.data.appDetails.coupon.expiryNum = action.payload.value
    },
    updateUrl: (state, action) => {
      const { value, nodeIdx } = action.payload
      state.canvas.editorState.nodes[nodeIdx].data.api.endpointURL = value
    },
    updateWrapRequestInArray: (state, action) => {
      const { value, nodeIdx } = action.payload
      state.canvas.editorState.nodes[nodeIdx].data.api.wrapRequestInArray = value
    },
    updateHeadersAndParams: (state, action) => {
      const { headers, params, nodeIdx, newNodeDetails, nodeId } = action.payload
      const node = state.canvas.editorState.nodes.find(n => n.id === nodeId)
      const { data } = node
      const chart = state.canvas.editorState
      chart.nodes[nodeIdx].data.api.headers = headers
      chart.nodes[nodeIdx].data.api.params = params
      chart.nodes[nodeIdx].data.configResponses = [...generateportsId({ data: data, response: newNodeDetails })]
    },
    updatePayloadType: (state, action) => {
      const { value, nodeIdx } = action.payload
      const chart = state.canvas.editorState
      chart.nodes[nodeIdx].data.api.payloadType = value
    },
    modifyConditions: (state, action) => {
      const chart = state.canvas.editorState
      const { data, conditionsText, conditions } = action.payload
      // state.canvas.editorState.links = chart.links.filter(x => x.source === data.nodeid)
      const currentNodeIdx = chart.nodes.findIndex(x => x.id === data.nodeid)

      let parentNode = null
      chart.nodes[currentNodeIdx].data.conditionsText = conditionsText
      chart.nodes[currentNodeIdx].data.conditions = conditions
      chart.nodes[currentNodeIdx].data.showTestAndReview = true
      let curr = {
        nodes: state.canvas.editorState.nodes,
        links: state.canvas.editorState.links,
      }
      {
        const lastHistory = state.canvas.history.present

        if (lastHistory && !isEqual(lastHistory, curr)) {
          state.canvas.history.past.push(state.canvas.history.present)
          state.canvas.history.present = curr
        }
      }
    },
    expandCollapseNode: (state, action) => {
      const { nodeId, sectionId } = action.payload

      const nodes = state.canvas.editorState.nodes
      const links = state.canvas.editorState.links

      const node = nodes.find(x => x.data.nodeid === nodeId)

      const section = node.data.configResponses.find(x => x.id === sectionId)

      const collapseAll = parentId => {
        const section = node.data.configResponses.findIndex(x => x.id === parentId)
        node.data.configResponses[section].isOpen = false
        if (node.data.configResponses[section].type === "node") {
          node.data.configResponses[section].children.map(childKey => {
            let child = node.data.configResponses.findIndex(x => x.id === childKey)
            if (node.data.configResponses[child].type === "leaf") {
              node.data.configResponses[child].isOpen = false
            } else {
              node.data.configResponses[child].isAvailable = false
              node.data.configResponses[child].isOpen = false

              node.data.configResponses[child].children.map(x => {
                collapseAll(x)
              })
            }
          })
        }
      }
      if (section.isOpen) {
        //close all children; including sub children

        collapseAll(section.id)
      } else {
        //open up to its immediate child
        section.isOpen = true
        section.children.map(x => {
          const item = node.data.configResponses.find(y => y.id === x)

          if (item.type === "node") {
            item.isAvailable = true
          } else {
            item.isOpen = true
          }
        })
      }

      node.data.configResponses = node.data.configResponses.map(x => {
        return { ...x, ts: new Date().getTime() }
      })
      links = links.map(x => {
        return { ...x, ts: new Date().getTime() }
      })
    },
    createAdditionalFields: (state, action) => {
      const { nodeId, fields } = action.payload
      const node = state.canvas.editorState.nodes.find(x => x.data.nodeid === nodeId)
      const newFields = fields.map(f => `out.${f.config_key}`)
      const additionalFields = node.data.configResponses.filter(d => d.additional).map(d => d.config_key)
      const diff = additionalFields.filter(c => !newFields.includes(c))

      //remove links
      diff.map(p => {
        const port = node.data.configResponses.find(q => q.config_key === p).port.id
        //have to check for links
        state.canvas.editorState.links = state.canvas.editorState.links.filter(l => l.source !== port)
      })

      node.data.configResponses = node.data.configResponses.filter(g => !diff.includes(g.config_key))
      fields.map(f => {
        if (!node.data.configResponses.find(h => h.config_key === `out.${f.config_key}`))
          node.data.configResponses.push({
            app_event_id: node.data.appEvent,
            app_id: node.data.id,
            config_key: `out.${f.config_key}`,
            config_key_required: false,
            id: `out.${f.id}`,
            key_value_type: "input",
            label: f.label,
            sequence: 0,
            side: "right",
            additional: true,
            value: f.value,
            disabled: true,
            mapped: true,
            port: {
              id: `${node.data.nodeid.toString()}|${node.data.id.toString()}|${
                node.data.appEvent === null || node.data.appEvent === undefined
                  ? "undefined"
                  : node.data.appEvent.toString()
              }|out.${f.id.toString()}`,
              source:
                `${node.data.nodeid.toString()}|${node.data.id.toString()}|${
                  node.data.appEvent === null || node.data.appEvent === undefined
                    ? "undefined"
                    : node.data.appEvent.toString()
                }|out.${f.id.toString()}` +
                "|" +
                "source",
              target:
                `${node.data.nodeid.toString()}|${node.data.id.toString()}|${
                  node.data.appEvent === null || node.data.appEvent === undefined
                    ? "undefined"
                    : node.data.appEvent.toString()
                }|out.${f.id.toString()}` +
                "|" +
                "target",
            },
            source: {
              id:
                `${node.data.nodeid.toString()}|${node.data.id.toString()}|${
                  node.data.appEvent === null || node.data.appEvent === undefined
                    ? "undefined"
                    : node.data.appEvent.toString()
                }|out.${f.id.toString()}` +
                "|" +
                "source",
            },
          })
      })

      node.data.configResponses = node.data.configResponses.map(x => {
        return { ...x, ts: new Date().getTime() }
      })

      let t = state.canvas.editorState.links.map(x => {
        return { ...x, ts: new Date().getTime() }
      })
    },
    addCustom: (state, action) => {
      const { idToFunction: data, seq, eventSequence } = action.payload
      const node = state.canvas.editorState.nodes.find(n => n.id === data.nodeid)
      let params
      let num
      if (seq === null) {
        num = 0
      } else {
        num = seq.sequence
      }
      if (data.appEventSequences) {
        data.appEventSequences.map(x => {
          params = x.config_key
        })
      }
      let hasSequence =
        data.appEventConfigurations && data.appEventConfigurations.filter(x => x.sequence !== 0).length ? true : false

      let configForms = []

      if (hasSequence) {
        configForms = data.appEventConfigurations[seq]
      }
      node.data.eventSequence = eventSequence
      node.data.configResponses = [
        ...node.data.configResponses,
        ...generateportsId({
          data: node.data,
          response: [
            {
              app_event_id: Number(`${configForms.app_event_id}`),
              app_id: Number(`${configForms.app_id}`),
              config_key: `${configForms.config_key}` + ">>" + "custom",
              config_key_type: "custom",
              sequence: Number(`${configForms.sequence}`),

              config_key_required: false,
              fetch_fields: false,
              id: `${configForms.config_key}`,
              portId: Number(`${configForms.id}`),
              key_value_type: "list",
              label: `${configForms.label}` + ">>" + "custom",
            },
          ],
        }),
      ]
      // data.appEventConfigurationDetails.push({
      //   app_event_id: `${configForms[configForms.length - 1].app_event_id}`,
      //   app_id: `${configForms[configForms.length - 1].app_id}`,
      //   config_key: `${configForms[configForms.length - 1].config_key}` + ">>" + "custom",
      //   config_key_type: "custom",
      //   sequence: `${configForms[configForms.length - 1].sequence}`,

      //   config_key_required: false,
      //   fetch_fields: false,
      //   id: `${configForms[configForms.length - 1].id}`,
      //   key_value_type: "list",
      //   label: `${configForms[configForms.length - 1].label}` + ">>" + "custom",
      // })
      // data.appEventConfigurations.push({
      //   app_event_id: Number(`${configForms[configForms.length - 1].app_event_id}`),
      //   app_id: Number(`${configForms[configForms.length - 1].app_id}`),
      //   config_key: `${configForms[configForms.length - 1].config_key}` + ">>" + "custom",
      //   sequence: Number(`${configForms[configForms.length - 1].sequence}`),

      //   config_key_required: true,
      //   fetch_fields: false,
      //   id: Number(`${configForms[configForms.length - 1].id}`),
      //   key_value_type: "list",
      //   label: `${configForms[configForms.length - 1].label}` + ">>" + "custom",
      //   side: `${configForms[configForms.length - 1].side}`,
      //   type: "custom",
      // })
      node.data.appEventSequences = [
        ...node.data.appEventSequences,
        {
          config_key: `${configForms.config_key}`,
          //appId: data.id,
          //data,
          eventConfigId: Number(`${configForms.sequence}`),
          nodeId: data.nodeid,
          //selectedEventConfigId: "",
          //selectedEventConfigId: "",
          eventId: data.appEvent,
          sequence: {
            sequence: `${configForms.sequence}`,
            id: "",
            name: "",
            config_key: `${configForms.config_key}`,
          },
          id: "",
          source: false,
        },
      ]
    },
    filterLinks: (state, action) => {
      const { appEventSequences } = action.payload

      let filtereEdge = appEventSequences[appEventSequences.length - 1].config_key
      state.canvas.editorState.links = state.canvas.editorState.links.filter(
        e => e.targetHandle.split("|")[0] !== filtereEdge
      )
    },
    filterConfigs: (state, action) => {
      const data = action.payload
      const node = state.canvas.editorState.nodes.find(n => n.id === data.nodeid)
      let ConfigKey = data.appEventSequences[data.appEventSequences.length - 1].config_key
      node.data.appEventConfigurationDetails = node.data.appEventConfigurationDetails.filter(
        x => x.config_key === ConfigKey
      )

      // let lengthEventSequence=data.appEventConfigurations.length
      node.data.configResponses = node.data.configResponses.filter(
        d => d.id !== ConfigKey && d.config_key_type === "custom"
      )

      node.data.appEventSequences.pop()
      // cust.pop()
      // configForms = []
      // cust = []
      //     if(indexOfConfig===lengthEventSequence-1){

      //       return (
      //     configForms=[],

      //       setShow(false),
      // cust.pop(),
      //         data.configResponses = data.configResponses.filter(x => x.config_key_type !== "custom"),
      //         data.appEventConfigurationDetails= xyz,
      //         data.appEventSequences.pop(),
      //         data.configResponses=[],
      //         impConfigs=[],
      //         configForms.pop()

      //       )

      //     }
      //     else {
      //       return (

      //         data.configResponses = data.configResponses.filter(x => x.config_key_type !== "custom"),
      //         data.appEventConfigurationDetails= xyz,
      //         data.appEventSequences.pop(),
      // cust.pop(),
      // configForms=[]

      //       )
      //     }
    },
    undo: (state, action) => {
      const history = state.canvas.history
      const chart = state.canvas.editorState
      const currentState = {
        nodes: chart.nodes,
        links: chart.links,
        ports: chart.ports,
      }

      if (!history.past.length && !history.future.length && history.present) {
        state = state
      }

      if (!history.past.length) {
        state = state
      }

      history.future.push(history.present)
      history.present = history.past.pop()
      if (state.RedoUndoNodes.length) {
        let result

        history.present.nodes = history.present.nodes.map(n => {
          state.RedoUndoNodes.map(r => {
            if (n.id === r.id) {
              return (result = {
                ...n,
                data: {
                  id: n.data.id,
                  name: n.data.name,
                  image: n.data.image,
                  background: n.data.background,
                  associatedAccounts: n.data.associatedAccounts,
                  webhook_enabled: n.webhook_enabled === true ? true : false,
                  webhook_instructions: "",
                  webhook_url: n.data.webhook_url ? n.data.webhook_url : "",
                  provider: n.data.provider,
                  type: n.data.type,
                  nodeid: n.data.nodeid,
                  appEvent: null,
                  appEventConfigurations: [],
                  appEventSequences: [],
                  appEvents: n.data.appEvents,
                  webhookUrl: n.data.webhookUrl ? n.data.webhookUrl : "",
                  webhook: n.webhook ? n.webhook : "",
                  configResponses: [],
                  tested: false,
                  reviewed: false,
                  isloading: false,
                  showTestAndReview: true,
                  linkedAccounts: n.data.linkedAccounts,

                  configResponse: [],
                },
              })
            } else {
              return (result = { ...n })
            }
          })

          return result
        })
        history.present.links = history.present.links.filter(l => {
          return state.RedoUndoNodes.find(n => {
            return n.id !== l.target
          })
        })
      } else {
        state.canvas.editorState = currentChart
        state.canvas.history = history
      }

      const currentChart = {
        ...chart,
        ...history.present,
      }

      state.canvas.editorState = currentChart
      state.canvas.history = history

      //.set("history", _history)
    },
    redo: (state, action) => {
      const history = state.canvas.history
      const chart = state.canvas.editorState

      if (!history.past.length && !history.future.length && history.present) {
        state = state
      }

      if (!history.future.length) {
        state = state
      }

      history.past.push(history.present)
      history.present = history.future.pop()

      const currentChart = {
        ...chart,
        ...history.present,
      }

      state.canvas.editorState = currentChart
      state.canvas.history = history
    },
    saveKonnectName: (state, action) => {
      state.canvas.editorState.konnect_name = action.payload
    },
    clearState: (state, action) => {
      state.canvas.editorState = initialState.canvas.editorState
      state.canvas.history = initialState.canvas.history
    },
    addFieldstoconfig: (state, action) => {
      const { data, newFeilds } = action.payload
      let node = state.canvas.editorState.nodes.find(n => n.id === data.nodeid)
      let additionFields = data.additionalResponses.filter(a => {
        return newFeilds.find(n => {
          return n.id === a.id
        })
      })
      const { port, source, target, ...rest } = newFeilds
      node.data.configResponses = [...node.data.configResponses, ...generateportsId({ data, response: newFeilds })]
    },
    showChatGpt: (state, action) => {
      state.canvas.editorState.nodes = action.payload
    },
    onAppEventChange: (state, action) => {
      const { data, selected } = action.payload
      const node = state.canvas.editorState.nodes.find(n => n.id === data.nodeid)
      node.data.appEventSequences = []
      node.data.appEventConfigurations = []
      node.data.appEventConfigurationDetails = []
      node.data.configResponse = []
      node.data.configResponses = []
      node.data.configResponsesOrig = []

      node.data.appEvent = selected.id
      node.data.selectedEvent = selected
      node.data.selectedAccount = null
      state.canvas.editorState.links = state.canvas.editorState.links.filter(
        l => l.source !== data.nodeid && l.target !== data.nodeid
      )
      node.data.display_message = null
      node.data.tested = false
    },
    modifySegmentIndex: (state, action) => {
      // console.log(action, "action233333")
      let node = state.canvas.editorState.nodes.find(n => n.id === action.payload.data.nodeid)
      node.data.appDetails.textSplitter.segmentIndex = action.payload.list1
    },
    updateGsheetPop: (state, action) => {
      state.canvas.gSheetPopUp = action.payload
    },
    setDelayConfigInNode: (state, action) => {
      let node = state.canvas.editorState.nodes.find(n => n.id === action.payload.data.nodeid)
      node.data.delayConfigs = action.payload.delayConfigs
    },
    moveActionToInitialState: (state, action) => {
      state.canvas.editorState.links = []
      state.canvas.editorState.nodes = state.canvas.editorState.nodes.map((n, i) => {
        if (i > 0) {
          return {
            ...n,
            data: {
              konnect_activity_id: n.data.konnect_activity_id,
              id: n.data.id,
              name: n.data.name,
              image: n.data.image,
              background: n.data.background,
              associatedAccounts: n.data.associatedAccounts ? n.data.associatedAccounts : "",
              webhook_enabled: false,
              webhook_instructions: n.data.webhook_instructions ? n.data.webhook_instructions : "",
              webhook_url: n.data.webhook_url ? n.data.webhook_url : "",
              provider: n.data.provider,
              type: n.data.type,
              auth_type: n.data.auth_type ? n.data.auth_type : "",
              nodeid: n.data.nodeid,
              appEvent: null,
              appEventConfigurations: [],
              appEventSequences: [],
              appEvents: n.data.appEvents ? n.data.appEvents : "",
              configResponses: [],
              tested: false,
              reviewed: false,
              isloading: false,
              showTestAndReview: true,
              linkedAccounts: n.data.linkedAccounts ? n.data.linkedAccounts : "",
              configResponse: [],
            },
            type: n.type,
          }
        } else {
          return n
        }
      })
    },
  },
  extraReducers: builder => {
    //to store response for webHook
    builder.addCase(captureWebHookResponse.fulfilled, (state, action) => {
      const { data } = action.meta.arg
      const chart = state.canvas.editorState.nodes
      const node = chart.find(n => n.id === action.meta.arg.data.nodeid)
      if (action.payload.message && action.payload.message.toUpperCase() === "WAITING") {
        node.data.awaitingWebhookResponse = true
      } else {
        node.data.awaitingWebhookResponse = false

        const response = JSON.parse(JSON.stringify(action.payload.raw_response).replace(/\:null/gi, ':""'))
        node.data.webhookRawResponse = response
        // node.webhookResponse = FlattenJSON(response)
        let responseWithHandleinfo = FlattenJSON(response)
        const newResponse = generateportsId({ data, response: responseWithHandleinfo })
        node.data.configResponsesOrig = newResponse
        node.data.configResponses = newResponse
      }
      const { raw_response, test_response, ...rest } = action.payload

      node.data.tested = true
      node.data.reviewed = action.payload.test_status.toLowerCase() === "success"
      node.data = { ...node.data, ...rest }

      let curr = {
        nodes: state.canvas.editorState.nodes,
        links: state.canvas.editorState.links,
      }
      {
        const lastHistory = state.canvas.history.present

        if (lastHistory && !isEqual(lastHistory, curr)) {
          state.canvas.history.past.push(state.canvas.history.present)
          state.canvas.history.present = curr
        }
      }
    }),
      builder.addCase(getAppEventConfig.fulfilled, (state, action) => {
        const { data } = action.meta.arg
        const node = state.canvas.editorState.nodes.find(x => x.id === action.meta.arg.nodeId)

        node.data.Type = action.meta.arg.type //have to work

        node.data.reviewed = false
        node.data.tested = false

        const configs = action.payload.sort((x, y) => {
          return x.sequence - y.sequence
        })
        const hasSeqZero = configs.filter(x => x.sequence === 0).length
        if (hasSeqZero) {
          let _configs = configs.filter(x => x.sequence === 0)
          if (node.data.type === "ADD_ON" && node.data.provider.toLowerCase() === "dateformatter") {
            _configs = _configs.reverse()
          }
          node.data.configResponses = generateportsId({ data, response: [..._configs] })
          node.data.configResponsesOrig = generateportsId({ data, response: [..._configs] })
        } else {
          if (node.data.type != "ADD_ON" && node.data.provider != "api") {
            node.data.configResponses = []
            node.data.configResponsesOrig = []
          }
        }
        node.data.appEventConfigurations = configs
        if (node.data.provider.toLowerCase() !== "scheduler") {
          node.data.appEventConfigurations = configs.filter(x => x.sequence !== 0)
        }
        // node.data.appEventConfigurations = configs.filter(x => x.sequence !== 0)
        node.data.appEvent = action.meta.arg.eventId
        node.data.appEventSequences = []
        node.data.appEventConfigurationDetails = []
      }),
      builder.addCase(onDeleteNodeV2.fulfilled, (state, action) => {
        const nodeId = action.meta.arg.nodeId
        const node = state.canvas.editorState.nodes.find(n => n.id === nodeId)
        state.RedoUndoNodes = [...state.RedoUndoNodes, node]
      }),
      builder.addCase(getAppEvents.fulfilled, (state, action) => {
        const node = state.canvas.editorState.nodes.find(x => x.id === action.meta.arg.id)

        if (node) {
          const nodeIdx = state.canvas.editorState.nodes.findIndex(n => n.id === action.meta.arg.id)

          let _events

          if (nodeIdx === 0) {
            _events = action.payload.filter(x => x.side === "left")
          } else {
            _events = action.payload.filter(x => x.side === "right")
          }

          node.data.appEvents = _events
          node.data.configResponse = []
        }
      }),
      builder.addCase(getLinkedAccounts.fulfilled, (state, action) => {
        const node = state.canvas.editorState.nodes.find(x => x.id === action.meta.arg.id)
        const nodeIdx = state.canvas.editorState.nodes.findIndex(n => n.id === action.meta.arg.id)

        if (node) {
          node.data.linkedAccounts = action.payload.authorized_app_accounts
          // if (action.payload.authorized_app_accounts.length === 1) {
          //   node.data.selectedAccount = action.payload.authorized_app_accounts[0]
          // }
        }
      }),
      builder.addCase(getAppEventConfigDetail.fulfilled, (state, action) => {
        const node = state.canvas.editorState.nodes.find(x => x.id === action.meta.arg.nodeId)

        node.data.appEventConfigurationDetails = node.data.appEventConfigurationDetails.filter(
          c => c.config_key !== action.meta.arg.config_key
        ) //.push("custom value")
        const configDetails = action.payload.map(x => {
          return { ...x, config_key: action.meta.arg.config_key }
        })

        node.data.reviewed = false
        node.data.tested = false
        node.data.appEventConfigurationDetails = [...node.data.appEventConfigurationDetails, ...configDetails]
        let curr = {
          nodes: state.canvas.editorState.nodes,
          links: state.canvas.editorState.links,
        }
        {
          const lastHistory = state.canvas.history.present

          if (lastHistory && !isEqual(lastHistory, curr)) {
            state.canvas.history.past.push(state.canvas.history.present)
            state.canvas.history.present = curr
          }
        }
      }),
      builder.addCase(getAppEventConfigDetailFetch.fulfilled, (state, action) => {
        const sourceFields = action.payload.map(x => {
          return {
            app_event_id: action.meta.arg.eventId,
            app_id: action.meta.arg.appId,
            config_key: x.config_key ? x.config_key : x.name,
            config_key_required: x.config_key_required,
            id: x.id,
            label: x.name,
            placeholder: x.placeholder || x.name,
            key_value_type: x.key_value_type ? x.key_value_type : "display",
            type: "source",
          }
        })
        const node = state.canvas.editorState.nodes.find(x => x.data.nodeid === action.meta.arg.nodeId)
        const { data } = node
        node.data.reviewed = false
        node.data.tested = false
        const _configs = [...sourceFields]
        if (node.data.type === "ADD_ON" && node.data.provider.toLowerCase() === "dateformatter") {
          _configs = _configs.reverse()
        }
        //config_key_type
        node.data.configResponses = node.data.configResponses.filter(c => c.config_key_type === "custom")
        node.data.configResponsesOrig = node.data.configResponsesOrig.filter(c => c.config_key_type === "custom")
        state.canvas.editorMeta.canPublish = false

        node.data.configResponses = [
          ...node.data.configResponses,
          ...generateportsId({ data, response: [..._configs] }),
        ]
        node.data.configResponsesOrig = [
          ...node.data.configResponsesOrig,
          ...generateportsId({ data, response: [..._configs] }),
        ]
        let curr = {
          nodes: state.canvas.editorState.nodes,
          links: state.canvas.editorState.links,
        }
        {
          const lastHistory = state.canvas.history.present

          if (lastHistory && !isEqual(lastHistory, curr)) {
            state.canvas.history.past.push(state.canvas.history.present)
            state.canvas.history.present = curr
          }
        }
      }),
      builder.addCase(appTestAndReview.fulfilled, (state, action) => {
        const { data } = action.meta.arg
        if (action.payload?.test_status === "Success") {
          const {
            condition_activity_id,
            config_fields,
            display_message,
            error,
            konnect_activity_id,
            konnect_id,
            pass_through_condition,
            raw_response,
            test_status,
            delay_activity_id,
            delay_config,
          } = action.payload

          const nodeIdx = state.canvas.editorState.nodes.findIndex(x => x.data.nodeid === data.nodeid)
          const node = state.canvas.editorState.nodes.find(x => x.data.nodeid === data.nodeid)
          const currentLinks = state.canvas.editorState.links.filter(x => x.target === data.nodeid)
          const nodes = state.canvas.editorState.nodes
          let parentNode = null
          node.data.tested = true
          node.data.isloading = false
          node.data.reviewed = test_status ? test_status.toLowerCase() === "success" : false
          node.data.display_message = display_message

          node.data.additionalResponses = FlattenJSON(raw_response)
          node.data.condition_activity_id = condition_activity_id
          node.data.config_fields = config_fields
          node.data.error = error
          node.data.konnect_activity_id = konnect_activity_id
          node.data.delay_activity_id = delay_activity_id
          node.data.konnect_id = konnect_id
          node.data.pass_through_condition = pass_through_condition
          node.data.raw_response = raw_response
          node.data.test_status = test_status
          node.data.delay_config = delay_config
          node.data = { ...node.data, ...action.payload }
          state.canvas.editorMeta.canPublish = true
          node.data.configResponses = node.data.configResponses.map(q => {
            return {
              ...q,
              error: null,
            }
          })
          node.data.configResponses = node.data.configResponses.filter(q => !q.additional)

          if (node.data.type === "ADD_ON" && node.data.provider === "conditioner") {
            if (nodes[nodeIdx].data.configResponses.length === 1 && currentLinks.length) {
              parentNode = nodes.find(x => x.data.nodeid === currentLinks[0].source)
              const newConfigResponses = parentNode.data.configResponses.map(x => {
                const props = _.pick(x, [
                  "app_event_id",
                  "app_id",
                  "config_key",
                  "id",
                  "label",
                  "type",
                  "value",
                  "isOpen",
                  "level",
                  "children",
                ])
                return props
              })
              nodes[nodeIdx].data.configResponses = [
                ...nodes[nodeIdx].data.configResponses,
                ...generateportsId({ data, response: newConfigResponses }),
              ]
              nodes[nodeIdx].data.additionalResponses = parentNode.additionalResponses
                ? [...parentNode.additionalResponses]
                : []
              if (node.data.reviewed) {
                //data = nodes[nodeIdx].data

                nodes[nodeIdx].data.pass_through_condition = true
                nodes[nodeIdx].data.condition_activity_id = konnect_activity_id
              }
            }
          }
          if (node.data.pass_through_condition) {
            node.data.pass_through_condition = true
            node.data.condition_activity_id = konnect_activity_id
            node.data.konnect_activity_id = konnect_activity_id
          }
          if (node.data.pass_through_condition) {
            node.data.pass_through_condition = true
            node.data.condition_activity_id = konnect_activity_id
            node.data.konnect_activity_id = konnect_activity_id
          }
          let gsheetPop = false
          if (
            (node.data.provider === "google-form" || node.data.provider === "google_sheet") &&
            node.data.konnect_custom
          ) {
            gsheetPop = true
          }
          state.canvas.gSheetPopUp = gsheetPop
          if (!node.data.reviewed) {
            if (response.errors) {
              Object.keys(response.errors).map(o => {
                const field = node.data.configResponses.findIndex(p => p.config_key === o)
                if (field !== -1) {
                  node.data.configResponses[field].error =
                    response.errors[o].message || `${node.data.configResponses[field].label} is not valid`
                }
              })
            }
          }
          const configFields = node.data.reviewed
            ? typeof config_fields === "string"
              ? JSON.parse(config_fields)
              : config_fields
            : {}

          node.data.configResponses = node.data.configResponses.map(x => {
            return {
              ...x,
              ts: new Date().getTime(),
              value: configFields[x.config_key] || configFields[x.id] || x.value || null,
              mapped: !!(configFields[x.config_key] || configFields[x.id]),
            }
          })
          if (node.data.reviewed) {state.canvas.editorState.konnect_activity_id = action.payload.konnect_activity_id
          }
          if (node.data.type === "ADD_ON" && node.data.provider === "api") {
            if (!(action.payload.errors && action.payload.errors.message) && action.payload.test_status != "Failure") {
              let response = typeof raw_response === "string" ? JSON.parse(raw_response) : raw_response
              node.data.additionalResponses = []

              node.data.additionalResponses = FlattenJSON(response)
            }
          }
          let curr = {
            nodes: state.canvas.editorState.nodes,
            links: state.canvas.editorState.links,
          }
          {
            const lastHistory = state.canvas.history.present

            if (lastHistory && !isEqual(lastHistory, curr)) {
              state.canvas.history.past.push(state.canvas.history.present)
              state.canvas.history.present = curr
            }
          }
        } else {
          const nodeIdx = state.canvas.editorState.nodes.findIndex(n => n.id === data.nodeid)

          state.canvas.editorState.nodes[nodeIdx].data.isloading = false
          state.canvas.editorState.nodes[nodeIdx].data.display_message = action.payload?.display_message
          state.canvas.editorState.nodes[nodeIdx].data.konnect_activity_id = action.payload?.konnect_activity_id
          state.canvas.editorState.nodes[nodeIdx].data.delay_activity_id = action.payload?.delay_activity_id
          state.canvas.editorState.ts = new Date().getTime()
          state.canvas.editorMeta.dirty = true
          state.canvas.editorState.nodes[nodeIdx].data.configResponses = state.canvas.editorState.nodes[
            nodeIdx
          ].data.configResponses.map(x => {
            if (x.config_key_required && !x.value) {
              return { ...x, dirty: true }
            } else {
              return x
            }
          })
          state.canvas.editorState.nodes[nodeIdx].data.isloading = false
          state.canvas.editorState.nodes[nodeIdx].data.display_message = action.payload.display_message
          state.canvas.editorState.nodes[nodeIdx].data.konnect_activity_id = action.payload.konnect_activity_id
          state.canvas.editorState.nodes[nodeIdx].data.delay_activity_id = action.payload.delay_activity_id
          state.canvas.editorState.ts = new Date().getTime()
          state.canvas.editorMeta.dirty = true
        }
      }),
      builder.addCase(getConditionsList.fulfilled, (state, action) => {
        state.canvas.conditionsList = action.payload
      }),
      builder.addCase(getKonnect.fulfilled, (state, action) => {
        state.canvas.canedit = true

        if (action.payload.canvas_json.canvas_name === "ReactFlow") {
          const history = {
            past: [],
            present: {
              nodes: action.payload.canvas_json.nodes,
              links: action.payload.canvas_json.links,
            },
            future: [],
          }
          state.canvas.editorState ={... action.payload.canvas_json,nodes:
            action.payload.canvas_json.nodes.map(node=>{
              return {
                ...node,data:{
                  ...node.data,editable:true
                }
              }
            }),
          }
          state.canvas.history = history
        } else {
          let appDetails, expiryNum, duration // for couponGen and TextSplitter

          const getAppDetails = (name, data, tested) => {
            // if (tested) {
            switch (name) {
              case "Coupon Generator":
                if (tested) {
                  let initialDate
                  let expiryDate = data.raw_response.expiry_date
                  action.payload.activity_dates.map(node => {
                    if (node.konnect_activity_id === data.konnect_activity_id) {
                      initialDate = node.created_at.substring(0, 10)
                    }
                  })
                  let initialDateFiltered = new Date(
                    initialDate.substring(5, 7) + "/" + initialDate.substring(8, 10) + "/" + initialDate.substring(0, 4)
                  )
                  let expiryDateFiltered = new Date(
                    expiryDate.substring(5, 7) + "/" + expiryDate.substring(8, 10) + "/" + expiryDate.substring(0, 4)
                  )
                  var Difference_In_Time = expiryDateFiltered.getTime() - initialDateFiltered.getTime()
                  expiryNum = (Difference_In_Time / (1000 * 3600 * 24)).toString()

                  duration = [
                    { id: 1, name: "Days", selected: true },
                    { id: 2, name: "Week", selected: false },
                    { id: 3, name: "Months", selected: false },
                    { id: 4, name: "Years", selected: false },
                  ]
                  appDetails = {
                    ...appDetails,
                    coupon: {
                      expiryNum: expiryNum,
                      duration: duration,
                    },
                  }
                  return appDetails
                } else {
                  appDetails = {
                    ...appDetails,
                    coupon: {
                      expiryNum: "",
                      duration: [
                        { id: 1, name: "Days", selected: true },
                        { id: 2, name: "Week", selected: false },
                        { id: 3, name: "Months", selected: false },
                        { id: 4, name: "Years", selected: false },
                      ],
                    },
                  }
                  return appDetails
                }

              case "TextSplitter":
                if (tested) {
                  let result_data = data.raw_response.Result
                  let splitResult = data.raw_response.text.split(data.raw_response.separator)
                  if (!Array.isArray(result_data)) {
                    if (splitResult[0] === data.raw_response.Result) {
                      appDetails = {
                        ...appDetails,
                        textSplitter: {
                          segmentIndex: [
                            { id: 1, name: "First", selected: true },
                            { id: 2, name: "Second", selected: false },
                            { id: 3, name: "Last", selected: false },
                            { id: 4, name: "Second to Last", selected: false },
                            { id: 5, name: "All", selected: false },
                          ],
                        },
                      }
                    } else if (splitResult[splitResult.length - 1] === data.raw_response.Result) {
                      appDetails = {
                        ...appDetails,
                        textSplitter: {
                          segmentIndex: [
                            { id: 1, name: "First", selected: false },
                            { id: 2, name: "Second", selected: false },
                            { id: 3, name: "Last", selected: true },
                            { id: 4, name: "Second to Last", selected: false },
                            { id: 5, name: "All", selected: false },
                          ],
                        },
                      }
                    } else if (
                      splitResult[splitResult.length - 2] === data.raw_response.Result &&
                      splitResult[splitResult.length - 2] !== splitResult[1]
                    ) {
                      appDetails = {
                        ...appDetails,
                        textSplitter: {
                          segmentIndex: [
                            { id: 1, name: "First", selected: false },
                            { id: 2, name: "Second", selected: false },
                            { id: 3, name: "Last", selected: false },
                            { id: 4, name: "Second to Last", selected: true },
                            { id: 5, name: "All", selected: false },
                          ],
                        },
                      }
                    } else if (splitResult[1] === data.raw_response.Result) {
                      appDetails = {
                        ...appDetails,
                        textSplitter: {
                          segmentIndex: [
                            { id: 1, name: "First", selected: false },
                            { id: 2, name: "Second", selected: true },
                            { id: 3, name: "Last", selected: false },
                            { id: 4, name: "Second to Last", selected: false },
                            { id: 5, name: "All", selected: false },
                          ],
                        },
                      }
                    }
                  } else {
                    appDetails = {
                      ...appDetails,
                      textSplitter: {
                        segmentIndex: [
                          { id: 1, name: "First", selected: false },
                          { id: 2, name: "Second", selected: false },
                          { id: 3, name: "Last", selected: false },
                          { id: 4, name: "Second to Last", selected: false },
                          { id: 5, name: "All", selected: true },
                        ],
                      },
                    }
                  }
                  return appDetails
                } else {
                  appDetails = {
                    ...appDetails,
                    textSplitter: {
                      segmentIndex: [
                        { id: 1, name: "First", selected: true },
                        { id: 2, name: "Second", selected: false },
                        { id: 3, name: "Last", selected: false },
                        { id: 4, name: "Second to Last", selected: false },
                        { id: 5, name: "All", selected: false },
                      ],
                    },
                  }
                  return appDetails
                }
              default:
                return null
            }
            // }
          }

          const { nodes, links } = action.payload.canvas_json
          let arr = []

          let newNodes = nodes.map((n, i) => {
            if (i > 0) {
              arr.push(i)
            }
            let data = {
              Type: n.type,
              nodeid: n.nodeId,
              // auth_type:null,
              appDetails: getAppDetails(n.name, n, n.tested),
              appEvent: n.appEvent,
              appEventConfigurations: n.appEventConfigurations,
              appEventSequences: n.appEventSequences,
              appEvents: n.appEvents,
              configResponses: n.configResponses,
              tested: n.tested,
              reviewed: n.reviewed,
              isloading: n.isloading,
              showTestAndReview: n.showTestAndReview,
              additionalResponses: n.additionalResponses,
              associatedAccounts: n.associatedAccounts,
              background: n.background,
              condition_activity_id: n.condition_activity_id,
              configResponsesOrig: n.configResponsesOrig,
              config_fields: n.config_fields,
              display_message: n.display_message,
              error: n.error,
              id: n.appId,
              image: n.image,
              konnect_activity_id: n.konnect_activity_id,
              selectedEvent: { ...n.appEvents.find(a => a.id === n.appEvent) },
              konnect_id: n.konnect_id,
              linkedAccounts: n.linkedAccounts,
              name: n.name,
              order: n.order ? n.order : null,
              pass_through_condition: n.pass_through_condition,
              provider: n.provider,
              raw_response: n.raw_response,
              selectedAccount: n.selectedAccount,
              test_status: n.test_status,
              type: n.appType,
              type: n.appType,
            }
            return {
              id: n.nodeId,
              position: { x: 500 + 500 * i, y: 300 },
              data: {
                Type: n.type,
                nodeid: n.nodeId,
                // auth_type:null,
                appDetails: getAppDetails(n.name, n),
                appEvent: n.appEvent,
                appEventConfigurations: n.appEventConfigurations,
                appEventSequences: n.appEventSequences,
                appEvents: n.appEvents,
                configResponses: n.configResponses,
                tested: n.tested,
                reviewed: n.reviewed,
                isloading: n.isloading,
                showTestAndReview: n.showTestAndReview,
                additionalResponses: n.additionalResponses,
                associatedAccounts: n.associatedAccounts,
                background: n.background,
                condition_activity_id: n.condition_activity_id,
                configResponsesOrig: n.configResponsesOrig,
                config_fields: n.config_fields,
                display_message: n.display_message,
                error: n.error,
                id: n.appId,
                image: n.image,
                konnect_activity_id: n.konnect_activity_id,
                konnect_id: n.konnect_id,
                linkedAccounts: n.linkedAccounts,
                name: n.name,
                order: n.order ? n.order : null,
                pass_through_condition: n.pass_through_condition,
                provider: n.provider,
                raw_response: n.raw_response,
                selectedAccount: n.selectedAccount,
                test_status: n.test_status,
                type: n.appType,
                appEventConfigurationDetails: n.appEventConfigurationDetails,
                webhook: n.webhook,
                webhook_instructions: n.webhook_instructions,
                webhook_url: n.webhookUrl,
                webhookUrl: n.webhookUrl,
                selectedEvent: { ...n.appEvents.find(a => a.id === n.appEvent) },
              },
              type: GetAppType({ droppedElement: data, nodes: arr }),
            }
          })
          let newEdges = links.map(e => {
            let x = {
              node: e.from.node,
              app: nodes.find(n => n.nodeId === e.from.node).appId,
              appEvent: nodes
                .find(n => n.nodeId === e.from.node)
                .configResponses.find(c => c.id === e.from.port.split("|")[3]).app_event_id
                ? nodes
                    .find(n => n.nodeId === e.from.node)
                    .configResponses.find(c => c.id === e.from.port.split("|")[3]).app_event_id
                : nodes.find(n => n.nodeId === e.from.node).appEvent
                ? nodes.find(n => n.nodeId === e.from.node).appEvent
                : "undefined",
              // .appEvent
              //   ? nodes.find(n => n.nodeId === e.from.node).appEvent
              //   : "undefined",

              port: nodes
                .find(n => n.nodeId === e.from.node)
                .configResponses.find(c => c.id === e.from.port.split("|")[3]).id,
              label: nodes
                .find(n => n.nodeId === e.from.node)
                .configResponses.find(c => c.id === e.from.port.split("|")[3]).label,

              id: nodes
                .find(n => n.nodeId === e.from.node)
                .configResponses.find(c => c.id === e.from.port.split("|")[3]).id,
              config_key: nodes
                .find(n => n.nodeId === e.from.node)
                .configResponses.find(c => c.id === e.from.port.split("|")[3]).config_key,
            }
            let y = {
              node: e.to.node,
              app: nodes.find(n => n.nodeId === e.to.node).appId,
              appEvent: nodes
                .find(n => n.nodeId === e.to.node)
                .configResponses.find(c => c.id === e.to.port.split("|")[3]).app_event_id
                ? nodes.find(n => n.nodeId === e.to.node).configResponses.find(c => c.id === e.to.port.split("|")[3])
                    .app_event_id
                : nodes.find(n => n.nodeId === e.to.node).appEvent
                ? nodes.find(n => n.nodeId === e.to.node).appEvent
                : "undefined",
              port: nodes.find(n => n.nodeId === e.to.node).configResponses.find(c => c.id === e.to.port.split("|")[3])
                .id,
              label: nodes.find(n => n.nodeId === e.to.node).configResponses.find(c => c.id === e.to.port.split("|")[3])
                .label,

              id: nodes.find(n => n.nodeId === e.to.node).configResponses.find(c => c.id === e.to.port.split("|")[3])
                .id,
              config_key: nodes
                .find(n => n.nodeId === e.to.node)
                .configResponses.find(c => c.id === e.to.port.split("|")[3]).config_key,
            }
            let source =
              nodes.find(n => n.nodeId === e.from.node).configResponses.find(c => c.id === e.from.port.split("|")[3])
                .id +
              "|" +
              JSON.stringify({ ...x, type: "source" })
            let target =
              nodes.find(n => n.nodeId === e.to.node).configResponses.find(c => c.id === e.to.port.split("|")[3]).id +
              "|" +
              JSON.stringify({ ...y, type: "target" })
            return {
              source: e.from.node,
              target: e.to.node,

              sourceHandle: source,

              targetHandle: target,
              id: e.id,

              type: EdgeType.buttonedge,
            }
          })

          state.canvas.editorState.nodes = newNodes
          state.canvas.editorState.links = newEdges
        }
      }),
      builder.addCase(getAppEventConfigDetailCustom.fulfilled, (state, action) => {
        const { config_key, data, eventSequence, input, port, nodeId } = action.meta.arg
        let res = action.payload
        const nodeIdx = state.canvas.editorState.nodes.findIndex(x => x.id === nodeId)
        state.canvas.editorState.nodes[nodeIdx].data.appEventSequences.filter(
          d => d.config_key === config_key.slice(0, config_key.indexOf(">"))
        )[0].sequence.id = input
        state.canvas.editorState.nodes[nodeIdx].data.appEventSequences.filter(
          d => d.config_key === config_key.slice(0, config_key.indexOf(">"))
        )[0].id = input

        state.canvas.editorState.nodes[nodeIdx].data.appEventConfigurationDetails = state.canvas.editorState.nodes[
          nodeIdx
        ].data.appEventConfigurationDetails.filter(c => c.config_key !== config_key)
        let newConfigKey = state.canvas.editorState.nodes[nodeIdx].data.appEventConfigurations[eventSequence].config_key

        const configDetails = action.payload.map(x => {
          return { ...x, config_key: newConfigKey }
        })

        state.canvas.editorState.nodes[nodeIdx].data.reviewed = false
        state.canvas.editorState.nodes[nodeIdx].data.tested = false
        state.canvas.editorState.nodes[nodeIdx].data.appEventConfigurationDetails = [
          ...state.canvas.editorState.nodes[nodeIdx].data.appEventConfigurationDetails,
          ...configDetails,
        ]
        //  data.appEventConfigurationDetails = data.appEventConfigurationDetails.filter(c => c.config_key !== config_key)

        // const configDetails = res.map(x => {
        //   return { ...x, config_key: config_key }
        // })
        state.canvas.editorState.nodes[nodeIdx].data.reviewed = false
        state.canvas.editorState.nodes[nodeIdx].data.tested = false
        state.canvas.editorState.nodes[nodeIdx].data.appEventConfigurationDetails = [
          ...state.canvas.editorState.nodes[nodeIdx].data.appEventConfigurationDetails,
          ...configDetails,
        ]
      }),
      builder.addCase(getAppEventConfigDetailFetchuserDefined.fulfilled, (state, action) => {
        const { appId, app_account_id, data, eventId, input, nodeId, selectedEventConfigId } = action.meta.arg
        const sourceFields = action.payload.map(x => {
          return {
            app_event_id: eventId,
            app_id: appId,
            config_key: x.config_key ? x.config_key : x.name,
            config_key_required: x.config_key_required,
            id: x.id,
            label: x.name,
            placeholder: x.placeholder || x.name,
            key_value_type: x.key_value_type ? x.key_value_type : "display",
            type: "source",
          }
        })
        const node = state.canvas.editorState.nodes.find(x => x.id === data.nodeid)
        node.data.reviewed = false
        node.data.tested = false
        const _configs = [...sourceFields]
        if (node.data.type === "ADD_ON" && data.provider.toLowerCase() === "dateformatter") {
          _configs = _configs.reverse()
        }

        state.canvas.editorMeta.canPublish = false
        node.data.configResponses = node.data.configResponses.filter(c => c.config_key_type === "custom")
        node.data.configResponsesOrig = node.data.configResponsesOrig.filter(c => c.config_key_type === "custom")

        node.data.configResponses = [
          ...node.data.configResponses,
          ...generateportsId({ data, response: [..._configs] }),
        ]
        node.data.configResponsesOrig = [
          ...node.data.configResponses,
          ...generateportsId({ data, response: [..._configs] }),
        ]
      }),
      builder.addCase(getAppEventConfigDetailCustomKeymap.fulfilled, (state, action) => {
        const { app, appEvent, app_account_id, data, targetPortInfo, eventConfigId } = action.meta.arg
        const { config_key, id, key_value_type, label, type } = targetPortInfo

        const { eventSequence } = data
        let newConfigKey = data.appEventConfigurations[eventSequence].config_key

        const node = state.canvas.editorState.nodes.find(n => n.id === data.nodeid)
        node.data.appEventConfigurationDetails = node.data.appEventConfigurationDetails.filter(
          c => c.config_key !== config_key
        )
        node.data.appEventSequences.filter(
          d => d.config_key === config_key.slice(0, config_key.indexOf(">"))
        )[0].sequence.id = eventConfigId
        node.data.appEventSequences.filter(
          d => d.config_key === config_key.slice(0, config_key.indexOf(">"))
        )[0].id = eventConfigId

        const configDetails = action.payload?.map(x => {
          return { ...x, config_key: newConfigKey }
        })
        node.data.reviewed = false
        node.data.tested = false
        node.data.appEventConfigurationDetails = [...node.data.appEventConfigurationDetails, ...configDetails]
        //  data.appEventConfigurationDetails = data.appEventConfigurationDetails.filter(c => c.config_key !== config_key)

        // const configDetails = res.map(x => {
        //   return { ...x, config_key: config_key }
        // })
        node.data.reviewed = false
        node.data.tested = false
      }),
      builder.addCase(getAppEventConfigDetailFetchKeyMap.fulfilled, (state, action) => {
        const { appId, app_account_id, data, eventId, input, nodeId, selectedEventConfigId } = action.meta.arg
        const sourceFields = action.payload.map(x => {
          return {
            app_event_id: eventId,
            app_id: appId,
            config_key: x.config_key ? x.config_key : x.name,
            config_key_required: x.config_key_required,
            id: x.id,
            label: x.name,
            placeholder: x.placeholder || x.name,
            key_value_type: x.key_value_type ? x.key_value_type : "display",
            type: "source",
          }
        })
        const node = state.canvas.editorState.nodes.find(x => x.id === data.nodeid)
        node.data.reviewed = false
        node.data.tested = false
        const _configs = [...sourceFields]
        if (node.data.type === "ADD_ON" && data.provider.toLowerCase() === "dateformatter") {
          _configs = _configs.reverse()
        }
        node.data.configResponses = node.data.configResponses.filter(c => c.config_key_type === "custom")
        node.data.configResponsesOrig = node.data.configResponsesOrig.filter(c => c.config_key_type === "custom")
        state.canvas.editorMeta.canPublish = false

        node.data.configResponses = [
          ...node.data.configResponses,
          ...generateportsId({ data, response: [..._configs] }),
        ]
        node.data.configResponsesOrig = [
          ...node.data.configResponses,
          ...generateportsId({ data, response: [..._configs] }),
        ]
      }),
      builder.addCase(appTestAndReview.rejected, (state, action) => {
        const { data } = action.meta.arg

        const nodeIdx = state.canvas.editorState.nodes.findIndex(n => n.id === data.nodeid)

        state.canvas.editorState.nodes[nodeIdx].data.isloading = false
        state.canvas.editorState.nodes[nodeIdx].data.display_message = "Something went Wrong! Try Again"
      })
  },
})

export const {
  updateNode,
  updateNodes,
  updateEdge,
  onNodesChange,
  onEdgesChange,
  onConnect,
  onDeleteNode,
  toggleBasicAuth,
  onWebhookAuthChange,
  onCanvasDrop,
  onLinkComplete,
  handleRefresh,
  setConfigMenuState,
  turnWebhookResNull,
  setLinkedAccount,
  updateToggleToast,
  updateToastContent,
  setEventsConfig,
  onPortTextChange,
  onDeleteLink,
  enableTestReviewLoader,
  marksDirty,
  updateDuration,
  updateExpiryNum,
  updateUrl,
  updateWrapRequestInArray,
  updatePayloadType,
  updateHeadersAndParams,
  modifyConditions,
  createAdditionalFields,
  addCustom,
  filterLinks,
  filterConfigs,
  undo,
  redo,
  saveKonnectName,
  clearState,
  addFieldstoconfig,
  onToggleDrag,
  showChatGpt,
  onAppEventChange,
  modifySegmentIndex,
  updateGsheetPop,
  setDelayConfigInNode,
  moveActionToInitialState,
} = canvasSlice.actions

export default canvasSlice.reducer
