import React from "react"
import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"
import {
  PublishButton,
  GsheetModalButtonWrapper,
  GsheetButtonWrapper,
  TestAndReviewButtonWrapper,
} from "../../../../UI"
import "./styles.css"
import { FormControlLabel, Checkbox, Button } from "@material-ui/core"
const styles = {
  textAlign: "center",
  width: "600px",
  customModal: {
    background: "#b8bdcc",
    width: "100%",
  },
}
export default class GsheetModal extends React.Component {
  constructor(props) {
    super(props)
    let checkboxItems = props.data
    if (!props.isTested) {
      checkboxItems = props.data.map(obj => ({ id: obj.id, name: obj.label, checked: false }))
    }
    this.state = {
      open: props.open,
      data: checkboxItems,
      isAndCondition: true,
    }
  }

  onOpenModal = () => {
    this.setState({ open: true })
  }

  onCloseModal = () => {
    this.setState({ open: false })
  }

  handleChange = event => {
    let data = this.state.data
    data.forEach(obj => {
      if (obj.name === event.target.name) {
        obj.checked = event.target.checked
      }
    })
    this.setState({ data: data })
  }

  handleProceed = () => {
    this.props.publishAppScript({ data: this.state.data, isAndCondition: this.state.isAndCondition })
  }

  enableButton = val => {
    if (val === "AND") {
      this.setState({ isAndCondition: true })
    } else {
      this.setState({ isAndCondition: false })
    }
  }

  render() {
    const { open, data, isAndCondition } = this.state
    const checkBoxItems = data.map(obj => (
      <div
        style={{
          display: "flex",
          paddingLeft: "5px",
          margin: "20px 10px 0px 20px",
          // marginRight: "1%",
          justifyContent: "center",
          alignItems: "center",
          background: "#FFFFFF",
          borderRadius: "10px",
          minWidth: "20%",
        }}
      >
        <FormControlLabel
          control={<Checkbox checked={obj.checked} onChange={this.handleChange} name={obj.name} color="primary" />}
          label={obj.name}
        />
      </div>
    ))

    return (
      <div style={styles}>
        <Modal
          open={open}
          onClose={this.props.onCloseModal}
          classNames={{
            modal: "gsheet-customModal",
            overlay: "",
          }}
        >
          <GsheetModalButtonWrapper>
            <div style={{ margin: "20px" }}>
              <GsheetButtonWrapper>
                {isAndCondition ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.enableButton("AND")
                      }}
                    >
                      AND
                    </Button>
                    <Button
                      onClick={() => {
                        this.enableButton("OR")
                      }}
                    >
                      OR
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        this.enableButton("AND")
                      }}
                    >
                      AND
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.enableButton("OR")
                      }}
                    >
                      OR
                    </Button>
                  </>
                )}
              </GsheetButtonWrapper>
            </div>
            <TestAndReviewButtonWrapper>
              <PublishButton onClick={this.handleProceed}>
                {this.props.translator("konnect.proceed.btnLabel")}
              </PublishButton>
            </TestAndReviewButtonWrapper>
          </GsheetModalButtonWrapper>
          <div style={{ display: "flex", flexDirection: "row", flexFlow: "wrap", marginBottom: "35px" }}>
            {checkBoxItems}
          </div>
        </Modal>
      </div>
    )
  }
}
