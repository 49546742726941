import React from "react"
import { Box, Flex } from "rebass"
import px2vw from "utils/px2vw"
export const CommonOuter = ({ node, reviewed, nodeTested, isFirst, captureWebHook }) => {
  //let nodeReviewed = node.tested ? node.reviewed : null

  return (
    <>
      {/* {!nodeTested && ( */}
      <Box
        sx={{
          background: reviewed ? "rgba(22, 247, 137, 0.22)" : "rgba(244, 67, 54, 0.12)",
          boxShadow: `${isFirst ? "0px 0px 3px 1px #B7CBFF;" : "0px 0px 3px 1px rgba(0, 0, 0, 0.08)"}`,
          borderTopLeftRadius: "30px",
          borderTopRightRadius: "30px",
          marginBottom: captureWebHook || node.display_message || node.error ? "-90px" : "-120px",
          zIndex: captureWebHook || node.display_message || node.error ? 0 : -2,
          textAlign: "center",
          padding: "10px 10px 100px 10px",
          width: px2vw(419),
          transition: "margin-bottom 0.15s",
          color: reviewed ? "#0F9D58" : "#F44336",
          fontSize: "14px",
          fontWeight: 500,
        }}
      >
        {captureWebHook && !node.display_message && !node.error
          ? "Waiting: Awaited data from Webhook"
          : node.display_message || node.error}
      </Box>
      {/* )} */}
    </>
  )
}
