import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { KonnectzView } from "./Konnectz.view"
import { SearchHeader, Preloader } from "src/components"
import {
  getKonnectsList,
  getFoldersList,
  getKonnectsByFolderId,
  updateKonnectStatus,
  createFolder,
  moveKonnectsToFolder,
  renameKonnectFolder,
  deleteKonnectFolder,
} from "src/store/actions"
import { Modal } from "react-responsive-modal"
import { DeleteModal } from "./modals/DeleteModal.view"
import { CreateFolderModal } from "./modals/CreateFolderModal.view"
import { MoveFolderModal } from "./modals/MoveFolderModal.view"
import { RenameFolderModal } from "./modals/RenameFolderModal.view"
import { DeleteFolderModal } from "./modals/DeleteFolderModal.view"
import AuthTokenService from "utils/AuthTokenService"
import { ToastContainer, toast } from "react-toastify"
import Cross from '../../assets/images/cross.svg'
export const Container = props => {
  const {
    getKonnectsList,
    getFoldersList,
    getKonnectsByFolderId,
    createFolder,
    moveKonnectsToFolder,
    folderKonnects,
    konnectsList,
    foldersList,
    updateKonnectStatus,
    renameKonnectFolder,
    deleteKonnectFolder,
  } = props
  const [expandedItem, setExpandedItem] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [openCreateFolderModal, setOpenCreateFolderModal] = useState(false)
  const [openMoveFolderModal, setOpenMoveFolderModal] = useState(false)
  const [openRenameFolderModal, setOpenRenameFolderModal] = useState(false)
  const [openDeleteFolderModal, setOpenDeleteFolderModal] = useState(false)
  const [konnectId, setKonnectId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [filterMode, setFilterMode] = useState(false)
  const [searchValue, setSearchValue] = useState(null)
  const [selectedKonnects, setSelectedKonnects] = useState([])
  const [folderId, setFolderId] = useState(null)
  const [showMoveIcon, setShowMoveIcon] = useState(false)
  const [konnectsPageIndex, setKonnectsPageIndex] = useState(1)
  const bg = {
    overlay: {
      borderRadius: "16px",
      background: "#FF9900",
    },
  }

  useEffect(() => {
    setLoading(true)
    getKonnectsList(
      searchValue
        ? {
            page_index: konnectsPageIndex,
            page_size: 10,

            search_query: searchValue,
          }
        : {
            page_index: konnectsPageIndex,
            page_size: 10,
          }
    ).then(res => {
      if (res.type === "GET_KONNECTS_LIST_FAIL") {
        if (res.error.response.status === 422) {
          AuthTokenService.clear()
          props.history.push("/login")
        }
      }
      setLoading(false)
    })

    getFoldersList().then(res => {
      if (res.type === "GET_KONNECTS_LIST_FAIL") {
        if (res.error.response.status === 422) {
          AuthTokenService.clear()
          props.history.push("/login")
        }
      }
    })
  }, [])

  useEffect(() => {
    let params = {
      page_index: konnectsPageIndex,
      page_size: 10,
    }
    let params1 = {
      page_index: konnectsPageIndex,
      page_size: 10,
      search_query: searchValue,
    }

    getKonnectsList(searchValue ? { ...params1 } : { ...params })

    // if(searchValue){
    //     getKonnectsList({ ...params1 })
    // }
  }, [konnectsPageIndex, searchValue])
  const handleExpandKonnect = konnectId => {
    setExpandedItem(konnectId)
  }

  const onCloseModal = () => {
    setOpenModal(false)
  }

  const deleteKonnectModal = konnectId => {
    setOpenModal(true)
    setKonnectId(konnectId)
  }

  const changeStatus = (konnectId, status) => {
    updateKonnectStatus(konnectId, status === "ACTIVE" ? "INACTIVE" : "ACTIVE").then(() => {
      getKonnectsList()
    })
  }

  const deleteKonnect = status => {
    setLoading(true)
    updateKonnectStatus(konnectId, status).then(() => {
      if (folderId) {
        getKonnectsByFolderId(folderId).then(() => {
          setLoading(false)
        })
      } else {
        getKonnectsList({
          page_index: konnectsPageIndex,
          page_size: 10,
        }).then(() => {
     
           setLoading(false)
        })
      
      }
   
      onCloseModal()
      
    })
  
   
  }

  const onEditKonnect = id => {
    props.history.push("/konnect/" + id)
  }

  const onSearchItem = e => {
    setSearchValue(e.currentTarget.value)
    getKonnectsList(e.currentTarget.value, page)
  }

  const filterKonnects = (id, clearFilter) => {
    setLoading(true)
    setSelectedKonnects([])
    setShowMoveIcon(false)
    setFilterMode(clearFilter ? false : true)
    if (!clearFilter) {
      setFolderId(id)
      getKonnectsByFolderId(id).then(() => {
        setLoading(false)
      })
    } else {
      setFolderId(null)
      getKonnectsList().then(() => {
        setLoading(false)
      })
    }
  }

  const onCreateFolderBtnClick = () => {
    setOpenCreateFolderModal(true)
  }

  const createFolderName = folderName => {
    createFolder(folderName).then(() => {
      getFoldersList()
      setOpenCreateFolderModal(false)
    })
  }

  const onMoveFolderIconClick = () => {
    setOpenMoveFolderModal(true)
  }

  const updateKonnectsSelection = (item, remove) => {
    let itemExists = false
    selectedKonnects.map((konnect, index) => {
      if (konnect === item) {
        selectedKonnects.splice(index, 1)
        itemExists = true
        return
      }
    })
    if (!itemExists) selectedKonnects.push(item)

    setSelectedKonnects(selectedKonnects)
    setShowMoveIcon(selectedKonnects.length > 0)
  }

  const moveFolder = folderId => {
    setLoading(true)
    setOpenMoveFolderModal(false)
    setFolderId(folderId)
    moveKonnectsToFolder(folderId, selectedKonnects).then(() => {
      setFilterMode(true)
      setShowMoveIcon(false)
      setSelectedKonnects([])
      getKonnectsByFolderId(folderId).then(() => {
        setLoading(false)
      })
    })
  }

  const onRenameFolderIconClick = folderId => {
    setFolderId(folderId)
    setOpenRenameFolderModal(true)
  }

  const renameFolder = folderName => {
    renameKonnectFolder(folderId, folderName).then(() => {
      setOpenRenameFolderModal(false)
      getFoldersList()
    })
  }

  const onDeleteFolderIconClick = folderId => {
    setFolderId(folderId)
    setOpenDeleteFolderModal(true)
  }

  const deleteFolder = () => {
    deleteKonnectFolder(folderId).then(res => {
      if (res.payload.status === "FAILURE") {
        toast.error(res.payload.message),
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
      }
      setOpenDeleteFolderModal(false)
      getFoldersList()
    })
  }

  const showLoader = show => {
    setLoading(show)
  }
  const fetchMoreKonnects = () => {
    setKonnectsPageIndex(konnectsPageIndex + 1)
  }
  return (
    <>
      <SearchHeader searchValue={searchValue} onSearchItem={onSearchItem} />
      {loading && <Preloader loading={loading} />}
      <KonnectzView
        key="transition"
        expandedItem={expandedItem}
        foldersList={foldersList}
        currentFolderId={folderId}
        showMoveIcon={showMoveIcon}
        onMoveFolderIconClick={onMoveFolderIconClick}
        updateKonnectsSelection={updateKonnectsSelection}
        onCreateFolderBtnClick={onCreateFolderBtnClick}
        onRenameFolderIconClick={onRenameFolderIconClick}
        onDeleteFolderIconClick={onDeleteFolderIconClick}
        konnectsList={filterMode ? folderKonnects : konnectsList}
        fetchMoreKonnects={fetchMoreKonnects}
        setExpandKonnect={handleExpandKonnect}
        onEditKonnect={onEditKonnect}
        showLoader={showLoader}
        deleteKonnect={deleteKonnectModal}
        filterKonnects={filterKonnects}
        changeStatus={changeStatus}
      />
      <Modal
        open={openModal}
        onClose={onCloseModal}
        closeIcon={<Cross />}
        center
        styles={{ modal: { padding: "20px", borderRadius: "16px" } }}
      >
        <DeleteModal closeModal={onCloseModal} onDeleteClick={deleteKonnect} />
      </Modal>
      <Modal
        open={openCreateFolderModal}
        onClose={() => setOpenCreateFolderModal(false)}
        center
        closeIcon={<Cross />}
        styles={{ modal: { padding: "20px", borderRadius: "16px", minWidth: "400px" } }}
      >
        <CreateFolderModal closeModal={() => setOpenCreateFolderModal(false)} createFolderName={createFolderName} />
      </Modal>
      <Modal
        open={openMoveFolderModal}
        onClose={() => setOpenMoveFolderModal(false)}
        closeIcon={<Cross />}
        center
        styles={{ modal: { padding: "20px", borderRadius: "16px", minWidth: "600px" } }}
      >
        <MoveFolderModal
          foldersList={foldersList}
          moveFolder={moveFolder}
          closeIcon={<Cross />}
          closeModal={() => setOpenMoveFolderModal(false)}
          createFolderName={createFolderName}
        />
      </Modal>
      <Modal
        open={openRenameFolderModal}
        onClose={() => setOpenRenameFolderModal(false)}
        center
        closeIcon={<Cross />}
        styles={{ modal: { padding: "20px", borderRadius: "16px", minWidth: "400px" } }}
      >
        <RenameFolderModal
          folderId={folderId}
          foldersList={foldersList}
          closeModal={() => setOpenRenameFolderModal(false)}
          renameFolder={renameFolder}
        />
      </Modal>
      <Modal
        open={openDeleteFolderModal}
        onClose={() => setOpenDeleteFolderModal(false)}
        center
        styles={{ modal: { padding: "20px", borderRadius: "16px", minWidth: "400px" } }}
      >
        <DeleteFolderModal closeModal={() => setOpenDeleteFolderModal(false)} deleteFolder={deleteFolder} />
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    konnectsList: state.getIn(["konnect", "konnects"]),
    foldersList: state.getIn(["folders", "folders"]),
    folderKonnects: state.getIn(["folders", "folderKonnects"]),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getKonnectsList: (searchQuery, page) => dispatch(getKonnectsList(searchQuery, page)),
    getFoldersList: () => dispatch(getFoldersList()),
    createFolder: folderName => dispatch(createFolder(folderName)),
    renameKonnectFolder: (folderId, folderName) => dispatch(renameKonnectFolder(folderId, folderName)),
    deleteKonnectFolder: folderId => dispatch(deleteKonnectFolder(folderId)),
    moveKonnectsToFolder: (folderId, konnects) => dispatch(moveKonnectsToFolder(folderId, konnects)),
    getKonnectsByFolderId: id => dispatch(getKonnectsByFolderId(id)),
    updateKonnectStatus: (id, status) => dispatch(updateKonnectStatus(id, status)),
  }
}

export const KonnectzContainer = connect(mapStateToProps, mapDispatchToProps)(Container)
