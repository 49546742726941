import React, { useContext, createContext, useState } from "react"
import { useMediaQuery } from "react-responsive"

export const ThemeContext = createContext()

export const ThemeProvider = props => {
  const FHD = useMediaQuery({ minWidth: 1920, minHeight: 968, maxHeight: 1000 })
  const FHD_FullScreen = useMediaQuery({ minWidth: 1920, minHeight: 1079 })

  const HD = useMediaQuery({ minWidth: 1600, minHeight: 789, maxWidth: 1919, maxHeight: 898 })
  const HD_FullScreen = useMediaQuery({ minWidth: 1600, minHeight: 899, maxWidth: 1919 })

  const SD = useMediaQuery({ minWidth: 1366, minHeight: 657, maxWidth: 1439, maxHeight: 766 })
  const SD_FullScreen = useMediaQuery({ minWidth: 1366, minHeight: 767, maxWidth: 1439 })

  const Retina = useMediaQuery({ minWidth: 1440, minHeight: 789, maxWidth: 1599, maxHeight: 899 })
  const Retina_FullScreen = useMediaQuery({ minWidth: 1440, minHeight: 899, maxWidth: 1599 })

  // const w1240 = useMediaQuery({ query: "(min-width: 1240px" })
  // const w1440 = useMediaQuery({ query: "(min-width: 1440px" })
  // const w1600 = useMediaQuery({ query: "(min-width: 1600px)" })
  // const w1900 = useMediaQuery({ query: "(min-width: 1900px)" })
  // const h650 = useMediaQuery({ query: "(max-height: 670px"  })
  // const h765 = useMediaQuery({ query: "(max-height: 768px" })
  // const h775 = useMediaQuery({ query: "(max-height: 790px" })
  // const h880 = useMediaQuery({ query: "(max-height: 900px" })
  // const h960 = useMediaQuery({ query: "(max-height: 990px" })
  // const h1070 = useMediaQuery({ query: "(max-height: 1080px" })

  const fbP = ([a, b, c, d, e, f, g, h]) => {
    return SD
      ? a
      : SD_FullScreen
      ? b
      : Retina
      ? c
      : Retina_FullScreen
      ? d
      : HD
      ? e
      : HD_FullScreen
      ? f
      : FHD
      ? g
      : FHD_FullScreen
      ? h
      : a
  }

  const bP = ([a, b, c, d]) => {
    return SD || SD_FullScreen
      ? a
      : Retina || Retina_FullScreen
      ? b
      : HD || HD_FullScreen
      ? c
      : FHD || FHD_FullScreen
      ? d
      : a
  }

  const scale = size => {
    return SD || SD_FullScreen
      ? size
      : Retina || Retina_FullScreen
      ? size
      : HD || HD_FullScreen
      ? (size * 1600) / 1366
      : FHD || FHD_FullScreen
      ? (size * 1920) / 1366
      : size
  }

  // const hbP = ([a,b,c,d]) => {
  //   return w1900 ? a : w1600 ? b : w1440 ? c : w1240 ? d : d
  // }

  // const vbP = ([a,b,c,d,e,f,g]) => {
  //   return h650 ? f : h765 ? e : h775 ? d : h880 ? c : h960 ? b : h1070 ? a : g
  // }

  const [selected, setSelected] = useState("/dashboard")

  return (
    <ThemeContext.Provider
      value={{
        bP,
        fbP,
        scale,
        selected,
        setSelected,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  )
}

export const useThemeContext = () => {
  return useContext(ThemeContext)
}
