import React, { useState, useRef } from "react"
import {
  ArrowHeadType,
  EdgeSmoothStepProps,
  getBezierPath,
  getEdgeCenter,
  getMarkerEnd,
  getBezierEdgeCenter,
  onEdgeClick,
  useStore,
  useViewport,
} from "react-flow-renderer"
import styled from "@emotion/styled"
import ReactTooltip from "react-tooltip"
import { onDeleteLink } from "../../store/slice/canvasSlice"
import { useSelector, useDispatch } from "react-redux"
import { Box } from "rebass"
import { svg } from "../../asset/svg"

const foreignObjectSize = 40

export const CustomEdgeButton = newEdge => {
  const { x, y } = useViewport()
  const { nodes } = useSelector(state => state.canvas.canvas.editorState)
  const dispatch = useDispatch()
  const { sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition, id } = newEdge
  const e = window.event
  const [displayToolTip, setDisplayToolTip] = useState(null)
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  const markerEnd = getMarkerEnd()
  const [centerX, centerY] = getBezierEdgeCenter({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  })

  const allReviewed = nodes.every(node => node.data.reviewed)
  const handleToolTip = e => {
    setDisplayToolTip({ x: e.clientX, y: e.clientY })
  }
  return (
    <>
      {/* { allReviewed ? <>
      <path
      data-tip={"fsdfsdfs"}
        id={id} 
       
        onDoubleClick={event => {
          event.stopPropagation()
         onDeleteLink(id)
        }}
        className={"react-flow__edge-path1 " }
        d={edgePath}
        markerEnd={markerEnd}
      /> 
      </>
      :
       <path
       data-tip={"fsdfsdfs"}
        id={id} 
        //onClick={()=>setIsActive(id)}

        onDoubleClick={event => {
          event.stopPropagation()
         onDeleteLink(id)
        }}
        className={"react-flow__edge-path"}
        d={edgePath}
        markerEnd={markerEnd}
      /> } */}
      <path
        id={id}
        className="react-flow__edge-path"
        height={5}
        cursor="pointer"
        d={edgePath}
        markerEnd={markerEnd}
        onMouseEnter={handleToolTip}
        onMouseLeave={() => {
          setTimeout(() => {
            setDisplayToolTip(null)
          }, 500)
        }}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={centerX - foreignObjectSize / 2}
        y={centerY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <button
          className="edgebutton"
          onClick={event => {
            event.stopPropagation()
            dispatch(onDeleteLink(id))
          }}
        >
          X
        </button>
      </foreignObject>
    </>
  )
}
