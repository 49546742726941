import UnlimitedSteps from "assets/images/features/unlimited-steps.png"
import VisualizeEverything from "assets/images/features/visualize-everything.png"
import IntegrationLibrary from "assets/images/features/integration-library.png"
import SimpleAutomation from "assets/images/features/simple-automation.png"
import PlatformForEveryone from "assets/images/features/platform-for-everyone.png"
import SeamlessAppsIntegration from "assets/images/features/seamless-apps-integration.png"

export const arabic = {
  translation: {
    common: {
      app: "كونيكتز",
    },
    header: {
      topNavigations: {
        tipsAndTricks: "النصائح والحيل",
        pricing: "التسعير",
        blogs: "المدونات",
        helpCenter: "مركز المساعدة",
      },
      searchAppTextPlaceholder: "ابحث هنا...",
      createKonnectzBtnLabel: "إنشاء Konnectz",
      taskhistory: "تاريخ المهمة",
      success: "نجاح",
      error: "خطأ",
      notask: "لم يتم العثور على مهمة",
      tryagain: "اضبط الفلتر وحاول مرة أخرى"
    },
    errorMessages: {
      SENT_RESET_INSTRUCTIONS_SUCCESS: "تم إرسال تعليمات إعادة تعيين كلمة المرور عبر البريد الإلكتروني ، يرجى التحقق من بريدك الإلكتروني.",
      SENT_RESET_INSTRUCTIONS_FAIL: "طلب غير مصرح به!",
      VERIFY_EMAIL_FAIL: "عذرا ، غير قادر على تأكيد بريدك الإلكتروني.",
      PASSWORD_NOT_UPDATED: "آسف ، فشل تحديث حسابك",
      GET_FORGOT_PASSWORD_LINK_SUCCESS: "",
      GET_FORGOT_PASSWORD_LINK_FAIL: "فشل في إرسال تعليمات كلمة المرور. يرجى المحاولة بعد مرور بعض الوقت !",
      AUTH_SIGN_UP_FAIL: "هناك خطأ ما. يرجى المحاولة بعد مرور بعض الوقت !",
      somethingWentWrong: "هناك خطأ ما. يرجى المحاولة بعد مرور بعض الوقت !",
    },
    successMessages: {
      PASSWORD_UPDATED: "تم تحديث كلمة المرور. الرجاء تسجيل الدخول باستخدام كلمة المرور الجديدة الخاصة بك!",
    },
    history:{
      task:{
        task: "مهمة",
        tasks: "مهام",
        taskdate: "تاريخ المهمة:",
        user: "مستخدم :"
      }
  },
    auth: {
      signup: {
        createPassword: {
          title: "أنشئ كلمة مرور",
          subtitle: "اكتبهم مرتين للتأكيد",
          passwordErrorMsg: "رمز مرور خاطئ !",
          confirmPasswordErrorMsg: "رمز مرور خاطئ !",
          passwordMatchingErrorMsg: "كلمتا المرور غير متطابقتين!",
          continueBtnLabel: "استمر",
          createPasswordPlaceholder: "أنشئ كلمة مرور",
          confirmPasswordPlaceholder: "تأكيد كلمة المرور",
          passwordRule:
            "تلميح: يجب أن تكون كلمة المرور الخاصة بك بين 8 و 15 حرفًا ، ويجب أن تحتوي على الأقل على حرف كبير واحد ، وحرف صغير واحد ، ورقم واحد ، وحرف خاص واحد.",
          passwordRule1: "يجب أن تكون كلمة المرور الخاصة بك بين 8 و 15 حرفًا.",
          passwordRule2: "يجب أن تحتوي كلمة المرور الخاصة بك على حرف كبير واحد على الأقل.",
          passwordRule3: "يجب أن تحتوي كلمة مرورك على رقم واحد على الأقل.",
          passwordRule4: "يجب أن تحتوي كلمة مرورك على حرف خاص واحد على الأقل.",
        },
        verificationSent: {
          title: "التحقق",
          subtitle: "التحقق من عنوان البريد الإلكتروني",
          verificationText1: "أرسلنا رابط التحقق على ",
          verificationText2: ", افتح البريد الإلكتروني وتحقق من عنوان بريدك الإلكتروني.",
          didntReceiveEmailText: "ألم يستقبل أي بريد؟ ",
          resendText: "إعادة إرسال",
        },
        verifySuccess: {
          title: "اكتمل التحقق",
          verifyFailedTitle: "فشل التحقق"
        },
        signupSuccess: {
          firstName: "الاسم الاول",
          firstNameErrorMsg: "الإسم الأول مطلوب !",
          lastName: "الكنية",
          lastNameErrorMsg: "إسم العائلة مطلوب !",
          verifyErrorMsg: "هناك خطأ ما. يرجى محاولة تسجيل الدخول إذا تم التحقق من حسابك بالفعل.",
        },
        title: "أنشئ حسابك",
        subtitle: "مرحبا مرحبا",
        verifyingMessage: "التحقق منك",
        emailInvalid: "البريد الإلكتروني غير صالح!",
        emailExists: "عنوان البريد الإلكتروني الذي أدخلته مسجل بالفعل",
        teamSignup: "التسجيل كفريق؟",
        continueBtnLabel: "استمر",
        alreadyHaveAccountText: "هل لديك حساب؟ ",
        loginBtnLabel: "تسجيل الدخول",
        emailPlaceholder: "أدخل البريد الإلكتروني",
        features: {
          feature1: {
            title: "أتمتة بسيطة",
            image: SimpleAutomation,
            description:
              "قم ببناء سير عمل معقد ببساطة وسهولة في دقائق باستخدام أداة إنشاء الاتصال المرئي",
          },
          feature2: {
            title: "منصة للجميع",
            image: PlatformForEveryone,
            description:
              "ليست أداة أتمتة عادية للمبرمجين فقط ، يمكن لأي شخص بناء أتمتة باستخدام KonnectzIT",
          },
          feature3: {
            title: "تكامل تطبيقات سلس",
            image: SeamlessAppsIntegration,
            description:
              "قم بتوصيل تطبيقات غير محدودة من مكتبة التكامل الخاصة بنا والمهام اليومية الآلية",
          },
        },
      },
      login: {
        title: "تسجيل الدخول في حسابك",
        subtitle: "مرحبًا بك مرة أخرى!",
        emailPlaceholder: "أدخل البريد الإلكتروني",
        emailInvalid: "بريد إلكتروني خاطئ !",
        passwordRequired: "كلمة المرور مطلوبة !",
        passwordPlaceholder: "أدخل كلمة المرور",
        rememberMe: "تذكرنى",
        loginBtnLabel: "تسجيل الدخول الآن",
        forFirstTimeSignup: "للمرة الأولى ؟ ",
        forgotPasswordLbl: "هل نسيت كلمة المرور",
        alreadyHaveAccountText: "ليس لديك حساب؟ ",
        clickingContinueText: "بالنقر فوق متابعة ، فإنك توافق على ",
        termsAndConditionsText: "البنود و الظروف.",
        signupNowText: "سجل",
        features: {
          feature1: {
            title: "خطوات غير محدودة",
            image: UnlimitedSteps,
            description:
              "قم بتوصيل عدة تطبيقات وإنشاء خطوات غير محدودة لأتمتة سلسة.",
          },
          feature2: {
            title: "تصور كل شيء",
            image: VisualizeEverything,
            description:
              "قم ببناء عمليات سير العمل الخاصة بك وتخيل الأتمتة باستخدام أداة إنشاء الاتصال المرئي بسهولة.",
          },
          feature3: {
            title: "مكتبة التكامل",
            image: IntegrationLibrary,
            description:
              "مجموعة من التطبيقات السحابية لدمج وإرسال البيانات بينها لتوفير وقتك.",
          },
        },
        loginFailMsg: "اسم المستخدم او كلمة المرور غير صحيحة!",
        captchaErrorMsg: "خطأ في كلمة التحقق",
      },
      forgot: {
        title: "هل نسيت كلمة المرور",
        forgtoBtnLabel: "احصل على رابط نسيت كلمة المرور",
        successSubTitle: "أرسل البريد الإلكتروني",
        features: {
          feature1: {
            title: "أتمتة بسيطة",
            image: SimpleAutomation,
            description:
              "أنشئ سير عمل معقدًا ببساطة وسهولة في دقائق باستخدام أداة إنشاء الاتصال المرئي",
          },
          feature2: {
            title: "منصة للجميع",
            image: PlatformForEveryone,
            description:
              "ليست أداة أتمتة عادية للمبرمجين فقط ، يمكن لأي شخص بناء أتمتة باستخدام KonnectzIT",
          },
          feature3: {
            title: "تكامل تطبيقات سلس",
            image: SeamlessAppsIntegration,
            description:
              "قم بتوصيل تطبيقات غير محدودة من مكتبة التكامل الخاصة بنا والمهام اليومية الآلية",
          }
        },
        emailInvalid: "بريد إلكتروني خاطئ!",
      },
      reset: {
        title: "إعادة تعيين كلمة المرور",
        subTitle: "أنشئ كلمة مرور جديدة",
      },
    },
    konnect: {
      sidebar: {
        search_apps: "بحث...",
        choose_trigger: "اختر المشغل",
        choose_trigger_header: "اختر حدث التطبيق",
        no_triggers_available: "لا توجد مشغلات متاحة",
        choose_format_header: "خيار التنسيق",
        choose_config_header: "اختر التكوين",
        select_trigger_action_dropdown_header: "Select",
        choose_option_header: "تحديد الخيار",
        no_options_available: "لا توجد خيارات متاحة",
        choose_linked_account: "حدد حساب",
        customize_form_header: "تكوين النموذج",
        customize_form_partial: "أختر ",
        configure_response: "تخصيص الاستجابة",
        basic_auth: "المصادقة الأساسية",
        api_key: "مفتاح API",
        api_token: " سر API",
        webhook_url: "عنوان URL للويب هوك",
        webhook_response: "استجابة Webhook",
        choose_action_event: " اختر حدث العمل",
        endpoint_url: "عنوان URL لنقطة النهاية",
        payload_type: "نوع الحمولة",
        wrap_request_array: "طلب الالتفاف في المصفوفة",
        set_params: "تعيين بارامز",
        add_header: "إضافة رأس",
        set_paramter: "تعيين المعلمة",
        expiry_time: "عدد الخط الزمني",
        expiry_val: "الجدول الزمني",
        segmentIndex: "مؤشر القطاع",
        validate: "تحقق",
        generate: "يولد",
      },
      UI: {
        betaText: "بيتا",
        webhookDuplicateToastMsg: "تطبيق Webhook موجود بالفعل!",
      },
      header: {
        backBtnLabel: "عودة",
        saveBtnLabel: "حفظ",
        publishBtnLabel: "ينشر",
        nameOfKonnectz: "اسم Konnectz",
      },
      testAndReview: {
        btnLabel: "اختبار ومراجعة"
      },
      proceed: {
        btnLabel: "تقدم",
      },
      additionalConfigsModal: {
        cancelBtnLabel: "يلغي",
        okBtnLabel: "موافق",
      },
      conditions: {
        addNewConditionBtnLabel: "إضافة شرط جديد +",
        cancelBtnLabel: "يلغي",
        okBtnLabel: "موافق",
        operatorAndLabel: "و",
        operatorOrLabel: "أو",
        headerConditionText: "حالة",
        selectFieldHeaderTitle: "حدد الحقل",
        selectConditionHeaderTitle: "حدد الشرط",
        validateBtnLabel: "تحقق",
        conditionsHeaderText: "الظروف",
      },
      publishSuccessMsg: "تم نشر Konnect بنجاح!",
      saveSuccessMsg: "تم حفظ Konnect بنجاح!"
    },
    apps: {
      authenticatedAppsTitle: "تطبيقات مصادق عليها",
      aunthenticateNowTitle: "المصادقة الآن",
      noAppsToAunthenticate: "لا توجد تطبيقات للمصادقة في الوقت الحالي.",
      authenticateNow: "ليس لديك أي تطبيقات مصادقة. المصادقة الآن.",
      addAccountText: "إضافة حساب",
      betaText: "بيتا",
      oAuthHandlerText: "KonnectzIT OAuth Handler ، يُرجى الانتظار ..",
      cancelBtnText: "إلغاء",
      yesContinueText: "نعم ، استمر",
      requiredText: "(مطلوب)",
      accessAPIModalHeaderText1: "اسمح لـ KonnectzIT بالوصول ",
      accessAPIModalHeaderText2: " الحساب؟",
      accessAPIModalMainText1: "يمكنك العثور على ملف ",
      accessAPIModalMainText2: " بالانتقال إلى ",
      accessAPIModalMainText3: " صفحة التكامل",
      authorized: "التطبيقات المعتمدة"
    },
    konnectzList: {
      homeText: "الصفحة الرئيسية",
      noKonnectzInfo: "لا konnectz في الوقت الحالي!",
      deleteModalBodyText: "هل أنت متأكد أنك تريد حذف ؟",
      deleteModalNoText: "لا",
      deleteModalDeleteText: "حذف",
      folders: {
        createFolderTitleText: "أعط اسما للمجلد الخاص بك",
        createFolderInputPlaceholder: "إسم الملف",
        createFolderBtn: "يخلق",
        deleteFolderConfirmationText: "هل أنت متأكد أنك تريد حذف هذا المجلد؟",
        deleteBtn: "حذف",
        selectFolderInfo: "حدد المجلد الذي تريد نقل الروابط المحددة فيه",
        moveFolderCancelBtn: "يلغي",
        moveBtn: "نقل",
        renameSaveBtn: "يحفظ",
        renameYourFolderText: "أعد تسمية المجلد الخاص بك",
        createFolderBtn: "أنشئ مجلد"
      }
    },
    dashboard: {
      widgets: {
        latestApps: {
          headerTitle: "أحدث التطبيقات",
        },
        totalKonnectz: {
          headerTitle: "إجمالي Konnectz",
          activeKonnectz: "نشط Konnectz",
          inactiveKonnectz: "Konnectz غير نشط",
        },
        latestKonnectz: {
          headerTitle: "أحدث Konnectz",
        },
        subscription: {
          contentText: "افتح المزيد من القوة عن طريق ترقية Pro",
          upgradeBtnLabel: "تحديث الآن",
        },
        taskConsumption: {
          headerTitle: "استهلاك المهام",
        },
      },
    },
    account: {
      countryRequiredText: "الدولة مطلوبة!",
      addressLine1RequiredText: "سطر العنوان 1 مطلوب!",
      cityRequiredText: "المدينة مطلوبة!",
      stateRequiredText: "الدولة مطلوبة!",
      zipCodeRequiredText: "الرمز البريدي مطلوب!",
      taxIdRequiredText: "المعرف الضريبي مطلوب!",
      cityLabel: "مدينة",
      stateLabel: "حالة",
      zipCodeLabel: "الرمز البريدي",
      taxIdLabel: "الرقم الضريبي",
      addressLine2Label: "سطر العنوان 2",
      addressLine1Label: "العنوان الأول",
      countryLabel: "دولة",
      billingtext:"عنوان وصول الفواتير",
      myProfileText: "ملفي",
      emailAddressLabel: "عنوان البريد الالكترونى",
      firstNameLabel: "الاسم الاول",
      lastNameLabel: "الكنية",
      firstNameRequiredText: "اسم القبضة مطلوب!",
      lastNameRequiredText: "إسم العائلة مطلوب!",
      companyNameLabel: "اسم الشركة",
      timeZoneLabel: "وحدة زمنية",
      changeLanguageLabel: "تغيير اللغة",
      changePasswordHeaderText: "غير كلمة السر",
      currentPasswordLabel: "كلمة المرور الحالي",
      currentPasswordRequiredText: "كلمة المرور الحالية مطلوبة!",
      newPasswordLabel: "كلمة سر جديدة",
      newPasswordRequiredText: "مطلوب كلمة مرور جديدة!",
      confirmPasswordLabel: "تأكيد كلمة المرور",
      confirmPasswordRequiredText: "تأكيد كلمة المرور مطلوب!",
      updateBtnLabel: "تحديث",
      editUpdateAccountSideBarText: "تحرير / تحديث الحساب",
      changePasswordSideBarText: "غير كلمة السر",
      billingText: "الفواتير",
      teamText: "فريق",
      agencyText: "وكالة",
      sideBarHeaderAccountText: "الحساب",
      profileUpdatedSuccessfullyText: "تم تحديث الملف الشخصي بنجاح!",
      addTeamMemberText: "إضافة عضو الفريق",
      addSubAccountsText: "إضافة حسابات فرعية",

      billing: {
        couponCodeText: "رمز",
        applyCodeBtnLbl: "تطبيق الكود",
        tasksText: "مهام",
        redeemedOnText: "استرداد في",
        expiresOnText: "تنتهي صلاحيته في",
        noCouponsAppliedText: "لم يتم تطبيق أي ترخيص",
        plansAndFeaturesText: "الخطط والميزات",
        currentPlanText: "الخطة الحالية",
        upgradeText: "تطوير",
        billingText: "الفواتير",
        licenseerror:"رخصة غير صالحة!",
        completionofprofile:"يرجى إكمال ملف التعريف الخاص بك قبل استرداد الرمز. هذا مطلوب من أجل GPDR / معالجة البيانات وغرض الفوترة ",
      },
      team: {
        name: "اسم",
        firstName: "الاسم الاول",
        lastName: "اسم العائلة",
        access: "وصول",
        email: "بريد الالكتروني",
        addBtn: "يضيف",
        cancelBtn: "يلغي",
        assignAccessLbl: "قم بتعيين الوصول",
        noUsersInfo: "لا يوجد مستخدمون متاحون",
        teamMembersHeaderText: "أعضاء الفريق",
      },
      agency: {
        name: "اسم العميل :",
        firstName: "الاسم الاول",
        lastName: "اسم العائلة",
        tasks: "المهمة الموكلة :",
        consumed:"استهلكت المهمة:",
        totalSubaccounts: "إجمالي الحسابات الفرعية:",
        email: "بريد الالكتروني :",
        active: "نشيط :",
        inActive: "غير نشط :",
        addBtn: "يضيف",
        cancelBtn: "يلغي",
        noAgenciesInfo: "لا توجد وكالات متاحة",
        agencyMembersHeaderText: "الحسابات الفرعية",
        total:"إجمالي المهام:",
        update:"تحديث",
        updateTask: "تحديث المهمة",
        delete: "حذف",
        available: "المهمة متاحة",
        deactivate: "تفعيل",
        activate: "إلغاء التنشيط",
        subaccounterror:"فشل تحديث تفاصيل الحساب الفرعي!"

      },
    },
    sideNav: {
      homeLabel: "الصفحة الرئيسية",
      appsLabel: "تطبيقات",
      konnectzLabel: "Konnectz",
      historyLabel: "التاريخ",
      accountLabel: "الحساب",
      notificationsLabel: "إشعارات",
      logoutLabel: "تسجيل خروج",
      getHelpLabel: "احصل على مساعدة؟",
      konnectzITLogoText: "KonnectzIT"
    }
  },
}
