import * as React from "react"
import {
  CanvasInnerDefault,
  CanvasOuterDefault,
  CanvasWrapper,
  LinkDefault,
  LinkWrapper,
  NodeDefault,
  NodeInnerDefault,
  WebhookInnerDefault,
  AddOnInnerDefault,
  DateFormatterInnerDefault,
  ApiInnerDefault,
  NodeWrapper,
  PortDefault,
  PortsDefault,
  ConditionsAppInnerDefault,
  CouponInnerDefault,
  TextSplitterDefault,
} from "../"

import { EditorPlaceholder } from "../../../UI"

export const BaseEditor = props => {
  const [canvasSize, setCanvasSize] = React.useState({ width: 0, height: 0 })

  const {
    editorState,
    editorMeta,
    editorActions: {
      onDragNode,
      onDragNodeStop,
      onDragNodeStart,
      onCanvasDrop,
      onLinkStart,
      onLinkMove,
      onLinkComplete,
      onLinkCancel,
      onPortPositionChange,
      onLinkMouseEnter,
      onLinkMouseLeave,
      onLinkClick,
      onCanvasClick,
      onNodeClick,
      onNodeMouseEnter,
      onNodeMouseLeave,
      onNodeSizeChange,
      onPortTextChange,
      toggleBasicAuth,
      captureWebhookResponse,
      onDeleteLink,
      expandCollapseNode,
    },
    Components: {
      CanvasOuter = CanvasOuterDefault,
      CanvasInner = CanvasInnerDefault,
      NodeInner = NodeInnerDefault,
      WebhookInner = WebhookInnerDefault,
      AddOnInner = AddOnInnerDefault,
      ApiInner = ApiInnerDefault,
      CouponInner = CouponInnerDefault,
      TextSplitter = TextSplitterDefault,
      DateFormatterInner = DateFormatterInnerDefault,
      ConditionsAppInner = ConditionsAppInnerDefault,
      Ports = PortsDefault,
      Port = PortDefault,
      Node = NodeDefault,
      Link = LinkDefault,
    } = {},
    config = {},
    editorActions,
    testAndReviewLoader,
  } = props
  const { links = [], nodes = [], selected, hovered, offset, scale } = editorState

  const canvasCallbacks = {
    onCanvasClick,
    onCanvasDrop,
  }

  const linkCallbacks = { onLinkMouseEnter, onLinkMouseLeave, onLinkClick }
  const nodeCallbacks = {
    onDragNode,
    onNodeClick,
    onDragNodeStart,
    onDragNodeStop,
    onNodeMouseEnter,
    onNodeMouseLeave,
    onNodeSizeChange,
  }
  const portCallbacks = {
    onPortPositionChange,
    onLinkStart,
    onLinkMove,
    onLinkComplete,
    onLinkCancel,
  }
  const nodesInView = [...nodes]
  const linksInView = [...links]

  return (
    <CanvasWrapper
      position={editorState.offset}
      scale={editorState.scale}
      ComponentInner={CanvasInner}
      ComponentOuter={CanvasOuter}
      onSizeChange={(width, height) => setCanvasSize({ width, height })}
      {...canvasCallbacks}
      editorActions={editorActions}
      editorMeta={editorMeta}
      nodes={nodes}
    >
      {/* {editorState.nodes.length ? ( */}
      <>
        {linksInView.map(link => {
          const isSelected = !config.readonly && selected.type === "link" && selected.id === link.id
          const isHovered = !config.readonly && hovered.type === "link" && hovered.id === link.id
          const fromNodeId = link.from.node
          const toNodeId = link.to.node
          return (
            <LinkWrapper
              ts={link.ts || new Date().getTime()}
              offset={offset}
              config={config}
              key={link.id}
              link={link}
              Component={Link}
              isSelected={isSelected}
              isHovered={isHovered}
              fromNode={nodes.find(x => x.nodeId === fromNodeId)}
              toNode={toNodeId ? nodes.find(x => x.nodeId === toNodeId) : undefined}
              chart={editorState}
              {...linkCallbacks}
              onDeleteLink={editorActions.onDeleteLink}
            />
          )
        })}
        {nodesInView.map((node, idx) => {
          const nodeId = node.nodeId
          const isSelected = selected.type === "node" && selected.id === nodeId
          const selectedLink = getSelectedLinkForNode(selected, nodeId, links)
          const hoveredLink = getSelectedLinkForNode(hovered, nodeId, links)
          let AppNodeInner = NodeInner
          switch (node.appType) {
            case "WEBHOOK":
              {
                AppNodeInner = WebhookInner
              }
              break
            case "ADD_ON":
              {
                AppNodeInner = AddOnInner
                if (node.provider.toLowerCase() === "dateformatter") AppNodeInner = DateFormatterInner
                if (node.provider.toLowerCase() === "conditioner") AppNodeInner = ConditionsAppInner
                if (node.provider.toLowerCase() === "api") AppNodeInner = ApiInner
                if (node.provider.toLowerCase() === "generator") AppNodeInner = CouponInner
                if (node.provider.toLowerCase() === "textsplitter") AppNodeInner = TextSplitter
              }
              break
            default: {
              AppNodeInner = !idx && node.appType === "WEBHOOK_API" ? WebhookInner : NodeInner
            }
          }
          return (
            <NodeWrapper
              config={config}
              editorActions={editorActions}
              key={nodeId}
              Component={Node}
              node={node}
              chart={editorState}
              offset={editorState.offset}
              isSelected={isSelected}
              selected={selectedLink ? selected : undefined}
              hovered={hoveredLink ? hovered : undefined}
              selectedLink={selectedLink}
              hoveredLink={hoveredLink}
              NodeInner={AppNodeInner}
              
              Links={links}
              testAndReviewLoader={testAndReviewLoader}
              {...nodeCallbacks}
              {...portCallbacks}
            />
            
          )
        })}
      </>
      {/*  ) : (
      //   <>
      //     <EditorPlaceholder></EditorPlaceholder>
      //   </>
      // )}*/}
    </CanvasWrapper>
  )
}

const getSelectedLinkForNode = (selected, nodeId, links) => {
  const link = selected.type === "link" && selected.id ? links[selected.id] : undefined

  if (link && (link.from.nodeId === nodeId || link.to.nodeId === nodeId)) {
    return link
  }

  return undefined
}
