import React, { useState, useEffect } from "react"
import { Box, Text, Flex, Link } from "rebass"
import { useTranslation } from "react-i18next"
import { Checkbox, Label } from "@rebass/forms"
import px2vw from "utils/px2vw"
import DeleteIconSvg from "src/assets/images/deleteIcon"
import { Button } from "src/components/index"
import '../style.css'
import { Icon } from "src/components"
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons"
export const AdditionalConfigsModal = ({ closeModal, addedKeys = [], data = [], appIcon }) => {
    const { t } = useTranslation()
    const [list, setList] = useState([])
    const [showValue, setShowValue] = useState('')
    const [expandedSection, setExpandedSection] = useState([])

    useEffect(() => {
        let listData = data.map(item => {
            const l = addedKeys.findIndex(y => y.uiKey === item.uiKey)
            return { ...item, selected: l > -1 }
        })
        setList(listData)
        return () => {
            setList([])
        }
    }, [data])

    let formElements = []

    let sections = []

    const onMouseOverItem = (value) => {
        setShowValue(value)
    }

    const onMouseOutItem = () => {
        setShowValue('')
    }

    const collapseAll = parentId => {
        const _list = [...list]
        const section = _list.findIndex(x => x.id === parentId)
        _list[section].isOpen = false
        if (_list[section].type === "node") {
            _list[section].children.map(childKey => {
                let child = _list.findIndex(x => x.id === childKey)
                if (_list[child].type === "leaf") {
                    _list[child].isOpen = false
                } else {
                    _list[child].isAvailable = false
                    _list[child].isOpen = false

                    _list[child].children.map(x => {
                        collapseAll(x)
                    })
                }
            })
        }
        setList(_list)
    }

    const HiSection = ({ info, setExpanded, index, isExpanded, parent = [] }) => {
        return (
            <>
                <Flex
                    width="100%"
                    height="40px"
                    sx={{
                        background: "#F3F7FF",
                        borderRadius: "8px",
                        alignItems: "center",
                        px: "10px",
                        mb: "10px",
                        justifyContent: "space-between",
                        cursor: "pointer",
                    }}

                    onMouseDown={(e) => {
                        e.stopPropagation();
                        if (e.nativeEvent.which === 1) {
                            if (info.isOpen) {
                                //close all children; including sub children
                                collapseAll(info.id)
                            } else {
                                //open up to its immediate child
                                const item = list.findIndex(u => u.id === info.id)
                                list[item].isOpen = true
                                list[item].children.map(x => {
                                    const item = list.find(y => y.id === x)

                                    if (item.type === "node") {
                                        item.isAvailable = true
                                    } else {
                                        item.isOpen = true
                                    }
                                })
                            }

                            setExpandedSection([
                                ...expandedSection,
                                {
                                    sectionId: info.id,
                                },
                            ])
                        }
                    }}
                >
                    {info.label}
                    <Icon icon={isExpanded ? faChevronUp : faChevronDown} size="lg" />
                </Flex>
            </>
        )
    }

    if (list) {

        Object.keys(list).map(q => {
            const x = list[q]
            const __key = `${x.id}_${x.parent}${x.uiKey}`
            if (x.type === "leaf" && (x.isOpen || x.level === 0)) {
                let displayValue = x.selected ? true : showValue === x.value
                formElements.push(
                    <Flex onMouseOver={() => onMouseOverItem(x.value)} onMouseOut={onMouseOutItem} variant="flex.center" sx={{
                        flexDirection: "row",
                        marginBottom: "20px",
                        padding: "5px",
                        marginRight: "20px",
                        backgroundColor: displayValue ? "#F3F7FF" : "#FFFFFF",
                        border: displayValue ? '1px solid #B7CBFF' : '1px solid #FFFFFF',
                        borderRadius: '16px',
                        "&:hover": {
                            backgroundColor: displayValue ? "#F3F7FF" : "#FFFFFF",
                            border: displayValue ? '1px solid #B7CBFF' : '1px solid #FFFFFF',
                            borderRadius: '16px'
                        }
                    }}>
                        <Label
                            variant="wrapperLabel"
                            sx={{ fontSize: ["xxs", "xs", "sm"], ml: px2vw(8), color: "app.secondary.20" }}
                        >
                            <Checkbox
                                name="isAdmin"
                                onChange={e => {
                                    const l = list.findIndex(y => y.uiKey === x.uiKey)
                                    list[l] = { ...x, selected: !x.selected }
                                    setList(list)
                                }}
                                checked={x.selected}
                            />
                            {/* <Box sx={{ marginLeft: "20px" }}>
                                <img src={appIcon} style={{ width: 20 }} />
                            </Box> */}
                            <Box sx={{ marginLeft: "10px" }}>
                                <span style={{ color: "#000000", fontSize: "14px", fontWeight: "500" }}>{x.label}</span>
                                {displayValue && (
                                    <span sx={{
                                        color: "#FFFFFF", fontSize: "14px", fontWeight: "500", "&:hover": {
                                            color: "#000000"
                                        }
                                    }}>{' (' + x.value + ')'}</span>
                                )}
                            </Box>
                        </Label>
                    </Flex>
                )
            } else if (x.isAvailable || x.isOpen || x.level === 0) {
                formElements.push(<HiSection info={x} isExpanded={x.isOpen} key={`${x.app_id}_${__key}`}></HiSection>)
            }
        })
    }

    return (
        <>
            <Flex
                sx={{
                    textAlign: "left",
                    cursor: "pointer",
                    flexDirection: "column",
                    backgroundColor: "app.primary.110",
                }}
            >
                <Box className={'modal-scroller'} sx={{ width: '95%', height: "100%", marginTop: "40px", marginLeft: "35px", minHeight: "600px", maxHeight: "600px" }}>
                    {formElements}
                </Box>
                <Box sx={{ height: "60px", boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.08);", textAlign: "center" }}>
                    <Flex flexDirection="row" sx={{ justifyContent: "center", marginTop: "10px" }}>
                        <Box sx={{ textAlign: "center", justifyContent: "center" }}>
                            <Button
                                fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                                sx={{
                                    background: "none",
                                    boxShadow: "none",
                                    fontWeight: "normal",
                                    cursor: "pointer",
                                    color: "app.secondary.70",
                                    marginRight: "20px",
                                    "&:hover": {
                                        background: "none",
                                        color: "app.secondary.70",
                                    },
                                }}
                                onClick={closeModal}
                            >
                                {t("konnect.additionalConfigsModal.cancelBtnLabel")}
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                variant="primary"
                                fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                                sx={{ fontWeight: "normal", px: px2vw(20), borderRadius: "0px" }}
                                onClick={() => closeModal(list)}
                            >
                                {t("konnect.additionalConfigsModal.okBtnLabel")}
                            </Button>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
        </>
    )
}
