import { makeRequestActionCreator } from 'utils/redux'
import { GET_PROFILE, UPDATE_PROFILE } from 'config/Constants'

export const getProfile = () => dispatch => {
  const request = makeRequestActionCreator(GET_PROFILE)

  return dispatch(
    request({
      url: 'api/v1/users/profile',
      method: 'GET'
    })
  )
}

export const updateProfile = (payload) => dispatch => {
  const request = makeRequestActionCreator(UPDATE_PROFILE)

  return dispatch(
    request({
      url: 'api/v1/users/profile',
      method: 'POST',
      data: payload
    })
  )
}

export const updatePassword = (payload) => dispatch => {
  const request = makeRequestActionCreator(UPDATE_PROFILE)

  return dispatch(
    request({
      url: 'api/v1/users/change_password',
      method: 'POST',
      data: payload
    })
  )
}