import React from "react"
import * as store from "store2"
//store2 is a library used for localStorage and sessionStorage 

const user = store.session.get("user_session")//to store
const data = user ? JSON.parse(user) : null
const initialState = {
  user: data,
  isAuthenticated: Boolean(data),
}

const AuthContext = React.createContext({})//creating Context

const AuthProvider = ({ children }) => {
  const [state, updateAuthState] = React.useState(initialState)

  return (
    <AuthContext.Provider value={{ isAuthenticated: state.isAuthenticated, user: state.user, updateAuthState }}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }

  return context
}

export { AuthProvider, useAuth }
