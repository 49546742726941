import i18next from "i18next"
import { initReactI18next } from "react-i18next"

import { resources } from "src/translations/translations"

i18next.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safe from xss
  },
})

// eslint-disable-next-line import/no-default-export
export default i18next
