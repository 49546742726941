import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { Flex, Box } from "rebass"
import { getResellerUsersList } from "src/store/actions"
import { ToastContainer, toast } from "react-toastify"
import { ResellerUsersList }  from "../../components/Reseller/resellerUsersList.js"

export const ResellerContainer_ = ({
  addResellerMemberBtnClick,
  getResellerUsersList,
  resellerUsersList,
  totalSubAccounts,
  allowedSubaccountsLimit,
  totalTasks,
  activeSubAccounts,
  inActiveSubAccounts,
  taskConsumed,
  updateResellerMember,
}) => {
  useEffect(() => {
    getResellerUsersList()
  }, [])
 
  return (
    <>
   
      <Flex flexDirection="row" sx={{ textAlign: "end" }}>
        <ResellerUsersList 
          addResellerMemberBtnClick={addResellerMemberBtnClick}
          users={resellerUsersList}
          totalSubAccounts={totalSubAccounts}
          allowedSubaccountsLimit={allowedSubaccountsLimit}
          totalTasks={totalTasks}
          activeSubAccounts={activeSubAccounts}
          inActiveSubAccounts={inActiveSubAccounts}
          taskConsumed={taskConsumed}
          
        />
      </Flex>
      {/* <Flex sx={{ flexDirection: 'Column', textAlign:"end"}} width={{ _: 550, sm: 550, md: 300, lg: 500, xl: 1120 }}>
         
         <AgencyUsersList users={agencyUsersList} />
     </Flex>  */}
      {/* <Flex flexDirection="column" width={{ _: 300, sm: 300, md: 300, lg: 300, xl: 550}}>
                <AgencyUsersList users={agencyUsersList} />
            </Flex> */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    resellerUsersList: state.getIn(["reseller", "resellerUsersList"]),
    totalTasks: state.getIn(["reseller", "totalTasks"]),
    activeSubAccounts: state.getIn(["reseller", "activeSubAccounts"]),
    inActiveSubAccounts: state.getIn(["reseller", "inActiveSubAccounts"]),
    taskConsumed: state.getIn(["reseller", "taskConsumed"]),
    totalSubAccounts: state.getIn(["reseller", "totalSubAccounts"]),
    allowedSubaccountsLimit: state.getIn(["reseller", "allowedSubaccountsLimit"]),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getResellerUsersList: () => dispatch(getResellerUsersList()),
  }
}

export const ResellerContainer = connect(mapStateToProps, mapDispatchToProps)(ResellerContainer_)
