import React, { useState, useEffect } from "react"
import Draggable from "react-draggable"
import { KONNECT_FLOW_DRAGGABLE } from "config/Constants"
import { v4 } from "uuid"
import CanvasContext from "./CanvasContext"
import styled from "@emotion/styled"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"
import { PanAndZoom } from "./Panner"
import "./wrapper.css"
import { ToastContainer, toast } from "react-toastify"

export const CanvasInnerDefault = styled.div`
  // position: relative;
  width: 10000px;
  height: 10000px;
  border: 2px dashed #ffffff;
  // background: blanchedalmond;
`

// width: 100%;
// overflow: hidden;

export const CanvasOuterDefault = styled.div`
  position: relative;
  // background: powderblue;
`

class _CanvasWrapper extends React.Component {
  state = {
    width: 0,
    height: 0,
    offsetX: 0,
    offsetY: 0,
  }

  ref = React.createRef()

  componentDidMount() {
    this.updateSize()

    if (this.ref.current) {
      if (window.ResizeObserver) {
        const ro = new window.ResizeObserver(this.updateSize)
        ro.observe(this.ref.current)
      } else {
        window.addEventListener("resize", this.updateSize)
      }
      window.addEventListener("scroll", this.updateSize)
    }
  }

  componentDidUpdate() {
    this.updateSize()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateSize)
    window.removeEventListener("scroll", this.updateSize)
  }

  updateScale = stats => {
    const { editorActions } = this.props

    editorActions.setScale({
      scale: stats.scale,
      offset: {
        x: stats.positionX,
        y: stats.positionY,
      },
    })
  }

  render() {
    const {
      config,
      scale,
      position,
      children,
      onCanvasClick,
      onCanvasDrop,
      background,
      editorActions,
      ComponentInner,
      editorMeta,
      nodes
    } = this.props
    const { offsetX, offsetY } = this.state

    const options = {
      transformEnabled: true,
      maxScale: 2,
      limitToBounds: true,
      limitToWrapper: false,
      centerContent: false,
    }

    /* Check if webhook app is already present */
    let isWebhookExists = false

    nodes.map(node => {
      if(node.appType === "WEBHOOK") {
        isWebhookExists = true
      }
    })

    return (
      <CanvasContext.Provider value={{ offsetX: this.state.offsetX, offsetY: this.state.offsetY, zoomScale: scale }}>
        <CanvasOuterDefault config={config} ref={this.ref} background={background}>
          <TransformWrapper
            scale={scale}
            nodes={nodes}
            defaultPositionX={position.x}
            defaultPositionY={position.y}
            positionX={position.x}
            positionY={position.y}
            options={{
              maxScale: 2,
              minScale: 0.4,
              limitToBounds: false,
              contentClass: "transformComponentSet",
            }}
            zoomIn={{ step: 10, animation: false, animationTime: 0 }}
            zoomOut={{ step: 10, animation: false, animationTime: 0 }}
            pan={{ disabled: false, panReturnAnimationTime: 0, animationTime: 0 }}
            scalePadding={{ animationTime: 0, disabled: true }}
            wheel={{ disabled: !editorMeta.onWheel, limitsOnWheel: true, step: 80 }}
            // doubleClick={{ disabled: true }}
            onZoomChange={this.updateScale}
            onPanning={data => {
              editorActions.onDragCanvas({ data })
            }}
            onPanningStop={data => {
              editorActions.onDragCanvasStop({ data })
            }}
          >
            {({ resetTransform, zoomIn, zoomOut, scale, nodes }) => (
              <TransformComponent
                style={{
                  display: "fixed",
                }}
              >
                {/* <PanAndZoom scale={scale} onDragCanvas={editorActions.onDragCanvas} pos={position}> */}
                <CanvasInnerDefault
                  config={config}
                  children={children}
                  onClick={onCanvasClick}
                  tabIndex={0}
                  onDrop={e => {
                    e.preventDefault()
                    const data = JSON.parse(e.dataTransfer.getData(KONNECT_FLOW_DRAGGABLE))
                    //console.log(data,"data in editor")
                    if(data.type === 'WEBHOOK' && isWebhookExists) {
                      toast.info("Webhook app already exists!"), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                      }
                      return
                    }
                    if (data) {
                      const nodeId = v4()

                      onCanvasDrop({
                        nodeId,
                        data,
                        position: {
                          x: (e.clientX - (position.x + offsetX)) / scale,
                          y: (e.clientY - (position.y + offsetY)) / scale,
                        },
                        recent: true,
                        editorActions,
                      })
                    }
                  }}
                  onDragOver={e => e.preventDefault()}
                />
                {/* </PanAndZoom> */}
              </TransformComponent>
            )}
          </TransformWrapper>
        </CanvasOuterDefault>
      </CanvasContext.Provider>
    )
  }

  updateSize = () => {
    const el = this.ref.current

    if (el) {
      const rect = el.getBoundingClientRect()

      if (el.offsetWidth !== this.state.width || el.offsetHeight !== this.state.height) {
        this.setState({ width: el.offsetWidth, height: el.offsetHeight })
        this.props.onSizeChange(el.offsetWidth, el.offsetHeight)
      }

      if (rect.left !== this.state.offsetX || rect.top !== this.state.offsetY) {
        this.setState({ offsetX: rect.left, offsetY: rect.top })
      }
    }
  }
}

export const CanvasWrapper = _CanvasWrapper
