import React, { useState } from "react"
import { Box, Text, Flex, Link } from "rebass"
import { Input, Button, Icon } from "src/components"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw"
// import RenameFolder from "src/assets/images/rename_folder.svg"
import RenameFolderIcon from "src/assets/images/rename_folder_icon.svg"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

export const RenameFolderModal = ({ closeModal, renameFolder, foldersList, folderId }) => {
  const folderObj = foldersList.filter(folder => folder.id === folderId)[0]
  const { t } = useTranslation()
  const [folderName, setFolderName] = useState(folderObj.name)
  return (
    <>
      <Flex flexDirection="column" sx={{ borderRadius: "16px", px: px2vw(60), padding: "0px", minWidth: "500px" }}>
        <Flex flexDirection="row" sx={{ borderRadius: "16px", px: px2vw(20), padding: "0px" }}>
          <Box sx={{ p: "7px 3px 0px 5px", m: "2px", boxShadow: "1px 1px 1px 1px #e6e2e2", borderRadius: "5px" }}>
            <RenameFolderIcon width="20px" height="20px" />
          </Box>
          <Text
            fontSize={[20]}
            fontWeight="semi-bold"
            color="app.primary.30"
            textAlign="left"
            sx={{ pt: 2.5, justifyContent: "left", textAlign: "left", mr: px2vw(3), ml: "12px" }}
          >
            Rename Folder
          </Text>
        </Flex>
        <Box sx={{ background: "#BCC6CC", width: "inherit", height: "1px", margin: "14px -20px" }}></Box>
        <Box sx={{ p: px2vw(20), justifyContent: "center", textAlign: "center" }}>
          {/* <img width="135px" height="95px" src={RenameFolderImg} /> */}
          <RenameFolderIcon width="135px" height="95px" />
        </Box>
        <Box sx={{ color: "#000000", p: px2vw(10) }}>
          <Text sx={{ mb: px2vw(18), fontSize: "sm", color: "app.primary.30" }}>
            {t("konnectzList.folders.renameYourFolderText")}
          </Text>
          <Input
            type={"text"}
            value={folderName}
            sxProps={{
              "& ~ span": { color: "#B7CBFF !important" },
            }}
            onChange={e => setFolderName(e.target.value)}
          />
        </Box>
        <Box sx={{ p: px2vw(10) }}>
          <Flex flexDirection="row" sx={{ justifyContent: "center", mb: "10px" }}>
            <Box sx={{ textAlign: "center", justifyContent: "center" }}>
              <Button
                fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                sx={{
                  backgroundColor: "app.secondary.110",
                  boxShadow: "none",
                  fontWeight: "normal",
                  cursor: "pointer",
                  color: "app.primary.30",
                  display: "flex",
                }}
                onClick={() => renameFolder(folderName)}
              >
                <Icon icon={faCheckCircle} pt="3px" />
                <Box sx={{ cursor: "pointer", m: "1px 0 0 10px" }}>{t("konnectzList.folders.renameSaveBtn")}</Box>
              </Button>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}