/**
 * Auth Shell wrapper for all Auth pages.
 */
import React, { useState, useEffect, useRef } from "react"
import { Flex, Box } from "rebass"
import styled from "@emotion/styled"
import { FeaturesView } from "./Features.view"
import px2vw from "utils/px2vw"
import Carousel from "react-elastic-carousel"

const ShellBox = styled(Flex)`
  /* Fixed width and height for the Auth Shell */
  //max-width: 1440px;
  width: 100vw;
  margin: auto;
`

export const AuthShellView = ({ features, children }) => {
  const carouselRef = useRef(null)
  const playSpeed = 4000
  const onNextEnd = (currentItem, nextItem) => {
    if (features.length - 1 === nextItem) {
      setTimeout(() => {
        carouselRef.current.goTo(0)
      }, playSpeed)
    }
  }
  return (
    <Flex width="100vw" height="100vh" alignItems="flex-start" sx={{ backgroundColor: "#DBE5FF" }}>
      <ShellBox
        sx={{
          padding: [`${px2vw(20)}`, `${px2vw(20)}`, `${px2vw(20)} ${px2vw(20)}`],
          justifyContent: "center",
          flexDirection: ["row", "row", "row", "row"],
        }}
      >
        <FeaturesBox px={{ _: 10, sm: 10, md: 15, lg: 30, xl: 50 }}>
          {features.length > 0 && (
            <Carousel
              className="features-carousel"
              ref={carouselRef}
              enableMouseSwipe={true}
              autoPlaySpeed={playSpeed}
              // style={{ paddingBottom: "30px" }}
              easing="ease"
              transitionMs="500"
              showArrows={false}
              enableAutoPlay={true}
              onNextEnd={onNextEnd}
              renderPagination={({ pages, activePage, onClick }) => {
                return (
                  <Flex direction="row">
                    {pages.map(page => {
                      const isActivePage = activePage === page
                      return (
                        <Box
                          sx={{
                            borderRadius: isActivePage ? "12px" : "8px",
                            width: isActivePage ? "12px" : "8px",
                            height: isActivePage ? "12px" : "8px",
                            mx: "3px",
                            justifyContent: "center",
                            backgroundColor: isActivePage ? "#F7C545" : "#B7CBFF",
                          }}
                          key={page}
                          onClick={() => onClick(page)}
                        />
                      )
                    })}
                  </Flex>
                )
              }}
            >
              {features.map((item, i) => (
                <FeaturesView key={i} feature={item} />
              ))}
            </Carousel>
          )}
          {/* {features ?.length > 0 &&
          features.map((item, i) => {
            return <FeaturesView mb={i !== features.length - 1 ? px2vw(63) : null} key={i} feature={item} />
          })} */}
        </FeaturesBox>
        <Flex column align="center" justifyContent="center">
          <MainPanel>{children}</MainPanel>
        </Flex>
      </ShellBox>
    </Flex>
  )
}

export const FeaturesBox = ({ children, hasValidation, ...props }) => (
  <Flex
    sx={{
      width: ["100%", "100%", "100%"],
      maxWidth: px2vw(600),
      justifyContent: "center",
      "& > div:not(:first-of-type)": {
        display: ["none", "none", "flex"],
      },
    }}
    flexDirection="row"
    {...props}
  >
    {children}
  </Flex>
)

export const MainPanel = ({ children, hasValidation, ...props }) => (
  <Flex
    width={{ _: 400, sm: 400, md: 400, lg: 440, xl: 504 }}
    minWidth={{ _: 400, sm: 400, md: 400, lg: 440, xl: 504 }}
    minHeight={{ _: 560, sm: 560, md: 560, lg: 560, xl: 678 }}
    height={{ _: 560, sm: 560, md: 560, lg: 560, xl: 678 }}
    sx={{
      backgroundColor: "white",
      textAlign: "center",
      boxShadow: "0px 0px 10px 4px rgb(21,47,115, 0.35)",
    }}
    py={{ _: 20, sm: 20, md: 20, lg: 20, xl: 31 }}
    px={{ _: 30, sm: 30, md: 35, lg: 40, xl: 57 }}
    flexDirection="column"
    {...props}
  >
    {children}
  </Flex>
)
