import { configureStore } from "../../../../node_modules/@reduxjs/toolkit/dist"
import sidebarReducer from "./slice/sidebarSlice"
import canvasReducer from "./slice/canvasSlice"
import constantReducer from "./slice/constantSlice"
export const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    canvas: canvasReducer,
    constant: constantReducer,
  },
})
