import React, { useState, useEffect } from "react"
import { Flex, Text, Box } from "rebass"
import { connect } from "react-redux"
import { Button, Icon, Input } from "src/components"
import { useTranslation } from "react-i18next"
import px2vw from "utils/px2vw"
import styled from "@emotion/styled"
import { IconWrapper, SearchWrapper } from "../../components/Konnect/UI"
import { faArrowLeft, faUndo, faRedo, faCompress, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"
import MenuCollapseSVG from "src/assets/images/menu-collapse"
import MenuSVG from "src/assets/images/menu"
import { Modal } from "react-responsive-modal"
import { ToastContainer, toast } from "react-toastify"
import { Input as RebassInput, Label as RebassLabel } from "@rebass/forms"

import {
  getApps,
  getAuthApps,
  setLinkedAccounts,
  getLinkedAccounts,
  getAppEvents,
  getAppEventConfig,
  getAppEventConfigDetail,
  getAppEventConfigDetailFetch,
  setEventsConfig,
  getEditorState,
  setEditorState,
  getKonnectName,
  setKonnectName,
  onDragNode,
  onDragNodeStart,
  onDragNodeStop,
  onCanvasDrop,
  onLinkStart,
  onLinkMove,
  onLinkComplete,
  onLinkCancel,
  onPortPositionChange,
  onLinkMouseEnter,
  onLinkMouseLeave,
  onLinkClick,
  onCanvasClick,
  onNodeClick,
  onNodeMouseEnter,
  onNodeMouseLeave,
  onNodeSizeChange,
  onPortTextChange,
  saveConnect,
  toggleSidebar,
  setScale,
  clearState,
  redo,
  undo,
  onDragCanvasStop,
  onDragCanvas,
  getKonnect,
  toggleBasicAuth,
  captureWebhookResponse,
  onWebhookAuthChange,
  onDeleteNode,
  onDeleteNodeV2,
  onSetAsFirstNode,
  onDeleteLink,
  expandCollapseNode,
  appTestAndReview,
  createAdditionalFields,
  setConfigMenuState,
  modifyConditions,
} from "src/store/actions"
import { KonnectView } from "./KonnectApps.view"
import { KonnectWrapper, Konnectheader } from "src/components/Konnect/UI"

const Container = props => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [saveOrPublishMessage, setSaveOrPublishMessage] = useState(false)
  const [publishFailure, setPublishFailure] = useState(false)
  const [konnectTitle, setKonnectTitle] = useState('')

  useEffect(() => {
    props.clearState()
    if (props.match.params.id) {
      props.getKonnect(props.match.params.id).then((res) => {
        setKonnectTitle(res.payload.canvas_json.konnect_name)
      })
    }
  }, [])

  useEffect(() => {
    setKonnectTitle(editorState.konnect_name)
  }, [editorState])

  const showSaveModal = (message, res) => {
    setOpenModal(true)
    setSaveOrPublishMessage(message)
  }

  const onCloseModal = list => {
    setOpenModal(false)
  }

  const {
    apps,
    authorisedApps,
    appEvents,
    appEventConfig,
    editorState,
    getApps,
    getAuthApps,
    getLinkedAccounts,
    setLinkedAccounts,
    getAppEvents,
    getAppEventConfig,
    getAppEventConfigDetail,
    getAppEventConfigDetailFetch,
    setEventsConfig,
    setEditorState,
    getEditorState,
    setKonnectName,
    getKonnectName,
    onDragNode,
    onDragNodeStop,
    onDragNodeStart,
    onCanvasDrop,
    onLinkStart,
    onLinkMove,
    onLinkComplete,
    onLinkCancel,
    onPortPositionChange,
    onLinkMouseEnter,
    onLinkMouseLeave,
    onLinkClick,
    onCanvasClick,
    onNodeClick,
    onNodeMouseEnter,
    onNodeMouseLeave,
    onNodeSizeChange,
    onPortTextChange,
    saveConnect,
    payload,
    toggleSidebar,
    editorMeta,
    setScale,
    clearState,
    redo,
    undo,
    onDragCanvas,
    onDragCanvasStop,
    toggleBasicAuth,
    captureWebhookResponse,
    onWebhookAuthChange,
    onDeleteNode,
    onDeleteNodeV2,
    onSetAsFirstNode,
    onDeleteLink,
    expandCollapseNode,
    appTestAndReview,
    createAdditionalFields,
    setConfigMenuState,
    modifyConditions,
    testAndReviewLoader
  } = props

  const { t } = useTranslation()

  const onSearchItem = e => {
    getAuthApps(e.currentTarget.value, true)
  }
  return (
    <>
      <KonnectWrapper sx={{ flexDirection: "column", alignItems: "stretch", pt: 6 }} id="konnectWrapper">
        <Konnectheader height={{ _: 80, sm: 80, md: 80, lg: 80, xl: 80 }}>
          <Flex flexDirection="row" sx={{ flexShrink: 0, flexGrow: 1, justifyContent: "left", color: "#152F73" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                width: "65px",
                height: "80px",
                marginRight: px2vw(20),
                backgroundColor: isCollapsed ? "#152F73" : "#F3F7FF",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                // toggleSidebar()
                setIsCollapsed(!isCollapsed)
              }}
            >
              {/* <IconWrapper
                icon={faCompress}
                width="20px"
                height="20px"
                style={{
                  alignSelf: 'center'
                }}
              /> */}
              {isCollapsed ? <MenuSVG /> : <MenuCollapseSVG />}
            </Box>
            <Box
              sx={{
                height: "80px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",
                justifyContent: "center",
              }}
            >
              <IconWrapper
                width="18px"
                height="12px"
                icon={faArrowLeft}
                style={{
                  alignSelf: "center",
                }}
                onClick={() => {
                  clearState()
                  history.back()
                }}
              />
            </Box>

            <Box
              sx={{
                ml: 11,
                height: "80px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",
                justifyContent: "center",
              }}
            >
              <span
                onClick={() => {
                  history.back()
                }}
                style={{
                  alignSelf: "center",
                }}
              >
                {t("konnect.header.backBtnLabel")}
              </span>
            </Box>

          </Flex>

          <Flex flexDirection="row" sx={{ flexGrow: 1, justifyContent: "flex-end", marginRight: px2vw(20) }}>
            <Box
              sx={{
                mx: px2vw(250),
                height: "80px",
                display: "flex",
                alignItems: "center",

                cursor: "pointer",
                justifyContent: "center",
              }}>
              <RebassInput
                className="konnect-name"
                width="368px"
                height="37px"
                maxLength='120'
                placeholder={t("konnect.header.nameOfKonnectz")}

                autoFocus
                onFocus={(event) => event.target.placeholder = " "}
                onBlur={(event) => event.target.placeholder = t("konnect.header.nameOfKonnectz")}
                type="text"
                value={konnectTitle}
                defaultValue={konnectTitle}
                onChange={e => {
                  setKonnectTitle(e.target.value)
                  setKonnectName(e.target.value)
                }}
                style={{
                  color: '#152F73',
                  margin: 'auto',
                  border: 'none',
                  fontFamily: 'arial',
                  fontWeight: '500',
                  paddingLeft: '25px',
                  alignSelf: "center",
                  borderRadius: '16px',
                  fontStyle: 'normal',
                  lineHeight: '17px',
                  background: '#F3F7FF'
                }}
              ></RebassInput>
            </Box>




            <Box
              sx={{
                mx: px2vw(10),
                height: "80px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",
                justifyContent: "center",
              }}
            >
              <IconWrapper
                icon={faPlus}
                style={{
                  alignSelf: "center",
                }}
                onClick={e => {
                  const { scale } = editorState
                  if (scale <= 2)
                    setScale({
                      scale: scale + 0.05,
                    })
                }}
              />
            </Box>
            <Box
              sx={{
                mx: px2vw(10),
                height: "80px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",
                justifyContent: "center",
              }}
            >
              <Input
                width="54px"
                height="33px"
                style={{
                  alignSelf: "center",
                }}
                fontSize="18px"
                value={parseInt(editorState.scale * 100)}
                onChange={e => {
                  const val = e.target.value
                  if (isNaN(val)) return

                  const value = parseInt(val)

                  const scaledVal = value / 100
                  if (scaledVal < 0.2) return
                  setScale({ scale: scaledVal })
                }}
                type={"number"}
              ></Input>
            </Box>
            <Box
              sx={{
                mx: px2vw(10),
                height: "80px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",
                justifyContent: "center",
              }}
            >
              <IconWrapper
                icon={faMinus}
                style={{
                  alignSelf: "center",
                }}
                onClick={e => {
                  const { scale } = editorState
                  if (scale >= 0.1) setScale({ scale: scale - 0.05 })
                }}
              />
            </Box>
            <Box
              sx={{
                mx: px2vw(10),
                height: "80px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",
                justifyContent: "center",
              }}
            >
              <IconWrapper
                icon={faUndo}
                style={{
                  alignSelf: "center",
                }}
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()

                  undo()
                }}
              />
            </Box>
            <Box
              sx={{
                mx: px2vw(10),
                height: "80px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",
                justifyContent: "center",
              }}
            >
              <IconWrapper
                icon={faRedo}
                style={{
                  alignSelf: "center",
                }}
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()

                  redo()
                }}
              />
            </Box>
            <Box
              sx={{
                mx: px2vw(10),
                height: "80px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",
                justifyContent: "center",
              }}
            >
              <Button
                width="93px"
                height="51px"
                fontSize="18px"
                sx={{
                  fontStyle: "normal",
                  backgroundColor: "app.secondary.50",
                  color: "#000",
                  border: "none",
                  borderRadius: "12px",
                  alignSelf: "center",
                }}
                className="auth-submit-btn"
                // style={{
                //   margin: 0,
                //   paddingRight: px2vw(19),
                //   paddingLeft: px2vw(19),
                //   paddingTop: px2vw(13),
                //   paddingBottom: px2vw(13),
                // }}
                onClick={e => {
                  const _payload = {
                    ...payload,
                    canvas_json: { ...editorState, payload: { ...payload } },
                  }
                  let editor = {
                    ...editorState,
                    edit: false,
                    konnect_name: konnectTitle
                  }
                  if (props.match.params.id) {
                    editor.id = props.match.params.id
                    editor.edit = true
                  }
                  saveConnect(editor)
                    .then(res => {
                      if (res.type === "SAVEKONNECT_SUCCESS") {
                        //alert("Connect is successful !")
                        setPublishFailure(false)
                        showSaveModal(t("konnect.saveSuccessMsg"), res)
                        //clearState()
                      }
                    })
                    .catch(err => {
                      console.log("error", err)
                    })
                }}
              >
                {t("konnect.header.saveBtnLabel")}
              </Button>
            </Box>
            <Box
              sx={{
                mx: px2vw(10),
                height: "80px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",
                justifyContent: "center",
              }}
            >
              <Button
                width="93px"
                height="51px"
                fontSize="18px"
                disabled={!editorMeta.canPublish}
                sx={{
                  fontStyle: "normal",
                  backgroundColor: "app.secondary.50",
                  color: "#000",
                  border: "none",
                  borderRadius: "12px",
                  alignSelf: "center",
                }}
                className="auth-submit-btn"
                onClick={e => {
                  const _payload = {
                    ...payload,
                    canvas_json: { ...editorState, payload: { ...payload } },
                  }
                  let editor = {
                    ...editorState,
                    edit: false,
                    konnect_name: konnectTitle
                  }
                  if (props.match.params.id) {
                    editor.id = props.match.params.id
                    editor.edit = true
                  }
                  editor.publish = true
                  saveConnect(editor)
                    .then(res => {
                      if (res.type === "SAVEKONNECT_SUCCESS") {
                        if (res.payload.publish_status === "Failure") {
                          showSaveModal(res.payload.message, res)
                          setPublishFailure(true)
                        } else {
                          showSaveModal(t("konnect.publishSuccessMsg"), res)
                          setPublishFailure(false)
                        }
                        //alert("Connect is successful !")
                        //showSaveModal("Konnect is successfully published !", res)
                        //clearState()
                      }
                      console.log("Connect successful !", res)
                    })
                    .catch(err => {
                      console.log("error", err)
                    })
                }}
              >
                {t("konnect.header.publishBtnLabel")}
              </Button>
            </Box>
          </Flex>
        </Konnectheader>
        <KonnectView
          history={props.history}
          isCollapsed={isCollapsed}
          toggleSidebar={() => setIsCollapsed(!isCollapsed)}
          apps={apps}
          onSearchItem={onSearchItem}
          authorisedApps={authorisedApps}
          appEvents={appEvents}
          appEventConfig={appEventConfig}
          editorState={editorState}
          payload={payload}
          editorMeta={editorMeta}
          testAndReviewLoader = {testAndReviewLoader}
          editorActions={{
            getAuthApps,
            getApps,
            getLinkedAccounts,
            setLinkedAccounts,
            getAppEvents,
            getAppEventConfig,
            getAppEventConfigDetail,
            getAppEventConfigDetailFetch,
            setEventsConfig,
            getEditorState,
            setEditorState,
            getKonnectName,
            setKonnectName,
            onDragNode,
            onDragNodeStop,
            onDragNodeStart,
            onCanvasDrop,
            onLinkStart,
            onLinkMove,
            onLinkComplete,
            onLinkCancel,
            onPortPositionChange,
            onLinkMouseEnter,
            onLinkMouseLeave,
            onLinkClick,
            onCanvasClick,
            onNodeClick,
            onNodeMouseEnter,
            onNodeMouseLeave,
            onNodeSizeChange,
            onPortTextChange,
            saveConnect,
            setScale,
            onDragCanvasStop,
            onDragCanvas,
            toggleBasicAuth,
            captureWebhookResponse,
            onWebhookAuthChange,
            onDeleteNode,
            onDeleteNodeV2,
            onSetAsFirstNode,
            onDeleteLink,
            expandCollapseNode,
            appTestAndReview,
            createAdditionalFields,
            setConfigMenuState,
            modifyConditions,
          }}
        />
      </KonnectWrapper>
      <Modal
        open={openModal}
        onClose={onCloseModal}
        onAnimationEnd={() => scrollTo(0, 0)}
        center
        styles={{ modal: { padding: "20px", borderRadius: "16px" } }}
      >
        <Flex flexDirection="column" sx={{ borderRadius: "16px", px: px2vw(60), padding: "0px" }}>
          {/* <Box sx={{ p: px2vw(20), justifyContent: 'center', textAlign: 'center' }}>
            <DeleteIconSvg />
          </Box> */}
          <Box sx={{ color: "#000000", p: px2vw(10) }}>
            <Text variant="title" fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}>
              {saveOrPublishMessage}
            </Text>
          </Box>
          <Box sx={{ p: px2vw(10) }}>
            <Flex flexDirection="row" sx={{ justifyContent: "center", marginTop: "10px" }}>
              {/* <Box sx={{ textAlign: "center", justifyContent: "center" }}>
                <Button
                  fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                  sx={{
                    background: "none",
                    boxShadow: "none",
                    fontWeight: "normal",
                    cursor: "pointer",
                    color: "app.secondary.70",
                    marginRight: "20px",
                    "&:hover": {
                      background: "none",
                      color: "app.secondary.70",
                    },
                  }}
                  onClick={onCloseModal}
                >
                  Cancel
                </Button>
              </Box> */}
              <Box>
                <Button
                  variant="primary"
                  fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                  sx={{ fontWeight: "normal", px: px2vw(20), borderRadius: "0px" }}
                  onClick={() => {
                    if (publishFailure) {
                      onCloseModal()
                    } else {
                      props.history.push("/konnectz")
                    }
                  }}
                >
                  OK
                </Button>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  )
}

const mapStateToProps = state => {
  const {
    apps = [],
    authorisedApps = [],
    appEvents = [],
    appEventConfig = [],
    editorState = null,
    payload = null,
    editorMeta = {},
    testAndReviewLoader = false
  } = state.getIn(["konnect"]).toJS()
  return {
    apps: apps.authorized_apps ? [...apps.authorized_apps, ...apps.authorized_apps, ...apps.authorized_apps] : [],
    authorisedApps,
    appEvents,
    appEventConfig,
    editorState,
    payload,
    editorMeta,
    testAndReviewLoader
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getEditorState: () => dispatch(getEditorState()),
    setEditorState: () => dispatch(setEditorState()),
    getKonnectName: () => dispatch(getKonnectName()),
    setKonnectName: (name) => dispatch(setKonnectName(name)),
    getApps: (searchQuery, authorizedOnly) => dispatch(getApps(searchQuery, authorizedOnly)),
    getAuthApps: (searchQuery, authorizedOnly) => dispatch(getAuthApps(searchQuery, authorizedOnly)),
    getAppEvents: node => dispatch(getAppEvents(node)),
    getLinkedAccounts: node => dispatch(getLinkedAccounts(node)),
    getAppEventConfig: node => dispatch(getAppEventConfig(node)),
    getAppEventConfigDetail: node => dispatch(getAppEventConfigDetail(node)),
    getAppEventConfigDetailFetch: node => dispatch(getAppEventConfigDetailFetch(node)),
    setLinkedAccounts: props => dispatch(setLinkedAccounts(props)),
    setEventsConfig: props => dispatch(setEventsConfig(props)),
    onDragNode: props => dispatch(onDragNode(props)),
    onDragNodeStop: props => dispatch(onDragNodeStop(props)),
    onDragNodeStart: props => dispatch(onDragNodeStart(props)),
    onCanvasDrop: props => dispatch(onCanvasDrop(props)),
    onLinkStart: props => dispatch(onLinkStart(props)),
    onLinkMove: props => dispatch(onLinkMove(props)),
    onLinkComplete: props => dispatch(onLinkComplete(props)),
    onLinkCancel: props => dispatch(onLinkCancel(props)),
    onPortPositionChange: props => dispatch(onPortPositionChange(props)),
    onLinkMouseEnter: data => dispatch(onLinkMouseEnter(data)), //Ashwin@todo
    onLinkMouseLeave: data => dispatch(onLinkMouseLeave(data)), //Ashwin@todo
    onLinkClick: () => dispatch(onLinkClick()), //Ashwin@todo
    onCanvasClick: () => dispatch(onCanvasClick()), //Ashwin@todo

    onNodeClick: data => dispatch(onNodeClick(data)),
    onNodeMouseEnter: () => dispatch(onNodeMouseEnter()), //Ashwin@todo
    onNodeMouseLeave: () => dispatch(onNodeMouseLeave()), //Ashwin@todo
    onNodeSizeChange: data => dispatch(onNodeSizeChange(data)),
    onPortTextChange: data => dispatch(onPortTextChange(data)),
    saveConnect: data => dispatch(saveConnect(data)),
    toggleSidebar: () => dispatch(toggleSidebar()),
    setScale: scale => dispatch(setScale(scale)),
    undo: () => dispatch(undo()),
    redo: () => dispatch(redo()),
    clearState: () => dispatch(clearState()),
    onDragCanvas: data => dispatch(onDragCanvas(data)),
    onDragCanvasStop: data => dispatch(onDragCanvasStop(data)),
    getKonnect: data => dispatch(getKonnect(data)),
    toggleBasicAuth: data => dispatch(toggleBasicAuth(data)),
    captureWebhookResponse: data => dispatch(captureWebhookResponse(data)),
    onWebhookAuthChange: data => dispatch(onWebhookAuthChange(data)),
    onDeleteNode: data => dispatch(onDeleteNode(data)),
    onDeleteNodeV2: data => dispatch(onDeleteNodeV2(data)),
    onSetAsFirstNode: data => dispatch(onSetAsFirstNode(data)),
    onDeleteLink: data => dispatch(onDeleteLink(data)),
    expandCollapseNode: data => dispatch(expandCollapseNode(data)),
    appTestAndReview: data => dispatch(appTestAndReview(data)),
    createAdditionalFields: data => dispatch(createAdditionalFields(data)),
    setConfigMenuState: data => dispatch(setConfigMenuState(data)),
    modifyConditions: data => dispatch(modifyConditions(data)),
  }
}

export const KonnectAppContainer = connect(mapStateToProps, mapDispatchToProps)(Container)