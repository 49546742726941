import React, { useRef } from "react"
// import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import { Box, Flex } from "rebass"
import { Input as RebassInput, Label as RebassLabel } from "@rebass/forms"
import { Icon } from "../Icon/Icon"
import { Span } from "src/components"

export const Input = ({
  action,
  errorMessage,
  icon,
  id,
  label,
  setError,
  iconClick,
  iconLeft,
  placeholder,
  ref,
  sxProps = {},
  disabled = false,
  hideAdditional = false,
  ...props
}) => {
  const inputField = ref ? ref : useRef(null)
  return (
    <>
      {(label || action) && (
        <Flex mb={2}>
          {label && <RebassLabel sx={{ flex: "1 1 0" }}>{label}</RebassLabel>}
          {action}
        </Flex>
      )}
      <Box
        tabIndex={-1}
        onKeyDown={e => {
          if ((e.keyCode !== 13 || e.which !== 13) && inputField && inputField.current) {
            inputField.current.focus()
          }
        }}
        sx={{
          position: "relative",
          color: props.defaultValue ? "app.primary.30" : "app.primary.80",
          ":hover, :focus": { color: "app.primary.30" },
        }}
      >
        <RebassInput
          className={"input" + (setError ? " error" : "")}
          ref={inputField}
          autocomplete="off"
          autocorrect="off"
          autocapitalize="none"
          spellcheck="false"
          tabIndex={0}
          height={{ _: 40, sm: 40, md: 40, lg: 40, xl: "auto" }}
          disabled={disabled}
          fontSize={{ _: 14, sm: 14, md: 14, lg: 16, xl: 16 }}
          py={{ _: "4px", sm: "4px", md: "6px", lg: "8px", xl: "10px" }}
          sx={{
            border: "none",
            borderRadius: "24px",
            backgroundColor: "app.secondary.60",
            pl: "16px",
            m: 0,
            pr: icon ? "40px" : 3,
            pl: iconLeft ? "40px" : 3,

            ":hover, :focus": {
              borderColor: "app.primary.30",
              color: "app.primary.30",
              "& ~ span": {
                color: "app.primary.30",
              },
            },
            "&.error": {
              borderColor: "app.secondary.30",
              color: "app.primary.30",
              "& ~ span": {
                color: "app.primary.30",
              },
            },
            mb: 0,
            ...sxProps,
          }}
          {...props}
        />
        {iconLeft && (
          <Icon
            icon={iconLeft}
            color={props.defaultValue ? "#152F73" : "#B7CBFF"}
            sx={{
              pointerEvents: "none",
              marginTop: "1px",
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              left: 12,
            }}
          />
        )}
        {icon && (
          <Icon
            onClick={iconClick}
            icon={icon}
            color={props.defaultValue ? "#152F73" : "#B7CBFF"}
            sx={{
              cursor: "pointer",
              marginTop: "1px",
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              right: 12,
            }}
          />
        )}

        {!props.defaultValue && placeholder && (
          <Span
            className="placeholder"
            fontSize={{ _: 14, sm: 14, md: 14, lg: 16, xl: 16 }}
            sx={{
              color: "app.secondary.80",
              marginTop: "1px",
              position: "absolute",
              top: "50%",
              transform: "translate(-50%,-50%)",
              left: "50%",
              userSelect: "none",
              pointerEvents: "none",
            }}
          >
            {placeholder}
          </Span>
        )}
      </Box>

      {!hideAdditional ? (
        <Flex
          sx={{
            minHeight: 20,
            borderRadius: "0 0 3px 3px",
            justifyContent: "flex-end",
          }}
        >
          {setError && (
            <RebassLabel
              as="span"
              htmlFor={id}
              p={2}
              sx={{
                width: "auto",
                fontSize: "10px",
                color: "app.secondary.30",
              }}
            >
              {errorMessage}
            </RebassLabel>
          )}
        </Flex>
      ) : null}
    </>
  )
}
