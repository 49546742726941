import React, { useState } from "react"
import { Box, Flex } from "rebass"
import { Label, Radio } from "@rebass/forms"
//import { Dropdown } from "./../../../../../../components/Konnect/DropdownMenu/index"
//import { Input } from "./../../../../../../components/Input/Input"
import { faTrash, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { Icon } from    "../../../../../../../components"//src/components"
import { ConnectorPortInput } from "../../../../konnect/UI"
import { useTranslation } from "react-i18next"

export const ApiItem = ({
    inputList,
    handleAddClick,
    handleInputChange,
    handleRemoveClick
//   konnect_activity_id,
}) => {
  return (
    <>
      <Flex
        sx={{
          border: "1px solid #B7CBFF",
          flexDirection: "column",
          borderRadius: "12px",
          pl: "14px",
          pr: "24px",
          py: "9px",
          boxSizing: "border-box",
          mb: "20px",
        }}
      >
      {inputList.map((x, i) => {
        return (
            <Flex sx={{ width: "100%", flexDirection: "row", textAlign: "left", justifyContent: "space-around" }}>
            <Box width={2/3} sx={{ mx: "20px", width: "100%", height: "58px" }}>
            <ConnectorPortInput
              name="key"
              placeholder="key"
              value={x.key}
              onChange={e => handleInputChange(e, i)}
            />
            </Box>
            <Box width={1/2} sx={{ mx: "20px", width: "100%", height: "58px" }}>
              {inputList.length !== 1 && <Icon ml="30px" mr="40px" icon={faTrash} onClick={() => handleRemoveClick(i)} />}
              {inputList.length - 1 === i && <Icon ml="30px" mr="40px" icon={faPlusCircle} onClick={handleAddClick} />}
            </Box>
          </Flex>
        );
      })}
      </Flex>
    </>
  )
}
