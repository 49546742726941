import UnlimitedSteps from "assets/images/features/unlimited-steps.png"
import VisualizeEverything from "assets/images/features/visualize-everything.png"
import IntegrationLibrary from "assets/images/features/integration-library.png"
import SimpleAutomation from "assets/images/features/simple-automation.png"
import PlatformForEveryone from "assets/images/features/platform-for-everyone.png"
import SeamlessAppsIntegration from "assets/images/features/seamless-apps-integration.png"

export const fr = {
  translation: {
    common: {
      app: "Konnectz",
    },
    header: {
      topNavigations: {
        tipsAndTricks: "Trucs et astuces",
        pricing: "Tarification",
        blogs: "Blogues",
        helpCenter: "Centre d'aide",
      },
      searchAppTextPlaceholder: "Cherche ici...",
      createKonnectzBtnLabel: "Créer Konnectz",
      taskhistory: "Historique des tâches",
      success: "Succès",
      error: "Erreur",
      notask: "Aucune tâche trouvée",
      tryagain: "Ajustez votre filtre et réessayez"
    },
    errorMessages: {
      SENT_RESET_INSTRUCTIONS_SUCCESS: "Les instructions de réinitialisation du mot de passe ont été envoyées par e-mail, veuillez vérifier votre e-mail.",
      SENT_RESET_INSTRUCTIONS_FAIL: "Demande non autorisée !",
      VERIFY_EMAIL_FAIL: "Désolé, impossible de confirmer votre email.",
      PASSWORD_NOT_UPDATED: "Désolé, échec de la mise à jour de votre compte",
      GET_FORGOT_PASSWORD_LINK_SUCCESS: "",
      GET_FORGOT_PASSWORD_LINK_FAIL: "Échec de l'envoi des instructions relatives au mot de passe. Veuillez essayer après un certain temps !",
      AUTH_SIGN_UP_FAIL: "Quelque chose s'est mal passé. Veuillez essayer après un certain temps !",
      somethingWentWrong: "Quelque chose s'est mal passé. Veuillez essayer après un certain temps !",
    },
    successMessages: {
      PASSWORD_UPDATED: "Le mot de passe a été mis à jour. Veuillez vous connecter avec votre nouveau mot de passe !",
    },
    history:{
      task:{
        task: "tâche",
        tasks: "Tâches",
        taskdate: "date de tâche :",
        user: "Utilisateur :"
      }
  },
    auth: {
      signup: {
        createPassword: {
          title: "Créer un mot de passe",
          subtitle: "Tapez-les DEUX FOIS pour confirmer",
          passwordErrorMsg: "Mot de passe incorrect !",
          confirmPasswordErrorMsg: "Mot de passe incorrect !",
          passwordMatchingErrorMsg: "Les deux mots de passe ne correspondent pas !",
          continueBtnLabel: "CONTINUEZ",
          createPasswordPlaceholder: "Créer un mot de passe",
          confirmPasswordPlaceholder: "Confirmez le mot de passe",
          passwordRule:
            "Conseil : Votre mot de passe doit comporter entre 8 et 15 caractères et doit contenir au moins une majuscule, une minuscule, un chiffre et un caractère spécial.",
          passwordRule1: "Votre mot de passe doit comporter entre 8 et 15 caractères.",
          passwordRule2: "Votre mot de passe doit contenir au moins une majuscule.",
          passwordRule3: "Votre mot de passe doit contenir au moins un chiffre.",
          passwordRule4: "Votre mot de passe doit contenir au moins un caractère spécial.",
        },
        verificationSent: {
          title: "VÉRIFICATION",
          subtitle: "Vérifier l'adresse e-mail",
          verificationText1: "Nous avons envoyé un lien de vérification sur",
          verificationText2: ", ouvrez l'e-mail et vérifiez votre adresse e-mail.",
          didntReceiveEmailText: "Vous n'avez pas reçu de courrier ?",
          resendText: "Renvoyer",
        },
        verifySuccess: {
          title: "Vérification terminée",
          verifyFailedTitle: "Échec de la vérification",
        },
        signupSuccess: {
          firstName: "Prénom",
          firstNameErrorMsg: "Le prénom est requis !",
          lastName: "Nom de famille",
          lastNameErrorMsg: "Le nom de famille est requis !",
          verifyErrorMsg: "Quelque chose s'est mal passé. Veuillez essayer de vous connecter si votre compte a déjà été vérifié.",
        },
        title: "Créez votre compte",
        subtitle: "Salut, bienvenue",
        verifyingMessage: "Vous vérifier",
        emailInvalid: "Le courriel est invalide !",
        emailExists: "L'adresse e-mail que vous avez saisie est déjà enregistrée",
        teamSignup: "Vous vous inscrivez en équipe ?",
        continueBtnLabel: "Continuez",
        alreadyHaveAccountText: "Avoir un compte ?",
        loginBtnLabel: "S'identifier",
        emailPlaceholder: "Entrez l'e-mail",
        features: {
          feature1: {
            title: "Automatisation simple",
            image: SimpleAutomation,
            description: "Créez un flux de travail complexe simplement et facilement en quelques minutes à l'aide de notre générateur de Konnectz visuel",
          },
          feature2: {
            title: "Plateforme pour tous",
            image: PlatformForEveryone,
            description:
              "Ce n'est pas un outil d'automatisation régulier réservé aux programmeurs, n'importe qui peut construire l'automatisation avec KonnectzIT",
          },
          feature3: {
            title: "Intégration transparente des applications",
            image: SeamlessAppsIntegration,
            description: "Connectez un nombre illimité d'applications à partir de notre bibliothèque d'intégration et des tâches quotidiennes automatisées",
          },
        },
      },
      login: {
        title: "Connectez-vous à votre compte",
        subtitle: "Hé, bienvenue à nouveau !",
        emailPlaceholder: "Entrez l'e-mail",
        emailInvalid: "Email invalide !",
        passwordRequired: "Mot de passe requis !",
        passwordPlaceholder: "Entrer le mot de passe",
        rememberMe: "Souviens-toi de moi",
        loginBtnLabel: "CONNECTE-TOI MAINTENANT",
        forFirstTimeSignup: "Pour la première fois ?",
        forgotPasswordLbl: "Mot de passe oublié",
        alreadyHaveAccountText: "Vous n'avez pas de compte ?",
        clickingContinueText: "En cliquant sur Continuer, vous acceptez nos",
        termsAndConditionsText: "Termes et conditions.",
        signupNowText: "S'inscrire",
        features: {
          feature1: {
            title: "Étapes illimitées",
            image: UnlimitedSteps,
            description: "Connectez plusieurs applications et créez des étapes illimitées pour une automatisation transparente.",
          },
          feature2: {
            title: "Visualisez tout",
            image: VisualizeEverything,
            description: "Créez vos flux de travail et imaginez facilement l'automatisation à l'aide d'un générateur de Konnectz visuel.",
          },
          feature3: {
            title: "Bibliothèque d'intégration",
            image: IntegrationLibrary,
            description: "Collection d'applications cloud pour fusionner et envoyer des données entre elles pour gagner du temps.",
          },
        },
        loginFailMsg: "Le nom d'utilisateur ou le mot de passe est invalide !",
        captchaErrorMsg: "Erreur CAPTCHA",
      },
      forgot: {
        title: "Mot de passe oublié",
        forgtoBtnLabel: "Obtenir le lien Mot de passe oublié",
        successSubTitle: "Email envoyé",
        features: {
          feature1: {
            title: "Automatisation simple",
            image: SimpleAutomation,
            description: "Créez un flux de travail complexe simplement et facilement en quelques minutes à l'aide de notre générateur de Konnectz visuel",
          },
          feature2: {
            title: "Plateforme pour tous",
            image: PlatformForEveryone,
            description:
              "Ce n'est pas un outil d'automatisation régulier réservé aux programmeurs, n'importe qui peut construire l'automatisation avec KonnectzIT",
          },
          feature3: {
            title: "Intégration transparente des applications",
            image: SeamlessAppsIntegration,
            description: "Connectez un nombre illimité d'applications à partir de notre bibliothèque d'intégration et des tâches quotidiennes automatisées",
          },
        },
        emailInvalid: "Email invalide!",
      },
      reset: {
        title: "réinitialiser le mot de passe",
        subTitle: "Créer un nouveau mot de passe",
      },
    },
    konnect: {
      sidebar: {
        search_apps: "Chercher...",
        choose_trigger: "Choisissez le déclencheur",
        choose_trigger_header: "Choisissez l'événement d'application",
        no_triggers_available: "Aucun déclencheur disponible",
        choose_format_header: "Options de mise en forme",
        choose_config_header: "Choisissez Configuration",
        select_trigger_action_dropdown_header: "Select",
        choose_option_header: "Définir l'option",
        no_options_available: "Aucune option disponible",
        choose_linked_account: "Sélectionnez un compte",
        customize_form_header: "Configurer le formulaire",
        customize_form_partial: "Choisir",
        configure_response: "Personnaliser la réponse",
        basic_auth: "Authentification de base",
        api_key: "clé API",
        api_token: "Clé secrète de l'API",
        webhook_url: "URL du webhook",
        webhook_url_toast: "URL Webhook copiée dans le presse-papier !",
        webhook_response: "Réponse Webhook",
        choose_action_event: "Choisissez l'événement d'action",
        endpoint_url: "URL du point de terminaison",
        payload_type: "Type de charge utile",
        wrap_request_array: "Envelopper la demande dans le tableau",
        set_params: "Définir les paramètres",
        add_header: "Ajouter un en-tête",
        set_paramter: "Définir le paramètre",
        expiry_time: "Nombre de chronologie",
        expiry_val: "Chronologie",
        segmentIndex: "Index des segments",
        validate: "Valider",
        generate: "produire",
      },
      UI: {
        betaText: "Bêta",
        webhookDuplicateToastMsg: "L'application Webhook existe déjà !",
      },
      header: {
        backBtnLabel: "Retour",
        saveBtnLabel: "Sauvegarder",
        publishBtnLabel: "Publier",
        nameOfKonnectz: "Nom du Konnectz",
      },
      testAndReview: {
        btnLabel: "Tester et réviser",
      },
      proceed: {
        btnLabel: "PROCÉDER",
      },
      additionalConfigsModal: {
        cancelBtnLabel: "Annuler",
        okBtnLabel: "d'accord",
      },
      conditions: {
        addNewConditionBtnLabel: "Ajouter une nouvelle condition +",
        cancelBtnLabel: "Annuler",
        okBtnLabel: "d'accord",
        operatorAndLabel: "ET",
        operatorOrLabel: "OU",
        headerConditionText: "État",
        selectFieldHeaderTitle: "Sélectionnez un champ",
        selectConditionHeaderTitle: "Sélectionnez l'état",
        validateBtnLabel: "Valider",
        conditionsHeaderText: "Conditions",
      },
      publishSuccessMsg: "Konnect est publié avec succès !",
      saveSuccessMsg: "Konnect est enregistré avec succès !",
    },
    apps: {
      authenticatedAppsTitle: "Applications authentifiées",
      aunthenticateNowTitle: "Authentifiez-vous maintenant",
      noAppsToAunthenticate: "Aucune application à authentifier pour le moment.",
      authenticateNow: "Vous n'avez aucune application authentifiée. Authentifiez-vous maintenant.",
      addAccountText: "Ajouter un compte",
      betaText: "Bêta",
      oAuthHandlerText: "Gestionnaire KonnectzIT OAuth, veuillez patienter..",
      cancelBtnText: "Annuler",
      yesContinueText: "Oui, continuer",
      requiredText: "(Obligatoire)",
      accessAPIModalHeaderText1: "Autoriser KonnectzIT à accéder",
      accessAPIModalHeaderText2: "Compte?",
      accessAPIModalMainText1: "Vous pouvez trouver votre",
      accessAPIModalMainText2: "en naviguant vers",
      accessAPIModalMainText3: "page d'intégration",
      authorized: "Applications autorisées"
    },
    konnectzList: {
      homeText: "Accueil",
      noKonnectzInfo: "Pas de konnectz pour le moment !",
      deleteModalBodyText: "Etes-vous sûr que vous voulez supprimer ?",
      deleteModalNoText: "Non",
      deleteModalDeleteText: "Supprimer",
      folders: {
        createFolderTitleText: "Donnez un nom à votre dossier",
        createFolderInputPlaceholder: "Nom de dossier",
        createFolderBtn: "Créer",
        deleteFolderConfirmationText: "Voulez-vous vraiment supprimer ce dossier ?",
        deleteBtn: "Supprimer",
        selectFolderInfo: "Sélectionnez le dossier dans lequel vous souhaitez déplacer vos contacts sélectionnés",
        moveFolderCancelBtn: "Annuler",
        moveBtn: "Se déplacer",
        renameSaveBtn: "Sauvegarder",
        renameYourFolderText: "Renommer votre dossier",
        createFolderBtn: "Créer le dossier"
      }
    },
    dashboard: {
      widgets: {
        latestApps: {
          headerTitle: "Dernières applications",
        },
        totalKonnectz: {
          headerTitle: "Konnectz totale",
          activeKonnectz: "Konnectz active",
          inactiveKonnectz: "Konnectz inactif",
        },
        latestKonnectz: {
          headerTitle: "Dernières Konnectz",
        },
        subscription: {
          contentText: "Débloquez plus de puissance en mettant à niveau Pro",
          upgradeBtnLabel: "METTRE À JOUR MAINTENANT",
        },
        taskConsumption: {
          headerTitle: "Consommation de tâches",
        },
      },
    },
    account: {
      countryRequiredText: "Le pays est obligatoire !",
      addressLine1RequiredText: "La ligne d'adresse 1 est obligatoire !",
      cityRequiredText: "La ville est obligatoire !",
      stateRequiredText: "L'état est obligatoire !",
      zipCodeRequiredText: "Le code postal est requis !",
      taxIdRequiredText: "L'identification fiscale est requise !",
      cityLabel: "Ville",
      stateLabel: "État",
      zipCodeLabel: "Code postal",
      taxIdLabel: "Numéro d'identification fiscale",
      addressLine2Label: "Adresse Ligne 2",
      addressLine1Label: "Adresse 1",
      countryLabel: "De campagne",
      billingtext:"Adresse de facturation",
      myProfileText: "Mon profil",
      emailAddressLabel: "Adresse e-mail",
      firstNameLabel: "Prénom",
      lastNameLabel: "Nom de famille",
      firstNameRequiredText: "Le prénom est requis!",
      lastNameRequiredText: "Le nom de famille est requis !",
      companyNameLabel: "Nom de la compagnie",
      timeZoneLabel: "Fuseau horaire",
      changeLanguageLabel: "Changer de langue",
      changePasswordHeaderText: "Changer le mot de passe",
      currentPasswordLabel: "Mot de passe actuel",
      currentPasswordRequiredText: "Le mot de passe actuel est requis !",
      newPasswordLabel: "nouveau mot de passe",
      newPasswordRequiredText: "Un nouveau mot de passe est requis !",
      confirmPasswordLabel: "Confirmez le mot de passe",
      confirmPasswordRequiredText: "Confirmer le mot de passe est requis !",
      updateBtnLabel: "Mettre à jour",
      editUpdateAccountSideBarText: "Modifier/Mettre à jour le compte",
      changePasswordSideBarText: "Changer le mot de passe",
      billingText: "Facturation",
      teamText: "Équipe",
      agencyText: "Agence",
      sideBarHeaderAccountText: "Compte",
      profileUpdatedSuccessfullyText: "Mise à jour du profil réussie!",
      addTeamMemberText: "Ajouter un membre de l'équipe",
      addSubAccountsText: "Ajouter des sous-comptes",

      billing: {
        couponCodeText: "Code",
        applyCodeBtnLbl: "Appliquer le code",
        tasksText: "Tâches",
        redeemedOnText: "Remboursé le",
        expiresOnText: "Expire le",
        noCouponsAppliedText: "Aucune licence appliquée",
        plansAndFeaturesText: "Forfaits et fonctionnalités",
        currentPlanText: "Plan actuel",
        upgradeText: "Améliorer",
        billingText: "Facturation",
        licenseerror:"Licence invalide !",
        completionofprofile:"Veuillez compléter votre profil avant d'utiliser le code. Ceci est requis pour le RGPD/le traitement des données et la facturation",
      },
      team: {
        name: "Nom",
        firstName: "Prénom",
        lastName: "Nom de famille",
        access: "Accès",
        email: "E-mail",
        addBtn: "Ajouter",
        cancelBtn: "Annuler",
        assignAccessLbl: "Attribuer un accès",
        noUsersInfo: "Aucun utilisateur disponible",
        teamMembersHeaderText: "Membres de l'équipe",
      },
      agency: {
        name: "Nom du client :",
        firstName: "Prénom",
        lastName: "Nom de famille",
        tasks: "Tâche assignée :",
        consumed:"Tâche consommée :",
        totalSubaccounts: "Total des sous-comptes :",
        email: "E-mail :",
        active: "Actif :",
        inActive: "Inactif :",
        addBtn: "Ajouter",
        cancelBtn: "Annuler",
        noAgenciesInfo: "Aucune agence disponible",
        agencyMembersHeaderText: "Sous-comptes",
        total:"Tâches totales :",
        update:"Mettre à jour",
        updateTask: "Mettre à jour la tâche",
        delete: "Supprimer",
        available: "Tâche disponible",
        deactivate: "Activer",
        activate: "Désactiver",
        subaccounterror:"Échec de la mise à jour des détails du sous-compte !"

      },
    },
    sideNav: {
      homeLabel: "Accueil",
      appsLabel: "applications",
      konnectzLabel: "Konnectz",
      historyLabel: "Histoire",
      accountLabel: "Compte",
      notificationsLabel: "Avis",
      logoutLabel: "Se déconnecter",
      getHelpLabel: "Obtenir de l'aide?",
      konnectzITLogoText: "KonnectzIT",
    },
  },
}
