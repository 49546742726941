import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import AuthShellContainer from 'src/features/auth/shell/Shell.container'
import { SignupSuccessView } from './SignupSuccess.view'
import signupFeatures from "src/config/features/signup-features"
import { updateUser, verifyEmail } from "src/store/actions/appActions"
import { useTranslation } from "react-i18next"
import AuthTokenService from "utils/AuthTokenService";

function SignupSuccessContainer(props) {
  const { t } = useTranslation()
  const state = {
    firstName: useState(""),
    firstNameError: useState(false),
    firstNameErrorMsg: "auth.signup.signupSuccess.firstNameErrorMsg",
    lastName: useState(""),
    loading: useState(false),
    lastNameError: useState(false),
    lastNameErrorMsg: "auth.signup.signupSuccess.lastNameErrorMsg"
  }

  useEffect(() => {
    if (AuthTokenService.exists()) {
      props.history.push('/apps')
    }
  }, [])

  const [loading, setLoading] = state.loading
  const [registrationToken, setRegistrationToken] = useState(null)
  const [verifyError, setVerifyError] = useState(null)

  function onContinueClick() {
    setLoading(true)
    props.verifyEmail({
      confirmation_token: props.match.params.token,
      first_name: state.firstName[0],
      last_name: state.lastName[0]
    }).then((res) => {
      setLoading(false)
      if (res.payload ?.key === 'VERIFY_EMAIL_SUCCESS') {
        AuthTokenService.storeToken({ token: res.payload.auth_token, lang: "en" })
        setRegistrationToken(res.payload.auth_token)
        setVerifyError(null)
        props.history.push('/verify-email-success')
      } else if (res.payload ?.key === 'VERIFY_EMAIL_FAIL') {
        setVerifyError(t("auth.signup.signupSuccess.verifyErrorMsg"))
      } else {
        setShowError(true)
      }
    })
  }

  return (
    <>
      <AuthShellContainer {...props} features={signupFeatures.features}>
        <SignupSuccessView
          verifyError={verifyError}
          state={state}
          loading={loading}
          onContinueClick={onContinueClick}
        />
      </AuthShellContainer>
    </>
  )
}

const mapStateToProps = state => {
  return {
    //user: state.getIn(['app', 'user'])
    registrationToken: state.getIn(['app', 'registrationToken'])
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateUser: (data, accessToken) => dispatch(updateUser(data, accessToken)),
    verifyEmail: data => dispatch(verifyEmail(data))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupSuccessContainer)
