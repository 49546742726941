import * as React from "react"
import { PortsGroupDefault } from "../"
// it is  used in  base editor
export const PortsDefault = ({ children, config }) => {
 // console.log(children.filter(child => ["input", "top"].includes(child.props.port.type)),"Ports default")

  return (
    <div>
      <PortsGroupDefault config={config} side="top">
        {children.filter(child => ["input", "top"].includes(child.props.port.type))}
      </PortsGroupDefault>
      <PortsGroupDefault config={config} side="bottom">
        {children.filter(child => ["output", "bottom"].includes(child.props.port.type))}
      </PortsGroupDefault>
      <PortsGroupDefault config={config} side="right">
        {children.filter(child => ["right"].includes(child.props.port.type))}
      </PortsGroupDefault>
      <PortsGroupDefault config={config} side="left">
        {children.filter(child => ["left"].includes(child.props.port.type))}
      </PortsGroupDefault>
    </div>
  )
}
