import React, { useState, useEffect } from "react"
import { Box, Text, Flex, Link } from "rebass"
import { Checkbox, Label } from "@rebass/forms"
import { useTranslation } from "react-i18next"
import { Button } from "src/components/index"
import px2vw from "utils/px2vw"
import "./styles.css"
import { Dropdown } from "src/components/Konnect/DropdownMenu/index"
import { Input } from "src/components/Input/Input"
import { ConditionItem } from "./components/conditionItem"
import { ApiItem } from "./components/apiItem"
import { v4 } from "uuid"
import { SelectionHeader, EventSelectionGroup } from "src/components/Konnect/UI"

export const ApiModal = ({
  closeModal,
  updateHeaderAndParamsDetails,
  headers,
  params,
  fromFields,
  konnect_activity_id,
}) => {
  const { t } = useTranslation()
  const [headerList, setHeaderList] = useState(headers)
  const [paramsList, setParamsList] = useState(params)

  // handle input change
  const handleHeaderListInputChange = (e, index) => {
    const list = [...headerList]
    if(e.target){
        const { name, value } = e.target
        list[index][name] = value
        setHeaderList(list)
    }
  }

  // handle click event of the Remove button
  const handleHeaderListRemoveClick = index => {
    const list = [...headerList]
    list.splice(index, 1)
    setHeaderList(list)
  }

  // handle click event of the Add button
  const handleHeaderListAddClick = () => {
    setHeaderList([...headerList, { key: "" }])
  }

  // handle input change
  const handleParamsListInputChange = (e, index) => {
    const list = [...paramsList]
    if(e.target){
        const { name, value } = e.target
        list[index][name] = value
        setParamsList(list)
    }
  }

  // handle click event of the Remove button
  const handleParamsListRemoveClick = index => {
    const list = [...paramsList]
    list.splice(index, 1)
    setParamsList(list)
  }

  // handle click event of the Add button
  const handleParamsListAddClick = () => {
    setParamsList([...paramsList, { key: "" }])
  }

  return (
    <>
      <Flex
        sx={{
          textAlign: "left",
          cursor: "pointer",
          flexDirection: "column",
          backgroundColor: "app.primary.110",
        }}
      >
        <Box
          className={"modal-scroller"}
          sx={{
            width: "95%",
            height: "100%",
            marginTop: "40px",
            marginLeft: "35px",
            minHeight: "600px",
            maxHeight: "600px",
          }}
        >
          <EventSelectionGroup>
            <SelectionHeader>{t("konnect.sidebar.add_header")}</SelectionHeader>
            <ApiItem
              inputList={headerList}
              handleInputChange={handleHeaderListInputChange}
              handleAddClick={handleHeaderListAddClick}
              handleRemoveClick={handleHeaderListRemoveClick}
              fromFields={fromFields}
            />
          </EventSelectionGroup>
          <EventSelectionGroup>
              <SelectionHeader>{t("konnect.sidebar.set_paramter")}</SelectionHeader>
              <ApiItem
              inputList={paramsList}
              handleInputChange={handleParamsListInputChange}
              handleAddClick={handleParamsListAddClick}
              handleRemoveClick={handleParamsListRemoveClick}
              fromFields={fromFields}
            />
            </EventSelectionGroup>
        </Box>
        <Box sx={{ height: "60px", textAlign: "center" }}>
          <Flex flexDirection="row" sx={{ justifyContent: "center", marginTop: "10px" }}>
            <Box sx={{ textAlign: "center", justifyContent: "center" }}>
              <Button
                fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                sx={{
                  background: "none",
                  boxShadow: "none",
                  fontWeight: "normal",
                  cursor: "pointer",
                  color: "app.secondary.70",
                  marginRight: "20px",
                  "&:hover": {
                    background: "none",
                    color: "app.secondary.70",
                  },
                }}
                onClick={closeModal}
              >
                {t("konnect.conditions.cancelBtnLabel")}
              </Button>
            </Box>
            <Box>
              <Button
                variant="primary"
                fontSize={{ _: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
                sx={{ fontWeight: "normal", px: px2vw(20), borderRadius: "0px", color: "#FFF" }}
                onClick={() => updateHeaderAndParamsDetails(headerList,paramsList)}
              >
                {t("konnect.conditions.okBtnLabel")}
              </Button>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}
