/**
 * App config action to handle the global config api.
 */

import { makeRequestActionCreator } from "utils/redux"
import { GET_AUTH_URL, SAVE_AUTH, GET_APP_DETAILS, UPDATE_APP_STATUS, SAVE_LABEL } from "config/Constants"

export const getOauthData = payload => dispatch => {
  const request = makeRequestActionCreator(GET_AUTH_URL)

  return dispatch(
    request({
      url: "api/v1/auth-url",
      data: payload,
      method: "POST",
    })
  )
}

export const saveOauthData = payload => dispatch => {
  const request = makeRequestActionCreator(SAVE_AUTH)

  return dispatch(
    request({
      url: "api/v1/authorization",
      data: payload,
      method: "POST",
    })
  )
}

export const getAppDetails = payload => dispatch => {
  const request = makeRequestActionCreator(GET_APP_DETAILS)

  return dispatch(
    request({
      url: `api/v1/apps/${payload}/authorized_app_accounts`,
      method: "GET",
    })
  )
}

export const updateAppStatus = payload => dispatch => {
  const request = makeRequestActionCreator(UPDATE_APP_STATUS)
  return dispatch(
    request({
      url: `api/v1/app_users/${payload.appId}/update_status`,
      data: { status: payload.status },
      method: "POST",
    })
  )
}

export const saveAuthLabel = payload => dispatch => {
  const request = makeRequestActionCreator(SAVE_LABEL)
  return dispatch(
    request({
      url: `api/v1/app_users/${payload.id}/update_label`,
      data: { label: payload.label },
      method: "POST",
    })
  )
}
