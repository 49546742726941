/**
 * Forgot password container to handle state management.
 */
import React, { useState } from "react"
import { connect } from "react-redux"
import AuthShellContainer from "src/features/auth/shell/Shell.container"
import { ForgotView, ForgotMsg } from "./Forgot.view"
import { useTranslation } from "react-i18next"
import { getForgotPasswordLink } from "src/store/actions/appActions"
import forgotFeatures from "src/config/features/forgot-features"
import { ToastContainer, toast } from "react-toastify"

const _ForgotContainer = props => {
  const state = {
    email: useState(""),
    screen: useState("forgot"),
    loading: useState(false),
    emailError: useState(false),
    emailErrorMsg: "auth.forgot.emailInvalid"
  }
  const [screen, setScreen] = state.screen
  const [loading, setLoading] = state.loading

  const { t } = useTranslation()

  //Forgot password api handler
  function requestPasswordLink() {
    setLoading(true)
    props.getForgotPasswordLink({
      email: state.email[0]
    }).then((res) => {
      setLoading(false)
      if (res.payload.key === 'SENT_RESET_INSTRUCTIONS_SUCCESS') {
        setScreen('success')
      } else if (res.payload.key === 'SENT_RESET_INSTRUCTIONS_FAIL') {
        toastMessage(t("errorMessages." + res.payload.key))
      } else {
        toastMessage(t("errorMessages.somethingWentWrong"))
      }
    })
  }

  function toastMessage(msg) {
    toast.error(msg), {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    }
  }

  return (
    <>
      <AuthShellContainer {...props} features={forgotFeatures.features}>
        {screen === "forgot" && (
          <ForgotView
            validation={false}
            state={state}
            loading={loading}
            onContinueClick={requestPasswordLink}
          />
        )}
        {screen === "success" && <ForgotMsg state={state} />}
      </AuthShellContainer>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    getForgotPasswordLink: data => dispatch(getForgotPasswordLink(data)),
  }
}

export const ForgotContainer = connect(mapStateToProps, mapDispatchToProps)(_ForgotContainer)
