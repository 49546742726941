/**
 * Generic Action Creator Factory
 */
export const makeActionCreator = (type, ...argNames) => {
  return (...args) => {
    const action = { type }

    argNames.forEach((arg, i) => {
      action[argNames[i]] = args[i]
    })

    return action
  }
}

/**
 * Generic Request Action Creator
 */
export const makeRequestActionCreator = (type, extra = {}, accessToken = null) => {
  return request => {
    const headers = { "Content-Type": "application/json" }

    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`
    }

    request.headers = headers
    return {
      type,
      payload: {
        request,
      },
      ...extra,
    }
  }
}
