import React, { useState, useEffect } from "react"
import "./App.css"
import axios from "axios"
import { useTranslation } from "react-i18next"
import AuthTokenService from "utils/AuthTokenService"
import { Box, Text, Flex, Link } from "rebass"
import "./App.css"
import { useHistory } from "react-router-dom"
import { useData } from "./Context/canvasContext"
import { Preloader } from "../../components/Preloader/index"
import { getAppsforGpt } from "../ReactFlow/features/index"
export const ChatGPT = index => {
  const { t } = useTranslation()

  const [prompt, setPrompt] = useState("")
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState([])
  const [apps, setApps] = useState([])
  const [list, setList] = useState([])
  const history1 = useHistory()
  const [data, setData] = useState(null)
  const [items, setItems] = useState()
  const [actions, setActions] = useState([])
  const handleSubmit = e => {
    let prompt1 = prompt.toUpperCase()
    e.preventDefault()
    setLoading(true)
    axios
    .post("https://chatgpt.kntz.it/chat", { prompt },{timeout: 115000},)

      .then(res => {
        setResponse(res.data.message)

        // const response = res.data.filter(str => {
        //   return actions.find(a => {
        //     const string = `/${a.name}/i`
        //     return str.UseCase.match(string)
        //   })
        // })
        // console.log("111111111111",response)
        // const promptImg = apps.filter(a => {
        //   return a.name.toLowerCase() === prompt.toLowerCase()
        // })
        // setPropmtApp(promptImg)

        // let apps3 = apps1.map(itm => ({
        //   ...apps.find(item => item.name.toLowerCase() === itm.AppName.toLowerCase() && item),
        //   ...itm,
        // }))
        //
        // setResponse(apps3)
        setLoading(false)
      })

      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }

  let apps4new = []

  if (response && response.length) {
    let appswithprompt = JSON.parse(response).filter(a => {
      a.app1.toLowerCase().includes(prompt.toLowerCase()) ||
        a.app2.toLowerCase().includes(prompt.toLowerCase()) ||
        a.app3.toLowerCase().includes(prompt.toLowerCase())
    })
    let apps1 = JSON.parse(response).filter(a => {
      return apps.find(e => {
        return a.app1.toLowerCase().includes(e.name.toLowerCase())
      })
    })

    let apps1new = apps1.map(itm => ({
      ...itm,
      app1: {
        ...apps.find(item => itm.app1.toLowerCase().includes(item.name.toLowerCase()) && item),
      },
    }))

    let apps2 = apps1new.filter(a => {
      return apps.find(e => {
        return a.app2.toLowerCase().includes(e.name.toLowerCase())
      })
    })
    let apps2new = apps2.map(itm => ({
      ...itm,
      app2: {
        ...apps.find(item => itm.app2.toLowerCase().includes(item.name.toLowerCase()) && item),
      },
    }))
    let apps4 = apps2new.filter(a => {
      return apps.find(e => {
        return a.app3.toLowerCase().includes(e.name.toLowerCase())
      })
    })
    apps4new = apps4.map(itm => ({
      ...itm,
      app3: {
        ...apps.find(item => itm.app3.toLowerCase().includes(item.name.toLowerCase()) && item),
      },
    }))
  }

  const onSearch = searchTerm => {
    setPrompt(searchTerm.toLowerCase())
  }
  const fetchData = async searchQuery => {
    var UrlInfo = {
      url: `https://stagingapp.konnectzit.com/api/v1/apps/public_app_list`, //api/v1/apps/authorized_apps1
      method: "GET",
    }
    if (searchQuery) {
      const qs = Object.keys(searchQuery)
        .map(key => `${key}=${searchQuery[key]}`)
        .join("&")

      UrlInfo.url = UrlInfo.url + `?${qs}`
    }
    try {
      let result = await axios(UrlInfo)
      let response = await result.data
      setApps(response.apps)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchAppActions = async () => {
    //const props = prompt.slice(0, 1).toUpperCase() + prompt.slice(1, prompt.length + 1)
    let urlInfo = { url: `https://production.konnectzit.com/api/v1/apps/Facebook-Lead-Ads`, method: "GET" }
    try {
      let result = await axios(urlInfo)
      let response = await result.data
      setActions(response.triggers)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (prompt === "") {
      apps4new = []
      setResponse([])
    }
  }, [prompt])
  useEffect(() => {
    getAppsforGpt()
    fetchData()
    fetchAppActions()
    const items = JSON.parse(localStorage.getItem("dataKey"))
    if (items) {
      setItems(items)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem("dataKey", JSON.stringify(data))
    if (data) {
      history1.push("Konnect")
    }
  }, [data])

  return (
    <div key={index}>
      <h1 style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>Get Usecases for your apps</h1>
      <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
        <form onSubmit={handleSubmit}>
          {/* <label>Chat GPT</label> */}
          <div style={{ display: "flex", marginBottom: "20px" }}>
            <div>
              {" "}
              <input
                type="text"
                width={"600px"}
                value={prompt}
                placeholder="Enter App Name"
                onChange={e => {
                  setPrompt(e.target.value)
                }}
                style={{ padding: "12px", border: "1px solid lightgray", borderRadius: "10px", width: "500px" }}
              />
            </div>

            <div>
              <button
                type="submit"
                style={{
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "#152F73",
                  padding: "12px",
                  borderRadius: "8px",
                  marginLeft: "15px",
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </form>{" "}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="dropdown">
          {apps
            .filter(item => {
              const searchTerm = prompt.toLowerCase()
              const fullName = item.name.toLowerCase()

              return searchTerm && fullName.startsWith(searchTerm) && fullName !== searchTerm
            })
            .slice(0, 10)
            .map(item => (
              <div onClick={() => onSearch(item.name)} className="dropdown-row" key={item.name}>
                <img width={30} height={30} src={item.image_url} /> {item.name}
              </div>
            ))}
        </div>{" "}
      </div>
      {loading && (
        <Flex sx={{ gap: "20px", flexDirection: "column" }}>
          {" "}
          <p
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "300px",
              margin: "auto",
              textAlign: "center",
            }}
          >
            We are getting some awesome automations from ChatGPT, it usually takes few seconds. Please don't refresh or
            close this page
          </p>
          <Preloader
            width={{ _: 40, sm: 40, md: 44, lg: 48, xl: 52 }}
            height={{ _: 40, sm: 40, md: 44, lg: 48, xl: 52 }}
            loading={loading}
            position="static"
          />{" "}
        </Flex>
      )}
      {prompt === "" ? null : (
        <div style={{ display: "contents", width: "100%" }}>
          <div>
            {apps4new.length > 0 &&
              apps4new.map((x, i) => (
                <>
                  <Flex className="chat" sx={{ marginBottom: "5px" }}>
                    <Flex>
                      <div>
                        <div style={{ display: "flex" }}>
                          <img src={x.app1.image_url} width={40} height={40} />{" "}
                          <img src={x.app2.image_url} width={40} height={40} />{" "}
                          <img src={x.app3.image_url} width={40} height={40} />{" "}
                        </div>
                      </div>
                      <div
                        style={{
                          alignItems: "center",
                          display: "grid",
                          justifyContent: "center",
                          fontSize: "16px",
                          marginLeft: "25px",
                        }}
                      >
                        <div>{x.use_case}</div>
                        <div style={{ fontWeight: "semi-bold", fontSize: "14px" }}>
                          By {x.app1.name + " + " + x.app2.name + " + " + x.app3.name}
                        </div>
                      </div>
                    </Flex>
                    <span
                      onClick={() => {
                        setData(x)
                      }}
                    >
                      <button
                        style={{
                          color: "white",
                          width: "70px",
                          height: "50px",
                          backgroundColor: "#152F73",
                          padding: "11px",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setData(x)
                          //   changeRoute()
                        }}
                      >
                        Try it
                      </button>
                    </span>{" "}
                  </Flex>
                </>
              ))}
          </div>
        </div>
      )}
    </div>
  )
}
